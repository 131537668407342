.icon {
  display: inline-block;
  vertical-align: top;

  &_facebook { @include sprite($facebook) }
  &_twitter { @include sprite($twitter) }
  &_google { @include sprite($google) }
  &_vk, &_vkontakte { @include sprite($vkontakte) }
  &_ok, &_odnoklassniki  { @include sprite($ok) }
  &_facebook-small { @include sprite($facebook-small) }
  &_twitter-small { @include sprite($twitter-small) }
  &_google-small { @include sprite($google-small) }
  &_vk-small { @include sprite($vkontakte-small) }
  &_ok-small { @include sprite($ok-small) }
  &_counter { @include sprite($counter) }
  &_heart { @include sprite($heart) }
  &_cup { @include sprite($cup) }
  &_info { @include sprite($info) }
  &_info-light { @include sprite($info-light)}
  &_visa { @include sprite($visa) }
  &_mastercard { @include sprite($mastercard) }
  &_qiwi { @include sprite($qiwi) }
  &_yandex { @include sprite($yandex) }
  &_webmoney { @include sprite($webmoney) }
  &_moneta { @include sprite($moneta) }
  &_wallet { @include sprite($wallet) }
  &_sberbank { @include sprite($sberbank) }
  &_alfabank { @include sprite($alfabank) }
  &_promsvyazbank { @include sprite($promsvyazbank) }
  &_gift { @include sprite($gift)}
  &_medal { @include sprite($medal)}
  &_spinner { @include sprite($spinner) }
  &_star { @include sprite($star)}
  &_starhalf { @include sprite($starhalf) }
  &_starempty { @include sprite($starempty) }
  &_nav-divider { @include sprite($nav-divider) }
  &_cancel { @include sprite($cancel) }

  &_cross {
    width: 16px;
    height: 16px;
    position: relative;
    display: block;
    &:before {
      content: '';
      width: 2px;
      height: 18px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: 9px;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      content: '';
      width: 2px;
      height: 18px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: 9px;
      transform: rotate(-45deg);
    }
  }
  &_cross-bold { @include sprite($cross-bold) }
  &_cross-bold_grey { @include sprite($cross-bold_grey) }
  &_checkmark { @include sprite($checkmark) }
  &_clock { @include sprite($clock) }
  &_visa-logo { @include sprite($visa-logo) }
  &_qiwi-logo { @include sprite($qiwi-logo) }
  &_yandex-logo { @include sprite($yandex-logo)}

  &-bitcoin { @include sprite($bitcoin)}
  &-comepay { @include sprite($comepay)}
  &-contact { @include sprite($contact)}
  &-mobiles { @include sprite($mobiles)}
  &-moneyru { @include sprite($moneyru)}
  &_okpay_rub { @include sprite($okpay)}
  &_payeer_rub { @include sprite($payeer)}
  &_perfectmoney_rub { @include sprite($perfectmoney)}
  &_card_rub { @include sprite($visamastercard)}
  &_qiwi_rub { @include sprite($qiwi-c)}
  &_yamoney_rub { @include sprite($yandex-c)}
  &-webmoney { @include sprite($webmoney-c)}

  &_medal-gold { @include sprite($medal-gold) }
  &_medal-gold-huge { @include sprite($medal-gold-huge) }
  &_medal-gold-large { @include sprite($medal-gold-large) }
  &_medal-silver-large { @include sprite($medal-silver-large) }
  &_medal-bronze-large { @include sprite($medal-bronze-large) }
  &_arrow-right { @include sprite($arrow-right) }
  &_arrow-right-white { @include sprite($arrow-right-white)}

  &_arrow-down-white { @include sprite($arrow-down-white) }
  &_arrow-up-white { @include sprite($arrow-up-white)}
  &_counter-bg { @include sprite($counter-bg) }
  &_counter-bg-dark { @include sprite($counter-bg-dark) }

  &_bonus-small-1 { @include sprite($bonus-small-1) }
  &_bonus-tiny-1 { @include sprite($bonus-tiny-1)}
  &_bonus-4 { @include sprite($bonus-4) }

  &_search { @include sprite($search) }
  &_exit { @include sprite($exit) }
  &_purse { @include sprite($purse) }
  &_star-white { @include sprite($star-white) }
  &_arrow-down { @include sprite($arrow-down) }
  &_gamepad { @include sprite($gamepad) }
  &_safebox { @include sprite($safebox) }
  &_star-big { @include sprite($star-big) }
  &_star-big-empty { @include sprite($star-big-empty) }
  &_info-outline { @include sprite($info-outline) }
  &_volume { @include sprite($volume) }
  &_play { @include sprite($play) }
  &_shuffle { @include sprite($shuffle) }
  &_ruble-sign { @include sprite($ruble-currency-sign)}

  &_vip-1-small { @include  sprite($vip-1-small)}
  &_vip-2-small { @include  sprite($vip-2-small)}
  &_vip-3-small { @include  sprite($vip-3-small)}
  &_vip-4-small { @include  sprite($vip-4-small)}
  &_vip-5-small { @include  sprite($vip-5-small)}
  &_vip-6-small { @include  sprite($vip-6-small)}

  &_vip-1-medium { @include  sprite($vip-1-medium)}
  &_vip-2-medium { @include  sprite($vip-2-medium)}
  &_vip-3-medium { @include  sprite($vip-3-medium)}
  &_vip-4-medium { @include  sprite($vip-4-medium)}
  &_vip-5-medium { @include  sprite($vip-5-medium)}
  &_vip-6-medium { @include  sprite($vip-6-medium)}

  &_18 {@include sprite($l18)}
  &_curagao {@include sprite($curagao)}
  &_ecorga {@include sprite($ecogra)}
  &_microgaming {@include sprite($microgaming)}
  &_netent {@include sprite($netent)}

  &_lock_small {@include sprite($lock_small)}

  &_popup-panel_toggle {@include  sprite($icon-popup-panel-arrow)}
}