.gameplay-panel {
  display: block;
  position: relative;
  width: auto;
  border-radius: 6px;
  background-color: #132e4d;
  box-shadow: 0 1px 0 0 #131221, inset 0 -1px 0 0 #132e4d;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__head {
    margin-bottom: 15px;
  }

  &__head {
    &:after {
      display: none;
    }
  }

  &__title {
    text-align: center;
    padding: 37px 40px 7px;
    text-shadow: 0 2px 1px rgba(9, 32, 58, 0.83);
    text-transform: uppercase;
    color: #fff000;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;

    @include xSmall {
      font-size: 13px;
      line-height: 16px;
      padding: 20px 16px 8px;
    }
  }

  &__subtitle {
    display: block;
    color: #fff;
    text-transform: none;
    text-align: center;
    padding: 0 10px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    max-width: 590px;
    margin: 0 auto;

    @include xSmall {
      font-size: 12px;
      line-height: 16px;
      padding: 0 30px;
    }
  }

  &__note {
    display: inline-block;
    vertical-align: top;
    margin: 0 6px;
    color: #fff001;
  }

  &__content {
    text-align: center;
    margin-top: 10px;
  }

  &__input {
    height: 37px;

    @include xSmall {
      margin-bottom: 10px;
    }

    .input__inner {
      width: 192px;
      color: #7a7a7a;
      height: 37px;
      box-shadow: 0 1px 1px rgba(43, 42, 70, 0.46), inset 0 1px 1px rgba(0, 0, 0, 0.79);
      border-radius: 2px;
      background-color: #09203a;

      @include xSmall {
        width: 200px;
      }
    }
  }

  &__button {
    min-width: 70px;
    height: 37px;
    margin-top: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    text-shadow: 0 1px 0 #be2f21;

    &_wide {
      min-width: 202px;
      padding: 10px 14px;
    }

    &_footer {
      display: block;
      margin: 0 12px;
      background: transparent;
      border: solid 1px rgba(109, 108, 146, 0.65);
      box-shadow: none;
      text-transform: none;
      font-size: 16px;
      font-weight: 400;
      color: #cdccde;
      text-shadow: none;
      border-radius: 6px;
      height: 36px;
      line-height: 34px;
      padding: 0 20px;

      @include xSmall {
        font-size: 13px;
        padding: 0 10px;
        margin: 0 6px;

      }
    }
  }

  &__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 380px;

    @include xSmall {
      max-width: 216px;
    }
  }

  &__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
  }

  &__bonus-note {
    display: block;
    vertical-align: top;
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 400;
    padding-left: 30px;
    line-height: 13px;

    @include xSmall {
      font-size: 12px;
    }
  }

  &__link {
    display: block;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    text-decoration: underline;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    color: #7a7a7a;
    margin: 0 12px;

    @include xSmall {
      font-size: 13px;
      margin: 0 6px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 56px;
    background-color: #09203a;
    box-shadow: 0 1px 0 0 #131221, inset 0 -1px 0 0 #132e4d;
  }

}