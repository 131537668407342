.jackpot {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  position: relative;
  padding-top: 19px;

  &__base {
    display: block;
    position: relative;
    width: 204px;
    height: 63px;
    padding: 7px 10px;
    background-color: #102843;
    border: 6px solid #7ac4ff;
    border-radius: 31px;
    box-shadow: 0 5px 8px rgba(14, 34, 57, 0.93);
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    color: #d7e6f8;
    z-index: 3;
    letter-spacing: -3px;
  }

  &__back {
    width: 75px;
    height: 75px;
    background-color: #102843;
    border: 6px solid #fd8a1f;
    border-radius: 50%;
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
  }

  &__overlay {
    display: block;
    position: absolute;
    width: 100%;
    top: 11px;
    z-index: 1;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    &:before {
      content: '';
      width: 72px;
      height: 75px;
      background-color: #102843;
      border: 6px solid #fd8a1f;
      border-radius: 50%;
      position: absolute;
      display: block;
      top: -4px;
      left: 34px;
      z-index: 1;
    }

    &:after {
      content: '';
      width: 72px;
      height: 75px;
      background-color: #102843;
      border: 6px solid #fd8a1f;
      border-radius: 50%;
      position: absolute;
      display: block;
      top: -4px;
      right: 34px;
      z-index: 1;
    }
  }

}