.tab-cashier {
  display: block;
  position: relative;
  text-align: center;
  background-color: #132e4d;

  &__info-panel {
    display: block;
    //position: absolute;
    margin: 0 auto;
    width: 440px;
    //left: 50%;
    //top: 48px;
    //-ms-transform: translateX(-50%);
    //transform: translateX(-50%);
  }

  &__header {
    //height: 123px;
    min-height: 20px;
    position: relative;
    background-color: #132e4d;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      opacity: 0.27;
      background-image: linear-gradient(90deg,#04469c,#52baff 50%,#04469c);
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.42);
    }
  }

  &__content {
    background-color: #132e4d;
    width: 100%;
    margin: 20px auto 0;
    padding-bottom: 76px;

    @include xSmall {
      width: 100%;
    }

    .tab__content {
      max-height: 440px;
      overflow: hidden;
      max-width: 680px;
      margin: 25px auto 0;

      @include xSmall {
        max-height: none;
      }
    }
  }
  &__content_deposit {
    background-color: #1b1a27;
    width: 680px;
    margin: 0 auto;
    padding-bottom: 76px;

    @include xSmall {
      width: 100%;
    }

     .tab__content {
       max-height: 440px;
       overflow: hidden;
    }


  }

  .tab__title {
    padding: 30px 120px 21px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;

    @include xSmall {
      padding: 30px 20px 21px;
      text-align: left;
    }

    .fa {
      margin-right: 10px;

      @include xSmall {
        height: 100%;
        position: absolute;
        left: 20px;
        top: 31px;
      }
    }
    .tab-title_yellow {
      color: #f2e419;

      @include xSmall {
        padding-left: 32px;
      }
      * {
        color: #f2e419;
      }
      }
    .tab-title_grey {
      color: #585861;
    }
    &_big {
      font-size: 20px;
      margin-bottom: 13px;
    }
    &_caption {
      font-weight: normal;
    }
  }

  ul.tab__title_caption {
    text-align: left;
    width: 72%;
    margin: 0 auto 10px;
    padding: 0;

    @include xSmall {
      width: 80%;
    }

    li {
      margin: 0 0 3px;
      list-style-type: none;
    }
  }
  &__payment {
    padding-top: 55px;
  }
  .tab_style_button{
    width: 640px;
    display: inline-block;
    vertical-align: top;
    @include Small{
      width: calc(100%/3*2 - 45px);
      svg:not(:root),
      .payitem__img_inner img{
        max-width: 96%;
      }
      .payitem{
        width: 30%;
      }
      .tab__item{
        width: 30%;
      }
    }
    @include xSmall{
      width: 100%;
    }
  }
  &__bonus-sidebar{
    width: 270px;
    display: inline-block;
    background: #09203a;
    color: #fff;
    img{
      max-width: 100%;
    }
    .bonus-sidebar__header{
      background: #0d2847;
      padding: 5px 15px;
      font-size: 21px;
      text-transform: uppercase;
      font-weight: 700;
    }
    .bonus-sidebar__content{
      padding: 0 10px;
    }
    .bonus-img{
      width: 100px;
    }
    .bonus-name{
      font-size: 18px;
      color: rgb(253, 194, 54);
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      text-shadow: 0 2px 0 rgba(21, 20, 31, 0.7);
      margin: 5px 0 0;

    }
    .bonus-description{
        font-size: 15px;
        color: rgb(193, 193, 204);
        line-height: 1.2;
        text-align: center;
        text-shadow: 0 2px 0 rgba(44, 44, 60, 0.7);
    }
    .button_color_orange{
      margin-bottom: 15px;
      border-radius: 20px;
    }
    .bonus-timer{
      margin-bottom: 20px;
    }
    .bonus-sidebar__subtitle{
      background: #09203a;
      padding: 10px 15px;

      .bonus-subtitle__header{
        margin: 0;
      }

      small{
        color: #cccccc;
        font-size: 12px;
      }
    }
    @include xSmall{
      display: block;
      margin: 20px auto 0;
      width: 320px;
      max-width: 100%;
    }
  }
  .bonus-panel__title_alert{
    white-space: normal;
    margin-top: -6px;
  }
}
.tab-cashier__header_auto {
  height: auto;
}