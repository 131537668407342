.registration {
  display: block;
  position: relative;
  font-size: 0;

  @include xSmall {
    text-align: center;
  }

  &__image {
    width: 187px;
    height: 166px;
    background-size: 130px;
    background-repeat: no-repeat;
    background-position: center top;
    border-radius: 2px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer;

    @include xSmall {
      display: block;
      margin: 0 auto;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      transition: transform 0.2s ease;
    }
  }

  &__note {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__form {
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding-left: 22px;

    @include xSmall {
      padding-left: 0;
    }
  }

  &__input.input {
    margin-left: 18px;

    @include xSmall {
      margin-left: 0;
      margin-top: 20px;
      display: block;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__input-inner {
    width: 206px;
    height: 56px;
    background-color: #09203a;
    border-radius: 2px;
    border: none;
    box-shadow: inset 0 1px 0 #0d0c13, 0 1px 0 #3e6884;
    color: #ccc;
    font-size: 15px;
    font-weight: 500;
    padding: 0 21px;
    margin-top: 14px;
  }

  &__checkbox {
    display: block;
    position: relative;
    margin-top: 28px;

    @include xSmall {
      .checkbox__label {
        display: inline-block;
      }
    }

    .checkbox__label {
      display: inline-block;
    }
  }

  &__button {
    font-size: 18px;
    padding: 9px 18px;
    margin-top: 24px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  }
}