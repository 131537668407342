.levels-table {
  display: block;
  position: relative;
  margin: 0 auto;
  font-family: 'ClearSans', sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;


  &__table {
    display: table;
    width: 826px;
    margin: 0 auto;
    z-index: 1;

    @include Small {
      width: 594px;
    }

    @include xSmall {
      display: block;
      width: 100%;
    }
  }

  &__slider {
    position: absolute;
    top: 260px;
    left: 50%;
    transform: translateX(-50%);
    width: 860px;

    @include Small {
      top: 208px;
      width: 610px;
    }

    @include xSmall {
      display: none;
    }
  }

  &__slider-bar {
    width: 100%;
    height: 17px;
    // background-color: #09203a;
    border: 1px solid #457ea5;
    border-radius: 3px;
  }

  &__slider-inner {
    width: 30%;
    position: absolute;
    top: -1px;
    left: 0;
    height: 17px;
    background-color: #388ad6;
    background-image: linear-gradient(90deg,rgba(87,185,220,.29),#35d0ff 50%,rgba(87,185,220,.29));
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  }

  &__info {
    display: none;
    position: relative;
    height: 252px;
    border-radius: 3px;
    box-shadow: inset 0 -3px 0 rgba(0,0,0, 0.4), 0 3px 8px rgba(0, 0, 0, 0.19);
    background-color: #09203a;

    &.active {
      min-height: 258px;
      display: table!important;
      width: 100%;
    }

    @include Small {
      height: 280px;
    }

    @include xSmall {
      display: block;
      height: auto;

      &:after {
        content: " ";
        position: absolute;
        bottom: 100%;  /* At the top of the tooltip */
        left: 50%;
        margin-left: -7px;
        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent #09203a transparent;
      }
    }
  }

  &__status {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 234px;
    text-align: center;

    @include Small {
      width: 180px;
    }

    @include xSmall {
      display: block;
      position: absolute;
      top: 24px;
      left: 20px;
      text-align: left;
    }
  }

  &__status-inner {
    display: inline-block;
    vertical-align: top;

    @include xSmall {
      width: 48px;
      height: 45px;

      .status__icon {
        width: 30px;
        top: 1px;
      }

      .status__note {
        font-size: 11px;
        bottom: 3px;
      }
    }
  }

  &__item {
    display: table-cell;
    position: relative;
    width: calc(100% / 6);
    vertical-align: bottom;
    text-align: center;
    padding: 62px 10px 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include Small {
      padding: 62px 9px 0;
    }

    @include xSmall {
      display: block;
      width: 100%;
      padding: 87px 30px 30px;
      min-width: 250px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 60px;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(0,0,0, 0.14);
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 92%;
      background-image: linear-gradient(180deg,#132e4d,#52baff 50%,#132e4d);

      @include xSmall {
        height: 100%;
        top: 60px;
        background-color: rgba(0,0,0, 0.14);
        background-image: none;
      }
    }
    &:first-child {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 92%;
        background-image: linear-gradient(180deg,#132e4d,#52baff 50%,#132e4d);

        @include xSmall {
          height: 100%;
          top: 54px;
          background-color: rgba(0,0,0, 0.14);
          background-image: none;
        }
      }

    }
  }

  &__icon {
    display: block;
    position: relative;
    margin: 31px auto 0;
    width: 92px;

    @include Small {
      width: 30px;
    }

    @include xSmall {
      float: right;
      margin: 0;
      width: 30px;
    }
  }

  &__arrow {
    display: block;
    position: relative;
    margin-top: 30px;
    opacity: 0;

    @include xSmall {
      display: none;
    }

    &_active {
      opacity: 1;
    }

    &:after {
      content: " ";
      position: absolute;
      bottom: 100%;  /* At the top of the tooltip */
      left: 50%;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent #09203a transparent;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 22px;
    margin: 0;

    @include xSmall {
      padding-left: 65px;
    }

    &_small {
      font-size: 18px;
      line-height: 20px;
      @include Small {
        font-size: 15px;
      }
      @include xSmall {
        padding-top: 2px;
        float: left;
        padding-left: 0;
      }
    }

    &_accent {
      color: #ffde00;

      @include xSmall {
        min-height: 50px;
      }
    }
  }

  &__caption {
    font-size: 14px;
    line-height: 18px;
    color: #9c9c9c;
    white-space: nowrap;
    margin-top: 104px;
    display: block;

    @include Small {
      white-space: normal;
    }

    @include xSmall {
      margin-top: 30px;
      display: block;
      float: left;
      clear: left;
    }

  }

  &__note {
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    margin: 16px 0 0 0;
  }

  &__summary {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    padding-right: 37px;
    padding-top: 25px;
    padding-bottom: 20px;

    @include xSmall {
      padding: 20px;
      display: block;
    }
  }

  &__ratenote {
    font-size: 15px;
    padding: 18px 0 6px;
    line-height: 20px;
    color: #fff;
    // background-color: #09203a;
    white-space: nowrap;
    display: block;
    position: absolute;
    top: 205px;
    right: 0;
    z-index: 2;
    // -ms-transform: translateX(-50%);
    // transform: translateX(50%);

    @include Small {
      top: 158px;
    }

    @include xSmall {
      display: none;
    }

    &_zero {
      right: auto;
      left: 8px;
      // -ms-transform: translateX(-50%);
      // transform: translateX(-50%);
    }
  }

  &__link {
    font-weight: 400;
    text-decoration: none;
    color: #858585;
    display: block;
    margin-top: 14px;

    @include xSmall {
      display: none;
    }
  }

  &__viewrate {
    display: block;
    margin: 16px auto 0;
    width: 70px;
    height: 31px;
    padding: 6px 0;
    text-align: center;
    background-color: #09203a;
    background-image: linear-gradient(180deg,#09233f,#0d2f57);
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

    @include xSmall {
      margin: 22px auto 0;
      float: right;
      clear: right;
    }

  }

  &__ratestat {
    display: none;
    width: auto;
    text-align: left;
    clear: both;

    @include xSmall {
      display: block;
    }

  }

}

.levels-table__item_active, .slick-current {

  .levels-table__caption {
    color: #fff;
  }

  .levels-table__viewrate {
    background-color: #3687d6;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
  }

  .levels-table__link {
    color: #fff;
  }
}