.tab-bonuses {
  display: block;
  position: relative;

  &__gallery {
    //padding: 40px 32px 60px;
    padding: 40px 32px 0;
    position: relative;

    @include xSmall {
      padding: 20px;
    }
  }

  &__item {
    margin-bottom: 26px;
  }
}