.registration-v5 {
  display: block;
  position: relative;

  &__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase;


    &_large {
      font-size: 22px;
    }
  }

  &__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between;

    @include xSmall {
      flex-direction: column;
      margin-top: 16px;
    }

  }

  &__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    text-align: right;

    @include Small {
      //padding-right: 33px;
    }

    @include xSmall {
      border-right: none;
      padding-right: 0;
    }
  }

  &__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px;

    @include Small {
      padding-left: 23px;
    }

    @include xSmall {
      border-right: none;
      padding-left: 0;
    }
  }

  &__figure {
    width: 360px;
    position: absolute;
    left: 95px;
    //height: 354px;
    //border-radius: 10px;
    //background-color: #435182;
    //box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.63);
    //margin: 0 auto;
    //position: relative;

    & table {
      border-spacing:0 8px;

      @include xSmall {
        padding-left: 10px;
      }
      & td:nth-child(2) {
        padding-left: 10px;
        text-align: left;
        font-family: 'ClearSans';
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        @include xSmall {
          font-size: 14px;
        }
      }
    }

    @include Small {
      width: 275px;
      height: 349px;
      left: 60px;
    }
    @include xSmall {
      left: auto;
      width: 300px;
      position: relative;
      display:block;
      margin: 0 auto 25px auto;
      font-size: 0;
    }

  }

  &__figure-img {
    width: 45px;
    margin-top: 8px;
    margin-left: 25px;
    margin-right: 10px;
    display: block;
    object-fit: contain;
    float: left;
    //@include Small {
    //  width: 165px;
    //  height: auto;
    //  left: calc(50% - (165px/2));
    //}

    @include xSmall {
      width: 40px;
      height: auto;
      margin-left: 10px;
    }
  }

  &__figure-info {
    width: 243px;
    height: 62px;
    border-radius: 4px;
    background-color: #333157;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.65);
    margin: 0 auto;
    @include Small {
      width: 220px;
    }

    @include xSmall {
      width: 140px;
      height: 57px;
    }
  }

  &__figure-container {
    margin-top: 20px;
    margin-right: 37px;
    display: flex;
    flex-direction: column;
    @include Small {
      margin-right: 0;
    }
    @include xSmall {
      flex-direction: row;
      margin-right: 0;
      margin-top: 10px;
    }
  }

  &__figure-caption {
    //display: block;
    //padding: 6px 10px 17px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.76);
    font-size: 22px;
    font-weight: bold;
    color: #ffb824;
    margin-top: 8px;
    text-align: left;
    font-size: 22px;
    @include Small {
      font-size: 20px;
    }
    @include xSmall {
      font-size: 18px;
      margin-top: 1px;
    }
  }

  &__figure-note {
    font-family: 'ClearSans';
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    //padding:0;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5);
    @include Small {
      font-size: 12px;
    }
    @include xSmall {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__counter {
    width: 243px;
    height: 62px;
    border-radius: 4px;
    background-color: #333157;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.65);
    margin: 3px auto;
    @include Small {
      width: 220px;
    }
    @include xSmall {
      width: 140px;
      height: 57px;
      margin-left: 3px;
      margin-top: 0;
    }
  }



  &__cross {
    width: 31px;
    height: 30px;
    background-color: #58567c;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.45);
    border: solid 3px #3a3859;
    border-radius: 50%;
    position: absolute;
    top: 252px;
    left: 25px;
    @include Small {
      top: 265px;
      left: 12px;
    }

    @include xSmall {
      top: 195px;
      left: 133px;
    }

    &:after {
      content: '+';
      font-size: 25px;
      font-weight: bold;
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.34);
      font-family: 'ClearSans';
      color: #ffffff;
      position: absolute;
      top: -4px;
      left: 6px;
    }
  }

  &__form {
    width: 330px;

    @include Small {
      width: 300px;
    }

    @include xSmall {
      width: 260px;
      margin: 0 auto;

    }
  }

  &__input {
    display: block;
    margin-bottom: 16px;
  }



  &__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px;
  }

  &__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(253,99,36,.7);
    }

    @include xSmall {
      width: 260px;
      margin: 18px auto 0;
    }
  }

  &__socials {
    margin: 24px auto 0;
  }

  &__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px;
  }


}