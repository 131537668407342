.filter {
  display: block;
  position: relative;
  text-align: left;
  margin: 0 auto;
  height: 58px;
  padding: 11px 11px 12px 11px;
  background-color: #4d4b63;
  border-radius: 1px;
  font-size: 0;

  .history__filter & {
    width: 600px;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    background: #6d6c84;
    &:first-child {
      width: 80px;
      margin-right: 9px;
    }
    .filter__title + &:first-child {
      margin-right: 0;
    }
    .filter__item + &:nth-child(2n + 1) {
      margin-right: 9px;
    }
    .filter__item + &:nth-child(2) {
      width: 91px;
      margin-right: 30px;
    }
  }

  &__button {
    display: block;
    position: absolute;
    top: 11px;
    right: 11px;
    padding: 7px 14px;
    border-radius: 1px;
    font-family: 'ClearSans', sans-serif;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.18), 0 8px 10px rgba(0, 0, 0, 0.16);

    &_fortune {
      padding: 9px 14px;
      font-size: 15px;
      font-family: 'ClearSans', sans-serif;
    }

  }

  &__select {
    border-radius: 0;
    background: #6d6c84;
    height: 34px;
    padding: 8px 30px 8px 15px;
    border: none;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;

    option[disabled] {
      display: none;
    }
  }


  &__date {
    display: block;
    position: relative;

    input[placeholder]          {color: #fff}
    input::-moz-placeholder     {color: #fff}
    input:-moz-placeholder      {color: #fff}
    input:-ms-input-placeholder {color: #fff}

  }

  &__date-inner {
    display: block;
    position: relative;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    width: 91px;
    background-color: #6d6c84;
    border-radius: 1px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
    border: none;
    padding: 9px 0;
    text-align: center;
  }

  &__title {
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    padding: 9px 12px 6px;
  }

}