.ticket {
  display: block;
  position: relative;
  width: 100%;
  font-size: 0;
  height: 144px;
  background-color: #29476a;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07), 0 5px 5px rgba(0, 0, 0, 0.12);

  &__action {
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 100%;
    padding: 26px 25px 30px 26px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #223c59;
      box-shadow: 1px 0 0 #355478;
    }
  }

  &__summary {
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07), 0 5px 5px rgba(0, 0, 0, 0.12);
    width: 260px;
    height: 100%;
    background-color: #29476a;
    background-image: url('../img/timer-bg.png');
    background-position: center;
    background-repeat: no-repeat;
  }

  &__head {
    display: block;
    position: relative;
  }

  &__title {
    display: inline-block;
    vertical-align: top;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1px 10px 2px 3px;
  }

  &__info {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    position: relative;
    cursor: pointer;
    padding: 4px 0 0 0;
    text-align: center;
    width: 21px;
    height: 21px;
    font-size: 0;
    border-radius: 50%;
    background-color: #4ea1e1;
    box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.11),0 0 16px rgba(0,0,0,.26),0 8px 10px rgba(0,0,0,.16);
  }

  &__tooltip {
    min-width: 191px;
    right: 0;
    margin-right: 11px;
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    top: calc(100% + 23px);
    opacity: 0;
    visibility: hidden;

    .ticket__info:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__exchange {
    display: block;
    position: relative;
    white-space: nowrap;
    margin-top: 22px;
  }

  &__input {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 148px;

    &_withbutton {
      width: 190px;

      .input__inner {
        padding: 17px 63px 17px 14px;
      }
    }

    .input__inner {
      width: 100%;
      height: 44px;
      background-color: #1a3350;
      border-radius: 2px;
      box-shadow: inset 0 1px 0 #152a42, 0 1px 0 #33567f;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__button {
    width: 49px;
    height: 44px;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;
  }

  &__icon {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 11px 18px 11px 21px;
  }

  &__caption{
    display: block;
    position: relative;
    text-align: center;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    text-transform: uppercase;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 44px auto 0;

    &_accent {
      color: #ffde00;
      font-size: 35px;
      font-weight: 700;
      margin: 14px auto 0;
    }
  }

}