.tournament-details {
  display: block;
  position: relative;

  &__header {
    display: block;
    position: relative;
    height: 238px;
    @include  xSmall {
      height: auto;
      min-height: 182px;
      margin-bottom: 15px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 797px;
      width: 100%;
      height: 165px;
      opacity: 0.29;
      background-image: radial-gradient(380px 210px at 50% 163.64%, white 0%, transparent 100%);
      border-bottom: 1px solid #224061;
    }
  }

  &__summary {
    position: relative;
    max-width: 1140px;
    margin: 0 auto 20px;
    @include Small{
      max-width: 750px;
    }
    @include xSmall{
      max-width: 600px;
    }
  }

  &__crosstitle {
    display: block;
    position: relative;
    padding: 55px 100px 51px;

    @include xSmall {
      padding: 55px 20px 51px;
    }

    &_small {
      padding: 33px 100px 30px;

      @include xSmall {
        padding: 33px 20px 30px;
      }
    }
    .leaderboard &{
      border-bottom: 1px solid #036;
      padding: 17px 0;
      margin-bottom: 4px;
      font-size: 21px;
      font-weight: 100;
      span{
        color: #8aadee;
      }
    }
  }

  &__leaderboard {
    position: relative;
    max-width: 1140px;
    margin: 0 auto;
  }

  &__slider {
    width: 960px;
    margin: 0 auto;

    @include Medium {
      width: 800px;
    }

    @include Small {
      width: 630px;
    }

    @include xSmall {
      width: calc(100% - 40px);
      max-width: 480px;
    }

    .slider__item {
      height: 104px;
      overflow: hidden;
      margin: 0 10px;

      @include xSmall {
        margin: 0 5px;
      }

      .slider__img {
        height: 55px;
        width: 81px;

        @include xSmall {
          width: 81px;
          height: 55px;
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  &__button {
    padding: 9px 27px;
    margin-top: 22px;
  }
  &__header_top {
    @include xSmall {
      height: 80px;
      padding-top: 20px;
      &:before {
        content: '';
        display: inline-block;
        width: 1px;
        margin-left: -1px;
        font-size: 0;
        height: 80px;
        vertical-align: middle;
      }
    }
  }
  &__title {
    display: block;
    position: relative;
    line-height: 1.2;
    @include Medium{
      line-height: 1.2;
      font-size: 22px;
    }
    @include xSmall {
      font-size: 16px;
      padding-top: 0;
      display: inline-block;
      vertical-align: middle;
      width: calc(99% - 1px);
      line-height: 25px;
    }
  }

  &__subtitle {
    padding-top: 22px;
    display: block;
    position: relative;
    font-size: 20px;
    font-weight: 100;
    color: #8aadee;
  }

  &__countdown {
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;
    position: relative;
    @include xSmall {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,0);
      margin-top: 0;
      white-space: nowrap;
    }
  }

  &__currency {
    display: block;
    position: absolute;
    bottom: 0;
    right: -48px;
    font-size: 56px;
    line-height: 44px;
    font-weight: 500;
    color: #fff;

    @include xSmall {
      display: none;
    }

    &_ruble {
      @include sprite($ruble-currency-sign);
      bottom: 10px;
    }

    &_dollar {
      right: -40px;
      font-size: 47px;
    }
  }

  &__prizes-sum{
    margin: 0;
    font-size: 42px;

    @include Medium{
      font-size: 33px;
    }
    @include xSmall{
      font-size: 24px;
    }

    span{
      color: #fff;
    }
  }
}