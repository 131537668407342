.main-nav {
  display: table;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  width: 100%;
  background-color: #09203a;
  border-radius: 5px;
  overflow: hidden;

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    height: 2px;
    background-image: linear-gradient(90deg,rgba(87, 185, 220, 0.3),#35d0ff 50%,rgba(87, 185, 220, 0.3));
    box-shadow: -2px 10px 61px 7px #3fc8fa;
  }

  @media screen and (max-width: 430px) {
    display: block;
    white-space: nowrap;
  }


  &__subnav.subnav {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;

    @include xSmall {
      display: block;
      height: auto;
      width: 100vw;
      max-width: 480px;
      padding: 5px;
    }

    .subnav__item {
      @include xSmall {
        display: inline-block;
        vertical-align: top;
        width: auto;
      }
    }

    .hero__nav_sticky & {
      top: 100%;
      opacity: 0;
      visibility: hidden;
    }

    .main-nav__item_subnav:hover & {
      top: 100%;
      opacity: 1;
      visibility: visible;
    }

    .hero__nav_sticky .main-nav__item_active & {
      top: 100%;
      opacity: 1;
      visibility: visible;
    }

  }

  &__item {
    display: table-cell;
    vertical-align: top;
    width: auto;
    white-space: normal;

    @media screen and (max-width: 430px) {
      display: inline-block;
    }

    &:after {
      content: '';
      font-size: 18px;
      color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      //right: 95%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    &:first-child:after {
      content: none;
    }

    &_active:after, &_active + &:after {
      content: none;
    }

  }


  &__link {
    display: block;
    position: relative;
    text-align: center;
    padding: 22px 36px;
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.19);
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    z-index: 2;
    height: 55px;

    &:hover {
      color: $yellow;

      .main-nav__img{
        background-image: url("../img/nav_img/sprite_active.png");
      }

      &:after {
        color: #fff;
      }
    }

    .main-nav__item:first-child & {
      padding-top: 22px;
      padding-bottom: 43px;
    }

    .main-nav__item_active & {
      color: yellow;

      &:after {
        content: none;
      }

      
      .main-nav__img{
        background-image: url("../img/nav_img/sprite_active.png");
      }
      
    }

    // .main-nav__item:first-child.main-nav__item_active & {
    //   box-shadow: inset 0 -4px 0 rgba(4, 4, 4, 0.27), 3px 0 0 rgba(4, 4, 4, 0.37);
    // }

    // .main-nav__item:last-child.main-nav__item_active & {
    //   box-shadow: inset 0 -4px 0 rgba(4, 4, 4, 0.27), -3px 0 0 rgba(4, 4, 4, 0.37);
    // }

    @include xLarge {
      padding-bottom: 43px;
      padding-left: 50px;
    }
    @include Large {
      padding-bottom: 43px;
      padding-left: 70px;
    }
    @include Medium {
      padding-bottom: 43px;
      padding-left: 47px;
    }

    @include Small {
      font-size: 14px;
      padding: 21px 25px;
      padding-bottom: 41px;
    }

    @include xSmall {

      .main-nav__item & {
        padding: 16px 8px;
        font-size: 12px;
      }

      .main-nav__item:first-child & {
        padding: 16px 8px;
        font-size: 12px;
      }

    }

    // &__like:hover{
    //   .main-nav__img__like{
    //     background-image: url("../img/nav_img/popular_icon_a.png");
    //   }
    // }
    // &__slots:hover, &.active {
    //   .main-nav__img__slots{
    //     background-image: url("../img/nav_img/slots_icon_a.png");
    //   }
    // }
    // &__jackpot:hover{
    //   .main-nav__img__jackpot{
    //     background-image: url("../img/nav_img/jackpots_icon_a.png");
    //   }
    // }
    // &__bord:hover{
    //   .main-nav__img__bord{
    //     background-image: url("../img/nav_img/table_icon_a.png");
    //   }
    // }
    // &__fav:hover{
    //   .main-nav__img__fav{
    //     background-image: url("../img/nav_img/fav_icon_a.png");
    //   }
    // }

  }

  &__img{
      display: inline-block;
      background-image: url("../img/nav_img/sprite_inactive.png");
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: cover;
      text-indent: -9999px;
      text-align: left;
      position: absolute;
      top: 4px;  
      left: 23px;
      width: 79px;
      height: 53px;

      &__like {
        background-position: -2px -9px;
      }
  
      &__slots {
        background-position: -9px -298px;
      }
  
      &__jackpot {
        background-position: -10px -150px;
      }
  
      &__bord {
        background-position: -10px -223px; 
      }
  
      &__fav {
        background-position: -10px -78px;
      }
    
 
    @include Medium {
      left:0;
      width: 70px;
      &__like {
        background-position: -6px -6px;
      }
  
      &__slots {
        background-position: -10px -263px;
      }
  
      &__jackpot {
        background-position: -10px -130px;
      }
  
      &__bord {
        background-position: -10px -195px; 
      }
  
      &__fav {
        background-position: -10px -67px;
      }
    }

    @include Small {
      display: none;
    }

    @include xSmall {
      display: none;
    }
  }
}