.registration-v2 {
  display: block;
  position: relative;

  &__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase;


    &_large {
      font-size: 22px;
    }
  }

  &__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between;

    @include xSmall {
      flex-direction: column;
      margin-top: 16px;
    }

  }

  &__footer{
    background: #00142d;
    padding: 10px 0;
    position: relative;
    text-align: center;
    color: #353b58;
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: radial-gradient(ellipse at center,#00b1ec 0,transparent 70%);
      box-shadow: none;
      width: 100%;
      z-index: 0;
      opacity: 1;
    }
  }

  &__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    padding-right: 54px;
    text-align: right;

    @include Small {
      padding-right: 33px;
    }

    @include xSmall {
      border-right: none;
      padding-right: 0;
    }
  }

  &__inner-right {
    flex: 1 1 50%;
    // border-right: 2px solid #40658d;
    padding-left: 56px;

    @include Small {
      padding-left: 23px;
    }

    @include xSmall {
      border-right: none;
      padding-left: 0;
      margin-top: 30px;
    }
  }

  &__figure {
    display: inline-block;
    vertical-align: top;
    width: 245px;
    box-shadow: 0 6px 6px 0 rgba(0,0,0, 0.3);

    @include xSmall {
      box-shadow: inset 0px 0px 5px 3px rgba(255,255,255,1);
      border-radius: 5px;
      width: 282px;
      display:block;
      margin: 0 auto;
      font-size: 0;
    }
  }

  &__figure-img {
    display: block;
    height: 175px;
    object-fit: contain;
    opacity: 0.84;
    box-shadow: inset 0 4px 4px 0 rgba(255, 255, 255, 0.54), inset 0 -4px 3px 0 rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 1px 1px;
    width: 290px;
    @include xSmall {
      width: 141px;
      height: auto;
      display: inline-block;
      vertical-align: top;
      border-radius: 5px 0 0 5px;
      box-shadow: none;
    }
  }

  &__figure-info {
    background-color: #242333;
    box-shadow: inset 0 -2px 0 0 rgba(255, 255, 255, 0.12);
    border-radius: 0 0 4px 4px;
    @include xSmall {
      height: 101px;
      display: inline-block;
      width: 141px;
      background-color: #31304c;
      vertical-align: top;
      border-radius: 0 5px 5px 0;
      box-shadow: inset -4px 0px 6px 2px rgba(255,255,255,0.5);
    }
  }

  &__figure-title {
    display: block;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.71);
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 13px 10px 0;
    @include xSmall {
      font-size: 16px;
    }
  }

  &__figure-caption {
    display: block;
    margin: 0 auto;
    padding: 6px 10px 17px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #7f7ba0;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82);
    @include xSmall {
      padding: 12px 20px 17px;
    }
  }

  &__counter {
    display: inline-block;
    vertical-align: top;
    width: 245px;
    margin-top: 22px;

    @include xSmall {
      display:block;
      margin: 0 auto;
    }
  }



  &__counter-title {
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82);

    @include xSmall {
      display:block;
      margin: 16px auto 0 auto;
    }
  }

  &__counter-inner {
    width: 193px;
    height: 33px;
    opacity: 0.32;
    border-radius: 3px;
    margin: 10px auto 20px auto;
    box-shadow: inset 0 7px 4px 0 rgba(0, 0, 0, 0.86), inset 0 -7px 4px 0 rgba(0, 0, 0, 0.89);
  }

  &__form {
    width: 330px;
    margin: 0 auto;

    @include Small {
      width: 300px;
    }

    @include xSmall {
      width: 260px;
      margin: 0 auto;
    }
  }

  &__input {
    display: block;
    margin-bottom: 16px;
    @include xSmall {

    }
  }
  &__checkbox{
    display: block;
    text-align: left;

    .checkbox__label{
      color: rgb(242, 166, 57);
    }

    .checkbox__inner:checked+.checkbox__label:after {
      content: "";
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      bottom: 4px;
      left: 4px;
      background: url(../img/checkbox.png) center center no-repeat;
    }

    .checkbox__inner+.checkbox__label:before {
      width: 25px;
      height: 25px;
      line-height: 25px;
      background: #ffe500;
      box-shadow: none;
      border: none;
      border-radius: 20px;
    }
    .checkbox__inner:checked+.checkbox__label:before {
      width: 25px;
      height: 25px;
      line-height: 25px;
      background: #ffe500;
      box-shadow: none;
      border: none;
      border-radius: 20px;
    }

  }
  &__input-inner {
    width: 100%;
    height: 56px;
    border: none;
    color: #ccc;
    font-weight: 700;
    padding: 0 17px;
    border-radius: 2px;
    background-color: rgb(7, 9, 41);
    box-shadow: 0 0 1px 0 rgba(0, 150, 255, 1);
  }

  &__button {
    display: block;
    margin: 24px auto 0;
    height: 56px;
    font-size: 22px;
    border-radius: 3px;
    background-image: linear-gradient(180deg,#ffea00,#ff9400);
    box-shadow: 0 0 16px 1px #ffd961bf;
    color: #8e231f;
    border: 1px solid #f59125;
    width: 100%;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(253,99,36,.7);
    }

    @include xSmall {
      width: 260px;
      margin: 18px auto 0;
    }
  }

  &__socials {
    margin: 24px auto 0;
  }

  &__socials-title {
    font-size: 16px;
    font-weight: 500;
    color: #353b58;
    text-align: center;
    margin: 0 auto 16px;
  }

  .email-input-reg{
    position: relative;
    .tooltip{
      opacity: 0;
      visibility: hidden;
    }
    &:hover .tooltip{
      opacity: 1;
      visibility: visible;
      top: -72px;
      left: 0;
      width: 330px;
      max-width: none;
      background-color: rgba(255, 127, 0, 0.9);
      color: #000;
      border: 0;
      box-shadow: none;
    }
    .tooltip__arrow{
      top: auto;
      bottom: -30px;
      left: 30px;
      &:after{
        border-color:  rgba(255, 127, 0, 0.9) transparent transparent;
        border-width: 15px 15px 0 15px;
        bottom: 15px;
      }
    }
  }
  .socials_form .socials__item {
    background-color: rgb(5, 22, 92);
    border: 1px solid rgb(2, 39, 95);
    border-radius: 2px;
    box-shadow: inset 0 0 6px 1px #112443;
    width: 52px;
    height: 52px;
    @include Small {
      width: 48px;
      height: 48px;
    }
    @include xSmall {
      width: 40px;
      height: 40px;
    }
  }
}