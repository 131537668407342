.signup {
  position: relative;
  display: block;
  // width: 170px;
  height: 43px;
  margin-top: 2px;

  .modal__signup & {
    @include xSmall {
      margin: 0 auto;
    }
  }

  @include Medium {
    width: auto;
  }

  @include Small {
    width: auto;
    height: 36px;
  }

  @include xSmall {
    width: auto;
  }

  &__button {
    // position: absolute;
    font-size: 16px;
    line-height: 16px;
    padding: 14px 9px;
    top: 0;
    left: 0;
    background-color: #e2d533;
    background-image: linear-gradient(180deg,#ff8d00,#ada434);
    z-index: 2;
    min-width: 170px;
    box-shadow: 0 2px 0 rgba(0,0,0,.11);

    @include Medium {
      position: relative;
    }

    @include Small {
      position: relative;
      padding: 10px 9px;
    }

    @include xSmall {
      .head-panel__signup & {
        position: relative;
      }

    }
  }

  &__input {

    @include Medium {
      display: none;
    }

    @include Small {
      display: none;
    }

    @include xSmall {
      .head-panel__signup & {
        display: none;
      }
    }

    .input__inner {
      padding-left: 148px;
      padding-right: 15px;
    }
  }
}