.status {
  width: 48px;
  height: 45px;
  // background-color: #3c5b80;
  // background-image: linear-gradient(140deg, #4ea8db 0%, #236dd2 100%);
  // border-radius: 3px;
  // box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  text-align: center;
  position: relative;
  display: block;

  &__icon {
    margin-top: 8px;
    position: relative;
  }

  &__note {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.18);
    color: #fefefe;
    font-family: 'ClearSans', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin-top: 1px;
  }
}

.status_huge {
  width: 114px;
  height: 117px;

  .status__icon {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    text-align: center;
    width: 92px;
  }

  .status__note {
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    bottom: 9px;
    left: 0;
    right: 0;
    display: block;
    position: absolute;
  }

}