// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$alfabank-name: 'alfabank';
$alfabank-x: 899px;
$alfabank-y: 653px;
$alfabank-offset-x: -899px;
$alfabank-offset-y: -653px;
$alfabank-width: 78px;
$alfabank-height: 28px;
$alfabank-total-width: 991px;
$alfabank-total-height: 923px;
$alfabank-image: '../img/sprite.png?v=2';
$alfabank: (899px, 653px, -899px, -653px, 78px, 28px, 991px, 923px, '../img/sprite.png?v=2', 'alfabank', );
$arrow-down-white-name: 'arrow-down-white';
$arrow-down-white-x: 434px;
$arrow-down-white-y: 438px;
$arrow-down-white-offset-x: -434px;
$arrow-down-white-offset-y: -438px;
$arrow-down-white-width: 12px;
$arrow-down-white-height: 8px;
$arrow-down-white-total-width: 991px;
$arrow-down-white-total-height: 923px;
$arrow-down-white-image: '../img/sprite.png?v=2';
$arrow-down-white: (434px, 438px, -434px, -438px, 12px, 8px, 991px, 923px, '../img/sprite.png?v=2', 'arrow-down-white', );
$arrow-down-name: 'arrow-down';
$arrow-down-x: 852px;
$arrow-down-y: 611px;
$arrow-down-offset-x: -852px;
$arrow-down-offset-y: -611px;
$arrow-down-width: 16px;
$arrow-down-height: 11px;
$arrow-down-total-width: 991px;
$arrow-down-total-height: 923px;
$arrow-down-image: '../img/sprite.png?v=2';
$arrow-down: (852px, 611px, -852px, -611px, 16px, 11px, 991px, 923px, '../img/sprite.png?v=2', 'arrow-down', );
$arrow-right-white-name: 'arrow-right-white';
$arrow-right-white-x: 852px;
$arrow-right-white-y: 671px;
$arrow-right-white-offset-x: -852px;
$arrow-right-white-offset-y: -671px;
$arrow-right-white-width: 14px;
$arrow-right-white-height: 12px;
$arrow-right-white-total-width: 991px;
$arrow-right-white-total-height: 923px;
$arrow-right-white-image: '../img/sprite.png?v=2';
$arrow-right-white: (852px, 671px, -852px, -671px, 14px, 12px, 991px, 923px, '../img/sprite.png?v=2', 'arrow-right-white', );
$arrow-right-name: 'arrow-right';
$arrow-right-x: 452px;
$arrow-right-y: 378px;
$arrow-right-offset-x: -452px;
$arrow-right-offset-y: -378px;
$arrow-right-width: 14px;
$arrow-right-height: 10px;
$arrow-right-total-width: 991px;
$arrow-right-total-height: 923px;
$arrow-right-image: '../img/sprite.png?v=2';
$arrow-right: (452px, 378px, -452px, -378px, 14px, 10px, 991px, 923px, '../img/sprite.png?v=2', 'arrow-right', );
$arrow-up-white-name: 'arrow-up-white';
$arrow-up-white-x: 392px;
$arrow-up-white-y: 438px;
$arrow-up-white-offset-x: -392px;
$arrow-up-white-offset-y: -438px;
$arrow-up-white-width: 12px;
$arrow-up-white-height: 8px;
$arrow-up-white-total-width: 991px;
$arrow-up-white-total-height: 923px;
$arrow-up-white-image: '../img/sprite.png?v=2';
$arrow-up-white: (392px, 438px, -392px, -438px, 12px, 8px, 991px, 923px, '../img/sprite.png?v=2', 'arrow-up-white', );
$bitcoin-name: 'bitcoin';
$bitcoin-x: 516px;
$bitcoin-y: 612px;
$bitcoin-offset-x: -516px;
$bitcoin-offset-y: -612px;
$bitcoin-width: 142px;
$bitcoin-height: 30px;
$bitcoin-total-width: 991px;
$bitcoin-total-height: 923px;
$bitcoin-image: '../img/sprite.png?v=2';
$bitcoin: (516px, 612px, -516px, -612px, 142px, 30px, 991px, 923px, '../img/sprite.png?v=2', 'bitcoin', );
$bonus-4-name: 'bonus-4';
$bonus-4-x: 252px;
$bonus-4-y: 0px;
$bonus-4-offset-x: -252px;
$bonus-4-offset-y: 0px;
$bonus-4-width: 216px;
$bonus-4-height: 178px;
$bonus-4-total-width: 991px;
$bonus-4-total-height: 923px;
$bonus-4-image: '../img/sprite.png?v=2';
$bonus-4: (252px, 0px, -252px, 0px, 216px, 178px, 991px, 923px, '../img/sprite.png?v=2', 'bonus-4', );
$bonus-small-1-name: 'bonus-small-1';
$bonus-small-1-x: 252px;
$bonus-small-1-y: 208px;
$bonus-small-1-offset-x: -252px;
$bonus-small-1-offset-y: -208px;
$bonus-small-1-width: 181px;
$bonus-small-1-height: 140px;
$bonus-small-1-total-width: 991px;
$bonus-small-1-total-height: 923px;
$bonus-small-1-image: '../img/sprite.png?v=2';
$bonus-small-1: (252px, 208px, -252px, -208px, 181px, 140px, 991px, 923px, '../img/sprite.png?v=2', 'bonus-small-1', );
$bonus-tiny-1-name: 'bonus-tiny-1';
$bonus-tiny-1-x: 244px;
$bonus-tiny-1-y: 490px;
$bonus-tiny-1-offset-x: -244px;
$bonus-tiny-1-offset-y: -490px;
$bonus-tiny-1-width: 97px;
$bonus-tiny-1-height: 83px;
$bonus-tiny-1-total-width: 991px;
$bonus-tiny-1-total-height: 923px;
$bonus-tiny-1-image: '../img/sprite.png?v=2';
$bonus-tiny-1: (244px, 490px, -244px, -490px, 97px, 83px, 991px, 923px, '../img/sprite.png?v=2', 'bonus-tiny-1', );
$cancel-name: 'cancel';
$cancel-x: 0px;
$cancel-y: 904px;
$cancel-offset-x: 0px;
$cancel-offset-y: -904px;
$cancel-width: 18px;
$cancel-height: 19px;
$cancel-total-width: 991px;
$cancel-total-height: 923px;
$cancel-image: '../img/sprite.png?v=2';
$cancel: (0px, 904px, 0px, -904px, 18px, 19px, 991px, 923px, '../img/sprite.png?v=2', 'cancel', );
$checkmark-name: 'checkmark';
$checkmark-x: 855px;
$checkmark-y: 553px;
$checkmark-offset-x: -855px;
$checkmark-offset-y: -553px;
$checkmark-width: 13px;
$checkmark-height: 13px;
$checkmark-total-width: 991px;
$checkmark-total-height: 923px;
$checkmark-image: '../img/sprite.png?v=2';
$checkmark: (855px, 553px, -855px, -553px, 13px, 13px, 991px, 923px, '../img/sprite.png?v=2', 'checkmark', );
$clock-name: 'clock';
$clock-x: 768px;
$clock-y: 778px;
$clock-offset-x: -768px;
$clock-offset-y: -778px;
$clock-width: 20px;
$clock-height: 20px;
$clock-total-width: 991px;
$clock-total-height: 923px;
$clock-image: '../img/sprite.png?v=2';
$clock: (768px, 778px, -768px, -778px, 20px, 20px, 991px, 923px, '../img/sprite.png?v=2', 'clock', );
$comepay-name: 'comepay';
$comepay-x: 792px;
$comepay-y: 0px;
$comepay-offset-x: -792px;
$comepay-offset-y: 0px;
$comepay-width: 77px;
$comepay-height: 70px;
$comepay-total-width: 991px;
$comepay-total-height: 923px;
$comepay-image: '../img/sprite.png?v=2';
$comepay: (792px, 0px, -792px, 0px, 77px, 70px, 991px, 923px, '../img/sprite.png?v=2', 'comepay', );
$contact-name: 'contact';
$contact-x: 252px;
$contact-y: 378px;
$contact-offset-x: -252px;
$contact-offset-y: -378px;
$contact-width: 110px;
$contact-height: 70px;
$contact-total-width: 991px;
$contact-total-height: 923px;
$contact-image: '../img/sprite.png?v=2';
$contact: (252px, 378px, -252px, -378px, 110px, 70px, 991px, 923px, '../img/sprite.png?v=2', 'contact', );
$counter-bg-dark-name: 'counter-bg-dark';
$counter-bg-dark-x: 792px;
$counter-bg-dark-y: 100px;
$counter-bg-dark-offset-x: -792px;
$counter-bg-dark-offset-y: -100px;
$counter-bg-dark-width: 32px;
$counter-bg-dark-height: 44px;
$counter-bg-dark-total-width: 991px;
$counter-bg-dark-total-height: 923px;
$counter-bg-dark-image: '../img/sprite.png?v=2';
$counter-bg-dark: (792px, 100px, -792px, -100px, 32px, 44px, 991px, 923px, '../img/sprite.png?v=2', 'counter-bg-dark', );
$counter-bg-name: 'counter-bg';
$counter-bg-x: 899px;
$counter-bg-y: 354px;
$counter-bg-offset-x: -899px;
$counter-bg-offset-y: -354px;
$counter-bg-width: 50px;
$counter-bg-height: 64px;
$counter-bg-total-width: 991px;
$counter-bg-total-height: 923px;
$counter-bg-image: '../img/sprite.png?v=2';
$counter-bg: (899px, 354px, -899px, -354px, 50px, 64px, 991px, 923px, '../img/sprite.png?v=2', 'counter-bg', );
$counter-name: 'counter';
$counter-x: 792px;
$counter-y: 174px;
$counter-offset-x: -792px;
$counter-offset-y: -174px;
$counter-width: 32px;
$counter-height: 44px;
$counter-total-width: 991px;
$counter-total-height: 923px;
$counter-image: '../img/sprite.png?v=2';
$counter: (792px, 174px, -792px, -174px, 32px, 44px, 991px, 923px, '../img/sprite.png?v=2', 'counter', );
$cross-bold-name: 'cross-bold';
$cross-bold-x: 854px;
$cross-bold-y: 174px;
$cross-bold-offset-x: -854px;
$cross-bold-offset-y: -174px;
$cross-bold-width: 15px;
$cross-bold-height: 15px;
$cross-bold-total-width: 991px;
$cross-bold-total-height: 923px;
$cross-bold-image: '../img/sprite.png?v=2';
$cross-bold: (854px, 174px, -854px, -174px, 15px, 15px, 991px, 923px, '../img/sprite.png?v=2', 'cross-bold', );
$cross-bold-grey-name: 'cross-bold_grey';
$cross-bold-grey-x: 853px;
$cross-bold-grey-y: 424px;
$cross-bold-grey-offset-x: -853px;
$cross-bold-grey-offset-y: -424px;
$cross-bold-grey-width: 15px;
$cross-bold-grey-height: 15px;
$cross-bold-grey-total-width: 991px;
$cross-bold-grey-total-height: 923px;
$cross-bold-grey-image: '../img/sprite.png?v=2';
$cross-bold-grey: (853px, 424px, -853px, -424px, 15px, 15px, 991px, 923px, '../img/sprite.png?v=2', 'cross-bold_grey', );
$cup-name: 'cup';
$cup-x: 899px;
$cup-y: 569px;
$cup-offset-x: -899px;
$cup-offset-y: -569px;
$cup-width: 48px;
$cup-height: 54px;
$cup-total-width: 991px;
$cup-total-height: 923px;
$cup-image: '../img/sprite.png?v=2';
$cup: (899px, 569px, -899px, -569px, 48px, 54px, 991px, 923px, '../img/sprite.png?v=2', 'cup', );
$curagao-name: 'curagao';
$curagao-x: 792px;
$curagao-y: 424px;
$curagao-offset-x: -792px;
$curagao-offset-y: -424px;
$curagao-width: 31px;
$curagao-height: 36px;
$curagao-total-width: 991px;
$curagao-total-height: 923px;
$curagao-image: '../img/sprite.png?v=2';
$curagao: (792px, 424px, -792px, -424px, 31px, 36px, 991px, 923px, '../img/sprite.png?v=2', 'curagao', );
$ecogra-name: 'ecogra';
$ecogra-x: 899px;
$ecogra-y: 0px;
$ecogra-offset-x: -899px;
$ecogra-offset-y: 0px;
$ecogra-width: 92px;
$ecogra-height: 36px;
$ecogra-total-width: 991px;
$ecogra-total-height: 923px;
$ecogra-image: '../img/sprite.png?v=2';
$ecogra: (899px, 0px, -899px, 0px, 92px, 36px, 991px, 923px, '../img/sprite.png?v=2', 'ecogra', );
$exit-name: 'exit';
$exit-x: 562px;
$exit-y: 778px;
$exit-offset-x: -562px;
$exit-offset-y: -778px;
$exit-width: 22px;
$exit-height: 22px;
$exit-total-width: 991px;
$exit-total-height: 923px;
$exit-image: '../img/sprite.png?v=2';
$exit: (562px, 778px, -562px, -778px, 22px, 22px, 991px, 923px, '../img/sprite.png?v=2', 'exit', );
$facebook-small-name: 'facebook-small';
$facebook-small-x: 858px;
$facebook-small-y: 248px;
$facebook-small-offset-x: -858px;
$facebook-small-offset-y: -248px;
$facebook-small-width: 9px;
$facebook-small-height: 20px;
$facebook-small-total-width: 991px;
$facebook-small-total-height: 923px;
$facebook-small-image: '../img/sprite.png?v=2';
$facebook-small: (858px, 248px, -858px, -248px, 9px, 20px, 991px, 923px, '../img/sprite.png?v=2', 'facebook-small', );
$facebook-name: 'facebook';
$facebook-x: 980px;
$facebook-y: 66px;
$facebook-offset-x: -980px;
$facebook-offset-y: -66px;
$facebook-width: 11px;
$facebook-height: 24px;
$facebook-total-width: 991px;
$facebook-total-height: 923px;
$facebook-image: '../img/sprite.png?v=2';
$facebook: (980px, 66px, -980px, -66px, 11px, 24px, 991px, 923px, '../img/sprite.png?v=2', 'facebook', );
$gamepad-name: 'gamepad';
$gamepad-x: 0px;
$gamepad-y: 244px;
$gamepad-offset-x: 0px;
$gamepad-offset-y: -244px;
$gamepad-width: 216px;
$gamepad-height: 216px;
$gamepad-total-width: 991px;
$gamepad-total-height: 923px;
$gamepad-image: '../img/sprite.png?v=2';
$gamepad: (0px, 244px, 0px, -244px, 216px, 216px, 991px, 923px, '../img/sprite.png?v=2', 'gamepad', );
$gift-name: 'gift';
$gift-x: 429px;
$gift-y: 843px;
$gift-offset-x: -429px;
$gift-offset-y: -843px;
$gift-width: 20px;
$gift-height: 23px;
$gift-total-width: 991px;
$gift-total-height: 923px;
$gift-image: '../img/sprite.png?v=2';
$gift: (429px, 843px, -429px, -843px, 20px, 23px, 991px, 923px, '../img/sprite.png?v=2', 'gift', );
$google-small-name: 'google-small';
$google-small-x: 577px;
$google-small-y: 843px;
$google-small-offset-x: -577px;
$google-small-offset-y: -843px;
$google-small-width: 18px;
$google-small-height: 18px;
$google-small-total-width: 991px;
$google-small-total-height: 923px;
$google-small-image: '../img/sprite.png?v=2';
$google-small: (577px, 843px, -577px, -843px, 18px, 18px, 991px, 923px, '../img/sprite.png?v=2', 'google-small', );
$google-name: 'google';
$google-x: 614px;
$google-y: 778px;
$google-offset-x: -614px;
$google-offset-y: -778px;
$google-width: 22px;
$google-height: 22px;
$google-total-width: 991px;
$google-total-height: 923px;
$google-image: '../img/sprite.png?v=2';
$google: (614px, 778px, -614px, -778px, 22px, 22px, 991px, 923px, '../img/sprite.png?v=2', 'google', );
$heart-name: 'heart';
$heart-x: 479px;
$heart-y: 843px;
$heart-offset-x: -479px;
$heart-offset-y: -843px;
$heart-width: 20px;
$heart-height: 18px;
$heart-total-width: 991px;
$heart-total-height: 923px;
$heart-image: '../img/sprite.png?v=2';
$heart: (479px, 843px, -479px, -843px, 20px, 18px, 991px, 923px, '../img/sprite.png?v=2', 'heart', );
$icon-popup-panel-arrow-name: 'icon_popup-panel_arrow';
$icon-popup-panel-arrow-x: 979px;
$icon-popup-panel-arrow-y: 354px;
$icon-popup-panel-arrow-offset-x: -979px;
$icon-popup-panel-arrow-offset-y: -354px;
$icon-popup-panel-arrow-width: 11px;
$icon-popup-panel-arrow-height: 18px;
$icon-popup-panel-arrow-total-width: 991px;
$icon-popup-panel-arrow-total-height: 923px;
$icon-popup-panel-arrow-image: '../img/sprite.png?v=2';
$icon-popup-panel-arrow: (979px, 354px, -979px, -354px, 11px, 18px, 991px, 923px, '../img/sprite.png?v=2', 'icon_popup-panel_arrow', );
$info-light-name: 'info-light';
$info-light-x: 463px;
$info-light-y: 208px;
$info-light-offset-x: -463px;
$info-light-offset-y: -208px;
$info-light-width: 4px;
$info-light-height: 13px;
$info-light-total-width: 991px;
$info-light-total-height: 923px;
$info-light-image: '../img/sprite.png?v=2';
$info-light: (463px, 208px, -463px, -208px, 4px, 13px, 991px, 923px, '../img/sprite.png?v=2', 'info-light', );
$info-outline-name: 'info-outline';
$info-outline-x: 718px;
$info-outline-y: 778px;
$info-outline-offset-x: -718px;
$info-outline-offset-y: -778px;
$info-outline-width: 20px;
$info-outline-height: 20px;
$info-outline-total-width: 991px;
$info-outline-total-height: 923px;
$info-outline-image: '../img/sprite.png?v=2';
$info-outline: (718px, 778px, -718px, -778px, 20px, 20px, 991px, 923px, '../img/sprite.png?v=2', 'info-outline', );
$info-name: 'info';
$info-x: 980px;
$info-y: 309px;
$info-offset-x: -980px;
$info-offset-y: -309px;
$info-width: 7px;
$info-height: 15px;
$info-total-width: 991px;
$info-total-height: 923px;
$info-image: '../img/sprite.png?v=2';
$info: (980px, 309px, -980px, -309px, 7px, 15px, 991px, 923px, '../img/sprite.png?v=2', 'info', );
$instagram-name: 'instagram';
$instagram-x: 400px;
$instagram-y: 778px;
$instagram-offset-x: -400px;
$instagram-offset-y: -778px;
$instagram-width: 24px;
$instagram-height: 24px;
$instagram-total-width: 991px;
$instagram-total-height: 923px;
$instagram-image: '../img/sprite.png?v=2';
$instagram: (400px, 778px, -400px, -778px, 24px, 24px, 991px, 923px, '../img/sprite.png?v=2', 'instagram', );
$l18-name: 'l18';
$l18-x: 792px;
$l18-y: 358px;
$l18-offset-x: -792px;
$l18-offset-y: -358px;
$l18-width: 36px;
$l18-height: 36px;
$l18-total-width: 991px;
$l18-total-height: 923px;
$l18-image: '../img/sprite.png?v=2';
$l18: (792px, 358px, -792px, -358px, 36px, 36px, 991px, 923px, '../img/sprite.png?v=2', 'l18', );
$lock-small-name: 'lock_small';
$lock-small-x: 379px;
$lock-small-y: 843px;
$lock-small-offset-x: -379px;
$lock-small-offset-y: -843px;
$lock-small-width: 20px;
$lock-small-height: 24px;
$lock-small-total-width: 991px;
$lock-small-total-height: 923px;
$lock-small-image: '../img/sprite.png?v=2';
$lock-small: (379px, 843px, -379px, -843px, 20px, 24px, 991px, 923px, '../img/sprite.png?v=2', 'lock_small', );
$mastercard-name: 'mastercard';
$mastercard-x: 792px;
$mastercard-y: 316px;
$mastercard-offset-x: -792px;
$mastercard-offset-y: -316px;
$mastercard-width: 76px;
$mastercard-height: 12px;
$mastercard-total-width: 991px;
$mastercard-total-height: 923px;
$mastercard-image: '../img/sprite.png?v=2';
$mastercard: (792px, 316px, -792px, -316px, 76px, 12px, 991px, 923px, '../img/sprite.png?v=2', 'mastercard', );
$medal-bronze-large-name: 'medal-bronze-large';
$medal-bronze-large-x: 899px;
$medal-bronze-large-y: 66px;
$medal-bronze-large-offset-x: -899px;
$medal-bronze-large-offset-y: -66px;
$medal-bronze-large-width: 51px;
$medal-bronze-large-height: 66px;
$medal-bronze-large-total-width: 991px;
$medal-bronze-large-total-height: 923px;
$medal-bronze-large-image: '../img/sprite.png?v=2';
$medal-bronze-large: (899px, 66px, -899px, -66px, 51px, 66px, 991px, 923px, '../img/sprite.png?v=2', 'medal-bronze-large', );
$medal-bronze-name: 'medal-bronze';
$medal-bronze-x: 792px;
$medal-bronze-y: 490px;
$medal-bronze-offset-x: -792px;
$medal-bronze-offset-y: -490px;
$medal-bronze-width: 30px;
$medal-bronze-height: 33px;
$medal-bronze-total-width: 991px;
$medal-bronze-total-height: 923px;
$medal-bronze-image: '../img/sprite.png?v=2';
$medal-bronze: (792px, 490px, -792px, -490px, 30px, 33px, 991px, 923px, '../img/sprite.png?v=2', 'medal-bronze', );
$medal-gold-huge-name: 'medal-gold-huge';
$medal-gold-huge-x: 620px;
$medal-gold-huge-y: 153px;
$medal-gold-huge-offset-x: -620px;
$medal-gold-huge-offset-y: -153px;
$medal-gold-huge-width: 70px;
$medal-gold-huge-height: 92px;
$medal-gold-huge-total-width: 991px;
$medal-gold-huge-total-height: 923px;
$medal-gold-huge-image: '../img/sprite.png?v=2';
$medal-gold-huge: (620px, 153px, -620px, -153px, 70px, 92px, 991px, 923px, '../img/sprite.png?v=2', 'medal-gold-huge', );
$medal-gold-large-name: 'medal-gold-large';
$medal-gold-large-x: 899px;
$medal-gold-large-y: 258px;
$medal-gold-large-offset-x: -899px;
$medal-gold-large-offset-y: -258px;
$medal-gold-large-width: 51px;
$medal-gold-large-height: 66px;
$medal-gold-large-total-width: 991px;
$medal-gold-large-total-height: 923px;
$medal-gold-large-image: '../img/sprite.png?v=2';
$medal-gold-large: (899px, 258px, -899px, -258px, 51px, 66px, 991px, 923px, '../img/sprite.png?v=2', 'medal-gold-large', );
$medal-gold-name: 'medal-gold';
$medal-gold-x: 57px;
$medal-gold-y: 843px;
$medal-gold-offset-x: -57px;
$medal-gold-offset-y: -843px;
$medal-gold-width: 22px;
$medal-gold-height: 31px;
$medal-gold-total-width: 991px;
$medal-gold-total-height: 923px;
$medal-gold-image: '../img/sprite.png?v=2';
$medal-gold: (57px, 843px, -57px, -843px, 22px, 31px, 991px, 923px, '../img/sprite.png?v=2', 'medal-gold', );
$medal-silver-large-name: 'medal-silver-large';
$medal-silver-large-x: 899px;
$medal-silver-large-y: 162px;
$medal-silver-large-offset-x: -899px;
$medal-silver-large-offset-y: -162px;
$medal-silver-large-width: 51px;
$medal-silver-large-height: 66px;
$medal-silver-large-total-width: 991px;
$medal-silver-large-total-height: 923px;
$medal-silver-large-image: '../img/sprite.png?v=2';
$medal-silver-large: (899px, 162px, -899px, -162px, 51px, 66px, 991px, 923px, '../img/sprite.png?v=2', 'medal-silver-large', );
$medal-silver-name: 'medal-silver';
$medal-silver-x: 0px;
$medal-silver-y: 843px;
$medal-silver-offset-x: 0px;
$medal-silver-offset-y: -843px;
$medal-silver-width: 27px;
$medal-silver-height: 31px;
$medal-silver-total-width: 991px;
$medal-silver-total-height: 923px;
$medal-silver-image: '../img/sprite.png?v=2';
$medal-silver: (0px, 843px, 0px, -843px, 27px, 31px, 991px, 923px, '../img/sprite.png?v=2', 'medal-silver', );
$medal-name: 'medal';
$medal-x: 109px;
$medal-y: 843px;
$medal-offset-x: -109px;
$medal-offset-y: -843px;
$medal-width: 22px;
$medal-height: 31px;
$medal-total-width: 991px;
$medal-total-height: 923px;
$medal-image: '../img/sprite.png?v=2';
$medal: (109px, 843px, -109px, -843px, 22px, 31px, 991px, 923px, '../img/sprite.png?v=2', 'medal', );
$microgaming-name: 'microgaming';
$microgaming-x: 253px;
$microgaming-y: 678px;
$microgaming-offset-x: -253px;
$microgaming-offset-y: -678px;
$microgaming-width: 134px;
$microgaming-height: 33px;
$microgaming-total-width: 991px;
$microgaming-total-height: 923px;
$microgaming-image: '../img/sprite.png?v=2';
$microgaming: (253px, 678px, -253px, -678px, 134px, 33px, 991px, 923px, '../img/sprite.png?v=2', 'microgaming', );
$mobiles-name: 'mobiles';
$mobiles-x: 620px;
$mobiles-y: 0px;
$mobiles-offset-x: -620px;
$mobiles-offset-y: 0px;
$mobiles-width: 142px;
$mobiles-height: 47px;
$mobiles-total-width: 991px;
$mobiles-total-height: 923px;
$mobiles-image: '../img/sprite.png?v=2';
$mobiles: (620px, 0px, -620px, 0px, 142px, 47px, 991px, 923px, '../img/sprite.png?v=2', 'mobiles', );
$moneta-name: 'moneta';
$moneta-x: 899px;
$moneta-y: 448px;
$moneta-offset-x: -899px;
$moneta-offset-y: -448px;
$moneta-width: 86px;
$moneta-height: 33px;
$moneta-total-width: 991px;
$moneta-total-height: 923px;
$moneta-image: '../img/sprite.png?v=2';
$moneta: (899px, 448px, -899px, -448px, 86px, 33px, 991px, 923px, '../img/sprite.png?v=2', 'moneta', );
$moneyru-name: 'moneyru';
$moneyru-x: 371px;
$moneyru-y: 490px;
$moneyru-offset-x: -371px;
$moneyru-offset-y: -490px;
$moneyru-width: 142px;
$moneyru-height: 48px;
$moneyru-total-width: 991px;
$moneyru-total-height: 923px;
$moneyru-image: '../img/sprite.png?v=2';
$moneyru: (371px, 490px, -371px, -490px, 142px, 48px, 991px, 923px, '../img/sprite.png?v=2', 'moneyru', );
$nav-divider-name: 'nav-divider';
$nav-divider-x: 980px;
$nav-divider-y: 162px;
$nav-divider-offset-x: -980px;
$nav-divider-offset-y: -162px;
$nav-divider-width: 1px;
$nav-divider-height: 39px;
$nav-divider-total-width: 991px;
$nav-divider-total-height: 923px;
$nav-divider-image: '../img/sprite.png?v=2';
$nav-divider: (980px, 162px, -980px, -162px, 1px, 39px, 991px, 923px, '../img/sprite.png?v=2', 'nav-divider', );
$netent-name: 'netent';
$netent-x: 0px;
$netent-y: 778px;
$netent-offset-x: 0px;
$netent-offset-y: -778px;
$netent-width: 99px;
$netent-height: 35px;
$netent-total-width: 991px;
$netent-total-height: 923px;
$netent-image: '../img/sprite.png?v=2';
$netent: (0px, 778px, 0px, -778px, 99px, 35px, 991px, 923px, '../img/sprite.png?v=2', 'netent', );
$ok-small-name: 'ok-small';
$ok-small-x: 980px;
$ok-small-y: 258px;
$ok-small-offset-x: -980px;
$ok-small-offset-y: -258px;
$ok-small-width: 11px;
$ok-small-height: 21px;
$ok-small-total-width: 991px;
$ok-small-total-height: 923px;
$ok-small-image: '../img/sprite.png?v=2';
$ok-small: (980px, 258px, -980px, -258px, 11px, 21px, 991px, 923px, '../img/sprite.png?v=2', 'ok-small', );
$ok-name: 'ok';
$ok-x: 977px;
$ok-y: 569px;
$ok-offset-x: -977px;
$ok-offset-y: -569px;
$ok-width: 13px;
$ok-height: 25px;
$ok-total-width: 991px;
$ok-total-height: 923px;
$ok-image: '../img/sprite.png?v=2';
$ok: (977px, 569px, -977px, -569px, 13px, 25px, 991px, 923px, '../img/sprite.png?v=2', 'ok', );
$okpay-name: 'okpay';
$okpay-x: 172px;
$okpay-y: 612px;
$okpay-offset-x: -172px;
$okpay-offset-y: -612px;
$okpay-width: 142px;
$okpay-height: 34px;
$okpay-total-width: 991px;
$okpay-total-height: 923px;
$okpay-image: '../img/sprite.png?v=2';
$okpay: (172px, 612px, -172px, -612px, 142px, 34px, 991px, 923px, '../img/sprite.png?v=2', 'okpay', );
$payeer-name: 'payeer';
$payeer-x: 344px;
$payeer-y: 612px;
$payeer-offset-x: -344px;
$payeer-offset-y: -612px;
$payeer-width: 142px;
$payeer-height: 31px;
$payeer-total-width: 991px;
$payeer-total-height: 923px;
$payeer-image: '../img/sprite.png?v=2';
$payeer: (344px, 612px, -344px, -612px, 142px, 31px, 991px, 923px, '../img/sprite.png?v=2', 'payeer', );
$perfectmoney-name: 'perfectmoney';
$perfectmoney-x: 417px;
$perfectmoney-y: 678px;
$perfectmoney-offset-x: -417px;
$perfectmoney-offset-y: -678px;
$perfectmoney-width: 142px;
$perfectmoney-height: 29px;
$perfectmoney-total-width: 991px;
$perfectmoney-total-height: 923px;
$perfectmoney-image: '../img/sprite.png?v=2';
$perfectmoney: (417px, 678px, -417px, -678px, 142px, 29px, 991px, 923px, '../img/sprite.png?v=2', 'perfectmoney', );
$play-name: 'play';
$play-x: 852px;
$play-y: 490px;
$play-offset-x: -852px;
$play-offset-y: -490px;
$play-width: 17px;
$play-height: 21px;
$play-total-width: 991px;
$play-total-height: 923px;
$play-image: '../img/sprite.png?v=2';
$play: (852px, 490px, -852px, -490px, 17px, 21px, 991px, 923px, '../img/sprite.png?v=2', 'play', );
$promsvyazbank-name: 'promsvyazbank';
$promsvyazbank-x: 589px;
$promsvyazbank-y: 678px;
$promsvyazbank-offset-x: -589px;
$promsvyazbank-offset-y: -678px;
$promsvyazbank-width: 128px;
$promsvyazbank-height: 21px;
$promsvyazbank-total-width: 991px;
$promsvyazbank-total-height: 923px;
$promsvyazbank-image: '../img/sprite.png?v=2';
$promsvyazbank: (589px, 678px, -589px, -678px, 128px, 21px, 991px, 923px, '../img/sprite.png?v=2', 'promsvyazbank', );
$purse-name: 'purse';
$purse-x: 327px;
$purse-y: 843px;
$purse-offset-x: -327px;
$purse-offset-y: -843px;
$purse-width: 22px;
$purse-height: 24px;
$purse-total-width: 991px;
$purse-total-height: 923px;
$purse-image: '../img/sprite.png?v=2';
$purse: (327px, 843px, -327px, -843px, 22px, 24px, 991px, 923px, '../img/sprite.png?v=2', 'purse', );
$qiwi-logo-name: 'qiwi-logo';
$qiwi-logo-x: 620px;
$qiwi-logo-y: 358px;
$qiwi-logo-offset-x: -620px;
$qiwi-logo-offset-y: -358px;
$qiwi-logo-width: 123px;
$qiwi-logo-height: 49px;
$qiwi-logo-total-width: 991px;
$qiwi-logo-total-height: 923px;
$qiwi-logo-image: '../img/sprite.png?v=2';
$qiwi-logo: (620px, 358px, -620px, -358px, 123px, 49px, 991px, 923px, '../img/sprite.png?v=2', 'qiwi-logo', );
$qiwi-name: 'qiwi';
$qiwi-x: 899px;
$qiwi-y: 711px;
$qiwi-offset-x: -899px;
$qiwi-offset-y: -711px;
$qiwi-width: 59px;
$qiwi-height: 24px;
$qiwi-total-width: 991px;
$qiwi-total-height: 923px;
$qiwi-image: '../img/sprite.png?v=2';
$qiwi: (899px, 711px, -899px, -711px, 59px, 24px, 991px, 923px, '../img/sprite.png?v=2', 'qiwi', );
$qiwi-c-name: 'qiwi_c';
$qiwi-c-x: 112px;
$qiwi-c-y: 678px;
$qiwi-c-offset-x: -112px;
$qiwi-c-offset-y: -678px;
$qiwi-c-width: 111px;
$qiwi-c-height: 48px;
$qiwi-c-total-width: 991px;
$qiwi-c-total-height: 923px;
$qiwi-c-image: '../img/sprite.png?v=2';
$qiwi-c: (112px, 678px, -112px, -678px, 111px, 48px, 991px, 923px, '../img/sprite.png?v=2', 'qiwi_c', );
$ruble-currency-sign-name: 'ruble-currency-sign';
$ruble-currency-sign-x: 792px;
$ruble-currency-sign-y: 248px;
$ruble-currency-sign-offset-x: -792px;
$ruble-currency-sign-offset-y: -248px;
$ruble-currency-sign-width: 36px;
$ruble-currency-sign-height: 38px;
$ruble-currency-sign-total-width: 991px;
$ruble-currency-sign-total-height: 923px;
$ruble-currency-sign-image: '../img/sprite.png?v=2';
$ruble-currency-sign: (792px, 248px, -792px, -248px, 36px, 38px, 991px, 923px, '../img/sprite.png?v=2', 'ruble-currency-sign', );
$safebox-name: 'safebox';
$safebox-x: 0px;
$safebox-y: 0px;
$safebox-offset-x: 0px;
$safebox-offset-y: 0px;
$safebox-width: 222px;
$safebox-height: 214px;
$safebox-total-width: 991px;
$safebox-total-height: 923px;
$safebox-image: '../img/sprite.png?v=2';
$safebox: (0px, 0px, 0px, 0px, 222px, 214px, 991px, 923px, '../img/sprite.png?v=2', 'safebox', );
$sberbank-name: 'sberbank';
$sberbank-x: 129px;
$sberbank-y: 778px;
$sberbank-offset-x: -129px;
$sberbank-offset-y: -778px;
$sberbank-width: 110px;
$sberbank-height: 27px;
$sberbank-total-width: 991px;
$sberbank-total-height: 923px;
$sberbank-image: '../img/sprite.png?v=2';
$sberbank: (129px, 778px, -129px, -778px, 110px, 27px, 991px, 923px, '../img/sprite.png?v=2', 'sberbank', );
$search-name: 'search';
$search-x: 273px;
$search-y: 843px;
$search-offset-x: -273px;
$search-offset-y: -843px;
$search-width: 24px;
$search-height: 25px;
$search-total-width: 991px;
$search-total-height: 923px;
$search-image: '../img/sprite.png?v=2';
$search: (273px, 843px, -273px, -843px, 24px, 25px, 991px, 923px, '../img/sprite.png?v=2', 'search', );
$shuffle-name: 'shuffle';
$shuffle-x: 508px;
$shuffle-y: 778px;
$shuffle-offset-x: -508px;
$shuffle-offset-y: -778px;
$shuffle-width: 24px;
$shuffle-height: 22px;
$shuffle-total-width: 991px;
$shuffle-total-height: 923px;
$shuffle-image: '../img/sprite.png?v=2';
$shuffle: (508px, 778px, -508px, -778px, 24px, 22px, 991px, 923px, '../img/sprite.png?v=2', 'shuffle', );
$slider-arrow-left-name: 'slider-arrow-left';
$slider-arrow-left-x: 858px;
$slider-arrow-left-y: 358px;
$slider-arrow-left-offset-x: -858px;
$slider-arrow-left-offset-y: -358px;
$slider-arrow-left-width: 11px;
$slider-arrow-left-height: 16px;
$slider-arrow-left-total-width: 991px;
$slider-arrow-left-total-height: 923px;
$slider-arrow-left-image: '../img/sprite.png?v=2';
$slider-arrow-left: (858px, 358px, -858px, -358px, 11px, 16px, 991px, 923px, '../img/sprite.png?v=2', 'slider-arrow-left', );
$slider-arrow-right-name: 'slider-arrow-right';
$slider-arrow-right-x: 979px;
$slider-arrow-right-y: 402px;
$slider-arrow-right-offset-x: -979px;
$slider-arrow-right-offset-y: -402px;
$slider-arrow-right-width: 11px;
$slider-arrow-right-height: 16px;
$slider-arrow-right-total-width: 991px;
$slider-arrow-right-total-height: 923px;
$slider-arrow-right-image: '../img/sprite.png?v=2';
$slider-arrow-right: (979px, 402px, -979px, -402px, 11px, 16px, 991px, 923px, '../img/sprite.png?v=2', 'slider-arrow-right', );
$spinner-name: 'spinner';
$spinner-x: 688px;
$spinner-y: 612px;
$spinner-offset-x: -688px;
$spinner-offset-y: -612px;
$spinner-width: 27px;
$spinner-height: 29px;
$spinner-total-width: 991px;
$spinner-total-height: 923px;
$spinner-image: '../img/sprite.png?v=2';
$spinner: (688px, 612px, -688px, -612px, 27px, 29px, 991px, 923px, '../img/sprite.png?v=2', 'spinner', );
$star-big-empty-name: 'star-big-empty';
$star-big-empty-x: 217px;
$star-big-empty-y: 843px;
$star-big-empty-offset-x: -217px;
$star-big-empty-offset-y: -843px;
$star-big-empty-width: 26px;
$star-big-empty-height: 25px;
$star-big-empty-total-width: 991px;
$star-big-empty-total-height: 923px;
$star-big-empty-image: '../img/sprite.png?v=2';
$star-big-empty: (217px, 843px, -217px, -843px, 26px, 25px, 991px, 923px, '../img/sprite.png?v=2', 'star-big-empty', );
$star-big-name: 'star-big';
$star-big-x: 161px;
$star-big-y: 843px;
$star-big-offset-x: -161px;
$star-big-offset-y: -843px;
$star-big-width: 26px;
$star-big-height: 25px;
$star-big-total-width: 991px;
$star-big-total-height: 923px;
$star-big-image: '../img/sprite.png?v=2';
$star-big: (161px, 843px, -161px, -843px, 26px, 25px, 991px, 923px, '../img/sprite.png?v=2', 'star-big', );
$star-white-name: 'star-white';
$star-white-x: 454px;
$star-white-y: 778px;
$star-white-offset-x: -454px;
$star-white-offset-y: -778px;
$star-white-width: 24px;
$star-white-height: 23px;
$star-white-total-width: 991px;
$star-white-total-height: 923px;
$star-white-image: '../img/sprite.png?v=2';
$star-white: (454px, 778px, -454px, -778px, 24px, 23px, 991px, 923px, '../img/sprite.png?v=2', 'star-white', );
$star-name: 'star';
$star-x: 747px;
$star-y: 678px;
$star-offset-x: -747px;
$star-offset-y: -678px;
$star-width: 12px;
$star-height: 11px;
$star-total-width: 991px;
$star-total-height: 923px;
$star-image: '../img/sprite.png?v=2';
$star: (747px, 678px, -747px, -678px, 12px, 11px, 991px, 923px, '../img/sprite.png?v=2', 'star', );
$starempty-name: 'starempty';
$starempty-x: 745px;
$starempty-y: 612px;
$starempty-offset-x: -745px;
$starempty-offset-y: -612px;
$starempty-width: 12px;
$starempty-height: 11px;
$starempty-total-width: 991px;
$starempty-total-height: 923px;
$starempty-image: '../img/sprite.png?v=2';
$starempty: (745px, 612px, -745px, -612px, 12px, 11px, 991px, 923px, '../img/sprite.png?v=2', 'starempty', );
$starhalf-name: 'starhalf';
$starhalf-x: 980px;
$starhalf-y: 120px;
$starhalf-offset-x: -980px;
$starhalf-offset-y: -120px;
$starhalf-width: 11px;
$starhalf-height: 10px;
$starhalf-total-width: 991px;
$starhalf-total-height: 923px;
$starhalf-image: '../img/sprite.png?v=2';
$starhalf: (980px, 120px, -980px, -120px, 11px, 10px, 991px, 923px, '../img/sprite.png?v=2', 'starhalf', );
$terminal-symbol-terminal-rus-name: 'terminal-symbol-terminal-rus';
$terminal-symbol-terminal-rus-x: 620px;
$terminal-symbol-terminal-rus-y: 437px;
$terminal-symbol-terminal-rus-offset-x: -620px;
$terminal-symbol-terminal-rus-offset-y: -437px;
$terminal-symbol-terminal-rus-width: 123px;
$terminal-symbol-terminal-rus-height: 47px;
$terminal-symbol-terminal-rus-total-width: 991px;
$terminal-symbol-terminal-rus-total-height: 923px;
$terminal-symbol-terminal-rus-image: '../img/sprite.png?v=2';
$terminal-symbol-terminal-rus: (620px, 437px, -620px, -437px, 123px, 47px, 991px, 923px, '../img/sprite.png?v=2', 'terminal-symbol-terminal-rus', );
$twitter-small-name: 'twitter-small';
$twitter-small-x: 625px;
$twitter-small-y: 843px;
$twitter-small-offset-x: -625px;
$twitter-small-offset-y: -843px;
$twitter-small-width: 18px;
$twitter-small-height: 17px;
$twitter-small-total-width: 991px;
$twitter-small-total-height: 923px;
$twitter-small-image: '../img/sprite.png?v=2';
$twitter-small: (625px, 843px, -625px, -843px, 18px, 17px, 991px, 923px, '../img/sprite.png?v=2', 'twitter-small', );
$twitter-name: 'twitter';
$twitter-x: 666px;
$twitter-y: 778px;
$twitter-offset-x: -666px;
$twitter-offset-y: -778px;
$twitter-width: 22px;
$twitter-height: 20px;
$twitter-total-width: 991px;
$twitter-total-height: 923px;
$twitter-image: '../img/sprite.png?v=2';
$twitter: (666px, 778px, -666px, -778px, 22px, 20px, 991px, 923px, '../img/sprite.png?v=2', 'twitter', );
$vip-1-medium-name: 'vip-1-medium';
$vip-1-medium-x: 498px;
$vip-1-medium-y: 122px;
$vip-1-medium-offset-x: -498px;
$vip-1-medium-offset-y: -122px;
$vip-1-medium-width: 92px;
$vip-1-medium-height: 92px;
$vip-1-medium-total-width: 991px;
$vip-1-medium-total-height: 923px;
$vip-1-medium-image: '../img/sprite.png?v=2';
$vip-1-medium: (498px, 122px, -498px, -122px, 92px, 92px, 991px, 923px, '../img/sprite.png?v=2', 'vip-1-medium', );
$vip-1-small-name: 'vip-1-small';
$vip-1-small-x: 792px;
$vip-1-small-y: 671px;
$vip-1-small-offset-x: -792px;
$vip-1-small-offset-y: -671px;
$vip-1-small-width: 30px;
$vip-1-small-height: 30px;
$vip-1-small-total-width: 991px;
$vip-1-small-total-height: 923px;
$vip-1-small-image: '../img/sprite.png?v=2';
$vip-1-small: (792px, 671px, -792px, -671px, 30px, 30px, 991px, 923px, '../img/sprite.png?v=2', 'vip-1-small', );
$vip-2-medium-name: 'vip-2-medium';
$vip-2-medium-x: 498px;
$vip-2-medium-y: 0px;
$vip-2-medium-offset-x: -498px;
$vip-2-medium-offset-y: 0px;
$vip-2-medium-width: 92px;
$vip-2-medium-height: 92px;
$vip-2-medium-total-width: 991px;
$vip-2-medium-total-height: 923px;
$vip-2-medium-image: '../img/sprite.png?v=2';
$vip-2-medium: (498px, 0px, -498px, 0px, 92px, 92px, 991px, 923px, '../img/sprite.png?v=2', 'vip-2-medium', );
$vip-2-small-name: 'vip-2-small';
$vip-2-small-x: 392px;
$vip-2-small-y: 378px;
$vip-2-small-offset-x: -392px;
$vip-2-small-offset-y: -378px;
$vip-2-small-width: 30px;
$vip-2-small-height: 30px;
$vip-2-small-total-width: 991px;
$vip-2-small-total-height: 923px;
$vip-2-small-image: '../img/sprite.png?v=2';
$vip-2-small: (392px, 378px, -392px, -378px, 30px, 30px, 991px, 923px, '../img/sprite.png?v=2', 'vip-2-small', );
$vip-3-medium-name: 'vip-3-medium';
$vip-3-medium-x: 0px;
$vip-3-medium-y: 490px;
$vip-3-medium-offset-x: 0px;
$vip-3-medium-offset-y: -490px;
$vip-3-medium-width: 92px;
$vip-3-medium-height: 92px;
$vip-3-medium-total-width: 991px;
$vip-3-medium-total-height: 923px;
$vip-3-medium-image: '../img/sprite.png?v=2';
$vip-3-medium: (0px, 490px, 0px, -490px, 92px, 92px, 991px, 923px, '../img/sprite.png?v=2', 'vip-3-medium', );
$vip-3-small-name: 'vip-3-small';
$vip-3-small-x: 543px;
$vip-3-small-y: 490px;
$vip-3-small-offset-x: -543px;
$vip-3-small-offset-y: -490px;
$vip-3-small-width: 30px;
$vip-3-small-height: 30px;
$vip-3-small-total-width: 991px;
$vip-3-small-total-height: 923px;
$vip-3-small-image: '../img/sprite.png?v=2';
$vip-3-small: (543px, 490px, -543px, -490px, 30px, 30px, 991px, 923px, '../img/sprite.png?v=2', 'vip-3-small', );
$vip-4-medium-name: 'vip-4-medium';
$vip-4-medium-x: 122px;
$vip-4-medium-y: 490px;
$vip-4-medium-offset-x: -122px;
$vip-4-medium-offset-y: -490px;
$vip-4-medium-width: 92px;
$vip-4-medium-height: 92px;
$vip-4-medium-total-width: 991px;
$vip-4-medium-total-height: 923px;
$vip-4-medium-image: '../img/sprite.png?v=2';
$vip-4-medium: (122px, 490px, -122px, -490px, 92px, 92px, 991px, 923px, '../img/sprite.png?v=2', 'vip-4-medium', );
$vip-4-small-name: 'vip-4-small';
$vip-4-small-x: 720px;
$vip-4-small-y: 153px;
$vip-4-small-offset-x: -720px;
$vip-4-small-offset-y: -153px;
$vip-4-small-width: 30px;
$vip-4-small-height: 30px;
$vip-4-small-total-width: 991px;
$vip-4-small-total-height: 923px;
$vip-4-small-image: '../img/sprite.png?v=2';
$vip-4-small: (720px, 153px, -720px, -153px, 30px, 30px, 991px, 923px, '../img/sprite.png?v=2', 'vip-4-small', );
$vip-5-medium-name: 'vip-5-medium';
$vip-5-medium-x: 498px;
$vip-5-medium-y: 366px;
$vip-5-medium-offset-x: -498px;
$vip-5-medium-offset-y: -366px;
$vip-5-medium-width: 92px;
$vip-5-medium-height: 92px;
$vip-5-medium-total-width: 991px;
$vip-5-medium-total-height: 923px;
$vip-5-medium-image: '../img/sprite.png?v=2';
$vip-5-medium: (498px, 366px, -498px, -366px, 92px, 92px, 991px, 923px, '../img/sprite.png?v=2', 'vip-5-medium', );
$vip-5-small-name: 'vip-5-small';
$vip-5-small-x: 792px;
$vip-5-small-y: 611px;
$vip-5-small-offset-x: -792px;
$vip-5-small-offset-y: -611px;
$vip-5-small-width: 30px;
$vip-5-small-height: 30px;
$vip-5-small-total-width: 991px;
$vip-5-small-total-height: 923px;
$vip-5-small-image: '../img/sprite.png?v=2';
$vip-5-small: (792px, 611px, -792px, -611px, 30px, 30px, 991px, 923px, '../img/sprite.png?v=2', 'vip-5-small', );
$vip-6-medium-name: 'vip-6-medium';
$vip-6-medium-x: 498px;
$vip-6-medium-y: 244px;
$vip-6-medium-offset-x: -498px;
$vip-6-medium-offset-y: -244px;
$vip-6-medium-width: 92px;
$vip-6-medium-height: 92px;
$vip-6-medium-total-width: 991px;
$vip-6-medium-total-height: 923px;
$vip-6-medium-image: '../img/sprite.png?v=2';
$vip-6-medium: (498px, 244px, -498px, -244px, 92px, 92px, 991px, 923px, '../img/sprite.png?v=2', 'vip-6-medium', );
$vip-6-small-name: 'vip-6-small';
$vip-6-small-x: 720px;
$vip-6-small-y: 213px;
$vip-6-small-offset-x: -720px;
$vip-6-small-offset-y: -213px;
$vip-6-small-width: 30px;
$vip-6-small-height: 30px;
$vip-6-small-total-width: 991px;
$vip-6-small-total-height: 923px;
$vip-6-small-image: '../img/sprite.png?v=2';
$vip-6-small: (720px, 213px, -720px, -213px, 30px, 30px, 991px, 923px, '../img/sprite.png?v=2', 'vip-6-small', );
$visa-logo-name: 'visa-logo';
$visa-logo-x: 899px;
$visa-logo-y: 511px;
$visa-logo-offset-x: -899px;
$visa-logo-offset-y: -511px;
$visa-logo-width: 92px;
$visa-logo-height: 28px;
$visa-logo-total-width: 991px;
$visa-logo-total-height: 923px;
$visa-logo-image: '../img/sprite.png?v=2';
$visa-logo: (899px, 511px, -899px, -511px, 92px, 28px, 991px, 923px, '../img/sprite.png?v=2', 'visa-logo', );
$visa-name: 'visa';
$visa-x: 792px;
$visa-y: 731px;
$visa-offset-x: -792px;
$visa-offset-y: -731px;
$visa-width: 36px;
$visa-height: 11px;
$visa-total-width: 991px;
$visa-total-height: 923px;
$visa-image: '../img/sprite.png?v=2';
$visa: (792px, 731px, -792px, -731px, 36px, 11px, 991px, 923px, '../img/sprite.png?v=2', 'visa', );
$visamastercard-name: 'visamastercard';
$visamastercard-x: 620px;
$visamastercard-y: 77px;
$visamastercard-offset-x: -620px;
$visamastercard-offset-y: -77px;
$visamastercard-width: 142px;
$visamastercard-height: 46px;
$visamastercard-total-width: 991px;
$visamastercard-total-height: 923px;
$visamastercard-image: '../img/sprite.png?v=2';
$visamastercard: (620px, 77px, -620px, -77px, 142px, 46px, 991px, 923px, '../img/sprite.png?v=2', 'visamastercard', );
$vkontakte-small-name: 'vkontakte-small';
$vkontakte-small-x: 673px;
$vkontakte-small-y: 843px;
$vkontakte-small-offset-x: -673px;
$vkontakte-small-offset-y: -843px;
$vkontakte-small-width: 20px;
$vkontakte-small-height: 14px;
$vkontakte-small-total-width: 991px;
$vkontakte-small-total-height: 923px;
$vkontakte-small-image: '../img/sprite.png?v=2';
$vkontakte-small: (673px, 843px, -673px, -843px, 20px, 14px, 991px, 923px, '../img/sprite.png?v=2', 'vkontakte-small', );
$vkontakte-name: 'vkontakte';
$vkontakte-x: 818px;
$vkontakte-y: 778px;
$vkontakte-offset-x: -818px;
$vkontakte-offset-y: -778px;
$vkontakte-width: 24px;
$vkontakte-height: 16px;
$vkontakte-total-width: 991px;
$vkontakte-total-height: 923px;
$vkontakte-image: '../img/sprite.png?v=2';
$vkontakte: (818px, 778px, -818px, -778px, 24px, 16px, 991px, 923px, '../img/sprite.png?v=2', 'vkontakte', );
$volume-name: 'volume';
$volume-x: 529px;
$volume-y: 843px;
$volume-offset-x: -529px;
$volume-offset-y: -843px;
$volume-width: 18px;
$volume-height: 18px;
$volume-total-width: 991px;
$volume-total-height: 923px;
$volume-image: '../img/sprite.png?v=2';
$volume: (529px, 843px, -529px, -843px, 18px, 18px, 991px, 923px, '../img/sprite.png?v=2', 'volume', );
$w1-name: 'w1';
$w1-x: 0px;
$w1-y: 678px;
$w1-offset-x: 0px;
$w1-offset-y: -678px;
$w1-width: 82px;
$w1-height: 70px;
$w1-total-width: 991px;
$w1-total-height: 923px;
$w1-image: '../img/sprite.png?v=2';
$w1: (0px, 678px, 0px, -678px, 82px, 70px, 991px, 923px, '../img/sprite.png?v=2', 'w1', );
$wallet-name: 'wallet';
$wallet-x: 792px;
$wallet-y: 553px;
$wallet-offset-x: -792px;
$wallet-offset-y: -553px;
$wallet-width: 33px;
$wallet-height: 28px;
$wallet-total-width: 991px;
$wallet-total-height: 923px;
$wallet-image: '../img/sprite.png?v=2';
$wallet: (792px, 553px, -792px, -553px, 33px, 28px, 991px, 923px, '../img/sprite.png?v=2', 'wallet', );
$webmoney-name: 'webmoney';
$webmoney-x: 269px;
$webmoney-y: 778px;
$webmoney-offset-x: -269px;
$webmoney-offset-y: -778px;
$webmoney-width: 101px;
$webmoney-height: 24px;
$webmoney-total-width: 991px;
$webmoney-total-height: 923px;
$webmoney-image: '../img/sprite.png?v=2';
$webmoney: (269px, 778px, -269px, -778px, 101px, 24px, 991px, 923px, '../img/sprite.png?v=2', 'webmoney', );
$webmoney-c-name: 'webmoney_c';
$webmoney-c-x: 0px;
$webmoney-c-y: 612px;
$webmoney-c-offset-x: 0px;
$webmoney-c-offset-y: -612px;
$webmoney-c-width: 142px;
$webmoney-c-height: 36px;
$webmoney-c-total-width: 991px;
$webmoney-c-total-height: 923px;
$webmoney-c-image: '../img/sprite.png?v=2';
$webmoney-c: (0px, 612px, 0px, -612px, 142px, 36px, 991px, 923px, '../img/sprite.png?v=2', 'webmoney_c', );
$yandex-logo-name: 'yandex-logo';
$yandex-logo-x: 620px;
$yandex-logo-y: 275px;
$yandex-logo-offset-x: -620px;
$yandex-logo-offset-y: -275px;
$yandex-logo-width: 116px;
$yandex-logo-height: 53px;
$yandex-logo-total-width: 991px;
$yandex-logo-total-height: 923px;
$yandex-logo-image: '../img/sprite.png?v=2';
$yandex-logo: (620px, 275px, -620px, -275px, 116px, 53px, 991px, 923px, '../img/sprite.png?v=2', 'yandex-logo', );
$yandex-name: 'yandex';
$yandex-x: 899px;
$yandex-y: 765px;
$yandex-offset-x: -899px;
$yandex-offset-y: -765px;
$yandex-width: 65px;
$yandex-height: 19px;
$yandex-total-width: 991px;
$yandex-total-height: 923px;
$yandex-image: '../img/sprite.png?v=2';
$yandex: (899px, 765px, -899px, -765px, 65px, 19px, 991px, 923px, '../img/sprite.png?v=2', 'yandex', );
$yandex-c-name: 'yandex_c';
$yandex-c-x: 620px;
$yandex-c-y: 514px;
$yandex-c-offset-x: -620px;
$yandex-c-offset-y: -514px;
$yandex-c-width: 142px;
$yandex-c-height: 36px;
$yandex-c-total-width: 991px;
$yandex-c-total-height: 923px;
$yandex-c-image: '../img/sprite.png?v=2';
$yandex-c: (620px, 514px, -620px, -514px, 142px, 36px, 991px, 923px, '../img/sprite.png?v=2', 'yandex_c', );
$yandex-soc-name: 'yandex_soc';
$yandex-soc-x: 854px;
$yandex-soc-y: 100px;
$yandex-soc-offset-x: -854px;
$yandex-soc-offset-y: -100px;
$yandex-soc-width: 13px;
$yandex-soc-height: 24px;
$yandex-soc-total-width: 991px;
$yandex-soc-total-height: 923px;
$yandex-soc-image: '../img/sprite.png?v=2';
$yandex-soc: (854px, 100px, -854px, -100px, 13px, 24px, 991px, 923px, '../img/sprite.png?v=2', 'yandex_soc', );
$spritesheet-width: 991px;
$spritesheet-height: 923px;
$spritesheet-image: '../img/sprite.png?v=2';
$spritesheet-sprites: ($alfabank, $arrow-down-white, $arrow-down, $arrow-right-white, $arrow-right, $arrow-up-white, $bitcoin, $bonus-4, $bonus-small-1, $bonus-tiny-1, $cancel, $checkmark, $clock, $comepay, $contact, $counter-bg-dark, $counter-bg, $counter, $cross-bold, $cross-bold-grey, $cup, $curagao, $ecogra, $exit, $facebook-small, $facebook, $gamepad, $gift, $google-small, $google, $heart, $icon-popup-panel-arrow, $info-light, $info-outline, $info, $instagram, $l18, $lock-small, $mastercard, $medal-bronze-large, $medal-bronze, $medal-gold-huge, $medal-gold-large, $medal-gold, $medal-silver-large, $medal-silver, $medal, $microgaming, $mobiles, $moneta, $moneyru, $nav-divider, $netent, $ok-small, $ok, $okpay, $payeer, $perfectmoney, $play, $promsvyazbank, $purse, $qiwi-logo, $qiwi, $qiwi-c, $ruble-currency-sign, $safebox, $sberbank, $search, $shuffle, $slider-arrow-left, $slider-arrow-right, $spinner, $star-big-empty, $star-big, $star-white, $star, $starempty, $starhalf, $terminal-symbol-terminal-rus, $twitter-small, $twitter, $vip-1-medium, $vip-1-small, $vip-2-medium, $vip-2-small, $vip-3-medium, $vip-3-small, $vip-4-medium, $vip-4-small, $vip-5-medium, $vip-5-small, $vip-6-medium, $vip-6-small, $visa-logo, $visa, $visamastercard, $vkontakte-small, $vkontakte, $volume, $w1, $wallet, $webmoney, $webmoney-c, $yandex-logo, $yandex, $yandex-c, $yandex-soc, );
$spritesheet: (991px, 923px, '../img/sprite.png?v=2', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
