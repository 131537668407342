.checkbox {
  display: inline-block;
  vertical-align: top;
  position: relative;

  &__inner {
    display: none;
  }

  &__label {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    padding-left: 28px;
    cursor: pointer;
    display: block;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 17px;
      height: 17px;
      background-color: #09203a;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.38), 0 1px 0 #222130;
    }

    .checkbox__inner:checked + &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 17px;
      height: 17px;
      background-color: #ff8d00;
      background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
      border-radius: 1px;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 2px;
      left: 2px;
      @include sprite($checkmark);
      opacity: 0;
    }

    .checkbox__inner:checked + &:after {
      content: '';
      opacity: 1;
    }
  }

}

.checkbox_dark {

  .checkbox__label {
    font-size: 16px;
    line-height: 25px;
    padding-left: 34px;
    font-weight: 500;
    color: #ccc;

    &:before {
      width: 25px;
      height: 25px;
      background-color: #09203a;
      box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.11), inset 0 2px 4px 0 rgba(0, 0, 0, 0.68);
      border-radius: 2px;
    }

    &:after {
      width: 18px;
      height: 18px;
      background: none;
      content: '\2713';
    }
  }

  .checkbox__inner:checked + .checkbox__label:before {
    width: 25px;
    height: 25px;
    line-height: 25px;
    background: #09203a;
    box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.11), inset 0 2px 4px 0 rgba(0, 0, 0, 0.68);
    border: none;
  }

  .checkbox__inner:checked + .checkbox__label:after {
    content: '\2713';
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    bottom: 4px;
    left: 4px;
  }

}