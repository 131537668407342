.panel {
  display: table;
  position: relative;
  width: 100%;
  @include Small{
    display: block;
  }
  @include xSmall{
    display: block;
  }

  &__cell {
    display: table-cell;
    position: relative;
    vertical-align: middle;

    @include Small {
      display: inline-block;
      position: static;
    }
    @include xSmall {
      display: inline-block;
      width: 100%;
      position: static;
    }


    &_img {
      text-align: center;
      width: 1px;
      padding-right: 1px;

      @include Small {
        width: 260px;
      }

      @include xSmall {
        width: 100%;
      }
      .timer__note{
        color: #8aadee;
        font-weight: 100;
      }
    }

    &_content {
      vertical-align: top;
    }

    &_column {
      width: 40%;
      padding: 0 15px;
      vertical-align: top;
      color: #fff;
      @include Small{
        padding: 0 20px;
        &:nth-child(2){
            width: 390px;
          }
        &:nth-child(3){
          width: 100% ;
        }
      }
      @include xSmall{
        width: 100%;
      }
      .lottery__content &{
        @include Small{
          padding: 0;

        }
        @include xSmall{
          padding: 0;

        }
      }
    }
    .button_light{
      margin-top: 20px;
    }
  }

  &__overflow {
    display: block;
    position: absolute;
    height: auto;
    top: 0;
    left: 0;
    right: 1px;
    z-index: 2;

    @include xLarge {
      height: auto;
    }

    @include Large {
      height: auto;
    }

    @include xSmall {
      width: auto;
      height: auto;
    }
  }

  &__img {
    display: block;
    width: 100%;
    max-width: 279px;

    @include xLarge {
      max-width: 369px;
    }

    @include Large {
      max-width: 301px;
    }

    @include Small {
    }

    @include xSmall {
      max-width: 115px;
    }

  }
    .img_overflow {
      height: 245px;
      width: 250px;
      overflow: hidden;
      margin: 20px 0 0 20px;

      @include Small{
        height: initial;
        width: initial;
      }

      @include xSmall {
        width: 115px;
        min-height: 100px;
        height: initial;
      }

    }

  &__summary {
    position: relative;
    font-size: 0;
    height: 289px;

    @include xLarge {
      height: 363px;
    }

    @include Large {
      height: 301px;
    }

    @include xSmall {
      height: 100%;
    }


  }

  &__slider {
    position: relative;
    height: 144px;
    text-align: center;
    margin-top: 1px;
    background-color: #29476a;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07), 0 5px 5px rgba(0, 0, 0, 0.12);

    @include Small {
      margin-left: 280px;
      height: 133px;
    }

    @include xSmall {
      margin-top: 134px;
      z-index: 2;
    }
  }

  &__slider-inner {
    width: 560px;
    display: inline-block;
    vertical-align: top;
    margin-top: 28px;

    @include xLarge {
      width: 954px;
    }

    @include Large {
      width: 770px;
    }

    @include Small {
      width: 324px;
    }

    @include xSmall {
      width: 277px;
    }


    .slider__item {
      padding: 0 17px;
    }
  }

  &__info {
    width: 300px;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 25px 0 22px;

    @include xLarge {
      width: 598px;
    }

    @include Large {
      width: 470px;
    }

    @include Small {
      width: 389px;
      padding: 0;
    }

    @include xSmall {
      width: 100%;
      padding: 0;
    }
  }

  &__prize {
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: center;
    width: 314px;
    height: 100%;
    padding: 29px 19px 20px 19px;

    @include xLarge {
      width: 410px;
    }

    @include Large {
      width: 365px;
    }

    @include Small {
      width: 268px;
    }

    @include xSmall {
      width: 100%;
      height: 253px;
    }
  }

  &__icons {
    display: table;
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 40px;

    @include Large {
      margin-top: 20px;
    }

    @include Small {
      display: block;
      margin-top: 0px;
    }

    @include xSmall {
      display: block;
      margin-top: 20px;
    }
    &.bonus-name{
      margin-top: 20px;
      .panel__icon-title{
        font-size: 14px;
        line-height: 18px;
      }
      img{
        @include xSmall {
          height: 50px;
        }
        @include Small {
          height: 50px;
        }
      }
    }
  }

  &__icon-cell {
    vertical-align: top;
    text-align: center;
    display: inline-block;
    width: 32%;

    @include Small{
      display: inline-block;
      width: 32%;
    }

    @include xSmall {
      display: inline-block;
      width: 31%;
    }
    img{
      width: 80px;
      @include Medium{
        width: 60px;
      }
      @include Small{
        width: 50px;
      }
      @include xSmall{
        width: 50px;
      }
    }
  }

  &__icon {
    display: block;
    margin: 0 auto;
    padding: 26px 0 10px;
    text-shadow: 0 1px 0 #fff;
    color: #1f2934;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    @include sprite($medal-gold_large);

    .panel__icon-cell:last-child & {
      @include sprite($medal-bronze_large);
    }

    .panel__icon-cell:nth-child(2) & {
      @include sprite($medal-silver_large);
    }

  }

  &__icon-title {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.18);
    color: #fefefe;
    font-family: 'ClearSans', sans-serif;
    font-weight: 500;
    font-size: 14px;

    @include Small {
      display: block;
      vertical-align: top;
      line-height: 30px;
    }
  }

  &__header {
    display: block;
    position: relative;
    //white-space: nowrap;
    min-height: 54px;
    padding: 0 25px 16px;

    @include Small {
       min-height: 88px;
       padding-left: 108px;
       padding-top: 25px;
     }
    @include xSmall {
      min-height: 64px;
      padding: 0 15px 0 76px;
      display: table;
      vertical-align: middle;
      height: 64px;
      width: 100%;
    }
    &_noshadow {
      padding-bottom: 0;
      &:after {
        content: none;
      }
    }
  }

  &__timer {
    height: 114px;
    padding: 15px 0;
    margin-top: 5px;
    margin-left: 20px;
    @include xLarge {
      margin-bottom: 10px;
    }
    @include Large {
      margin-bottom: 10px;
    }
    @include Medium {
      margin-bottom: 10px;
    }
    @include xSmall {
      margin-left: 0;
    }


    .timer__note {
      margin: 0;
      padding: 0;
    }

    .timer__table {
      margin-top: 4px;
    }
  }

  &__countnote {
    float: left;
    font-family: 'ClearSans', sans-serif;
    padding-left: 5px;
  }

  &__countbutton {
    float: right;
    background-color: #388ad6;
    background-image: linear-gradient(180deg,#2b85c9,#193a62);
    box-shadow: 0 -1px 0 0 #79c6ff,0 2px 0 0 #152f50,inset 0 1px 7px 0 hsla(0,0%,100%,.3);
    border-radius: 2px;
    font-size: 12px;
    line-height: 20px;
    text-shadow: none;
    padding: 0px 8px;
    font-family: 'ClearSans',sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;

    @include Small {
      position: absolute;
      right: 17px;
      top: 30px;
      padding: 0 4px;
    }

    @include xSmall {
      position: absolute;
      right: 17px;
      top: 30px;
      padding: 0 4px;
    }
  }

  &__countdown {
    position: relative;
    clear: both;
    display: inline-block;
    vertical-align: top;
    padding-left: 3px;
    margin-top: 26px;

    @include Small {
      padding-left: 0;
    }

    @include xSmall {
      padding-left: 0;
      display: block;
      margin-top: 36px;
    }

    .countdown__divider {
      padding: 0 4px 0 2px;

      @include Small {
        padding: 0 1px 0 0;
      }
      @include xSmall {
        padding: 0 1px 0 0;
      }
    }

    .countdown__item {

      @include xLarge {
        width: 36px;
        background-size: 42px;
      }

      @include Medium {
        width: 24px;
        background-size: 30px;
        font-size: 20px;
        line-height: 34px;
      }

      @include Small {
        width: 24px;
        background-size: 30px;
        font-size: 20px;
        line-height: 34px;
        margin-right: 3px;
      }

      @include xSmall {
        width: 24px;
        background-size: 30px;
        font-size: 20px;
        line-height: 34px;
        margin-right: 3px;
      }

    }
  }

  &__title {
    margin: 0;
    font-family: 'ClearSans', sans-serif;
    display: inline-block;
    vertical-align: middle;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    //overflow: hidden;

    &_tournament {
      padding: 0 0 0 14px;
      //max-width: 100%;
      max-width: 401px;
      max-height: 38px;
      overflow: hidden;

      @include xLarge {
        max-width: 302px;
      }
      @include Large {
        max-width: 302px;
      }
      @include Medium {
        max-width: 138px;
      }

      @include Small {
        max-width: 138px;
      }

      @include xSmall {
        max-width: none;
        padding: 0 0 0 10px;
        display: table-cell;
        vertical-align: middle;
      }
    }
    &.title {
      font-size: 16px;
      line-height: 19px;
    }
    &_lottery {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.27);
      color: #ffd801;
      font-weight: 500;
      line-height: 28px;
      text-overflow: initial;
      white-space: normal;
      overflow: initial;
    }

  }

  &__caption {
    margin: 20px 0 0;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 17px;
    text-shadow: 0 1px 0 rgba(0,0,0,.38);
    max-height: 102px;
    overflow: hidden;
    padding: 0 25px;
  }


  &__status {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.11);
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 9px 13px;
    color: #fff;
    background-color: #14c471;
    background-image: linear-gradient(180deg, #18e581 0%, #0e9b5d 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 4px 8px rgba(0, 0, 0, 0.13);

    @include xSmall {
      display: table-cell;
      vertical-align: middle;
      text-indent: -9999px;
      font-size: 0;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;
      height: 11px;

      &:before {
        display: block;
        position: relative;
        content: '';
        width: 11px;
        height: 11px;
        background-image: linear-gradient(to bottom, #30e08a, #10a663);
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.31);
        border: solid 1px #29af73;
        text-indent: -9999px;
        border-radius: 50%;
        padding: 0;
      }
    }

    &_finished {
      background-color: #ff6526;
      background-image: linear-gradient(180deg, #ffb11a 0%, #ff0036 100%);
      box-shadow: none;

      @include xSmall {
        display: table-cell;
        vertical-align: middle;
        text-indent: -9999px;
        font-size: 0;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        height: 11px;

        &:before {
          display: block;
          position: relative;
          content: '';
          width: 11px;
          height: 11px;
          background-color: #fd7423;
          background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
          border: solid 1px #fd0834;
          text-indent: -9999px;
          border-radius: 50%;
          padding: 0;
        }
      }
    }
    &_future {
      background-color: #ffea00;
      background-image: linear-gradient(180deg, #ffea00 0%, #d69200 100%);
      box-shadow: none;

      @include xSmall {
        display: table-cell;
        vertical-align: middle;
        text-indent: -9999px;
        font-size: 0;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        height: 11px;

        &:before {
          display: block;
          position: relative;
          content: '';
          width: 11px;
          height: 11px;
          background-color: #ffea00;
          background-image: linear-gradient(180deg, #ffea00 0%, #d69200 100%);
          border: solid 1px #d69200;
          text-indent: -9999px;
          border-radius: 50%;
          padding: 0;
        }
      }
    }

  }

  &__info-button {
    display: block;
    position: relative;
    margin-top: 23px;
    padding: 0 25px;
    z-index: 3;

    @include xSmall {
      text-align: center;
    }
    .button {
      padding: 10px 11px 9px 13px;

      @include xSmall {
        white-space: nowrap;
      }
    }

    &_lottery {
      margin-top: 32px;
    }
  }

  &__arrow {
    display: inline-block;
    line-height: 12px;
    padding: 4px 0 0 6px;
    vertical-align: top;
  }

  &__tickets {
    display: block;
    position: relative;
    margin-top: 1px;
  }

  &__finished {
    display: block;
    position: relative;
    margin-top: 1px;
    padding: 16px;
    width: 100%;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.11);
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #fd7423;
    background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  }


}

.panel_lottery {

  .panel__title_lottery {
    @include xSmall {
      display: table-cell;
      padding-left: 13px;
    }
  }

  .panel__summary {
    height: 312px;

    @include xSmall {
      height: auto;
    }
  }

  .panel__overflow {
    height: 312px;
    width: 251px;

    @include Small {
      height: 88px;
      width: 88px;
    }

    @include xSmall {
      width: 64px;
      height: 64px;
    }


  }

  .panel__finished {

    @include Small {
      position: absolute;
      width: 660px;
      bottom: -4px;
      left: 0;
      height: 40px;
      font-size: 18px;
      right: 0;
      display: block;
      box-shadow: none;
      padding: 10px;
    }

    @include xSmall {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      left: 72px;
      top: 27px;
      padding: 0;
      text-indent: -9999px;
    }

  }


  .panel__img {
    height: 251px;
    width: 251px;

    @include Small {
      max-width: 88px;
      height: 88px;
    }

    @include xSmall {
      width: 64px;
      height: 64px;
    }

  }

  .panel__info {

    @include xLarge {
      width: 716px;
    }

    @include Large {
      width: 522px;
    }
  }


}