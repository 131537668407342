.timer {
  display: block;
  position: relative;

  &__note {
    text-shadow: 0 1px 0 #243344;
    color: #ffd801;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 5px;

    &_large {
      font-size: 14px;
    }
  }

  &__table {
    display: table;
    margin: 0 auto;
  }

  &__row {
    display: table-row;
  }

  &__cell {
    display: table-cell;

    .timer__row_digit & {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.26);
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: 'ClearSans', sans-serif;

      @include Small {
        font-size: 28px;
      }
    }

    .timer__row_caption & {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
      color: #ffd801;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }

    &_empty {
      padding: 0 12px 0 0;
    }
  }

}