.promo-details {
  display: block;
  position: relative;
  color: #ffffff;

  &__gift-panel {
    margin-bottom: 36px;
  }

  &__dropdown {
    display: block;

    @include xSmall {
      display: none;
    }

  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: #0e2035;
    box-shadow: 0 1px 0 #1d3b5e;
    margin: 21px 0 22px;
  }

  &__title {
    padding-left: 3px;
    position: relative;
    text-shadow: 0 2px 0 rgba(0,0,0,.2);
    font-family: 'ClearSans',sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0 21px 0;
  }
  h1, h2 , h3, h4, h5 {
    text-shadow: 0 2px 0 rgba(0,0,0,.2);
    font-family: 'ClearSans',sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0 21px 0;
    &:first-child {
      border-bottom: #0e2035;
      box-shadow: 0 0 1px 0 #1d3b5e;
    }
  }
  p {
    margin: 0 0 14px 0;
    font-size: 16px;
    font-family: 'ClearSans',sans-serif;
    line-height: 18px;
  }
  &__bullet-list.bullet-list, ul {
    margin: 21px 0 38px 0;
    li {
      position: relative;
      color: #fff;
      font-weight: 400;
      padding-left: 28px;
      text-shadow: 0 2px 0 rgba(0, 0, 0, .29);
      font-family: 'ClearSans', sans-serif;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 18px;
      list-style-type: none;
      &:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        left: 0;
        top: 4px;
        width: 12px;
        height: 12px;
        background-color: #fda31b;
        background-image: linear-gradient(170deg, #fda31b, #fd0834);
        box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .11), 0 0 16px rgba(0, 0, 0, .26), 1.4px 2.7px 1px rgba(0, 0, 0, .18);
      }
    }
  }

  &__content {
    font-size: 16px;
    color: #fff;
  }

  &__button {
    display: none;
    position: absolute;
    right: 0;
    top: 1px;
    width: auto;
    background-color: #185e9c;
    font-size: 12px;
    line-height: 20px;
    text-shadow: none;
    text-align: center;
    padding: 0 15px;
    font-family: 'ClearSans',sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    height: 21px;
    border-radius: 1px;
    background-image: linear-gradient(to bottom, #2b85c9, #193a62);
    box-shadow: 0 -1px 0 0 #79c6ff, 0 2px 0 0 #152f50, inset 0 1px 7px 0 rgba(255, 255, 255, 0.3);

    @include xSmall {
      display: block;
    }
  }

}