.clock-timer {
  width: 99px;
  height: 49px;
  position: relative;
  background-color: #09203a;
  border-radius: 2px;
  box-shadow: inset 0 1px 0 rgba(4, 4, 4, 0.24), 0 1px 0 #302f43;

  &__icon {
    position: absolute;
    top: 13px;
    left: 17px;
  }

  &__counter {
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 15px;
    text-align: right;
  }

}