.bonus-panel {
  position: relative;
  display: table;
  font-size: 0;
  width: 100%;
  min-height: 240px;
  background-color: #09203a;
  border-radius: 1px;
  box-shadow: inset 0 -3px 0 #09203a, 0 3px 8px rgba(0, 0, 0, 0.19);

  @include Medium {
    min-height: 255px;
  }
  @include Small {
    min-height: 255px;
  }
 @include xSmall {
    padding: 20px 14px;
  }

  &__view {
    display: table-cell;
    vertical-align: middle;
    padding-left: 27px;
    position: relative;
    width: 24%;

    @include xLarge {
      width: 20%;
      text-align: center;
    }

    @include xSmall {
      display: block;
      padding-left: 0;
      text-align: center;
      width: auto;
    }
  }

  &__img {
    width: 180px;

    @include Small {
      width: 140px;
    }
  }

  &__info {
    display: table-cell;
    vertical-align: top;
    padding-left: 20px;
    padding-bottom: 46px;
    padding-top: 20px;

    @include xSmall {
      display: block;
      padding-left: 0;
      padding-bottom: 0;
    }
  }

  &__action {
    display: table-cell;
    text-align: center;
    position: relative;
    width: 30%;
    min-width: 300px;

    @include Small {
      width: 214px;
      min-width: inherit;
    }

    @include xSmall {
      display: block;
      width: 100%;
      min-width: inherit;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 110px;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 314px;
      height: 153px;
      opacity: 0.4;
      background-image: radial-gradient(164px 86px at 50% 50%, #e31933 0%, transparent 80%, transparent 100%);

      @include Small {
        display: none;
      }

      @include xSmall {
        top: 73px;
      }


    }
  }

  &__timer {
    margin-top: 15px;
  }

  &__button {
    display: inline-block;
    vertical-align: top;
    margin-top: 33px;
    margin-bottom: 15px;

    &_large {
      padding: 15px 21px;
      line-height: 20px;
      font-size: 18px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);

      @include xSmall {
        margin-bottom: 80px;
      }

    }

  }

  &__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.32);
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    text-align: left;
    margin: 11px 0 0;


    @include xSmall {
      padding: 20px 0;
      text-align: center;
    }

    .bonus-panel__action & {
      text-align: center;

      @include Small {
        white-space: normal;
        margin: 30px 0;
        padding: 0 10px;
      }

      @include xSmall {
        margin: 20px 0;
        padding: 0 5px;
        white-space: normal;
      }
    }

    &_alert {
      font-size: 14px;
      color: #ff1632;
      white-space: nowrap;
      margin: 38px 30px 0;
      text-align: center;
    }

    &_important {
      font-size: 14px;
      color: $yellow;
      white-space: nowrap;
      margin: 38px 30px 0;
      text-align: center;
    }

    &_important {
      font-size: 14px;
      color: $yellow;
      white-space: nowrap;
      margin: 38px 30px 0;
      text-align: center;
    }

  }

  &__note {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.24);
    color: #cacad4;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 22px;
    margin: 14px 0 0 0;

    @include xSmall {
      margin: 0;
    }
  }

  &__tooltip {
    display: block;
    position: absolute;
    width: 180px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #f36467, #e22624);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8), inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    padding: 10px;
    text-transform: none;
    bottom: -3px;
    right: 100%;
    margin-right: 20px;
    line-height: 18px;

    @include xSmall {
      bottom: -78px;
      right: 50%;
      transform: translateX(50%);
      margin-right: 0;
    }
  }

  &__arrow {
    display: block;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 100%;
    transform: rotate(90deg);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

    @include xSmall {
      top: -6px;
      left: calc(50% - 7px);
    }

    &:after {
      content: " ";
      position: absolute;
      bottom: 100%;  /* At the top of the tooltip */
      left: 50%;
      z-index: 3;
      margin-left: -7px;
      border-style: solid;
      border-width: 7px;
      border-color: transparent transparent #EC4849 transparent;

      @include xSmall {
        border-color: transparent #f36467 transparent transparent;
      }
    }

    &_right {
      right: 5px;
      left: auto;
      &:after {
        right: 5px;
        left: auto;
      }
    }

  }

  &__informer {
    width: 224px;
    height: 56px;
    margin: 20px auto 0;
    border-radius: 3px;
    background-image: linear-gradient(to top, #f2d42a, #f3db53);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 rgba(255, 255, 255, 0.3);
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    color: #140602;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
    padding: 12px;

    @include  Small {
      width: 182px;
      padding: 5px;
    }
    &_green {
      width: 147px;
      height: 46px;
      border-radius: 3px;
      background-image: linear-gradient(to top, #89b035, #91b83c);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 0 1px 1px rgba(8, 23, 4, 0.5);
      padding: 15px;
    }
  }
}