.chosen-container-single .chosen-single {
  background: #6d6c84;
  border: none;
  border-radius: 0;
  height: 35px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  color: #fff;
  font-family: 'ClearSans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
  padding: 0 0 0 14px;
}

.chosen-container-single .chosen-single div b {
  @include sprite($arrow-down-white);
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
}

.chosen-container.chosen-with-drop .chosen-single div b {
  @include sprite($arrow-up-white);
}

.chosen-container-single .chosen-drop {
  background: #6d6c84;
  border-radius: 0;
  border: none;
  margin-top: 0;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  background: #6d6c84;
  border: none;
  box-shadow: none;
}


.chosen-container .chosen-results {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
  font-family: 'ClearSans', sans-serif;
  padding: 0;
  margin: 0;

  .active-result {
    padding: 10px 14px;
  }
}

.chosen-container .chosen-results li.highlighted {
  background-color: #398cd7;
  background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.18), 0 8px 10px rgba(0, 0, 0, 0.16);
}

.chosen-container .chosen-results li.disabled-result {
  display: none;
}
