.gift-panel {
  display: block;
  position: relative;
  padding-bottom: 26px;

  &__content {
    display: table;
    width: 100%;
    height: 206px;
    background-color: #29476a;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);

    @include Medium {
      height: 234px;
    }

    @include Small {
      height: 270px;
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    padding: 23px 20px 16px 10px;

    @include xSmall {
      display: block;
      padding: 20px;
    }

    &_img {
      width: 1px;
      padding: 23px 16px 16px 23px;

      @include xSmall {
        width: auto;
        text-align: center;
        padding: 20px;
      }
    }
  }

  &__img {
    width: 175px;


    @include Small {
      width: 116px;
    }

  }

  &__caption {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    line-height: 22px;

    @include xLarge {
      padding-right: 135px;
    }

    @include Medium {
      padding-right: 35px;
    }
  }

  &__footer {
    height: 47px;
    margin-top: -1px;
    background-color: #243f5e;
    border: 1px solid #2e4d72;
  }

  &__button {
    position: absolute;
    bottom: 6px;
    padding: 11px 25px;
    left: 50%;
    white-space: nowrap;
    border-radius: 23px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-shadow: 0 1px 0 rgba(4, 4, 4, 0.26);
    font-size: 20px;
    font-weight: 700;
  }
}