.profile-password {
  padding: 35px 40px 35px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px rgba(255,255,255,0.1);
  }

  &__title {
    display: block;
    margin-bottom: 29px;
    &_left {
      text-align: left;
    }
  }

  &__action {
    margin: -8px;
    font-size: 0;
  }

  &__input {
    display: inline-block;
    vertical-align: top;
    width: 33.3%;
    padding: 8px;

    .input__inner {
      width: 100%;
      height: 53px;
      padding: 14px 17px 14px;
      background-color: #141320;
      color: #fff;
      font-size: 16px;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.13), 0 1px 0 #222130;
    }
  }

}