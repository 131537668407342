.tab {
  display: block;
  position: relative;
  &__close {
    width: 17px;
    height: 17px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 31px;
    right: 26px;
    text-align: center;
    padding: 0;
    cursor: pointer;
  }
  &__action {
    display: block;
    position: relative;
    height: 70px;
    font-size: 0;
    background-color: #09203a;
    box-shadow: inset 0 1px 0 rgba(61, 68, 75, 0.35);

    @include xSmall {
      height: 43px;
    }

  }


  &__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 24px 47px;
    text-shadow: 0 2px 0 rgba(0,0,0,.42);
    font-family: 'ClearSans',sans-serif;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #727f8c;

    @include Small {
      padding: 24px 34px;
    }

    @include xSmall {
      font-size: 14px;
      padding: 12px;
    }

    &:before {
      content: "-";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }

    &:first-child:before {
      content: none;
    }

    &_active + &:before {
      content: none;
    }

    &:hover {
      color: #fff;
      &:after {
        color: #727f8c;
      }
    }

    &_active {
      color: #fefefe;
      background-color: #091420;
      box-shadow: inset 0 3px 0 #ff9600;
      border-radius: 3px 3px 0 0;
      &:before {
        content: none;
      }
    }
  }

  &__content {
    display: block;
    position: relative;

    .popup .tab__content {
      background-color: #222131;
    }
  }

}

// Style for payment tabs

.tab_style_button {
  display: block;
  position: relative;

  .tab__action {
    height: 58px;
    box-shadow: none;
    background: none;
  }

  .tab__content {
    margin-top: 25px;
    background-color: transparent;
  }

  .tab__item {
    width: 200px;
    padding: 18px 10px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.09);
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    background-color: #09203a;
    border-radius: 0;
    box-shadow: none;
    border-left: 1px solid #1b1a27;

    @include xSmall {
      width: 32%;
      font-size: 14px;
    }

    &:before {
      content: none;
    }
    &:first-child {
      margin-right: 0;
    }

    &_active {
      background-color: #e2d533;
      background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
      text-shadow: 1px 1px 4px rgb(58, 4, 4);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);


      & ~ .tab__item {
        &:after {
          content: none;
        }
      }
    }
  }

}


