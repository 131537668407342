.title {
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  font-family: 'ClearSans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  color: #fff;
  margin: 0;

  &_color_accent {
    color: $textaccent;
  }

  &_color_important {
    color: #ff1e00;
  }

  &_font_hugest {
    font-size: 30px;
    font-weight: 500;
    font-family: 'ClearSans', sans-serif;
  }

  &_font_huge {
    font-size: 30px;
  }

  &_font_largest {
    font-size: 23px;
  }

  &_font_large {
    font-size: 19px;
  }

  &_font_smaller {
    font-size: 15px;
  }

  &_font_smallest {
    font-size: 14px;
  }

  &_family_base {
    font-family: 'ClearSans', sans-serif;
  }

  &_align_center {
    text-align: center;
  }

}