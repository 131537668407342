.preview {
  position: relative;
  background-color: #08203a;
  border: 1px solid #15355a;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  &__item {
    display: block;
    position: relative;
  }

  &__img {
    max-width: 100%;
    width: 100%;
    display: block;
    max-height: 188px;
  }

  &__overlay {
    display: block;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all ease 0.1s;
    z-index: -1;

    .preview__item:hover & {
      background-color: rgba(0, 0, 0, 0.73);
      box-shadow: inset 0 0 2.9px 1.1px rgba(255, 255, 255, 0.28), inset 0 1px 0 rgba(255, 255, 255, 0.08), 0 2px 1px rgba(13, 25, 39, 0.37);
      opacity: 1;
      z-index: 1;
    }
  }

  &__action {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__button {
    font-size: 15px;
    width: 105px;
    height: 34px;
    padding: 7px 15px;
    display: inline-block;
    white-space: nowrap;
    &_demo {
      padding: 6px 9px;
      margin-top: 11px;
    }
  }

  &__icon {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 12px;
    cursor: pointer;
  }
  &__icon.fa {
    font-size: 25px;
    color: #ffffff;
  }
  &__icon.fa.in_favorites {
    color: #fff001;
  }

  &__info {
    padding: 10px 13px;
    text-align: left;
  }

  &__title {
    text-shadow: 0 2px 0 #111c28;
    color: #a2bedc;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    max-width: calc(100% - 40px);
  }

  &__note {
    text-shadow: 0 1px 0 #0a1829;
    color: #4d7bb0;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 5px 0 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px);

    @include xSmall {
      max-width: 100%;
    }

  }
}