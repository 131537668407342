.Zebra_DatePicker {
  background-color: #132e4d;
  box-shadow: 0 0 0 1px rgba(255,255,255, 0.2),0 -1px 0px 2px #132e4d;
}
.Zebra_DatePicker.dp_visible {
  border-radius: 2px;
  border: none;

}
.Zebra_DatePicker * {
  color: #ffffff;
}
.Zebra_DatePicker td.dp_hover, .Zebra_DatePicker .dp_header td.dp_hover {
  background-color: #353535;
  border-radius: 1px;
  color: #ffffff!important;
}
.Zebra_DatePicker td.dp_selected {
  background-color: #6a9500;
  border-radius: 1px;
 // box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.18), 0 8px 10px rgba(0, 0, 0, 0.16);
  color: #FFF !important;
}

.Zebra_DatePicker td.dp_current {
  color: #ffffff;
}
