.profile-info {
  display: block;
  position: relative;
  text-align: center;

  &__title {
    display: block;
    position: relative;
    padding: 43px 10px 21px 19px;

    @include xSmall {
      padding: 27px 74px 21px 84px;
      text-align: left;
    }
  }

  &__caption {
    display: block;
    position: relative;
    font-size: 16px;
    text-align: center;
    padding: 0 10px 0 21px;

    @include xSmall {
      display: none;
    }
  }

  &__status {
    display: inline-block;
    padding-left: 10px;
    padding-top: 30px;
    position: relative;

    @include xSmall {
      display: block;
      padding-left: 20px;
      padding-top: 24px;
      position: absolute;
      top: 0;
    }

    .status {

      img {
        width: 90px;
        margin: 0 auto;
      }

      .status__note {
        @include xSmall {
          display: none;
        }
      }
    }

    @include xSmall {
      .status {
        width: 50px;
        height: 54px;

        img {
          display: block;
          width: 30px;
          height: 30px;
          margin: 0 auto;
        }
      }

      .status__icon {
        min-width: 30px;
        top: 2px;
      }

      .status__note {
        font-size: 11px;
      }
    }



  }

  &__rating {
    display: block;
    position: relative;
    padding-bottom: 31px;

    .rating__stars {
      width: auto;
    }


    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg,#132e4d,#52baff 50%,#132e4d);
      box-shadow: 0 1px 1px rgba(255,255,255,0.1);
    }
  }

  &__bonus {
    display: block;
    position: relative;
    padding-bottom: 31px;
    padding-top: 32px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(0,0,0,0.2);
      box-shadow: 0 1px 1px rgba(255,255,255,0.1);
    }
  }

  &__action {
    display: block;
    position: relative;
    padding: 41px 20px 41px 28px;

    @include xSmall {
      position: absolute;
      top: 24px;
      right: 20px;
      padding: 0;
    }
  }

  &__button {
    font-family: "ClearSans", sans-serif;
    background-image: linear-gradient(140deg, #ff8d00 0%, #e2d533 100%);
    text-shadow: 1px 1px 4px rgb(58, 4, 4);
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    font-size: 20px;
    padding: 10px 15px;

    @include xSmall {
      width: 45px;
      height: 45px;
      font-size: 0;
    }
  }

  &__icon {
    display: none;

    @include xSmall {
      display: block;
      position: absolute;
      top: 14px;
      right: 9px;
      pointer-events: none;
    }
  }

}