.footer {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 37px;
  text-align: center;

  &__head {
    width: 100%;
    height: 93px;
    background-color: #091b2f;
    box-shadow: inset 0 1px 0 0 #18385f;
    text-align: center;
    margin-top: 178px;

    @include xSmall {
      width: 100%;
      height: 43px;
      margin-top: 76px;
    }
  }

  &__logo {
    display: block;
    top: -80px;
    left: 50%;
    margin-left: 36px;
    transform: translateX(-50%);
    position: absolute;
    z-index: 2;
    width: 215px;
    height: 140px;
    

    @include xSmall {
      width: 120px;
      height: 85px;
      top: -48px;
      margin-left: 0;
    }
  }

  &__nav {
    background-color: #102a47;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.24);
    text-align: center;

    @include xSmall {
      padding: 6px 4px;
    }

    @include xSmall {
      .nav {
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 300px;
      }
    }

    &:before {
      content: "";
      width: 45%;
      margin: 0px auto;
      height: 3px; 
      background-image: linear-gradient(90deg,#a1efeb0f 0%,#52baff 50%,#a1efeb0f 100%);
      display: block;
      -webkit-box-shadow: 0 29px 84px 3px #1fa9db;
      -moz-box-shadow: 0 29px 84px 3px #1fa9db;
      box-shadow: 0 29px 84px 3px #1fa9db;

      @include xSmall {
        margin-top:-6px;
        margin-bottom: 8px;
      }
    }

  }

  &__icons {
    display: block;
    margin: 30px auto;
  }

  &__cell {
    display: inline-block;
    vertical-align: middle;
    padding-right: 18px;
    &:last-child {
      padding-right: 0;
    }

  }

  &__rules {
    color: #435872;
    font-size: 12px;
    font-weight: 400;
    max-width: 720px;
    margin: 0 auto;
  }
}