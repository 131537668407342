.gameplay-signup {
  display: block;
  position: relative;
  text-align: center;
  //padding: 0 26px;
  padding: 0;

  &__title {
    font-size: 20px;
    font-weight: 500;
    padding-top: 9px;
    line-height: 23px;
    .gold {
      color: #f2e419;
    }
    & + & {
      padding-top: 0;
    }

    &_huge {
      font-size: 25px;
      font-weight: 700;
      padding-top: 35px;
    }
    &_signin {
      margin-top: 20px;
    }
  }

  &__jackpot {
    margin-top: 25px;
  }

  &__input {
    margin-top: 37px;
    .input__inner {
      width: 100%;
      height: 54px;
      background-color: #0e2744;
      border-radius: 2px;
      box-shadow: inset 0 1px 0 #0d2035, 0 1px 0 #1c4371;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-family: "ClearSans", sans-serif;
    }
    & + & {
      margin-top: 12px;
    }
  }

  &__button {
    margin-top: 33px;
    padding: 10px 20px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
    color: #fefefe;
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
    font-family: "ClearSans", sans-serif;
    background-color: #fd7123;
    background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

    &_signin {
      margin-top: 46px;
      width: 219px;
      padding: 10px 24px;
      & + & {
        margin-top: 19px;
        background-color: #3687d6;
        background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
      }
    }

  }
  .gameplay-user__button {
    width: 194px;
  }
  &__caption {
    font-size: 16px;
    margin-top: 25px;
    .gameplay-signup__button_signin + & {
      margin-top: 55px;
    }
  }

  &__socials {
    margin-top: 20px;
    }


}
