.popup_re-call{
  background-image: linear-gradient(180deg,#152740,#070829);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  z-index: 202;
  .popup__close{
    background: transparent;
    box-shadow: none;
    margin-top: 7px;
  }
  .popup__head{
    background: #00142c;
  }
  .popup__title{
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 15px 15px;
  }
  .popup__content{
    font-size: 18px;
    padding: 0 12% 20px;
    a{
      color: #ffcc00;
    }
    input{
      background-color: #070929;
      margin: 15px auto;
      padding: 12px;
      color: #a0a5be;
      border: 1px solid #083665;

      &:focus {
        outline: 0;
      }
    }
    button {
      background-image: linear-gradient(180deg,#e2d533 , #ff8d00);
      box-shadow: 0px 0px 16px 9px #e4cf1757, inset 0 2px 0 0 rgba(247,204,69,.004);
      width: 232px;
      height: 51px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      display: block;
      margin: 10px auto 10px;
      color: #8e231f;
      font-size: 18px;
      text-transform: uppercase;

      &:focus {
        outline: 0;
      }
      }

    & > span{
      cursor: pointer;
      text-decoration: underline;
      color: rgb(66, 73, 82);
    }
  }
  #re-call-not-phone-error{
    color: red;
    text-decoration: none;
  }
}
.overflow.re-call_overflow{
  z-index: 201;
}