.wish-bonus,
.webim-button-slider{
        z-index: 99 !important;
        @include xSmall {
          z-index: 100 !important;
        }
}
.wish-bonus {
  display: block;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 44px;
  min-height: 191px;
  transition: width ease-in-out 0.3s;
  z-index: 200;

  &.open {
    width: 760px;
    z-index: 200;

    @include Small {
      width: 700px;
    }

    @include xSmall {
      width: 100%;
    }

    .wish-bonus__content-block {
      transform: translateX(0);
      opacity: 1;
    }
    .wish-bonus__close {
      opacity: 1;
    }
  }

  &__close {
    width: 44px;
    height: 38px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    text-align: center;
    padding: 11px 0;
    cursor: pointer;
    @include xSmall{
      left: 0;
      right: auto;
    }
  }

  &__content-block {
    display: block;
    width: 760px;
    opacity: 0;
    transform: translateX(-716px);
    transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s;
    background-color: #132e4d;
    box-shadow: 8px 6px 8px #040f21, inset 0 1px 0 #132e4d;

    @include Small {
      width: 700px;
    }

    @include xSmall {
      width: 100%;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__bonus-label {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 44px;
    height: 190px;
    z-index: 2;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
    background-image: linear-gradient(180deg, #1678be 0%, #1c4480 100%);
    box-shadow: 0 0 6px 8px rgba(0,0,0, 0.2), inset 0 1px 0 #4d98ce;
    transition: left ease-in-out 0.2s;

    .wish-bonus.open & {
      left: -60px;
    }

    @include xSmall {
      height: 110px;
      width: 34px;
    }
    span {
      display: block;
      white-space: nowrap;
      position: relative;
      width: 100%;
      color: #fff;
      font-size: 17px;
      top: -7px;
      font-weight: 700;
      transform-origin: 85px 75px;
      transform: rotate(-90deg);
      text-transform: uppercase;

      @include xSmall {
        font-size: 11px;
        top: 0px;
        transform-origin: 52px 42px;
        letter-spacing: 0px;
        font-family: sans-serif;
      }
      &.small-txt{
        font-size: 14px;
        top: 0px;
  
        @include xSmall{
          font-size: 9px;
          top: 1px;
        }
      }
    }
  }

  &__head {
    width: 100%;
    &:after {
      display: none;
    }
  }

  &__title {
    text-align: center;
    text-transform: none;
    padding: 18px 40px 7px;
    text-shadow: 0 2px 1px rgba(9, 32, 58, 0.83);
    color: #fff000;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;

    @include xSmall {
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__subtitle {
    display: block;
    color: #fff;
    text-transform: none;
    text-align: center;
    padding: 0 40px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

    @include xSmall {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__content {
    text-align: center;
    margin-top: 10px;
  }

  &__input {
    height: 42px;

    .input__inner {
      width: 192px;
      color: #e5e2f3;
      height: 42px;
      box-shadow: 0 1px 1px rgba(43, 42, 70, 0.46), inset 0 1px 1px rgba(0, 0, 0, 0.79);
      border-radius: 2px;
      background-color: #133966;

      @include xSmall {
        width: 170px;
      }
    }
  }

  &__button {
    min-width: 70px;
    height: 42px;
    margin-top: 0;
    text-shadow: 0 1px 0 #be2f21;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 288px;
  }

  &__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
  }

  &__bonus-note {
    display: block;
    vertical-align: top;
    color: #83a6d1;
    font-size: 11px;
    font-weight: 400;
    padding-left: 30px;
  }

  &__link {
    display: inline-block;
    vertical-align: top;
    line-height: 42px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    color: #9cc1e2;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    margin-left: 28px;
    cursor: pointer;

    @include xSmall {
      display: block;
      margin: 0;
    }
  }

}