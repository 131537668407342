[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none ;
}
.wrap {
  @include xSmall{
    width: 97%;
  }
}

.section__promo {
  background: transparent;
}
.lottery-details-promo {
  display: block;
  position: relative;

  &__header {
    display: block;
    position: relative;
    text-align: center;
    margin: 0 auto 44px;
    padding-top: 17px;
    @include xSmall {
      //width: 233px;
      width: 90%;
      margin-bottom: 20px;
    }
  }



  &__autorisation {
    margin: 19px auto;
    .button__outer {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      @include xSmall {
        display: block;
        margin-bottom: 35px;
      }
    }
    .registration__text {
      display: block;
      color: #b2b2b2;
      font-family: 'ClearSans', sans-serif;
      font-size: 18px;
      font-weight: 400;
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 100%;
      text-align: center;
      @include Small {
        font-size: 14px;
      }
      @include xSmall {
        font-size: 14px;
      }
    }


  }
  .registration__button, .deposit__button, .layout_lottery-promo__popup .reg__button {
    display: inline-block;
    border: none;
    vertical-align: middle;
    padding: 0 10px;
    width: 297px;
    height: 50px;
    margin: 0;
    background-image: linear-gradient(349deg, rgba(253,8,52,1) 0%, rgba(253,42,47,1) 21%, rgba(253,103,36,1) 45%, rgba(253,151,28,1) 67%, rgba(253,160,27,1) 71%, rgba(253,163,27,1) 100%);
    border-radius: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    cursor: pointer;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 50px;
    transition: opacity 0.3s ease;
    &:hover {
      background-image: linear-gradient(165deg, rgba(253,8,52,1) 0%, rgba(253,42,47,1) 21%, rgba(253,103,36,1) 45%, rgba(253,151,28,1) 67%, rgba(253,160,27,1) 71%, rgba(253,163,27,1) 100%);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
    }
    &:active, &:focus {
      outline: none;
      background-image: linear-gradient(178deg, #d86512 0%, #8b0c0d 100%);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
    }
    @include xSmall {
      width: 212px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }
  }
  .deposit__button {
    margin-top: 20px;
  }
  .login__button {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 100px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #22384b;
    border-radius: 3px;
    box-shadow: none;
    font-family: "ClearSans", sans-serif;
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 700;
    line-height: 50px;
    padding: 0;
    &:hover, &:active, &:focus {
      background: #0b2238;
      color: #6bb5ff;
    }

  }
  &__slider {
    //min-height: 510px;
    background-color: #15355a;
    border: 1px solid #38608e;
    overflow: hidden;
    box-shadow: 0 7px 2px rgba(0, 0, 0, 0.13), inset 1px 0 0 #214268, inset 0 1px 0 #214268;
    margin-bottom: 20px;
    @include Medium {
      //min-height: 450px;
    }
    @include Small {
      //min-height: 375px;
    }
    @include xSmall {
      //min-height: 471px;
    }
    .slider__title {
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 25px;
      font-weight: 700;
      line-height: 26px;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      overflow: visible;
      @include Medium {
        font-size: 20px;
      }
      @include Small {
        font-size: 18px;
      }
      @include xSmall {
        font-size: 12px;
        padding: 0 0 0 80px;
      }
    }
    .slider__date {
      width: 110px;
      height: 45px;
      background-color: rgba(255, 255, 255, 0.08);
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 45px;
      position: absolute;
      text-align: center;
      top: -5px;
      left: 0;
      @include Small {
        width: 75px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 700;
      }
      @include xSmall {
        width: 65px;
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        font-weight: 700;

      }
    }
    .slick-slider__item.top-slider{
      .slick-slider__description {
        @include Small {
          padding: 0 40px 0 0;
        }
      }
      .slick-slider__counter.timer-box {
        @include xSmall {
          margin: 5px auto;
        }
        //border: solid 1px red;
      }
      .slick__img {
        width: 500px;
        max-width: 500px;
        max-height: inherit;
        @include Small {
          width: 350px;
          max-width: 350px;
          padding: 0;
        }
        @include xSmall {
          width: 300px;
          max-width: 300px;
        }

      }

    }
    .slick-slider__item.finished .slider__date  {
      color: #fff001;
      &:before {
        content: '';
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 19px;
        height: 14px;
        background: url("../img/promo/checked_item.png") no-repeat ;
        background-size: contain;
        margin-left: -3px;
      }
    }
    .slick-slider__item.active .slider__date, .slick-current.slick-active.active .slider__date{
      background-color: #3668a2;
      box-shadow: inset 0 1px 0 #5a88bd;
    }
    .slick-slider__item.top-slider.future .slick-slider__model {
      margin-bottom: 30px;
    }

    .slick-slider {
      margin: 10px;
      &.top-slider {
        padding-bottom: 70px;
      }

      @include xSmall{
        margin: 10px 10px;
      }
    }


    .slick-slider__item {
      outline: none;
      display: flex;
      justify-content: center;
      padding: 75px 88px 0;
      position: relative;
      //min-height: 338px;
      @include Small {
        padding-top: 45px;
      }
      @include xSmall {
       flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-top: 45px;
        padding-bottom: 10px;
      }
    }
    .slick-slider__img {
      flex: 0 0 50%;
      padding: 10px 20px;
      align-items: center;
      display: flex;
      justify-content: center;

      @include Small {
        flex: 0 0 40%;
        //padding: 0;
      }
      @include xSmall{
        //margin-bottom: 70px;

      }

      .slick__img {
        max-width: 408px;
        max-height: 273px;
        @include Medium {
          max-width: 350px;
          max-height: 190px;
        }
        @include Small {
          max-width: 255px;
          max-height: 160px;
        }
        @include xSmall {
          max-width: 253px;
          max-height: 136px;
        }
      }

      /*.slick__img {
        display: inline-block;
        max-width: 408px;

        @include Medium {
          max-width: 287px;

        }
        @include Small {
          max-width: 220px;
        }
        @include xSmall {
          max-height: 176px;
          overflow: hidden;
          }
      }*/
    }
    .slick-slider__description {
      flex: 0 0 50%;
      padding: 0 20px;
      @include xSmall {
        flex: 0 0 auto;
        align-self: center;
        padding-bottom: 10px;
        text-align: center;
        //min-width: 300px;
      }
    }
    @include Small {
      flex: 0 0 60%;
      padding: 0 10px 0 0;
    }
    .slick-slider__subtitle {
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 35px;
      font-weight: 500;
      line-height: 35px;
      @include Medium {
        font-size: 28px;
        line-height: 28px;

      }
      @include Small {
        font-size: 14px;
        line-height: 20px;
      }
      @include xSmall {
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }
    }
    .slick-slider__title {
      color: #fff001;
      font-size: 35px;
      font-weight: 700;
      font-family: 'ClearSans', sans-serif;
      line-height: 35px;
      padding: 12px 0;
      @include Medium {
        font-size: 28px;
        line-height: 28px;
      }
      @include Small {
        font-size: 20px;
        line-height: 24px;
        padding: 5px 0;
      }
      @include xSmall {
        font-size: 16px;
        line-height: 18px;
        text-align: center;
      }
    }
    .slick-slider__model{
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 18px;
      font-weight: 500;
      @include Small {
        font-size: 14px;
      }
      @include xSmall {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
      }
    }

    .slick-slider__counter {
      width: 292px;
      //min-height: 127px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.08);
      padding: 20px 20px 8px 20px;
      margin: 20px 0 15px;
      &.timer-box {
        @include xSmall {
          height: auto;
          min-height: auto;
          padding: 15px 20px 0 20px;
        }
      }
      &.winners {
        @include Medium {
          width: 100%;
        }
        @include Small {
          width: 100%;
        }
        @include xSmall {
          width: 100%;
          padding: 6px 10px;
        }
        .slick-slider__text, .slick-slider__text.txt-winner {
          @include Small {
            font-size: 13px;
            line-height: 20px;
          }
          @include xSmall {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
      @include Medium {
        width: 242px;
        //height: 100px;
      }
     @include Small {
       padding: 20px 20px 8px 20px;
       width: 242px;
       //height: 100px;
       margin: 10px 0;
      }
     @include xSmall {
       padding: 10px;
       width: 200px;
       height: 78px;
       margin: 5px 0;
       text-align: center;

      }
      .slick-slider__text{
        margin-top: 0;
      }
      .slick-slider__text.txt-winner {
        color: #fff;
        font-size: 16px;
        font-weight: normal;
      }
    }
    .slick-slider__note,.slick-slider__text  {
      color: #a2bedc;
      font-family: 'ClearSans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
      text-align: left;
      &.slick-slider__note_date {
        @include Small {
          display: none;
        }
        @include xSmall {
          display: none;
        }
      }

    }
    .slick-slider__text {
      margin-top: 30px;
    }
    .slick-slider__winners {
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      text-align: left;
      @include xSmall {
        font-size: 14px;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
    .counter__title {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
      color: #a2bedc;
      font-family: 'ClearSans', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 13px;
      @include Medium {
        font-size: 14px;
      }
      @include Small {
        font-size: 14px;
      }
      @include xSmall {
        font-size: 11px;
      }
    }
    .slick-slider__timer {
      padding: 10px 0 9px;
      @include Medium {
        padding: 5px 0;
      }
      @include Small {
        padding: 5px 0;
      }
      @include xSmall {
        padding: 5px 0;
        display: inline-block;
      }
      .timer__cell {
        text-shadow: 0 3px 0 rgba(0, 0, 0, 0.25);
        color: #f0efef;
        font-family: 'ClearSans', sans-serif;
        font-size: 40px;
        font-weight: 700;
        @include Medium {
          font-size: 30px;
        }
        @include Small {
          font-size: 30px;
        }
        @include xSmall {
          font-size: 18px;
          text-align: center;
        }
      }
      .timer__row_caption .timer__cell, .timer__row_caption span{
        text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
        color: #a2bedc;
        font-family: 'ClearSans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 13px;
        text-transform: none;
        @include Medium {
          font-size: 12px;
        }
        @include Small {
          font-size: 12px;
        }
        @include xSmall {
          font-size: 11px;
          width: 10px;
          overflow: hidden;
          font-weight: normal;
        }
      }
      .timer__row_caption span {
        width: 33.3%;
        display: inline-block;
        text-align: center;
      }
      .timer__cell_empty {
        @include xSmall {
          padding-right: 0;
        }
      }
    }
    .slick-slider__link {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
      color: rgba(255, 255, 255, 0.6);
      font-family: 'ClearSans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 13px;
      text-decoration: underline;
    }
    .slick-dots {
      bottom: 0;

      margin-left: -64px;
      @include Medium {
        //bottom: -50px;
      }
      @include Small {
        bottom: 0px;
      }
      @include xSmall {
        margin-left: 0;
        bottom: 13px;
        //top: 194px;
      }
       li {
         width: auto;
         margin: 0 5px;
         height: 25px;
         text-align: center;
         position: relative;
         @include xSmall {
           margin: 0 4px;
         }
         &:last-child .dot{
           width: 18px;
           height: 18px;
           background-color: #fd951e;
           &:before {
             content: '';
             position: absolute;
             background: url("../img/promo/super.png") no-repeat;
             width: 135px;
             height: 31px;
             background-size: contain;
             top: -13px;
             left: 80%;
             @include xSmall {
               background: url("../img/promo/super_mobile.png") no-repeat;
               width: 119px;
               height: 25px;
               right: 9px;
               left: auto;
               top: 100%;
             }
           }
         }
         &:nth-last-child(2) .dot {
           width: 16px;
           height: 16px;
         }
         &:nth-last-child(3) .dot {
           width: 14px;
           height: 14px;
         }
       }
      .dot {
        display: block;
        width: 10px;
        height: 10px;
        box-sizing: content-box;
        background-color: #031e3c;
        border-radius: 50%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.finished {
          background: #fff001!important;
          &:after {
            position: absolute;
            content: '';
            width: 19px;
            height: 17px;
            top: 50%;
            left: 50%;
            background: url("../img/promo/checked.png") no-repeat 0 0;
            background-size: contain;
            transform: translate(-30%, -60%);
          }
        }
      }
      .slick-active .dot {
        box-shadow: 0 0 0 2px #fff;
        &.end {
          box-shadow: 0 0 0 2px #fff, 0 0 5px 0 #fff,0 0 10px 0 #fff001, 0 0 30px 0 #fff001, 0 0 20px 0 #000;
          &:after {
            opacity: 0.7;
          }
        }
       }

    }
    .slick-arrow {
      display: flex;
      position: absolute;
      right: 20px;
      width: 30px;
      height: 80px;
      text-align: center;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      background: transparent;
      z-index: 2;
      border: none;
      @include Small {
        top: 43%;
        right: 0;
      }
      @include  xSmall{
        right: -5px;
        top: 30%;
      }
      &.disabled {
        cursor: default;
      }

      &:before {
        color: #fff;
        text-shadow:  0 0 4px rgba(0, 0, 0, 0.46);
        font-size: 80px;
        font-weight: normal;
        line-height: 80px;
        display: block;
        content: '\203A';
        font-family: 'ClearSans', sans-serif;
        transition: color ease 0.3s;
        @include Medium{
          font-size: 72px;
        }
        @include Small{
          font-size: 72px;
        }
        @include xSmall{
          font-size: 42px;
        }
      }

      &.slick-prev {
        left: 20px;
        right: auto;
        @include Small {
          left: 0;
        }
        @include xSmall {
          left: -5px;
        }
        &:before {
          content: '\2039';
          font-family: 'ClearSans', sans-serif;
        }
      }

      &.slick-disabled {
        &:before {
          color: #4b617b;
          text-shadow: none;
        }
      }
    }

  }
  &__list {

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include xLarge {
      padding: 0 120px;
    }

    .lottery-details-promo__slider__list {

      @include xLarge {
        flex: 0 0 1055px;
      }
      @include Large {
        flex: 0 0 1055px;
      }
      @include Medium {
        flex: 0 0 815px;
      }
      @include Small {
        flex: 0 0 602px;
      }
      @include xSmall {
        flex: 0 0 100%;
      }

      margin-left: auto;
    }
    .slick-slider {
      margin-bottom: 10px;
    }
    .slick-slider__item {
      //min-height: 225px;
    }
    .lottery-details-promo__slider {
      //min-height: 330px;
      box-shadow: 0 1px 0 #153151, inset 0 1px 0 #38608e, inset -1px 0 0 #38608e, inset 1px 0 0 #355d8c, inset 0 -1px 0 #1d426e;
      @include Small {
        //min-height: 267px;
      }
      @include Medium {
        //min-height: 287px;
      }
    }
    .list__date {
      display: block;
      flex: 0 0 105px;
      width: 105px;
      margin-right: 20px;
      background-color: #15355a;
      box-shadow: 0 7px 2px rgba(0, 0, 0, 0.13), inset 1px 0 0 #214268, inset 0 1px 0 #214268;
      top: 0;
      transition: top ease-in-out 0.3s;
      &.weee {
        position: fixed;
        z-index: 10;
        top: 10px;
      }

      @include Small {
        flex: 0 0 83px;
        width: 83px;
        margin-right: 15px;
      }
      @include xSmall {
        display: none;
      }

    }
    .list__date__block {

      .slick-arrow {
        position: relative;
        transition: none;
        left: 0;
        right: 0;
        width: 100%;
        top: 11px;
        &.slick-next {
          top: auto;
          bottom: -9px;
          &:after {
            content: '';
            height: 0;
            opacity: 0;
            transition: opacity ease-in-out 0.4s;
            right: 3px;
            top: -33px;
            position: absolute;
            width: 115px;
            background: url("../img/promo/super_date.png") no-repeat;

          }
          &.slick-disabled {
            height: 0;
            &:before {
              display: none;
            }
            &:after {
              height: 55px;
              opacity: 1;
            }
          }
        }
        &:before {
          color: #fff;
          text-shadow: 0 0 4px rgba(0, 0, 0, 0.46);
          font-size: 24px;
          font-weight: normal;
          line-height: 20px;
          display: block;
          content: '\203A';
          font-family: 'ClearSans', sans-serif;
          transition: color ease 0.3s;
          transform: rotate(90deg);
        }
        &.slick-prev {
          &:before {
            content: '\2039';
            font-family: 'ClearSans', sans-serif;
          }

        }
      }

    }
    .list__date__title {
      display: block;
      height: 37px;
      background-color: rgba(255, 255, 255, 0.12);
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 13px;
      font-weight: 900;
      line-height: 37px;
      text-align: center;
      @include Small {
        font-size: 10px;
      }
    }
    .list__date__item {
      display: block;
      cursor: pointer;
      height: 29px;
      line-height: 29px;
      width : 83px;
      text-align: center;
      margin: 0 auto;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      position: relative;
      text-decoration: none;
      &.finished{
        color: #fff001;
        &:before {
          content: '';
          position: absolute;
          width: 14px;
          height: 10px;
          background: url("../img/promo/checked_item.png") no-repeat ;
          background-size: contain;
          left: 7px;
          top: 50%;
          transform: translateY(-50%);
        }
        @include Small {
          //width: 70px;
          padding: 0 11px 0 21px;
        }
      }
      &:last-child {
        color: #fd951e;
        &:after {
          content: '';
          height: 55px;
          transition: opacity ease-in-out 0.4s;
          right: -14px;
          top: 8px;
          position: absolute;
          width: 115px;
          background: url("../img/promo/super_date.png") no-repeat;

        }

      }
      &.active_day {
        background-color: #3668a2;
        box-shadow: inset 0 1px 0 #5a88bd;
        margin-top: 1px;
        //width: 83px;
      }
      &.active {
        color: #03b1ff;
      }
      &:hover {
        color: #007dbf;
      }

    }
    .lottery-details-promo__slider__list .js-fixed.slick-slider {
      margin-bottom: 0;
      transition: top ease-in-out 0.2s;
    }
    .slick-slider__item {
      padding: 35px 0 10px;
    }
    .slick-slider__img {
      height: auto;
      @include xSmall{
        margin-bottom: 20px;

      }

      .slick__img {
        max-width: 408px;
        max-height: 273px;
        @include Medium {
          max-width: 350px;
          max-height: 190px;
        }
        @include Small {
          max-width: 255px;
          max-height: 160px;
        }
        @include xSmall {
          max-width: 253px;
          max-height: 136px;
        }
      }
    }
    .slick-slider__description {
      padding: 0 6px;
      @include xSmall {
        max-width: 280px;
      }
    }
    .slick-slider__subtitle {
      font-size: 20px;
      font-weight: 500;
      @include Medium {
        font-size: 18px;
      }
      @include Small {
        font-size: 14px;
      }
      @include xSmall {
        font-size: 14px;
      }
    }
    .slick-slider__title {
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      padding: 8px 0;
      @include Medium {
        font-size: 28px;
      }
      @include Small {
        font-size: 20px;
        line-height: 22px;
      }
      @include xSmall {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .slick-slider__model {
      font-size: 20px;
      font-weight: 500;
      @include Medium {
        font-size: 18px;
      }
      @include Small {
        font-size: 14px;
      }
      @include xSmall {
        font-size: 14px;
      }
    }
    .slick-slider__counter {
      width: auto;
      max-width: 320px;
      display: table;
      min-width: 240px;
      //padding: 14px 20px;
      height: auto;
      margin-bottom: 8px;
      @include xSmall {
        min-width: 0;
        max-width: none;
        width: 260px;
        //padding: 10px 12px;
        margin: 10px 0 6px 0;

      }
    }

    .counter__title {
      font-size: 14px;
    }
    .slick-slider__timer .timer__cell {
      font-size: 28px;
    }
    .slick-slider__link {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
      color: white;
      @include Medium {
        font-size: 14px;
      }
    }
  }

  &__title {
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    display: block;
    position: relative;
    margin-bottom: 8px;
    &_list {
      text-align: center;
      font-size: 25px;
    }
    @include Medium {
      font-size: 20px;
      font-weight: 900;
      line-height: 20px;
    }
    @include Small {
      font-size: 18px;
      font-weight: 900;
      line-height: 18px;
    }
    @include xSmall {
      font-size: 11px;
      font-weight: 900;
      line-height: 15px;
    }
  }

  &__subtitle {
    color: #fff001;
    font-family: 'ClearSans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 20px;
    text-transform: uppercase;
    @include Medium {
      font-size: 20px;
      font-weight: 900;
      line-height: 20px;
    }
    @include Small {
      font-size: 18px;
      font-weight: 900;
      line-height: 18px;
    }
    @include xSmall {
      font-size: 11px;
      font-weight: 900;
      line-height: 12px;
      margin-bottom: 10px;
    }
  }

  &__countdown {
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;
    position: relative;
    @include xSmall {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,0);
      margin-top: 0;
      white-space: nowrap;
    }
  }



}
.lottery-details-promo__title_list {
  margin-bottom: 20px;
  @include xSmall {
    font-size: 16px;
  }
}
.popup-js {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-49%);
  transform: translate(-50%,-49%);
  @include xSmall {
    position: absolute;
    left: 0;
    top: 0;
    transform: none;
    width: 100% !important;
    max-height: 100%;
    overflow-y: scroll;
  }
}
.popup__promo__info {
  width: 800px;
  background-color: #10253d;
  border: 1px solid #00426d;
  border-radius: 5px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.36), inset 0 0 0px rgba(255, 255, 255, 0.21);
  @include Small {
    width: 568px;
  }
  .popup__head {
    height: 64px;
    border-bottom: 1px solid #354c68;
  }
  .popup__content {
    background-image: radial-gradient(296px 277px at 51.85% 100.98%, rgba(68, 102, 141, 0.83) 0%, rgba(19, 46, 77, 0.73) 100%) ;
    min-height: 270px;
    color: #fff;
    padding: 50px 60px;
    font-family: 'ClearSans', sans-serif;
    @include xSmall {
      padding: 20px;
    }
  }
  .popup__content li {
    @include xSmall {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .popup__footer {
    background: transparent;
    border-top: 1px solid #354c68;
  }
  .popup__close {
    height: 100%;
    background: transparent;
  }
  .icon-close {
    background: url("../img/promo/close.png") no-repeat ;
    width: 33px;
    height: 33px;
    cursor: pointer;
    opacity: 0.8;

  }
  .popup__close:hover .icon-close {
    opacity: 1;
  }
  .popup__title {
    text-align: center;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
  }
  .popup__promo__info__list {
    margin: 0;
    padding: 0;
  }
  .popup__promo__info__list__item {
    margin: 0;
    padding: 0 0 10px 25px;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    list-style-type: none;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #fe0;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      left: 0;
      top: 0;
      margin-top: 8px;
    }

  }
  .button__popup__close {
    display: inline-block;
    width: 100px;
    height: 34px;
    margin: 15px auto;
    background-color: transparent;
    border: 1px solid #3b5469;
    border-radius: 3px;
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    &:hover, &:active, &:focus {
      background: #0b2238;
      color: #6bb5ff;
    }
  }
}
.overlay {
  display: block;
  //text-align: center;
  position: fixed;
  overflow: auto;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: visible;
  background-color: rgba(0,0,0,0.5);
}
.layout_lottery-promo__popup {
  width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-49%);
  transform: translate(-50%,-49%);
  background: #15355a;
  border: solid 1px #38608e;
  font-family: 'ClearSans',sans-serif;
  padding: 30px 0;
  border-radius: 2px;
  @include Small {
    width: 90%;
  }
  @include xSmall {
    width: 90%;
  }
}
.icon-close {
  background: url("../img/promo/close.png") no-repeat ;
  width: 33px;
  height: 33px;
  cursor: pointer;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 10px;
}
.layout_lottery-promo__popup-close:hover .icon-close {
  opacity: 1;
}
.layout_lottery-promo__popup-title {
  //font-family: 'ClearSans',sans-serif;
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  @include xSmall {
    font-size: 14px;
  }
}
.layout_lottery-promo__popup-content {
  width: 80%;
  margin: auto;
  position: relative;
  @include Small {
    width: 90%;
  }
  @include xSmall {
    width: 90%;
    text-align: center;
  }
}
.layout_lottery-promo__popup-img {
  z-index: 10;
  position: relative;
}
.layout_lottery-promo__popup-info {
  background: #38608e;
  position: absolute;
  top: 40px;
  border-radius: 2px;
  border: solid 1px rgba(255, 255, 255, 0.17);
  margin-left:  150px;
  width: calc(100% - 150px);
  padding: 15px 0px 15px 70px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 28px;
  @include xSmall {
    font-size: 14px;
    line-height: 20px;
    //padding: 10px 0 10px 40px;
    position: relative;
    width: 100%;
    margin: 10px 0 20px 0;
    padding: 10px;
    top:0;
  }

}
span.layout_lottery-promo__popup-content, span.layout_lottery-promo__popup-subtitle {
  display: inline-block;
}
.layout_lottery-promo__popup-subtitle {
  color: #fff001;
}
.layout_lottery-promo__popup-action {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  @include xSmall {
    margin-bottom: 0;
  }
  a {
    color: #b2b2b2;
    font-size: 14px;
    font-weight: normal;
    margin-left: -100px;
  }
}

.layout_lottery-promo__popup .button__outer {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  @include xSmall {
    display: block;
    margin-bottom: 35px;
  }
}
.layout_lottery-promo__popup .registration__button {
  display: inline-block;
  border: none;
  vertical-align: middle;
  padding: 0 10px;
  width: 297px;
  height: 50px;
  margin: 0;
  background-image: linear-gradient(349deg, rgba(253,8,52,1) 0%, rgba(253,42,47,1) 21%, rgba(253,103,36,1) 45%, rgba(253,151,28,1) 67%, rgba(253,160,27,1) 71%, rgba(253,163,27,1) 100%);
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
  color: white;
  font-family: 'ClearSans', sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 50px;
  transition: opacity 0.3s ease;
  &:hover {
    background-image: linear-gradient(165deg, rgba(253,8,52,1) 0%, rgba(253,42,47,1) 21%, rgba(253,103,36,1) 45%, rgba(253,151,28,1) 67%, rgba(253,160,27,1) 71%, rgba(253,163,27,1) 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  }
  &:active, &:focus {
    outline: none;
    background-image: linear-gradient(178deg, #d86512 0%, #8b0c0d 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  }
  @include xSmall {
    width: 212px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

.layout_lottery-promo__popup .registration__text {
  display: block;
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: -24px;
  left: 0;
  width: 100%;
  text-align: center;
  @include Small {
    font-size: 14px;
  }
  @include xSmall {
    font-size: 14px;
  }
}
.layout_lottery-promo__popup  .login__button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  box-shadow: none;
  font-family: "ClearSans", sans-serif;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
  padding: 0;
  margin-left: 10px;
  &:hover, &:focus {
    color: #6bb5ff;
    border-color: #4b6b86;
    outline: none;
  }
  &:active {
    border-color: #123c61;
  }
}
.lottery-btm-box {
  background-color: #194574;
  border: 1px solid #15355a;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 50;
}
.lottery-btm-content {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  @include Large {
     width: 80%;
   }
  @include Medium {
    width: 90%;
  }
  @include Small {
    flex-direction: column;
    width: 90%;
    text-align: center;
  }
  @include xSmall {
    flex-direction: column;
    width: 90%;
    text-align: center;
  }
}
.lottery-btm_item {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'ClearSans', sans-serif;
  text-transform: uppercase;
  color: #fff;
  //white-space: nowrap;

  &:first-child {
    margin-right: auto;
    @include Small {
      margin: auto;
    }
    @include xSmall {
      margin: auto;
    }
  }
}
.lottery-btm_highlight {
  color: #fff001;
}
.lottery-btm_item__text {
  display: inline-block;
}
.lottery-btm-box .casher__button {
  display: inline-block;
  border: none;
  vertical-align: middle;
  padding: 0 10px;
  width: 204px;
  height: 40px;
  background-image: linear-gradient(349deg, rgba(253,8,52,1) 0%, rgba(253,42,47,1) 21%, rgba(253,103,36,1) 45%, rgba(253,151,28,1) 67%, rgba(253,160,27,1) 71%, rgba(253,163,27,1) 100%);
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
  color: white;
  font-family: 'ClearSans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  transition: opacity 0.3s ease;
  margin: 0 0 0 20px;
  &:hover {
    background-image: linear-gradient(165deg, rgba(253,8,52,1) 0%, rgba(253,42,47,1) 21%, rgba(253,103,36,1) 45%, rgba(253,151,28,1) 67%, rgba(253,160,27,1) 71%, rgba(253,163,27,1) 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  }
  &:active, &:focus {
    outline: none;
    background-image: linear-gradient(178deg, #d86512 0%, #8b0c0d 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  }
  @include Small {
    margin: 10px 0 0 0;
  }
  @include xSmall {
    margin: 10px 0 0 0;
  }
}