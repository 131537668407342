.vip-panel {
  display: block;
  position: relative;
  width: 209px;
  height: 244px;
  border-radius: 3px;
  background-color: #214875;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #31557f;


  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 44px;
    background-color: #1f426b;
    box-shadow: 0 -2px 0 0 #284f7c, 0 -1px 0 0 #193b61;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #193b61;
    box-shadow: 0 -1px 0 #284f7c;
  }

  &__badge {
    display: block;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    width: 38px;
    height: 38px;
    top: -12px;
    right: -14px;
    background-color: #3b8ed7;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 2px 7px 3px rgba(0, 0, 0, 0.26);
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 23px;
    line-height: 38px;
    font-weight: 700;
    text-transform: uppercase;
  }


  &__button {
    display: block;
    position: absolute;
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 42px;
    border-radius: 22px;
    bottom: 26px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-family: "ClearSans", sans-serif;
    padding: 10px 10px;
    line-height: 20px;
    outline: none;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 1px 0 #2765a8;
  }
}
.tab-vip .tab__content > div.active{
  width: 100%;
  height: 252px;
  display: table!important;
}
