.main {
  display: block;
  position: relative;

  &__inner {
    text-align: left;
  }

  &_gallery {
    padding: 18px 12px 12px;
    font-size: 0;

    @include xLarge {
      padding: 17px 22px 12px;
    }

    @include Large {
      padding: 18px 11px 12px;
    }

    @include Small {
      padding: 18px 10px 12px;
    }

    @include xSmall {
      padding: 18px 3% 12px;
    }
  }

  &_promo {
    padding: 30px 26px 182px 26px;
    background-color: #122d4c;

    @include xSmall {
      padding: 30px 26px 10px;
    }
  }

  &_profile {

    @include xSmall {
      width: calc(100% - 1px);
    }

  }

  &_promo-details {
    padding: 30px 30px 95px 30px;
    background-color: #122d4c;

    @include xSmall {
      padding: 30px 30px 30px;
    }
  }

  &_tournament {
    padding: 0 20px 30px 20px;
  }

  &_tournament-details {
    text-align: center;
    padding: 0 26px 28px;

    @include Small {
      padding: 0 8px 28px;
    }
    @include xSmall {
      padding: 0 0 40px;
    }
  }

  &_lottery {
    padding: 0 20px 28px 20px;
  }

  &_lottery-details {
    text-align: center;
    padding: 0 26px 0;

    @include xSmall {
      padding: 0;
    }
  }

  &__item {
    display: inline-block;
    vertical-align: top;

    .main_gallery & {
      margin: 10px 10px;
    }

    .main_promo & {
      display: block;
      width: 100%;
      margin-bottom: 27px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .main_tournament & {
      display: block;
      background: #00284b;
      border: 1px solid #2a588c;
      border-radius: 3px;
      width: 870px;
      padding-bottom: 15px;
      margin: 0 auto 32px;
      max-width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover{
        box-shadow: inset 0px 0px 30px 0px #4c9cb9;
        
      }
      @include Small{
        padding-bottom: 0px;
        width: 700px;
      }
      @include xSmall{
        padding-bottom: 0px;
      }
    }
  }
}