.promo-bonus {
  display: block;
  position: relative;
  text-align: center;
  background-color: #2f527b;
  border-radius: 1px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.13), inset 0 0 0 #1b3a5e, 0 -1px 3px rgba(0, 0, 0, 0.19);

  &__title {
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    padding: 25px 23px 0;
    margin: 0;
    display: block;
  }

  &__img {
    display: block;
    position: relative;
    margin-top: 8px;
    padding: 10px 0;

    img {
      height: 200px;
    }
  }

}