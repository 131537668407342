.pay-tooltip {
  display: inline-block;
  position: relative;
  background-color: #fff;
  border-radius: 3px;
  padding: 18px 15px;
  width: 100%;
  text-align: left;
  color: #4e4e4e;
  font-size: 16px;

  @include xSmall {
    padding: 18px 12px;
    min-height: 100px;
  }

  &_withphone {
    @include xSmall {
      min-height: 155px;
    }
  }

  &:after {
    content: " ";
    position: absolute;
    bottom: 99%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -24px;
    border-width: 24px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
  &.left{
    &:after{
      left: 16%
    }
  }
  &.right{
    &:after{
      left: 84%
    }
  }

  &__input {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    &__second{
      .pay-tooltip__caption{
        min-width: 132px;
        display: inline-block!important;
      }
      .input__inner {
        width: 102px;
        height: 36px;
        background-color: #ffffff;
        border-radius: 2px;
        border: 1px solid #bababa;
        color: #4a4a4a;
        font-size: 16px;
        font-weight: bold;
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
        padding: 9px 18px 8px 12px;
        margin-left: 14px;
   
        #withdraw-tab & {
          width: 130px;
   
          @include xSmall {
            width: 120px;
          }
   
        }
      }
    }
    &.pay-tooltip__with7{

      &:before{
        content: '7';
        position: absolute;
        left: 34px;
        top: 8px;
        @include xSmall{
          left: 24px;
        }

        #withdraw-tab &{
          left: 25px;
          top: 7px;
          font-size: 16px;
        }
      }
      .input__inner{
        padding-left: 21px;
      }
    }
    &.pay-tooltip__with-plus{

      &:before{
        content: '+';
        position: absolute;
        left: 34px;
        top: 8px;
        @include xSmall{
          left: 24px;
          top: 7px;
        }

        #withdraw-tab &{
          left: 25px;
          top: 7px;
          font-size: 16px;
        }
      }
      .input__inner{
        padding-left: 21px;
      }
    }


   .input__inner {
     width: 102px;
     height: 36px;
     background-color: #ffffff;
     border-radius: 2px;
     border: 1px solid #bababa;
     color: #4a4a4a;
     font-size: 16px;
     font-weight: bold;
     box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
     padding: 9px 18px 8px 12px;
     margin-left: 14px;

     #withdraw-tab & {
       width: 130px;

       @include xSmall {
         width: 120px;
       }

     }
   }
  }
  &__summ {
    color: #4a4a4a;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;

    @include xSmall {
      display: block;
    }
    &__second{
      margin-top: 10px;
      display: inline;
      @include Small {
        display: block;
      }
      @include xSmall {
        display: block;
      }
    }
    .pay-tooltip__input {
      @include xSmall {
        display: block;
        //position: absolute;
        //bottom: 17px;
        //left: 19px;
        margin: -24px 0 0 6px;
      }
      &:before{
        color: transparent;
      }
    }

    input[type="radio"] {
      vertical-align: middle;
      margin: 0;

    }

    @include xSmall {
      input.l_num {
        display: block;
        margin-top: 20px;
      }
    }

    label {
      display: inline-block;
      padding-right: 13px;
      font-weight: bold;
      cursor: pointer;
      &:first-child {
        padding-left: 12px;
      }
      @include xSmall {
        padding-right: 9px;
      }

    }
  }
  &__phone {
    padding: 0 0 20px;
  }
  &__phone_inner {
    width: 170px;
    height: 36px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 2px;
    border: 1px solid #bababa;
    color: #4a4a4a;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    padding: 9px 18px 8px 30px;
    margin:  0 0 0 12px;
    vertical-align: middle;
    display: inline-block;

    @include xSmall {
      width: 130px;
      margin-left: 4px;
    }
  }

  &__number {
    padding: 0 0 10px;
    position: relative;
    display: block;
  }

  &__number_inner {
    width: 170px;
    height: 36px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 2px;
    border: 1px solid #bababa;
    color: #4a4a4a;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    padding: 9px 18px 8px 12px;
    margin:  0 0 0 12px;
    vertical-align: middle;
    display: inline-block;

    #withdraw-tab .pay-tooltip__input &-noprefix {
      margin-left: 12px;
    }

    @include xSmall {
      width: 130px;
      margin-left: 4px;
      padding: 9px 10px 8px 10px;
    }


  }

  &__button {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 9px 15px 8px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #ff8d00, #e2d533);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16), 0 8px 6px 0 rgba(0, 0, 0, 0.16);
    margin-left: 14px;
    margin-top: -2px;

    @include xSmall {
      display: block;
      position: absolute;
      right: 16px;
      bottom: 17px;
      padding: 10px 5px 10px;
      font-size: 14px;
    }

    &_withdraw {
      @include xSmall {
        display: block;
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: 10px;
      }
    }

  }

  &__note {
    color: #f00;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    border-radius: 3px;
    background-color: #ffecec;
    border: solid 1px #ff9797;
    text-align: left;
    padding: 8px 14px 8px 14px;
    margin-bottom: 14px;
    &* {
      color: #f00;
    }
  }

  &__caption {
    min-width: 132px;
    display: inline-block;
  }
  
  &__card-date {
    @include Small {
      margin-left: 2px!important; 
    }
  }
}

// Style for error tooltip

.pay-tooltip_error {

  .pay-tooltip__input {
    display: block;
    margin: 0;

    .input__inner {
      width: 100%;
    }
  }

  .pay-tooltip__button {
    display: block;
    margin: 11px auto 0;
    padding: 13px 23px 12px;
  }
}