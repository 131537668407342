.table {
  width: 100%;
  border-collapse: collapse;

  &__row {
    height: 43px;

    .table_leaderboard &{
      height: 31px;

      .leaderboard &.signed_in_user{
        background: #31518d;
        border-bottom: 1px solid #3a84c6;
        box-shadow: inset 0 1px 0 1px #3a84c6;
      }
    }

    &:nth-child(odd) {
      height: 31px;
      background-color: #132d4b;

      .table_promo & {
        background-color: #3d628e;
      }

      .table_leaderboard & {
        background-color: #263c65;
        height: 31px;
      }
    }

    &_active.table__row .table__cell {
      background-color: #3668a2;
      box-shadow: inset 0 1px 0 #5a88bd;
      color: #fff;
      &:first-child {
        text-align: center;
      }

      .table_promo & {
        background-color: transparent;
        box-shadow: none;
      }

    }

    &_empty {
      height: 26px;
    }
  }

  // &__headrow {

  // }

  &__cell {
    font-family: 'ClearSans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #88add7;
    text-align: left;
    white-space: nowrap;

    &_fluid.table__cell {
      width: 100%;

      .table_leaderboard & {
        width: 100%;
      }

    }
    .table_promo & {
      color: #fff;
    }

    .table_leaderboard & {
      color: #fff;
    }

    .table__headrow & {
      color: #a8d1ff;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 4px 0 16px;
      text-align: left;

      .table.table_leaderboard &{
        border-bottom: 1px solid #036;
        padding: 0 0 5px;
        color: #7c9ddb;
        font-weight: 100;
      }
    }

    .table_promo .table__headrow & {
      color: #c8c7dc;
      padding: 0 0 14px;
    }

    .table_leaderboard .table__headrow &,
    .table_gameplay .table__headrow &
    {
      color: #fff;
      padding: 0 0 14px;
      font-family: 'ClearSans', sans-serif;
    }

    .table__headrow &:first-child {
      padding: 4px 10px 16px;
      text-transform: uppercase;
      text-align: center;
    }

    .table_promo .table__headrow &:first-child {
      padding: 0 10px 14px;
      width: 1px;
    }

    .table_promo .table__headrow &:last-child {
      padding: 0 10px 14px;
      width: 1px;
    }

    .table_leaderboard .table__headrow &:last-child,
    .table_leaderboard .table__headrow &:nth-child(3) {
      text-align: right;
      padding-right: 20px;
    }
    .table_leaderboard .table__body &:last-child {
      color: #fdc236;
    }

    .table_leaderboard .table__body &:nth-child(3) {
      color: #17b271;
    }
    .table_leaderboard .table__body .table__row:last-child &{
      border-bottom: 1px solid #036;
    }


    .table__body &:first-child {
      width: 1px;
      text-align: center;
      padding: 0 10px;
      text-transform: uppercase;
    }

    .table__body &:last-child {
      width: 1px;
      text-align: right;
      padding: 0 10px;
    }

    .table_leaderboard .table__body &:last-child,
    .table_leaderboard .table__body &:nth-child(3)
    {
      padding: 0 20px;
      @include Small{
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 106px;
        padding: 0 10px;
      }
    }

    .table_leaderboard .table__body &:nth-child(3) {
      text-align: right;
    }
  }
  .overflow_outer {
    max-width: 58px;
  }
  .overflow_ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

}