.profile {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;

  &__table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
    position: relative;
  }

  &__header {
    height: 133px;
    //background-image: linear-gradient(230deg, rgba(255, 255, 255, 0.2) 0%, transparent 15%), linear-gradient(-230deg, #324c69 0%, #132e4d 15%);

  }

  &__aside {
    display: table-cell;
    vertical-align: top;
    position: relative;
    width: 298px;

    @include Small {
      width: 219px;
    }

    @include xSmall {
      display: block;
      width: 100%;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-image: linear-gradient(180deg,#132e4d,#52baff 50%,#132e4d);
      box-shadow: 0 1px 1px rgba(255,255,255,0.1);

      @include xSmall {
        display: none;
      }
    }


    .cabinet-promo {
      max-width:200px;
      margin:0 auto;
      position:relative;

      @include xSmall{
        margin-top: 20px;
      }

      .profile-contacts__button{
        margin-top: -19%;
      }

      .title__inner{
        position:relative;
        width:100%;
      }
      .rating__info{
        top:1px;
      }
      .error{
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #ff002c;
        display:none;
      }
      .cabinet-promo__note{
        color: #9e9cbf;
        font-family: 'ClearSans',sans-serif;
        font-size: 15px;
        line-height: 14px;
        text-align: center;
        margin: 10px;
      }
      .form-email{
        position:relative;
      }
      &__input{
        width: 100%;
        height: 42px;
        padding: 10px 109px 14px 17px;
        background-color: #141320;
        color: #fff;
        font-size: 14px;
        text-transform: none;
        border-radius: 1px;
        box-shadow: inset 0 1px 0 rgba(0,0,0,.13), 0 1px 0 #222130;
      }
      .tooltip__content{
        text-transform: none;
        font-weight:normal;
      }

      &__button{
        width: 80px;
        height: 29px;
        padding: 6px 0;
        font-size: 13px;
        line-height: 17px;
        display:inline-block;
      }
      .promo--success{
        font-size: 13px;
        color:white;
        border: solid 1px rgba(255, 255, 255, 0.16);
        padding:15px;
        margin:20px 0;
      }
      .promo--success p{
        margin-top:2px;
      }
      .promo--success a{
        color: #3c90f0;
        text-decoration: underline;
      }
    }
  }

  &__title {
    font-size: 30px;
    line-height: 40px;
    display: block;
    text-align: center;
    padding: 46px 20px 47px;
  }

  &__main {
    display: table-cell;
    vertical-align: top;

    @include xSmall {
      display: block;
      width: 100%;
    }
  }

  &__form {  }  // styles for v2

  &__form_v1 {  //styles for v1
    background-color: #1b1a27;
    box-shadow: inset 0 -1px 0 #2b2a3c, 0 3px 8px rgba(0, 0, 0, 0.72);

    .profile-details {
      padding: 35px 42px 42px 47px;

      &:after {
        background-color: rgba(0,0,0,0.2);
        box-shadow: 0 1px 1px rgba(255,255,255,0.1);
      }

      .profile-details__input {
        padding: 0;
        width: auto;
        margin-right: 11px;
        &:last-child {
          margin-right: 0;
        }
      }

      .profile-details__title {
        margin-bottom: 38px;
      }

      .input__inner {
        width: 229px;
        height: 53px;
        padding: 10px 26px;
      }
    }

    .profile-contacts {
      padding: 39px 39px 35px;

      &:after {
        background-color: rgba(0,0,0,0.2);
        box-shadow: 0 1px 1px rgba(255,255,255,0.1);
      }

      .input__inner {
        height: 53px;
      }

      .profile-contacts__button {
        width: 121px;
        height: 37px;
        bottom: 8px;
        padding: 0 10px;

        &_green {
          background-image: linear-gradient(180deg, #4edb80 0%, #307c0b 100%);
        }
      }
    }

    .profile-socials {
      padding: 35px 42px 32px;

      &:after {
        background-color: rgba(0,0,0,0.2);
        box-shadow: 0 1px 1px rgba(255,255,255,0.1);
      }

      .profile-socials__title {
        margin-bottom: 8px;
      }

      .profile-socials__note {
        text-align: left;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
      }

      .profile-socials__action {
        margin-top: 33px;
        display: inline-block;
        vertical-align: top;
      }
    }

    .profile-notify {
      padding: 31px 43px 35px;

      &:after {
        background-color: rgba(0,0,0,0.2);
        box-shadow: 0 1px 1px rgba(255,255,255,0.1);
      }

      .profile-notify__title {
        margin-bottom: 23px;
      }

      .profile-notify__checkbox {
        display: block;
        margin-bottom: 13px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .profile__action {
      padding: 35px 29px 32px 38px;
      text-align: center;
    }

    .profile__button {
      padding: 14px 30px;
      float: none;
      font-size: 20px;
    }

  }

  &__password {
    display: block;
    position: relative;
  }

  &__info {
    display: block;
    position: relative;
  }

  &__details {
    display: block;
    position: relative;
    padding: 0;
    border: none;
  }

  &__contacts {  }

  &__socials {  }

  &__notify {  }

  &__action {
    padding: 41px 31px 18px;

    @include Small {
      padding: 41px 20px 18px;
    }

    @include xSmall {
      padding: 26px 20px 40px;
      text-align: center;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  &__button {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    padding: 10px 12px;
    font-family: 'ClearSans',sans-serif;
    font-size: 16px;
    line-height: 18px;
    float: left;

    @include xSmall {
      float: none;
      margin: 0 auto;
      //width: 165px;
      display: block;
    }

    &_submit {
      float: right;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

      @include xSmall {
        float: none;
        margin: 15px auto 0;
        display: block;
        width: 100%;
      }
    }
  }

  &__panel {
    display: block;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(0,0,0,0.2);
      box-shadow: 0 1px 1px rgba(255,255,255,0.1);
    }

  }

}