.input {
  display: inline-block;
  vertical-align: top;
  position: relative;

  &__label {
    display: block;
    position: relative;
  }

  &__inner {
    width: 256px;
    height: 56px;
    background-color: #091420;
    border-radius: 2px;
    border: none;
    box-shadow: inset 0 1px 0 #0d0c13, 0 1px 0 #323144;
    color: #f0f2f4;
    font-size: 15px;
    vertical-align: middle;
    font-weight: 500;
    font-family: "ClearSans", sans-serif;
    text-transform: uppercase;
    padding: 0 21px;

    .input_withbutton & {
      width: 253px;
      height: 43px;
      background-color: #091420;
      border: 1px solid #435c77;
      border-radius: 2px;
      box-shadow: inset 0 0 6px 1px #203956, inset -121px 0 0 #091420;
      color: #fff;
      font-family: 'ClearSans', sans-serif;
      font-size: 12px;
      display: block;
    }

  }

}


