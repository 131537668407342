.mobile-nav {
  display: none;
  position: relative;

  &__title {
    color: #fff;
    opacity: 0.5;
    margin: 0;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    display: block;
    position: relative;
    line-height: 42px;
    white-space: nowrap;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -19px;
      right: -19px;
      height: 2px;
      width: calc(100% + 38px);
      background-color: #183C66;
    }
  }

  @include xSmall {
    display: block;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    display: block;
    position: relative;
    width: 100%;

    &_active {

    }
  }

  &__link {
    height: 42px;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 44px;
    color: #00a6ff;
    display: block;
    position: relative;
    padding-left: 40px;

    &:before {
      display: block;
      content: '';
      top: 1px;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #08203a;
      z-index: -1;
    }

    &_active {
      color: #fc5526;
      margin: -2px -19px;
      z-index: 2;
      height: 43px;
      line-height: 45px;
      padding-left: 59px;

      &:before {
        background-color: #132e4d;
        height: 43px;
        z-index: -1;
      }
    }

  }

  &__icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    svg {
      fill: #fff;
      display: block;
    }

    &_active svg {
      fill: #fc5526;
    }

  }

  &__caption {
    color: #fc5526;
  }

}

.mobile-nav_dropdown {
  display: none;

  &.open {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #091420;
    z-index: 300;
    top: 50px;
    padding: 0 19px;
  }
}