.popup-panel {
  display: block;
  position: relative;
  box-shadow: inset 0 -3px 0 #21528a, 0 3px 8px rgba(0,0,0,.19);
  background-color: #21528a;
  margin: 32px 32px 0;
  overflow: hidden;
  min-height: 55px;
  transition: height ease-in-out 0.2s;

  @include xSmall {
    margin: 24px 20px 0;
  }

  &__toggle {
    width: 44px;
    height: 55px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    text-align: center;
    padding: 17px 0;
    cursor: pointer;

    .icon_popup-panel_toggle {
      transition: transform ease-in-out 0.2s;
      transform: rotate(90deg);
    }

    &.close {
      .icon_popup-panel_toggle {
        transform: rotate(270deg);
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__head {
    text-align: center;
  }

  &__title {
    text-align: center;
    padding: 18px 40px 7px;
    line-height: 16px;
    text-shadow: 0 2px 1px rgba(9, 32, 58, 0.83);
    color: #fff000;
    font-size: 18px;
    font-weight: 700;

    @include Small {
      font-size: 16px;
    }

    @include xSmall {
      font-size: 16px;
      line-height: 16px;
      padding: 10px 40px 10px;
    }

    &_inline {
      display: inline-block;
      vertical-align: top;
      padding: 13px 20px;
    }

  }

  &__subtitle {
    display: block;
    color: #fff;
    text-transform: none;
    text-align: center;
    padding: 0 10px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 auto 12px;
    max-width: 632px;

    @include Small {
      font-size: 14px;
    }

    @include xSmall {
      font-size: 12px;
      line-height: 16px;
      padding: 0 20px;
    }
  }

  &__content {
    text-align: center;
    margin-top: 10px;
  }

  &__row {
    text-align: center;
  }

  &__input {
    height: 42px;
    margin-right: 8px;

    .input__inner {
      width: 192px;
      color: #e5e2f3;
      height: 42px;
      border-radius: 2px;
      box-shadow: 0 1px 1px rgba(43, 42, 70, 0.46), inset 0 1px 1px rgba(0, 0, 0, 0.79);
      background-color: #09203a;

      @include xSmall {
        width: 170px;
      }
    }
  }

  &__button {
    min-width: 70px;
    height: 42px;
    margin-top: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    text-shadow: 0 1px 0 #be2f21;

    &_wide {
      min-width: 202px;
      padding: 10px 14px;
    }
  }

  &__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 288px;

    @include xSmall {
      max-width: 216px;
    }
  }

  &__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
  }

  &__bonus-note {
    display: block;
    vertical-align: top;
    text-align: left;
    color: #cccccc;
    font-size: 11px;
    font-weight: 400;
    padding-left: 30px;
  }

  &__link {
    display: block;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    text-decoration: underline;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
    cursor: pointer;

    &_pale {
      color: #375475;
    }
  }
  .popup-panel__footer> &__subtitle{
    font-size: 11px;
  }
}