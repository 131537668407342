.sh_btn {
  border-radius: 3px;

  @include xSmall {
    width: 36px !important;
    min-height: 36px !important;
    height: 36px !important;
    border-radius: 3px 0 0 3px !important;
    overflow: hidden !important;
  }

}