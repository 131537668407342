.winnings {
  display: block;
  position: relative;
  text-align: center;
  height: 179px;
  background-color: #2f4b6c;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 4px 0 rgba(4, 4, 4, 0.12);

  &__title {
    text-align: left;
    display: block;
    position: relative;
    padding: 19px;
    font-size: 17px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #213752;
      box-shadow: 0 1px 0 #37567a;
    }
  }

  &__slider {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 890px;
    margin-top: 20px;
    text-align: left;

    .title {
      font-size: 15px;
    }
  }

}