#sunday-popup{
  background:  url(../img/sunday-popup.png)center top no-repeat #010417;
  width: 782px;
  height: 559px;
  color: #fff;
  text-align: center;

  @include xSmall{
    height: auto;
    padding-bottom: 21px;
    left: calc(50% - 335px);
    transform: none;
    position: absolute;
    display: block;
    overflow-y: auto!important;
  }

  @media only screen and (max-width: 782px){
    left: 0;
  }

  @media only screen and (max-width: 670px){
    background-position: -50px 0;
  }

  @media only screen and (max-width: 500px){
    background-image: none;
  }

  .popup__close{
    background: transparent;
    box-shadow: none;
  }

  h1{
    font-size: 43px;
    text-transform: uppercase;
    margin-bottom: 0;

    @media only screen and (max-width: 400px){
      font-size: 35px;
    }
  }

  h2{
    font-size: 30px;
    margin-top: 0;

    @media only screen and (max-width: 400px){
      font-size: 25px;
    }

    span{
      color: #ffbf3e;
    }
  }
  .popup-container{
    padding-left: 220px;

    @media only screen and (max-width:710px){
      padding-left: 120px;
    }

    @media only screen and (max-width:500px){
      padding: 0 10px;
    }

    span{
      color: #9e9cbf;
      font-family:  'ClearSans',sans-serif;
      font-size: 15px;
      line-height: 14px;
      text-align: center;
      width: 50%;
      display: block;
      margin: 20px auto 0;

      @media only screen and (max-width: 600px){
        width: 100%;
      }
    }
  }
  .popup__button{
    display: block;
    margin: 0 auto;
    background: linear-gradient(150deg,#ffb11a,#ff0036);
  }
  img{
    max-width: 100%;
  }
}