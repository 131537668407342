.nav {
  list-style-type: none;
  display: table;
  margin: 0 auto;
  padding: 0;
  padding-left: 250px;
  font-size: 0;
  
  &_hero {
    padding-left: 0px!important;
    width: 100%;
  }

  @include Small {
    padding-left: 210px;
  }

  .header__head-nav & {

    @include xSmall {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  &__item {
    display: table-cell;
    vertical-align: top;
    position: relative;

    @include xSmall {
      display: inline-block;
    }

    .header__head-nav & {
      @include xSmall {
        display: block;
      }
    }

    &:after {
      content: '-';
      display: block;
      position: absolute;
      color: #fff;
      font-size: 18px;
      top: 50%;
      left: 100%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      
      @include xSmall {
        display: none;
      }
    }

    &:last-child:after {
      content: none;
    }
  }

  &__link {
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.19);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    display: block;
    padding: 19px 17px;
    text-align: center;
    white-space: nowrap;

    &-important {
      color: #e22222;
      font-weight: 900;
      transform: scale(1.2);
    }

    @include Small {
      padding: 29px 13px;
      font-size: 15px;
    }

    //.nav__item:first-child & {
    //  padding-left: 0;
    //}
    //
    //.nav__item:last-child & {
    //  padding-right: 0;
    //}

    &_active, &:hover {
      color: $yellow;
    }

    .header__head-nav & {

      @include Small {
        padding: 29px 15px;
        text-transform: capitalize;
        font-size: 15px;
      }

      @include xSmall {
        display: block;
        font-size: 12px;
        padding: 4px 20px;
        line-height: 12px;
        text-align: left;
        text-transform: capitalize;
      }

    }

    .footer__nav & {
      @include xSmall {
        font-size: 10px;
        padding: 4px 20px;
        line-height: 12px;
        text-align: left;
      }

    }
  }
}

.nav_footer {
  display: inline-block;
  width: auto;
  padding-left: 0px;

  .nav__item {
    display: inline-block;
    vertical-align: top;
  }
}