.gameplay-toppanel {
  display: table;
  width: 100%;
  position: relative;
  background-image: url('../img/gameplayaside-bg.jpg');
  height: 60px;
  margin: 0 0 0 -1px;
  font-size: 0;
  display: flex;

  &_small {
    background-image: url('../img/gameplayasidesmall-bg.jpg');
  }

  &__icon {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    width: 1px;
    padding-left: 10px;

    @include Medium {
      padding-left: 0px;
    }

    .lottery__icon{
      width: 45px;
      height: 54px;
      padding-top: 2px;

      @include Medium{
        margin: 0px 3px;
      }
    }
  }

  &__summary {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    padding-left: 9px;

    @include Medium {
      padding-left: 1px;
    }
  }

  &__caption {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    @include Large {
      font-size: 12px;
    }
    @include Medium {
      font-size: 11px;
    }
  }

  &__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.23);
    color: #fff001;
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    text-transform: uppercase;
    margin: 0;
    text-overflow: ellipsis;
    @include Large {
      font-size: 12px;
    }
    @include Medium {
      font-size: 12px;
    }
  }

  &__tooltip.tooltip {
    display: none;
    right: -2px;
    top: 60px;
  }

  &__info {
    display: table-cell;
    position: relative;
    width: 1px;
    vertical-align: middle;
    cursor: pointer;
    padding: 0 10px;

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}