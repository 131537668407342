.tooltip {
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.17);
  text-align: left;
  z-index: 2;
  color: #fefefe;
  font-family: 'ClearSans', sans-serif;
  font-size: 14px;
  line-height: 16px;
  display: block;
  position: absolute;
  padding: 12px 14px 12px 14px;
  width: auto;
  max-width: 200px;
  white-space: normal;
  min-width: 190px;
  background-color: #305a84;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

  .icon_info.open + & {
    display: block;
  }

  &__content {

  }

  &_right {
    right: -17px;
    transform: none;
  }

  &__arrow {
    display: block;
    position: absolute;
    z-index: 3;
    top: 18px;
    left: -6px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

    &:after {
      content: " ";
      position: absolute;
      bottom: 100%;  /* At the top of the tooltip */
      left: 50%;
      z-index: 3;
      margin-left: -7px;
      border-style: solid;
      border-width: 7px;
      border-color: transparent #305a84 transparent  transparent;
    }

    &_right {
      right: 3px;
      top: 1px;
      left: auto;
      &:after {
        right: 5px;
        left: auto;
        border-color: transparent  transparent #305a84 transparent;
      }
    }

  }

}

.tooltip_style_flat {
  background-color: #fd941d;
  border-radius: 2px;
  box-shadow: none;
  text-shadow: none;
  color: #000;
  text-transform: uppercase;
  padding: 8px 10px;
  min-width: 168px;
}