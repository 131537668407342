.profile-details {
  padding: 41px 31px 18px;

  @include Small {
    padding: 41px 20px 18px;
  }

  @include xSmall {
    padding: 20px 19px 18px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px rgba(255,255,255,0.1);
  }

  &__title {
    display: block;
    margin-bottom: 34px;
    &_left {
      text-align: left;
    }
  }

  &__action {
    margin: -8px;
    font-size: 0;
  }

  &__input {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 8px;

    @include xSmall {
      width: 100%;
    }

    .input__inner {
      width: 100%;
      height: 42px;
      padding: 10px 17px 14px;
      background-color: #091420;
      color: #fff;
      font-size: 16px;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0,0,0,.13),0 1px 0 #091420;
    }
  }

}