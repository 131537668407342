.maintenance {
  display: table;
  width: 100%;
  height: 100vh;
  min-height: 770px;
  background-image: url('../img/underconstruction-bg.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  text-align: center;

  &__row {
    display: table-row;
    position: relative;
  }

  &__logo {
    display: table-cell;
    position: relative;
    padding-top: 4%;

    img {
      padding-left: 60px;
    }

    @include xSmall {
      padding-top: 20px;

      img {
        padding-left: 40px;
        width: 300px;
      }

    }
  }

  &__title {
    display: table-cell;
    position: relative;
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.26), 0 3px 2px rgba(0, 0, 0, 0.31);
    color: #ff001e;
    font-family: 'ClearSans', sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 2%;

    @include xSmall {
      font-size: 32px;
      padding: 16px 10px;
      line-height: 48px;
    }
  }

  &__info {
    display: table-cell;
    position: relative;
    max-width: 936px;
    margin: 0 auto;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.44);
    font-family: 'ClearSans', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 41px;
    color: #fff;
    padding: 0 40px;

    @include xSmall {
      font-size: 21px;
      line-height: 30px;
      padding: 0 20px;
    }

    &_accent {
      color: #ffea00;
    }
  }

  &__divider {
    display: table-cell;
    position: relative;
    background-image: url('../img/divider.png');
    background-repeat: no-repeat;
    background-position: center center;
    height: 1px;
    width: 100%;
    padding: 2% 0;
  }

  &__caption {
    display: table-cell;
    position: relative;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 15px;
  }

  &__subscribe {
    display: inline-block;
    position: relative;
    margin-top: 27px;
    margin-bottom: 30px;
  }

  &__input {
    display: block;
    position: relative;
    width: 479px;
    height: 73px;
    background-color: rgba(255, 255, 255, 0.12);
    border: 3px solid #fff;
    border-radius: 36px;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 134px 0 20px;

    @include xSmall {
      width: 300px;
    }
  }

  &__button {
    display: block;
    position: absolute;
    top: 7px;
    right: 7px;
    width: 109px;
    height: 59px;
    background-color: #fff;
    border-radius: 29px;
    border: none;
    color: #06f;
    font-family: 'ClearSans', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer;
  }

}