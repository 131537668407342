.vip {
  display: block;
  position: relative;
  font-family: 'ClearSans', sans-serif;

  &__header {
    //height: 133px;
    //background-image: linear-gradient(230deg, rgba(255, 255, 255, 0.2) 0%, transparent 15%), linear-gradient(-230deg, #324c69 0%, #132e4d 15%);
    text-align: center;
    padding: 34px;
  }

  &__action {
    background-color: #132e4d;;
    text-align: center;
    position: relative;
    display: block;
    padding: 50px 0 64px;
  }

  &__rating {
    height: 100px;
  }

  &__title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    position: relative;

    &.title_font_hugest {
      font-size: 30px;
      line-height: 40px;
      margin: 30px 20px 10px;
    }
  }

  &__subtitle {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 12px;
    display: block;

    @include xSmall {
      display: none;
    }
  }

  &__icon {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 12px;
    text-align: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: #4ea1e1;
    box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.11),0 0 16px rgba(0,0,0,.26),0 8px 10px rgba(0,0,0,.16);

    .rating__info {
      bottom: 0;
    }

    .rating__tooltip {
      text-transform: none;
      font-weight: 400;
    }

    .icon {
      margin: 0;
    }

  }

  &__table {
    display: table;
    border-collapse: collapse;
    position: relative;
    margin: 0 auto;

    @include xSmall {
      display: block;
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    @include xSmall {
      display: block;
    }
  }

  &__input {
    .input__inner {
      width: 205px;
      height: 47px;
      text-align: center;
      border-radius: 1px;
      background-color: #091420;
      box-shadow: inset 0 1px 0 rgba(0,0,0,.13),0 1px 0 #2c2b3d;

      @include xSmall {
        width: 166px;
      }
    }

    &_color_white {
      .input__inner {
        color: #fff;
      }
    }

    &_color_yellow {
      .input__inner {
        color: $textaccent;
      }
    }
  }

  &__viewrate {
    width: 166px;
    height: 63px;
    background-color: #3788d6;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    color: #fff;
    font-size: 29px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 10px;

    @include xSmall {
      margin: 0 auto;
    }
  }

  &__button {
    width: auto;
    height: 63px;
    white-space: nowrap;
    background-color: #e2d533;
    background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    text-shadow: 1px 1px 4px rgb(58, 4, 4);
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    padding: 0 20px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    display: block;
    position: absolute;
    bottom: -32px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
  }

  &__levels-table {
    padding: 0 50px 34px;

    @include Small {
      padding: 0 30px 34px;
    }

    @include xSmall {
      padding: 0 28px 34px;
      position: relative;
      z-index: 0;
    }
  }

}