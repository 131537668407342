.registration-v3 {
  display: block;
  position: relative;

  &__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase;

    &_large {
      font-size: 22px;
    }
  }

  &__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between;

    @include xSmall {
      flex-direction: column;
      margin-top: 16px;
    }

  }

  &__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    text-align: right;

    @include Small {
      //padding-right: 33px;
    }

    @include xSmall {
      border-right: none;
      padding-right: 0;
    }
  }

  &__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px;

    @include Small {
      padding-left: 23px;
    }

    @include xSmall {
      border-right: none;
      padding-left: 0;
    }
  }

  &__figure {
    width: 342px;
    height: 354px;
    border-radius: 10px;
    background-color: #435182;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.63);
    margin: 0 auto;
    position: relative;

    @include Small {
      width: 275px;
    }
    @include xSmall {
      width: 280px;
      height: 188px;
      display:block;
      margin: 20px auto;
      font-size: 0;
    }
  }

  &__figure-img {
    display: block;
    object-fit: contain;
    filter: drop-shadow(8px 8px 10px black);
    position: absolute;
    width: 235px ;
    left: calc(50% - (235px/2));
    top: -25px;
    @include Small {
      width: 216px;
      height: auto;
      left: calc(50% - (216px/2));

    }
    @include xSmall {
      width: 120px;
      height: auto;
      left: calc(50% - (120px/2));
      display: inline-block;
      vertical-align: top;
      top: -22px;
    }
  }

  &__figure-info {
    position: absolute;
    bottom: 25px;
    text-align: center;
    width: 100%;

    @include Small {
      bottom: 30px;
    }

    @include xSmall {
      bottom: 11px;

    }
  }

  &__figure-caption {
    //display: block;
    //padding: 6px 10px 17px;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #ffb824;
    @include xSmall {
      //padding: 12px 20px 17px;

      font-size: 16px;
    }
  }

  &__figure-note {
    font-family: 'ClearSans';
    font-size: 31px;
    font-weight: bold;
    color: #ffffff;
    padding:  5px 0;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5);
    @include Small {
      padding:  10px 0;
    }
    @include xSmall {
      line-height: 1.04;
      font-size: 25px;
    }
  }

  &__form {
    width: 330px;

    @include Small {
      width: 300px;
    }

    @include xSmall {
      width: 260px;
      margin: 0 auto;
    }
  }

  &__input {
    display: block;
    margin-bottom: 16px;
  }

  &__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px;
  }

  &__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(253,99,36,.7);
    }

    @include xSmall {
      width: 260px;
      margin: 18px auto 0;
    }
  }

  &__socials {
    margin: 24px auto 0;
  }

  &__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px;
  }


}