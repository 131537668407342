.gameplay-gallery {
  list-style: none;
  margin: 0;
  padding: 27px 27px 10px;

  &__item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .preview {
    background: none;
    box-shadow: none;
  }

  .preview__info {
    padding: 0;
  }

  .preview__title {
    text-shadow: 0 1px 0 #111c28;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    padding-top: 13px;
  }

  .preview__button.button_color_orange {
    margin-top: 35px;
  }

}