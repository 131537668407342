.popup_lottery-bonus-popup {
  background-image: linear-gradient(180deg,#152740 , #070829);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  width: 660px;
  @include xSmall{
    overflow: auto!important;
  }
  .popup__close{
    background: transparent;
    box-shadow: none;
    margin-top: 7px;
  }
  .popup__head{
    background: #00142c;
  }
  .popup__title{
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 15px 15px;
  }
  .popup__content{
    font-size: 17px;
    padding: 0 10px;
    display: flex;
    @include xSmall{
      display: block;
    }
    img{
      max-width: 100%;
      position: relative;
       top: 50%;
     transform: translateY(-50%);
     @include xSmall{
      transform: none;
      position: unset;
      }
    }
    button {
      background-image: linear-gradient(180deg,#e2d533 , #ff8d00);
      box-shadow: 0px 0px 16px 9px #e4cf1757, inset 0 2px 0 0 rgba(247,204,69,.004);
      width: 100%;
      height: 51px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      display: block;
      margin: 10px auto 15px;
      color: #8e231f;
      font-size: 18px;
      text-transform: uppercase;
      
      &:last-child{
        box-shadow: 0 0 13px 6px rgba(66, 118, 195, 0.16), inset 0 0 35px 12px rgba(46, 152, 230, 0.18);
        background: none;
        width: 75%;
        color: #fff;
        border: 1px solid #0c4e64;
        text-shadow: 0 1px 0 #000;
        font-size: 18px;
      }

      &:focus {
        outline: 0;
      }
    }

    .first__p{
      font-weight: 500;
      color: #61698d;
      font-size: 18px;
    }
  }
  .timer__note{
    color: #7297d3;
    padding-bottom: 0;
    font-size: 11px;
  }
  .timer__row_digit .timer__cell{
    color: #7297d3;
    font-size: 36px;
  }
  .timer__row_caption .timer__cell{
    color: #7297d3;
    font-size: 12px;
  }
}
.lottery-bonus-popup{
  &__column{
    flex-basis: 50%;
    padding: 20px 15px 20px;

    @include xSmall{
      width: 100%;
    }

    &> *:first-child{
      margin-top: 0;
    }
    h2 {
      font-size: 36px;
      color: rgb(255, 204, 0);
      font-weight: bold;
      line-height: 1;
      margin: 0;
    }
    p{
      text-align: justify;
      margin: 25px auto;
      span{
        color: rgb(255, 204, 0);
      }
    }
    &:first-child{
      p{
        color: #61698d;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
        text-align: center;
      }
      h3{
        color: #ffcc00;
        font-size: 22px;
        line-height: 1;
        span{
          font-size: 34px;
          display: block;
        }
      }
    }
  }
}