.svg-arrow-left-dims {
	width: 12px;
	height: 20px;
}

.svg-arrow-right-dims {
	width: 12px;
	height: 20px;
}

.svg-bitcoin-dims {
	width: 306.5px;
	height: 64.001px;
}

.svg-cancel-dims {
	width: 16px;
	height: 16px;
}

.svg-card_rub-dims {
	width: 142px;
	height: 46px;
}

.svg-close-dims {
	width: 18px;
	height: 18px;
}

.svg-comepay-dims {
	width: 77px;
	height: 70px;
}

.svg-contact-dims {
	width: 110px;
	height: 70px;
}

.svg-counter-dims {
	width: 40px;
	height: 49px;
}

.svg-facebook-dims {
	width: 12px;
	height: 25px;
}

.svg-game-hall-dims {
	width: 23px;
	height: 18px;
}

.svg-gift-dims {
	width: 126px;
	height: 110px;
}

.svg-lottery-dims {
	width: 20px;
	height: 24px;
}

.svg-lottery2-dims {
	width: 20px;
	height: 24px;
}

.svg-mailru-dims {
	width: 1000px;
	height: 1000px;
}

.svg-mobile-dims {
	width: 142px;
	height: 47px;
}

.svg-money-dims {
	width: 27px;
	height: 18px;
}

.svg-moneyru-dims {
	width: 142px;
	height: 48px;
}

.svg-odnoklassniki-dims {
	width: 14px;
	height: 23px;
}

.svg-okpay_rub-dims {
	width: 142px;
	height: 34px;
}

.svg-payeer_rub-dims {
	width: 142px;
	height: 31px;
}

.svg-perfectmoney_usd-dims {
	width: 142px;
	height: 29px;
}

.svg-profile-dims {
	width: 24px;
	height: 24px;
}

.svg-promo-dims {
	width: 23px;
	height: 24px;
}

.svg-qiwi_rub-dims {
	width: 111px;
	height: 48px;
}

.svg-ruble-dims {
	width: 13px;
	height: 18px;
}

.svg-slider-left-transp-dims {
	width: 63px;
	height: 88px;
}

.svg-slider-right-transp-dims {
	width: 63px;
	height: 88px;
}

.svg-star-dims {
	width: 20px;
	height: 18px;
}

.svg-star-filled-dims {
	width: 20px;
	height: 18px;
}

.svg-telegram-dims {
	width: 300px;
	height: 300px;
}

.svg-terminal-dims {
	width: 123px;
	height: 47px;
}

.svg-tournament-dims {
	width: 20px;
	height: 24px;
}

.svg-twitter-dims {
	width: 20px;
	height: 17px;
}

.svg-vip-dims {
	width: 25px;
	height: 17px;
}

.svg-vip-level-dims {
	width: 24px;
	height: 24px;
}

.svg-vip-points-dims {
	width: 24px;
	height: 24px;
}

.svg-vkontakte-dims {
	width: 23px;
	height: 13px;
}

.svg-walletone-dims {
	width: 82px;
	height: 70px;
}

.svg-webmoney-dims {
	width: 142px;
	height: 36px;
}

.svg-wheel-of-fortune-dims {
	width: 22px;
	height: 24px;
}

.svg-yamoney_rub-dims {
	width: 142px;
	height: 36px;
}

