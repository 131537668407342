.main_lottery-details{

  img{
    max-width: 100%;
  }
}
.section.section_full .section__main-lottery{
  max-width: 100%;

  .panel.panel_tournament{

    @include Small{
      padding-top: 20px;
      padding-bottom: 0;
    }
  }


  .main__item{
    width: 870px;
    max-width: calc(100% - 20px);
    background: #00284b;
    border: 1px solid #2a588c;
    border-radius: 3px;
    filter: none;
    

    @include xLarge {
      width: 1380px;
    }

    @include Large {
      width: 1140px;
    }

    @include Small {
      width: 660px;
    }

    @include xSmall {
      width: 90%;
      display: block;
      position: relative;
    }

    &:hover{
      box-shadow: inset 0px 0px 30px 0px #4c9cb9;
      filter: drop-shadow(0 0 10px #002c5b);
    }

    .tournament_name{
      letter-spacing: 1px;
      font-size: 28px;
      margin-top: 0;

      @include Medium{
        margin-bottom: 10px;
      }

      @include Small{
        margin: 0 0 10px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @include xSmall{
        position: static;
        max-width: 100%;
        margin: 10px 0 -16px;
        font-size: 22px;
        text-align: center;
      }
    }

    .tournament_summa{
      color: #366297;

      @include Medium{
        margin-bottom: 10px;
      }
    }

    .countdown span{
      color: #fcc807;
      font-size: 47px;
      @include Medium{
        font-size: 44px;
      }
      @include xSmall{
        font-size: 38px;
      }

      &:last-child{
        color: #366297;
        font-size: 50px;

        @include xSmall{
          font-size: 40px;
        }
      }
    }

    .tournament_status .active-tournament__status, .tournament_status .finished-tournament__status, .tournament_status .future-tournament__status{
      padding-top: 0;
      padding-bottom: 22px;

      @include Medium{
        padding-right: 53px;
        padding-bottom: 10px;
      }

      @include Small{
        top: 14px;
      }

      @include xSmall{
        top: 20px;
        right: 5px; 
      }
    }

    .active-tournament__status{
      color: #00e14f;
    }

    .tournament_status .finished-tournament__status {
      color: #ff3e00;
    }

    .tournament_description{
      color: #fff;
      text-align: justify;
    }

    .icon.icon_arrow-right-white{
      background-image: none;

      &:after{
        content: '»';
        font-size: 30px;
        font-style: normal;
        position: relative;
        top: -2px;
      }
    }


    .button{
      background: #135a82;
      border: 0;
      box-shadow: none;
      float: right;
      padding: 10px 20px;
      text-transform: capitalize;
      font-weight: 100;
      text-shadow: none;

      @include xSmall{
        float: none;
        margin-bottom: 0;
        position: absolute;
        right: 30px;
      }
    }
  }
  .panel__img{

    @include xSmall{
      width: 100%;
      max-width: 100%;
    }
  }
  .panel{
    display: block;
    @include xSmall{
      padding-bottom: 80px;
    }
  }

  .lottery__caption_xs{
    font-size: 14px;
  }

  .panel__cell{
    display: inline-block;
    max-width: 100%;

    @include xSmall{
      display: block;
      width: 100%;
    }

    .panel__overflow{
      display: none;
    }

    .img_overflow{

      @include xSmall{
        width: 100%;
      }
    }

    &:first-child{
      width: 250px;

      @include xSmall{
        width: 75%;
      }
    }

    &:nth-child(2){
      width: 330px;

      @include Medium{
        width: 290px;
      }

      @include Small{
        padding-left: 15px;
      }
      @include xSmall{
        width: 100%;
      }
    }

    &:last-child{
      width: 729px;

      @include Large{
        width: 489px;
      }

      @include Medium{
        position: static;
        width: 279px;
      }

      @include Small{
        padding: 0;
        width: 100%;
      }

      @include xSmall{
        position: static;
        width: 100%;
      }
    }
  }

  .tournament_status .active-tournament__status img, .tournament_status .finished-tournament__status img, .tournament_status .future-tournament__status img{
    top: -3px;
    right: 14px;
    width: 51px;
    @include Medium{
      top: 17px;
      right: 24px;
    }
  }
  .tournament_prises{
    @include Small{
      text-align: center;
    }
    @include xSmall{
      text-align: center;
    } 
  }
  .panel__icon-cell{
    text-align: left;
    @include Small{
      text-align: center;
    }
    @include xSmall{
      text-align: center;
    }
  }

  #prev_lotteries .panel__icon-cell .panel__icon{
    text-align: center;
    margin: 0;
  }
}
img.layout_tournament-item__img{

  @include xSmall{
    position: relative;
    height: 130px;
    width: 100%;
    max-width: none;
    // left: calc(50% - 325px);
  }
}

.layout_tournament-item_details{
  color: #fff;
  height: 117px;
  background-image: linear-gradient(90deg,#09203a,#183a63 50%,#09203a);
  width: 1420px;
  margin: -4px auto 0;
  position: relative;
  padding-top: 33px;
  font-size: 18px;
  line-height: 1.5;

  @include Large{
    width: 1220px;
  }

  @include Medium{
    width: 980px;
  }

  @include Small{
    width: 750px;
  }
  @include xSmall{
    max-width: 100%;
    padding: 15px 10px;
    height: initial;
  }

  &:after{
    content: '';
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, rgba(0,26,59,1) 0%, rgba(31, 126, 190, 1) 50%, rgba(0,26,59,1) 100%);
    display: block;
    position: absolute;
    bottom: 0;
  }
}
.tournament-item{

  &_gamer-status{
    color: #fff000;

    span{
      color: #fff;
    }
  }
}
.lottery-details{

  &_yellow-big-txt{
    color: #fff000;
    font-size: 1.3em;
    font-weight: 900;
  }

  &_block{
    background-color: #00284b;
    border: 1px solid rgb(45, 81, 125);
    border-radius: 3px;
    width: 1420px;
    margin: 0 auto 20px;
    padding: 20px 50px 35px;
    color: #fff;

    @include Large{
      width: 1220px;
    }

    @include Medium{
      width: 980px;
    }

    @include Small{
      width: 750px;
    }

    @include  xSmall{
      width: 90%;
      min-width: 310px;
      padding: 10px 15px;
    }

    &-slider{

      @include Large{
        width: 1100px;
        margin: 0 auto;
      }

      @include Medium{
        width: 880px;
        margin: 0 auto;
      }

      @include Small{
        width: 350px;
        margin: 0 auto;
      }

      @include xSmall{
        max-width: 350px;
        width: 90%;
        min-width: 200px;
        margin: 0 auto;
      }
    }

    &-slider_slide{
      text-align: right;
      padding: 0 0 0 15px;
      position: relative;

      p{
        text-align: left;
        width: 200px;
        padding-left: 10px;
        color: #fff000;
        border-left: 1px solid;
        height: 46px;
        overflow: hidden;

        @include Large{
          border: 0;
          width: initial;
        }

        @include Medium{
          border: 0;
          width: initial;
        }

        @include Small{
          border: 0;
          width: initial;
        }

        @include xSmall{
          width: 100%;
          max-width: 100%;
          padding: 0;
          border: 0;
        }
      }

      span{
        position: absolute;
        top: calc(50% - 40px);
        font-size: 80px;
        color: #fff000;
        font-weight: 900;
        text-shadow: 0 0 7px #000;
        right: 55%;

        @include Large{
          font-size: 65px;
          right: 50%;
        }

        @include Medium{
          font-size: 50px;
          right: 51%;
        }
        @include Small{
          font-size: 65px;
        }
        @include xSmall{
          font-size: 50px;
        }
      }

      img{
        display: inline;
        max-width: 65%;
      }
    }

    & .slick-next{
      right: -40px;
      height: 71px;
      width: 37px;

      &:before{
        content: url(../img/grand-lottery-arrow-r.png);
        height: 71px;
        width: 37px;
        display: block;
      }
    }

    & .slick-prev{
      left: -40px;
      height: 71px;
      width: 37px;

      &:before{
        content: url(../img/grand-lottery-arrow-l.png);
        height: 71px;
        width: 37px;
        display: block;
      }
    }

    &-header{
      font-size: 24px;
      color: #fff000;
      font-weight: 700;
      line-height: 1.993;
      position: relative;
      z-index: 9;
      background: #00284b;
      width: 300px;
      margin: 0 auto;

      @include xSmall{
        width: 100%;
        line-height: 1.2;
      }
    }

    &-subheader{
      color: #1f7ebe;
      font-size: 18px;
      border-bottom: 1px solid #1f7ebe;
      line-height: 4;
      display: inline-block;
      padding: 0 20px;
      margin-top: -25px;
      position: relative;

      @include xSmall{
        border-top: 0;
        margin-top: 0;
        line-height: 1.2;
        padding: 10px 0;
      }
    }
  }

  &_more{
    background: #091b2f;
    margin-top: 25px;
    padding: 15px 10px 15px;
    border: 1px solid rgb(80, 104, 133);
    border-radius: 3px;
  }

  &_more-header{
    border: 1px solid #1f7ebe;
    background-color: #122d4c;
    width: 184px;
    margin: -25px auto 0;
    border-radius: 3px;
    font-weight: 100;
    color: #1f7ebe;
    padding: 12px 0;
    position: relative;
    top: 25px;
  }

  &_popup-button{
    margin-top: 35px;
    display: inline-block;
    text-decoration: underline;
  }
}

.popup.lottery-res-popup{
  background: url(../img/grand-lottery-popup-bg.png) 50% -1px no-repeat;
  height: auto;
  width: 686px;
  border: 1px solid #2d517d;
  border-radius: 3px;
  padding-bottom: 50px;
  color: #fff;
  font-weight: 100;

  .popup__close{
    background: transparent;
    box-shadow: none;
  }

  h2{
    color: #fff000;
    text-align: center;
  }

  table{
    width: 90%;
    margin: 0 auto;
  }

  thead{
    background: #1a406d;

    td{
      padding: 15px 10px;
    }
  }

  tbody{

    tr{
      background: #1a406d;

      &:nth-child(odd){
        background: #1e4c81;
      }
    }
  }

  td{
    border: 0;
    padding: 10px;
  }
}
