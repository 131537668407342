.rules {
  padding: 33px 35px 33px;

  &__title {
    color: #fefefe;
    margin: 0;
  }

  &__caption {
    font-weight: 400;
    color: #fff001;
    font-size: 15px;
    margin: 18px 0 0 0;

    .rules__note + & {
      margin-top: 33px;
    }

  }

  &__note {
    font-weight: 400;
    color: #7c9bbe;
    font-size: 14px;
    line-height: 17px;
    margin: 16px 0 0;
  }

}