.hide_big_nav {
  display: none;
  color: #ffffff;
  font-weight: 700;
  position: relative;
  min-width: 86px;
  padding-top: 8px;
  @include xSmall {
    display: inline-block;
   }
}
.gameplay-nav-small {
  position: absolute;
  right: -10px;
  z-index: 1;
  display: block;
  width: 120px;
  margin: 0;
  padding: 0;
  background-color: #153358;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  &.hide {
    display: none;
  }
  &__item {
    display: block;
    vertical-align: top;
    position: relative;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 16px;
    cursor: pointer;

  }

}
.gameplay-nav {
  display: table;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  @include xSmall {
    display: none;
  }
  &__item {
    display: table-cell;
    vertical-align: top;
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 16px;
    cursor: pointer;
      a{
        color: #fff;
        text-decoration: none;
      }
    &_active {
      color: $textaccent;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        @include sprite($arrow-down);
      }
    }

    &:before {
      content: '-';
      color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      left: 100%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%,-50%);
    }

    &:last-child:before {
      content: none;
    }


  }
}