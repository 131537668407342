.user-panel {
  display: table;
  width: 100%;
  position: relative;
  font-size: 0;
  border-collapse: collapse;

  @include xSmall {
    display: block;
    padding-top: 72px;
  }

  &__cell {
    display: table-cell;
    vertical-align: top;
    width: 1px;

    @include xSmall {
      display: block;
      width: 100%;
    }

    &_action {
      white-space: nowrap;
      width: 1px;
      text-align: right;
      padding-top: 3px;

      .header__panel_logged.open & {
        width: 100%;
        text-align: left;
      }


      @include Small {
        min-width: 84px;
      }
    }

    &_status {
      width: 1px;
      max-width: 40px;
      // padding-top: 5px;

      @include Medium {
        max-width: 60px;
      }

      @include xSmall {
        display: none;
      }
    }

  }


  &__status.status {
    display: block;
    vertical-align: top;
    position: relative;
    margin-left: 5px;

    @include Small {
      display: none;
    }
  }

  &__rating.rating {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: 12px;
    //padding-right: 32px;
    width: 160px;

    .user-panel__cell_bonus & {
      margin-left: 62px;
      width: 144px;


      .rating__info {
        bottom: -4px;
        right: -34px;
      }

      @include xLarge {

        .rating__bar {
          width: 175px;
        }

        .rating__title:first-child {
          display: inline-block;
        }

      }

      @include Large {

        .rating__bar {
          margin-left: 0;
        }

      }

      @include Small {
        margin-left: 0;

        .rating__bar {
          margin-top: 10px;
          width: 108px;
        }

        .rating__info {
          right: -26px;
          bottom: -4px;
        }
      }

      @include xSmall {
        margin-left: 0;
        width: 100%;
        display: block;

        .rating__title_accent {
          margin-left: 14px;
        }

      }

    }

    @include xLarge {

      .rating__bar {
        display: block;
        vertical-align: top;
        position: relative;
        width: 175px;
      }
    }

    @include Large {

      .rating__bar {
        display: block;
        vertical-align: top;
        position: relative;
        width: 175px;
      }

    }

    @include Small {

      .rating__bar {
        margin-top: 12px;
        width: 108px;
      }

      .rating__title {
        display: block;
        padding-top: 4px;
      }

      .rating__icon:first-child {
        margin-left: 0;
      }

      .rating__info {
        right: -26px;
        bottom: -4px;
      }


    }

    @include xSmall {
      width: 100%;
      display: block;
      margin-left: 0;
      padding: 10px 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 0;
        border: solid 1px #08203a;
      }


      .rating__title {
        font-size: 12px;
      }

      .rating__bar {
        display: block;
        width: 110px;
        float: right;
        margin: 0;
        position: absolute;
        right: 0;
        top: 4px;
      }

      .rating__info {
        display: none;
      }

      .rating__percent {
        padding-top: 0;
        font-size: 9px;
        padding-right: 3px;
      }

      .rating__icon {
        margin-right: 1px;
        display: inline-block;
        width: 18px;
        height: 16px;
      }

      .rating__icon:first-of-type {
        margin-left: 5px;
      }

    }

  }

  &__bonus.rating {

  }

  &__button.button {
    display: inline-block;
    position: relative;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16), 0 8px 6px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(140deg,#ff8d00,#e2d533);
    text-shadow: 1px 1px 4px rgb(58, 4, 4);
    padding: 14px 12px 12px;
    margin-left: 56px;
    transition: box-shadow 0.2s ease;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(210, 228, 47, 0.7);
    }

    @include xLarge {
      margin-left: 108px;
    }

    @include Large {
      margin-left: 38px;
    }

    @include Small {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      padding: 9px 7px;
      width: 87px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    }

    @include xSmall {
      width: 100%;
      display: block;
      margin: 19px auto;
      border-radius: 2px;
      padding: 8px 12px 8px;
      position: absolute;
      top: 0;
      left: 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: -18px;
        border: solid 1px #08203a;
      }
    }
  }

  &__countpad.countpad {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 21px;

    @include Large {
      margin-left: 21px;
    }

    @include Small {
      display: block;
      position: absolute;
      top: 48px;
      right: 0;
      width: 35px;
      height: 35px;
    }

    @include xSmall {
      background: none;
      border: none;
      box-shadow: none;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 10px 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 0;
        border: solid 1px #08203a;
      }

      &:hover {
        box-shadow: none;
        border: none;
        background: none;
      }
    }

    & .countpad__icon {
      @include xSmall {
        top: 8px;
        left: 2px;
        display: none;
        transform: none;
        position: absolute;
      }
    }

    & .countpad__counter {
      @include xSmall {
        right: 0;
        top: 9px;
        box-shadow: none;
      }
    }

    & + & {
      margin-left: 18px;

      @include Large {
        margin-left: 18px;
        right: 0;
      }

      @include Small {
        right: 0;
      }

      @include xSmall {
        margin-left: 0;
        display: block;
        left: 0;
      }

    }
  }

  @include xLarge {
    .rating__stars {
      margin-left: 5px;
      padding-top: 1px;
    }
  }

  @include Large {
    .rating__stars {
      margin-left: 5px;
      padding-top: 1px;
    }
  }

  @include Small {
    .rating__stars {
      //padding-top: 6px;
    }
  }

  &__profile, &__logout, &__switchlang {
    display: none;
    position: relative;
    font-family: 'ClearSans',sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #00a6ff;
    padding: 12px 40px;
    cursor: pointer;

    @include xSmall {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
      border: solid 1px #08203a;
    }
  }

  &__profile .mobile-nav__icon {
    left: 0;
  }

  &__logout {
    font-weight: 300;
  }

  &__switchlang {

  }

  &__note {
    text-transform: uppercase;
    &_important {
      color: #fc5526;
    }
  }

  &__caption {
    position: relative;
    vertical-align: top;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #00a6ff;
    padding: 2px 40px;

    &_accent {
      position: absolute;
      top: 10px;
      right: 0;
      padding: 0;
    }
  }

  &__vip-points {
    display: none;

    @include xSmall {
      display: block;
      position: relative;
      height: 40px;
      margin: 0;
      padding: 10px 0;

      .user-panel__caption {
        padding: 2px 16px;
        display: inline-block;
      }

      .user-panel__caption_accent {
        padding: 2px 0;
        display: inline-block;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 0;
        border: solid 1px #08203a;
      }
    }
  }

}