.profile-contacts {
  display: block;
  position: relative;
  padding: 41px 31px 18px;

  @include Small {
    padding: 41px 20px 18px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(90deg,#132e4d,#52baff 50%,#132e4d);
    box-shadow: 0 1px 1px rgba(255,255,255,0.1);
  }

  &__title {
    display: block;
    margin-bottom: 29px;
  }

  &__action {
    font-size: 0;
    margin: 0 -8px;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 8px;

    @include xSmall {
      width: 100%;

      & + & {
        margin-top: 22px;
      }
    }
  }

  &__label {
    display: block;
    position: relative;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    text-transform: uppercase;
    margin-bottom: 14px;
  }

  &__label-inner {
    font-size: 16px;
    display: inline-block;
    width: 70px;
    line-height: 23px;
  }

  &__status {
    background-color: #fd2230;
    border-radius: 1px;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 13px 4px;
    display: inline-block;
    vertical-align: top;
    margin-left: 18px;

    @include Small {
      margin-left: 5px;
    }

    &_confirmed {
      background-color: #6a9500;
      padding: 3px 11px 4px;
    }
  }

  &__input {
    display: block;
    position: relative;

    .input__inner {
      width: 100%;
      height: 42px;
      background-color: #091420;
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0,0,0,.13),0 1px 0 #091420;
      padding: 10px 15px 10px 15px;
    }

    input[placeholder]          {text-overflow:ellipsis;}
    input::-moz-placeholder     {text-overflow:ellipsis;}
    input:-moz-placeholder      {text-overflow:ellipsis;}
    input:-ms-input-placeholder {text-overflow:ellipsis;}
  }

  .profile-contacts__button + .field-userprofile-email .input__inner, .profile-contacts__button + .input__inner, .profile-contacts__button + .field-userprofile-phone .input__inner {
    padding: 10px 105px 10px 15px;
    text-transform: none;

  }
  &__button {
    width: 93px;
    height: 29px;
    padding: 6px 0;
    background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    text-shadow: 1px 1px 4px rgb(58, 4, 4);
    font-size: 13px;
    line-height: 17px;
    font-family: "ClearSans", sans-serif;
    position: absolute;
    right: 6px;
    top: 44px;
    z-index: 1;
    @include Medium {
      font-size: 11px;
    }
    @include Small {
      font-size: 11px;
    }
    @include xSmall {
      font-size: 10px;
    }
  }
  .field-userprofile-email .input__inner_readonly  {
    pointer-events: none;
  }

  .profile-contacts__button + .field-userprofile-phone  {
    position: relative;
    .input__inner {
      padding-left: 20px;
    }
  }
  .profile-contacts__button + .field-userprofile-phone:before {
    content: '+';
    display: block;
    position: absolute;
    top: 0;
    left: 12px;
    font-size: 14px;
    color: #fff;
    height: 42px;
    line-height: 42px;

    @include xSmall {
      left: 12px;
    }
  }
  &__subtitle{
    color: #9e9cbf;
    font-family: 'ClearSans',sans-serif;
    font-size: 13px;
    line-height: 14px;
  }
}