// @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Roboto+Condensed:400,300,700&subset=latin,cyrillic');

@import 'normalize';
@import 'variables';
//break points
@import "components/device_mixin";

// Load in our compiled SCSS variables
@import 'sprites';
@import 'components/icon';
@import "components/svg";
@import "svgsprite";
@import "components/scrollbar";

@import "components/title";
@import 'components/button';
@import 'components/input';
@import "components/checkbox";
@import "components/radiobutton";
@import 'components/wrap';
@import 'components/section';
@import "components/table";
@import "components/aside";
@import 'components/main';
@import 'components/header';
@import 'components/head-panel';
@import "components/mobile-panel";
@import "components/user-panel";
@import "components/toppanel";
@import "components/user-toppanel";
@import "components/status";
@import "components/rating";
@import "components/tooltip";
@import "components/countpad";
@import 'components/nav';
@import "components/mobile-nav";
@import 'components/signup';
@import 'components/socials';
@import "components/bottom-panel";
@import "components/wish-bonus";

@import 'components/hero';
@import 'components/hero-promo';
@import "components/countdown";

@import 'components/main-nav';
@import 'components/subnav';
@import 'components/winsline';

@import 'components/preview';
@import 'components/search';
@import "components/tournament";
@import "components/timer";

@import "components/footer";
@import "components/info-panel";
@import "components/alert-panel";

@import "components/modal";
@import "components/popup";
@import "components/overflow";

@import "components/bonus";
@import "components/registration";
@import "components/registration-v2";
@import "components/registration-v3";
@import "components/registration-v4";
@import "components/registration-v5";
@import "components/registration-v6";
@import "components/registration-v7";
@import "components/sunday-popup";
@import "components/rwp-popup";
@import "components/monday-rush-popup";
@import "components/clock-timer";

@import "components/payment";

@import "components/tab";
// Tabs content blocks

@import "components/cashier-bonus";
@import "components/tab-cashier";
@import "components/tab-bonuses";
@import "components/vip";
@import "components/profile";
@import "components/profile-info";
@import "components/profile-details";
@import "components/profile-contacts";
@import "components/profile-socials";
@import "components/profile-notify";
@import "components/profile-panel";
@import "components/profile-password";

@import "components/popup-panel";
@import "components/re-call";
@import "components/_lottery-bonus-popup";

@import "components/payitem";
@import "components/pay-tooltip";
@import "components/history";
@import "components/filter";

@import "components/bonus-panel";
@import "components/levels-table";

@import "components/promo-panel";
@import "components/promo-bonus";
@import "components/promo-details";
@import "components/gift-panel";

@import "components/bullet-list";

@import "components/panel";
@import "components/tournament-details";
@import "components/summary";
@import "components/leaderboard";

@import "components/slider";
@import "components/chosen-select";
@import "components/datepicker";

@import "components/lottery";
@import "components/ticket";
@import "components/lottery-details";
@import "components/lottery-details-promo";
@import "components/lottery-info";
@import "components/grand-lottery";
@import "components/table-panel";
@import "components/winnings";

// Gameplay section

@import "components/gameplay";
@import "components/gameplay-toppanel";
@import "components/gameplay-user";
@import "components/gameplay-nav";
@import "components/gameplay-signup";
@import "components/gameplay-gallery";
@import "components/gameplay-panel";
@import "components/jackpot";

// vipclub pages

@import "components/vipclub";
@import "components/vip-panel";
@import "components/jackpot-details";
// Wheel of fortune

@import "components/fortune-levels";
@import "components/fortune-history";
@import "components/fortune-wheel";
@import "components/range";

@import "components/custom";

// Static pages

@import "static-pages/maintenance";
@import "static-pages/restriction";
@import "static-pages/pagenotfound";
@import "static-pages/rules";
@import "static-pages/page_content";
@import "static-pages/partners";

@import "components/scroller";
@import "components/siteheart";


html {
  height: 100%;

  &.hidden {
    overflow: hidden;
  }
}

body {
  background-color: $bgcolor;
  font-family: 'ClearSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;

  @include xSmall {
    overflow-x: hidden;
  }

  &.hidden {
    overflow: hidden;
  }
}
.txt-orange{
  color: #ff8920;
}
a, a:hover, a:active {
  color: #fff;
  text-decoration: none;
}
main a, main  a:active {
  color: #3c90f0;
}
html.modal_open {

  @include xSmall {
    overflow: hidden;
    &.Sa {
      overflow: visible;
      height: auto;
      body {
        overflow: visible;
      }
    }
    body {
      overflow: hidden;
    }
  }
  .overflow {
    display: block;
  }
}
.loading {
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}
.txt-orange{
  color: #ff8920;
}
.loader {
  color: #ffffff;
  font-size: 20px;
  top: 50%;
  left:50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load 1.3s infinite linear;
  animation: load 1.3s infinite linear;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
@-webkit-keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@font-face {
  font-family: "ClearSans";
  src: url("ClearSansRegular/ClearSansRegular.eot");
  src: url("ClearSansRegular/ClearSansRegular.eot?#iefix")format("embedded-opentype"),
  url("ClearSansRegular/ClearSansRegular.woff") format("woff"),
  url("ClearSansRegular/ClearSansRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
