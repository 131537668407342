.profile-notify {
  display: block;
  position: relative;
  padding: 41px 31px 18px;

  @include Small {
    padding: 41px 20px 18px;
  }

  @include xSmall {
    padding: 19px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(90deg,#132e4d,#52baff 50%,#132e4d);
    box-shadow: 0 1px 1px rgba(255,255,255,0.1);
  }

  &__row {
    display: block;
    width: 100%;

    & + & {
      margin-top: 10px;
    }

    @include xSmall {
      display: table-row;
    }
  }

  &__title {
    display: block;
    margin-bottom: 4px;
  }

  &__action {
    display: block;
    position: relative;
    font-size: 0;

    @include xSmall {
      display: table;
      width: 100%;
    }
  }

  &__block {
    display: inline-block;
    vertical-align: top;
    margin-right: 56px;
  }

  &__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 13px;
    display: block;

    .profile-notify__row & {
      padding-top: 16px;
    }

    @include xSmall {
      display: table-cell;
      vertical-align: top;
    }

  }

  &__choice {
    display: block;

    @include xSmall {
      display: table-cell;
      vertical-align: top;

      .profile-notify__row & {
        padding-top: 16px;
      }
    }
  }

  &__checkbox {
    margin-right: 80px;

    @include Small {
      margin-right: 40px;
    }

    @include xSmall {
      margin-right: 0;
      display: block;
      margin-bottom: 11px;
    }

    &:last-child {
      margin-right: 0;
    }

    .checkbox__label {
      font-size: 15px;
      font-family: "ClearSans", sans-serif;
      font-weight: 500;
    }

  }

}