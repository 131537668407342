.lottery-details {
  display: block;
  position: relative;

  &__header {
    display: block;
    position: relative;
    height: 238px;
    @include  xSmall {
      height: auto;
      min-height: 182px;
      margin-bottom: 15px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 797px;
      width: 100%;
      height: 165px;
      opacity: 0.29;
      background-image: radial-gradient(380px 210px at 50% 163.64%, white 0%, transparent 100%);
      border-bottom: 1px solid #224061;
    }
  }

  &__summary {
    position: relative;
  }

  &__tickets {
    font-size: 0;
    margin-top: 32px;
  }

  &__ticket-table {
    width: 50%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    max-height: 450px;
    background-color: #2f4b6c;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 4px 0 rgba(4, 4, 4, 0.12);

    @include xSmall {
      max-height: none;
    }
  }

  &__winnings {
    display: block;
    position: relative;
    margin-top: 34px;
    padding: 0 0 46px;
    overflow: hidden;

    @include Small {
      margin: 34px auto 10px;
      max-width: 648px;
    }
  }


  &__header_top {
    @include xSmall {
      height: 80px;
      padding-top: 20px;
      &:before {
        content: '';
        display: inline-block;
        width: 1px;
        margin-left: -1px;
        font-size: 0;
        height: 80px;
        vertical-align: middle;
      }
    }
  }
  &__title {
    padding-top: 55px;
    display: block;
    position: relative;
    @include xSmall {
      padding-top: 0;
      display: inline-block;
      vertical-align: middle;
      width: calc(99% - 1px);
      line-height: 33px;
    }
  }

  &__subtitle {
    padding-top: 22px;
    display: block;
    position: relative;
  }

  &__countdown {
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;
    position: relative;
    @include xSmall {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,0);
      margin-top: 0;
      white-space: nowrap;
    }
  }

  &__currency {
    display: block;
    position: absolute;
    bottom: 0;
    right: -48px;
    font-size: 56px;
    line-height: 44px;
    font-weight: 500;
    color: #fff;

    @include xSmall {
      display: none;
    }

    &_ruble {
      @include sprite($ruble-currency-sign);
      bottom: 3px;
    }

    &_dollar {
      right: -40px;
      font-size: 47px;
    }

  }

}