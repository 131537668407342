.overflow {
  display:none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(0,0,0, 0.7);
}

.overflow__game{
  z-index: 199;
}

.modal_open {
  //position: fixed;
  width: 100%;
  .overflow{
    display:block;
  }
}

.overflow__no__money{
  display:none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(0,0,0, 0.7);
}