.rating {
  display: block;
  width: 100%;
  position: relative;

  &__summary {
    display: block;
    width: 100%;
    position: relative;
    text-align: left;
    white-space: nowrap;
  }

  &__info {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: -4px;
    padding: 4px 0 0 0;
    text-align: center;
    width: 21px;
    height: 21px;
    font-size: 0;
    border-radius: 50%;
    background-color: #4ea1e1;
    box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.11),0 0 16px rgba(0,0,0,.26),0 8px 10px rgba(0,0,0,.16);

    .rating__summary & {
      right: -35px;
      bottom: -5px;
      
    }

  }

  &__tooltip {
    min-width: 191px;
    right: 0;
    margin-right: 11px;
    transform: translateX(50%);
    top: calc(100% + 16px);
    opacity: 0;
    visibility: hidden;

    .rating__summary & {
      right: -116px;
      margin-right: 0px;
      top: 0;
    }
    .rating__summary__profile & {
      right: -4px;
      margin-right: 0px;
      top: calc(100% + 16px);
    }

    .rating__info:hover & {
      opacity: 1;
      visibility: visible;
    }
    &_right {
      transform: none;
      right: -12px;
    }
  }

  &__title {
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    color: $textcolor;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    line-height: 20px;

    .rating__info{
      bottom: 0;
    }

    &_accent {
      color: $textaccent;
      margin-left: 4px;

      @include xLarge {
        margin-left: 8px;
      }

      &.rating__title_large {
        margin-left: 5px;
      }
    }

    &_large {
      font-size: 17px;
      @include Small {
        font-size: 14px;
      }
    }

  }

  &__caption {
    display: inline-block;
    position: relative;
    text-align: left;
    color: $textcolor;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 4px;

    &_accent {
      color: $textaccent;
    }
  }

  &__stars {
    text-align: right;
    position: relative;
    display: inline-block;
    top: 0;
    right: 0;
    padding-top: 2px;
  }

  &__icon {
    margin-right: 4px;
    display: inline-block;
    width: 18px;
    height: 19px;
    &:first-of-type {
      margin-left: 12px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  &__bar {
    display: block;
    margin-top: 14px;
    width: calc(100% - 25px);
    height: 12px;
    position: relative;
    background-color: #060d12;
    border: 1px solid #133152;
    border-radius: 5px;
  }

  &__inner {
    display: block;
    position: relative;
    width: 68%;
    height: 12px;
    margin-top: -1px;
    background-color: #4ea1e1;
    border-radius: 5px;
    box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.63), 0 0 27px rgba(122, 247, 255, 0.58), 0 0 24px #000;
  }

  &__percent {
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    padding-top: 13px;
    color: $textcolor;
    font-family: 'ClearSans', sans-serif;

    &_large {
      font-size: 14px;
    }
  }

}

// styles for gameplay page

.rating_gameplay {

  .rating__bar {
    margin-top: 19px;
  }

  .rating__stars {
    top: 0;
    margin-right: 3px;
    .fa-star {
      color: #F2E419;
    }
    .fa-star-o {
      color: #08203A;
      text-shadow: 1px 1px 0px #22476f;
      font-weight: bold;
    }
   }

  .rating__percent {
    padding-top: 20px;
  }

}

// styles for profile tab content
.rating_profile {

  .rating__stars {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 29px;
    padding-left: 15px;
    text-align: center;

    @include xSmall {
      position: absolute;
      top: -81px;
      left: 69px;
      text-align: left;
    }

    .fa-star {
      color: #F2E419;
    }
    .fa-star-o {
      color: #08203A;
      text-shadow: 1px 1px 0px #22476f;
      font-weight: bold;
    }

  }

  .rating__icon {
    margin-right: 5px;
    &:first-of-type {
      margin-left: 0;
    }
  }

  .rating__bar {
    margin: 36px 62px 36px 46px;
    width: auto;

    .rating__info {
      right: -30px;
    }

    @include Small {
      margin: 36px 42px 36px 20px;
    }

    @include xSmall {
      margin: 36px 48px 14px 18px;
    }

  }



  .rating__percent {
    font-size: 15px;
    padding-top: 20px;
  }

  .rating__summary {
    margin-top: 38px;
    padding: 0 18px 0 22px;

    .rating__info {
      right: -33px;
    }
  }

  .rating__caption {
    display: block;
    margin-bottom: 11px;
    &_accent {
      text-transform: none;
    }
    @include Small {
      font-size: 14px;
    }
  }

}

.rating_profile_bonus {

  .rating__summary {
    margin-top: 0;
  }

  .rating__bar {
    margin: 36px 62px 36px 46px;
    width: auto;

    @include Small {
      margin: 36px 42px 36px 20px;
    }

    @include xSmall {
      margin: 24px 48px 14px 18px;
    }

  }

  .rating__percent {
    padding-top: 22px;
  }

  .rating__bar + .rating__summary {
    margin-top: 52px;

    @include xSmall {
      margin-top: 28px;
    }
  }

}
/*fontawesome*/

.rating__icon {
  &.fa-star {
    color: #F2E419;
  }
  &.fa-star-o {
    color: #08203A;
    text-shadow: 1px 1px 0px #22476f;
    font-weight: bold;
  }
}

.user-panel {
  .rating__icon {
    font-size: 11px;
  }
}

/*fontawesome*/