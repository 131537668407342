.payment {
  display: block;
  position: relative;
  max-height: 440px;

  @include xSmall {
    max-height: none;
  }

  &__gallery {
    font-size: 0;
  }

  &__row {
    display: block;
    position: relative;
    text-align: center;
    padding: 0 20px;
    margin: 0 auto;

    &-inner {
      text-align: left;
    }

    @include xSmall {
      padding: 0 8px;
    }
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    margin: 8px 15px 8px 0;
    cursor: pointer;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__tooltip {
    display: none;
    position: relative;
    margin: 4px auto 30px;
    width: 600px;
    max-width: calc(100% - 45px);
    left: -4px;

    &_inner {
      text-align: left;

      @include xSmall {
        width: calc(100% - 8px);
      }
    }

    @include xSmall {
      width: 100%;
      padding: 0 8px;
    }

    &_open {
      display: block;
    }
  }
}