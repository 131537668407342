.slider {
  display: block;
  margin: 0 auto !important;
  position: relative;

  &__item {
    display: block;
    padding: 0 4px;
    &:focus {
      outline: none;
    }
    &__inner {
      position: relative;
      width: 940px;
      margin: 0 auto;
      z-index: 1;
      @include xLarge {
        margin: 0 -76px;
        width: 1420px;
      }
      @include Large {
        width: 1194px;
      }
      @include Medium {
        width: 940px;
      }
      @include Small {
        width: 700px;
      }
    }
  }
  &__img_text {
    position: absolute;
    left: 0;
    top: 0;
    @include Medium {
      width: 32%;
    }
    @include Small {
      width: 32%;
    }
    &.puaro_text {
      top: 23px;
      @include Medium {
        top: 25px;
        width: 32%;
        left: -7px;
      }
      @include Small {
        top: 32px;
        width: 32%;
        left: -4px;
      }
    }


  }
  &__img {
    display: block;
    position: relative;

    &_desktop {
      display: block !important;
      @include xSmall {
        display: none !important;
      }
    }

    &_mobile {
      display: none !important;
      @include xSmall {
        display: block !important;
      }
    }
  }

  &__title {
    display: block;
    text-align: center;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__link {

  }

  .slick-prev {
    left: -14px;
    top: 24px;
    text-align: left;
    height: 20px;
    &:before {
      background-image: url('../img/svg/arrow-left.svg');
      content: '';
      display: inline-block;
      width: 12px;
      height: 20px;
    }
  }

  .slick-next {
    top: 24px;
    right: -14px;
    text-align: right;
    height: 20px;
    &:before {
      background-image: url('../img/svg/arrow-right.svg');
      content: '';
      display: inline-block;
      width: 12px;
      height: 20px;
    }
  }

  .slick-dots {
    display: block;
    width: 100%;
    bottom: -24px;
  }

  .slick-dots li, .slick-dots li button {
    width: 33px;
    height: 4px;
    margin: 0 4px;
  }

  .slick-dots li button:before {
    width: 33px;
    height: 4px;
    background-color: #1f3957;
    content: '';
  }

  .slick-dots li.slick-active button:before {
    width: 33px;
    height: 4px;
    background-color: #fda91b;
    background-image: linear-gradient(180deg, #fda31b 0%, #fd5908 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  }

}


// Style for info slider with just text - no graphics

.slider_info {

  .slider__item {
    display: block;
    position: relative;
    padding: 17px 10px;
    width: 170px;
    height: 78px;
    margin: 0 4px;
    background-color: #415f83;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 4px 0 rgba(4, 4, 4, 0.12);
  }

  .slick-prev {
    left: -23px;
    top: 38px;
  }

  .slick-next {
    top: 38px;
    right: -23px;
  }

}


.slider_hero {

  display: none;

  @include xSmall {
    height: auto;
  }

  .slider__item {
    width: 100%;
    padding: 0;
  }

  .slider__img_withcursor {
    cursor: pointer;
  }

  .slider__img {
    width: 100%;
    height: 390px;

    @include Large {
      height: 324px;
    }

    @include Medium {
      height: 258px;
    }

    @include Small {
      height: 192px;
    }
    @include xSmall {
      height: 250px;
    }
  }

  .tournament__img {
    @include xSmall {
      height: 150px;
    }
  }

  .slider__link {
    display: inline-block;
    position: absolute;
    bottom: 100px;
    text-decoration: none;
    font-family: "ClearSans", sans-serif;
    left: 127px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    &:before {
      content: "";
      position: absolute;
      display: block;
      @include sprite($arrow-right-white);
      left: 100%;
      top: 9px;
      margin-left: 10px;
      transition: margin-left ease 0.3s;
    }

    &:hover {
      text-decoration: underline;

      &:before {
        margin-left: 30px;
      }
    }
  }

  .slick-dots {
    bottom: 10px;
    right: 0;
    display: inline-block;
    width: auto;
    z-index: 100;

    @include xLarge {
      right: 0;
    }

    @include Large {
      right: 0;
    }

    @include Small {
      right: 0;
      bottom: 11px;
    }

    @include xSmall {
      top: 17px;
      right: 17px;
      bottom: auto;
    }
  }

  .slick-dots li, .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0 4px;
  }

  .slick-dots li button:before {
    width: 15px;
    height: 15px;
    background-color: #27425f;
    border-radius: 50%;
    content: '';
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    width: 15px;
    height: 15px;
    background-color: #00455e;
    background-image: linear-gradient(180deg, #1b87d5 0%, #3a6dbf 100%);
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 rgba(122, 199, 255, 0.38), 0 2px 4px rgba(0, 0, 0, 0.24);
  }

}

.slider_leaderboard {

  &.slick-slider {
    margin-bottom: 10px;
    position: static;
    .slick-list {
      position: static;
    }
  }

  .slick-dots {
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    z-index: 9;
  }
  .slick-next.slick-disabled:before, .slick-prev.slick-disabled:before{
    opacity: 1;
    color: rgba(255, 255, 255, 0.25);
  }
  .slick-dots li, .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0 4px;
    font-size: 15px;
    color: #fff;
  }
  .slick-dots li.slick-active button{
    font-size: 20px;
    color: #8aadee;
    position: relative;
    top: 1px;
  }
  .slick-dots li button:before{
    display: none;
  }
  .slick-next{
    bottom: -20px;
    position: absolute;
    top: auto;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 101;
    &:before{
      content: "\f054";
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      padding: 10px 12px;
      border-left: 1px solid #036;
    }
  }
  .slick-prev{
    bottom: -20px;
    position: absolute;
    top: auto;
    left: 0;
    width: 40px;
    height: 40px;
    z-index: 101;
    &:before{
      content: "\f053";
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      padding: 10px 12px;
      border-right: 1px solid #036;
    }
  }
}

.lottery-details__tickets {

  &.slick-slider {
    margin-bottom: 60px;
  }


  .slick-dots {
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: auto;
    z-index: 100;
  }

  .slick-dots li, .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0 4px;
  }

  .slick-dots li button:before {
    width: 15px;
    height: 15px;
    background-color: #27425f;
    border-radius: 50%;
    content: '';
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    width: 15px;
    height: 15px;
    background-color: #00455e;
    background-image: linear-gradient(180deg, #1b87d5 0%, #3a6dbf 100%);
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 rgba(122, 199, 255, 0.38), 0 2px 4px rgba(0, 0, 0, 0.24);
  }

}



.slider_gameplay {

  .slider__item {
    margin: 0 11px;
    padding: 0;
  }

  .slick-prev {
    left: -14px;
    top: 25px;
  }

  .slick-next {
    top: 25px;
    right: -14px;
  }

}

.slider_small {

  .slider__img {
    width: 100%;
  }

  .slick-prev, .slick-next {
    top: 35px;

    /*@include xSmall {
      width: 63px;
      height: 100%;
      background-image: linear-gradient(to right,rgba(0,0,0,.1) 0,rgba(0,0,0,.5) 100%);
      opacity: 0.3;
      z-index: 2;
      top: 34px;
      right: 17px;

      &:before {
        display: none;
      }
    }*/
  }

  .slick-prev {
    /*@include xSmall {
      transform: rotate(180deg);
      top: 0;
      left: 17px;
    }*/
  }

  .slick-dots {
    bottom: -14px;
  }

}

.slider_tournament {

  .slick-prev, .slick-next {
    top: 23px;
  }

}

.levels-table__table.slick-slider {
  margin-bottom: 0;

  .slick-dots {
    top: 44px;
    bottom: auto;
  }

  .slick-dots li, .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0 4px;
  }

  .slick-dots li button:before {
    width: 15px;
    height: 15px;
    background-color: #27425f;
    border-radius: 50%;
    content: '';
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    width: 15px;
    height: 15px;
    background-color: #00455e;
    background-image: linear-gradient(180deg, #1b87d5 0%, #3a6dbf 100%);
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 rgba(122, 199, 255, 0.38), 0 2px 4px rgba(0, 0, 0, 0.24);
  }

}