.vipclub {

  &__header {
    display: block;
    position: relative;
    height: 133px;
    background-image: linear-gradient(230deg, rgba(255, 255, 255, 0.2) 0%, transparent 15%), linear-gradient(-230deg, #324c69 0%, #132e4d 15%);
    border-radius: 6px 6px 0 0;
    box-shadow: inset -1px 0 0 0 #38608e,inset 1px 0 0 0 #355d8c;
  }

  &__content {
    display: block;
    position: relative;
    background-color: #15355a;
    border-radius: 3px;
    padding: 0 0 137px 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      background-color: #0d2239;
      box-shadow: 0 1px 0 #1a3a5e;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__row {
    display: block;
    position: relative;
    font-size: 0;
    text-align: center;

    @include xSmall {
      padding-bottom: 0!important;
    }
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    margin: 20px 58px;
    cursor: pointer;
    @include  Medium {
      margin: 20px 47px;
    }
    @include  Small {
      margin: 20px 10px;
    }
    @include  xSmall {
      margin: 20px auto;
      display: block;
      width: 209px;

    }
  }

  &__info {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: left;
    width: 100%;
    max-width: 862px;
    margin: 0 auto;
    background-color: #285282;
    border: 1px solid #31639d;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.22);
    padding: 29px;
    display: none;
    @include Medium {
      max-width: 815px;
    }
    @include Small {
      max-width: 815px;
    }
    @include xSmall {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      width: auto;
      margin: 0 20px;
    }
    &_open {
      display: block;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 18px;
  }

  &__caption {
    color: #fff;
    font-size: 15px;
    line-height: 18px;
    font-family: "ClearSans", sans-serif;
  }

  &__bullet-list {
    margin: 36px 0;
  }

  &__title {
    font-size: 40px;
    line-height: 22px;
    text-align: center;
    padding: 55px;
    font-family: 'ClearSans', sans-serif;
    @include Medium {
      font-size: 40px;
    }
  }

  &__note {
    margin: 0;
    text-align: center;
    padding: 62px 135px 10px;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    line-height: 20px;
    @include xSmall {
      padding: 20px;
    }
  }

  &__arrow {
    display: block;
    position: absolute;
    z-index: 3;
    top: 1px;
    left: 50%;
    bottom: 100%;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

    &:after {
      content: " ";
      position: absolute;
      bottom: 100%;  /* At the top of the tooltip */
      left: 50%;
      z-index: 3;
      margin-left: -14px;
      border-style: solid;
      border-width: 14px;
      border-color: transparent transparent #285282 transparent;
    }

    &_right {
      right: 45px;
      left: auto;
      &:after {
        right: 45px;
        left: auto;
      }
      @include xSmall {
        left: 50%;
        right: auto;
        &:after {
          right: auto;
          left: 50%;
        }
      }
    }

    &_left {
      left: 98px;
      right: auto;
      &:after {
        right: auto;
        left: auto;
      }
      @include xSmall {
        left: 50%;
        right: auto;
        &:after {
          right: auto;
          left: 50%;
        }
      }
    }


  }

}