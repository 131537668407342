.fortune-history {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  width: 100%;
  background-color: #4d4b63;

  &__head {
    background-color: #58566d;
  }


  &__headRow {

  }

  &__headCell {
    position: relative;
    text-align: left;
    min-width: 116px;
    height: 60px;
    padding: 0 20px;
    &:last-child:after {
      content: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #4e4c61;
      box-shadow: 1px 0 0 #656378;
    }

    &_fluid {
      text-align: left;
      padding: 0 20px;
    }
  }

  &__body {

  }

  &__cell {
    display: table-cell;
    position: relative;
    min-width: 116px;
    height: 61px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;

    &:first-child {
      min-width: 215px;
      text-align: left;
      padding-left: 20px;
    }

    &:last-child {
      min-width: 257px;
      text-align: left;
      padding: 0 24px;
    }

    &:last-child:after {
      content: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #413f55;
      box-shadow: 1px 0 0 #56546c;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 1px;
      height: 1px;
      background-color: #403e54;
      box-shadow: 0 1px 0 #55536d;
    }

    &_fluid {
      width: 100%;
      text-align: left;
      padding: 19px 21px;
    }
  }

  &__title {
    font-size: 18px;
    font-family: 'ClearSans', sans-serif;
    line-height: 20px;
  }

  &__list {
    margin: 0;
    padding: 0 0 14px;
    list-style: none;
  }

  &__listItem {
    display: block;
    position: relative;
  }

  &__listLink {
    display: block;
    position: relative;
    height: 60px;
    padding: 21px;
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.17);
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #403e54;
      box-shadow: 0 1px 0 #55536d;
    }

    &_active {
      background-color: #54526c;
      background-image: linear-gradient(140deg, #9d97de 0%, #8076ec 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26);

      &:before {
        content: none;
      }

    }
  }

  &__item {
    display: block;
    position: relative;
    padding: 20px 32px 20px 128px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: block;
    position: absolute;
    left: 20px;
    top: 0;
  }

  &__caption {
    text-shadow: 0 1px 0 rgba(4, 4, 4, 0.32);
    color: #cbcad7;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    display: block;
  }

  &__button {
    background-color: #ff7c22;
    background-image: linear-gradient(165deg, #ffb11a 0%, #ff0036 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26);
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.11);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    padding: 5px 12px;

    &_disabled {
      background: none;
      box-shadow: none;
      pointer-events: none;
      text-shadow: none;
      padding: 3px 4px;
    }
  }

}