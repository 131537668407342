.info-panel {
  display: block;
  position: relative;
  width: 440px;

  &__header {
    padding: 26px;
    background-color: #0b2540;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.65);
    text-align: center;
  }

  &__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
    font-size: 19px;
    font-family: 'ClearSans', sans-serif;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff;

    &_important {
      color: #ff0000;
      margin-left: 5px;
    }
  }

  &__caption {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    font-family: 'ClearSans', sans-serif;
    color: #fff;
    font-size: 17px;
    line-height: 20px;
  }

  &__content {
    width: 440px;
    height: 89px;
    padding:  24px 42px;
    background-color: #3889d6;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  }
}