.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 679px;
  overflow: auto;
  box-shadow: inset 0 -1px 0 rgba(91, 64, 71, 0.38), 0 1px 8px rgba(0, 0, 0, 0.53);

  @include xSmall {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    background-color: #1b1a27;
    box-shadow: inset 0 -1px 0 rgba(108,106,139,.25);
  }

  &__header {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.26);
    background-image: url('../img/modal-head-bg.jpg');
    height: 78px;
    text-align: center;
    position: relative;
  }

  &__title {
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.14);
    padding: 27px 50px;
    color: #fff;
    font-size: 25px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__icon {
    display: block;
    position: absolute;
    top: 30px;
    right: 21px;
    cursor: pointer;
  }

  &__content {
    background-color: #132e4d;
    padding: 32px 27px 38px 32px;
    font-family: 'ClearSans', sans-serif;
    font-size: 0;
    display: block;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 1px;
      opacity: 0.27;
      background-color: #3f3d60;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.42);
    }

    @include xSmall {
      padding: 0 20px 30px 20px;
    }
  }

  &__button {
    position: absolute;
    display: block;
    left: 50%;
    top: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%,-50%);
    // text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    padding: 9px 22px;
    border-radius: 20px;
    z-index: 2;
    box-shadow: none;
  }

  &__input {
    margin-left: 30px;

    @include xSmall {
      display: block;
      margin-left: 0;
      padding-top: 20px;
    }
    &:first-child {
      margin-left: 0;
    }
  }

  &__input-inner.input__inner {
    background-color: #09203a;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3), 0 1px 0 #09203a;
    border: none;
    color: #ccc;
    font-size: 15px;
    line-height: 18px;
    margin-top: 28px;
    width: 295px;
    text-transform: none;
    height: 49px;

    @include xSmall {
      width: 100%;
      margin-top: 14px;
    }
  }

  &__label {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.33);
    color: #fff;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
  }

  &__caption {
    color: #ccc;
    font-size: 14px;
    display: block;
    white-space: nowrap;
    position: absolute;
    top: 0;
    right: 3px;
    transition: color 0.3s ease;
    cursor: pointer;

    @include xSmall {
      top: 24px;
    }

    &:hover {
      color: #fff;
    }
  }

  &__tooltip {
    margin-top: 14px;
  }

  &__note {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 28px;
    display: block;

    @include xSmall {
      padding: 10px 0 0 0;
      text-align: center;
      margin-bottom: 10px;
      display: block;
    }

    &_important {
      color: $textimportant;
      display: inline-block;
      vertical-align: top;
      font-weight: 700;
      font-size: 17px;
      margin: 33px 0 6px 0;

      @include xSmall {
        margin: 20px 0 0;
        display: block;
      }
    }

    &_accent {
      color: $textaccent;
      display: inline-block;
      vertical-align: top;
      font-weight: 700;
      margin: 33px 0 6px 5px;

      @include xSmall {
        margin: 20px 0 0;
        display: block;
      }
    }
  }

  &__error {
    display: block;
    position: relative;
    text-align: center;
    padding: 10px 0 20px;
    max-width: 100%;

    @include xSmall {
      padding: 0 0 10px;
    }
  }


  &__signup-soc {
    display: block;
    float: left;
    text-align: left;

    @include xSmall {
      float: none;
      padding-bottom: 80px;
    }
  }

  &__signup {
    text-align: center;
    width: auto;
    
    @include xSmall {
      text-align: center;

      .signup__button {
        left: auto;
      }
    }


    .signup__input-inner {
      background-color: #09203a;
      border: 1px solid #353448;
      border-radius: 2px;
      box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.05), inset -121px 0 0 rgba(0, 0, 0, 0.09);
    }
  }

  &__actions {
    display: block;
    float: right;
    text-align: right;

    @include xSmall {
      float: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
    }
  }

  &__social {
    .socials__item {
      background-color: #09203a;
      border: 1px solid #4685a2;
      border-radius: 2px;
      box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.05);
    }
  }

  &__footer {
    width: 100%;
    position: relative;
    padding: 37px 31px 39px 32px;
    background-color: #09203a;
    box-shadow: inset 0 -1px 0 rgba(108, 106, 139, 0.25);
    font-family: 'ClearSans', sans-serif;

    @include xSmall {
      box-shadow: none;
    }
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}
