.range {
  display: block;
  position: relative;

  &__back {
    display: block;
    position: absolute;
    width: 100%;
    height: 17px;
    background: #15141d;
    border-radius: 8px;
    border: none;
    left: 0;
    right: 0;
    bottom: 25px;
  }

  &__back-table {
    display: table;
    width: 100%;
    height: 17px;
    table-layout: fixed;
    border-collapse: collapse;
  }

  &__back-cell {
    display: table-cell;
    position: relative;
    width: calc(100%/7);

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -10px;
      top: -3px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: #15141d;
    }

    &:first-child:before { left: 0; }
    &:nth-child(2):before { left: -5px; }
    &:nth-child(4):before { left: -13px; }
    &:nth-child(5):before { left: -17px; }
    &:nth-child(6):before { left: -21px; }
    &:last-child:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: -3px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: #15141d;
    }
  }

  &__input {
    opacity: 0;
  }



  .irs-bar {
    height: 17px;
    background-color: #8c83e6;
    background-image: linear-gradient(180deg, #9d97de 0%, #8076ec 100%);
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26);
    border: none;
  }

  .irs-line {
    background: #15141d;
    border-radius: 8px;
    height: 17px;
    border: none;
    overflow: inherit;
  }

  .irs-grid {
    bottom: 57px;
    width: 565px !important;
  }

  .irs-grid-text {
    display: block;
    position: absolute;
    bottom: -6px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: inherit;

    &.js-grid-text-0 {
      font-size: 20px;
      line-height: 20px;
      color: #fff;
      bottom: -3px;
      left: 4px!important;

      &:before {  left: -3px; bottom: -4px}
    }
    &.js-grid-text-1 {
      font-size: 23px;
      line-height: 23px;
      bottom: -4px;
      margin-left: -7px !important;
      &:before { left: -6px; bottom: -3px;}
    }
    &.js-grid-text-2 { font-size: 28px;line-height: 28px; &:before {left: -4px; bottom: -5px; }}
    &.js-grid-text-3 { font-size: 30px;line-height: 30px; &:before {font-size: 14px;    left: -9px;
      bottom: -5px;} }
    &.js-grid-text-4 { font-size: 33px;line-height: 33px; &:before {font-size: 15px;left: -9px;
      bottom: -5px;} }
    &.js-grid-text-5 { font-size: 36px;line-height: 36px; &:before {font-size: 15px;left: -9px;
      bottom: -6px;}}
    &.js-grid-text-6 { font-size: 40px;line-height: 40px; &:before {font-size: 15px;left: -6px;
      bottom: -8px;}}

    &:before {
      content: 'x';
      display: block;
      font-size: 10px;
      font-weight: 700;
      position: absolute;
      left: -9px;
      bottom: 0;
    }

    &_active {
      color: #fff;
    }

  }

  .irs-grid-pol {
    display: none;
  }


  .irs-slider {
    top: 29px;
    width: 24px;
    height: 24px;
    border: none;
    background-color: #ff6426;
    background-image: linear-gradient(140deg, #ffb11a 0%, #ff0036 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 5px 8px rgba(0, 0, 0, 0.17);
  }

}

.irs-grid-text_active ~ .irs-grid-text {
  color: #15141d;
}