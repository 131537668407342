.button {
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-align: center;
  cursor: pointer;
  padding: 11px 21px;
  background-color: #185e9c;
  background-image: linear-gradient(180deg, #1b87d5 0%, #163464 100%);
  box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 #7ac7ff, 0 2px 0 rgba(0, 0, 0, 0.11);
  border: none;
  border-radius: 3px;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.22);
  color: #fff;
  font-family: 'ClearSans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  transition: box-shadow 0.2s ease;

  &:hover {
    box-shadow: 0 0 11px 0 #355b8a, inset 0 1px 6px 0 rgba(255, 255, 255, 0.32);
  }

  &:active {
    color: #fff;
  }
}

.button_font_cond {
  font-family: 'ClearSans', sans-serif;
  font-weight: 600;
}

.button_small {
  text-shadow: 0 2px 3px rgba(6, 23, 42, 0.65);
  font-family: 'ClearSans', sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  padding: 1px 5px;
}

.button_shape_round {
  padding: 9px 20px 8px;
  background-color: #e2d533;
  background-image: linear-gradient(180deg,#ff8d00,#e2d533);
  text-shadow: 1px 1px 4px rgb(58, 4, 4);
  border-radius: 17px;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.34), 0 3px 2px rgba(4, 4, 4, 0.26);
  display: inline-block;
  font-family: 'ClearSans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    background-color: darken(#e2d533, 10%);
    background-image: linear-gradient(180deg, darken(#ff8d00,10%) 0%, darken(#e2d533,10%) 100%);
    box-shadow: none;
  }
}


.button_color_orange {
  background-color: #e2d533;
  background-image: linear-gradient(180deg, #ffb11a 0%, #e2d533 100%);
  text-shadow: 1px 1px 4px rgb(58, 4, 4);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 3px 6px #040404;

  &:hover {
    background-color: darken(#e2d533, 10%);
    background-image: linear-gradient(180deg, darken(#ff8d00,10%) 0%, darken(#e2d533,10%) 100%);
    box-shadow: none;
  }
}

.button_color_dark {
  background-color: #000000;
  background-image: linear-gradient(140deg, #000000 0%, #000000 100%);
  -webkit-box-shadow: 0px -1px 10px 1px #0095ff;
  -moz-box-shadow: 0px -1px 10px 1px #0095ff;
  box-shadow: 0px -1px 10px 1px #0095ff;

  &:hover {
    color: yellow;
    -webkit-box-shadow: 0px -1px 10px 1px yellow;
    -moz-box-shadow: 0px -1px 10px 1px yellow;
    box-shadow: 0px -1px 10px 1px yellow;
  }
}

.button_color_yellow {
  background-color: #e2d533;
  background-image: linear-gradient(180deg,#ff8d00,#e2d533);
  text-shadow: 1px 1px 4px rgb(58, 4, 4);
  border-radius: 1px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 4px 8px rgba(0, 0, 0, 0.13);

  &:hover {
    background-color: darken(#e2d533,10%);
    background-image: linear-gradient(180deg, darken(#ff8d00,10%) 0%, darken(#e2d533,10%) 100%);
    box-shadow: none;
  }
}

.button_color_green {
  background-color: #000000;
  background-image: linear-gradient(140deg, #000000 0%, #000000 100%);
  -webkit-box-shadow: 0px -1px 10px 1px #0095ff;
  -moz-box-shadow: 0px -1px 10px 1px #0095ff;
  box-shadow: 0px -1px 10px 1px #0095ff;

  &:hover {
    color: yellow;
    -webkit-box-shadow: 0px -1px 10px 1px yellow;
    -moz-box-shadow: 0px -1px 10px 1px yellow;
    box-shadow: 0px -1px 10px 1px yellow;
  }
}

.button_color_paleblue {
  background-color: #4c7099;
  background-image: none;
  box-shadow: inset 0 0 2.9px 1.1px rgba(255, 255, 255, 0.28), inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 2px 1px rgba(13, 25, 39, 0.37);

  &:hover {
    background-color: darken(#4c7099,10%);
    box-shadow: none;
  }
}

.button_color_brightblue {
  background-color: #388ad6;
  background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);

  &:hover {
    background-color: darken(#388ad6,10%);
    background-image: linear-gradient(180deg, darken(#4ea8db,10%) 0%, darken(#236dd2,10%) 100%);
    box-shadow: none;
  }
}

.button_color_transp {
  background-color: #264061;
  background-image: none;
  box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.17), 0 1px 1px rgba(0, 0, 0, 0.28);

  &:hover {
    background-color: darken(#264061,10%);
    box-shadow: none;
  }
}

.button_style_flat {
  width: 79px;
  height: 33px;
  background-color: #09203a;
  border-radius: 2px;
  background-image: none;
  box-shadow: inset 0 1px 0 #132e4d, 0 1px 0 #175499;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;

  &:hover {
    box-shadow: inset 0 1px 0 #494767, 0 1px 0 #175499;
  }
}
.button_light{
  background: #135a82;
  border: 1px solid #fff;
  padding: 5px 14px;
  @include Small{
    margin: 20px 0;
  }
  @include xSmall{
    margin: 20px 0;
  }
}