.countpad {
  display: block;
  position: relative;
  width: 48px;
  height: 45px;
  background-color: #3c5b80;
  background-image: linear-gradient(141deg, #4ea8db, #236dd2);
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.35), 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px rgba(95, 184, 239, 0.5);
  border-radius: 3px;
  transition: box-shadow 0.3s ease, border 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 13px 0 rgba(145, 209, 255, 0.52);
    border: solid 1px rgba(95, 184, 239, 0.5);
  }


  &__icon {
    position: absolute;
    background-image: url("../img/bonus.png");
    background-size: 63px 59px;
    width: 62px;
    height: 55px;
    top: 46%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @include Small {
      left: 36%;
    }
    @include xSmall {
      left: 36%;
    }
  }

  &__counter {
    width: 25px;
    height: 24px;
    position: absolute;
    top: -10px;
    right: -8px;
    border-radius: 50%;
    background-color: #e25570;
    box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.97), 0 0 34px rgba(122, 247, 255, 0.58), 0 0 28px #000;
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 2px 0;

    @include Small {
      width: 19px;
      height: 19px;
      top: -7px;
      font-size: 13px;
    }
  }

  &__title {
    display: none;
    position: relative;
    vertical-align: top;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #00a6ff;
    padding: 2px 40px;

    @include xSmall {
      display: inline-block;
      font-size: 14px;
    }
  }

}