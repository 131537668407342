#rwp-popup {
  background:  url(../img/rwp-popup.png)center top no-repeat #010417;
  max-width: 663px;
  height: 560px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  left: calc(50% - 284px);
  transform: translate(0,-49%);
  display: block;
  overflow-y: auto!important;
  min-height: 0;

  @include xSmall{
    height: auto;
    padding-bottom: 21px;
    left: calc(50% - 331px);
    transform: none;
    position: absolute;
    display: block;
    overflow-y: auto!important;
  }

  @media only screen and (max-width: 663px){
    left: 0;
  }

  .popup__close{
    background: transparent;
    box-shadow: none;
  }

  h1{
    font-size: 43px;
    text-transform: uppercase;
    margin-bottom: 0;

    @media only screen and (max-width: 400px){
      font-size: 35px;
    }
  }

  h2{
    font-size: 30px;
    margin-top: 0;

    @media only screen and (max-width: 400px){
      font-size: 25px;
    }

    span{
      color: #ffbf3e;
    }
  }
  .popup-container{

    @media only screen and (max-width:710px){
    }

    @media only screen and (max-width:500px){
    }

    span{
      color: #9e9cbf;
      font-family: 'ClearSans',sans-serif;
      font-size: 15px;
      line-height: 14px;
      text-align: center;
      width: 50%;
      display: block;
      margin: 20px auto 0;

      @media only screen and (max-width: 600px){
        width: 100%;
      }
    }
  }

  .popup__button{
    display: block;
    margin: -5px auto 0;
    background: linear-gradient(150deg,#ffb11a,#ff0036);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-shadow: 0 2px 1.96px rgb(29, 27, 46);
    font-size: 22px;
    font-weight: 100;
    width: 261px;
    height: 77px;
  }
  img{
    max-width: 100%;
    margin-top: 20px;
  }
}