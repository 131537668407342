.pagenotfound {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  &__hero {
    display: block;
    position: relative;
    width: auto;
    max-width: 1175px;
    height: 100%;
    padding: 75px 20px;
    margin: 0 auto;
    @include Large {
      max-width: 983px;
    }
    @include Medium {
      max-width: 940px;
    }
    @include Small {
      max-width: 702px;
    }
    @include xSmall {
      padding: 50px 6px!important;

    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 75px;
      left: 20px;
      width: 406px;
      height: 230px;
      z-index: 1;
      background-color: transparent;
      background-image: url('../img/404-bg-header-left.png');
      background-repeat: no-repeat;

      @include xSmall {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 75px;
      right: 20px;
      width: 406px;
      height: 230px;
      z-index: 1;
      background-color: transparent;
      background-image: url('../img/404-bg-header-right.png');
      background-repeat: no-repeat;

      @include xSmall {
        display: none;
      }
    }

    @media (max-height: 800px) {
      padding: 75px 20px 0;
      &:before, &:after {
        top: 75px;
        @include xSmall {
          left: 0;

        }
      }
    }
  }

  &__shadow {
    display: block;
    position: relative;
    width: 100%;
    box-shadow: -10px 0 15px -5px rgba(0,0,0,0.2), 10px 0 15px -5px rgba(0,0,0,0.2);
  }

  &__header {
    display: block;
    position: relative;
    height: 90px;
    background-color: #091b2f;
    box-shadow: inset 0 -1px 0 #091b2f, 0 1px 0 #1c3d62;
  }

  &__content {
    display: block;
    position: relative;
    background-color: #132e4d;
    border: 1px solid #254b78;
    border-bottom: none;
    border-top: none;
    margin-top: 1px;
  }

  &__footer {
    display: block;
    position: relative;
    background-color: #14355d;
    height: 58px;
    border: 1px solid #254b78;
    border-top: none;
    box-shadow: 0 20px 30px -5px rgba(0,0,0,0.3);
  }

  &__table {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }

  &__row {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    text-align: center;
    padding: 2% 0;
    .pagenotfound__row:first-of-type & {
      padding: 6.6% 0 0 0;
      @include Small {
        padding: 54px 0 0 0;
      }
      @include xSmall {
        padding: 54px 0 0 0;
      }
    }
    .pagenotfound__row:nth-of-type(2) & {
      padding: 2.7% 0 0 0;

    }
    .pagenotfound__row:nth-of-type(3) & {
      padding: 1.3% 0 0 0;

    }
    .pagenotfound__row:nth-of-type(4) & {
      padding: 2% 0 5% 0;
       @include xSmall {
         padding: 2% 0 10% 0;
       }
    }
  }

  &__logo {
    display: block;
    position: absolute;
    width: 275px;
    height: 160px;
    bottom: -37px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    @include xSmall {
      width: 300px;
      height: auto;
    }
  }

  &__title {
    display: block;
    position: relative;
    color: #92b2d8;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 179px;
    margin: 0 auto;
    line-height: 150px;
    @include xSmall {
      font-size: 90px;
      line-height: 100px;
    }
  }

  &__subtitle {
    display: block;
    position: relative;
    color: #92b2d8;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 50px;
    margin: 0 auto;
    letter-spacing: 3px;
    @include xSmall {
      font-size: 22px;
    }
  }

  &__caption {
    display: block;
    position: relative;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.29);
    color: #fff59d;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto;
    @include  xSmall {
      padding: 0 15px;
      font-size: 17px;
    }
  }

  &__note {
    display: block;
    position: relative;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.17);
    color: #80a3cb;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 8px 20px;
  }

  &__gallery {
    display: block;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    .preview {
      margin: 9px;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.65), inset 0 -1px 0 rgba(255, 255, 255, 0.04);
      @include Small {
        width: 180px;
      }
       @include xSmall {
        &:last-child {
          display: none;
        }
        width: 43%;
      }
    }
  }


  &__button {
    display: block;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 11px 25px;
    margin: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
    color: #fefefe;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    @include xSmall {
      white-space: nowrap;
    }
  }

}