.promo-panel {
  display: table;
  position: relative;
  width: 100%;

  @include Medium {
    height: 306px;
    box-shadow: 0 11px 11px rgba(0, 0, 0, 0.11);
    border: 1px solid #506885;
    border-radius: 2px;
  }

  @include Small {
    box-shadow: 0 11px 11px rgba(0, 0, 0, 0.11);
    border: 1px solid #506885;
    border-radius: 2px;
  }

  @include xSmall {
    max-width: 268px;
    margin: 0 auto;
  }

  &__cell {
    display: table-cell;
    position: relative;
    vertical-align: top;
    font-family: "ClearSans", sans-serif;

    @include xSmall {
      display: block;
      width: 100%;
    }

    &:first-child {
      padding-right: 11px;
      padding-left: 11px;
      box-shadow: inset 0 0 0 1px hsla(0,0%,100%,.18),0 0 8px rgba(0,0,0,.52);
      text-align: center;

      @include Medium {
        background-color: #29476a;
        padding-right:0;
        box-shadow: none;
      }

      @include Small {
        background-color: #29476a;
        padding-right:0;
        box-shadow: none;
      }
      @include xSmall {
        background-color: rgba(18, 45, 76, 0.82);
      }
    }
    &_info {
      padding: 30px 22px 0 25px;
      background-color: #29476a;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12);

      @include Medium {
        box-shadow: none;
      }

      @include Small {
        box-shadow: none;
      }

      @include xSmall {
        padding: 30px 22px 20px 25px;
      }
    }
  }
  &__img {
    display: block;
    height: auto;
    max-height: 300px;
    max-width: 181px;
    margin: 17px auto;

    @include Medium {
      max-height: 178px;
      max-width: 155px;
    }

    @include Small {
      max-height: 178px;
      max-width: 155px;
    }

    @include xSmall {
      max-height: 268px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__title {
    margin: 0;
  }

  &__caption {
    margin: 10px 0 0;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 16px;
    line-height: 22px;
  }


  &__button {
    display: block;
    float: left;
    position: relative;
    margin-top: 30px;
    padding: 11px 25px;
    white-space: nowrap;
    border-radius: 23px;
    text-shadow: 0 1px 0 rgba(4, 4, 4, 0.26);
    font-size: 20px;
    font-weight: 700;

    @include Small {
      float: none;
    }

    @include xSmall {
      float: none;
      margin: 30px auto 0;
    }

  }

  &__link {
    display: block;
    float: right;
    text-align: right;
    text-decoration: none;
    margin: 48px 0 0 0;
    color: #00a6ff;
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }

    @include Large {
      margin: 26px 0 0;
    }


    @include Small {
      float: none;
      margin: 19px auto 20px;
      text-align: left;
    }

    @include xSmall {
      float: none;
      margin: 20px auto 20px;
      text-align: center;
    }

    & .icon {
      margin-left: 8px;
      margin-right: 6px;
      margin-top: 2px;
    }
  }

}