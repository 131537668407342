.profile-socials {
  display: block;
  position: relative;
  padding: 27px 42px 26px 42px;

  @include xSmall {
    padding: 27px 18px 26px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(90deg,#132e4d,#52baff 50%,#132e4d);
    box-shadow: 0 1px 1px rgba(255,255,255,0.1);
  }

  &__title {
    display: block;
    margin-bottom: 15px;
  }

  &__note {
    color: #cccccc;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
    margin: 0;
  }

  &__action {
    margin-top: 27px;
  }

}