.lottery {
  display: block;
  position: relative;

  &__title {
    padding: 22px;
    text-align: center;
  }

  &__caption {
    @include xSmall {
      display: none;
    }
    &_xs {
      display: none;

      @include xSmall {
        display: block;
        font-size: 14px;
      }
    }
  }

  &__tabs {
    height: 60px;
    border-radius: 12px;
    font-size: 0;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 5px 8px rgba(0, 0, 0, 0.17);
    width: 575px;
    margin: 0 auto;
    max-width: 100%;
    @include xSmall {
      height: 35px;
    }
  }

  &__tabitem {
    width: 50%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: center;
    padding: 19px;
    cursor: pointer;
    border: 1px solid #1776bb;
    background-color: #00284b;

    @include xSmall {
      padding: 12px 19px 32px 19px;
    }

    &:before {
      content: none;
    }

    &:hover {
      .title {
        color: $yellow;
      }
    }

    &:first-child{
      border-radius: 10px 0 0 10px;
    }

    &:last-child{
      border-radius: 0 10px 10px 0;
    }

    &_active {
      background-color: #135a82;
      border-radius: 2px;
      .title{
        color: $yellow;
      }
    }
  }

  &__content {
    margin-top: 32px;
  }

  &__panel {
    display: block;
    position: relative;
    margin-bottom: 35px;
  }

}