.tournament {
  display: block;
  position: relative;
  text-align: center;


  &_status{
    margin-bottom: 8px;
  }
 
  &_name{
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'ClearSans',sans-serif;
    margin-bottom: 36px;
    color: white;
    
    &:focus{
      color: white;
    }
    a{
      color: #fff;
    }
    h2{
      font-size: 20px;
      @include Small{
        max-width: 260px;
      }
      @include xSmall{
        position: absolute;
        top: 10px;
        left: 145px;
        max-width: calc(100% - 200px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 5px;
      }
    }
  }
  &_vip-levels{
    @include xSmall{
      position: absolute;
      top: 34px;
      left: 145px;
    }
  }

  &__promo {
    display: block;
    position: relative;
    padding: 0 20px;

    .tournament__prize{
      color: white;
      font-size: 20px;
      margin-bottom: 5px;

      &__name{
        color: white;
        font-size: 18px;
      }
    }
  }

  &__head {
    position: relative;
    overflow: hidden;
    // background-color: #2d517a;
    // height: 41px;
    border-radius: 2px;
    // box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);

    .tournament__name{
      color: #ffd801;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
    }
  }

  &__backnote {
    position: absolute;
    display: block;
    bottom: -7px;
    left: -9px;
    font-size: 28px;
    font-weight: 700;
    color: #355a83;
    text-transform: uppercase;
    z-index: 1;
  }

  &__title {
    text-shadow: 0 2px 0 rgba(13, 25, 39, 0.23);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 7px 0;
    z-index: 1;
    position: relative;

    @include Medium {
      padding: 16px 0;
      white-space: nowrap;
      font-size: 17px;
    }
    @include Small {
      padding: 16px 0;
      white-space: nowrap;
      font-size: 17px;
    }
  }

  &__img-overlay {
    border-radius: 2px;
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
  }

  &__img {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__button {
    // position: absolute;
    // white-space: nowrap;
    margin-bottom: 10px;
    // left: 50%;
    // -ms-transform: translateX(-50%);
    // transform: translateX(-50%);
    z-index: 1;
  }

  &__timer {
    padding-top: 20px;
    // background-image: url(../img/timer-bg.jpg);
    height: 155px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__table {
    margin-top: -17px;
    position: relative;
    padding: 0 20px;
  }


  &__main {
    margin-top: 27px;
  }

  &__panel {
    margin-top: 24px;
  }

  &__yourposition{
    h3{
      color: #fff;
      margin: 0px auto 5px auto;
      @include Large {
        margin: 5px 0px;
      }
    }
    .table-wrap{
      padding: 20px;
      padding-top: 0;
    }
    table{
      background: #132d4b;
      color: #88add7;
      max-width: 100%;
      width: 100%;
      border: 0;
      td{
        padding: 0 5px;
        font-size: 14px;
        &:nth-child(n+2){
          overflow: hidden;
          max-width: 69px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .tab__content .active &_vip-level{
    width: 30px;
    height: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    background: #042b52;
    color: #1d487a;
    font-weight: 900;
    font-size: 25px;
    border-radius: 3px;
    margin: 0;
    @include xSmall{
      width: 20px;
      height: auto;
      font-size: 17px;
    }

    &.active{
      background: #336daf;
      color: #fff;
      filter: drop-shadow(0 4px 10px #001327);
      display: inline-block;
    }
    &s{
      @include xSmall{
        max-width: calc(100% - 180px);
      }
      & > p{
         color: #6a8bc5;
         margin-bottom: 7px;
         font-size: 15px;
         margin-top: 15px;
        @include xSmall{
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
       }
    }
  }
  &_summa{
    margin-top: 25px;
    @include Small{
      text-align: center;
    }
    @include xSmall{
      text-align: center;
    }
    .countdown__divider:before{
      content: '';
    }
    .countdown span{
      font-size: 40px;
      color: #fcc807;
      font-family: "ClearSans", sans-serif;
      font-weight: 700;
      &:last-child{
        color: #366297;
        font-size: 29px;
        margin-left: 10px;
      }
    }
  }
  &_description{
    color: #8aadee;
    font-size: .95em;
    text-align: justify;
  }
  &_status{

    .active,
    .future,
    .finished{
      &-tournament__status{
        font-size: 16px;
        padding-right: 53px;
        padding-top: 17px;
        padding-bottom: 15px;
        text-align: right;
        @include Small{
          position: absolute;
          top: 0;
          right: 0;
          padding-right: 73px;
        }
        @include xSmall{
          position: absolute;
          top: -11px;
          right: 0;
          font-size: 0;
        }

        span{
          color: #8aadee;
          font-size: 0.95em;
        }
        img{
          position: absolute;
          top: 15px;
          right: 10px;
          width: 51px;
          @include Medium{
            right: 10px;
            width: 51px;
          }
          @include xSmall{
            right: 10px;
            top: 20px;
            width: 40px;
          }
        }
      }
    }

    .active-tournament__status{
      color: #00ffbb;
    }
    .future-tournament__status{
      color: #ff9500;
    }
    .finished-tournament__status{
      color: #ff364b;
    }
  }
}
.tournament_gameplay {
  margin-top: 10px;

  .tournament__timer {
    padding-top: 0px;
    height: 83px;
    @include Large {
      height: 83px;
    }

    .timer__row_caption{
      .timer__cell{
        font-size: 18px;
      }
    }

    .timer__cell{
      font-size: 30px;
    }

    .timer__note {
      padding-bottom: 0;
    }
  }

  .tournament__table {
    margin-top: 7px;
    max-height: 122px;
    overflow: hidden;

    @include Large {
      max-height: 115px;
    }

    .table__row{
      height: 31px;
    }
  }

  .tournament__img-overlay {
    height: 190px;
    width: 190px;
    margin: 0px auto;
    
    @include Medium{
      height: 180px;
      width: 180px;
    }
  }

  .table__body .table__cell:last-child{
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.tournament__attention-prize{
  margin: -20px auto;
  & > *{
    display: inline-block;
    vertical-align: middle;
    @include Small{
      margin: -10px 0;
    }
    @include xSmall{
      margin: -20px 0;
    }
  }
  span{
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
    small{
      color: #8aadee;
      font-size: 72%;
    }
  }
}

.tournament__table {

  .table__headrow .table__cell {
    padding: 0 10px;
    text-align: right;
    &:nth-child(2){
      padding: 0;
      text-align: left;
    }
    .tournament_undefined &{
      text-align: center;
    }
  }
  .table__body .table__cell:last-child{
    @include Small{
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}