.leaderboard {
  display: block;
  position: relative;
  margin: 0 auto;
  width: auto;
  max-width: 1140px;
  padding-bottom: 20px;
  font-size: 0;
  min-height: 220px;
  background-color: #20355c;
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.52);

  @include xSmall {
    padding-bottom: 60px;
    position: relative;
  }

  &__block {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 26px 18px 0 18px;

    &:focus {
      outline: none;
    }
    .tournament-details__leaderboard &{
      padding: 0;
    }
  }

  &__importance {
    display: table;
    margin: 0 auto 20px;
    height: 29px;
    background-color: #30508c;
    width: 800px;
    border-left: 1px solid #3376b5;
    border-right: 1px solid #3376b5;
    position: relative;

    @include Small{
      max-width: 750px;
    }
    @include xSmall{
      max-width: 600px;
    }

    &:before{
      content: ' ';
      display: block;
      position: absolute;
      background-color: #2a467b;
      opacity: 0.6;
      height: 30px;
      bottom: -7px;
      width: calc(100% - 14px);
      left: 1%;
    }

    &:after{
      content: ' ';
      display: block;
      position: absolute;
      background-color: #2d4a82;
      opacity: 0.4;
      height: 30px;
      bottom: -13px;
      width: calc(100% - 26px);
      left: 2%;
    }

    @include xSmall {
      margin: 0 18px;
      width: calc(100% - 36px);
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    width: 1px;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    padding: 10px 15px;
    border-top: 1px solid #3376b5;
    border-bottom: 1px solid #3376b5;
    position: relative;
    z-index: 1;
    background: #30508c;

    &:nth-last-child(1){
      color: #fdc236;
      width: 13%;
      padding: 10px 15px 10px 0px;
      text-align: right;
    }
    &:nth-last-child(2){
      color: #17b271;
    }
    
    &_fluid {
      width: auto;
    }
  }
  .tournament-details__leaderboard &{
    max-width: 810px;
    padding-bottom: 30px;
    width: 100%;

    @include Small{
      max-width: 750px;
    }
    @include xSmall{
      max-width: 600px;
    }
  }
}