.bonus {
  width: auto;
  display: inline-block;
  position: relative;
  font-size: 0;

  &__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 295px;
    width: 229px;
    background-repeat: no-repeat;
    background-position: center top;
    margin-right: 64px;

    @include xSmall {
      margin-right: 0;
      display: block;
      margin-bottom: 20px;
    }

    img {
      transition: transform 0.3s ease;
      height: 200px;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .bonus_single & {
      width: 480px;
      background-size: 188px;

      &:hover {
        img {
          transform: none;
        }
      }
    }

    &_1 {
      background-image: url('../img/bonus-1.png');
    }

    &_2 {
      background-image: url('../img/bonus-2.png');
    }

    &_3 {
      background-image: url('../img/bonus-3.png');
    }

  }

  &__info {
    //position: absolute;
    //bottom: 0;
    //left: 0;
    //right: 0;
    text-align: center;
    padding-top: 12px;
  }

  &__name {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;

    .bonus_single & {
      padding-bottom: 22px;
    }
  }

  &__note {
    font-weight: 400;
    font-size: 16px;
    color: #7e7c9d;
    display: inline-block;
    padding: 0 20px;
    margin-top: 5px;
  }

  &__caption {
    color: #a7a5d0;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    display: block;

    .bonus_single & {
      padding-bottom: 20px;
    }

  }
  &__subtitle {
    color: #a7a5d0;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
  }

}