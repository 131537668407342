.registration-v4 {
  display: block;
  position: relative;

  &__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase;


    &_large {
      font-size: 15px;
      font-weight: 100;
      text-align: center;
      text-transform: none;
      margin-bottom: 11px;
      color: #fff;

      @include xSmall {
        text-align: center;
      }
    }
  }

  &__inner {
    display: flex;
    height: 100%;
    justify-content: space-between;

    @include xSmall {
      flex-direction: column;
      margin-top: 16px;
    }

  }

  &__inner-left {
    flex: 1 1 50%;
    text-align: right;

    @include Small {
      //padding-right: 33px;
    }

    @include xSmall {
      border-right: none;
      padding-right: 0;
    }
  }

  &__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px;

    @include Small {
      padding-left: 23px;
    }

    @include xSmall {
      border-right: none;
      padding-left: 0;
    }
  }

  &__figure {
    width: 342px;
    height: 354px;
    border-radius: 10px;
    background-color: #435182;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.63);
    margin: 0 auto;
    position: relative;

    @include Small {
      width: 275px;
      height: 349px;
    }
    @include xSmall {
      width: 280px;
      height: 165px;
      display:block;
      margin: 0 auto 25px auto;
      font-size: 0;
    }

  }

  &__figure-img {
    display: block;
    object-fit: contain;
    max-width: 100%;
    margin: 0 auto;
  }

  &__figure-info {
    position: absolute;
    bottom: 121px;
    text-align: center;
    width: 100%;

    @include Small {

    }

    @include xSmall {
      display: inline-block;
      width: 156px;
      vertical-align: top;
      bottom: 96px;
      right: 11px;
      text-align: left;
    }
  }

  &__figure-caption {
    font-size: 22px;
    text-align: center;
    color: #ffb824;
    position: relative;
    margin-bottom: 15px;
    @include xSmall {
      //padding: 12px 20px 17px;
      font-size: 18px;
    }
  }

  &__figure-note {
    font-family: 'ClearSans';
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    //padding:0;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5);
    @include Small {
      padding: 0;
    }
    @include xSmall {
      font-size: 23px;
    }
  }

  &__counter {
    display:block;
    width: 342px;
    height: 112px;
    border-radius:0 0 10px 10px;
    background-color: #364371;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.63);
    position: absolute;
    bottom: 0;
    @include Small {
      width: 275px;
      height: 112px;
    }
    @include xSmall {
      width: 280px;
      height: 82px;
    }
  }

  &__counter-title {
    display: block;
    margin: 5px auto;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffb824;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82);

    @include Small {
      margin: 10px auto 0 auto;
    }

    @include xSmall {
      display:block;
      margin: 8px auto 0 auto;
      font-size: 14px;
    }
  }

  &__counter-inner {
    width: 131px;
    height: 58px;
    border-radius: 6px;
    background-color: #2f5c8e;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    margin: 10px auto;
    position: relative;
    @include xSmall {
      margin: 7px auto;
      width: 101px;
      height: 41px;
    }
  }

  &__counter-inner_inner {
    width: 112px;
    height: 42px;
    opacity: 0.86;
    border-radius: 2px;
    box-shadow: inset 0 8px 7px 0 rgba(0, 0, 0, 0.86), inset 0 -8px 7px 0 rgba(0, 0, 0, 0.89);
    position: absolute;
    top: 8px;
    left: 9px;
    background-color: #ffffff;
    @include xSmall {
      top: 6px;
      left: 8px;
      width: 86px;
      height: 29px;
    }
  }

  &__form {
    width: 330px;

    @include Small {
      width: 300px;
    }

    @include xSmall {
      width: 260px;
      margin: 0 auto;
    }
  }

  &__input {
    display: block;
    margin-bottom: 16px;
  }

  &__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px;
  }

  &__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(253,99,36,.7);
    }

    @include xSmall {
      width: 260px;
      margin: 18px auto 0;
    }
  }

  &__socials {
    margin: 24px auto 0;
  }

  &__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px;
  }
  &__timer-title{
    font-size: 22px;
    text-align: center;
    color: #ffb824;
    margin-top: -20px;
  }
}

.js-timeout{
  font-family: 'ClearSans';
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.74);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.61);
  letter-spacing: 9px;
  line-height: 38px;


  @include xSmall {
    font-size: 21px;
    letter-spacing: 6px;
    line-height: 28px;
  }
}