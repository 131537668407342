.restriction {
  display: block;
  height: 100vh;
  background-image: url('../img/restriction-bg.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  text-align: center;

  &__logo {
    padding-top: 242px;

    img {
      padding-left: 60px;
    }

    @include xSmall {
      padding-top: 20px;

      img {
        padding-left: 40px;
        width: 300px;
      }

    }
  }

  &__title {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.26), 0 3px 2px rgba(0, 0, 0, 0.31);
    color: #ff001e;
    font-family: 'ClearSans', sans-serif;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 44px;
    margin: 0;

    @include xSmall {
      font-size: 32px;
      padding-top: 8px;
    }
  }

  &__info {
    max-width: 1040px;
    margin: 0 auto;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.44);
    font-family: 'ClearSans', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    color: #fff;
    padding: 44px 40px 20px;

    @include xSmall {
      padding: 44px 20px 20px;
      line-height: 32px;
      font-size: 22px;
    }

    &_accent {
      color: #ffea00;
    }
  }

}