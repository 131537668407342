.jackpot-details {
  display: block;
  position: relative;

  &__header {
    background-color: #15355a;
    padding: 0 44px 60px;
    background-image: url('../img/vip-details-bg.jpg');
    background-repeat: no-repeat;
    background-position: center top;
  }

  &__countdown {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 86px;
  }

  &__title {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    font-family: 'ClearSans', sans-serif;
    margin: 75px auto 60px;
  }

  &__subtitle {
    text-shadow: 0 2px 0 #000;
    font-size: 22px;
    text-align: center;
    margin-top: 56px;
  }

  &__caption {
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    margin: 0 0 36px 0;

    &:last-child {
      margin: 0;
    }

    &_accent {
      color: $textaccent;
    }
  }

  &__gallery {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 42px;
  }

}