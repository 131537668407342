.payitem {
  width: 190px;
  background-color: #fff;
  background-image: linear-gradient(180deg, #f8f8f8 0%, #d3d3d3 100%);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 8px rgba(0, 0, 0, 0.17), 0 8px 10px rgba(0, 0, 0, 0.16);

  @include xSmall {
    width: calc(100%/3 - 8px);
    height: auto;
    margin: 8px 8px 8px 0;
  }

  &__img {
    height: 92px;
    text-align: center;
    position: relative;
    display: table;
    width: 100%;

    @include xSmall {
      height: 83px;
    }
    &_inner {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center;
    }
    img{
      @include xSmall {
        max-width: 100%;
      }
    }
    svg {
      //display: block;
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%,-50%);

      @include xSmall {
        width: calc(100% - 20px);
      }
    }

    .svg-walletone {
      @include xSmall {
        width:calc(100% - 36px);
      }

    }    
  }
  &__footer {
    background-color: #d5d5d5;
    box-shadow: inset 0 1px 0 #cbcbcb, 0 -1px 0 #ebebeb;
    padding: 11px 13px 10px 13px;
    @include Small {
      min-height: 75px;
      padding: 11px 2px 10px 2px;
    }
    @include xSmall {
      padding: 11px 2px 10px 2px;
      min-height: 75px;
    }
  }

  &__note {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.68);
    color: #222131;
    font-family: 'ClearSans', sans-serif;
    font-weight: 700;
    line-height: 17px;
    font-size: 15px;
    margin: 0;
    text-align: center;

    a {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
      color: black;
    }

    @include Medium {
      font-size: 12px;
    }

    @include Small {
      font-size: 12px;
    }

    @include xSmall {
      font-size: 12px;
    }

    &_small {
      font-size: 12px;
      font-weight: 500;
    }
  }

}