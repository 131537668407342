.partners {
  padding: 33px 35px 33px;
  &__title {
    color: #fefefe;
    margin: 0;
    font-size: 1.6em;
    &_href {
      font-weight: 400;
      color: #7c9bbe;
      font-size: 16px;
      line-height: 15px;
      display: inline-block;
      margin-left: 15px;
      vertical-align: bottom;
    }
  }
  .partners__link_container {
    .partners__title {
      display: inline-block;
      vertical-align: bottom;
    }
  }
  &__caption {
    font-weight: 400;
    color: #fff001;
    font-size: 18px;
    margin: 18px 0 16px 0;
    &:first-letter {
      text-transform: uppercase;
    }


  }

  &__note {
    font-weight: 400;
    color: #7c9bbe;
    font-size: 15px;
    line-height: 17px;
    margin: 16px 0 0;
  }

}