.s_block {
  display: block;
  position: relative;
  text-align: center;
}
.socials {
  display: block;
  position: relative;
  text-align: center;

  &__item {
    width: 31px;
    height: 31px;
    background-color: #122d4b;
    border-radius: 2px;
    box-shadow: inset 0 1px 6px 0 #1c3652;
    border: solid 1px #435c77;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin: 6px 9px 0px 0px;
    text-align: center;
    transition: border 0.2s ease;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      border: solid 1px #93d1ff !important;
      }

    @include Small {
      margin: -2px 9px 0px 0px;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.socials_filled {

  .socials__item {
    background-color: #4685a2;
    border: none;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  }

}

.socials_form {

  .socials__item {
    background-color: $tab-base;
    border: 1px solid #4685a2;
    border-radius: 2px;
    box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.05);

    &_active {
      background-color: #4ea8db;
      background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    }
  }

}

.socials_small {

  .socials__item {
    width: 36px;
    height: 36px;
    margin-right: 7px;
  }

}