.tab__content{
  &>div{
    display: none;
    height: 0;
  }
  .active{
    height: auto;
    display: block;
  }
}


.registration__image{
  img{
    max-width: 100%;
    max-height: 135px;
  }
}

.popup_section__aside .aside_promo{
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.popup_section__aside .aside_promo .promo-bonus,.popup_section__aside .aside_promo .aside__promo-table{
  background:#09203a;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.13) inset, 0 3px 8px rgba(0, 0, 0, 0.19);
}
.popup_section__aside .aside_promo .table_promo .table__row:nth-child(2n+1){
  background: #1c4069;
}

.main_gallery .main__item {
  vertical-align: top;
  margin: 9px;
  -webkit-box-shadow: 0px 0px 15px 1px #0095ff;
  -moz-box-shadow: 0px 0px 15px 1px #0095ff;
  box-shadow: 0px 0px 15px 1px #0095ff;

  &:before {
    content: "";
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg,#fefeff00 0%,#52baff 50%,#fefeff00 100%);
    display: block;
    position: absolute;
    top:-3px;
  }

  &:after {
    content: "";
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg,#fefeff00 0%,#52baff 50%,#fefeff00 100%);
    display: block;
    position: absolute;
  }

  .preview__info {

    @include xLarge {
      padding: 20px 22px;
    }
  }

  &.preview {
    width: 182px;

    @include xLarge {
      width: 210px;
    }


    @include Large {
      width: 240px;
    }

    @include Small {
      width: 205px;
    }

    @include xSmall {
      width: 43%;
      max-width: 300px;
      margin: 3.33%;
    }

  }

  &:first-child {
    @include xLarge {
      width: 286px;
    }
  }

  &:nth-child(2){
    @include xLarge {
      width: 286px;
    }
  }

  &:nth-child(3){
    @include xLarge {
      width: 286px;
    }
  }
}

.popup_chooseBonus .bonus__item {
  cursor: pointer;
}
#login-modal .modal__input.input .modal__caption {
  cursor: pointer;
}

.lottery-btm-box .lottery-btm_item{
    
    @include xSmall {
      font-size: 10px;
      line-height: 15px;
    }
}

.lottery-btm-box .casher__button{
    
    @include xSmall {
      width: 180px;
      height: 28px;
      font-size: 12px;
    }

}

.popup_bonusNotification .popup__content{
  padding-bottom: 20px;
  .popup__title {
    padding: 25px 0;
  }
}
.popup.successOutpay .popup__content{
  padding-bottom:  20px;
  min-height: 90px;
  padding-top: 25px;
}
.popup_afterRegistration .popup__content{
  padding-bottom:  20px;
  min-height: 150px;
}
.popup_afterActivation .popup__content{
  padding-bottom:  20px;
  min-height: 150px;
}
.popup_phoneVerified  .popup__content {
  padding-bottom: 20px;
  .popup__title {
    padding: 25px 0;
  }
}
  .popup_newViplevel .popup__content {
  padding-top: 20px;
  text-align: center;
  .popup__title {
    font-size: 31px;
    line-height: 40px;
  }
  .popup__subtitle{
    display: block;
    text-align: center;
    color: #fff;
    font-size: 19px;
    padding: 0;
    margin-top: 9px;
  }
  .bonus__item {
    min-width: 227px;
    min-height: 246px;
    @include xSmall{
      width: 100%;
    }
  }
}
  .popup_registration .modal__error {
    max-width: 400px;
  }

.popup_confirmEmail{
  .popup__content{
    padding: 27px 53px 55px 53px;
    @include xSmall {
      padding: 40px 24px;
    }
  }
  .popup__title{
    padding-bottom: 20px;
  }
  .popup__caption {
       font-size: 15px;
       line-height: 18px;
     }
}

.text-center{
  text-align: center; 
}
.txt-yellow{
  color: #fdc236;
}
.mb_-22-mob{
  @include xSmall{
    margin-bottom: -22px
  }
}