// color variables

$bgcolor: #09203a;
$bghead: #091b2f;
$bghead-top-panel: #091420;
$yellow: #ffea00;
$green: #00ff76;
$textcolor: #fefefe;
$textaccent: #fff000;
$textinfo: #00c6ff;
$textimportant: #fa2e38;

$tab-light: #1d3550;
$tab-lightest: #283b50;
$tab-base: #091420;

$dark-slate-blue-two: #15355a;