.search {
  display: block;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    background-image: linear-gradient(90deg,#04469c 0%,#52baff 50%,#04469c 100%);
    display: block;
    position: absolute;
  }

  &__button {
    display: block;
    height: 41px;
    width: 41px;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 4px;
      width: 41px;
      height: 41px;
      background-image: url("../img/find_icon.png");
      background-size: 41px 41px; 
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    background-color: $bgcolor;
    border-radius: 2px;
    box-shadow: inset 0 1px 0 #152231, 0 1px 0 #354c68;
    color: #a0c6f3;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: none;
    padding: 11px 37px 11px 13px;

    &::-webkit-input-placeholder { 
      opacity: 0.5; 
      -webkit-transition: opacity .5s; 
      transition: opacity .5s; 
    }
    &::placeholder{
      opacity: 0.5; 
      transition: opacity .5s;
    }
    &:-ms-input-placeholder { 
      opacity: 0.5; 
      -ms-transition: opacity .5s; 
      transition: opacity .5s; 
    }
    &:focus::placeholder { 
      opacity: 0; 
    }
    &:focus::-webkit-input-placeholder { 
      opacity: 0;
     }
     &:focus:-ms-input-placeholder { 
       opacity: 0; 
      }
  }
}