.registration-v6 {
  display: block;
  position: relative;

  &__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase;


    &_large {
      font-size: 22px;
    }
  }

  &__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between;

    @include xSmall {
      flex-direction: column;
      margin-top: 16px;
    }

  }

  &__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    text-align: right;

    @include Small {
      //padding-right: 33px;
    }

    @include xSmall {
      border-right: none;
      padding-right: 0;
    }
  }

  &__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px;

    @include Small {
      padding-left: 23px;
    }

    @include xSmall {
      border-right: none;
      padding-left: 0;
    }
  }

  &__figure {
    width: 342px;
    margin: 0 auto;
    position: relative;

    @include Small {
      width: 275px;
      height: 349px;
    }
    @include xSmall {
      width: 280px;
      display:block;
      margin: 0 auto 25px auto;
    }

  }

  &__figure-img-two,&__figure-img-one {
    float: left;
    margin-right: 25px;
    margin-left: 11px;
  }

  &__figure-img-one {
    width: 76px;
    margin-top: 18px;
    @include Small {
      width: 68px;
      margin-top: 14px;
      margin-left: 13px;
    }
    @include xSmall {
      width: 50px;
      margin-top: 8px;
      margin-left: 7px;
    }

  }

  &__figure-img-two {
    width: 78px;
    margin-right: 11px;
    margin-top: 10px;
    @include Small {
      margin-top: 9px;
      margin-left: 12px;
    }
    @include xSmall {
      width: 65px;
      margin-top: 10px;
      margin-left: 15px;
    }

  }

  &__title-img-one {
    padding-top: 29px;
    text-align: left;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.48);
    text-transform: uppercase;
    @include Small {
      font-size: 14px;
      padding-top: 20px;
    }
    @include xSmall {
      padding-top: 8px;
    }
  }

  &__title-img-two {
    padding-top: 27px;
    text-align: left;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.48);
    @include Small {
      font-size: 14px;
      padding-top: 20px;
    }
    @include xSmall {
      padding-top: 13px;
      font-size: 14px;
    }
  }



  &__title-img-num {
    font-weight: bold;
    font-size: 22px;
    color: #fdab1d;
  }

  &__figure-info-title {
    margin-bottom: 15px;
    text-align: center;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82);
    text-transform: uppercase;
    @include Small {
      font-size: 14px;
    }
  }

  &__figure-info {
    width: 342px;
    height: 108px;
    border-radius: 4px;
    background-color: #2c2b3e;

    @include Small {
      width: 275px;
      height: 96px;
    }

    @include xSmall {
      width: 280px;
      height: 70px;
      border-bottom:2px solid  #435182;
    }
  }

  &__figure-caption {
    //display: block;
    //padding: 6px 10px 17px;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #ffb824;
    @include xSmall {
      //padding: 12px 20px 17px;
      text-align: left;
      font-size: 18px;
    }
  }

  &__figure-note {
    font-family: 'ClearSans';
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    //padding:0;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5);
    @include Small {
      padding: 0;
    }
    @include xSmall {
      font-size: 23px;
    }
  }

  &__counter {
    margin-top: 1px;
    width: 342px;
    height: 174px;
    border-radius: 4px;
    background-color: #435182;
    @include Small {
      width: 275px;
      height: 140px;
    }
    @include xSmall {
      width: 280px;
      height: 123px;
    }
  }

  &__counter-title {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffb824;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82);

    @include Small {
      margin: 2px auto 0 auto;
    }

    @include xSmall {
      display:block;
      margin: 2px auto 0 auto;
      font-size: 14px;
    }
  }

  &__counter-inner {
    width: 131px;
    height: 58px;
    border-radius: 6px;
    background-color: #7f8ab0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    margin: 5px auto;
    position: relative;
    @include Small {
      width: 97.1px;
      height: 43px;
      margin:0 auto;
    }
    @include xSmall {
      margin: 0 auto;
      width: 101px;
      height: 41px;

    }
  }

  &__counter-inner_inner {
    width: 112px;
    height: 42px;
    opacity: 0.86;
    border-radius: 2px;
    box-shadow: inset 0 8px 7px 0 rgba(0, 0, 0, 0.86), inset 0 -8px 7px 0 rgba(0, 0, 0, 0.89);
    position: absolute;
    top: 8px;
    left: 9px;
    @include Small {
      width: 83px;
      height: 31.1px;
      top: 6px;
      left: 7px;
    }
    @include xSmall {
      top: 6px;
      left: 8px;
      width: 86px;
      height: 29px;
    }
  }

  &__form {
    width: 330px;

    @include Small {
      width: 300px;
    }

    @include xSmall {
      width: 260px;
      margin: 0 auto;
    }
  }

  &__input {
    display: block;
    margin-bottom: 16px;
  }

  &__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px;
  }

  &__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117;

    &:hover {
      box-shadow: 0 0 10px 0 rgba(253,99,36,.7);
    }

    @include xSmall {
      width: 260px;
      margin: 18px auto 0;
    }
  }

  &__socials {
    margin: 24px auto 0;
  }

  &__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px;
  }


}