.radiobutton {
  display: inline-block;
  vertical-align: top;
  position: relative;

  &__inner {
    display: none;
  }

  &__label {
    color: #fff;
    font-weight: 400;
    padding-left: 28px;
    cursor: pointer;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.29);
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 18px;

    &:before, .radiobutton__inner:checked:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 4px;
      width: 12px;
      height: 12px;
      background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 1.4px 2.7px 1px rgba(0, 0, 0, 0.18);}

    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      top: 6px;
      left: 2px;
      background-color: #fff;
      opacity: 0;
    }

    .radiobutton__inner:checked + &:after {
      content: '';
      opacity: 1;
    }
  }

}