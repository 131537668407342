svg {
  pointer-events: none; // mariam edge js clickablility
}
.svg-gift {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.svg-star {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  fill: $yellow;

  .esvg-star-shadow {
    fill: #000;
  }

  &_disabled {
    fill: #08203a;
    stroke: #15355A;
    stroke-width: 1px;
    opacity: 0.3;
  }
}

.svg-cancel {
  fill: #fff;
}

.svg__close {
  width: 17px;
  height: 17px;
  fill: rgba(255, 255, 255, 0.3);
  transition: fill 0.3s ease;

  &:hover {
    fill: #fff;
  }
}

.svg-yandex {
  width: 142px;
  height: 36px;
}

.svg-visa-mastercard {
  width: 142px;
  height: 46px;
}

.svg-webmoney {
  width: 142px;
  height: 36px;
}

.svg-qiwi {
  width: 112px;
  height: 48px;
}

.svg-walletone {
  width: 82px;
  height: 70px;
}

.svg-okpay {
  width: 142px;
  height: 34px;
}

.svg-payeer {
  width: 142px;
  height: 30px;
}

.svg-perfect-money {
  width: 142px;
  height: 30px;
}

.svg-comepay {
  width: 77px;
  height: 70px;
}

.svg-bitcoin {
  width: 142px;
  height: 32px;
}

.svg-contact {
  width: 110px;
  height: 70px;
}

.svg-moneyru {
  width: 142px;
  height: 47px;
}

.svg-terminal {
  width: 142px;
  height: 48px;
}


.svg-card_rub {
  width: 142px;
  height: 48px;
}

.svg-money {
  width: 27px;
  height: 18px;
}


.svg_vkontakte {
  width: 23px;
  height: 13px;
}

.svg_odnoklassniki {
  width: 14px;
  height: 23px;
}

.svg_twitter {
  width: 20px;
  height: 17px;
}

.svg_telegram{
  width: 22px;
  height: 25px;
  fill: #fff;
}

.svg_facebook {
  width: 12px;
  height: 25px;
}

.svg_mailru {
  width: 24px;
  height: 24px;
  fill: #fff;
}