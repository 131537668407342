.rules .page_content, .rules.page_content {

  color: #ccc;
  font-size: 14px;

  h1 {
    color: #ffea00;
    margin: 0;
  }

  h2, h3 {
    font-weight: 400;
    color: #fff59d;
    font-size: 15px;
    margin: 18px 0 0 0;

  }
  h4, h5 {
    font-weight: 400;
    color: #ccc;
    font-size: 13px;
    margin: 18px 0 0 0;
  }
  p {
    font-weight: 400;
    color: #ccc;
    font-size: 14px;
    line-height: 17px;
    margin: 16px 0 0;
  }
  ul, ol {
    font-weight: 400;
    color: #ccc;
    font-size: 14px;
    line-height: 17px;
   }
}