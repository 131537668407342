.section {
  width: 940px;
  margin: 0 auto;
  position: relative;
  display: table;

  @include xLarge {
    width: 1420px;
  }

  @include Large {
    width: 1180px;
  }

  @include Small {
    width: 700px;
  }

  @include xSmall {
    width: 100%;
    max-width: 480px;
    display: block;
    position: relative;
  }

  &__main {
    display: table-cell;
    vertical-align: top;
    position: relative;
    background-color: #132e4d;
    width: 100%;
    .section_full & {
      border-radius: 5px;
      box-shadow: inset 0 1px 0 0 #334356, inset -1px 0 0 0 #2d517d, inset 1px 0 0 0 #2d517d;
      &.tournament-section{
        background: none;
        box-shadow: none;
        // border: 1px solid #2d517d;
        &:before{
          content: none;
        }
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 200px;
        border-radius: 5px 5px 0 0;
        box-shadow: inset 0 1px 0 0 #334356, inset -1px 0 0 0 #2d517d, inset 1px 0 0 0 #2d517d;
        // background-image: linear-gradient(173deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(188deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 50%);
      }
      &-lottery{
        background: #09203a;
        box-shadow: none;
        &:after, &:before{
          content: none;
        }
        .main_lottery-details{
          padding: 0;
        }
        .panel{
          padding: 20px;
          &__cell{
            vertical-align: top;
          }
        }
        .img_overflow{
          margin: 0;
        }
      }
    }


    @include xSmall {
      width: 100%;
      display: block;
      position: relative;
      background-image: none;
    }
  }

  &__aside {
    min-width: 300px;
    display: table-cell;
    vertical-align: top;
    background-color: #122d4c;
    border-left: 1px solid #09203a;

    @include xLarge {
      min-width: 460px;
    }

    @include Large {
      min-width: 380px;
    }

    @include Small {
      min-width: 220px;
    }

    @include xSmall {
      min-width: 100%;
      display: block;
      position: relative;
      border-left: none;

      .section_promo & {
        background-color: #122d4c;
      }
    }

  }

  &_winsline {
    margin-top: 23px;
    overflow: hidden;
    display: none;

    @include xSmall {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &_promo {
    margin-top: 36px;
  }


  // Main page

  &_main {
    margin-top: 20px;
    height: 100%;
    border: 2px solid #132e4d;
    border-radius: 5px;

    @include xSmall {
      margin-top: 0px;
      border-radius: 0px;
    }

    &:after {
      content: '';
      width: 1px;
      display: block;
      position: absolute;
      top: 0;
      right: 300px;
      bottom: 0;
      background-color: #10253d;
      box-shadow: 1px 0 0 #214268;

      @include xLarge {
        right: 459px;
      }

      @include Large {
        right: 380px;
      }

      @include Small {
        right: 220px;
      }

      @include xSmall {
        right: 0;
        display: none;
      }

    }

    @include xSmall {
      height: auto;
    }
  }

  // Full width page (without aside)

  &_full {
    margin-top: 34px;
    &-tournament{
      @include xSmall {
      margin-top: 0px;
      }
    }
  }
  &-lottery_full{
    margin: 0px auto;
    background: #001a3b;
    width: 100%;
  }


}