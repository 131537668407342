.aside {
  display: block;
  position: relative;

  &_promo {
    padding: 30px 24px 30px 26px;
  }

  &__promo-bonus {
    //height: 213px;

    &_fixed {
      @include xSmall {
        max-width: 268px;
        margin: 0 auto;
      }
    }
  }

  &__promo-table {
    margin-top: 3px;
    padding-top: 16px;
    background-color: #2f527b;
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.13), inset 0 -3px 0 #214166, 0 3px 8px rgba(0, 0, 0, 0.19);

    &_fixed {
      @include xSmall {
        max-width: 268px;
        margin: 0 auto;
      }
    }
  }

  &__search {
    display: block;
    height: 100px;
    position: relative;
    padding: 29px 20px 20px;
    background-color: #132e4d;

    @include xLarge {
      padding: 30px 15px 30px;
    }
    @include xSmall {
      display: none;
    }
    &__top{
      display: none;
      height: 40px;
      padding-top: 10px;
      padding-bottom: 0px;
      @include xSmall {
        display: block;
      }
    }
  }

  &__curtour {
    margin: 0px auto;
    width: 93%;
    position: absolute;
    left: 0;
    top: 120px;
    right: 0;
    z-index: 2;
    display: block;
    padding: 15px 0 12px 140px;
    text-align: left;
    height: 50px;
    background-color: #10253d;
    border: 2px solid #1a5a91;
    border-bottom: 0px;
    border-top: 0px;

    @include xLarge {
      top: 127px; 
    }
    @include Large {
      padding: 15px 0 12px 100px;
      top: 135px;
    }
    @include Medium {
      padding: 15px 0 12px 70px;
      top: 137px;
    }
    @include Small {
      padding: 18px 0 12px 36px;
      top: 140px;
    }
    @include xSmall {
      top: 20px;
      padding: 12px 0 12px;
      border: 2px solid #1a5a91;
    }
  }

  &__icon {
    display: block;
    position: absolute;

    &_trophy {
      top: 0;
      left: 5px;
      background-image: url("../img/trophy.png");
      width: 60px;
      height: 50px;
      background-size: 60px 50px;

      @include Small {
        display: none;
      }

      @include xSmall {
        display: none;
      }
    }

    &_info {
      right: 21px;
      top: 16px;
      cursor: pointer;
    }

  }

  &__tooltip.tooltip {
    display: none;
    right: -13px;
    top: 27px;

    .aside__icon_info:hover & {
      display: block;
    }
  }

  &__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.23);
    color: #fff001;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 70px;
    padding-top: 15px;
    margin-bottom: 15px;
    text-align: center;    

    @include xLarge {
      margin-top: 35px;
      padding-top: 0px;
    }
    @include Large {
      margin-top: 45px;
    }
    @include Medium {
      margin-top: 47px;
    }
    @include Small {
      margin-top: 50px;
    }
    @include xSmall {
      padding-top: 0px;
    }
  }

  &__subtitle {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;

    @include Small {
      font-size: 12px;
    }
    @include xSmall {
      width: 100%;
      text-align: center;
    }
  }

  &__tournament {
    margin-top: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #132e4d;
    @include xSmall {
      margin-top: 0px;
    }
    .tournament__button{
      margin-top: 15px;
    }

    &__lottery{
      border: 0;
      width: 100%;
      margin-top: 10px;
      text-align: center;
      padding: 0px;

      .lottery__img-overlay{
        height: 190px;
        width: 190px;
        margin: 0px auto;
    
        @include Medium{
          height: 180px;
          width: 180px;
     }
      }
      .lottery__timer{
        .timer__note{
          padding: 0px;
        }
        .timer__cell{
          font-size: 30px;
        }
        .timer__row_caption{
          .timer__cell{
            font-size: 18px;
          }
        }
      }
    }

    &__holder {
      border: 2px solid #1a5a91;
      border-bottom: 0px;
      border-top: 0px;
      margin: 0px auto;
      width: 93%;
      background-color: #00284b;

      @include xLarge {
        padding: 30px 15px 30px;
      }
      @include xSmall {
        border: 2px solid #1a5a91;
      }

      &:before {
        content: "";
        width: 93%;
        height: 3px;
        background-image: linear-gradient(90deg,#04469c 0%,#52baff 50%,#04469c 100%);
        display: block;
        position: absolute;
        top: 124px;
        left: 16px;

        @include Large {
          left: 13px;
          top: 132px;
        }
        @include Medium {
          left: 10px;
          top: 134px;
        }
        
        @include Small {
          top: 137px;
          left: 7px;
        }

        @include xSmall {
          display: none;
        }
      }
    
      &:after {
        content: "";
        width: 93%;
        height: 3px;
        background-image: linear-gradient(90deg,#04469c 0%,#52baff 50%,#04469c 100%);
        display: block;
        position: absolute;
        bottom: 17px;
        left: 16px;

        @include Large {
          left: 13px;
        }
        @include Medium {
          left: 10px;
        }
        @include xSmall {
          display: none;
        }
      }
    }
  }

}