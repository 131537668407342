.table-panel {
  display: block;
  position: relative;
  background-color: #2f4b6c;
  border-radius: 2px;
  margin: 19px 1px;
  padding: 0 19px;
  max-height: 396px;

  @include xSmall {
    max-height: none;
  }

  &__title {
    display: block;
    position: relative;
    text-align: left;
    font-size: 16px;
    padding: 12px 10px 30px 6px;

    &_float_left {
      float: left;
      padding: 12px 0 30px 0;
    }

    &_float_right {
      float: right;
      padding: 12px 0 30px 0;
    }

    &_accent {
      padding: 0 10px 6px 6px;
    }
  }

  &__table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    position: relative;
    text-align: left;
  }

  &__thead {

  }

  &__row {

  }
  &__cell {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 14px;
    &:last-child {
      text-align: right;
    }

    thead .table-panel__row & {
      padding: 10px 14px;
    }

    tbody .table-panel__row:nth-child(odd) & {
      background-color: #406289;
      padding: 6px 14px;
    }

  }

}