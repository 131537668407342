.countdown {
  display: block;
  position: relative;
  font-size: 0;

  &__item {
    display: inline-block;
    width: 32px;
    height: 50px;
    line-height: 44px;
    border-radius: 2px;
    text-shadow: 0 2px 0 rgba(12, 12, 12, 0.33);
    color: #ddd;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    background-image: url('../img/svg/counter.svg');
    background-position: -3px 0;
    background-size: 38px;
    background-repeat: no-repeat;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }

    @include xSmall {
      background-image: url('../img/svg/counter.svg');
      width: 28px;
      height: 37px;
      background-position: center top;
      background-size: 34px;
      font-size: 26px;
      line-height: 37px;

    }
  }

  &__divider {
    display: inline-block;
    vertical-align: top;
    padding: 0 4px 0 1px;

    &:before {
      content: ',';
      display: block;
      height: 44px;
      color: #fff;
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase;

      @include xSmall {
        height: 32px;
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}