.popup {
  width: 568px;
  height: auto;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-49%);
  transform: translate(-50%,-49%);
  z-index: 200;
  background-color: #132e4d;
  border-radius: 2px;
  padding-bottom: 1px;
  box-shadow: inset 0 -1px 0 rgba(91, 64, 71, 0.38), 0 1px 8px rgba(0, 0, 0, 0.53);
  //overflow-y: auto;
  &_height_100 {
    //height: 100%!important;
  }
  @include xSmall {
    position: absolute;
    left: 0;
    top: 0;
    //right: 0;
    //bottom: 0;
    transform: none;
    width: 100% !important;
    max-height: 100%;
    overflow-y: scroll;
  }

  &_tabs {
    width: 940px;
    height: auto;
    top: 1%;
    transform: translate(-50%,0);
    position: absolute;

    .tab__content {
      min-height: 330px;
    }

    @include xLarge {
      width: 940px;
    }

    @include Large {
      width: 940px;
    }

    @include Small {
      width: 700px;
    }

    @include xSmall {
      width: 100%;
      transform: none;
      top: 0;
      overflow-y: auto;
      min-height: 0;
    }

    .tab__close {
      top: 25px;

      @include xSmall {
        top: 14px;
        right: 14px;
      }
    }
  }

  &__close, .popup_go_tomain {
    width: 44px;
    height: 38px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #132e4d;
    border-radius: 2px;
    box-shadow: inset 0.6px -0.8px 0 #2b2a3c;
    text-align: center;
    padding: 11px 0;
    cursor: pointer;
  }

  &__head {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg,rgba(87, 185, 220, 0),#35d0ff 50%,rgba(87, 185, 220, 0));
    }
  }

  &__title {
    text-align: left;
    font-size: 19px;
    font-weight: 700;
    padding: 24px 32px 21px;
    color: #fff;
    text-transform: uppercase;
    &_accent {
      color: $textaccent;
    }
    .popup__content &{
      text-align: center;
      padding: 0;
      @include xSmall {
        padding: 0 30px;
      }
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #9e9cbd;
    padding: 24px 5px 23px;
    display: inline-block;
    vertical-align: top;
    &_accent {
      color: $textaccent;
    }
  }

  &__content {
    position: relative;
  }

  &__label {
    text-shadow: 0 2px 0 rgba(0,0,0,.33);
    color: #fff;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: left;

    &_small {
      font-size: 19px;
    }
  }

  &__footer {
    position: relative;
    background-color: #00142c;
    min-height: 64px;
    text-align: center;
    &::before {
      content: "";
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      height: 1px;
      background-image: linear-gradient(90deg,rgba(87, 185, 220, 0),#35d0ff 50%,rgba(87, 185, 220, 0));
     }
     .footer__first {
       padding-top: 15px;
       display: block;
       color: rgb(88, 88, 88);
     }
     .footer__second {
      color: yellow;
      display: block;
      font-size: 50px;
    }
    .footer__third {
      display: block;
      color: rgb(88, 88, 88);
      padding-bottom: 15px;
    }    
  }

  &__note {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-family: 'ClearSans', sans-serif;
    text-transform: uppercase;
    display: block;
    padding-top: 21px;
    padding-bottom: 15px;
  }

  &__caption {
    display: block;
    position: relative;
    color: #fff;
    padding: 0 20px;
    font-size: 15px;
    line-height: 16px;
    font-weight: 400;
    text-align: left;
  }

  &__socials {
    padding-bottom: 25px;
     .socials__item {
      background-color: #09203a;
      border: 1px solid #4c829c;
      border-radius: 2px;
      box-shadow: inset 0 0 6px 1px hsla(0,0%,100%,.05);
    }
  }

  &__button {
    font-size: 18px;
    padding: 9px 18px;
    margin-top: -18px;
    margin-left: 10px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
  }

  &__gallery {
    text-align: center;
    margin-top: 41px;
    margin-bottom: 61px;

    .preview {
      background: transparent;
      box-shadow: none;
      margin-right: 28px;
      @include xSmall {
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .preview__info {
      text-align: left;
      padding: 14px 5px;
    }

    .preview__title {
      color: #f4f3ff;
      font-size: 14px;
      font-weight: 700;
    }

    .preview__note {
      color: #8b8b8b;
      font-size: 11px;
      font-weight: 700;
    }
  }


  .column{
    &__1-5{
      width: 19%;
    }
    &__2-5{
      width: 39%;
    }
    &__3-5{
      width: 59%;
    }
    &__4-5{
      width: 79%;
    }
    &__5-5{
      width: 100%;
    }
    &__1-3{
      width: 33%;
    }
    &__2-3{
      width: 66%;
    }
    &__half{
      width: 49%;
    }
    &__1-5,
    &__2-5,
    &__3-5,
    &__4-5,
    &__5-5,
    &__1-3,
    &__2-3,
    &__half{
      position: relative;
      display: inline-block;
      padding: 0;
      vertical-align: middle;
      @media screen and (max-width: 627px){
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  .game-container{
    width: 30%;
    display: inline-block;
    background: #0e233b;
    box-shadow: inset 0 3px 20px 11px rgba(33, 136, 152, 0.45);
    margin-right: 4.1%;
    text-align: center;
    position: relative;
    @media screen and (max-width: 725px){
      margin-right: 4%;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg,rgba(87, 185, 220, 0),#35d0ff 50%,rgba(87, 185, 220, 0));
      
    }
    &:nth-child(4){
      margin-right: 0;
      @media screen and (max-width: 480px){
        margin: 5px auto;
      }
    }
    @media screen and (max-width: 480px){
      margin: 5px auto;
      width: 284px;
      display: block;
    }
    p{
      margin: 0;
    }
    &:hover .preview__overlay{
      background-color: rgba(0,0,0,.73);
      box-shadow: inset 0 0 2.9px 1.1px hsla(0,0%,100%,.28), inset 0 1px 0 hsla(0,0%,100%,.08), 0 2px 1px rgba(13,25,39,.37);
      opacity: 1;
      z-index: 1;
      height: 161px;
      @media only screen and (max-width: 1000px){
        height: 70%;
      }
      @media only screen and (max-width: 880px){
        height: 68%;
      }
      @media only screen and (max-width: 767px){
        height: 66%;
      }
      @media only screen and (max-width: 740px){
        height: 64%;
      }
      @media only screen and (max-width: 660px){
        height: 62%;
      }
      @media only screen and (max-width: 610px){
        height: 60%;
      }
      @media only screen and (max-width: 570px){
        height: 58%;
      }
      @media only screen and (max-width: 520px){
        height: 56%;
      }
      @media only screen and (max-width: 480px){
        height: 191px;
      }
    }

    .preview__note {
      font-size: 15px;
      margin: 5px auto 15px auto;
      display: block;
    }

  }
  &.popup_vipBonusAct,
  &.popup_vipBonusReact{
    width: 920px;
    background: url(../img/vip-bonus-bg.png)center bottom no-repeat #1d1b2e;
    color: #fff;
    font-size: 16px;
    padding-bottom: 25px;
    max-width: 100%;
    overflow-y: visible;
    height: auto;
    max-height: initial;

    @media only screen and (max-width: 767px){
      display: block;
      transform: none;
      top: 0;
      overflow-y: auto!important;
      min-height: 0;
      height: 100%;
    }

    .popup__head{
      background-color: transparent;
      box-shadow: inset 0 1px 0 0 #262f39;
      text-align: center;
    }
    .popup__close{
      background: transparent;
      box-shadow: none;
      top: 15px;
    }
    .popup .column__1-3, .popup .column__1-5, .popup .column__2-3, .popup .column__2-5, .popup .column__3-5, .popup .column__4-5, .popup .column__5-5{
      vertical-align: top;
    }
    h1{
      color: rgb(253, 194, 54);
      text-transform: uppercase;
      text-shadow: 0 10px 11.88px rgba(24, 2, 27, 0.55);
      line-height: 1.2;
      margin-top: 43px;
      @media only screen and (max-width: 627px){
        margin-top: 0;
      }
    }

    p{
      line-height: 1.7;
    }
    img{
      max-width: 100%;
      @media only screen and (max-width: 627px){
        width: 200px;
      }
    }
    button{
      margin-left: 0;
      margin-top: 0;
    }
    .column__1-5{
      @media only screen and (max-width: 627px){
        width: 19%;
      }
      @media only screen and (max-width: 480px){
        width: 29%;
      }
    }
    .popup__section:last-child {
      text-align: center;
    }
  }
  &.popup_afterRegistration{
    background-image: linear-gradient(180deg,#112542,#070829); 
    width: 920px;
    color: #fff;
    height: auto;
    top: 19px;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    position: absolute;
    font-size: 18px;
    max-width: 100%;
    text-align: center;
    zoom: 0.84;
    @include xSmall{
      top:0;
      zoom: 1;
      transform: translate(0%);
    }

    img{
      max-width: 100%;
    }
    h2{
      font-size: 25px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      color: #ff5252;
      margin-bottom: 25px;
    }
    h3{
      font-size: 20px;
      margin: 0;
    }
    .popup__title{
      text-transform: uppercase;
      text-align: center;
    }
    .popup__head{
      background-color: #00142c;
    }
    .popup__close{
      background: transparent;
      box-shadow: none;
      top: 15px;
      opacity: 0.5;
    }
    .icon-prize{
      position: absolute;
      left: 363px;
      top: -17px;
      @include xSmall{
        display: none;
      }
    }
    .section-1-txt{
      @include xSmall{
        margin: 0 0 13px;
      }
    }
    .popup__button{
      width: 100%;
      height: 56px;
      border-radius: 5px; 
      color: #8e231f;
      font-size: 25px;
      font-weight: 500;
      -webkit-box-shadow: 0 0 20px 7px rgba(151, 175, 54, 0.5);
      -moz-box-shadow: 0 0 20px 7px rgba(151, 175, 54, 0.5);
      box-shadow: 0 0 20px 7px rgba(151, 175, 54, 0.5);
      background-image: linear-gradient(180deg,#e2d533,#ff8d00);
      text-shadow: 1px 1px 4px #3a0404;
      &.button_color_blue{
        border-top: 3px solid #4fcfff;
        background-image: linear-gradient(180deg,#01b7f0,#1932bc);
        margin-left: 33px;
        @media screen and (max-width: 871px){
          margin-left: 0;
          font-size: 16px;
        }
      }

      @include xSmall {
        font-size: 18px;
      }
    }
    .popup__section{
      padding: 15px 60px 13px;
      position: relative;
      @include Small{
        padding: 15px 30px;
      }
      @include xSmall{
        padding: 15px;
      }
      p:last-child{
        margin-bottom: 0;
      }
      p:first-child{
        margin-top: 0;
      }

      &:first-child img{
        @include xSmall{
          max-width: 200px;
        }
      }
      &:nth-child(3){
        text-align: center;
        h2{
          color: #fff;
        }
      }
      &__main {
        .popup__image {
          background-image: url("../img/popup__image.png");
          width: 78%;
          height: 360px;
          background-size: 100% 360px;
          margin: 0px auto;

          @include xSmall {
            width: 250px;
          }
        }
        .column__half {
          margin-bottom: 25px;
        }

        #clockdiv{
          font-family: sans-serif;
          color: #fff;
          display: inline-block;
          font-weight: 100;
          text-align: center;
          font-size: 30px;
          margin-bottom: 25px;

          @include xSmall {
            font-size: 19px;
          }
        }
        
        #clockdiv > div{
          padding: 10px;
          border-radius: 3px;
          background: rgba(17, 33, 115, 0.53);
          display: inline-block;
        }
        
        #clockdiv div > span{
          padding: 15px;
          border-radius: 3px;
          background: #07092a;
          display: inline-block;
          color: #00d6fb;
        }
        
        .smalltext{
          padding-top: 5px;
          font-size: 16px;
        }

        .no_present {
          color: #353b58;
          text-decoration: underline;
          margin-top: 15px;
          cursor: pointer;
        }
      }

      &__dark {
        background-color: #07092a;
        color: #00d6fb;
         &:after {
          content: "";
          width: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 1px;
          background-image: linear-gradient(90deg,rgba(87, 185, 220, 0),#35d0ff 50%,rgba(87, 185, 220, 0));
         }
         &:before {
          content: "";
          width: 100%;
          position: absolute;
          right: 0;
          top: 0;
          height: 1px;
          background-image: linear-gradient(90deg,rgba(87, 185, 220, 0),#35d0ff 50%,rgba(87, 185, 220, 0));
         }

         p {
            display: inline-block;
         }

        .popup__arrow {
          background-image: url("../img/popup__arrow.png");
          background-size: 12px 25px;
          width: 12px;
          height: 25px;
          display: inline-block;
          margin-bottom: -7px;
          margin-left: 8px;
        }
        .popup__countpad {
          display: inline-block;
          margin-bottom: -18px;
          margin-left: 20px;
        }
      }
      .popup__button{
        margin-top: 0;
      }
    }
    .popular {
      &__head {
        font-size: 26px;
      }
    }
    table{
      width: 100%;
      margin: 0 auto;
      font-size: 15px;
      @media screen and (max-width: 350px){
        font-size: 13px;
      }
    }
    thead tr:first-child td{
      border-top: 1px solid #3f3e55;
      font-size: 13px;
      color: #534d5e;
      background: #282738;
      &:last-child{
        text-align: right;
      }
    }
    td{
      border-right: 1px solid #3f3e55;
      padding: 8px 20px;
      &:first-child{
        border-left: 1px solid #3f3e55;
      }
      @include xSmall{
        padding: 5px;
      }
    }
    tbody{
      tr:last-child td{
        border-bottom: 1px solid #3f3e55;
      }
      tr:nth-child(even){
        background: #282738;
      }
      td:nth-child(2){
        color: #1cbbb4;
      }
      td:nth-child(4){
        color: #fdc236;
        text-align: right;
      }
    }
    .popup-subtitle{
      color: #554b60;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      width: 100%;
    }
    .popup__section:last-child{
      background: transparent;
    }
    .amount-of-payments{
      width: 320px;
      margin: 0 auto;
      text-align: center;
      background: #222131;
      position: relative;
      @media screen and (max-width: 350px){
        width: 290px;
      }
      .amount{
        margin: 0 0 15px;
        font-size: 42px;
      }
      small:nth-child(4){
        position: absolute;
        left: 20px;
        text-align: left;
        &:after{
          content: " ";
          margin-left: 5px;
          position: absolute;
          margin-top: -9px;
          width: 40px;
          height: 1px;
          display: block;
          background: #b6a9c3;
          left: 73px;
          @media screen and (max-width: 350px){
            width: 27px;
          }
        }
      }
      p:nth-child(5){
        text-align: right;
        position: relative;
        margin: 0 21px 10px 0;
        font-size: 16px;
      }
      small:last-child{
        text-align: right;
        font-size: 12px;
        position: absolute;
        right: 22px;
        margin-top: -10px;
        font-style: italic;
      }
    }
  }
  &.popup_afterActivation{
    width: 920px;
    color: #b6a9c3;
    height: auto;
    top: 1%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    position: absolute;
    font-size: 18px;
    max-width: 100%;
    @include xSmall{
      left: 50%;
      top:0;
    }

    img{
      max-width: 100%;
    }
    h2{
      font-size: 27px;
      font-weight: 100;
      text-transform: uppercase;
      margin: 0;
    }
    h3{
      font-size: 20px;
      margin: 0;
    }
    .popup__title{
      text-transform: none;
    }
    .popup__head{
      background-image: linear-gradient(180deg,#121d29,#04101d);
    }
    .popup__close{
      background: transparent;
      box-shadow: none;
      top: 15px;
      opacity: 0.5;
    }
    .icon-prize{
      position: absolute;
      left: 283px;
      top: 15px;
      @include xSmall{
        display: none;
      }
    }
    .popup__button{
      border-top: 3px solid #ffe303;
      border-radius: 21px;
      background-image: linear-gradient(180deg,#fe9400,#ff0024);
      &.button_color_blue{
        border-top: 3px solid #4fcfff;
        background-image: linear-gradient(180deg,#01b7f0,#1932bc);
        margin-left: 33px;
        @media screen and (max-width: 871px){
          margin-left: 0;
          font-size: 16px;
        }
      }
    }
    .popup__section:nth-child(even){
      background: #141224;
    }
    .popup__section{
      padding: 15px 60px 20px;
      position: relative;
      @include Small{
        padding: 15px 30px;
      }
      @include xSmall{
        padding: 15px;
      }

      &:last-child{
        background: url(../img/after-activation-bg.png) center bottom no-repeat #141224;
        padding-bottom: 41px;
      }
      .popup__button{
        margin-top: 0;
      }
    }
    table{
      width: 100%;
      margin: 0 auto;
      font-size: 15px;
      background: #222131;
      @media screen and (max-width: 350px){
        font-size: 13px;
      }
    }
    thead tr:first-child td{
      border-top: 1px solid #3f3e55;
      font-size: 13px;
      color: #534d5e;
      background: #282738;
      &:last-child{
        text-align: right;
      }
    }
    td{
      border-right: 1px solid #3f3e55;
      padding: 8px 20px;
      &:first-child{
        border-left: 1px solid #3f3e55;
      }
      @include xSmall{
        padding: 5px;
      }
    }
    tbody{
      tr:last-child td{
        border-bottom: 1px solid #3f3e55;
      }
      tr:nth-child(even){
        background: #282738;
      }
      td:nth-child(2){
        color: #1cbbb4;
      }
      td:nth-child(4){
        color: #fdc236;
        text-align: right;
      }
    }
    .popup-subtitle{
      color: #554b60;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      width: 100%;
    }
    .amount-of-payments{
      width: 320px;
      margin: 24px auto 0;
      text-align: center;
      background: #222131;
      position: relative;
      padding-bottom: 14px;
      @media screen and (max-width: 350px){
        width: 290px;
      }
      .amount{
        margin: 0 0 15px;
        font-size: 42px;
      }
      small:nth-child(4){
        position: absolute;
        left: 20px;
        text-align: left;
        &:after{
          content: " ";
          margin-left: 5px;
          position: absolute;
          margin-top: -9px;
          width: 40px;
          height: 1px;
          display: block;
          background: #b6a9c3;
          left: 73px;
          @media screen and (max-width: 350px){
            width: 27px;
          }
        }
      }
      p:nth-child(5){
        text-align: right;
        position: relative;
        margin: 0 21px 10px 0;
        font-size: 16px;
      }
      small:last-child{
        text-align: right;
        font-size: 12px;
        position: absolute;
        right: 22px;
        margin-top: -10px;
        font-style: italic;
      }
    }
    .timer-title{
      font-size: 12px;
      font-family: "ClearSans";
      color: rgb(253, 194, 54);
      text-transform: uppercase;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 10px;
    }

    .timer{
      width: 252px;
      border-top: 1px solid #3d3c50;
      border-bottom: 1px solid #3d3c50;
      margin: 0 auto;
      padding: 13px 2px;
      position: relative;
      &:before{
        content: url(../img/timer-stars-left.png);
        position: absolute;
        left: -133px;
        top: calc(50% - 19px);
      }
      &:after{
        content: url(../img/timer-stars-right.png);
        position: absolute;
        right: -133px;
        top: calc(50% - 19px);
      }
      &-body{
        display: table-row;
        font-size: 31px;
      }
    }
    .timer-cell{
      display: inline-block;
      font-size: 14px;
      width: 23%;
      text-align: center;
      &:first-child{
        padding-right: 13px;
      }
    }
    .timer__cell{
      padding: 0 5px;
      &:first-child{
        padding-left: 13px;
      }
      &:nth-child(2){
        padding: 0 16px;
      }
    }
    .timer__row{
      margin: 0 auto;
      display: block;
      width: 248px;
    }
    #countup p{
      display: inline-block;
      padding: 5px;
      font-size: 31px;
      margin: 0;
      width: 44px;
      text-align: center;
      &.dots{
        width: 8px;
        padding: 5px 0;
      }
      &#days{
        margin-right: 18px;
      }
    }
  }
}

.popup_paymentConfirm{
  h1,h2,h3,h4,h5{
    color: #f1e419;
  }
  ul{
    list-style:none;
  }
  .popup__content{
        padding-bottom: 20px;
  }
}

.popup_chooseBonus, .popup_depositSuccess {
  width: 940px;

  @include Small {
    width: 700px;
  }

  .popup__title {
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.27);
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: $textaccent;
    text-align: center;
    padding: 55px 50px 26px;
  }

  .popup__bonuses {
    text-align: center;
    padding-bottom: 68px;
  }

}

.popup_depositSuccess {

  .popup__gallery {

    .preview {
      max-width: 200px;
      border: none;
    }

    @include xSmall {
      .preview {
        max-width: 300px;
        width: auto;
        display: block;
        margin: 0 auto 20px;
      }
    }

  }

}

.popup_registration {
  width: 700px;

  .popup__title{
    text-align: left;
    font-size: 19px;
    padding: 24px 32px 21px;
    color: #fff;
  }

  .popup__content {
    padding: 35px 30px 40px 31px;
  }

  .popup__socials {

  }

  .popup__button {
    margin-top: 24px;
    margin-left: 0;
  }

}

.popup_afterRegistration {
  width: 568px;

  .popup__content {
    .bonus {
      display: inline-block;
      vertical-align: top;
      padding-bottom: 38px;
    }
  }

  .popup__title {
    font-size: 19px;
    &_accent {
      color: $textaccent;
      text-align: center;
      padding-top: 48px;
      padding-bottom: 4px;
    }
  }

  .popup__button {
    margin-left: -5px;
  }

}

.popup_depositSuccess {
  width: 800px;

  @include Small {
    width: 700px;
  }

  .popup__title {
    padding-top: 38px;
  }

  .popup__footer {
    min-height: 55px;
  }

  .popup__gallery {
    margin-bottom: 50px;
  }

  .popup__button {
    margin-left: -2px;
    padding: 12px 18px;
    margin-top: -20px;
  }

}

.popup_changePassword {
  width: 568px;
  position: fixed;

  .popup__content {
    padding: 33px 25px 49px 31px;
  }

  .popup__input {
    display: block;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .input__inner {
      width: 100%;
      height: 49px;
      font-family: 'ClearSans', sans-serif;
      margin-top: 14px;
    }
  }

}

.popup_passwordChanged, .popup_remindSuccess {
  width: 568px;

  .popup__content {
    padding: 52px 0 64px;

    .popup__title {
      text-align: center;
      padding: 0 30px;
    }
  }

}

.popup_restorePassword {


  .popup__content {
    padding: 32px 25px 64px 31px;
    .popup__subtitle {
      padding: 0;
      color: #aaa9b8;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .popup__input {
    display: block;
    margin-bottom: 16px;
    margin-top: 29px;
    &:last-child {
      margin-bottom: 0;
    }
    .input__inner {
      width: 100%;
      height: 49px;
      font-family: 'ClearSans', sans-serif;
      color: #a5a3bd;
      text-transform: none;
    }
  }

}


.popup_phoneVerification {

  .popup__content {
    padding: 36px 112px 52px 120px;
    text-align: center;

    @include xSmall {
      padding: 18px 16px 40px;
      max-width: 320px;
      margin: 0 auto;
    }

    .button_style_flat {
      box-shadow: none;
      font-size: 13px;
      padding: 8px 10px;
      margin-left: 7px;
      margin-top: 27px;
      width: auto;

      &:hover {
        box-shadow: 0 1px 5px #494767, 0 1px 0 #1a1925;
      }
    }
  }

  .popup__caption {
    display: block;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 27px;
  }

  .popup__input {
    .input__inner {
      height: 49px;
      width: 150px;
      text-transform: none;
    }
  }

  .popup__timer {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
  }

  .popup__advice {
    width: 170px;
    color: #838383;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    margin-top: 22px;
    margin-left: 14px;
  }

  .popup__button {
    margin-left: 0;
  }

}


.popup_emailVerification {

  .popup__content {
    padding: 27px 53px 55px 53px;

    @include xSmall {
      padding: 27px 53px 55px 53px;
    }
  }

  .popup__caption {
    font-size: 16px;
    line-height: 18px;
    padding-top: 21px;
  }

}

.popup_emailConfirmed {

  .popup__content {
    padding: 63px 115px 80px 128px;

    @include xSmall {
      padding: 40px 24px;
    }
  }

  .popup__caption {
    font-size: 18px;
    line-height: 18px;
  }

}
.popup_tournamentGames {
  width: 940px;
  height: 490px;
  position: fixed;

  @include Small {
    width: 700px;
  }

  @include xSmall {
    width: 100%;
    height: auto;
  }
  .preview__item {
    height: 141px;
    overflow: hidden;
    @include xSmall {
      height: 128px;
    }
  }

  .popup__title {
    padding: 33px 400px 29px 37px;
    font-size: 20px;

    @include xSmall {
      padding: 24px 32px 21px;
    }
  }

  .popup__search {
    display: block;
    position: absolute;
    top: 25px;
    right: 70px;

    @include xSmall {
      display: block;
      position: absolute;
      top: 94px;
      right: 50%;
      transform: translateX(50%);
    }

    .search__input {
      width: 276px;
      height: 39px;
      background-color: #181723;
      border-radius: 2px;
      border: none;
      box-shadow: inset 0 1px 0 #0d0c13,0 1px 0 #323144;
      color: #a5a3bd;
      font-size: 15px;
      font-weight: 500;
      font-family: 'ClearSans',sans-serif;
      text-transform: uppercase;
      padding: 0 21px;
    }

    .icon {
      display: block;
      position: absolute;
      top: 7px;
      right: 8px;
    }

  }

  .popup__content {
    padding: 18px 24px 20px 24px;

    @include xSmall {
      margin-top: 62px;
    }
  }

  .popup__gallery {
    text-align: left;
    margin: 0;
    max-height: 346px;
    position: relative;

    @include xSmall {
      max-height: none;
    }

    .main {
      padding: 0 22px;
      @include Small {
        padding: 0;
      }
      @include xSmall {
        padding: 0;
      }
    }

    @media only screen  and (max-width: 468px) {

    }
    .preview {
      width: 190px !important;
      margin: 10px;
      @include xSmall {
        margin: 10px!important;
        width: 176px !important;

      }

      @media only screen  and (max-width: 468px) {
        width: 43%!important;
        margin: 10px  3.33%!important;
      }

    }

    .preview__info {
      background-color: #2b2a3d;
      border: 1px solid rgba(255, 255, 255, 0.09);
      border-radius: 2px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.28);
      padding: 10px 13px;
    }

    .preview__title {
      font-size: 15px;
    }

    .preview__note {
      font-size: 12px;
    }


  }

}

.popup_favoritesAdded, .popup_favoritesAddedFail {

  .popup__title {
    font-family: 'ClearSans', sans-serif;
    font-size: 21px;
    padding: 20px 20px 20px 26px;
  }

  .popup__content {
    padding: 40px 20px 55px 20px;
  }

}


.popup_gameplay {
  width: 800px;
  //top: 180px;
}

.popup_gameplayDeposit {
  width: 800px;
  //top: 254px;

  .popup__title {
    font-size: 22px;
    letter-spacing: -0.1px;
    padding: 60px 30px 47px;
  }

  .popup__icon {
    display: block;
    position: relative;
    text-align: center;
    padding-bottom: 70px;
  }

  .popup__gameplay-button {
    top: -21px;
  }

  .popup__footer {
    min-height: 71px;
  }
}

.popup_gameplayGallery {
  width: 800px;
  top: 300px;

  @include xSmall {
    top: 0;
  }

  .popup__close, .popup_go_tomain {
    top: auto;
    right: auto;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
    height: auto;
    background: none;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    color: #01b4ff;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    box-shadow: none;
  }

  .popup__title {
    font-size: 22px;
    padding: 61px 60px 20px 60px;
  }

  .popup__gallery {
    margin-bottom: 0;

    .preview {
      border: none;

      @include xSmall {
        display: block;
        max-width: 200px;
        margin: 0 auto 20px;
      }

    }
  }

  .preview:last-of-type {
    margin-right: 0;

    @include xSmall {
      margin-right: auto;
    }
  }

  .popup__button {
    margin-left: 0;
    font-family: "ClearSans", sans-serif;
    padding: 10px 20px;
    margin-top: -22px;
    font-size: 20px;
  }

  .popup__footer {
    min-height: 76px;
  }

  .bonus-sidebar__subtitle{
    margin-bottom: 40px;
  }

  .bonus-subtitle__header{
    text-align: center;
    font-size: 1.17em;
    color: white;
    margin: 0 auto 10px;
  }
  .bonus-subtitle__txt{
    width: 100%;
    display: block;
    color: #ccc;
    font-size: 14px;
    padding: 0px 15px;
    text-align: center;
  }
}

.popup_gameplayNomoney {
  width: 800px;
  //top: 256px;

  .popup__title {
    font-size: 22px;
    margin: 0;
    padding: 55px 20px 0px;
    &_accent {
      color: $textaccent;
      text-align: center;
      padding: 0 20px;
    }
  }

  .popup__icon {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 47px;
    padding-bottom: 93px;
  }

  .popup__close, .popup_go_tomain {
    top: auto;
    bottom: 13px;
    right: auto;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
    height: auto;
    background: none;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    color: #01b4ff;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    box-shadow: none;
  }

  .popup__gameplay-button {
    margin-top: -24px;
  }

  .popup__footer {
    min-height: 90px;
  }

}

// Wheel of fortune popups styles

.popup_fortuneRules {
  width: 940px;

  .popup__title {
    padding: 32px 37px 30px;
  }

  .popup__content {
    padding: 15px 69px 25px 34px;
  }

  .popup__caption {
    text-shadow: 0 1px 0 #000;
    color: #bdbbce;
    line-height: 19px;
    text-align: left;
  }

  .popup__levels {
    padding: 36px 28px;
    background-color: #1b1a28;
    box-shadow: inset 0 1px 0 rgba(43, 42, 60, 0.83);
  }

}

.popup_fortuneHistory {
  width: 940px;

  .popup__title {
    padding: 32px 37px 30px;
  }

  .popup__content {
    padding: 25px 5px 25px 27px;
    font-size: 0;
  }

  .popup__filterSearch {
    display: table;
    width: 969px;
    border-collapse: collapse;
    margin-right: 22px;
  }

  .popup__cell {
    display: table-cell;
    vertical-align: top;
    white-space: nowrap;
    width: 1px;
    &_fluid {
      width: 100%;
      padding-right: 16px;
    }
  }

  .popup__sort {
    display: inline-block;
    vertical-align: top;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    line-height: 20px;
    padding: 20px 24px 18px;
    background-color: #58566d;
    white-space: nowrap;
    width: 163px;
  }

  .popup__filter {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
  }

  .popup__search {
    display: inline-block;
    vertical-align: top;
    position: relative;
    .input__inner {
      display: block;
      position: relative;
      width: 250px;
      padding: 0 72px 0 14px;
      height: 58px;
      border-radius: 1px;
      background-color: #4d4b63;
      box-shadow: none;
    }
  }

  .popup__searchButton {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 58px;
    background-color: #58566d;
    border: none;
    cursor: pointer;
  }

  .popup__history {
    display: block;
    position: relative;
    margin-top: 22px;
    padding-right: 23px;
    max-height: 475px;
    overflow: hidden;
  }

}

.popup_fortuneWheel {
  width: 940px;
  height: 978px;
}
.position_absolute {
  position: absolute;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  top: 1%;
}

.popup_newViplevel .popup__content {
  padding-top: 20px;
  text-align: center;
  .popup__title {
    font-size: 31px;
    line-height: 40px;
  }
  .popup__subtitle {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 19px;
    padding: 0;
    margin-top: 9px;
  }
  .bonus__item {
    min-width: 227px;
    min-height: 246px;
  }
}
.popup_deposit_for_bonus  {

  .popup_section__main {
    display: inline-block;
    vertical-align: top;
    padding: 29px 13px 30px 0;
    max-width: 646px;
    .payment__gallery {
      margin: 0 20px;
    }
    @include Small {
      padding: 15px 0 18px;
    }

    @include xSmall {
      width: 100%;
      padding: 15px 0 18px;
      .payment__row {
        padding: 0 8px;
      }
    }
  }
  .popup_section__aside{
    width: 284px;
    display: inline-block;
    vertical-align: top;
  }
  .payitem {
    @include xLarge {
      width: 179px;
    }
    @include Large {
      width: 179px;
    }
    @include Medium {
      width: 179px;
    }
  }

  .popup__content {
    text-align: center;
    padding-bottom: 20px;
  }
  .payment__row {
    padding: 0;
    text-align: center;
  }
  .payment__tooltip {
    margin: 4px 0 30px;
    @include xLarge {
      width: 568px;
    }
    @include Large {
      width: 568px;
    }
    @include Medium {
      width: 568px;
    }
    .pay-tooltip__button {
      @include xLarge {
        padding-left: 8px;
        padding-right: 8px;
      }
      @include Large {
        padding-left: 8px;
        padding-right: 8px;
      }
      @include Medium {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .popup__title_accent {
    text-align: center;
    padding: 0;
    @include xSmall {
      padding: 0;
    }
  }
}

.popup_gameBonusPopup {
  width: 656px;

  .popup__close {
    background: transparent;
    box-shadow: none;
  }
}

.popup_registration_v2,.popup_registration_v3,.popup_registration_v4,.popup_registration_v5,.popup_registration_v6,.popup_registration_v7 {
  width: 940px;

  @include Small  {
    width: 700px;
    max-height: 100%;
    overflow-y: scroll;
  }

  .popup__head {
    background: #00142c;
    text-align: center;
    &:after{
      height: 1px;
      background: radial-gradient(ellipse at center,#00b1ec 0%,transparent 70%);
      box-shadow: none;
      width: 100%;
      z-index: 1;
      opacity: 1;
    }
  }

  .popup__close {
    background: transparent;
    box-shadow: none;
    opacity: 0.4;
    padding: 19px 0 17px;
    right: 7px;
    height: auto;

  }

  .popup__title {
    padding: 17px 32px 16px;
    text-align: center;
  }

  .popup__content {
    padding: 24px 0 38px;
    background: linear-gradient(to top,#070929 0,#0e1f38 70%);
  }

}

.popup.popup_registration_v2{
  background: #132e4d;
}