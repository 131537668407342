.fortune-wheel {
  display: block;
  width: 1024px;
  height: 978px;
  position: relative;
  background-image: url('../img/lottery-bg.png');
  background-repeat: no-repeat;
  background-position: center;

  &__head {
    display: table;
    position: relative;
    white-space: nowrap;
    width: 100%;
    height: 86px;
    border-collapse: collapse;
    padding: 0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      opacity: 0.27;
      background-color: #3f3d60;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.42);
    }
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    &_fixed {
      width: 1px;
    }
  }

  &__title {
    display: inline-block;
    font-size: 20px;
    font-family: 'ClearSans', sans-serif;
    padding: 18px 0px 10px;
  }

  &__info {
    display: block;
    position: relative;
    padding: 0 34px;
  }

  &__dashboard {
    display: block;
    position: relative;
    padding: 29px 63px 26px;
  }

  &__icon {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 19px;
    padding-top: 7px;
  }

  &__tooltip {
    left: 50%;
    top: 38px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s, visibility 0.1s;

    .fortune-wheel__icon:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__button {
    height: 31px;
    font-size: 15px;
    margin-right: 5px;
    padding: 6px 15px;
    background-color: #3e92d8;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);


    &_aux {
      background-color: #fd7423;
      background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
      padding: 6px 16px;
    }

  }

  &__lottery {
    display: block;
    position: absolute;
    width: 590px;
    height: 590px;
    top: 200px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &__wheel {
    display: block;
    position: absolute;
    width: 590px;
    height: 590px;
    top: 0;
    left: 0;
    background-image: url('../img/lottery-wheel.png');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
  }

  &__logo {
    display: block;
    position: absolute;
    width: 464px;
    height: 118px;
    top: 141px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-image: url('../img/lottery-title.png');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  &__spinner {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 144px;
    height: 144px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url('../img/lottery-spinner.png');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 3;
  }

  &__arrow {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 65px;
    height: 91px;
    right: 6px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url('../img/lottery-arrow.png');
    background-repeat: no-repeat;
    background-position: center;
    z-index: 3;
  }

  &__range {
    display: block;
    width: 588px;
    position: absolute;
    bottom: 38px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

}



// Just some animation for test

//.fortune-wheel__wheel {
//  animation-name: rotation;
//  animation-duration: 3s;
//  animation-iteration-count: 2;
//  animation-timing-function: linear;
//}
//
//
//@keyframes rotation {
//  0% {transform:rotate(0deg);}
//  100% {transform:rotate(360deg);}
//}