.user-toppanel {
  display: block;
  // width: 100%;
  padding-left: 250px;
  min-height: 50px;
  position: relative;
  font-size: 0;
  padding: 16px 0;
  font-weight: 700;
  font-family: 'ClearSans', sans-serif;
  white-space: nowrap;
  overflow: visible;

  @include xSmall {
    width: 100%!important;
  }

  &.wrap {
    @include xLarge {
      padding-left: 310px;
    }
    @include Large {
      padding-left: 291px;
    }
    @include Medium {
      padding-left: 220px;
    }
    @include Small {
      padding-left: 225px;
    }
  }

  &__title {
    color: $textcolor;
    display: block;
    vertical-align: top;
    font-size: 15px;

    @include Small {
      display: none;
    }

    @include xSmall {
      display: none;

      .header__toppanel.open & {
        display: block;
      }
    }
  }

  &__name {
    position: relative;
    margin-left: 8px;
    font-size: 15px;
    line-height: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    float: left;
    max-width: 100px;

    @include Small {
      color: #00a6ff;
      margin-left: 5px;
      padding-right: 14px;
      line-height: 18px;

        &:first-child {
          display: none;
          &:before {
            content: none;
          }
        }

    }

    @include xSmall {
      color: #00a6ff;
      margin-left: 17px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 23px;
        height: 100%;
        pointer-events: none;
        right: 0;
        top: 0;
        background-image: linear-gradient(to right, rgba(9, 19, 32, 0), #091420);
      }

      &:first-child {
        color: #fff;

        &:before {
          content: none;
        }
      }

      .header__toppanel.open & {
        &:before {
          content: none;
        }
      }
    }

  }

  &__nav {
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    margin-left: 22px;

    @include Small {
      margin-left: 0;
    }

    @include xSmall {
      margin-left: 0;

      .header__toppanel.open & {
        display: none;
      }

    }
  }

  &__item {
    display: inline-block;
    text-decoration: none;
    vertical-align: top;
    position: relative;
    padding: 0 18px 0 16px;
    font-size: 15px;

    @include Small {
      padding: 0 18px 0 21px;
    }

    @include xSmall {
      padding: 0 18px 0 21px;
      &_vip {
        padding-left: 0;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -9px;
      left: 0;
      width: 1px;
      height: 40px;
      background-image: radial-gradient(circle at 50% 50%, #2b4460, #091420);

      @include xSmall {
        display: none;
      }
    }




    &_profile {
      padding: 0 16px 0 16px;

      @include Small {
        display: inline-block;
        padding-right: 0;
      }

      @include xSmall {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -9px;
        left: 0;
        @include sprite($nav-divider);

        @include Small {
          display: none;
        }
      }
    }

    &_balance {
      padding: 0 10px 0 21px;
    }

  }

  &__note {
    display: inline-block;
    vertical-align: top;
    position: relative;
    color: #46b8ff;
    font-size: 15px;
    letter-spacing: 0.17px;

    @include Small {

      padding-right: 13px;

      .user-toppanel__item_profile & {
        display: inline-block;
        line-height: 18px;
      }
      .user-toppanel__item_balance & {
        font-size: 14px;
        margin-left: 5px;
        line-height: 18px;
        &:first-of-type {
          display: none;
          }
      }
      .user-toppanel__item_vip & {
        font-size: 14px;
        margin-left: 5px;
        line-height: 18px;
        &:first-of-type {
          display: none;
        }
      }
    }

    @include xSmall {
      font-size: 14px;
      margin-left: 5px;

      .user-toppanel__item_balance & {
        font-size: 14px;
        margin-left: 5px;
        line-height: 18px;
        &:first-of-type {
          display: none;
        }
      }
      .user-toppanel__item_vip & {
        font-size: 14px;
        margin-left: 5px;
        line-height: 18px;
        &:first-of-type {
          display: none;
        }
      }
    }

    &_accent {
      color: #f1e419;
      padding-right: 0;
    }

    &_important {
      color: #FC5526;
      right: -15px;
    }

    &_white {
      color: #fff;
    }
  }

  &__icon {
    display: none;
    vertical-align: top;
    position: relative;
    width: 14px;
    height: 20px;
    fill: #fff;

    @include Small {
      display: inline-block;
    }

    @include xSmall {
      display: inline-block;
    }


    &_vip {
      width: 27px;
      height: 20px;
    }

    &_menu {
      display: block;
      position: absolute;
      width: 12px;
      background-color: #fff;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      height: 2px;

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 2px;
        left: 0;
        background-color: #fff;
      }

      &:before {
        top: -4px;
      }
      &:after {
        bottom: -4px;
      }

      .user-toppanel__button_close & {
        display: none;
      }

    }

    &_close {
      display: none;

      .user-toppanel__button_close & {
        display: block;
        position: absolute;
        top: 7px;
        left: 8px;
        height: 16px;
        width: 16px;
      }
    }
  }

  &__action {
    display: block;
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 15px;
    line-height: 15px;
    padding: 0 0 0 16px;
    text-decoration: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -14px;
      left: 0;
      width: 1px;
      height: 40px;
      background-image: radial-gradient(circle at 50% 50%, #2b4460, #091420);

      @include xSmall {
        display: none;
      }
    }

    .user-toppanel__note {
      right: 0;
      padding-right: 0;
    }

    @include Small {
      display: block;
    }

    @include xSmall {
      display: none;
    }
  }

  &__button {
    display: none;
    position: absolute;
    top: 10px;
    right: 19px;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-stretch: condensed;
    letter-spacing: -0.2px;
    color: #ffffff;
    padding: 6px 8px 6px 25px;
    outline: none;
    width: 64px;
    height: 29px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #2c85ca, #164278);
    box-shadow: inset 0 1px 0 0 #7ac7ff, inset 0 1px 6px 0 rgba(204, 242, 255, 0.5);


    &_close {
      text-indent: -4444px;
      width: 30px;
      height: 30px;
      right: 19px;
      top: 10px;
      padding: 0;
    }

    @include xSmall {
      display: block;
    }

  }

  &__item-confirm-btn, &__item-confirm-btn:hover{
    display: inline-block;
    position: absolute;
    font-size: 18px;
    text-shadow: 0 2px 0 rgba(0,0,0,.22);
    padding: 7px 22px;
    width: 140px;
    white-space: initial;
    line-height: 16px;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    top: -13px;
    background: transparent;
    border: 1px solid #ff6526;
    margin-top: 16px;
    @include Small{
      padding: 5px 12px;
      width: 120px;
    }
    @include xSmall{
      padding: 5px 12px;
      width: 120px;
    }
  }
}