.toppanel {
  display: block;
  position: relative;
  font-size: 0;
  padding: 19.5px 10px 19.5px 17px;
  font-weight: 700;
  font-family: 'ClearSans', sans-serif;
  white-space: nowrap;
  overflow: visible;

  @include xSmall{
    width: 100%!important;
  }

  &__title {
    color: $textcolor;
    display: none;
    vertical-align: top;
    font-size: 15px;
    line-height: 18px;

    @include xSmall {
      display: block;
    }

  }

  &__icon {
    display: none;
    vertical-align: top;
    position: relative;
    width: 14px;
    height: 20px;
    fill: #fff;

    @include xSmall {
      display: inline-block;
    }

    &_menu {
      display: block;
      position: absolute;
      width: 12px;
      background-color: #fff;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      height: 2px;

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 2px;
        left: 0;
        background-color: #fff;
      }

      &:before {
        top: -4px;
      }
      &:after {
        bottom: -4px;
      }

      .toppanel__button_close & {
        display: none;
      }

    }

    &_close {
      display: none;

      .toppanel__button_close & {
        display: block;
        position: absolute;
        top: 7px;
        left: 8px;
        height: 16px;
        width: 16px;
      }
    }
  }

  &__button {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-stretch: condensed;
    letter-spacing: -0.2px;
    color: #ffffff;
    padding: 6px 8px 6px 25px;
    outline: none;
    width: 64px;
    height: 29px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #2c85ca, #164278);
    box-shadow: inset 0 1px 0 0 #7ac7ff, inset 0 1px 6px 0 rgba(204, 242, 255, 0.5);

    @include xSmall {
      right: 19px;
    }

    &_close {
      text-indent: -4444px;
      width: 30px;
      height: 30px;
      right: 10px;
      top: 10px;
      padding: 0;
    }

    @include xSmall {
      display: block;
    }

  }

}