.alert-panel {
  background-color: #1f3756;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.08);
  padding: 18px 0;
  width:100%;

  &__inner {
    width: 1024px;
    margin: 0 auto;
    display: table;
  }

  &__cell {
    display: table-cell;
    width: 1px;
    vertical-align: middle;
    &_fluid {
      width: auto;
    }
  }

  &__note {
    white-space: nowrap;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__button {
    vertical-align: middle;
    border: 1px solid #435c77;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px 12px;
    margin-left: 17px;
  }
}