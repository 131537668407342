.lottery-info {
  display: block;
  position: relative;
  height: 354px;
  background-color: #3c5675;
  background-image: url('../img/timer-bg.png');
  background-repeat: no-repeat;
  background-position: center top;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 5px 0 rgba(0, 0, 0, 0.12);

  @include xSmall {
    height: auto;
  }

  &__title {
    font-size: 22px;
    padding-top: 45px;

    @include Small {
      padding: 27px 30px 27px;
    }

    &_important {
      padding-top: 66px;
      font-family: 'ClearSans', sans-serif;
      font-weight: 700;
      text-shadow: 0 3px 0 rgba(0, 0, 0, 0.23);
    }

    &_important + & {
      font-size: 36px;
      padding-top: 17px;
      padding-bottom: 58px;
      text-shadow: 0 3px 0 rgba(0, 0, 0, 0.23);
    }
  }

  &__timer {
    padding-top: 9px;
    padding-bottom: 40px;
  }

  &__info-title {
    padding: 35px 30px 27px;
    display: block;
    margin: 0;
    position: relative;

    @include Small {
      font-size:14px;
      padding: 25px 30px 27px;
    }

    @include xSmall {
      padding: 35px 20px 27px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 1px;
      background-color: #2d425b;
      box-shadow: 0 1px 0 #446184;
    }
  }

}