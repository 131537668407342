.scroller {
  display: none;
  z-index: 200;
  cursor: pointer;
  position: fixed;
  bottom: 20%;
  left: 2%;
  width: 43px;
  height: 50px;
  border-radius: 2px;
  box-shadow: inset 0 1px 6px 0 rgba(255, 255, 255, 0.32);
  border: solid 1px rgba(92, 92, 92, 0.4);
  text-align: center;
  &:hover {
    border: solid 1px #93d1ff;
  }

  &__icon {
    font-size: 21px;
    padding-top: 4px;
    color: #a8d1ff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }

  &__note {
    display: block;
    position: relative;
    font-family: 'ClearSans', sans-serif;
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    text-align: center;
    color: #a8d1ff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
}