@import '../../vendor/perfect-scrollbar/css/main.scss';

.ps-theme-tabs {
  @include ps-container(map-merge($ps-theme-default, (
      border-radius: 1px,
      scrollbar-y-rail-width: 8px,
      scrollbar-y-width: 8px,
      rail-default-opacity: 0.9,
      rail-container-hover-opacity: 1,
      bar-bg: #15141e,   // Основа для скролла
      bar-hover-bg: #4d4b63,
      bar-container-hover-bg: #4d4b63,  // сама скроллящаяся плашкаss
      rail-hover-bg: #15141e,
      scrollbar-y-right: 0,
      scrollbar-y-rail-right: 3px
  )));
}

.ps-theme-details {
   @include ps-container(map-merge($ps-theme-default, (
           border-radius: 3px,
           scrollbar-y-rail-width: 8px,
           scrollbar-y-width: 8px,
           rail-container-hover-opacity: 1,
           bar-bg: #1c2c3e,   // Основа для скролла
           bar-hover-bg: #2d82c3,
           bar-container-hover-bg: #2d82c3,  // сама скроллящаяся плашка
           rail-hover-bg: #1c2c3e,
           scrollbar-y-right: 0,
           scrollbar-y-rail-right: 3px
   )));
 }

.ps-hidden-details {
  @include ps-container(map-merge($ps-theme-default, (
          border-radius: 3px,
          scrollbar-y-rail-width: 8px,
          scrollbar-y-width: 8px,
          bar-bg: transparent,   // Основа для скролла
          bar-hover-bg: transparent,
          bar-container-hover-bg: transparent,  // сама скроллящаяся плашка
          rail-hover-bg: transparent,
          scrollbar-y-right: 0,
          scrollbar-x-rail: transparent !important,
          scrollbar-y-rail-right: 3px,
          rail-default-opacity: 0,
          rail-container-hover-opacity: 0,
          rail-hover-opacity: 0
  )));
}

.ps-theme-hidden >.ps-scrollbar-x-rail>.ps-scrollbar-x {
  background-color: transparent !important;
}