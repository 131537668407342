.hero-promo {
  display: block;
  position: relative;
  overflow: hidden;
  height: 484px;
  @include Large {
    height: 431px;
  }
  @include Medium {
    height: 370px;
  }
  @include Small {
    height: 230px;
  }
  //for mobile version without response

  @include xSmall {
    max-width: 480px;
    height: 230px;
    margin: 0 auto;
  }

  &__hero {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 483px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    &:before {
      width: 80%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 50%;
      right: 0;
      content: '';
      background: radial-gradient(ellipse at center, #05c0f6 0%, rgba(0, 210, 255, 0.46) 45%, rgba(79, 185, 242, 0) 82%, rgba(118, 173, 236, 0) 100%);
      border-radius: 100%;
      box-shadow: 0 3px 10px 0 #3094df;
      z-index: 2;
      transform: translateX(-50%);
    }
    &:after {
      width: 80%;
      height: 1px;
      position: absolute;
      top: 100%;
      left: 50%;
      right: 0;
      content: '';
      background: radial-gradient(ellipse at center, #05c0f6 0%, rgba(0, 210, 255, 0.46) 45%, rgba(79, 185, 242, 0) 82%, rgba(118, 173, 236, 0) 100%);
      border-radius: 100%;
      box-shadow: 0 -3px 10px 0 #3094df;
      transform: translateX(-50%);
    }



    @include Large {
      height: 430px;
      .hero-promo__img {
        height: 430px;
      }
    }

    @include Medium {

      height: 369px;
      .hero-promo__img {
        height: 369px;

      }
    }
    @include Small {
      height: 229px;
      .hero-promo__img {
        height: 229px;

      }
    }

    @include xSmall {
      height: 229px;
      .hero-promo__img {
        height: 229px;

      }
    }
  }
  &__item {
    position: absolute;
    padding-top: 1px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    &:before {
      content: '';
      left: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to left, transparent 0%, #08203a 100%);
    }
    &:after {
      content: '';
      right: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to right, transparent 0%, #08203a 100%);
    }
    @include xSmall {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
  &__item__mob {
    display: none;
    text-align: center;
    position: relative;
    &:before {
      content: '';
      left: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to left, transparent 0%, #08203a 100%);
    }
    &:after {
      content: '';
      right: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to right, transparent 0%, #08203a 100%);
    }
    @include xSmall {
      display: block;
    }
  }
  &__wrap {
    position: relative;
    transition: height 0.3s;
    margin-bottom: 61px;
    @include xSmall {
      margin-bottom: 32px;
    }

  }
  &__button {
    position: absolute;
    z-index: 1;
    width: 481px;
    height: 103px;
    background-color: #0c233c;
    border: 1px solid rgba(0, 175, 221, 0.9);
    border-radius: 4px;
    box-shadow: 0 1px 4px 3px rgba(0, 0, 0, 0.63);
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    margin-bottom: -51px;
    padding: 20px 10px 8px;
    text-align: center;
    @include Medium {
      width: 436px;
      height: 99px;
      margin-bottom: -49px;
    }
    @include Small {
      width: 288px;
      height: 78px;
      padding: 10px 10px 8px;
      margin-bottom: -39px;
    }
    @include xSmall {
      width: 178px;
      height: 48px;
      padding: 5px 5px 5px;
      margin-bottom: -24px;
    }
    &__title {
      color: #fff001;
      font-size: 30px;
      font-weight: 900;
      font-family: 'ClearSans', sans-serif;
      line-height: 24px;
      margin-bottom: 10px;
      @include Medium {
        font-size: 26px;
      }
      @include Small {
        font-size: 17px;
        margin-bottom: 5px;
      }
      @include xSmall {
        font-size: 10px;
        margin-bottom: 5px;
        line-height: 14px;
      }
    }
    &__subtitle {
      color: #fff001;
      font-size: 18px;
      font-weight: 900;
      line-height: 36px;
      font-family: 'ClearSans', sans-serif;
      text-transform: uppercase;
      @include Small {
        font-size: 14px;
        line-height: 24px;
      }
      @include xSmall {
        font-size: 10px;
        line-height: 16px;
      }
    }
  }

}
