.subnav {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 40px;
  background-color: #0f2947;
  border-radius: 0 0 3px 3px;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.04);

  &__item {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 3%;
    white-space: nowrap;

    &:after {
      content: '-';
      color: #fff;
      font-size: 14px;
      line-height: 41px;
      font-family: inherit;
      display: block;
      position: absolute;
      bottom: 0;
      left: 100%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);

      @include xSmall {
        line-height: 28px;
      }
    }
    &:last-child{
      &:after {
        content: none;
      }
    }
  }

  &__link {
    display: block;
    text-align: center;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    &_active {
      color: #f96915!important;
    }

    @include Small {
      font-size: 12px;
    }

    @include xSmall {
      font-size: 12px;
      padding: 4px 10px;
    }


    .subnav__item:hover & {
      color: #6eff00;

      &:after {
        color: #fff;
      }
    }

  }
}