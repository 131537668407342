.mobile-panel {
  display: none;
  position: relative;

  @include xSmall {
    display: block;
    z-index: 1;
  }

  &__button {
    display: block;
    width: 130px;
    height: 36px;
    padding: 10px 9px;
    font-size: 16px;
    font-family: 'ClearSans', sans-serif;
    font-weight: 400;
    line-height: 16px;
    outline: none;
    z-index: 2;
    min-width: 130px;
    box-shadow: 0 2px 0 rgba(0,0,0,.11);
    border-radius: 2px;

    &_blue {
      margin-top: 10px;
      background-image: linear-gradient(180deg,#2c85ca,#164278);
      box-shadow: inset 0 1px 0 0 #7ac7ff,inset 0 1px 6px 0 rgba(204,242,255,.5);

    }
  }

  &__action {
    display: block;
    position: absolute;
    top: 10px;
    right: 19px;
  }

  &__countpad.countpad {
    display: block;
    position: absolute;
    top: 48px;
    right: 0;
    width: 35px;
    height: 35px;

    .countpad__counter {
      @include xSmall {
        width: 19px;
        height: 19px;
        top: -7px;
        font-size: 13px;
      }
    }
  }

  &__cashier {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 9px 7px;
    width: 87px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

}