.summary {
  display: block;
  position: relative;
  font-size: 0;
  height: 400px;
  box-shadow: 0 10px 14px 3px rgba(11, 29, 53, 0.19), 0 10px 20px 1px rgba(11, 29, 53, 0.48);
  border-radius: 7px;
  margin-top: 30px;
  overflow: hidden;
  border-bottom: 1px solid #003366;
  @include Medium{
    height: 400px;
  }
  @include Small{
    height: auto;
  }

  @include xSmall {
    height: auto;
  }

  &__block {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: calc(50% - 1px);
    height: 400px;
    @include Medium{
      height: 400px;
    }

    @include Small{
      width: 100%;
      &:first-child{
        position: relative;
        top: 425px;
      }
      &:last-child{
        position: relative;
        top: -360px;
      }
    }

    @include xSmall {
      width: 100%;
      display: block;
      &:first-child{
        position: relative;
        top: 390px;
        height: 370px;
      }
      &:last-child{
        position: relative;
        top: -360px;
      }
    }

    .timer__table{
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__description {
    display: block;
    height: 100%;
    position: relative;
    background-color: #102448;
    padding-top: 20px;
    color: #7c9ddb;
  }

  &__description-inner {

  }

  &__content {
    display: block;
    position: relative;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    color: #7c9ddb;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    text-align: left;
    padding: 0 90px 0 0;
    height: 330px;
    margin: 0 20px 20px;
    overflow: auto;
    @include Medium{
      height: 360px;
    }

    @include Small {
      padding: 10px;
    }

    @include xSmall {
      padding: 0;
    }

    p:first-child {
      margin-top: 0;
    }
  }

  &__info {
    display: table;
    width: 100%;
    height: 100%;
    text-align: left;
    background: #20355c;
  }

  &__item {
    display: table;
    width: 100%;
    height: 40px;

    &:nth-child(2){
      background: #213a69;
    }
    &:nth-child(3){
      background: #25437a;
    }
    &:last-child{
      background: #274b8e;
    }

  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    padding: 0 22px;
    &:last-child {
      text-align: right;
    }
    .summary__description & {
      text-align: left;
    }
  }

  &__divider {
    display: table-row;
    height: 1px;
  }

  &__lottery-info {
    height: 354px;

    @include xSmall {
      height: auto;
    }
  }

  &__title {
    text-transform: inherit;

    &:last-child{
      text-transform: uppercase;
    }

    .summary__cell:first-child & {
      color: #8aadee;
      font-weight: 100;
    }

    @include Small {
      font-size: 16px;
    }

  }
  &__details{
    height: 280px;
    overflow: hidden;
    @include Medium{
      height: 278px;
    }
    @include xSmall{
      height: 270px;
    }
  }
  table{
    font-size: 15px;
    width: 100%;
    color: #8aadee;
    tr:last-child td{
      padding-top: 10px;
    }
    td {
      width: 50%;
      padding: 0 20px;
      @include Medium{
        padding: 0 10px;
      }
      @include xSmall{
        padding: 0 10px;
      }
      &:last-child {
        text-align: right;
      }
      &.countdown__timer{
        background: linear-gradient(to right, rgba(32,52,91,1) 0%, rgba(11,31,65,1) 100%);
        margin: 0;
        & > span{
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          @include Medium{
            font-size: 13px;
          }
        }

        .timer__row_digit .timer__cell{
          font-size: 25px;
          @include Medium{
            font-size: 20px;
          }
          @include xSmall{
            font-size: 18px;
          }
        }
      }
    }
    small{
      font-size: 15px;
      color: #fff;
      text-transform: lowercase;
      &.tournament-details__subtitle{
        padding-top: 10px;
      }
    }
  }
  .active,
  .future,
  .finished{
    &-tournament__status{
      font-size: 16px;
      padding-right: 0px;
      padding-top: 5px;
      padding-bottom: 15px;
      .tourn__status{
        display: inline-block;
        .stat{
          color: #8aadee;
          font-size: 0.95em;
        }
      }
      img{
        position: relative;
        top: 5px;
        right: 0px;
        width: 45px;
      }
    }
  }

  .active-tournament__status{
    color: #00ffbb;
  }
  .future-tournament__status{
    color: #ff9500;
  }
  .finished-tournament__status{
    color: #ff364b;
  }
  &__start-time,
  &__end-time{
      font-size: 22px;
      color: #fff;
      margin: 5px 0 0;
      font-weight: 100;
    @include Medium{
      font-size: 21px;
    }
  }
}