.wrap {
  margin: 0 auto;
  width: 940px;

  @include xLarge{
    width: 1420px;
  }

  @include Large{
    width: 1180px;
  }

  @include Small {
    width: 700px;
  }

  @include xSmall {
    width: 100%;
  }

}