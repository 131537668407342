.history {
  display: block;
  position: relative;
  max-height: 440px;

  &__filter {
    margin-top: 9px;
  }

  &__table {
    display: table;
    width: 600px;
    max-height: 440px;
    margin: 0 auto;
    border-collapse: collapse;
    @include Small {
      width: 400px;
    }
    @include xSmall {
      width: calc(100% - 40px);
    }
  }

  &__head {

  }

  &__body {
    max-height: 300px;
  }

  &__headrow {

  }

  &__row {

  }

  &__cell {
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 13px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 20px 0;
    border-bottom: 1px solid #232232;

    @include xSmall {
      padding: 20px 4px;
    }

    .history__head  & {
      font-size: 19px;
      padding-bottom: 32px;
    }

    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }

}