.head-panel {
  display: table;
  position: relative;
  font-family: 'ClearSans', sans-serif;
  font-size: 0;
  text-transform: uppercase;
  color: #fff;
  margin-left: 24px;
  margin-top: 13px;
  width: calc(100% - 24px);

  @include Small{
    margin-top: 4px;
  }

  &__cell {
    display: table-cell;
    vertical-align: middle;
    width: 1px;
    white-space: nowrap;

    @include Small {
      vertical-align: middle;
      width: auto;
    }

    @include xSmall {
      display: block;
      width: 130px;
    }

    &_fluid {
      width: auto;
      text-align: right;

      @include xSmall {
        display: block;
      }
    }
  }

  &__pointer {
    margin-top: -2px;
    margin-right: 3px;
    width: 145px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    background-image: url("../img/reg_and_get_bonus.png");
    background-repeat: no-repeat;
    background-size: 147px 54px;

    @include Small {
      background-image: url("../img/reg_and_get_bonus_small.png");
      background-size: 56px 43px; 
      width: 60px;
      height: 43px;
    }
  }

  &__signup {
    display: inline-block;
    vertical-align: top;
    position: relative;
    border-radius: 2px;
  }

  &__button {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: 24px;
    margin-right: 20px;
    line-height: 16px;
    padding: 13px 21px 14px 21px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #2c85ca, #164278);
    box-shadow: inset 0 1px 0 0 #7ac7ff, inset 0 1px 6px 0 rgba(204, 242, 255, 0.5);
    font-size: 16px;
    font-weight: 600;
    font-stretch: condensed;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);

    @include Small {
      padding: 10px 10px 10px;
      margin-left: 8px;
      margin-right: 10px;
    }
  }

  &__caption {
    display: inline-block;
    max-width: 50px;
    font-size: 16px;
    line-height: 22px;
    white-space: normal;

    @include Small {
      display: none;
    }
  }

  &__socials {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 21px;

    @include Small {
      margin-left: 8px;
    }

    @include Small {
      .socials__item {
        width: 31px;
        height: 31px;
        margin-right: 6px;
      }
    }
  }
}