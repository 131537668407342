.hero {
  display: block;
  position: relative;
  overflow: hidden;
  //for mobile version without response

  @include xSmall {
    max-width: 480px;
    margin: 0 auto;
  }

  &__slider {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 940px;
    height: 390px;
    display: block;
    overflow: hidden;
    margin: 0 auto;

    @include xLarge {
      width: 1420px;
      overflow: visible;
      .slick-list {
        overflow: visible;
      }
    }

    @include Large {
      width: 1180px;
      height: 324px;
    }

    @include Medium {
      height: 258px;
    }
    @include Small {
      width: 700px;
      height: 192px;
    }

    @include xSmall {
      height: auto;
      width: 100%;
      position: absolute;
    }
  }

  &__wrap {
    position: relative;
    min-height: 450px;
    transition: height 0.3s;

    @include Large {
      min-height: 382px;
    }

    @include Medium {
      min-height: 318px;
    }

    @include Small {
      min-height: 250px;
    }

    @include xSmall {
      min-height: 300px;
      width: 100%!important;
    }

    &__tournament{
      @include xSmall {
        min-height: 205px;
      }
    }

    &_subnav-open {
      height: 332px;
    }

  }

  &__nav {
    z-index: 2;
    display: block;
    position: absolute;
    text-align: left;
    width: 100%;
    top: 390px;
    left: 0;
    right: 0;

    @include Large {
      top: 324px;
    }

    @include Medium {
      top: 258px;
    }
    @include Small {
      top: 192px;
    }

    @include xSmall {
      top: 250px;

    }
    &__tournament{
      @include xSmall {
        top: 150px;
  
      }
    }
    &_sticky {
      position: fixed;
      top: 0;
      width: 940px;
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 10;

      @include xLarge {
        width: 1420px;
      }

      @include Large {
        width: 1180px;
      }

      @include Small {
        width: 700px;
      }

      @include xSmall {
        display: none;
      }
    }
  }

  &__subnav {
    display: block;
    text-align: center;
    background-color: #365477;
    height:0;
    transition: height 0.1s;

    @include xSmall {
      display: none;
    }

    .nav__link {
      padding: 14px 28px;

      @include Small {
        padding: 14px 21px;
      }
    }


    .hero__nav_sticky & {
      height: 44px;

    }

  }
  .hero__nav_scroll {
    &:after {
      display: block;
      background: linear-gradient(to right, rgba(15, 41, 71, 0.01) 0%, rgba(15, 41, 71, 1) 70%, rgba(15, 41, 71, 1) 100%);
      box-shadow: 0 4px 0 0 #17386d, inset 0 1px 0 0 #4d98ce, inset 0 -1px 0 0 rgba(250, 250, 250, 0.1);
      width: 35px;
      height: 100%;
      bottom: 0;
      right: 0;
      content: '';
      position: absolute;
      z-index: 2;
    }
  }



  &__counter {
    display: none;
    z-index: 2;
    width: 347px;
    height: 121px;
    position: absolute;
    top: 0;
    right: -12px;
    padding: 41px 30px 0 30px;
    border-radius: 4px;
    background-image: url('../img/counter-bg.png');
    background-repeat: no-repeat;
    background-position: center;

    @include background-2x( '../img/counter-bg', 'png', 347px, 121px, top center, no-repeat );

    @include xSmall {
      background-image: url('../img/counter-bg-small.png');
      width: 300px;
      height: 114px;
      right: 0;
      margin: 0 auto;
      position: relative;
      padding: 0;
      background-position: 320px 124px;

      @include background-2x( '../img/counter-bg-small', 'png', 320px, 124px, top center, no-repeat );
    }
  }

  &__countdown {
    top: 78px;
    position: absolute;
    left: 17px;

    @include xSmall {
      left: 0;
      right: 0;
      top: 75px;
      text-align: center;
    }
  }

  &__countnote {
    text-shadow: 0 2px 0 rgba(12, 12, 12, 0.18);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    float: left;

    @include xSmall {
      padding: 41px 0 0 12px;
      font-size: 18px;
    }
  }

  &__countbutton {
    float: right;
    margin-top: 2px;
    background-color: #185e9c;
    font-size: 12px;
    line-height: 20px;
    text-shadow: none;
    text-align: center;
    padding: 0;
    font-family: 'ClearSans',sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    width: 74px;
    height: 21px;
    border-radius: 1px;
    background-image: linear-gradient(to bottom, #2b85c9, #193a62);
    box-shadow: 0 -1px 0 0 #79c6ff, 0 2px 0 0 #152f50, inset 0 1px 7px 0 rgba(255, 255, 255, 0.3);

    @include xSmall {
      margin: 43px 10px 0 0;
    }
  }

}