.bottom-panel {
  width: 495px;
  bottom: 0;
  right: 0;
  height: auto;
  display: block;
  position: fixed;
  z-index: 200;
  background-color: #132e4d;
  border-radius: 2px;
  box-shadow: inset 0 -1px 0 rgba(91, 64, 71, 0.38), 0 1px 8px rgba(0, 0, 0, 0.53);

  @include xSmall {
    width: 100%;
  }

  &__close {
    width: 44px;
    height: 38px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #132e4d;
    border-radius: 2px;
    box-shadow: inset 0.6px -0.8px 0 #1e3550;
    text-align: center;
    padding: 11px 0;
    cursor: pointer;
  }

  &__title {
    color: #fff000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    text-transform: none;
    padding: 18px 44px;

    @include xSmall {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__subtitle {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    text-align: center;
    padding: 0 40px;
  }

  &__content {
    text-align: center;
    margin-top: 18px;
  }

  &__input {
    height: 40px;

    .input__inner {
      height: 40px;
      width: 192px;
      color: #e5e2f3;
    }
  }

  &__button {
    min-width: 70px;
    height: 40px;
    margin-top: 0;
  }

  &__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 288px;
  }

  &__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
  }

  &__bonus-note {
    display: block;
    vertical-align: top;
    color: #ccc;
    font-size: 11px;
    font-weight: 400;
    padding-left: 30px;
  }

}