@charset "UTF-8";
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

*, *:before, *:after {
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

template,
[hidden] {
  display: none; }

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
  cursor: pointer; }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/* Forms
   ========================================================================== */
/**
 * 1. Change font properties to `inherit` in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Restore the font weight unset by the previous rule.
 */
optgroup {
  font-weight: bold; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: none;
  margin: 0 auto; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* break points*/
/****************************
 HIGH PPI DISPLAY BACKGROUNDS
*****************************/
.icon {
  display: inline-block;
  vertical-align: top; }
  .icon_facebook {
    background-image: url(../img/sprite.png?v=2);
    background-position: -980px -66px;
    width: 11px;
    height: 24px; }
  .icon_twitter {
    background-image: url(../img/sprite.png?v=2);
    background-position: -666px -778px;
    width: 22px;
    height: 20px; }
  .icon_google {
    background-image: url(../img/sprite.png?v=2);
    background-position: -614px -778px;
    width: 22px;
    height: 22px; }
  .icon_vk, .icon_vkontakte {
    background-image: url(../img/sprite.png?v=2);
    background-position: -818px -778px;
    width: 24px;
    height: 16px; }
  .icon_ok, .icon_odnoklassniki {
    background-image: url(../img/sprite.png?v=2);
    background-position: -977px -569px;
    width: 13px;
    height: 25px; }
  .icon_facebook-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -858px -248px;
    width: 9px;
    height: 20px; }
  .icon_twitter-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -625px -843px;
    width: 18px;
    height: 17px; }
  .icon_google-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -577px -843px;
    width: 18px;
    height: 18px; }
  .icon_vk-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -673px -843px;
    width: 20px;
    height: 14px; }
  .icon_ok-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -980px -258px;
    width: 11px;
    height: 21px; }
  .icon_counter {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -174px;
    width: 32px;
    height: 44px; }
  .icon_heart {
    background-image: url(../img/sprite.png?v=2);
    background-position: -479px -843px;
    width: 20px;
    height: 18px; }
  .icon_cup {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -569px;
    width: 48px;
    height: 54px; }
  .icon_info {
    background-image: url(../img/sprite.png?v=2);
    background-position: -980px -309px;
    width: 7px;
    height: 15px; }
  .icon_info-light {
    background-image: url(../img/sprite.png?v=2);
    background-position: -463px -208px;
    width: 4px;
    height: 13px; }
  .icon_visa {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -731px;
    width: 36px;
    height: 11px; }
  .icon_mastercard {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -316px;
    width: 76px;
    height: 12px; }
  .icon_qiwi {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -711px;
    width: 59px;
    height: 24px; }
  .icon_yandex {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -765px;
    width: 65px;
    height: 19px; }
  .icon_webmoney {
    background-image: url(../img/sprite.png?v=2);
    background-position: -269px -778px;
    width: 101px;
    height: 24px; }
  .icon_moneta {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -448px;
    width: 86px;
    height: 33px; }
  .icon_wallet {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -553px;
    width: 33px;
    height: 28px; }
  .icon_sberbank {
    background-image: url(../img/sprite.png?v=2);
    background-position: -129px -778px;
    width: 110px;
    height: 27px; }
  .icon_alfabank {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -653px;
    width: 78px;
    height: 28px; }
  .icon_promsvyazbank {
    background-image: url(../img/sprite.png?v=2);
    background-position: -589px -678px;
    width: 128px;
    height: 21px; }
  .icon_gift {
    background-image: url(../img/sprite.png?v=2);
    background-position: -429px -843px;
    width: 20px;
    height: 23px; }
  .icon_medal {
    background-image: url(../img/sprite.png?v=2);
    background-position: -109px -843px;
    width: 22px;
    height: 31px; }
  .icon_spinner {
    background-image: url(../img/sprite.png?v=2);
    background-position: -688px -612px;
    width: 27px;
    height: 29px; }
  .icon_star {
    background-image: url(../img/sprite.png?v=2);
    background-position: -747px -678px;
    width: 12px;
    height: 11px; }
  .icon_starhalf {
    background-image: url(../img/sprite.png?v=2);
    background-position: -980px -120px;
    width: 11px;
    height: 10px; }
  .icon_starempty {
    background-image: url(../img/sprite.png?v=2);
    background-position: -745px -612px;
    width: 12px;
    height: 11px; }
  .icon_nav-divider {
    background-image: url(../img/sprite.png?v=2);
    background-position: -980px -162px;
    width: 1px;
    height: 39px; }
  .icon_cancel {
    background-image: url(../img/sprite.png?v=2);
    background-position: 0px -904px;
    width: 18px;
    height: 19px; }
  .icon_cross {
    width: 16px;
    height: 16px;
    position: relative;
    display: block; }
    .icon_cross:before {
      content: '';
      width: 2px;
      height: 18px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: 9px;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
    .icon_cross:after {
      content: '';
      width: 2px;
      height: 18px;
      background-color: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: 9px;
      transform: rotate(-45deg); }
  .icon_cross-bold {
    background-image: url(../img/sprite.png?v=2);
    background-position: -854px -174px;
    width: 15px;
    height: 15px; }
  .icon_cross-bold_grey {
    background-image: url(../img/sprite.png?v=2);
    background-position: -853px -424px;
    width: 15px;
    height: 15px; }
  .icon_checkmark {
    background-image: url(../img/sprite.png?v=2);
    background-position: -855px -553px;
    width: 13px;
    height: 13px; }
  .icon_clock {
    background-image: url(../img/sprite.png?v=2);
    background-position: -768px -778px;
    width: 20px;
    height: 20px; }
  .icon_visa-logo {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -511px;
    width: 92px;
    height: 28px; }
  .icon_qiwi-logo {
    background-image: url(../img/sprite.png?v=2);
    background-position: -620px -358px;
    width: 123px;
    height: 49px; }
  .icon_yandex-logo {
    background-image: url(../img/sprite.png?v=2);
    background-position: -620px -275px;
    width: 116px;
    height: 53px; }
  .icon-bitcoin {
    background-image: url(../img/sprite.png?v=2);
    background-position: -516px -612px;
    width: 142px;
    height: 30px; }
  .icon-comepay {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px 0px;
    width: 77px;
    height: 70px; }
  .icon-contact {
    background-image: url(../img/sprite.png?v=2);
    background-position: -252px -378px;
    width: 110px;
    height: 70px; }
  .icon-mobiles {
    background-image: url(../img/sprite.png?v=2);
    background-position: -620px 0px;
    width: 142px;
    height: 47px; }
  .icon-moneyru {
    background-image: url(../img/sprite.png?v=2);
    background-position: -371px -490px;
    width: 142px;
    height: 48px; }
  .icon_okpay_rub {
    background-image: url(../img/sprite.png?v=2);
    background-position: -172px -612px;
    width: 142px;
    height: 34px; }
  .icon_payeer_rub {
    background-image: url(../img/sprite.png?v=2);
    background-position: -344px -612px;
    width: 142px;
    height: 31px; }
  .icon_perfectmoney_rub {
    background-image: url(../img/sprite.png?v=2);
    background-position: -417px -678px;
    width: 142px;
    height: 29px; }
  .icon_card_rub {
    background-image: url(../img/sprite.png?v=2);
    background-position: -620px -77px;
    width: 142px;
    height: 46px; }
  .icon_qiwi_rub {
    background-image: url(../img/sprite.png?v=2);
    background-position: -112px -678px;
    width: 111px;
    height: 48px; }
  .icon_yamoney_rub {
    background-image: url(../img/sprite.png?v=2);
    background-position: -620px -514px;
    width: 142px;
    height: 36px; }
  .icon-webmoney {
    background-image: url(../img/sprite.png?v=2);
    background-position: 0px -612px;
    width: 142px;
    height: 36px; }
  .icon_medal-gold {
    background-image: url(../img/sprite.png?v=2);
    background-position: -57px -843px;
    width: 22px;
    height: 31px; }
  .icon_medal-gold-huge {
    background-image: url(../img/sprite.png?v=2);
    background-position: -620px -153px;
    width: 70px;
    height: 92px; }
  .icon_medal-gold-large {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -258px;
    width: 51px;
    height: 66px; }
  .icon_medal-silver-large {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -162px;
    width: 51px;
    height: 66px; }
  .icon_medal-bronze-large {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -66px;
    width: 51px;
    height: 66px; }
  .icon_arrow-right {
    background-image: url(../img/sprite.png?v=2);
    background-position: -452px -378px;
    width: 14px;
    height: 10px; }
  .icon_arrow-right-white {
    background-image: url(../img/sprite.png?v=2);
    background-position: -852px -671px;
    width: 14px;
    height: 12px; }
  .icon_arrow-down-white {
    background-image: url(../img/sprite.png?v=2);
    background-position: -434px -438px;
    width: 12px;
    height: 8px; }
  .icon_arrow-up-white {
    background-image: url(../img/sprite.png?v=2);
    background-position: -392px -438px;
    width: 12px;
    height: 8px; }
  .icon_counter-bg {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -354px;
    width: 50px;
    height: 64px; }
  .icon_counter-bg-dark {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -100px;
    width: 32px;
    height: 44px; }
  .icon_bonus-small-1 {
    background-image: url(../img/sprite.png?v=2);
    background-position: -252px -208px;
    width: 181px;
    height: 140px; }
  .icon_bonus-tiny-1 {
    background-image: url(../img/sprite.png?v=2);
    background-position: -244px -490px;
    width: 97px;
    height: 83px; }
  .icon_bonus-4 {
    background-image: url(../img/sprite.png?v=2);
    background-position: -252px 0px;
    width: 216px;
    height: 178px; }
  .icon_search {
    background-image: url(../img/sprite.png?v=2);
    background-position: -273px -843px;
    width: 24px;
    height: 25px; }
  .icon_exit {
    background-image: url(../img/sprite.png?v=2);
    background-position: -562px -778px;
    width: 22px;
    height: 22px; }
  .icon_purse {
    background-image: url(../img/sprite.png?v=2);
    background-position: -327px -843px;
    width: 22px;
    height: 24px; }
  .icon_star-white {
    background-image: url(../img/sprite.png?v=2);
    background-position: -454px -778px;
    width: 24px;
    height: 23px; }
  .icon_arrow-down {
    background-image: url(../img/sprite.png?v=2);
    background-position: -852px -611px;
    width: 16px;
    height: 11px; }
  .icon_gamepad {
    background-image: url(../img/sprite.png?v=2);
    background-position: 0px -244px;
    width: 216px;
    height: 216px; }
  .icon_safebox {
    background-image: url(../img/sprite.png?v=2);
    background-position: 0px 0px;
    width: 222px;
    height: 214px; }
  .icon_star-big {
    background-image: url(../img/sprite.png?v=2);
    background-position: -161px -843px;
    width: 26px;
    height: 25px; }
  .icon_star-big-empty {
    background-image: url(../img/sprite.png?v=2);
    background-position: -217px -843px;
    width: 26px;
    height: 25px; }
  .icon_info-outline {
    background-image: url(../img/sprite.png?v=2);
    background-position: -718px -778px;
    width: 20px;
    height: 20px; }
  .icon_volume {
    background-image: url(../img/sprite.png?v=2);
    background-position: -529px -843px;
    width: 18px;
    height: 18px; }
  .icon_play {
    background-image: url(../img/sprite.png?v=2);
    background-position: -852px -490px;
    width: 17px;
    height: 21px; }
  .icon_shuffle {
    background-image: url(../img/sprite.png?v=2);
    background-position: -508px -778px;
    width: 24px;
    height: 22px; }
  .icon_ruble-sign {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -248px;
    width: 36px;
    height: 38px; }
  .icon_vip-1-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -671px;
    width: 30px;
    height: 30px; }
  .icon_vip-2-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -392px -378px;
    width: 30px;
    height: 30px; }
  .icon_vip-3-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -543px -490px;
    width: 30px;
    height: 30px; }
  .icon_vip-4-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -720px -153px;
    width: 30px;
    height: 30px; }
  .icon_vip-5-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -611px;
    width: 30px;
    height: 30px; }
  .icon_vip-6-small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -720px -213px;
    width: 30px;
    height: 30px; }
  .icon_vip-1-medium {
    background-image: url(../img/sprite.png?v=2);
    background-position: -498px -122px;
    width: 92px;
    height: 92px; }
  .icon_vip-2-medium {
    background-image: url(../img/sprite.png?v=2);
    background-position: -498px 0px;
    width: 92px;
    height: 92px; }
  .icon_vip-3-medium {
    background-image: url(../img/sprite.png?v=2);
    background-position: 0px -490px;
    width: 92px;
    height: 92px; }
  .icon_vip-4-medium {
    background-image: url(../img/sprite.png?v=2);
    background-position: -122px -490px;
    width: 92px;
    height: 92px; }
  .icon_vip-5-medium {
    background-image: url(../img/sprite.png?v=2);
    background-position: -498px -366px;
    width: 92px;
    height: 92px; }
  .icon_vip-6-medium {
    background-image: url(../img/sprite.png?v=2);
    background-position: -498px -244px;
    width: 92px;
    height: 92px; }
  .icon_18 {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -358px;
    width: 36px;
    height: 36px; }
  .icon_curagao {
    background-image: url(../img/sprite.png?v=2);
    background-position: -792px -424px;
    width: 31px;
    height: 36px; }
  .icon_ecorga {
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px 0px;
    width: 92px;
    height: 36px; }
  .icon_microgaming {
    background-image: url(../img/sprite.png?v=2);
    background-position: -253px -678px;
    width: 134px;
    height: 33px; }
  .icon_netent {
    background-image: url(../img/sprite.png?v=2);
    background-position: 0px -778px;
    width: 99px;
    height: 35px; }
  .icon_lock_small {
    background-image: url(../img/sprite.png?v=2);
    background-position: -379px -843px;
    width: 20px;
    height: 24px; }
  .icon_popup-panel_toggle {
    background-image: url(../img/sprite.png?v=2);
    background-position: -979px -354px;
    width: 11px;
    height: 18px; }

svg {
  pointer-events: none; }

.svg-gift {
  width: 20px;
  height: 20px;
  fill: #fff; }

.svg-star {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  fill: #ffea00; }
  .svg-star .esvg-star-shadow {
    fill: #000; }
  .svg-star_disabled {
    fill: #08203a;
    stroke: #15355A;
    stroke-width: 1px;
    opacity: 0.3; }

.svg-cancel {
  fill: #fff; }

.svg__close {
  width: 17px;
  height: 17px;
  fill: rgba(255, 255, 255, 0.3);
  transition: fill 0.3s ease; }
  .svg__close:hover {
    fill: #fff; }

.svg-yandex {
  width: 142px;
  height: 36px; }

.svg-visa-mastercard {
  width: 142px;
  height: 46px; }

.svg-webmoney {
  width: 142px;
  height: 36px; }

.svg-qiwi {
  width: 112px;
  height: 48px; }

.svg-walletone {
  width: 82px;
  height: 70px; }

.svg-okpay {
  width: 142px;
  height: 34px; }

.svg-payeer {
  width: 142px;
  height: 30px; }

.svg-perfect-money {
  width: 142px;
  height: 30px; }

.svg-comepay {
  width: 77px;
  height: 70px; }

.svg-bitcoin {
  width: 142px;
  height: 32px; }

.svg-contact {
  width: 110px;
  height: 70px; }

.svg-moneyru {
  width: 142px;
  height: 47px; }

.svg-terminal {
  width: 142px;
  height: 48px; }

.svg-card_rub {
  width: 142px;
  height: 48px; }

.svg-money {
  width: 27px;
  height: 18px; }

.svg_vkontakte {
  width: 23px;
  height: 13px; }

.svg_odnoklassniki {
  width: 14px;
  height: 23px; }

.svg_twitter {
  width: 20px;
  height: 17px; }

.svg_telegram {
  width: 22px;
  height: 25px;
  fill: #fff; }

.svg_facebook {
  width: 12px;
  height: 25px; }

.svg_mailru {
  width: 24px;
  height: 24px;
  fill: #fff; }

.svg-arrow-left-dims {
  width: 12px;
  height: 20px; }

.svg-arrow-right-dims {
  width: 12px;
  height: 20px; }

.svg-bitcoin-dims {
  width: 306.5px;
  height: 64.001px; }

.svg-cancel-dims {
  width: 16px;
  height: 16px; }

.svg-card_rub-dims {
  width: 142px;
  height: 46px; }

.svg-close-dims {
  width: 18px;
  height: 18px; }

.svg-comepay-dims {
  width: 77px;
  height: 70px; }

.svg-contact-dims {
  width: 110px;
  height: 70px; }

.svg-counter-dims {
  width: 40px;
  height: 49px; }

.svg-facebook-dims {
  width: 12px;
  height: 25px; }

.svg-game-hall-dims {
  width: 23px;
  height: 18px; }

.svg-gift-dims {
  width: 126px;
  height: 110px; }

.svg-lottery-dims {
  width: 20px;
  height: 24px; }

.svg-lottery2-dims {
  width: 20px;
  height: 24px; }

.svg-mailru-dims {
  width: 1000px;
  height: 1000px; }

.svg-mobile-dims {
  width: 142px;
  height: 47px; }

.svg-money-dims {
  width: 27px;
  height: 18px; }

.svg-moneyru-dims {
  width: 142px;
  height: 48px; }

.svg-odnoklassniki-dims {
  width: 14px;
  height: 23px; }

.svg-okpay_rub-dims {
  width: 142px;
  height: 34px; }

.svg-payeer_rub-dims {
  width: 142px;
  height: 31px; }

.svg-perfectmoney_usd-dims {
  width: 142px;
  height: 29px; }

.svg-profile-dims {
  width: 24px;
  height: 24px; }

.svg-promo-dims {
  width: 23px;
  height: 24px; }

.svg-qiwi_rub-dims {
  width: 111px;
  height: 48px; }

.svg-ruble-dims {
  width: 13px;
  height: 18px; }

.svg-slider-left-transp-dims {
  width: 63px;
  height: 88px; }

.svg-slider-right-transp-dims {
  width: 63px;
  height: 88px; }

.svg-star-dims {
  width: 20px;
  height: 18px; }

.svg-star-filled-dims {
  width: 20px;
  height: 18px; }

.svg-telegram-dims {
  width: 300px;
  height: 300px; }

.svg-terminal-dims {
  width: 123px;
  height: 47px; }

.svg-tournament-dims {
  width: 20px;
  height: 24px; }

.svg-twitter-dims {
  width: 20px;
  height: 17px; }

.svg-vip-dims {
  width: 25px;
  height: 17px; }

.svg-vip-level-dims {
  width: 24px;
  height: 24px; }

.svg-vip-points-dims {
  width: 24px;
  height: 24px; }

.svg-vkontakte-dims {
  width: 23px;
  height: 13px; }

.svg-walletone-dims {
  width: 82px;
  height: 70px; }

.svg-webmoney-dims {
  width: 142px;
  height: 36px; }

.svg-wheel-of-fortune-dims {
  width: 22px;
  height: 24px; }

.svg-yamoney_rub-dims {
  width: 142px;
  height: 36px; }

.ps-container {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-container {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-container {
      overflow: auto !important; } }
  .ps-container.ps-active-x > .ps-scrollbar-x-rail,
  .ps-container.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent; }
  .ps-container.ps-in-scrolling {
    pointer-events: none; }
    .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .ps-container > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 4px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-container > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 4px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 3px;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 8px; }
    .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 4px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 8px; }
  .ps-container:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #999; }
    .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; }
      .ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #999; }
  .ps-container:hover > .ps-scrollbar-x-rail,
  .ps-container:hover > .ps-scrollbar-y-rail {
    opacity: 0.6; }
  .ps-container:hover > .ps-scrollbar-x-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: #999; }
  .ps-container:hover > .ps-scrollbar-y-rail:hover {
    background-color: #eee;
    opacity: 0.9; }
    .ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: #999; }

.ps-theme-tabs {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-theme-tabs {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-theme-tabs {
      overflow: auto !important; } }
  .ps-theme-tabs.ps-active-x > .ps-scrollbar-x-rail,
  .ps-theme-tabs.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: #15141e; }
  .ps-theme-tabs.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-tabs.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #15141e;
      opacity: 0.9; }
      .ps-theme-tabs.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #4d4b63; }
    .ps-theme-tabs.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #15141e;
      opacity: 0.9; }
      .ps-theme-tabs.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #4d4b63; }
  .ps-theme-tabs > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 1px;
    opacity: 0.9;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-theme-tabs > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: #4d4b63;
      border-radius: 1px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-theme-tabs > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 1px;
    opacity: 0.9;
    transition: background-color .2s linear, opacity .2s linear;
    right: 3px;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 8px; }
    .ps-theme-tabs > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: #4d4b63;
      border-radius: 1px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 8px; }
  .ps-theme-tabs:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-tabs:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #15141e;
      opacity: 0.9; }
      .ps-theme-tabs:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #4d4b63; }
    .ps-theme-tabs:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #15141e;
      opacity: 0.9; }
      .ps-theme-tabs:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #4d4b63; }
  .ps-theme-tabs:hover > .ps-scrollbar-x-rail,
  .ps-theme-tabs:hover > .ps-scrollbar-y-rail {
    opacity: 1; }
  .ps-theme-tabs:hover > .ps-scrollbar-x-rail:hover {
    background-color: #15141e;
    opacity: 0.9; }
    .ps-theme-tabs:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: #4d4b63; }
  .ps-theme-tabs:hover > .ps-scrollbar-y-rail:hover {
    background-color: #15141e;
    opacity: 0.9; }
    .ps-theme-tabs:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: #4d4b63; }

.ps-theme-details {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-theme-details {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-theme-details {
      overflow: auto !important; } }
  .ps-theme-details.ps-active-x > .ps-scrollbar-x-rail,
  .ps-theme-details.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: #1c2c3e; }
  .ps-theme-details.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-details.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #1c2c3e;
      opacity: 0.9; }
      .ps-theme-details.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #2d82c3; }
    .ps-theme-details.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #1c2c3e;
      opacity: 0.9; }
      .ps-theme-details.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #2d82c3; }
  .ps-theme-details > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 3px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-theme-details > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: #2d82c3;
      border-radius: 3px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-theme-details > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 3px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 3px;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 8px; }
    .ps-theme-details > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: #2d82c3;
      border-radius: 3px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 8px; }
  .ps-theme-details:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-theme-details:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: #1c2c3e;
      opacity: 0.9; }
      .ps-theme-details:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: #2d82c3; }
    .ps-theme-details:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: #1c2c3e;
      opacity: 0.9; }
      .ps-theme-details:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: #2d82c3; }
  .ps-theme-details:hover > .ps-scrollbar-x-rail,
  .ps-theme-details:hover > .ps-scrollbar-y-rail {
    opacity: 1; }
  .ps-theme-details:hover > .ps-scrollbar-x-rail:hover {
    background-color: #1c2c3e;
    opacity: 0.9; }
    .ps-theme-details:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: #2d82c3; }
  .ps-theme-details:hover > .ps-scrollbar-y-rail:hover {
    background-color: #1c2c3e;
    opacity: 0.9; }
    .ps-theme-details:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: #2d82c3; }

.ps-hidden-details {
  -ms-touch-action: none;
  touch-action: none;
  overflow: hidden !important;
  -ms-overflow-style: none; }
  @supports (-ms-overflow-style: none) {
    .ps-hidden-details {
      overflow: auto !important; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-hidden-details {
      overflow: auto !important; } }
  .ps-hidden-details.ps-active-x > .ps-scrollbar-x-rail,
  .ps-hidden-details.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent; }
  .ps-hidden-details.ps-in-scrolling {
    pointer-events: none; }
    .ps-hidden-details.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: transparent;
      opacity: 0; }
      .ps-hidden-details.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: transparent; }
    .ps-hidden-details.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: transparent;
      opacity: 0; }
      .ps-hidden-details.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: transparent; }
  .ps-hidden-details > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 3px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 8px; }
    .ps-hidden-details > .ps-scrollbar-x-rail > .ps-scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: transparent;
      border-radius: 3px;
      transition: background-color .2s linear;
      bottom: 0;
      /* there must be 'bottom' for ps-scrollbar-x */
      height: 8px; }
  .ps-hidden-details > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    border-radius: 3px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 3px;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 8px; }
    .ps-hidden-details > .ps-scrollbar-y-rail > .ps-scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: transparent;
      border-radius: 3px;
      transition: background-color .2s linear;
      right: 0;
      /* there must be 'right' for ps-scrollbar-y */
      width: 8px; }
  .ps-hidden-details:hover.ps-in-scrolling {
    pointer-events: none; }
    .ps-hidden-details:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
      background-color: transparent;
      opacity: 0; }
      .ps-hidden-details:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
        background-color: transparent; }
    .ps-hidden-details:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
      background-color: transparent;
      opacity: 0; }
      .ps-hidden-details:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
        background-color: transparent; }
  .ps-hidden-details:hover > .ps-scrollbar-x-rail,
  .ps-hidden-details:hover > .ps-scrollbar-y-rail {
    opacity: 0; }
  .ps-hidden-details:hover > .ps-scrollbar-x-rail:hover {
    background-color: transparent;
    opacity: 0; }
    .ps-hidden-details:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
      background-color: transparent; }
  .ps-hidden-details:hover > .ps-scrollbar-y-rail:hover {
    background-color: transparent;
    opacity: 0; }
    .ps-hidden-details:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
      background-color: transparent; }

.ps-theme-hidden > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: transparent !important; }

.title {
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  font-family: 'ClearSans', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  color: #fff;
  margin: 0; }
  .title_color_accent {
    color: #fff000; }
  .title_color_important {
    color: #ff1e00; }
  .title_font_hugest {
    font-size: 30px;
    font-weight: 500;
    font-family: 'ClearSans', sans-serif; }
  .title_font_huge {
    font-size: 30px; }
  .title_font_largest {
    font-size: 23px; }
  .title_font_large {
    font-size: 19px; }
  .title_font_smaller {
    font-size: 15px; }
  .title_font_smallest {
    font-size: 14px; }
  .title_family_base {
    font-family: 'ClearSans', sans-serif; }
  .title_align_center {
    text-align: center; }

.button {
  display: inline-block;
  vertical-align: top;
  position: relative;
  text-align: center;
  cursor: pointer;
  padding: 11px 21px;
  background-color: #185e9c;
  background-image: linear-gradient(180deg, #1b87d5 0%, #163464 100%);
  box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 #7ac7ff, 0 2px 0 rgba(0, 0, 0, 0.11);
  border: none;
  border-radius: 3px;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.22);
  color: #fff;
  font-family: 'ClearSans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  transition: box-shadow 0.2s ease; }
  .button:hover {
    box-shadow: 0 0 11px 0 #355b8a, inset 0 1px 6px 0 rgba(255, 255, 255, 0.32); }
  .button:active {
    color: #fff; }

.button_font_cond {
  font-family: 'ClearSans', sans-serif;
  font-weight: 600; }

.button_small {
  text-shadow: 0 2px 3px rgba(6, 23, 42, 0.65);
  font-family: 'ClearSans', sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  padding: 1px 5px; }

.button_shape_round {
  padding: 9px 20px 8px;
  background-color: #e2d533;
  background-image: linear-gradient(180deg, #ff8d00, #e2d533);
  text-shadow: 1px 1px 4px #3a0404;
  border-radius: 17px;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.34), 0 3px 2px rgba(4, 4, 4, 0.26);
  display: inline-block;
  font-family: 'ClearSans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase; }
  .button_shape_round:hover {
    background-color: #c6b91c;
    background-image: linear-gradient(180deg, #cc7100 0%, #c6b91c 100%);
    box-shadow: none; }

.button_color_orange {
  background-color: #e2d533;
  background-image: linear-gradient(180deg, #ffb11a 0%, #e2d533 100%);
  text-shadow: 1px 1px 4px #3a0404;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 3px 6px #040404; }
  .button_color_orange:hover {
    background-color: #c6b91c;
    background-image: linear-gradient(180deg, #cc7100 0%, #c6b91c 100%);
    box-shadow: none; }

.button_color_dark {
  background-color: #000000;
  background-image: linear-gradient(140deg, #000000 0%, #000000 100%);
  -webkit-box-shadow: 0px -1px 10px 1px #0095ff;
  -moz-box-shadow: 0px -1px 10px 1px #0095ff;
  box-shadow: 0px -1px 10px 1px #0095ff; }
  .button_color_dark:hover {
    color: yellow;
    -webkit-box-shadow: 0px -1px 10px 1px yellow;
    -moz-box-shadow: 0px -1px 10px 1px yellow;
    box-shadow: 0px -1px 10px 1px yellow; }

.button_color_yellow {
  background-color: #e2d533;
  background-image: linear-gradient(180deg, #ff8d00, #e2d533);
  text-shadow: 1px 1px 4px #3a0404;
  border-radius: 1px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 4px 8px rgba(0, 0, 0, 0.13); }
  .button_color_yellow:hover {
    background-color: #c6b91c;
    background-image: linear-gradient(180deg, #cc7100 0%, #c6b91c 100%);
    box-shadow: none; }

.button_color_green {
  background-color: #000000;
  background-image: linear-gradient(140deg, #000000 0%, #000000 100%);
  -webkit-box-shadow: 0px -1px 10px 1px #0095ff;
  -moz-box-shadow: 0px -1px 10px 1px #0095ff;
  box-shadow: 0px -1px 10px 1px #0095ff; }
  .button_color_green:hover {
    color: yellow;
    -webkit-box-shadow: 0px -1px 10px 1px yellow;
    -moz-box-shadow: 0px -1px 10px 1px yellow;
    box-shadow: 0px -1px 10px 1px yellow; }

.button_color_paleblue {
  background-color: #4c7099;
  background-image: none;
  box-shadow: inset 0 0 2.9px 1.1px rgba(255, 255, 255, 0.28), inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 2px 1px rgba(13, 25, 39, 0.37); }
  .button_color_paleblue:hover {
    background-color: #3b5777;
    box-shadow: none; }

.button_color_brightblue {
  background-color: #388ad6;
  background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
  .button_color_brightblue:hover {
    background-color: #2570b6;
    background-image: linear-gradient(180deg, #2a92cc 0%, #1c56a6 100%);
    box-shadow: none; }

.button_color_transp {
  background-color: #264061;
  background-image: none;
  box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.17), 0 1px 1px rgba(0, 0, 0, 0.28); }
  .button_color_transp:hover {
    background-color: #18283c;
    box-shadow: none; }

.button_style_flat {
  width: 79px;
  height: 33px;
  background-color: #09203a;
  border-radius: 2px;
  background-image: none;
  box-shadow: inset 0 1px 0 #132e4d, 0 1px 0 #175499;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase; }
  .button_style_flat:hover {
    box-shadow: inset 0 1px 0 #494767, 0 1px 0 #175499; }

.button_light {
  background: #135a82;
  border: 1px solid #fff;
  padding: 5px 14px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .button_light {
      margin: 20px 0; } }
  @media only screen and (max-width: 767px) {
    .button_light {
      margin: 20px 0; } }

.input {
  display: inline-block;
  vertical-align: top;
  position: relative; }
  .input__label {
    display: block;
    position: relative; }
  .input__inner {
    width: 256px;
    height: 56px;
    background-color: #091420;
    border-radius: 2px;
    border: none;
    box-shadow: inset 0 1px 0 #0d0c13, 0 1px 0 #323144;
    color: #f0f2f4;
    font-size: 15px;
    vertical-align: middle;
    font-weight: 500;
    font-family: "ClearSans", sans-serif;
    text-transform: uppercase;
    padding: 0 21px; }
    .input_withbutton .input__inner {
      width: 253px;
      height: 43px;
      background-color: #091420;
      border: 1px solid #435c77;
      border-radius: 2px;
      box-shadow: inset 0 0 6px 1px #203956, inset -121px 0 0 #091420;
      color: #fff;
      font-family: 'ClearSans', sans-serif;
      font-size: 12px;
      display: block; }

.checkbox {
  display: inline-block;
  vertical-align: top;
  position: relative; }
  .checkbox__inner {
    display: none; }
  .checkbox__label {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    padding-left: 28px;
    cursor: pointer;
    display: block;
    position: relative; }
    .checkbox__label:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 17px;
      height: 17px;
      background-color: #09203a;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.38), 0 1px 0 #222130; }
    .checkbox__inner:checked + .checkbox__label:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 17px;
      height: 17px;
      background-color: #ff8d00;
      background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
      border-radius: 1px;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .checkbox__label:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 2px;
      left: 2px;
      background-image: url(../img/sprite.png?v=2);
      background-position: -855px -553px;
      width: 13px;
      height: 13px;
      opacity: 0; }
    .checkbox__inner:checked + .checkbox__label:after {
      content: '';
      opacity: 1; }

.checkbox_dark .checkbox__label {
  font-size: 16px;
  line-height: 25px;
  padding-left: 34px;
  font-weight: 500;
  color: #ccc; }
  .checkbox_dark .checkbox__label:before {
    width: 25px;
    height: 25px;
    background-color: #09203a;
    box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.11), inset 0 2px 4px 0 rgba(0, 0, 0, 0.68);
    border-radius: 2px; }
  .checkbox_dark .checkbox__label:after {
    width: 18px;
    height: 18px;
    background: none;
    content: '\2713'; }

.checkbox_dark .checkbox__inner:checked + .checkbox__label:before {
  width: 25px;
  height: 25px;
  line-height: 25px;
  background: #09203a;
  box-shadow: 0 2px 0 0 rgba(255, 255, 255, 0.11), inset 0 2px 4px 0 rgba(0, 0, 0, 0.68);
  border: none; }

.checkbox_dark .checkbox__inner:checked + .checkbox__label:after {
  content: '\2713';
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  bottom: 4px;
  left: 4px; }

.radiobutton {
  display: inline-block;
  vertical-align: top;
  position: relative; }
  .radiobutton__inner {
    display: none; }
  .radiobutton__label {
    color: #fff;
    font-weight: 400;
    padding-left: 28px;
    cursor: pointer;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.29);
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 18px; }
    .radiobutton__label:before, .radiobutton__label .radiobutton__inner:checked:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 4px;
      width: 12px;
      height: 12px;
      background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 1.4px 2.7px 1px rgba(0, 0, 0, 0.18); }
    .radiobutton__label:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      top: 6px;
      left: 2px;
      background-color: #fff;
      opacity: 0; }
    .radiobutton__inner:checked + .radiobutton__label:after {
      content: '';
      opacity: 1; }

.wrap {
  margin: 0 auto;
  width: 940px; }
  @media only screen and (min-width: 1440px) {
    .wrap {
      width: 1420px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .wrap {
      width: 1180px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .wrap {
      width: 700px; } }
  @media only screen and (max-width: 767px) {
    .wrap {
      width: 100%; } }

.section {
  width: 940px;
  margin: 0 auto;
  position: relative;
  display: table; }
  @media only screen and (min-width: 1440px) {
    .section {
      width: 1420px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .section {
      width: 1180px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .section {
      width: 700px; } }
  @media only screen and (max-width: 767px) {
    .section {
      width: 100%;
      max-width: 480px;
      display: block;
      position: relative; } }
  .section__main {
    display: table-cell;
    vertical-align: top;
    position: relative;
    background-color: #132e4d;
    width: 100%; }
    .section_full .section__main {
      border-radius: 5px;
      box-shadow: inset 0 1px 0 0 #334356, inset -1px 0 0 0 #2d517d, inset 1px 0 0 0 #2d517d; }
      .section_full .section__main.tournament-section {
        background: none;
        box-shadow: none; }
        .section_full .section__main.tournament-section:before {
          content: none; }
      .section_full .section__main:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 200px;
        border-radius: 5px 5px 0 0;
        box-shadow: inset 0 1px 0 0 #334356, inset -1px 0 0 0 #2d517d, inset 1px 0 0 0 #2d517d; }
      .section_full .section__main-lottery {
        background: #09203a;
        box-shadow: none; }
        .section_full .section__main-lottery:after, .section_full .section__main-lottery:before {
          content: none; }
        .section_full .section__main-lottery .main_lottery-details {
          padding: 0; }
        .section_full .section__main-lottery .panel {
          padding: 20px; }
          .section_full .section__main-lottery .panel__cell {
            vertical-align: top; }
        .section_full .section__main-lottery .img_overflow {
          margin: 0; }
    @media only screen and (max-width: 767px) {
      .section__main {
        width: 100%;
        display: block;
        position: relative;
        background-image: none; } }
  .section__aside {
    min-width: 300px;
    display: table-cell;
    vertical-align: top;
    background-color: #122d4c;
    border-left: 1px solid #09203a; }
    @media only screen and (min-width: 1440px) {
      .section__aside {
        min-width: 460px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .section__aside {
        min-width: 380px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .section__aside {
        min-width: 220px; } }
    @media only screen and (max-width: 767px) {
      .section__aside {
        min-width: 100%;
        display: block;
        position: relative;
        border-left: none; }
        .section_promo .section__aside {
          background-color: #122d4c; } }
  .section_winsline {
    margin-top: 23px;
    overflow: hidden;
    display: none; }
    @media only screen and (max-width: 767px) {
      .section_winsline {
        padding-left: 20px;
        padding-right: 20px; } }
  .section_promo {
    margin-top: 36px; }
  .section_main {
    margin-top: 20px;
    height: 100%;
    border: 2px solid #132e4d;
    border-radius: 5px; }
    @media only screen and (max-width: 767px) {
      .section_main {
        margin-top: 0px;
        border-radius: 0px; } }
    .section_main:after {
      content: '';
      width: 1px;
      display: block;
      position: absolute;
      top: 0;
      right: 300px;
      bottom: 0;
      background-color: #10253d;
      box-shadow: 1px 0 0 #214268; }
      @media only screen and (min-width: 1440px) {
        .section_main:after {
          right: 459px; } }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .section_main:after {
          right: 380px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .section_main:after {
          right: 220px; } }
      @media only screen and (max-width: 767px) {
        .section_main:after {
          right: 0;
          display: none; } }
    @media only screen and (max-width: 767px) {
      .section_main {
        height: auto; } }
  .section_full {
    margin-top: 34px; }
    @media only screen and (max-width: 767px) {
      .section_full-tournament {
        margin-top: 0px; } }
  .section-lottery_full {
    margin: 0px auto;
    background: #001a3b;
    width: 100%; }

.table {
  width: 100%;
  border-collapse: collapse; }
  .table__row {
    height: 43px; }
    .table_leaderboard .table__row {
      height: 31px; }
      .leaderboard .table_leaderboard .table__row.signed_in_user {
        background: #31518d;
        border-bottom: 1px solid #3a84c6;
        box-shadow: inset 0 1px 0 1px #3a84c6; }
    .table__row:nth-child(odd) {
      height: 31px;
      background-color: #132d4b; }
      .table_promo .table__row:nth-child(odd) {
        background-color: #3d628e; }
      .table_leaderboard .table__row:nth-child(odd) {
        background-color: #263c65;
        height: 31px; }
    .table__row_active.table__row .table__cell {
      background-color: #3668a2;
      box-shadow: inset 0 1px 0 #5a88bd;
      color: #fff; }
      .table__row_active.table__row .table__cell:first-child {
        text-align: center; }
      .table_promo .table__row_active.table__row .table__cell {
        background-color: transparent;
        box-shadow: none; }
    .table__row_empty {
      height: 26px; }
  .table__cell {
    font-family: 'ClearSans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #88add7;
    text-align: left;
    white-space: nowrap; }
    .table__cell_fluid.table__cell {
      width: 100%; }
      .table_leaderboard .table__cell_fluid.table__cell {
        width: 100%; }
    .table_promo .table__cell {
      color: #fff; }
    .table_leaderboard .table__cell {
      color: #fff; }
    .table__headrow .table__cell {
      color: #a8d1ff;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 4px 0 16px;
      text-align: left; }
      .table.table_leaderboard .table__headrow .table__cell {
        border-bottom: 1px solid #036;
        padding: 0 0 5px;
        color: #7c9ddb;
        font-weight: 100; }
    .table_promo .table__headrow .table__cell {
      color: #c8c7dc;
      padding: 0 0 14px; }
    .table_leaderboard .table__headrow .table__cell,
    .table_gameplay .table__headrow .table__cell {
      color: #fff;
      padding: 0 0 14px;
      font-family: 'ClearSans', sans-serif; }
    .table__headrow .table__cell:first-child {
      padding: 4px 10px 16px;
      text-transform: uppercase;
      text-align: center; }
    .table_promo .table__headrow .table__cell:first-child {
      padding: 0 10px 14px;
      width: 1px; }
    .table_promo .table__headrow .table__cell:last-child {
      padding: 0 10px 14px;
      width: 1px; }
    .table_leaderboard .table__headrow .table__cell:last-child,
    .table_leaderboard .table__headrow .table__cell:nth-child(3) {
      text-align: right;
      padding-right: 20px; }
    .table_leaderboard .table__body .table__cell:last-child {
      color: #fdc236; }
    .table_leaderboard .table__body .table__cell:nth-child(3) {
      color: #17b271; }
    .table_leaderboard .table__body .table__row:last-child .table__cell {
      border-bottom: 1px solid #036; }
    .table__body .table__cell:first-child {
      width: 1px;
      text-align: center;
      padding: 0 10px;
      text-transform: uppercase; }
    .table__body .table__cell:last-child {
      width: 1px;
      text-align: right;
      padding: 0 10px; }
    .table_leaderboard .table__body .table__cell:last-child,
    .table_leaderboard .table__body .table__cell:nth-child(3) {
      padding: 0 20px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .table_leaderboard .table__body .table__cell:last-child,
        .table_leaderboard .table__body .table__cell:nth-child(3) {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 106px;
          padding: 0 10px; } }
    .table_leaderboard .table__body .table__cell:nth-child(3) {
      text-align: right; }
  .table .overflow_outer {
    max-width: 58px; }
  .table .overflow_ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.aside {
  display: block;
  position: relative; }
  .aside_promo {
    padding: 30px 24px 30px 26px; }
  @media only screen and (max-width: 767px) {
    .aside__promo-bonus_fixed {
      max-width: 268px;
      margin: 0 auto; } }
  .aside__promo-table {
    margin-top: 3px;
    padding-top: 16px;
    background-color: #2f527b;
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.13), inset 0 -3px 0 #214166, 0 3px 8px rgba(0, 0, 0, 0.19); }
    @media only screen and (max-width: 767px) {
      .aside__promo-table_fixed {
        max-width: 268px;
        margin: 0 auto; } }
  .aside__search {
    display: block;
    height: 100px;
    position: relative;
    padding: 29px 20px 20px;
    background-color: #132e4d; }
    @media only screen and (min-width: 1440px) {
      .aside__search {
        padding: 30px 15px 30px; } }
    @media only screen and (max-width: 767px) {
      .aside__search {
        display: none; } }
    .aside__search__top {
      display: none;
      height: 40px;
      padding-top: 10px;
      padding-bottom: 0px; }
      @media only screen and (max-width: 767px) {
        .aside__search__top {
          display: block; } }
  .aside__curtour {
    margin: 0px auto;
    width: 93%;
    position: absolute;
    left: 0;
    top: 120px;
    right: 0;
    z-index: 2;
    display: block;
    padding: 15px 0 12px 140px;
    text-align: left;
    height: 50px;
    background-color: #10253d;
    border: 2px solid #1a5a91;
    border-bottom: 0px;
    border-top: 0px; }
    @media only screen and (min-width: 1440px) {
      .aside__curtour {
        top: 127px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .aside__curtour {
        padding: 15px 0 12px 100px;
        top: 135px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .aside__curtour {
        padding: 15px 0 12px 70px;
        top: 137px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .aside__curtour {
        padding: 18px 0 12px 36px;
        top: 140px; } }
    @media only screen and (max-width: 767px) {
      .aside__curtour {
        top: 20px;
        padding: 12px 0 12px;
        border: 2px solid #1a5a91; } }
  .aside__icon {
    display: block;
    position: absolute; }
    .aside__icon_trophy {
      top: 0;
      left: 5px;
      background-image: url("../img/trophy.png");
      width: 60px;
      height: 50px;
      background-size: 60px 50px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .aside__icon_trophy {
          display: none; } }
      @media only screen and (max-width: 767px) {
        .aside__icon_trophy {
          display: none; } }
    .aside__icon_info {
      right: 21px;
      top: 16px;
      cursor: pointer; }
  .aside__tooltip.tooltip {
    display: none;
    right: -13px;
    top: 27px; }
    .aside__icon_info:hover .aside__tooltip.tooltip {
      display: block; }
  .aside__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.23);
    color: #fff001;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 70px;
    padding-top: 15px;
    margin-bottom: 15px;
    text-align: center; }
    @media only screen and (min-width: 1440px) {
      .aside__title {
        margin-top: 35px;
        padding-top: 0px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .aside__title {
        margin-top: 45px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .aside__title {
        margin-top: 47px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .aside__title {
        margin-top: 50px; } }
    @media only screen and (max-width: 767px) {
      .aside__title {
        padding-top: 0px; } }
  .aside__subtitle {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .aside__subtitle {
        font-size: 12px; } }
    @media only screen and (max-width: 767px) {
      .aside__subtitle {
        width: 100%;
        text-align: center; } }
  .aside__tournament {
    margin-top: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #132e4d; }
    @media only screen and (max-width: 767px) {
      .aside__tournament {
        margin-top: 0px; } }
    .aside__tournament .tournament__button {
      margin-top: 15px; }
    .aside__tournament__lottery {
      border: 0;
      width: 100%;
      margin-top: 10px;
      text-align: center;
      padding: 0px; }
      .aside__tournament__lottery .lottery__img-overlay {
        height: 190px;
        width: 190px;
        margin: 0px auto; }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .aside__tournament__lottery .lottery__img-overlay {
            height: 180px;
            width: 180px; } }
      .aside__tournament__lottery .lottery__timer .timer__note {
        padding: 0px; }
      .aside__tournament__lottery .lottery__timer .timer__cell {
        font-size: 30px; }
      .aside__tournament__lottery .lottery__timer .timer__row_caption .timer__cell {
        font-size: 18px; }
    .aside__tournament__holder {
      border: 2px solid #1a5a91;
      border-bottom: 0px;
      border-top: 0px;
      margin: 0px auto;
      width: 93%;
      background-color: #00284b; }
      @media only screen and (min-width: 1440px) {
        .aside__tournament__holder {
          padding: 30px 15px 30px; } }
      @media only screen and (max-width: 767px) {
        .aside__tournament__holder {
          border: 2px solid #1a5a91; } }
      .aside__tournament__holder:before {
        content: "";
        width: 93%;
        height: 3px;
        background-image: linear-gradient(90deg, #04469c 0%, #52baff 50%, #04469c 100%);
        display: block;
        position: absolute;
        top: 124px;
        left: 16px; }
        @media only screen and (min-width: 1240px) and (max-width: 1439px) {
          .aside__tournament__holder:before {
            left: 13px;
            top: 132px; } }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .aside__tournament__holder:before {
            left: 10px;
            top: 134px; } }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .aside__tournament__holder:before {
            top: 137px;
            left: 7px; } }
        @media only screen and (max-width: 767px) {
          .aside__tournament__holder:before {
            display: none; } }
      .aside__tournament__holder:after {
        content: "";
        width: 93%;
        height: 3px;
        background-image: linear-gradient(90deg, #04469c 0%, #52baff 50%, #04469c 100%);
        display: block;
        position: absolute;
        bottom: 17px;
        left: 16px; }
        @media only screen and (min-width: 1240px) and (max-width: 1439px) {
          .aside__tournament__holder:after {
            left: 13px; } }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .aside__tournament__holder:after {
            left: 10px; } }
        @media only screen and (max-width: 767px) {
          .aside__tournament__holder:after {
            display: none; } }

.main {
  display: block;
  position: relative; }
  .main__inner {
    text-align: left; }
  .main_gallery {
    padding: 18px 12px 12px;
    font-size: 0; }
    @media only screen and (min-width: 1440px) {
      .main_gallery {
        padding: 17px 22px 12px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .main_gallery {
        padding: 18px 11px 12px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .main_gallery {
        padding: 18px 10px 12px; } }
    @media only screen and (max-width: 767px) {
      .main_gallery {
        padding: 18px 3% 12px; } }
  .main_promo {
    padding: 30px 26px 182px 26px;
    background-color: #122d4c; }
    @media only screen and (max-width: 767px) {
      .main_promo {
        padding: 30px 26px 10px; } }
  @media only screen and (max-width: 767px) {
    .main_profile {
      width: calc(100% - 1px); } }
  .main_promo-details {
    padding: 30px 30px 95px 30px;
    background-color: #122d4c; }
    @media only screen and (max-width: 767px) {
      .main_promo-details {
        padding: 30px 30px 30px; } }
  .main_tournament {
    padding: 0 20px 30px 20px; }
  .main_tournament-details {
    text-align: center;
    padding: 0 26px 28px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .main_tournament-details {
        padding: 0 8px 28px; } }
    @media only screen and (max-width: 767px) {
      .main_tournament-details {
        padding: 0 0 40px; } }
  .main_lottery {
    padding: 0 20px 28px 20px; }
  .main_lottery-details {
    text-align: center;
    padding: 0 26px 0; }
    @media only screen and (max-width: 767px) {
      .main_lottery-details {
        padding: 0; } }
  .main__item {
    display: inline-block;
    vertical-align: top; }
    .main_gallery .main__item {
      margin: 10px 10px; }
    .main_promo .main__item {
      display: block;
      width: 100%;
      margin-bottom: 27px; }
      .main_promo .main__item:last-child {
        margin-bottom: 0; }
    .main_tournament .main__item {
      display: block;
      background: #00284b;
      border: 1px solid #2a588c;
      border-radius: 3px;
      width: 870px;
      padding-bottom: 15px;
      margin: 0 auto 32px;
      max-width: 100%; }
      .main_tournament .main__item:last-child {
        margin-bottom: 0; }
      .main_tournament .main__item:hover {
        box-shadow: inset 0px 0px 30px 0px #4c9cb9; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .main_tournament .main__item {
          padding-bottom: 0px;
          width: 700px; } }
      @media only screen and (max-width: 767px) {
        .main_tournament .main__item {
          padding-bottom: 0px; } }

.header {
  display: table;
  position: relative;
  background-color: #091b2f;
  height: 182px;
  width: 100%;
  overflow: hidden; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .header {
      height: 230px; } }
  @media only screen and (max-width: 767px) {
    .header {
      min-height: 156px;
      height: 156px;
      z-index: 100;
      overflow: auto; } }
  .header__inner {
    display: block;
    position: relative; }
  @media only screen and (max-width: 767px) {
    .header__left {
      background-image: linear-gradient(to bottom, #091420 0%, #091420 32%, #132e4d 32%, #324c69 100%); } }
  @media only screen and (max-width: 767px) {
    .header__right {
      background-image: linear-gradient(to bottom, #091420 0%, #091420 32%, #132e4d 32%, #324c69 100%); } }
  .header__wrap {
    display: table-cell;
    z-index: 1;
    position: relative;
    height: 100%; }
    @media only screen and (max-width: 767px) {
      .header__wrap {
        width: 480px; } }
    .header__wrap_scroll {
      overflow-y: auto;
      touch-action: initial; }
  .header__toppanel {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: #091420; }
    @media only screen and (max-width: 767px) {
      .header__toppanel {
        position: relative;
        padding-left: 0;
        width: 100%;
        margin-left: 0px; } }
    .header__toppanel.open {
      position: fixed;
      z-index: 100;
      background-color: #091420; }
    @media only screen and (max-width: 767px) {
      .header__toppanel .signup {
        height: 36px; }
      .header__toppanel .signup__button {
        padding: 10px 9px; }
      .header__toppanel .head-panel {
        width: auto;
        display: block;
        z-index: 2;
        right: 19px;
        position: absolute;
        top: 64px; }
      .header__toppanel .head-panel__cell_fluid {
        display: none; }
      .header__toppanel .head-panel__button {
        margin-left: 0;
        margin-top: 10px;
        font-size: 14px;
        line-height: 16px;
        padding: 10px 21px 10px;
        width: 130px; } }
  .header__logo {
    display: block;
    top: 56px;
    left: 4px;
    position: absolute;
    z-index: 2;
    width: 206px;
    height: 124px;
    background-image: url("../img/logo/logo.png"); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .header__logo {
        top: 74px; } }
    @media only screen and (max-width: 767px) {
      .header__logo {
        background-image: url("../img/logo/logo.png");
        background-size: 125px 80px;
        background-position: center center;
        background-repeat: no-repeat;
        width: 125px;
        height: 80px;
        top: 65px;
        left: 15px; } }
  .header__panel {
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    top: 44px;
    padding: 3px 0 40px 198px; }
    .header__panel.open {
      display: block;
      position: absolute;
      bottom: 0;
      background-color: #091420;
      right: 0;
      left: 0;
      top: 50px;
      width: 100% !important;
      z-index: 2; }
    @media only screen and (min-width: 1440px) {
      .header__panel {
        padding: 3px 0 40px 330px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .header__panel {
        padding: 3px 0 40px 295px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .header__panel {
        padding: 27px 0 40px 165px; } }
    @media only screen and (max-width: 767px) {
      .header__panel {
        display: block;
        width: 170px !important;
        top: 62px;
        z-index: 100;
        left: auto;
        padding: 2px 14px 0px 22px; }
        .header__panel .head-panel {
          display: none; } }
    @media only screen and (min-width: 1440px) {
      .header__panel_logged {
        padding: 15px 0 40px 330px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .header__panel_logged {
        padding: 15px 0 40px 295px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .header__panel_logged {
        padding: 15px 0 40px 213px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .header__panel_logged {
        padding: 15px 0 40px 228px; } }
    @media only screen and (max-width: 767px) {
      .header__panel_logged {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .header__panel .signup {
        height: 36px; }
      .header__panel .signup__button {
        padding: 10px 9px; } }
  .header__head-nav {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #09203a; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .header__head-nav {
        padding-left: 0;
        z-index: 1; } }
    @media only screen and (max-width: 767px) {
      .header__head-nav {
        background-image: linear-gradient(360deg, #324c69, #132e4d);
        height: 106px;
        padding-left: 184px;
        bottom: auto;
        top: 50px; }
        .header__head-nav .nav {
          display: none; } }
    .header__head-nav_top {
      position: relative; }
      .header__head-nav_top:after {
        content: '';
        width: 80%;
        position: absolute;
        right: 35px;
        bottom: 0;
        height: 2px;
        background-image: linear-gradient(90deg, rgba(87, 185, 220, 0.29), #35d0ff 50%, rgba(87, 185, 220, 0.29));
        -webkit-box-shadow: 0px 0px 80px 10px #3fc8fa;
        -moz-box-shadow: 0px 0px 80px 10px #3fc8fa;
        box-shadow: 0px 0px 80px 10px #3fc8fa; }
        @media only screen and (min-width: 1240px) and (max-width: 1439px) {
          .header__head-nav_top:after {
            right: 25;
            width: 75%; } }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .header__head-nav_top:after {
            right: 20px;
            width: 70%; } }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .header__head-nav_top:after {
            height: 0;
            box-shadow: none; } }
  .header_panel-open {
    height: 100%;
    overflow: auto;
    display: block; }
    .header_panel-open .header__wrap {
      display: block;
      height: 100%;
      min-height: 660px;
      width: 100%;
      max-width: 100%; }
  .header__confirm-email {
    height: 30px;
    background-image: linear-gradient(100deg, #fda31b, #fd0834);
    width: 100%;
    padding-top: 6px;
    position: fixed;
    top: 0;
    z-index: 11; }
    @media only screen and (max-width: 767px) {
      .header__confirm-email {
        display: none !important; } }
    .header__confirm-email .confirm-email__txt {
      width: 940px;
      margin: 0 auto;
      font-weight: 700;
      color: #fff;
      font-size: 15px;
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.22);
      text-align: center;
      text-transform: uppercase;
      position: relative; }
      @media only screen and (min-width: 1440px) {
        .header__confirm-email .confirm-email__txt {
          width: 1280px; } }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .header__confirm-email .confirm-email__txt {
          width: 1180px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .header__confirm-email .confirm-email__txt {
          width: 700px; } }
      @media only screen and (max-width: 767px) {
        .header__confirm-email .confirm-email__txt {
          width: 100%;
          max-width: 480px; } }
    .header__confirm-email .confirm-email__close {
      position: absolute;
      right: 5px;
      top: 3px; }

.head-panel {
  display: table;
  position: relative;
  font-family: 'ClearSans', sans-serif;
  font-size: 0;
  text-transform: uppercase;
  color: #fff;
  margin-left: 24px;
  margin-top: 13px;
  width: calc(100% - 24px); }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .head-panel {
      margin-top: 4px; } }
  .head-panel__cell {
    display: table-cell;
    vertical-align: middle;
    width: 1px;
    white-space: nowrap; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .head-panel__cell {
        vertical-align: middle;
        width: auto; } }
    @media only screen and (max-width: 767px) {
      .head-panel__cell {
        display: block;
        width: 130px; } }
    .head-panel__cell_fluid {
      width: auto;
      text-align: right; }
      @media only screen and (max-width: 767px) {
        .head-panel__cell_fluid {
          display: block; } }
  .head-panel__pointer {
    margin-top: -2px;
    margin-right: 3px;
    width: 145px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    background-image: url("../img/reg_and_get_bonus.png");
    background-repeat: no-repeat;
    background-size: 147px 54px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .head-panel__pointer {
        background-image: url("../img/reg_and_get_bonus_small.png");
        background-size: 56px 43px;
        width: 60px;
        height: 43px; } }
  .head-panel__signup {
    display: inline-block;
    vertical-align: top;
    position: relative;
    border-radius: 2px; }
  .head-panel__button {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: 24px;
    margin-right: 20px;
    line-height: 16px;
    padding: 13px 21px 14px 21px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #2c85ca, #164278);
    box-shadow: inset 0 1px 0 0 #7ac7ff, inset 0 1px 6px 0 rgba(204, 242, 255, 0.5);
    font-size: 16px;
    font-weight: 600;
    font-stretch: condensed;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .head-panel__button {
        padding: 10px 10px 10px;
        margin-left: 8px;
        margin-right: 10px; } }
  .head-panel__caption {
    display: inline-block;
    max-width: 50px;
    font-size: 16px;
    line-height: 22px;
    white-space: normal; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .head-panel__caption {
        display: none; } }
  .head-panel__socials {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 21px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .head-panel__socials {
        margin-left: 8px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .head-panel__socials .socials__item {
        width: 31px;
        height: 31px;
        margin-right: 6px; } }

.mobile-panel {
  display: none;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .mobile-panel {
      display: block;
      z-index: 1; } }
  .mobile-panel__button {
    display: block;
    width: 130px;
    height: 36px;
    padding: 10px 9px;
    font-size: 16px;
    font-family: 'ClearSans', sans-serif;
    font-weight: 400;
    line-height: 16px;
    outline: none;
    z-index: 2;
    min-width: 130px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.11);
    border-radius: 2px; }
    .mobile-panel__button_blue {
      margin-top: 10px;
      background-image: linear-gradient(180deg, #2c85ca, #164278);
      box-shadow: inset 0 1px 0 0 #7ac7ff, inset 0 1px 6px 0 rgba(204, 242, 255, 0.5); }
  .mobile-panel__action {
    display: block;
    position: absolute;
    top: 10px;
    right: 19px; }
  .mobile-panel__countpad.countpad {
    display: block;
    position: absolute;
    top: 48px;
    right: 0;
    width: 35px;
    height: 35px; }
    @media only screen and (max-width: 767px) {
      .mobile-panel__countpad.countpad .countpad__counter {
        width: 19px;
        height: 19px;
        top: -7px;
        font-size: 13px; } }
  .mobile-panel__cashier {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 9px 7px;
    width: 87px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400; }

.user-panel {
  display: table;
  width: 100%;
  position: relative;
  font-size: 0;
  border-collapse: collapse; }
  @media only screen and (max-width: 767px) {
    .user-panel {
      display: block;
      padding-top: 72px; } }
  .user-panel__cell {
    display: table-cell;
    vertical-align: top;
    width: 1px; }
    @media only screen and (max-width: 767px) {
      .user-panel__cell {
        display: block;
        width: 100%; } }
    .user-panel__cell_action {
      white-space: nowrap;
      width: 1px;
      text-align: right;
      padding-top: 3px; }
      .header__panel_logged.open .user-panel__cell_action {
        width: 100%;
        text-align: left; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .user-panel__cell_action {
          min-width: 84px; } }
    .user-panel__cell_status {
      width: 1px;
      max-width: 40px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .user-panel__cell_status {
          max-width: 60px; } }
      @media only screen and (max-width: 767px) {
        .user-panel__cell_status {
          display: none; } }
  .user-panel__status.status {
    display: block;
    vertical-align: top;
    position: relative;
    margin-left: 5px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-panel__status.status {
        display: none; } }
  .user-panel__rating.rating {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: 12px;
    width: 160px; }
    .user-panel__cell_bonus .user-panel__rating.rating {
      margin-left: 62px;
      width: 144px; }
      .user-panel__cell_bonus .user-panel__rating.rating .rating__info {
        bottom: -4px;
        right: -34px; }
      @media only screen and (min-width: 1440px) {
        .user-panel__cell_bonus .user-panel__rating.rating .rating__bar {
          width: 175px; }
        .user-panel__cell_bonus .user-panel__rating.rating .rating__title:first-child {
          display: inline-block; } }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .user-panel__cell_bonus .user-panel__rating.rating .rating__bar {
          margin-left: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .user-panel__cell_bonus .user-panel__rating.rating {
          margin-left: 0; }
          .user-panel__cell_bonus .user-panel__rating.rating .rating__bar {
            margin-top: 10px;
            width: 108px; }
          .user-panel__cell_bonus .user-panel__rating.rating .rating__info {
            right: -26px;
            bottom: -4px; } }
      @media only screen and (max-width: 767px) {
        .user-panel__cell_bonus .user-panel__rating.rating {
          margin-left: 0;
          width: 100%;
          display: block; }
          .user-panel__cell_bonus .user-panel__rating.rating .rating__title_accent {
            margin-left: 14px; } }
    @media only screen and (min-width: 1440px) {
      .user-panel__rating.rating .rating__bar {
        display: block;
        vertical-align: top;
        position: relative;
        width: 175px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .user-panel__rating.rating .rating__bar {
        display: block;
        vertical-align: top;
        position: relative;
        width: 175px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-panel__rating.rating .rating__bar {
        margin-top: 12px;
        width: 108px; }
      .user-panel__rating.rating .rating__title {
        display: block;
        padding-top: 4px; }
      .user-panel__rating.rating .rating__icon:first-child {
        margin-left: 0; }
      .user-panel__rating.rating .rating__info {
        right: -26px;
        bottom: -4px; } }
    @media only screen and (max-width: 767px) {
      .user-panel__rating.rating {
        width: 100%;
        display: block;
        margin-left: 0;
        padding: 10px 0; }
        .user-panel__rating.rating:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          top: 0;
          border: solid 1px #08203a; }
        .user-panel__rating.rating .rating__title {
          font-size: 12px; }
        .user-panel__rating.rating .rating__bar {
          display: block;
          width: 110px;
          float: right;
          margin: 0;
          position: absolute;
          right: 0;
          top: 4px; }
        .user-panel__rating.rating .rating__info {
          display: none; }
        .user-panel__rating.rating .rating__percent {
          padding-top: 0;
          font-size: 9px;
          padding-right: 3px; }
        .user-panel__rating.rating .rating__icon {
          margin-right: 1px;
          display: inline-block;
          width: 18px;
          height: 16px; }
        .user-panel__rating.rating .rating__icon:first-of-type {
          margin-left: 5px; } }
  .user-panel__button.button {
    display: inline-block;
    position: relative;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16), 0 8px 6px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(140deg, #ff8d00, #e2d533);
    text-shadow: 1px 1px 4px #3a0404;
    padding: 14px 12px 12px;
    margin-left: 56px;
    transition: box-shadow 0.2s ease; }
    .user-panel__button.button:hover {
      box-shadow: 0 0 10px 0 rgba(210, 228, 47, 0.7); }
    @media only screen and (min-width: 1440px) {
      .user-panel__button.button {
        margin-left: 108px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .user-panel__button.button {
        margin-left: 38px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-panel__button.button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 9px 7px;
        width: 87px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400; } }
    @media only screen and (max-width: 767px) {
      .user-panel__button.button {
        width: 100%;
        display: block;
        margin: 19px auto;
        border-radius: 2px;
        padding: 8px 12px 8px;
        position: absolute;
        top: 0;
        left: 0; }
        .user-panel__button.button:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          top: -18px;
          border: solid 1px #08203a; } }
  .user-panel__countpad.countpad {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 21px; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .user-panel__countpad.countpad {
        margin-left: 21px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-panel__countpad.countpad {
        display: block;
        position: absolute;
        top: 48px;
        right: 0;
        width: 35px;
        height: 35px; } }
    @media only screen and (max-width: 767px) {
      .user-panel__countpad.countpad {
        background: none;
        border: none;
        box-shadow: none;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 10px 0; }
        .user-panel__countpad.countpad:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          top: 0;
          border: solid 1px #08203a; }
        .user-panel__countpad.countpad:hover {
          box-shadow: none;
          border: none;
          background: none; } }
    @media only screen and (max-width: 767px) {
      .user-panel__countpad.countpad .countpad__icon {
        top: 8px;
        left: 2px;
        display: none;
        transform: none;
        position: absolute; } }
    @media only screen and (max-width: 767px) {
      .user-panel__countpad.countpad .countpad__counter {
        right: 0;
        top: 9px;
        box-shadow: none; } }
    .user-panel__countpad.countpad + .user-panel__countpad.countpad {
      margin-left: 18px; }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .user-panel__countpad.countpad + .user-panel__countpad.countpad {
          margin-left: 18px;
          right: 0; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .user-panel__countpad.countpad + .user-panel__countpad.countpad {
          right: 0; } }
      @media only screen and (max-width: 767px) {
        .user-panel__countpad.countpad + .user-panel__countpad.countpad {
          margin-left: 0;
          display: block;
          left: 0; } }
  @media only screen and (min-width: 1440px) {
    .user-panel .rating__stars {
      margin-left: 5px;
      padding-top: 1px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .user-panel .rating__stars {
      margin-left: 5px;
      padding-top: 1px; } }
  .user-panel__profile, .user-panel__logout, .user-panel__switchlang {
    display: none;
    position: relative;
    font-family: 'ClearSans',sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #00a6ff;
    padding: 12px 40px;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .user-panel__profile, .user-panel__logout, .user-panel__switchlang {
        display: block; } }
    .user-panel__profile:before, .user-panel__logout:before, .user-panel__switchlang:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
      border: solid 1px #08203a; }
  .user-panel__profile .mobile-nav__icon {
    left: 0; }
  .user-panel__logout {
    font-weight: 300; }
  .user-panel__note {
    text-transform: uppercase; }
    .user-panel__note_important {
      color: #fc5526; }
  .user-panel__caption {
    position: relative;
    vertical-align: top;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #00a6ff;
    padding: 2px 40px; }
    .user-panel__caption_accent {
      position: absolute;
      top: 10px;
      right: 0;
      padding: 0; }
  .user-panel__vip-points {
    display: none; }
    @media only screen and (max-width: 767px) {
      .user-panel__vip-points {
        display: block;
        position: relative;
        height: 40px;
        margin: 0;
        padding: 10px 0; }
        .user-panel__vip-points .user-panel__caption {
          padding: 2px 16px;
          display: inline-block; }
        .user-panel__vip-points .user-panel__caption_accent {
          padding: 2px 0;
          display: inline-block; }
        .user-panel__vip-points:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          top: 0;
          border: solid 1px #08203a; } }

.toppanel {
  display: block;
  position: relative;
  font-size: 0;
  padding: 19.5px 10px 19.5px 17px;
  font-weight: 700;
  font-family: 'ClearSans', sans-serif;
  white-space: nowrap;
  overflow: visible; }
  @media only screen and (max-width: 767px) {
    .toppanel {
      width: 100% !important; } }
  .toppanel__title {
    color: #fefefe;
    display: none;
    vertical-align: top;
    font-size: 15px;
    line-height: 18px; }
    @media only screen and (max-width: 767px) {
      .toppanel__title {
        display: block; } }
  .toppanel__icon {
    display: none;
    vertical-align: top;
    position: relative;
    width: 14px;
    height: 20px;
    fill: #fff; }
    @media only screen and (max-width: 767px) {
      .toppanel__icon {
        display: inline-block; } }
    .toppanel__icon_menu {
      display: block;
      position: absolute;
      width: 12px;
      background-color: #fff;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      height: 2px; }
      .toppanel__icon_menu:before, .toppanel__icon_menu:after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 2px;
        left: 0;
        background-color: #fff; }
      .toppanel__icon_menu:before {
        top: -4px; }
      .toppanel__icon_menu:after {
        bottom: -4px; }
      .toppanel__button_close .toppanel__icon_menu {
        display: none; }
    .toppanel__icon_close {
      display: none; }
      .toppanel__button_close .toppanel__icon_close {
        display: block;
        position: absolute;
        top: 7px;
        left: 8px;
        height: 16px;
        width: 16px; }
  .toppanel__button {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-stretch: condensed;
    letter-spacing: -0.2px;
    color: #ffffff;
    padding: 6px 8px 6px 25px;
    outline: none;
    width: 64px;
    height: 29px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #2c85ca, #164278);
    box-shadow: inset 0 1px 0 0 #7ac7ff, inset 0 1px 6px 0 rgba(204, 242, 255, 0.5); }
    @media only screen and (max-width: 767px) {
      .toppanel__button {
        right: 19px; } }
    .toppanel__button_close {
      text-indent: -4444px;
      width: 30px;
      height: 30px;
      right: 10px;
      top: 10px;
      padding: 0; }
    @media only screen and (max-width: 767px) {
      .toppanel__button {
        display: block; } }

.user-toppanel {
  display: block;
  padding-left: 250px;
  min-height: 50px;
  position: relative;
  font-size: 0;
  padding: 16px 0;
  font-weight: 700;
  font-family: 'ClearSans', sans-serif;
  white-space: nowrap;
  overflow: visible; }
  @media only screen and (max-width: 767px) {
    .user-toppanel {
      width: 100% !important; } }
  @media only screen and (min-width: 1440px) {
    .user-toppanel.wrap {
      padding-left: 310px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .user-toppanel.wrap {
      padding-left: 291px; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .user-toppanel.wrap {
      padding-left: 220px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .user-toppanel.wrap {
      padding-left: 225px; } }
  .user-toppanel__title {
    color: #fefefe;
    display: block;
    vertical-align: top;
    font-size: 15px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__title {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__title {
        display: none; }
        .header__toppanel.open .user-toppanel__title {
          display: block; } }
  .user-toppanel__name {
    position: relative;
    margin-left: 8px;
    font-size: 15px;
    line-height: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    float: left;
    max-width: 100px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__name {
        color: #00a6ff;
        margin-left: 5px;
        padding-right: 14px;
        line-height: 18px; }
        .user-toppanel__name:first-child {
          display: none; }
          .user-toppanel__name:first-child:before {
            content: none; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__name {
        color: #00a6ff;
        margin-left: 17px; }
        .user-toppanel__name:before {
          content: '';
          display: block;
          position: absolute;
          width: 23px;
          height: 100%;
          pointer-events: none;
          right: 0;
          top: 0;
          background-image: linear-gradient(to right, rgba(9, 19, 32, 0), #091420); }
        .user-toppanel__name:first-child {
          color: #fff; }
          .user-toppanel__name:first-child:before {
            content: none; }
        .header__toppanel.open .user-toppanel__name:before {
          content: none; } }
  .user-toppanel__nav {
    display: inline-block;
    vertical-align: top;
    font-size: 0;
    margin-left: 22px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__nav {
        margin-left: 0; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__nav {
        margin-left: 0; }
        .header__toppanel.open .user-toppanel__nav {
          display: none; } }
  .user-toppanel__item {
    display: inline-block;
    text-decoration: none;
    vertical-align: top;
    position: relative;
    padding: 0 18px 0 16px;
    font-size: 15px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__item {
        padding: 0 18px 0 21px; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__item {
        padding: 0 18px 0 21px; }
        .user-toppanel__item_vip {
          padding-left: 0; } }
    .user-toppanel__item:after {
      content: '';
      display: block;
      position: absolute;
      top: -9px;
      left: 0;
      width: 1px;
      height: 40px;
      background-image: radial-gradient(circle at 50% 50%, #2b4460, #091420); }
      @media only screen and (max-width: 767px) {
        .user-toppanel__item:after {
          display: none; } }
    .user-toppanel__item_profile {
      padding: 0 16px 0 16px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .user-toppanel__item_profile {
          display: inline-block;
          padding-right: 0; } }
      @media only screen and (max-width: 767px) {
        .user-toppanel__item_profile {
          display: none; } }
      .user-toppanel__item_profile:before {
        content: '';
        display: block;
        position: absolute;
        top: -9px;
        left: 0;
        background-image: url(../img/sprite.png?v=2);
        background-position: -980px -162px;
        width: 1px;
        height: 39px; }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .user-toppanel__item_profile:before {
            display: none; } }
    .user-toppanel__item_balance {
      padding: 0 10px 0 21px; }
  .user-toppanel__note {
    display: inline-block;
    vertical-align: top;
    position: relative;
    color: #46b8ff;
    font-size: 15px;
    letter-spacing: 0.17px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__note {
        padding-right: 13px; }
        .user-toppanel__item_profile .user-toppanel__note {
          display: inline-block;
          line-height: 18px; }
        .user-toppanel__item_balance .user-toppanel__note {
          font-size: 14px;
          margin-left: 5px;
          line-height: 18px; }
          .user-toppanel__item_balance .user-toppanel__note:first-of-type {
            display: none; }
        .user-toppanel__item_vip .user-toppanel__note {
          font-size: 14px;
          margin-left: 5px;
          line-height: 18px; }
          .user-toppanel__item_vip .user-toppanel__note:first-of-type {
            display: none; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__note {
        font-size: 14px;
        margin-left: 5px; }
        .user-toppanel__item_balance .user-toppanel__note {
          font-size: 14px;
          margin-left: 5px;
          line-height: 18px; }
          .user-toppanel__item_balance .user-toppanel__note:first-of-type {
            display: none; }
        .user-toppanel__item_vip .user-toppanel__note {
          font-size: 14px;
          margin-left: 5px;
          line-height: 18px; }
          .user-toppanel__item_vip .user-toppanel__note:first-of-type {
            display: none; } }
    .user-toppanel__note_accent {
      color: #f1e419;
      padding-right: 0; }
    .user-toppanel__note_important {
      color: #FC5526;
      right: -15px; }
    .user-toppanel__note_white {
      color: #fff; }
  .user-toppanel__icon {
    display: none;
    vertical-align: top;
    position: relative;
    width: 14px;
    height: 20px;
    fill: #fff; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__icon {
        display: inline-block; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__icon {
        display: inline-block; } }
    .user-toppanel__icon_vip {
      width: 27px;
      height: 20px; }
    .user-toppanel__icon_menu {
      display: block;
      position: absolute;
      width: 12px;
      background-color: #fff;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      height: 2px; }
      .user-toppanel__icon_menu:before, .user-toppanel__icon_menu:after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 2px;
        left: 0;
        background-color: #fff; }
      .user-toppanel__icon_menu:before {
        top: -4px; }
      .user-toppanel__icon_menu:after {
        bottom: -4px; }
      .user-toppanel__button_close .user-toppanel__icon_menu {
        display: none; }
    .user-toppanel__icon_close {
      display: none; }
      .user-toppanel__button_close .user-toppanel__icon_close {
        display: block;
        position: absolute;
        top: 7px;
        left: 8px;
        height: 16px;
        width: 16px; }
  .user-toppanel__action {
    display: block;
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 15px;
    line-height: 15px;
    padding: 0 0 0 16px;
    text-decoration: none; }
    .user-toppanel__action:after {
      content: '';
      display: block;
      position: absolute;
      top: -14px;
      left: 0;
      width: 1px;
      height: 40px;
      background-image: radial-gradient(circle at 50% 50%, #2b4460, #091420); }
      @media only screen and (max-width: 767px) {
        .user-toppanel__action:after {
          display: none; } }
    .user-toppanel__action .user-toppanel__note {
      right: 0;
      padding-right: 0; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__action {
        display: block; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__action {
        display: none; } }
  .user-toppanel__button {
    display: none;
    position: absolute;
    top: 10px;
    right: 19px;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-stretch: condensed;
    letter-spacing: -0.2px;
    color: #ffffff;
    padding: 6px 8px 6px 25px;
    outline: none;
    width: 64px;
    height: 29px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #2c85ca, #164278);
    box-shadow: inset 0 1px 0 0 #7ac7ff, inset 0 1px 6px 0 rgba(204, 242, 255, 0.5); }
    .user-toppanel__button_close {
      text-indent: -4444px;
      width: 30px;
      height: 30px;
      right: 19px;
      top: 10px;
      padding: 0; }
    @media only screen and (max-width: 767px) {
      .user-toppanel__button {
        display: block; } }
  .user-toppanel__item-confirm-btn, .user-toppanel__item-confirm-btn:hover {
    display: inline-block;
    position: absolute;
    font-size: 18px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.22);
    padding: 7px 22px;
    width: 140px;
    white-space: initial;
    line-height: 16px;
    text-align: center;
    font-weight: 700;
    border-radius: 3px;
    top: -13px;
    background: transparent;
    border: 1px solid #ff6526;
    margin-top: 16px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .user-toppanel__item-confirm-btn, .user-toppanel__item-confirm-btn:hover {
        padding: 5px 12px;
        width: 120px; } }
    @media only screen and (max-width: 767px) {
      .user-toppanel__item-confirm-btn, .user-toppanel__item-confirm-btn:hover {
        padding: 5px 12px;
        width: 120px; } }

.status {
  width: 48px;
  height: 45px;
  text-align: center;
  position: relative;
  display: block; }
  .status__icon {
    margin-top: 8px;
    position: relative; }
  .status__note {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.18);
    color: #fefefe;
    font-family: 'ClearSans', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    display: block;
    text-align: center;
    margin-top: 1px; }

.status_huge {
  width: 114px;
  height: 117px; }
  .status_huge .status__icon {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    text-align: center;
    width: 92px; }
  .status_huge .status__note {
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    bottom: 9px;
    left: 0;
    right: 0;
    display: block;
    position: absolute; }

.rating {
  display: block;
  width: 100%;
  position: relative; }
  .rating__summary {
    display: block;
    width: 100%;
    position: relative;
    text-align: left;
    white-space: nowrap; }
  .rating__info {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: -4px;
    padding: 4px 0 0 0;
    text-align: center;
    width: 21px;
    height: 21px;
    font-size: 0;
    border-radius: 50%;
    background-color: #4ea1e1;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .rating__summary .rating__info {
      right: -35px;
      bottom: -5px; }
  .rating__tooltip {
    min-width: 191px;
    right: 0;
    margin-right: 11px;
    transform: translateX(50%);
    top: calc(100% + 16px);
    opacity: 0;
    visibility: hidden; }
    .rating__summary .rating__tooltip {
      right: -116px;
      margin-right: 0px;
      top: 0; }
    .rating__summary__profile .rating__tooltip {
      right: -4px;
      margin-right: 0px;
      top: calc(100% + 16px); }
    .rating__info:hover .rating__tooltip {
      opacity: 1;
      visibility: visible; }
    .rating__tooltip_right {
      transform: none;
      right: -12px; }
  .rating__title {
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;
    color: #fefefe;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    line-height: 20px; }
    .rating__title .rating__info {
      bottom: 0; }
    .rating__title_accent {
      color: #fff000;
      margin-left: 4px; }
      @media only screen and (min-width: 1440px) {
        .rating__title_accent {
          margin-left: 8px; } }
      .rating__title_accent.rating__title_large {
        margin-left: 5px; }
    .rating__title_large {
      font-size: 17px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .rating__title_large {
          font-size: 14px; } }
  .rating__caption {
    display: inline-block;
    position: relative;
    text-align: left;
    color: #fefefe;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 4px; }
    .rating__caption_accent {
      color: #fff000; }
  .rating__stars {
    text-align: right;
    position: relative;
    display: inline-block;
    top: 0;
    right: 0;
    padding-top: 2px; }
  .rating__icon {
    margin-right: 4px;
    display: inline-block;
    width: 18px;
    height: 19px; }
    .rating__icon:first-of-type {
      margin-left: 12px; }
    .rating__icon:last-of-type {
      margin-right: 0; }
  .rating__bar {
    display: block;
    margin-top: 14px;
    width: calc(100% - 25px);
    height: 12px;
    position: relative;
    background-color: #060d12;
    border: 1px solid #133152;
    border-radius: 5px; }
  .rating__inner {
    display: block;
    position: relative;
    width: 68%;
    height: 12px;
    margin-top: -1px;
    background-color: #4ea1e1;
    border-radius: 5px;
    box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.63), 0 0 27px rgba(122, 247, 255, 0.58), 0 0 24px #000; }
  .rating__percent {
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    padding-top: 13px;
    color: #fefefe;
    font-family: 'ClearSans', sans-serif; }
    .rating__percent_large {
      font-size: 14px; }

.rating_gameplay .rating__bar {
  margin-top: 19px; }

.rating_gameplay .rating__stars {
  top: 0;
  margin-right: 3px; }
  .rating_gameplay .rating__stars .fa-star {
    color: #F2E419; }
  .rating_gameplay .rating__stars .fa-star-o {
    color: #08203A;
    text-shadow: 1px 1px 0px #22476f;
    font-weight: bold; }

.rating_gameplay .rating__percent {
  padding-top: 20px; }

.rating_profile .rating__stars {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 29px;
  padding-left: 15px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .rating_profile .rating__stars {
      position: absolute;
      top: -81px;
      left: 69px;
      text-align: left; } }
  .rating_profile .rating__stars .fa-star {
    color: #F2E419; }
  .rating_profile .rating__stars .fa-star-o {
    color: #08203A;
    text-shadow: 1px 1px 0px #22476f;
    font-weight: bold; }

.rating_profile .rating__icon {
  margin-right: 5px; }
  .rating_profile .rating__icon:first-of-type {
    margin-left: 0; }

.rating_profile .rating__bar {
  margin: 36px 62px 36px 46px;
  width: auto; }
  .rating_profile .rating__bar .rating__info {
    right: -30px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .rating_profile .rating__bar {
      margin: 36px 42px 36px 20px; } }
  @media only screen and (max-width: 767px) {
    .rating_profile .rating__bar {
      margin: 36px 48px 14px 18px; } }

.rating_profile .rating__percent {
  font-size: 15px;
  padding-top: 20px; }

.rating_profile .rating__summary {
  margin-top: 38px;
  padding: 0 18px 0 22px; }
  .rating_profile .rating__summary .rating__info {
    right: -33px; }

.rating_profile .rating__caption {
  display: block;
  margin-bottom: 11px; }
  .rating_profile .rating__caption_accent {
    text-transform: none; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .rating_profile .rating__caption {
      font-size: 14px; } }

.rating_profile_bonus .rating__summary {
  margin-top: 0; }

.rating_profile_bonus .rating__bar {
  margin: 36px 62px 36px 46px;
  width: auto; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .rating_profile_bonus .rating__bar {
      margin: 36px 42px 36px 20px; } }
  @media only screen and (max-width: 767px) {
    .rating_profile_bonus .rating__bar {
      margin: 24px 48px 14px 18px; } }

.rating_profile_bonus .rating__percent {
  padding-top: 22px; }

.rating_profile_bonus .rating__bar + .rating__summary {
  margin-top: 52px; }
  @media only screen and (max-width: 767px) {
    .rating_profile_bonus .rating__bar + .rating__summary {
      margin-top: 28px; } }

/*fontawesome*/
.rating__icon.fa-star {
  color: #F2E419; }

.rating__icon.fa-star-o {
  color: #08203A;
  text-shadow: 1px 1px 0px #22476f;
  font-weight: bold; }

.user-panel .rating__icon {
  font-size: 11px; }

/*fontawesome*/
.tooltip {
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.17);
  text-align: left;
  z-index: 2;
  color: #fefefe;
  font-family: 'ClearSans', sans-serif;
  font-size: 14px;
  line-height: 16px;
  display: block;
  position: absolute;
  padding: 12px 14px 12px 14px;
  width: auto;
  max-width: 200px;
  white-space: normal;
  min-width: 190px;
  background-color: #305a84;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
  .icon_info.open + .tooltip {
    display: block; }
  .tooltip_right {
    right: -17px;
    transform: none; }
  .tooltip__arrow {
    display: block;
    position: absolute;
    z-index: 3;
    top: 18px;
    left: -6px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .tooltip__arrow:after {
      content: " ";
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      left: 50%;
      z-index: 3;
      margin-left: -7px;
      border-style: solid;
      border-width: 7px;
      border-color: transparent #305a84 transparent  transparent; }
    .tooltip__arrow_right {
      right: 3px;
      top: 1px;
      left: auto; }
      .tooltip__arrow_right:after {
        right: 5px;
        left: auto;
        border-color: transparent  transparent #305a84 transparent; }

.tooltip_style_flat {
  background-color: #fd941d;
  border-radius: 2px;
  box-shadow: none;
  text-shadow: none;
  color: #000;
  text-transform: uppercase;
  padding: 8px 10px;
  min-width: 168px; }

.countpad {
  display: block;
  position: relative;
  width: 48px;
  height: 45px;
  background-color: #3c5b80;
  background-image: linear-gradient(141deg, #4ea8db, #236dd2);
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.35), 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px rgba(95, 184, 239, 0.5);
  border-radius: 3px;
  transition: box-shadow 0.3s ease, border 0.3s ease;
  cursor: pointer; }
  .countpad:hover {
    box-shadow: 0 0 13px 0 rgba(145, 209, 255, 0.52);
    border: solid 1px rgba(95, 184, 239, 0.5); }
  .countpad__icon {
    position: absolute;
    background-image: url("../img/bonus.png");
    background-size: 63px 59px;
    width: 62px;
    height: 55px;
    top: 46%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .countpad__icon {
        left: 36%; } }
    @media only screen and (max-width: 767px) {
      .countpad__icon {
        left: 36%; } }
  .countpad__counter {
    width: 25px;
    height: 24px;
    position: absolute;
    top: -10px;
    right: -8px;
    border-radius: 50%;
    background-color: #e25570;
    box-shadow: inset 0 0 4px rgba(255, 255, 255, 0.97), 0 0 34px rgba(122, 247, 255, 0.58), 0 0 28px #000;
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 2px 0; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .countpad__counter {
        width: 19px;
        height: 19px;
        top: -7px;
        font-size: 13px; } }
  .countpad__title {
    display: none;
    position: relative;
    vertical-align: top;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #00a6ff;
    padding: 2px 40px; }
    @media only screen and (max-width: 767px) {
      .countpad__title {
        display: inline-block;
        font-size: 14px; } }

.nav {
  list-style-type: none;
  display: table;
  margin: 0 auto;
  padding: 0;
  padding-left: 250px;
  font-size: 0; }
  .nav_hero {
    padding-left: 0px !important;
    width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .nav {
      padding-left: 210px; } }
  @media only screen and (max-width: 767px) {
    .header__head-nav .nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); } }
  .nav__item {
    display: table-cell;
    vertical-align: top;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .nav__item {
        display: inline-block; } }
    @media only screen and (max-width: 767px) {
      .header__head-nav .nav__item {
        display: block; } }
    .nav__item:after {
      content: '-';
      display: block;
      position: absolute;
      color: #fff;
      font-size: 18px;
      top: 50%;
      left: 100%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      @media only screen and (max-width: 767px) {
        .nav__item:after {
          display: none; } }
    .nav__item:last-child:after {
      content: none; }
  .nav__link {
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.19);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    display: block;
    padding: 19px 17px;
    text-align: center;
    white-space: nowrap; }
    .nav__link-important {
      color: #e22222;
      font-weight: 900;
      transform: scale(1.2); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .nav__link {
        padding: 29px 13px;
        font-size: 15px; } }
    .nav__link_active, .nav__link:hover {
      color: #ffea00; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .header__head-nav .nav__link {
        padding: 29px 15px;
        text-transform: capitalize;
        font-size: 15px; } }
    @media only screen and (max-width: 767px) {
      .header__head-nav .nav__link {
        display: block;
        font-size: 12px;
        padding: 4px 20px;
        line-height: 12px;
        text-align: left;
        text-transform: capitalize; } }
    @media only screen and (max-width: 767px) {
      .footer__nav .nav__link {
        font-size: 10px;
        padding: 4px 20px;
        line-height: 12px;
        text-align: left; } }

.nav_footer {
  display: inline-block;
  width: auto;
  padding-left: 0px; }
  .nav_footer .nav__item {
    display: inline-block;
    vertical-align: top; }

.mobile-nav {
  display: none;
  position: relative; }
  .mobile-nav__title {
    color: #fff;
    opacity: 0.5;
    margin: 0;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    display: block;
    position: relative;
    line-height: 42px;
    white-space: nowrap; }
    .mobile-nav__title:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -19px;
      right: -19px;
      height: 2px;
      width: calc(100% + 38px);
      background-color: #183C66; }
  @media only screen and (max-width: 767px) {
    .mobile-nav {
      display: block; } }
  .mobile-nav__list {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .mobile-nav__item {
    display: block;
    position: relative;
    width: 100%; }
  .mobile-nav__link {
    height: 42px;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 44px;
    color: #00a6ff;
    display: block;
    position: relative;
    padding-left: 40px; }
    .mobile-nav__link:before {
      display: block;
      content: '';
      top: 1px;
      left: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #08203a;
      z-index: -1; }
    .mobile-nav__link_active {
      color: #fc5526;
      margin: -2px -19px;
      z-index: 2;
      height: 43px;
      line-height: 45px;
      padding-left: 59px; }
      .mobile-nav__link_active:before {
        background-color: #132e4d;
        height: 43px;
        z-index: -1; }
  .mobile-nav__icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3; }
    .mobile-nav__icon svg {
      fill: #fff;
      display: block; }
    .mobile-nav__icon_active svg {
      fill: #fc5526; }
  .mobile-nav__caption {
    color: #fc5526; }

.mobile-nav_dropdown {
  display: none; }
  .mobile-nav_dropdown.open {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #091420;
    z-index: 300;
    top: 50px;
    padding: 0 19px; }

.signup {
  position: relative;
  display: block;
  height: 43px;
  margin-top: 2px; }
  @media only screen and (max-width: 767px) {
    .modal__signup .signup {
      margin: 0 auto; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .signup {
      width: auto; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .signup {
      width: auto;
      height: 36px; } }
  @media only screen and (max-width: 767px) {
    .signup {
      width: auto; } }
  .signup__button {
    font-size: 16px;
    line-height: 16px;
    padding: 14px 9px;
    top: 0;
    left: 0;
    background-color: #e2d533;
    background-image: linear-gradient(180deg, #ff8d00, #ada434);
    z-index: 2;
    min-width: 170px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.11); }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .signup__button {
        position: relative; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .signup__button {
        position: relative;
        padding: 10px 9px; } }
    @media only screen and (max-width: 767px) {
      .head-panel__signup .signup__button {
        position: relative; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .signup__input {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .signup__input {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .head-panel__signup .signup__input {
      display: none; } }
  .signup__input .input__inner {
    padding-left: 148px;
    padding-right: 15px; }

.s_block {
  display: block;
  position: relative;
  text-align: center; }

.socials {
  display: block;
  position: relative;
  text-align: center; }
  .socials__item {
    width: 31px;
    height: 31px;
    background-color: #122d4b;
    border-radius: 2px;
    box-shadow: inset 0 1px 6px 0 #1c3652;
    border: solid 1px #435c77;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin: 6px 9px 0px 0px;
    text-align: center;
    transition: border 0.2s ease; }
    .socials__item:last-child {
      margin-right: 0; }
    .socials__item:hover {
      border: solid 1px #93d1ff !important; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .socials__item {
        margin: -2px 9px 0px 0px; } }
  .socials__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.socials_filled .socials__item {
  background-color: #4685a2;
  border: none;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }

.socials_form .socials__item {
  background-color: #091420;
  border: 1px solid #4685a2;
  border-radius: 2px;
  box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.05); }
  .socials_form .socials__item_active {
    background-color: #4ea8db;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }

.socials_small .socials__item {
  width: 36px;
  height: 36px;
  margin-right: 7px; }

.bottom-panel {
  width: 495px;
  bottom: 0;
  right: 0;
  height: auto;
  display: block;
  position: fixed;
  z-index: 200;
  background-color: #132e4d;
  border-radius: 2px;
  box-shadow: inset 0 -1px 0 rgba(91, 64, 71, 0.38), 0 1px 8px rgba(0, 0, 0, 0.53); }
  @media only screen and (max-width: 767px) {
    .bottom-panel {
      width: 100%; } }
  .bottom-panel__close {
    width: 44px;
    height: 38px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #132e4d;
    border-radius: 2px;
    box-shadow: inset 0.6px -0.8px 0 #1e3550;
    text-align: center;
    padding: 11px 0;
    cursor: pointer; }
  .bottom-panel__title {
    color: #fff000;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    text-transform: none;
    padding: 18px 44px; }
    @media only screen and (max-width: 767px) {
      .bottom-panel__title {
        font-size: 12px;
        line-height: 16px; } }
  .bottom-panel__subtitle {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    text-align: center;
    padding: 0 40px; }
  .bottom-panel__content {
    text-align: center;
    margin-top: 18px; }
  .bottom-panel__input {
    height: 40px; }
    .bottom-panel__input .input__inner {
      height: 40px;
      width: 192px;
      color: #e5e2f3; }
  .bottom-panel__button {
    min-width: 70px;
    height: 40px;
    margin-top: 0; }
  .bottom-panel__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 288px; }
  .bottom-panel__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top; }
  .bottom-panel__bonus-note {
    display: block;
    vertical-align: top;
    color: #ccc;
    font-size: 11px;
    font-weight: 400;
    padding-left: 30px; }

.wish-bonus,
.webim-button-slider {
  z-index: 99 !important; }
  @media only screen and (max-width: 767px) {
    .wish-bonus,
    .webim-button-slider {
      z-index: 100 !important; } }

.wish-bonus {
  display: block;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 44px;
  min-height: 191px;
  transition: width ease-in-out 0.3s;
  z-index: 200; }
  .wish-bonus.open {
    width: 760px;
    z-index: 200; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .wish-bonus.open {
        width: 700px; } }
    @media only screen and (max-width: 767px) {
      .wish-bonus.open {
        width: 100%; } }
    .wish-bonus.open .wish-bonus__content-block {
      transform: translateX(0);
      opacity: 1; }
    .wish-bonus.open .wish-bonus__close {
      opacity: 1; }
  .wish-bonus__close {
    width: 44px;
    height: 38px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    text-align: center;
    padding: 11px 0;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .wish-bonus__close {
        left: 0;
        right: auto; } }
  .wish-bonus__content-block {
    display: block;
    width: 760px;
    opacity: 0;
    transform: translateX(-716px);
    transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s;
    background-color: #132e4d;
    box-shadow: 8px 6px 8px #040f21, inset 0 1px 0 #132e4d; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .wish-bonus__content-block {
        width: 700px; } }
    @media only screen and (max-width: 767px) {
      .wish-bonus__content-block {
        width: 100%; } }
  .wish-bonus__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .wish-bonus__bonus-label {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 44px;
    height: 190px;
    z-index: 2;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
    background-image: linear-gradient(180deg, #1678be 0%, #1c4480 100%);
    box-shadow: 0 0 6px 8px rgba(0, 0, 0, 0.2), inset 0 1px 0 #4d98ce;
    transition: left ease-in-out 0.2s; }
    .wish-bonus.open .wish-bonus__bonus-label {
      left: -60px; }
    @media only screen and (max-width: 767px) {
      .wish-bonus__bonus-label {
        height: 110px;
        width: 34px; } }
    .wish-bonus__bonus-label span {
      display: block;
      white-space: nowrap;
      position: relative;
      width: 100%;
      color: #fff;
      font-size: 17px;
      top: -7px;
      font-weight: 700;
      transform-origin: 85px 75px;
      transform: rotate(-90deg);
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .wish-bonus__bonus-label span {
          font-size: 11px;
          top: 0px;
          transform-origin: 52px 42px;
          letter-spacing: 0px;
          font-family: sans-serif; } }
      .wish-bonus__bonus-label span.small-txt {
        font-size: 14px;
        top: 0px; }
        @media only screen and (max-width: 767px) {
          .wish-bonus__bonus-label span.small-txt {
            font-size: 9px;
            top: 1px; } }
  .wish-bonus__head {
    width: 100%; }
    .wish-bonus__head:after {
      display: none; }
  .wish-bonus__title {
    text-align: center;
    text-transform: none;
    padding: 18px 40px 7px;
    text-shadow: 0 2px 1px rgba(9, 32, 58, 0.83);
    color: #fff000;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px; }
    @media only screen and (max-width: 767px) {
      .wish-bonus__title {
        font-size: 13px;
        line-height: 16px; } }
  .wish-bonus__subtitle {
    display: block;
    color: #fff;
    text-transform: none;
    text-align: center;
    padding: 0 40px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px; }
    @media only screen and (max-width: 767px) {
      .wish-bonus__subtitle {
        font-size: 12px;
        line-height: 16px; } }
  .wish-bonus__content {
    text-align: center;
    margin-top: 10px; }
  .wish-bonus__input {
    height: 42px; }
    .wish-bonus__input .input__inner {
      width: 192px;
      color: #e5e2f3;
      height: 42px;
      box-shadow: 0 1px 1px rgba(43, 42, 70, 0.46), inset 0 1px 1px rgba(0, 0, 0, 0.79);
      border-radius: 2px;
      background-color: #133966; }
      @media only screen and (max-width: 767px) {
        .wish-bonus__input .input__inner {
          width: 170px; } }
  .wish-bonus__button {
    min-width: 70px;
    height: 42px;
    margin-top: 0;
    text-shadow: 0 1px 0 #be2f21;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase; }
  .wish-bonus__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 288px; }
  .wish-bonus__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top; }
  .wish-bonus__bonus-note {
    display: block;
    vertical-align: top;
    color: #83a6d1;
    font-size: 11px;
    font-weight: 400;
    padding-left: 30px; }
  .wish-bonus__link {
    display: inline-block;
    vertical-align: top;
    line-height: 42px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    color: #9cc1e2;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    margin-left: 28px;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .wish-bonus__link {
        display: block;
        margin: 0; } }

.hero {
  display: block;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .hero {
      max-width: 480px;
      margin: 0 auto; } }
  .hero__slider {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 940px;
    height: 390px;
    display: block;
    overflow: hidden;
    margin: 0 auto; }
    @media only screen and (min-width: 1440px) {
      .hero__slider {
        width: 1420px;
        overflow: visible; }
        .hero__slider .slick-list {
          overflow: visible; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .hero__slider {
        width: 1180px;
        height: 324px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .hero__slider {
        height: 258px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .hero__slider {
        width: 700px;
        height: 192px; } }
    @media only screen and (max-width: 767px) {
      .hero__slider {
        height: auto;
        width: 100%;
        position: absolute; } }
  .hero__wrap {
    position: relative;
    min-height: 450px;
    transition: height 0.3s; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .hero__wrap {
        min-height: 382px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .hero__wrap {
        min-height: 318px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .hero__wrap {
        min-height: 250px; } }
    @media only screen and (max-width: 767px) {
      .hero__wrap {
        min-height: 300px;
        width: 100% !important; } }
    @media only screen and (max-width: 767px) {
      .hero__wrap__tournament {
        min-height: 205px; } }
    .hero__wrap_subnav-open {
      height: 332px; }
  .hero__nav {
    z-index: 2;
    display: block;
    position: absolute;
    text-align: left;
    width: 100%;
    top: 390px;
    left: 0;
    right: 0; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .hero__nav {
        top: 324px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .hero__nav {
        top: 258px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .hero__nav {
        top: 192px; } }
    @media only screen and (max-width: 767px) {
      .hero__nav {
        top: 250px; } }
    @media only screen and (max-width: 767px) {
      .hero__nav__tournament {
        top: 150px; } }
    .hero__nav_sticky {
      position: fixed;
      top: 0;
      width: 940px;
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 10; }
      @media only screen and (min-width: 1440px) {
        .hero__nav_sticky {
          width: 1420px; } }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .hero__nav_sticky {
          width: 1180px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .hero__nav_sticky {
          width: 700px; } }
      @media only screen and (max-width: 767px) {
        .hero__nav_sticky {
          display: none; } }
  .hero__subnav {
    display: block;
    text-align: center;
    background-color: #365477;
    height: 0;
    transition: height 0.1s; }
    @media only screen and (max-width: 767px) {
      .hero__subnav {
        display: none; } }
    .hero__subnav .nav__link {
      padding: 14px 28px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .hero__subnav .nav__link {
          padding: 14px 21px; } }
    .hero__nav_sticky .hero__subnav {
      height: 44px; }
  .hero .hero__nav_scroll:after {
    display: block;
    background: linear-gradient(to right, rgba(15, 41, 71, 0.01) 0%, #0f2947 70%, #0f2947 100%);
    box-shadow: 0 4px 0 0 #17386d, inset 0 1px 0 0 #4d98ce, inset 0 -1px 0 0 rgba(250, 250, 250, 0.1);
    width: 35px;
    height: 100%;
    bottom: 0;
    right: 0;
    content: '';
    position: absolute;
    z-index: 2; }
  .hero__counter {
    display: none;
    z-index: 2;
    width: 347px;
    height: 121px;
    position: absolute;
    top: 0;
    right: -12px;
    padding: 41px 30px 0 30px;
    border-radius: 4px;
    background-image: url("../img/counter-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../img/counter-bg.png");
    background-size: 347px 121px;
    background-position: top center;
    background-repeat: no-repeat; }
    @media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3 / 2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5) {
      .hero__counter {
        background-image: url("../img/counter-bg@2x.png"); } }
    @media only screen and (max-width: 767px) {
      .hero__counter {
        background-image: url("../img/counter-bg-small.png");
        width: 300px;
        height: 114px;
        right: 0;
        margin: 0 auto;
        position: relative;
        padding: 0;
        background-position: 320px 124px;
        background-image: url("../img/counter-bg-small.png");
        background-size: 320px 124px;
        background-position: top center;
        background-repeat: no-repeat; } }
  .hero__countdown {
    top: 78px;
    position: absolute;
    left: 17px; }
    @media only screen and (max-width: 767px) {
      .hero__countdown {
        left: 0;
        right: 0;
        top: 75px;
        text-align: center; } }
  .hero__countnote {
    text-shadow: 0 2px 0 rgba(12, 12, 12, 0.18);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    float: left; }
    @media only screen and (max-width: 767px) {
      .hero__countnote {
        padding: 41px 0 0 12px;
        font-size: 18px; } }
  .hero__countbutton {
    float: right;
    margin-top: 2px;
    background-color: #185e9c;
    font-size: 12px;
    line-height: 20px;
    text-shadow: none;
    text-align: center;
    padding: 0;
    font-family: 'ClearSans',sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    width: 74px;
    height: 21px;
    border-radius: 1px;
    background-image: linear-gradient(to bottom, #2b85c9, #193a62);
    box-shadow: 0 -1px 0 0 #79c6ff, 0 2px 0 0 #152f50, inset 0 1px 7px 0 rgba(255, 255, 255, 0.3); }
    @media only screen and (max-width: 767px) {
      .hero__countbutton {
        margin: 43px 10px 0 0; } }

.hero-promo {
  display: block;
  position: relative;
  overflow: hidden;
  height: 484px; }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .hero-promo {
      height: 431px; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .hero-promo {
      height: 370px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .hero-promo {
      height: 230px; } }
  @media only screen and (max-width: 767px) {
    .hero-promo {
      max-width: 480px;
      height: 230px;
      margin: 0 auto; } }
  .hero-promo__hero {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 483px;
    display: block;
    left: 50%;
    transform: translateX(-50%); }
    .hero-promo__hero:before {
      width: 80%;
      height: 1px;
      position: absolute;
      top: 0;
      left: 50%;
      right: 0;
      content: '';
      background: radial-gradient(ellipse at center, #05c0f6 0%, rgba(0, 210, 255, 0.46) 45%, rgba(79, 185, 242, 0) 82%, rgba(118, 173, 236, 0) 100%);
      border-radius: 100%;
      box-shadow: 0 3px 10px 0 #3094df;
      z-index: 2;
      transform: translateX(-50%); }
    .hero-promo__hero:after {
      width: 80%;
      height: 1px;
      position: absolute;
      top: 100%;
      left: 50%;
      right: 0;
      content: '';
      background: radial-gradient(ellipse at center, #05c0f6 0%, rgba(0, 210, 255, 0.46) 45%, rgba(79, 185, 242, 0) 82%, rgba(118, 173, 236, 0) 100%);
      border-radius: 100%;
      box-shadow: 0 -3px 10px 0 #3094df;
      transform: translateX(-50%); }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .hero-promo__hero {
        height: 430px; }
        .hero-promo__hero .hero-promo__img {
          height: 430px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .hero-promo__hero {
        height: 369px; }
        .hero-promo__hero .hero-promo__img {
          height: 369px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .hero-promo__hero {
        height: 229px; }
        .hero-promo__hero .hero-promo__img {
          height: 229px; } }
    @media only screen and (max-width: 767px) {
      .hero-promo__hero {
        height: 229px; }
        .hero-promo__hero .hero-promo__img {
          height: 229px; } }
  .hero-promo__item {
    position: absolute;
    padding-top: 1px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; }
    .hero-promo__item:before {
      content: '';
      left: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to left, transparent 0%, #08203a 100%); }
    .hero-promo__item:after {
      content: '';
      right: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to right, transparent 0%, #08203a 100%); }
    @media only screen and (max-width: 767px) {
      .hero-promo__item {
        display: none; } }
    .hero-promo__item img {
      max-width: 100%; }
  .hero-promo__item__mob {
    display: none;
    text-align: center;
    position: relative; }
    .hero-promo__item__mob:before {
      content: '';
      left: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to left, transparent 0%, #08203a 100%); }
    .hero-promo__item__mob:after {
      content: '';
      right: -1px;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 2;
      width: 147px;
      background-image: linear-gradient(to right, transparent 0%, #08203a 100%); }
    @media only screen and (max-width: 767px) {
      .hero-promo__item__mob {
        display: block; } }
  .hero-promo__wrap {
    position: relative;
    transition: height 0.3s;
    margin-bottom: 61px; }
    @media only screen and (max-width: 767px) {
      .hero-promo__wrap {
        margin-bottom: 32px; } }
  .hero-promo__button {
    position: absolute;
    z-index: 1;
    width: 481px;
    height: 103px;
    background-color: #0c233c;
    border: 1px solid rgba(0, 175, 221, 0.9);
    border-radius: 4px;
    box-shadow: 0 1px 4px 3px rgba(0, 0, 0, 0.63);
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    margin-bottom: -51px;
    padding: 20px 10px 8px;
    text-align: center; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .hero-promo__button {
        width: 436px;
        height: 99px;
        margin-bottom: -49px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .hero-promo__button {
        width: 288px;
        height: 78px;
        padding: 10px 10px 8px;
        margin-bottom: -39px; } }
    @media only screen and (max-width: 767px) {
      .hero-promo__button {
        width: 178px;
        height: 48px;
        padding: 5px 5px 5px;
        margin-bottom: -24px; } }
    .hero-promo__button__title {
      color: #fff001;
      font-size: 30px;
      font-weight: 900;
      font-family: 'ClearSans', sans-serif;
      line-height: 24px;
      margin-bottom: 10px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .hero-promo__button__title {
          font-size: 26px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .hero-promo__button__title {
          font-size: 17px;
          margin-bottom: 5px; } }
      @media only screen and (max-width: 767px) {
        .hero-promo__button__title {
          font-size: 10px;
          margin-bottom: 5px;
          line-height: 14px; } }
    .hero-promo__button__subtitle {
      color: #fff001;
      font-size: 18px;
      font-weight: 900;
      line-height: 36px;
      font-family: 'ClearSans', sans-serif;
      text-transform: uppercase; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .hero-promo__button__subtitle {
          font-size: 14px;
          line-height: 24px; } }
      @media only screen and (max-width: 767px) {
        .hero-promo__button__subtitle {
          font-size: 10px;
          line-height: 16px; } }

.countdown {
  display: block;
  position: relative;
  font-size: 0; }
  .countdown__item {
    display: inline-block;
    width: 32px;
    height: 50px;
    line-height: 44px;
    border-radius: 2px;
    text-shadow: 0 2px 0 rgba(12, 12, 12, 0.33);
    color: #ddd;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    background-image: url("../img/svg/counter.svg");
    background-position: -3px 0;
    background-size: 38px;
    background-repeat: no-repeat;
    margin-right: 5px; }
    .countdown__item:last-child {
      margin-right: 0; }
    @media only screen and (max-width: 767px) {
      .countdown__item {
        background-image: url("../img/svg/counter.svg");
        width: 28px;
        height: 37px;
        background-position: center top;
        background-size: 34px;
        font-size: 26px;
        line-height: 37px; } }
  .countdown__divider {
    display: inline-block;
    vertical-align: top;
    padding: 0 4px 0 1px; }
    .countdown__divider:before {
      content: ',';
      display: block;
      height: 44px;
      color: #fff;
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .countdown__divider:before {
          height: 32px;
          font-size: 20px;
          line-height: 32px; } }

.main-nav {
  display: table;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  width: 100%;
  background-color: #09203a;
  border-radius: 5px;
  overflow: hidden; }
  .main-nav::before {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    height: 2px;
    background-image: linear-gradient(90deg, rgba(87, 185, 220, 0.3), #35d0ff 50%, rgba(87, 185, 220, 0.3));
    box-shadow: -2px 10px 61px 7px #3fc8fa; }
  @media screen and (max-width: 430px) {
    .main-nav {
      display: block;
      white-space: nowrap; } }
  .main-nav__subnav.subnav {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .main-nav__subnav.subnav {
        display: block;
        height: auto;
        width: 100vw;
        max-width: 480px;
        padding: 5px; } }
    @media only screen and (max-width: 767px) {
      .main-nav__subnav.subnav .subnav__item {
        display: inline-block;
        vertical-align: top;
        width: auto; } }
    .hero__nav_sticky .main-nav__subnav.subnav {
      top: 100%;
      opacity: 0;
      visibility: hidden; }
    .main-nav__item_subnav:hover .main-nav__subnav.subnav {
      top: 100%;
      opacity: 1;
      visibility: visible; }
    .hero__nav_sticky .main-nav__item_active .main-nav__subnav.subnav {
      top: 100%;
      opacity: 1;
      visibility: visible; }
  .main-nav__item {
    display: table-cell;
    vertical-align: top;
    width: auto;
    white-space: normal; }
    @media screen and (max-width: 430px) {
      .main-nav__item {
        display: inline-block; } }
    .main-nav__item:after {
      content: '';
      font-size: 18px;
      color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .main-nav__item:first-child:after {
      content: none; }
    .main-nav__item_active:after, .main-nav__item_active + .main-nav__item:after {
      content: none; }
  .main-nav__link {
    display: block;
    position: relative;
    text-align: center;
    padding: 22px 36px;
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.19);
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    z-index: 2;
    height: 55px; }
    .main-nav__link:hover {
      color: #ffea00; }
      .main-nav__link:hover .main-nav__img {
        background-image: url("../img/nav_img/sprite_active.png"); }
      .main-nav__link:hover:after {
        color: #fff; }
    .main-nav__item:first-child .main-nav__link {
      padding-top: 22px;
      padding-bottom: 43px; }
    .main-nav__item_active .main-nav__link {
      color: yellow; }
      .main-nav__item_active .main-nav__link:after {
        content: none; }
      .main-nav__item_active .main-nav__link .main-nav__img {
        background-image: url("../img/nav_img/sprite_active.png"); }
    @media only screen and (min-width: 1440px) {
      .main-nav__link {
        padding-bottom: 43px;
        padding-left: 50px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .main-nav__link {
        padding-bottom: 43px;
        padding-left: 70px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .main-nav__link {
        padding-bottom: 43px;
        padding-left: 47px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .main-nav__link {
        font-size: 14px;
        padding: 21px 25px;
        padding-bottom: 41px; } }
    @media only screen and (max-width: 767px) {
      .main-nav__item .main-nav__link {
        padding: 16px 8px;
        font-size: 12px; }
      .main-nav__item:first-child .main-nav__link {
        padding: 16px 8px;
        font-size: 12px; } }
  .main-nav__img {
    display: inline-block;
    background-image: url("../img/nav_img/sprite_inactive.png");
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
    text-indent: -9999px;
    text-align: left;
    position: absolute;
    top: 4px;
    left: 23px;
    width: 79px;
    height: 53px; }
    .main-nav__img__like {
      background-position: -2px -9px; }
    .main-nav__img__slots {
      background-position: -9px -298px; }
    .main-nav__img__jackpot {
      background-position: -10px -150px; }
    .main-nav__img__bord {
      background-position: -10px -223px; }
    .main-nav__img__fav {
      background-position: -10px -78px; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .main-nav__img {
        left: 0;
        width: 70px; }
        .main-nav__img__like {
          background-position: -6px -6px; }
        .main-nav__img__slots {
          background-position: -10px -263px; }
        .main-nav__img__jackpot {
          background-position: -10px -130px; }
        .main-nav__img__bord {
          background-position: -10px -195px; }
        .main-nav__img__fav {
          background-position: -10px -67px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .main-nav__img {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .main-nav__img {
        display: none; } }

.subnav {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 40px;
  background-color: #0f2947;
  border-radius: 0 0 3px 3px;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.04); }
  .subnav__item {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 3%;
    white-space: nowrap; }
    .subnav__item:after {
      content: '-';
      color: #fff;
      font-size: 14px;
      line-height: 41px;
      font-family: inherit;
      display: block;
      position: absolute;
      bottom: 0;
      left: 100%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
      @media only screen and (max-width: 767px) {
        .subnav__item:after {
          line-height: 28px; } }
    .subnav__item:last-child:after {
      content: none; }
  .subnav__link {
    display: block;
    text-align: center;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff; }
    .subnav__link_active {
      color: #f96915 !important; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .subnav__link {
        font-size: 12px; } }
    @media only screen and (max-width: 767px) {
      .subnav__link {
        font-size: 12px;
        padding: 4px 10px; } }
    .subnav__item:hover .subnav__link {
      color: #6eff00; }
      .subnav__item:hover .subnav__link:after {
        color: #fff; }

.winsline {
  display: block;
  position: relative;
  font-size: 0;
  width: 959px;
  overflow: hidden;
  margin-right: -19px; }
  @media only screen and (min-width: 1440px) {
    .winsline {
      width: 1440px;
      margin-right: -20px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .winsline {
      width: 1203px;
      margin-right: -23px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .winsline {
      width: 719px; } }
  @media only screen and (max-width: 767px) {
    .winsline {
      width: calc(100% + 20px);
      margin-right: -20px; } }
  .winsline__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 76px;
    padding-right: 19px; }
    @media only screen and (min-width: 1440px) {
      .winsline__item {
        padding-right: 20px; } }
    @media only screen and (max-width: 767px) {
      .winsline__item {
        height: 64px; } }
  .winsline__block {
    background-color: #15355a;
    border-radius: 2px;
    height: 100%;
    transition: background-color ease 0.2s;
    text-decoration: none; }
    .winsline__item:hover .winsline__block {
      background-color: #1f4c7e; }
  .winsline__img {
    width: 88px;
    height: 76px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    border: 3px solid #2c82ff; }
    @media only screen and (max-width: 767px) {
      .winsline__img {
        width: 64px;
        height: 63px; } }
  .winsline__overlay {
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.47), 0 2px 3px rgba(6, 23, 42, 0.26);
    transition: background-color ease 0.1s;
    background-color: transparent;
    border-radius: 2px;
    width: 82px;
    height: 71px;
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden; }
    @media only screen and (max-width: 767px) {
      .winsline__overlay {
        width: 64px;
        height: 63px; } }
    .winsline__item:hover .winsline__overlay {
      background-color: rgba(0, 0, 0, 0.63);
      opacity: 1;
      visibility: visible; }
      @media only screen and (max-width: 767px) {
        .winsline__item:hover .winsline__overlay {
          opacity: 0;
          visibility: hidden; } }
  .winsline__button {
    display: block;
    position: absolute;
    bottom: 3px;
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%); }
  .winsline__content {
    display: block;
    position: relative;
    line-height: 20px;
    height: 76px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 13px 10px 97px; }
    @media only screen and (min-width: 1440px) {
      .winsline__content {
        padding: 9px 13px 10px 105px; } }
    @media only screen and (max-width: 767px) {
      .winsline__content {
        padding: 8px 13px 8px 80px;
        height: 64px; } }
  .winsline__title {
    color: #fff;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .winsline__title {
        font-size: 10px;
        line-height: 12px;
        padding-top: 1px;
        margin-right: 11px; } }
    .winsline__title_color_yellow {
      color: #ffe400; }
  .winsline__note {
    color: #01ff25;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    text-overflow: ellipsis;
    display: block;
    float: left;
    overflow: hidden;
    max-width: 74px;
    white-space: nowrap; }
    @media only screen and (max-width: 767px) {
      .winsline__note {
        line-height: 14px;
        font-size: 10px; } }
    .winsline__note_small {
      color: #3c90f0;
      text-transform: lowercase;
      margin-left: 4px; }

.preview {
  position: relative;
  background-color: #08203a;
  border: 1px solid #15355a;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }
  .preview__item {
    display: block;
    position: relative; }
  .preview__img {
    max-width: 100%;
    width: 100%;
    display: block;
    max-height: 188px; }
  .preview__overlay {
    display: block;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all ease 0.1s;
    z-index: -1; }
    .preview__item:hover .preview__overlay {
      background-color: rgba(0, 0, 0, 0.73);
      box-shadow: inset 0 0 2.9px 1.1px rgba(255, 255, 255, 0.28), inset 0 1px 0 rgba(255, 255, 255, 0.08), 0 2px 1px rgba(13, 25, 39, 0.37);
      opacity: 1;
      z-index: 1; }
  .preview__action {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .preview__button {
    font-size: 15px;
    width: 105px;
    height: 34px;
    padding: 7px 15px;
    display: inline-block;
    white-space: nowrap; }
    .preview__button_demo {
      padding: 6px 9px;
      margin-top: 11px; }
  .preview__icon {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 12px;
    cursor: pointer; }
  .preview__icon.fa {
    font-size: 25px;
    color: #ffffff; }
  .preview__icon.fa.in_favorites {
    color: #fff001; }
  .preview__info {
    padding: 10px 13px;
    text-align: left; }
  .preview__title {
    text-shadow: 0 2px 0 #111c28;
    color: #a2bedc;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    max-width: calc(100% - 40px); }
  .preview__note {
    text-shadow: 0 1px 0 #0a1829;
    color: #4d7bb0;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 5px 0 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 40px); }
    @media only screen and (max-width: 767px) {
      .preview__note {
        max-width: 100%; } }

.search {
  display: block;
  position: relative; }
  .search:after {
    content: "";
    width: 100%;
    height: 2px;
    background-image: linear-gradient(90deg, #04469c 0%, #52baff 50%, #04469c 100%);
    display: block;
    position: absolute; }
  .search__button {
    display: block;
    height: 41px;
    width: 41px;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0; }
    .search__button:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 4px;
      width: 41px;
      height: 41px;
      background-image: url("../img/find_icon.png");
      background-size: 41px 41px; }
  .search__input {
    width: 100%;
    height: 40px;
    background-color: #09203a;
    border-radius: 2px;
    box-shadow: inset 0 1px 0 #152231, 0 1px 0 #354c68;
    color: #a0c6f3;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: none;
    padding: 11px 37px 11px 13px; }
    .search__input::-webkit-input-placeholder {
      opacity: 0.5;
      -webkit-transition: opacity .5s;
      transition: opacity .5s; }
    .search__input::placeholder {
      opacity: 0.5;
      transition: opacity .5s; }
    .search__input:-ms-input-placeholder {
      opacity: 0.5;
      -ms-transition: opacity .5s;
      transition: opacity .5s; }
    .search__input:focus::placeholder {
      opacity: 0; }
    .search__input:focus::-webkit-input-placeholder {
      opacity: 0; }
    .search__input:focus:-ms-input-placeholder {
      opacity: 0; }

.tournament {
  display: block;
  position: relative;
  text-align: center; }
  .tournament_status {
    margin-bottom: 8px; }
  .tournament_name {
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'ClearSans',sans-serif;
    margin-bottom: 36px;
    color: white; }
    .tournament_name:focus {
      color: white; }
    .tournament_name a {
      color: #fff; }
    .tournament_name h2 {
      font-size: 20px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .tournament_name h2 {
          max-width: 260px; } }
      @media only screen and (max-width: 767px) {
        .tournament_name h2 {
          position: absolute;
          top: 10px;
          left: 145px;
          max-width: calc(100% - 200px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 5px; } }
  @media only screen and (max-width: 767px) {
    .tournament_vip-levels {
      position: absolute;
      top: 34px;
      left: 145px; } }
  .tournament__promo {
    display: block;
    position: relative;
    padding: 0 20px; }
    .tournament__promo .tournament__prize {
      color: white;
      font-size: 20px;
      margin-bottom: 5px; }
      .tournament__promo .tournament__prize__name {
        color: white;
        font-size: 18px; }
  .tournament__head {
    position: relative;
    overflow: hidden;
    border-radius: 2px; }
    .tournament__head .tournament__name {
      color: #ffd801;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 5px; }
  .tournament__backnote {
    position: absolute;
    display: block;
    bottom: -7px;
    left: -9px;
    font-size: 28px;
    font-weight: 700;
    color: #355a83;
    text-transform: uppercase;
    z-index: 1; }
  .tournament__title {
    text-shadow: 0 2px 0 rgba(13, 25, 39, 0.23);
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 7px 0;
    z-index: 1;
    position: relative; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .tournament__title {
        padding: 16px 0;
        white-space: nowrap;
        font-size: 17px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tournament__title {
        padding: 16px 0;
        white-space: nowrap;
        font-size: 17px; } }
  .tournament__img-overlay {
    border-radius: 2px;
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 0; }
  .tournament__img {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%;
    height: 100%; }
  .tournament__button {
    margin-bottom: 10px;
    z-index: 1; }
  .tournament__timer {
    padding-top: 20px;
    height: 155px;
    background-position: center;
    background-repeat: no-repeat; }
  .tournament__table {
    margin-top: -17px;
    position: relative;
    padding: 0 20px; }
  .tournament__main {
    margin-top: 27px; }
  .tournament__panel {
    margin-top: 24px; }
  .tournament__yourposition h3 {
    color: #fff;
    margin: 0px auto 5px auto; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .tournament__yourposition h3 {
        margin: 5px 0px; } }
  .tournament__yourposition .table-wrap {
    padding: 20px;
    padding-top: 0; }
  .tournament__yourposition table {
    background: #132d4b;
    color: #88add7;
    max-width: 100%;
    width: 100%;
    border: 0; }
    .tournament__yourposition table td {
      padding: 0 5px;
      font-size: 14px; }
      .tournament__yourposition table td:nth-child(n+2) {
        overflow: hidden;
        max-width: 69px;
        text-overflow: ellipsis;
        white-space: nowrap; }
  .tab__content .active .tournament_vip-level {
    width: 30px;
    height: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    background: #042b52;
    color: #1d487a;
    font-weight: 900;
    font-size: 25px;
    border-radius: 3px;
    margin: 0; }
    @media only screen and (max-width: 767px) {
      .tab__content .active .tournament_vip-level {
        width: 20px;
        height: auto;
        font-size: 17px; } }
    .tab__content .active .tournament_vip-level.active {
      background: #336daf;
      color: #fff;
      filter: drop-shadow(0 4px 10px #001327);
      display: inline-block; }
    @media only screen and (max-width: 767px) {
      .tab__content .active .tournament_vip-levels {
        max-width: calc(100% - 180px); } }
    .tab__content .active .tournament_vip-levels > p {
      color: #6a8bc5;
      margin-bottom: 7px;
      font-size: 15px;
      margin-top: 15px; }
      @media only screen and (max-width: 767px) {
        .tab__content .active .tournament_vip-levels > p {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; } }
  .tournament_summa {
    margin-top: 25px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tournament_summa {
        text-align: center; } }
    @media only screen and (max-width: 767px) {
      .tournament_summa {
        text-align: center; } }
    .tournament_summa .countdown__divider:before {
      content: ''; }
    .tournament_summa .countdown span {
      font-size: 40px;
      color: #fcc807;
      font-family: "ClearSans", sans-serif;
      font-weight: 700; }
      .tournament_summa .countdown span:last-child {
        color: #366297;
        font-size: 29px;
        margin-left: 10px; }
  .tournament_description {
    color: #8aadee;
    font-size: .95em;
    text-align: justify; }
  .tournament_status .active-tournament__status,
  .tournament_status .future-tournament__status,
  .tournament_status .finished-tournament__status {
    font-size: 16px;
    padding-right: 53px;
    padding-top: 17px;
    padding-bottom: 15px;
    text-align: right; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tournament_status .active-tournament__status,
      .tournament_status .future-tournament__status,
      .tournament_status .finished-tournament__status {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 73px; } }
    @media only screen and (max-width: 767px) {
      .tournament_status .active-tournament__status,
      .tournament_status .future-tournament__status,
      .tournament_status .finished-tournament__status {
        position: absolute;
        top: -11px;
        right: 0;
        font-size: 0; } }
    .tournament_status .active-tournament__status span,
    .tournament_status .future-tournament__status span,
    .tournament_status .finished-tournament__status span {
      color: #8aadee;
      font-size: 0.95em; }
    .tournament_status .active-tournament__status img,
    .tournament_status .future-tournament__status img,
    .tournament_status .finished-tournament__status img {
      position: absolute;
      top: 15px;
      right: 10px;
      width: 51px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .tournament_status .active-tournament__status img,
        .tournament_status .future-tournament__status img,
        .tournament_status .finished-tournament__status img {
          right: 10px;
          width: 51px; } }
      @media only screen and (max-width: 767px) {
        .tournament_status .active-tournament__status img,
        .tournament_status .future-tournament__status img,
        .tournament_status .finished-tournament__status img {
          right: 10px;
          top: 20px;
          width: 40px; } }
  .tournament_status .active-tournament__status {
    color: #00ffbb; }
  .tournament_status .future-tournament__status {
    color: #ff9500; }
  .tournament_status .finished-tournament__status {
    color: #ff364b; }

.tournament_gameplay {
  margin-top: 10px; }
  .tournament_gameplay .tournament__timer {
    padding-top: 0px;
    height: 83px; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .tournament_gameplay .tournament__timer {
        height: 83px; } }
    .tournament_gameplay .tournament__timer .timer__row_caption .timer__cell {
      font-size: 18px; }
    .tournament_gameplay .tournament__timer .timer__cell {
      font-size: 30px; }
    .tournament_gameplay .tournament__timer .timer__note {
      padding-bottom: 0; }
  .tournament_gameplay .tournament__table {
    margin-top: 7px;
    max-height: 122px;
    overflow: hidden; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .tournament_gameplay .tournament__table {
        max-height: 115px; } }
    .tournament_gameplay .tournament__table .table__row {
      height: 31px; }
  .tournament_gameplay .tournament__img-overlay {
    height: 190px;
    width: 190px;
    margin: 0px auto; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .tournament_gameplay .tournament__img-overlay {
        height: 180px;
        width: 180px; } }
  .tournament_gameplay .table__body .table__cell:last-child {
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis; }

.tournament__attention-prize {
  margin: -20px auto; }
  .tournament__attention-prize > * {
    display: inline-block;
    vertical-align: middle; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tournament__attention-prize > * {
        margin: -10px 0; } }
    @media only screen and (max-width: 767px) {
      .tournament__attention-prize > * {
        margin: -20px 0; } }
  .tournament__attention-prize span {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700; }
    .tournament__attention-prize span small {
      color: #8aadee;
      font-size: 72%; }

.tournament__table .table__headrow .table__cell {
  padding: 0 10px;
  text-align: right; }
  .tournament__table .table__headrow .table__cell:nth-child(2) {
    padding: 0;
    text-align: left; }
  .tournament_undefined .tournament__table .table__headrow .table__cell {
    text-align: center; }

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .tournament__table .table__body .table__cell:last-child {
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis; } }

.timer {
  display: block;
  position: relative; }
  .timer__note {
    text-shadow: 0 1px 0 #243344;
    color: #ffd801;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 5px; }
    .timer__note_large {
      font-size: 14px; }
  .timer__table {
    display: table;
    margin: 0 auto; }
  .timer__row {
    display: table-row; }
  .timer__cell {
    display: table-cell; }
    .timer__row_digit .timer__cell {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.26);
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: 'ClearSans', sans-serif; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .timer__row_digit .timer__cell {
          font-size: 28px; } }
    .timer__row_caption .timer__cell {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
      color: #ffd801;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center; }
    .timer__cell_empty {
      padding: 0 12px 0 0; }

.footer {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 37px;
  text-align: center; }
  .footer__head {
    width: 100%;
    height: 93px;
    background-color: #091b2f;
    box-shadow: inset 0 1px 0 0 #18385f;
    text-align: center;
    margin-top: 178px; }
    @media only screen and (max-width: 767px) {
      .footer__head {
        width: 100%;
        height: 43px;
        margin-top: 76px; } }
  .footer__logo {
    display: block;
    top: -80px;
    left: 50%;
    margin-left: 36px;
    transform: translateX(-50%);
    position: absolute;
    z-index: 2;
    width: 215px;
    height: 140px; }
    @media only screen and (max-width: 767px) {
      .footer__logo {
        width: 120px;
        height: 85px;
        top: -48px;
        margin-left: 0; } }
  .footer__nav {
    background-color: #102a47;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.24);
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .footer__nav {
        padding: 6px 4px; } }
    @media only screen and (max-width: 767px) {
      .footer__nav .nav {
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 300px; } }
    .footer__nav:before {
      content: "";
      width: 45%;
      margin: 0px auto;
      height: 3px;
      background-image: linear-gradient(90deg, #a1efeb0f 0%, #52baff 50%, #a1efeb0f 100%);
      display: block;
      -webkit-box-shadow: 0 29px 84px 3px #1fa9db;
      -moz-box-shadow: 0 29px 84px 3px #1fa9db;
      box-shadow: 0 29px 84px 3px #1fa9db; }
      @media only screen and (max-width: 767px) {
        .footer__nav:before {
          margin-top: -6px;
          margin-bottom: 8px; } }
  .footer__icons {
    display: block;
    margin: 30px auto; }
  .footer__cell {
    display: inline-block;
    vertical-align: middle;
    padding-right: 18px; }
    .footer__cell:last-child {
      padding-right: 0; }
  .footer__rules {
    color: #435872;
    font-size: 12px;
    font-weight: 400;
    max-width: 720px;
    margin: 0 auto; }

.info-panel {
  display: block;
  position: relative;
  width: 440px; }
  .info-panel__header {
    padding: 26px;
    background-color: #0b2540;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.65);
    text-align: center; }
  .info-panel__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
    font-size: 19px;
    font-family: 'ClearSans', sans-serif;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff; }
    .info-panel__title_important {
      color: #ff0000;
      margin-left: 5px; }
  .info-panel__caption {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
    font-family: 'ClearSans', sans-serif;
    color: #fff;
    font-size: 17px;
    line-height: 20px; }
  .info-panel__content {
    width: 440px;
    height: 89px;
    padding: 24px 42px;
    background-color: #3889d6;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }

.alert-panel {
  background-color: #1f3756;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.08);
  padding: 18px 0;
  width: 100%; }
  .alert-panel__inner {
    width: 1024px;
    margin: 0 auto;
    display: table; }
  .alert-panel__cell {
    display: table-cell;
    width: 1px;
    vertical-align: middle; }
    .alert-panel__cell_fluid {
      width: auto; }
  .alert-panel__note {
    white-space: nowrap;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    text-transform: uppercase; }
  .alert-panel__button {
    vertical-align: middle;
    border: 1px solid #435c77;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
    padding: 12px 12px;
    margin-left: 17px; }

.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 679px;
  overflow: auto;
  box-shadow: inset 0 -1px 0 rgba(91, 64, 71, 0.38), 0 1px 8px rgba(0, 0, 0, 0.53); }
  @media only screen and (max-width: 767px) {
    .modal {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: none;
      width: 100%;
      background-color: #1b1a27;
      box-shadow: inset 0 -1px 0 rgba(108, 106, 139, 0.25); } }
  .modal__header {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.26);
    background-image: url("../img/modal-head-bg.jpg");
    height: 78px;
    text-align: center;
    position: relative; }
  .modal__title {
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.14);
    padding: 27px 50px;
    color: #fff;
    font-size: 25px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase; }
  .modal__icon {
    display: block;
    position: absolute;
    top: 30px;
    right: 21px;
    cursor: pointer; }
  .modal__content {
    background-color: #132e4d;
    padding: 32px 27px 38px 32px;
    font-family: 'ClearSans', sans-serif;
    font-size: 0;
    display: block;
    position: relative; }
    .modal__content:after {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 1px;
      opacity: 0.27;
      background-color: #3f3d60;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.42); }
    @media only screen and (max-width: 767px) {
      .modal__content {
        padding: 0 20px 30px 20px; } }
  .modal__button {
    position: absolute;
    display: block;
    left: 50%;
    top: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    padding: 9px 22px;
    border-radius: 20px;
    z-index: 2;
    box-shadow: none; }
  .modal__input {
    margin-left: 30px; }
    @media only screen and (max-width: 767px) {
      .modal__input {
        display: block;
        margin-left: 0;
        padding-top: 20px; } }
    .modal__input:first-child {
      margin-left: 0; }
  .modal__input-inner.input__inner {
    background-color: #09203a;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3), 0 1px 0 #09203a;
    border: none;
    color: #ccc;
    font-size: 15px;
    line-height: 18px;
    margin-top: 28px;
    width: 295px;
    text-transform: none;
    height: 49px; }
    @media only screen and (max-width: 767px) {
      .modal__input-inner.input__inner {
        width: 100%;
        margin-top: 14px; } }
  .modal__label {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.33);
    color: #fff;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase; }
  .modal__caption {
    color: #ccc;
    font-size: 14px;
    display: block;
    white-space: nowrap;
    position: absolute;
    top: 0;
    right: 3px;
    transition: color 0.3s ease;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .modal__caption {
        top: 24px; } }
    .modal__caption:hover {
      color: #fff; }
  .modal__tooltip {
    margin-top: 14px; }
  .modal__note {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 28px;
    display: block; }
    @media only screen and (max-width: 767px) {
      .modal__note {
        padding: 10px 0 0 0;
        text-align: center;
        margin-bottom: 10px;
        display: block; } }
    .modal__note_important {
      color: #fa2e38;
      display: inline-block;
      vertical-align: top;
      font-weight: 700;
      font-size: 17px;
      margin: 33px 0 6px 0; }
      @media only screen and (max-width: 767px) {
        .modal__note_important {
          margin: 20px 0 0;
          display: block; } }
    .modal__note_accent {
      color: #fff000;
      display: inline-block;
      vertical-align: top;
      font-weight: 700;
      margin: 33px 0 6px 5px; }
      @media only screen and (max-width: 767px) {
        .modal__note_accent {
          margin: 20px 0 0;
          display: block; } }
  .modal__error {
    display: block;
    position: relative;
    text-align: center;
    padding: 10px 0 20px;
    max-width: 100%; }
    @media only screen and (max-width: 767px) {
      .modal__error {
        padding: 0 0 10px; } }
  .modal__signup-soc {
    display: block;
    float: left;
    text-align: left; }
    @media only screen and (max-width: 767px) {
      .modal__signup-soc {
        float: none;
        padding-bottom: 80px; } }
  .modal__signup {
    text-align: center;
    width: auto; }
    @media only screen and (max-width: 767px) {
      .modal__signup {
        text-align: center; }
        .modal__signup .signup__button {
          left: auto; } }
    .modal__signup .signup__input-inner {
      background-color: #09203a;
      border: 1px solid #353448;
      border-radius: 2px;
      box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.05), inset -121px 0 0 rgba(0, 0, 0, 0.09); }
  .modal__actions {
    display: block;
    float: right;
    text-align: right; }
    @media only screen and (max-width: 767px) {
      .modal__actions {
        float: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px; } }
  .modal__social .socials__item {
    background-color: #09203a;
    border: 1px solid #4685a2;
    border-radius: 2px;
    box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.05); }
  .modal__footer {
    width: 100%;
    position: relative;
    padding: 37px 31px 39px 32px;
    background-color: #09203a;
    box-shadow: inset 0 -1px 0 rgba(108, 106, 139, 0.25);
    font-family: 'ClearSans', sans-serif; }
    @media only screen and (max-width: 767px) {
      .modal__footer {
        box-shadow: none; } }
    .modal__footer:after {
      content: '';
      display: table;
      clear: both; }

.popup {
  width: 568px;
  height: auto;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -49%);
  transform: translate(-50%, -49%);
  z-index: 200;
  background-color: #132e4d;
  border-radius: 2px;
  padding-bottom: 1px;
  box-shadow: inset 0 -1px 0 rgba(91, 64, 71, 0.38), 0 1px 8px rgba(0, 0, 0, 0.53); }
  @media only screen and (max-width: 767px) {
    .popup {
      position: absolute;
      left: 0;
      top: 0;
      transform: none;
      width: 100% !important;
      max-height: 100%;
      overflow-y: scroll; } }
  .popup_tabs {
    width: 940px;
    height: auto;
    top: 1%;
    transform: translate(-50%, 0);
    position: absolute; }
    .popup_tabs .tab__content {
      min-height: 330px; }
    @media only screen and (min-width: 1440px) {
      .popup_tabs {
        width: 940px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .popup_tabs {
        width: 940px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .popup_tabs {
        width: 700px; } }
    @media only screen and (max-width: 767px) {
      .popup_tabs {
        width: 100%;
        transform: none;
        top: 0;
        overflow-y: auto;
        min-height: 0; } }
    .popup_tabs .tab__close {
      top: 25px; }
      @media only screen and (max-width: 767px) {
        .popup_tabs .tab__close {
          top: 14px;
          right: 14px; } }
  .popup__close, .popup .popup_go_tomain {
    width: 44px;
    height: 38px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #132e4d;
    border-radius: 2px;
    box-shadow: inset 0.6px -0.8px 0 #2b2a3c;
    text-align: center;
    padding: 11px 0;
    cursor: pointer; }
  .popup__head {
    position: relative; }
    .popup__head:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg, rgba(87, 185, 220, 0), #35d0ff 50%, rgba(87, 185, 220, 0)); }
  .popup__title {
    text-align: left;
    font-size: 19px;
    font-weight: 700;
    padding: 24px 32px 21px;
    color: #fff;
    text-transform: uppercase; }
    .popup__title_accent {
      color: #fff000; }
    .popup__content .popup__title {
      text-align: center;
      padding: 0; }
      @media only screen and (max-width: 767px) {
        .popup__content .popup__title {
          padding: 0 30px; } }
  .popup__subtitle {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #9e9cbd;
    padding: 24px 5px 23px;
    display: inline-block;
    vertical-align: top; }
    .popup__subtitle_accent {
      color: #fff000; }
  .popup__content {
    position: relative; }
  .popup__label {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.33);
    color: #fff;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: left; }
    .popup__label_small {
      font-size: 19px; }
  .popup__footer {
    position: relative;
    background-color: #00142c;
    min-height: 64px;
    text-align: center; }
    .popup__footer::before {
      content: "";
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      height: 1px;
      background-image: linear-gradient(90deg, rgba(87, 185, 220, 0), #35d0ff 50%, rgba(87, 185, 220, 0)); }
    .popup__footer .footer__first {
      padding-top: 15px;
      display: block;
      color: #585858; }
    .popup__footer .footer__second {
      color: yellow;
      display: block;
      font-size: 50px; }
    .popup__footer .footer__third {
      display: block;
      color: #585858;
      padding-bottom: 15px; }
  .popup__note {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-family: 'ClearSans', sans-serif;
    text-transform: uppercase;
    display: block;
    padding-top: 21px;
    padding-bottom: 15px; }
  .popup__caption {
    display: block;
    position: relative;
    color: #fff;
    padding: 0 20px;
    font-size: 15px;
    line-height: 16px;
    font-weight: 400;
    text-align: left; }
  .popup__socials {
    padding-bottom: 25px; }
    .popup__socials .socials__item {
      background-color: #09203a;
      border: 1px solid #4c829c;
      border-radius: 2px;
      box-shadow: inset 0 0 6px 1px rgba(255, 255, 255, 0.05); }
  .popup__button {
    font-size: 18px;
    padding: 9px 18px;
    margin-top: -18px;
    margin-left: 10px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
  .popup__gallery {
    text-align: center;
    margin-top: 41px;
    margin-bottom: 61px; }
    .popup__gallery .preview {
      background: transparent;
      box-shadow: none;
      margin-right: 28px; }
      @media only screen and (max-width: 767px) {
        .popup__gallery .preview {
          margin-right: 0;
          margin-bottom: 20px; } }
      .popup__gallery .preview:last-child {
        margin-right: 0; }
    .popup__gallery .preview__info {
      text-align: left;
      padding: 14px 5px; }
    .popup__gallery .preview__title {
      color: #f4f3ff;
      font-size: 14px;
      font-weight: 700; }
    .popup__gallery .preview__note {
      color: #8b8b8b;
      font-size: 11px;
      font-weight: 700; }
  .popup .column__1-5 {
    width: 19%; }
  .popup .column__2-5 {
    width: 39%; }
  .popup .column__3-5 {
    width: 59%; }
  .popup .column__4-5 {
    width: 79%; }
  .popup .column__5-5 {
    width: 100%; }
  .popup .column__1-3 {
    width: 33%; }
  .popup .column__2-3 {
    width: 66%; }
  .popup .column__half {
    width: 49%; }
  .popup .column__1-5, .popup .column__2-5, .popup .column__3-5, .popup .column__4-5, .popup .column__5-5, .popup .column__1-3, .popup .column__2-3, .popup .column__half {
    position: relative;
    display: inline-block;
    padding: 0;
    vertical-align: middle; }
    @media screen and (max-width: 627px) {
      .popup .column__1-5, .popup .column__2-5, .popup .column__3-5, .popup .column__4-5, .popup .column__5-5, .popup .column__1-3, .popup .column__2-3, .popup .column__half {
        width: 100%;
        text-align: center;
        margin-bottom: 10px; } }
  .popup .game-container {
    width: 30%;
    display: inline-block;
    background: #0e233b;
    box-shadow: inset 0 3px 20px 11px rgba(33, 136, 152, 0.45);
    margin-right: 4.1%;
    text-align: center;
    position: relative; }
    @media screen and (max-width: 725px) {
      .popup .game-container {
        margin-right: 4%; } }
    .popup .game-container:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg, rgba(87, 185, 220, 0), #35d0ff 50%, rgba(87, 185, 220, 0)); }
    .popup .game-container:nth-child(4) {
      margin-right: 0; }
      @media screen and (max-width: 480px) {
        .popup .game-container:nth-child(4) {
          margin: 5px auto; } }
    @media screen and (max-width: 480px) {
      .popup .game-container {
        margin: 5px auto;
        width: 284px;
        display: block; } }
    .popup .game-container p {
      margin: 0; }
    .popup .game-container:hover .preview__overlay {
      background-color: rgba(0, 0, 0, 0.73);
      box-shadow: inset 0 0 2.9px 1.1px rgba(255, 255, 255, 0.28), inset 0 1px 0 rgba(255, 255, 255, 0.08), 0 2px 1px rgba(13, 25, 39, 0.37);
      opacity: 1;
      z-index: 1;
      height: 161px; }
      @media only screen and (max-width: 1000px) {
        .popup .game-container:hover .preview__overlay {
          height: 70%; } }
      @media only screen and (max-width: 880px) {
        .popup .game-container:hover .preview__overlay {
          height: 68%; } }
      @media only screen and (max-width: 767px) {
        .popup .game-container:hover .preview__overlay {
          height: 66%; } }
      @media only screen and (max-width: 740px) {
        .popup .game-container:hover .preview__overlay {
          height: 64%; } }
      @media only screen and (max-width: 660px) {
        .popup .game-container:hover .preview__overlay {
          height: 62%; } }
      @media only screen and (max-width: 610px) {
        .popup .game-container:hover .preview__overlay {
          height: 60%; } }
      @media only screen and (max-width: 570px) {
        .popup .game-container:hover .preview__overlay {
          height: 58%; } }
      @media only screen and (max-width: 520px) {
        .popup .game-container:hover .preview__overlay {
          height: 56%; } }
      @media only screen and (max-width: 480px) {
        .popup .game-container:hover .preview__overlay {
          height: 191px; } }
    .popup .game-container .preview__note {
      font-size: 15px;
      margin: 5px auto 15px auto;
      display: block; }
  .popup.popup_vipBonusAct, .popup.popup_vipBonusReact {
    width: 920px;
    background: url(../img/vip-bonus-bg.png) center bottom no-repeat #1d1b2e;
    color: #fff;
    font-size: 16px;
    padding-bottom: 25px;
    max-width: 100%;
    overflow-y: visible;
    height: auto;
    max-height: initial; }
    @media only screen and (max-width: 767px) {
      .popup.popup_vipBonusAct, .popup.popup_vipBonusReact {
        display: block;
        transform: none;
        top: 0;
        overflow-y: auto !important;
        min-height: 0;
        height: 100%; } }
    .popup.popup_vipBonusAct .popup__head, .popup.popup_vipBonusReact .popup__head {
      background-color: transparent;
      box-shadow: inset 0 1px 0 0 #262f39;
      text-align: center; }
    .popup.popup_vipBonusAct .popup__close, .popup.popup_vipBonusReact .popup__close {
      background: transparent;
      box-shadow: none;
      top: 15px; }
    .popup.popup_vipBonusAct .popup .column__1-3, .popup.popup_vipBonusAct .popup .column__1-5, .popup.popup_vipBonusAct .popup .column__2-3, .popup.popup_vipBonusAct .popup .column__2-5, .popup.popup_vipBonusAct .popup .column__3-5, .popup.popup_vipBonusAct .popup .column__4-5, .popup.popup_vipBonusAct .popup .column__5-5, .popup.popup_vipBonusReact .popup .column__1-3, .popup.popup_vipBonusReact .popup .column__1-5, .popup.popup_vipBonusReact .popup .column__2-3, .popup.popup_vipBonusReact .popup .column__2-5, .popup.popup_vipBonusReact .popup .column__3-5, .popup.popup_vipBonusReact .popup .column__4-5, .popup.popup_vipBonusReact .popup .column__5-5 {
      vertical-align: top; }
    .popup.popup_vipBonusAct h1, .popup.popup_vipBonusReact h1 {
      color: #fdc236;
      text-transform: uppercase;
      text-shadow: 0 10px 11.88px rgba(24, 2, 27, 0.55);
      line-height: 1.2;
      margin-top: 43px; }
      @media only screen and (max-width: 627px) {
        .popup.popup_vipBonusAct h1, .popup.popup_vipBonusReact h1 {
          margin-top: 0; } }
    .popup.popup_vipBonusAct p, .popup.popup_vipBonusReact p {
      line-height: 1.7; }
    .popup.popup_vipBonusAct img, .popup.popup_vipBonusReact img {
      max-width: 100%; }
      @media only screen and (max-width: 627px) {
        .popup.popup_vipBonusAct img, .popup.popup_vipBonusReact img {
          width: 200px; } }
    .popup.popup_vipBonusAct button, .popup.popup_vipBonusReact button {
      margin-left: 0;
      margin-top: 0; }
    @media only screen and (max-width: 627px) {
      .popup.popup_vipBonusAct .column__1-5, .popup.popup_vipBonusReact .column__1-5 {
        width: 19%; } }
    @media only screen and (max-width: 480px) {
      .popup.popup_vipBonusAct .column__1-5, .popup.popup_vipBonusReact .column__1-5 {
        width: 29%; } }
    .popup.popup_vipBonusAct .popup__section:last-child, .popup.popup_vipBonusReact .popup__section:last-child {
      text-align: center; }
  .popup.popup_afterRegistration {
    background-image: linear-gradient(180deg, #112542, #070829);
    width: 920px;
    color: #fff;
    height: auto;
    top: 19px;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    position: absolute;
    font-size: 18px;
    max-width: 100%;
    text-align: center;
    zoom: 0.84; }
    @media only screen and (max-width: 767px) {
      .popup.popup_afterRegistration {
        top: 0;
        zoom: 1;
        transform: translate(0%); } }
    .popup.popup_afterRegistration img {
      max-width: 100%; }
    .popup.popup_afterRegistration h2 {
      font-size: 25px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      color: #ff5252;
      margin-bottom: 25px; }
    .popup.popup_afterRegistration h3 {
      font-size: 20px;
      margin: 0; }
    .popup.popup_afterRegistration .popup__title {
      text-transform: uppercase;
      text-align: center; }
    .popup.popup_afterRegistration .popup__head {
      background-color: #00142c; }
    .popup.popup_afterRegistration .popup__close {
      background: transparent;
      box-shadow: none;
      top: 15px;
      opacity: 0.5; }
    .popup.popup_afterRegistration .icon-prize {
      position: absolute;
      left: 363px;
      top: -17px; }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterRegistration .icon-prize {
          display: none; } }
    @media only screen and (max-width: 767px) {
      .popup.popup_afterRegistration .section-1-txt {
        margin: 0 0 13px; } }
    .popup.popup_afterRegistration .popup__button {
      width: 100%;
      height: 56px;
      border-radius: 5px;
      color: #8e231f;
      font-size: 25px;
      font-weight: 500;
      -webkit-box-shadow: 0 0 20px 7px rgba(151, 175, 54, 0.5);
      -moz-box-shadow: 0 0 20px 7px rgba(151, 175, 54, 0.5);
      box-shadow: 0 0 20px 7px rgba(151, 175, 54, 0.5);
      background-image: linear-gradient(180deg, #e2d533, #ff8d00);
      text-shadow: 1px 1px 4px #3a0404; }
      .popup.popup_afterRegistration .popup__button.button_color_blue {
        border-top: 3px solid #4fcfff;
        background-image: linear-gradient(180deg, #01b7f0, #1932bc);
        margin-left: 33px; }
        @media screen and (max-width: 871px) {
          .popup.popup_afterRegistration .popup__button.button_color_blue {
            margin-left: 0;
            font-size: 16px; } }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterRegistration .popup__button {
          font-size: 18px; } }
    .popup.popup_afterRegistration .popup__section {
      padding: 15px 60px 13px;
      position: relative; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .popup.popup_afterRegistration .popup__section {
          padding: 15px 30px; } }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterRegistration .popup__section {
          padding: 15px; } }
      .popup.popup_afterRegistration .popup__section p:last-child {
        margin-bottom: 0; }
      .popup.popup_afterRegistration .popup__section p:first-child {
        margin-top: 0; }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterRegistration .popup__section:first-child img {
          max-width: 200px; } }
      .popup.popup_afterRegistration .popup__section:nth-child(3) {
        text-align: center; }
        .popup.popup_afterRegistration .popup__section:nth-child(3) h2 {
          color: #fff; }
      .popup.popup_afterRegistration .popup__section__main .popup__image {
        background-image: url("../img/popup__image.png");
        width: 78%;
        height: 360px;
        background-size: 100% 360px;
        margin: 0px auto; }
        @media only screen and (max-width: 767px) {
          .popup.popup_afterRegistration .popup__section__main .popup__image {
            width: 250px; } }
      .popup.popup_afterRegistration .popup__section__main .column__half {
        margin-bottom: 25px; }
      .popup.popup_afterRegistration .popup__section__main #clockdiv {
        font-family: sans-serif;
        color: #fff;
        display: inline-block;
        font-weight: 100;
        text-align: center;
        font-size: 30px;
        margin-bottom: 25px; }
        @media only screen and (max-width: 767px) {
          .popup.popup_afterRegistration .popup__section__main #clockdiv {
            font-size: 19px; } }
      .popup.popup_afterRegistration .popup__section__main #clockdiv > div {
        padding: 10px;
        border-radius: 3px;
        background: rgba(17, 33, 115, 0.53);
        display: inline-block; }
      .popup.popup_afterRegistration .popup__section__main #clockdiv div > span {
        padding: 15px;
        border-radius: 3px;
        background: #07092a;
        display: inline-block;
        color: #00d6fb; }
      .popup.popup_afterRegistration .popup__section__main .smalltext {
        padding-top: 5px;
        font-size: 16px; }
      .popup.popup_afterRegistration .popup__section__main .no_present {
        color: #353b58;
        text-decoration: underline;
        margin-top: 15px;
        cursor: pointer; }
      .popup.popup_afterRegistration .popup__section__dark {
        background-color: #07092a;
        color: #00d6fb; }
        .popup.popup_afterRegistration .popup__section__dark:after {
          content: "";
          width: 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 1px;
          background-image: linear-gradient(90deg, rgba(87, 185, 220, 0), #35d0ff 50%, rgba(87, 185, 220, 0)); }
        .popup.popup_afterRegistration .popup__section__dark:before {
          content: "";
          width: 100%;
          position: absolute;
          right: 0;
          top: 0;
          height: 1px;
          background-image: linear-gradient(90deg, rgba(87, 185, 220, 0), #35d0ff 50%, rgba(87, 185, 220, 0)); }
        .popup.popup_afterRegistration .popup__section__dark p {
          display: inline-block; }
        .popup.popup_afterRegistration .popup__section__dark .popup__arrow {
          background-image: url("../img/popup__arrow.png");
          background-size: 12px 25px;
          width: 12px;
          height: 25px;
          display: inline-block;
          margin-bottom: -7px;
          margin-left: 8px; }
        .popup.popup_afterRegistration .popup__section__dark .popup__countpad {
          display: inline-block;
          margin-bottom: -18px;
          margin-left: 20px; }
      .popup.popup_afterRegistration .popup__section .popup__button {
        margin-top: 0; }
    .popup.popup_afterRegistration .popular__head {
      font-size: 26px; }
    .popup.popup_afterRegistration table {
      width: 100%;
      margin: 0 auto;
      font-size: 15px; }
      @media screen and (max-width: 350px) {
        .popup.popup_afterRegistration table {
          font-size: 13px; } }
    .popup.popup_afterRegistration thead tr:first-child td {
      border-top: 1px solid #3f3e55;
      font-size: 13px;
      color: #534d5e;
      background: #282738; }
      .popup.popup_afterRegistration thead tr:first-child td:last-child {
        text-align: right; }
    .popup.popup_afterRegistration td {
      border-right: 1px solid #3f3e55;
      padding: 8px 20px; }
      .popup.popup_afterRegistration td:first-child {
        border-left: 1px solid #3f3e55; }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterRegistration td {
          padding: 5px; } }
    .popup.popup_afterRegistration tbody tr:last-child td {
      border-bottom: 1px solid #3f3e55; }
    .popup.popup_afterRegistration tbody tr:nth-child(even) {
      background: #282738; }
    .popup.popup_afterRegistration tbody td:nth-child(2) {
      color: #1cbbb4; }
    .popup.popup_afterRegistration tbody td:nth-child(4) {
      color: #fdc236;
      text-align: right; }
    .popup.popup_afterRegistration .popup-subtitle {
      color: #554b60;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      width: 100%; }
    .popup.popup_afterRegistration .popup__section:last-child {
      background: transparent; }
    .popup.popup_afterRegistration .amount-of-payments {
      width: 320px;
      margin: 0 auto;
      text-align: center;
      background: #222131;
      position: relative; }
      @media screen and (max-width: 350px) {
        .popup.popup_afterRegistration .amount-of-payments {
          width: 290px; } }
      .popup.popup_afterRegistration .amount-of-payments .amount {
        margin: 0 0 15px;
        font-size: 42px; }
      .popup.popup_afterRegistration .amount-of-payments small:nth-child(4) {
        position: absolute;
        left: 20px;
        text-align: left; }
        .popup.popup_afterRegistration .amount-of-payments small:nth-child(4):after {
          content: " ";
          margin-left: 5px;
          position: absolute;
          margin-top: -9px;
          width: 40px;
          height: 1px;
          display: block;
          background: #b6a9c3;
          left: 73px; }
          @media screen and (max-width: 350px) {
            .popup.popup_afterRegistration .amount-of-payments small:nth-child(4):after {
              width: 27px; } }
      .popup.popup_afterRegistration .amount-of-payments p:nth-child(5) {
        text-align: right;
        position: relative;
        margin: 0 21px 10px 0;
        font-size: 16px; }
      .popup.popup_afterRegistration .amount-of-payments small:last-child {
        text-align: right;
        font-size: 12px;
        position: absolute;
        right: 22px;
        margin-top: -10px;
        font-style: italic; }
  .popup.popup_afterActivation {
    width: 920px;
    color: #b6a9c3;
    height: auto;
    top: 1%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    position: absolute;
    font-size: 18px;
    max-width: 100%; }
    @media only screen and (max-width: 767px) {
      .popup.popup_afterActivation {
        left: 50%;
        top: 0; } }
    .popup.popup_afterActivation img {
      max-width: 100%; }
    .popup.popup_afterActivation h2 {
      font-size: 27px;
      font-weight: 100;
      text-transform: uppercase;
      margin: 0; }
    .popup.popup_afterActivation h3 {
      font-size: 20px;
      margin: 0; }
    .popup.popup_afterActivation .popup__title {
      text-transform: none; }
    .popup.popup_afterActivation .popup__head {
      background-image: linear-gradient(180deg, #121d29, #04101d); }
    .popup.popup_afterActivation .popup__close {
      background: transparent;
      box-shadow: none;
      top: 15px;
      opacity: 0.5; }
    .popup.popup_afterActivation .icon-prize {
      position: absolute;
      left: 283px;
      top: 15px; }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterActivation .icon-prize {
          display: none; } }
    .popup.popup_afterActivation .popup__button {
      border-top: 3px solid #ffe303;
      border-radius: 21px;
      background-image: linear-gradient(180deg, #fe9400, #ff0024); }
      .popup.popup_afterActivation .popup__button.button_color_blue {
        border-top: 3px solid #4fcfff;
        background-image: linear-gradient(180deg, #01b7f0, #1932bc);
        margin-left: 33px; }
        @media screen and (max-width: 871px) {
          .popup.popup_afterActivation .popup__button.button_color_blue {
            margin-left: 0;
            font-size: 16px; } }
    .popup.popup_afterActivation .popup__section:nth-child(even) {
      background: #141224; }
    .popup.popup_afterActivation .popup__section {
      padding: 15px 60px 20px;
      position: relative; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .popup.popup_afterActivation .popup__section {
          padding: 15px 30px; } }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterActivation .popup__section {
          padding: 15px; } }
      .popup.popup_afterActivation .popup__section:last-child {
        background: url(../img/after-activation-bg.png) center bottom no-repeat #141224;
        padding-bottom: 41px; }
      .popup.popup_afterActivation .popup__section .popup__button {
        margin-top: 0; }
    .popup.popup_afterActivation table {
      width: 100%;
      margin: 0 auto;
      font-size: 15px;
      background: #222131; }
      @media screen and (max-width: 350px) {
        .popup.popup_afterActivation table {
          font-size: 13px; } }
    .popup.popup_afterActivation thead tr:first-child td {
      border-top: 1px solid #3f3e55;
      font-size: 13px;
      color: #534d5e;
      background: #282738; }
      .popup.popup_afterActivation thead tr:first-child td:last-child {
        text-align: right; }
    .popup.popup_afterActivation td {
      border-right: 1px solid #3f3e55;
      padding: 8px 20px; }
      .popup.popup_afterActivation td:first-child {
        border-left: 1px solid #3f3e55; }
      @media only screen and (max-width: 767px) {
        .popup.popup_afterActivation td {
          padding: 5px; } }
    .popup.popup_afterActivation tbody tr:last-child td {
      border-bottom: 1px solid #3f3e55; }
    .popup.popup_afterActivation tbody tr:nth-child(even) {
      background: #282738; }
    .popup.popup_afterActivation tbody td:nth-child(2) {
      color: #1cbbb4; }
    .popup.popup_afterActivation tbody td:nth-child(4) {
      color: #fdc236;
      text-align: right; }
    .popup.popup_afterActivation .popup-subtitle {
      color: #554b60;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      width: 100%; }
    .popup.popup_afterActivation .amount-of-payments {
      width: 320px;
      margin: 24px auto 0;
      text-align: center;
      background: #222131;
      position: relative;
      padding-bottom: 14px; }
      @media screen and (max-width: 350px) {
        .popup.popup_afterActivation .amount-of-payments {
          width: 290px; } }
      .popup.popup_afterActivation .amount-of-payments .amount {
        margin: 0 0 15px;
        font-size: 42px; }
      .popup.popup_afterActivation .amount-of-payments small:nth-child(4) {
        position: absolute;
        left: 20px;
        text-align: left; }
        .popup.popup_afterActivation .amount-of-payments small:nth-child(4):after {
          content: " ";
          margin-left: 5px;
          position: absolute;
          margin-top: -9px;
          width: 40px;
          height: 1px;
          display: block;
          background: #b6a9c3;
          left: 73px; }
          @media screen and (max-width: 350px) {
            .popup.popup_afterActivation .amount-of-payments small:nth-child(4):after {
              width: 27px; } }
      .popup.popup_afterActivation .amount-of-payments p:nth-child(5) {
        text-align: right;
        position: relative;
        margin: 0 21px 10px 0;
        font-size: 16px; }
      .popup.popup_afterActivation .amount-of-payments small:last-child {
        text-align: right;
        font-size: 12px;
        position: absolute;
        right: 22px;
        margin-top: -10px;
        font-style: italic; }
    .popup.popup_afterActivation .timer-title {
      font-size: 12px;
      font-family: "ClearSans";
      color: #fdc236;
      text-transform: uppercase;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 10px; }
    .popup.popup_afterActivation .timer {
      width: 252px;
      border-top: 1px solid #3d3c50;
      border-bottom: 1px solid #3d3c50;
      margin: 0 auto;
      padding: 13px 2px;
      position: relative; }
      .popup.popup_afterActivation .timer:before {
        content: url(../img/timer-stars-left.png);
        position: absolute;
        left: -133px;
        top: calc(50% - 19px); }
      .popup.popup_afterActivation .timer:after {
        content: url(../img/timer-stars-right.png);
        position: absolute;
        right: -133px;
        top: calc(50% - 19px); }
      .popup.popup_afterActivation .timer-body {
        display: table-row;
        font-size: 31px; }
    .popup.popup_afterActivation .timer-cell {
      display: inline-block;
      font-size: 14px;
      width: 23%;
      text-align: center; }
      .popup.popup_afterActivation .timer-cell:first-child {
        padding-right: 13px; }
    .popup.popup_afterActivation .timer__cell {
      padding: 0 5px; }
      .popup.popup_afterActivation .timer__cell:first-child {
        padding-left: 13px; }
      .popup.popup_afterActivation .timer__cell:nth-child(2) {
        padding: 0 16px; }
    .popup.popup_afterActivation .timer__row {
      margin: 0 auto;
      display: block;
      width: 248px; }
    .popup.popup_afterActivation #countup p {
      display: inline-block;
      padding: 5px;
      font-size: 31px;
      margin: 0;
      width: 44px;
      text-align: center; }
      .popup.popup_afterActivation #countup p.dots {
        width: 8px;
        padding: 5px 0; }
      .popup.popup_afterActivation #countup p#days {
        margin-right: 18px; }

.popup_paymentConfirm h1, .popup_paymentConfirm h2, .popup_paymentConfirm h3, .popup_paymentConfirm h4, .popup_paymentConfirm h5 {
  color: #f1e419; }

.popup_paymentConfirm ul {
  list-style: none; }

.popup_paymentConfirm .popup__content {
  padding-bottom: 20px; }

.popup_chooseBonus, .popup_depositSuccess {
  width: 940px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .popup_chooseBonus, .popup_depositSuccess {
      width: 700px; } }
  .popup_chooseBonus .popup__title, .popup_depositSuccess .popup__title {
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.27);
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff000;
    text-align: center;
    padding: 55px 50px 26px; }
  .popup_chooseBonus .popup__bonuses, .popup_depositSuccess .popup__bonuses {
    text-align: center;
    padding-bottom: 68px; }

.popup_depositSuccess .popup__gallery .preview {
  max-width: 200px;
  border: none; }

@media only screen and (max-width: 767px) {
  .popup_depositSuccess .popup__gallery .preview {
    max-width: 300px;
    width: auto;
    display: block;
    margin: 0 auto 20px; } }

.popup_registration {
  width: 700px; }
  .popup_registration .popup__title {
    text-align: left;
    font-size: 19px;
    padding: 24px 32px 21px;
    color: #fff; }
  .popup_registration .popup__content {
    padding: 35px 30px 40px 31px; }
  .popup_registration .popup__button {
    margin-top: 24px;
    margin-left: 0; }

.popup_afterRegistration {
  width: 568px; }
  .popup_afterRegistration .popup__content .bonus {
    display: inline-block;
    vertical-align: top;
    padding-bottom: 38px; }
  .popup_afterRegistration .popup__title {
    font-size: 19px; }
    .popup_afterRegistration .popup__title_accent {
      color: #fff000;
      text-align: center;
      padding-top: 48px;
      padding-bottom: 4px; }
  .popup_afterRegistration .popup__button {
    margin-left: -5px; }

.popup_depositSuccess {
  width: 800px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .popup_depositSuccess {
      width: 700px; } }
  .popup_depositSuccess .popup__title {
    padding-top: 38px; }
  .popup_depositSuccess .popup__footer {
    min-height: 55px; }
  .popup_depositSuccess .popup__gallery {
    margin-bottom: 50px; }
  .popup_depositSuccess .popup__button {
    margin-left: -2px;
    padding: 12px 18px;
    margin-top: -20px; }

.popup_changePassword {
  width: 568px;
  position: fixed; }
  .popup_changePassword .popup__content {
    padding: 33px 25px 49px 31px; }
  .popup_changePassword .popup__input {
    display: block;
    margin-bottom: 16px; }
    .popup_changePassword .popup__input:last-child {
      margin-bottom: 0; }
    .popup_changePassword .popup__input .input__inner {
      width: 100%;
      height: 49px;
      font-family: 'ClearSans', sans-serif;
      margin-top: 14px; }

.popup_passwordChanged, .popup_remindSuccess {
  width: 568px; }
  .popup_passwordChanged .popup__content, .popup_remindSuccess .popup__content {
    padding: 52px 0 64px; }
    .popup_passwordChanged .popup__content .popup__title, .popup_remindSuccess .popup__content .popup__title {
      text-align: center;
      padding: 0 30px; }

.popup_restorePassword .popup__content {
  padding: 32px 25px 64px 31px; }
  .popup_restorePassword .popup__content .popup__subtitle {
    padding: 0;
    color: #aaa9b8;
    font-size: 15px;
    font-weight: 700; }

.popup_restorePassword .popup__input {
  display: block;
  margin-bottom: 16px;
  margin-top: 29px; }
  .popup_restorePassword .popup__input:last-child {
    margin-bottom: 0; }
  .popup_restorePassword .popup__input .input__inner {
    width: 100%;
    height: 49px;
    font-family: 'ClearSans', sans-serif;
    color: #a5a3bd;
    text-transform: none; }

.popup_phoneVerification .popup__content {
  padding: 36px 112px 52px 120px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .popup_phoneVerification .popup__content {
      padding: 18px 16px 40px;
      max-width: 320px;
      margin: 0 auto; } }
  .popup_phoneVerification .popup__content .button_style_flat {
    box-shadow: none;
    font-size: 13px;
    padding: 8px 10px;
    margin-left: 7px;
    margin-top: 27px;
    width: auto; }
    .popup_phoneVerification .popup__content .button_style_flat:hover {
      box-shadow: 0 1px 5px #494767, 0 1px 0 #1a1925; }

.popup_phoneVerification .popup__caption {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 27px; }

.popup_phoneVerification .popup__input .input__inner {
  height: 49px;
  width: 150px;
  text-transform: none; }

.popup_phoneVerification .popup__timer {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px; }

.popup_phoneVerification .popup__advice {
  width: 170px;
  color: #838383;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  margin-top: 22px;
  margin-left: 14px; }

.popup_phoneVerification .popup__button {
  margin-left: 0; }

.popup_emailVerification .popup__content {
  padding: 27px 53px 55px 53px; }
  @media only screen and (max-width: 767px) {
    .popup_emailVerification .popup__content {
      padding: 27px 53px 55px 53px; } }

.popup_emailVerification .popup__caption {
  font-size: 16px;
  line-height: 18px;
  padding-top: 21px; }

.popup_emailConfirmed .popup__content {
  padding: 63px 115px 80px 128px; }
  @media only screen and (max-width: 767px) {
    .popup_emailConfirmed .popup__content {
      padding: 40px 24px; } }

.popup_emailConfirmed .popup__caption {
  font-size: 18px;
  line-height: 18px; }

.popup_tournamentGames {
  width: 940px;
  height: 490px;
  position: fixed; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .popup_tournamentGames {
      width: 700px; } }
  @media only screen and (max-width: 767px) {
    .popup_tournamentGames {
      width: 100%;
      height: auto; } }
  .popup_tournamentGames .preview__item {
    height: 141px;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .popup_tournamentGames .preview__item {
        height: 128px; } }
  .popup_tournamentGames .popup__title {
    padding: 33px 400px 29px 37px;
    font-size: 20px; }
    @media only screen and (max-width: 767px) {
      .popup_tournamentGames .popup__title {
        padding: 24px 32px 21px; } }
  .popup_tournamentGames .popup__search {
    display: block;
    position: absolute;
    top: 25px;
    right: 70px; }
    @media only screen and (max-width: 767px) {
      .popup_tournamentGames .popup__search {
        display: block;
        position: absolute;
        top: 94px;
        right: 50%;
        transform: translateX(50%); } }
    .popup_tournamentGames .popup__search .search__input {
      width: 276px;
      height: 39px;
      background-color: #181723;
      border-radius: 2px;
      border: none;
      box-shadow: inset 0 1px 0 #0d0c13,0 1px 0 #323144;
      color: #a5a3bd;
      font-size: 15px;
      font-weight: 500;
      font-family: 'ClearSans',sans-serif;
      text-transform: uppercase;
      padding: 0 21px; }
    .popup_tournamentGames .popup__search .icon {
      display: block;
      position: absolute;
      top: 7px;
      right: 8px; }
  .popup_tournamentGames .popup__content {
    padding: 18px 24px 20px 24px; }
    @media only screen and (max-width: 767px) {
      .popup_tournamentGames .popup__content {
        margin-top: 62px; } }
  .popup_tournamentGames .popup__gallery {
    text-align: left;
    margin: 0;
    max-height: 346px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .popup_tournamentGames .popup__gallery {
        max-height: none; } }
    .popup_tournamentGames .popup__gallery .main {
      padding: 0 22px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .popup_tournamentGames .popup__gallery .main {
          padding: 0; } }
      @media only screen and (max-width: 767px) {
        .popup_tournamentGames .popup__gallery .main {
          padding: 0; } }
    .popup_tournamentGames .popup__gallery .preview {
      width: 190px !important;
      margin: 10px; }
      @media only screen and (max-width: 767px) {
        .popup_tournamentGames .popup__gallery .preview {
          margin: 10px !important;
          width: 176px !important; } }
      @media only screen and (max-width: 468px) {
        .popup_tournamentGames .popup__gallery .preview {
          width: 43% !important;
          margin: 10px 3.33% !important; } }
    .popup_tournamentGames .popup__gallery .preview__info {
      background-color: #2b2a3d;
      border: 1px solid rgba(255, 255, 255, 0.09);
      border-radius: 2px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.28);
      padding: 10px 13px; }
    .popup_tournamentGames .popup__gallery .preview__title {
      font-size: 15px; }
    .popup_tournamentGames .popup__gallery .preview__note {
      font-size: 12px; }

.popup_favoritesAdded .popup__title, .popup_favoritesAddedFail .popup__title {
  font-family: 'ClearSans', sans-serif;
  font-size: 21px;
  padding: 20px 20px 20px 26px; }

.popup_favoritesAdded .popup__content, .popup_favoritesAddedFail .popup__content {
  padding: 40px 20px 55px 20px; }

.popup_gameplay {
  width: 800px; }

.popup_gameplayDeposit {
  width: 800px; }
  .popup_gameplayDeposit .popup__title {
    font-size: 22px;
    letter-spacing: -0.1px;
    padding: 60px 30px 47px; }
  .popup_gameplayDeposit .popup__icon {
    display: block;
    position: relative;
    text-align: center;
    padding-bottom: 70px; }
  .popup_gameplayDeposit .popup__gameplay-button {
    top: -21px; }
  .popup_gameplayDeposit .popup__footer {
    min-height: 71px; }

.popup_gameplayGallery {
  width: 800px;
  top: 300px; }
  @media only screen and (max-width: 767px) {
    .popup_gameplayGallery {
      top: 0; } }
  .popup_gameplayGallery .popup__close, .popup_gameplayGallery .popup_go_tomain {
    top: auto;
    right: auto;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
    height: auto;
    background: none;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    color: #01b4ff;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    box-shadow: none; }
  .popup_gameplayGallery .popup__title {
    font-size: 22px;
    padding: 61px 60px 20px 60px; }
  .popup_gameplayGallery .popup__gallery {
    margin-bottom: 0; }
    .popup_gameplayGallery .popup__gallery .preview {
      border: none; }
      @media only screen and (max-width: 767px) {
        .popup_gameplayGallery .popup__gallery .preview {
          display: block;
          max-width: 200px;
          margin: 0 auto 20px; } }
  .popup_gameplayGallery .preview:last-of-type {
    margin-right: 0; }
    @media only screen and (max-width: 767px) {
      .popup_gameplayGallery .preview:last-of-type {
        margin-right: auto; } }
  .popup_gameplayGallery .popup__button {
    margin-left: 0;
    font-family: "ClearSans", sans-serif;
    padding: 10px 20px;
    margin-top: -22px;
    font-size: 20px; }
  .popup_gameplayGallery .popup__footer {
    min-height: 76px; }
  .popup_gameplayGallery .bonus-sidebar__subtitle {
    margin-bottom: 40px; }
  .popup_gameplayGallery .bonus-subtitle__header {
    text-align: center;
    font-size: 1.17em;
    color: white;
    margin: 0 auto 10px; }
  .popup_gameplayGallery .bonus-subtitle__txt {
    width: 100%;
    display: block;
    color: #ccc;
    font-size: 14px;
    padding: 0px 15px;
    text-align: center; }

.popup_gameplayNomoney {
  width: 800px; }
  .popup_gameplayNomoney .popup__title {
    font-size: 22px;
    margin: 0;
    padding: 55px 20px 0px; }
    .popup_gameplayNomoney .popup__title_accent {
      color: #fff000;
      text-align: center;
      padding: 0 20px; }
  .popup_gameplayNomoney .popup__icon {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 47px;
    padding-bottom: 93px; }
  .popup_gameplayNomoney .popup__close, .popup_gameplayNomoney .popup_go_tomain {
    top: auto;
    bottom: 13px;
    right: auto;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: auto;
    height: auto;
    background: none;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    color: #01b4ff;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    text-transform: uppercase;
    box-shadow: none; }
  .popup_gameplayNomoney .popup__gameplay-button {
    margin-top: -24px; }
  .popup_gameplayNomoney .popup__footer {
    min-height: 90px; }

.popup_fortuneRules {
  width: 940px; }
  .popup_fortuneRules .popup__title {
    padding: 32px 37px 30px; }
  .popup_fortuneRules .popup__content {
    padding: 15px 69px 25px 34px; }
  .popup_fortuneRules .popup__caption {
    text-shadow: 0 1px 0 #000;
    color: #bdbbce;
    line-height: 19px;
    text-align: left; }
  .popup_fortuneRules .popup__levels {
    padding: 36px 28px;
    background-color: #1b1a28;
    box-shadow: inset 0 1px 0 rgba(43, 42, 60, 0.83); }

.popup_fortuneHistory {
  width: 940px; }
  .popup_fortuneHistory .popup__title {
    padding: 32px 37px 30px; }
  .popup_fortuneHistory .popup__content {
    padding: 25px 5px 25px 27px;
    font-size: 0; }
  .popup_fortuneHistory .popup__filterSearch {
    display: table;
    width: 969px;
    border-collapse: collapse;
    margin-right: 22px; }
  .popup_fortuneHistory .popup__cell {
    display: table-cell;
    vertical-align: top;
    white-space: nowrap;
    width: 1px; }
    .popup_fortuneHistory .popup__cell_fluid {
      width: 100%;
      padding-right: 16px; }
  .popup_fortuneHistory .popup__sort {
    display: inline-block;
    vertical-align: top;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    line-height: 20px;
    padding: 20px 24px 18px;
    background-color: #58566d;
    white-space: nowrap;
    width: 163px; }
  .popup_fortuneHistory .popup__filter {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%; }
  .popup_fortuneHistory .popup__search {
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .popup_fortuneHistory .popup__search .input__inner {
      display: block;
      position: relative;
      width: 250px;
      padding: 0 72px 0 14px;
      height: 58px;
      border-radius: 1px;
      background-color: #4d4b63;
      box-shadow: none; }
  .popup_fortuneHistory .popup__searchButton {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 58px;
    background-color: #58566d;
    border: none;
    cursor: pointer; }
  .popup_fortuneHistory .popup__history {
    display: block;
    position: relative;
    margin-top: 22px;
    padding-right: 23px;
    max-height: 475px;
    overflow: hidden; }

.popup_fortuneWheel {
  width: 940px;
  height: 978px; }

.position_absolute {
  position: absolute;
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  top: 1%; }

.popup_newViplevel .popup__content {
  padding-top: 20px;
  text-align: center; }
  .popup_newViplevel .popup__content .popup__title {
    font-size: 31px;
    line-height: 40px; }
  .popup_newViplevel .popup__content .popup__subtitle {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 19px;
    padding: 0;
    margin-top: 9px; }
  .popup_newViplevel .popup__content .bonus__item {
    min-width: 227px;
    min-height: 246px; }

.popup_deposit_for_bonus .popup_section__main {
  display: inline-block;
  vertical-align: top;
  padding: 29px 13px 30px 0;
  max-width: 646px; }
  .popup_deposit_for_bonus .popup_section__main .payment__gallery {
    margin: 0 20px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .popup_deposit_for_bonus .popup_section__main {
      padding: 15px 0 18px; } }
  @media only screen and (max-width: 767px) {
    .popup_deposit_for_bonus .popup_section__main {
      width: 100%;
      padding: 15px 0 18px; }
      .popup_deposit_for_bonus .popup_section__main .payment__row {
        padding: 0 8px; } }

.popup_deposit_for_bonus .popup_section__aside {
  width: 284px;
  display: inline-block;
  vertical-align: top; }

@media only screen and (min-width: 1440px) {
  .popup_deposit_for_bonus .payitem {
    width: 179px; } }

@media only screen and (min-width: 1240px) and (max-width: 1439px) {
  .popup_deposit_for_bonus .payitem {
    width: 179px; } }

@media only screen and (min-width: 1000px) and (max-width: 1239px) {
  .popup_deposit_for_bonus .payitem {
    width: 179px; } }

.popup_deposit_for_bonus .popup__content {
  text-align: center;
  padding-bottom: 20px; }

.popup_deposit_for_bonus .payment__row {
  padding: 0;
  text-align: center; }

.popup_deposit_for_bonus .payment__tooltip {
  margin: 4px 0 30px; }
  @media only screen and (min-width: 1440px) {
    .popup_deposit_for_bonus .payment__tooltip {
      width: 568px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .popup_deposit_for_bonus .payment__tooltip {
      width: 568px; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .popup_deposit_for_bonus .payment__tooltip {
      width: 568px; } }
  @media only screen and (min-width: 1440px) {
    .popup_deposit_for_bonus .payment__tooltip .pay-tooltip__button {
      padding-left: 8px;
      padding-right: 8px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .popup_deposit_for_bonus .payment__tooltip .pay-tooltip__button {
      padding-left: 8px;
      padding-right: 8px; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .popup_deposit_for_bonus .payment__tooltip .pay-tooltip__button {
      padding-left: 8px;
      padding-right: 8px; } }

.popup_deposit_for_bonus .popup__title_accent {
  text-align: center;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .popup_deposit_for_bonus .popup__title_accent {
      padding: 0; } }

.popup_gameBonusPopup {
  width: 656px; }
  .popup_gameBonusPopup .popup__close {
    background: transparent;
    box-shadow: none; }

.popup_registration_v2, .popup_registration_v3, .popup_registration_v4, .popup_registration_v5, .popup_registration_v6, .popup_registration_v7 {
  width: 940px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .popup_registration_v2, .popup_registration_v3, .popup_registration_v4, .popup_registration_v5, .popup_registration_v6, .popup_registration_v7 {
      width: 700px;
      max-height: 100%;
      overflow-y: scroll; } }
  .popup_registration_v2 .popup__head, .popup_registration_v3 .popup__head, .popup_registration_v4 .popup__head, .popup_registration_v5 .popup__head, .popup_registration_v6 .popup__head, .popup_registration_v7 .popup__head {
    background: #00142c;
    text-align: center; }
    .popup_registration_v2 .popup__head:after, .popup_registration_v3 .popup__head:after, .popup_registration_v4 .popup__head:after, .popup_registration_v5 .popup__head:after, .popup_registration_v6 .popup__head:after, .popup_registration_v7 .popup__head:after {
      height: 1px;
      background: radial-gradient(ellipse at center, #00b1ec 0%, transparent 70%);
      box-shadow: none;
      width: 100%;
      z-index: 1;
      opacity: 1; }
  .popup_registration_v2 .popup__close, .popup_registration_v3 .popup__close, .popup_registration_v4 .popup__close, .popup_registration_v5 .popup__close, .popup_registration_v6 .popup__close, .popup_registration_v7 .popup__close {
    background: transparent;
    box-shadow: none;
    opacity: 0.4;
    padding: 19px 0 17px;
    right: 7px;
    height: auto; }
  .popup_registration_v2 .popup__title, .popup_registration_v3 .popup__title, .popup_registration_v4 .popup__title, .popup_registration_v5 .popup__title, .popup_registration_v6 .popup__title, .popup_registration_v7 .popup__title {
    padding: 17px 32px 16px;
    text-align: center; }
  .popup_registration_v2 .popup__content, .popup_registration_v3 .popup__content, .popup_registration_v4 .popup__content, .popup_registration_v5 .popup__content, .popup_registration_v6 .popup__content, .popup_registration_v7 .popup__content {
    padding: 24px 0 38px;
    background: linear-gradient(to top, #070929 0, #0e1f38 70%); }

.popup.popup_registration_v2 {
  background: #132e4d; }

.overflow {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.7); }

.overflow__game {
  z-index: 199; }

.modal_open {
  width: 100%; }
  .modal_open .overflow {
    display: block; }

.overflow__no__money {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.7); }

.bonus {
  width: auto;
  display: inline-block;
  position: relative;
  font-size: 0; }
  .bonus__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 295px;
    width: 229px;
    background-repeat: no-repeat;
    background-position: center top;
    margin-right: 64px; }
    @media only screen and (max-width: 767px) {
      .bonus__item {
        margin-right: 0;
        display: block;
        margin-bottom: 20px; } }
    .bonus__item img {
      transition: transform 0.3s ease;
      height: 200px; }
    .bonus__item:hover img {
      transform: scale(1.1); }
    .bonus__item:last-child {
      margin-right: 0; }
    .bonus_single .bonus__item {
      width: 480px;
      background-size: 188px; }
      .bonus_single .bonus__item:hover img {
        transform: none; }
    .bonus__item_1 {
      background-image: url("../img/bonus-1.png"); }
    .bonus__item_2 {
      background-image: url("../img/bonus-2.png"); }
    .bonus__item_3 {
      background-image: url("../img/bonus-3.png"); }
  .bonus__info {
    text-align: center;
    padding-top: 12px; }
  .bonus__name {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    display: inline-block; }
    .bonus_single .bonus__name {
      padding-bottom: 22px; }
  .bonus__note {
    font-weight: 400;
    font-size: 16px;
    color: #7e7c9d;
    display: inline-block;
    padding: 0 20px;
    margin-top: 5px; }
  .bonus__caption {
    color: #a7a5d0;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    display: block; }
    .bonus_single .bonus__caption {
      padding-bottom: 20px; }
  .bonus__subtitle {
    color: #a7a5d0;
    font-family: 'ClearSans', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    display: block; }

.registration {
  display: block;
  position: relative;
  font-size: 0; }
  @media only screen and (max-width: 767px) {
    .registration {
      text-align: center; } }
  .registration__image {
    width: 187px;
    height: 166px;
    background-size: 130px;
    background-repeat: no-repeat;
    background-position: center top;
    border-radius: 2px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .registration__image {
        display: block;
        margin: 0 auto; } }
    .registration__image:hover img {
      transform: scale(1.1); }
    .registration__image img {
      transition: transform 0.2s ease; }
  .registration__note {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer; }
  .registration__form {
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding-left: 22px; }
    @media only screen and (max-width: 767px) {
      .registration__form {
        padding-left: 0; } }
  .registration__input.input {
    margin-left: 18px; }
    @media only screen and (max-width: 767px) {
      .registration__input.input {
        margin-left: 0;
        margin-top: 20px;
        display: block; } }
    .registration__input.input:first-child {
      margin-left: 0; }
  .registration__input-inner {
    width: 206px;
    height: 56px;
    background-color: #09203a;
    border-radius: 2px;
    border: none;
    box-shadow: inset 0 1px 0 #0d0c13, 0 1px 0 #3e6884;
    color: #ccc;
    font-size: 15px;
    font-weight: 500;
    padding: 0 21px;
    margin-top: 14px; }
  .registration__checkbox {
    display: block;
    position: relative;
    margin-top: 28px; }
    @media only screen and (max-width: 767px) {
      .registration__checkbox .checkbox__label {
        display: inline-block; } }
    .registration__checkbox .checkbox__label {
      display: inline-block; }
  .registration__button {
    font-size: 18px;
    padding: 9px 18px;
    margin-top: 24px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }

.registration-v2 {
  display: block;
  position: relative; }
  .registration-v2__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase; }
    .registration-v2__title_large {
      font-size: 22px; }
  .registration-v2__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .registration-v2__inner {
        flex-direction: column;
        margin-top: 16px; } }
  .registration-v2__footer {
    background: #00142d;
    padding: 10px 0;
    position: relative;
    text-align: center;
    color: #353b58; }
    .registration-v2__footer:after {
      content: "";
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: radial-gradient(ellipse at center, #00b1ec 0, transparent 70%);
      box-shadow: none;
      width: 100%;
      z-index: 0;
      opacity: 1; }
  .registration-v2__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    padding-right: 54px;
    text-align: right; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v2__inner-left {
        padding-right: 33px; } }
    @media only screen and (max-width: 767px) {
      .registration-v2__inner-left {
        border-right: none;
        padding-right: 0; } }
  .registration-v2__inner-right {
    flex: 1 1 50%;
    padding-left: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v2__inner-right {
        padding-left: 23px; } }
    @media only screen and (max-width: 767px) {
      .registration-v2__inner-right {
        border-right: none;
        padding-left: 0;
        margin-top: 30px; } }
  .registration-v2__figure {
    display: inline-block;
    vertical-align: top;
    width: 245px;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.3); }
    @media only screen and (max-width: 767px) {
      .registration-v2__figure {
        box-shadow: inset 0px 0px 5px 3px white;
        border-radius: 5px;
        width: 282px;
        display: block;
        margin: 0 auto;
        font-size: 0; } }
  .registration-v2__figure-img {
    display: block;
    height: 175px;
    object-fit: contain;
    opacity: 0.84;
    box-shadow: inset 0 4px 4px 0 rgba(255, 255, 255, 0.54), inset 0 -4px 3px 0 rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 1px 1px;
    width: 290px; }
    @media only screen and (max-width: 767px) {
      .registration-v2__figure-img {
        width: 141px;
        height: auto;
        display: inline-block;
        vertical-align: top;
        border-radius: 5px 0 0 5px;
        box-shadow: none; } }
  .registration-v2__figure-info {
    background-color: #242333;
    box-shadow: inset 0 -2px 0 0 rgba(255, 255, 255, 0.12);
    border-radius: 0 0 4px 4px; }
    @media only screen and (max-width: 767px) {
      .registration-v2__figure-info {
        height: 101px;
        display: inline-block;
        width: 141px;
        background-color: #31304c;
        vertical-align: top;
        border-radius: 0 5px 5px 0;
        box-shadow: inset -4px 0px 6px 2px rgba(255, 255, 255, 0.5); } }
  .registration-v2__figure-title {
    display: block;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.71);
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 13px 10px 0; }
    @media only screen and (max-width: 767px) {
      .registration-v2__figure-title {
        font-size: 16px; } }
  .registration-v2__figure-caption {
    display: block;
    margin: 0 auto;
    padding: 6px 10px 17px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: #7f7ba0;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82); }
    @media only screen and (max-width: 767px) {
      .registration-v2__figure-caption {
        padding: 12px 20px 17px; } }
  .registration-v2__counter {
    display: inline-block;
    vertical-align: top;
    width: 245px;
    margin-top: 22px; }
    @media only screen and (max-width: 767px) {
      .registration-v2__counter {
        display: block;
        margin: 0 auto; } }
  .registration-v2__counter-title {
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82); }
    @media only screen and (max-width: 767px) {
      .registration-v2__counter-title {
        display: block;
        margin: 16px auto 0 auto; } }
  .registration-v2__counter-inner {
    width: 193px;
    height: 33px;
    opacity: 0.32;
    border-radius: 3px;
    margin: 10px auto 20px auto;
    box-shadow: inset 0 7px 4px 0 rgba(0, 0, 0, 0.86), inset 0 -7px 4px 0 rgba(0, 0, 0, 0.89); }
  .registration-v2__form {
    width: 330px;
    margin: 0 auto; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v2__form {
        width: 300px; } }
    @media only screen and (max-width: 767px) {
      .registration-v2__form {
        width: 260px;
        margin: 0 auto; } }
  .registration-v2__input {
    display: block;
    margin-bottom: 16px; }
  .registration-v2__checkbox {
    display: block;
    text-align: left; }
    .registration-v2__checkbox .checkbox__label {
      color: #f2a639; }
    .registration-v2__checkbox .checkbox__inner:checked + .checkbox__label:after {
      content: "";
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      bottom: 4px;
      left: 4px;
      background: url(../img/checkbox.png) center center no-repeat; }
    .registration-v2__checkbox .checkbox__inner + .checkbox__label:before {
      width: 25px;
      height: 25px;
      line-height: 25px;
      background: #ffe500;
      box-shadow: none;
      border: none;
      border-radius: 20px; }
    .registration-v2__checkbox .checkbox__inner:checked + .checkbox__label:before {
      width: 25px;
      height: 25px;
      line-height: 25px;
      background: #ffe500;
      box-shadow: none;
      border: none;
      border-radius: 20px; }
  .registration-v2__input-inner {
    width: 100%;
    height: 56px;
    border: none;
    color: #ccc;
    font-weight: 700;
    padding: 0 17px;
    border-radius: 2px;
    background-color: #070929;
    box-shadow: 0 0 1px 0 #0096ff; }
  .registration-v2__button {
    display: block;
    margin: 24px auto 0;
    height: 56px;
    font-size: 22px;
    border-radius: 3px;
    background-image: linear-gradient(180deg, #ffea00, #ff9400);
    box-shadow: 0 0 16px 1px #ffd961bf;
    color: #8e231f;
    border: 1px solid #f59125;
    width: 100%; }
    .registration-v2__button:hover {
      box-shadow: 0 0 10px 0 rgba(253, 99, 36, 0.7); }
    @media only screen and (max-width: 767px) {
      .registration-v2__button {
        width: 260px;
        margin: 18px auto 0; } }
  .registration-v2__socials {
    margin: 24px auto 0; }
  .registration-v2__socials-title {
    font-size: 16px;
    font-weight: 500;
    color: #353b58;
    text-align: center;
    margin: 0 auto 16px; }
  .registration-v2 .email-input-reg {
    position: relative; }
    .registration-v2 .email-input-reg .tooltip {
      opacity: 0;
      visibility: hidden; }
    .registration-v2 .email-input-reg:hover .tooltip {
      opacity: 1;
      visibility: visible;
      top: -72px;
      left: 0;
      width: 330px;
      max-width: none;
      background-color: rgba(255, 127, 0, 0.9);
      color: #000;
      border: 0;
      box-shadow: none; }
    .registration-v2 .email-input-reg .tooltip__arrow {
      top: auto;
      bottom: -30px;
      left: 30px; }
      .registration-v2 .email-input-reg .tooltip__arrow:after {
        border-color: rgba(255, 127, 0, 0.9) transparent transparent;
        border-width: 15px 15px 0 15px;
        bottom: 15px; }
  .registration-v2 .socials_form .socials__item {
    background-color: #05165c;
    border: 1px solid #02275f;
    border-radius: 2px;
    box-shadow: inset 0 0 6px 1px #112443;
    width: 52px;
    height: 52px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v2 .socials_form .socials__item {
        width: 48px;
        height: 48px; } }
    @media only screen and (max-width: 767px) {
      .registration-v2 .socials_form .socials__item {
        width: 40px;
        height: 40px; } }

.registration-v3 {
  display: block;
  position: relative; }
  .registration-v3__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase; }
    .registration-v3__title_large {
      font-size: 22px; }
  .registration-v3__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .registration-v3__inner {
        flex-direction: column;
        margin-top: 16px; } }
  .registration-v3__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    text-align: right; }
    @media only screen and (max-width: 767px) {
      .registration-v3__inner-left {
        border-right: none;
        padding-right: 0; } }
  .registration-v3__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v3__inner-right {
        padding-left: 23px; } }
    @media only screen and (max-width: 767px) {
      .registration-v3__inner-right {
        border-right: none;
        padding-left: 0; } }
  .registration-v3__figure {
    width: 342px;
    height: 354px;
    border-radius: 10px;
    background-color: #435182;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.63);
    margin: 0 auto;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v3__figure {
        width: 275px; } }
    @media only screen and (max-width: 767px) {
      .registration-v3__figure {
        width: 280px;
        height: 188px;
        display: block;
        margin: 20px auto;
        font-size: 0; } }
  .registration-v3__figure-img {
    display: block;
    object-fit: contain;
    filter: drop-shadow(8px 8px 10px black);
    position: absolute;
    width: 235px;
    left: calc(50% - (235px/2));
    top: -25px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v3__figure-img {
        width: 216px;
        height: auto;
        left: calc(50% - (216px/2)); } }
    @media only screen and (max-width: 767px) {
      .registration-v3__figure-img {
        width: 120px;
        height: auto;
        left: calc(50% - (120px/2));
        display: inline-block;
        vertical-align: top;
        top: -22px; } }
  .registration-v3__figure-info {
    position: absolute;
    bottom: 25px;
    text-align: center;
    width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v3__figure-info {
        bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .registration-v3__figure-info {
        bottom: 11px; } }
  .registration-v3__figure-caption {
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #ffb824; }
    @media only screen and (max-width: 767px) {
      .registration-v3__figure-caption {
        font-size: 16px; } }
  .registration-v3__figure-note {
    font-family: 'ClearSans';
    font-size: 31px;
    font-weight: bold;
    color: #ffffff;
    padding: 5px 0;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v3__figure-note {
        padding: 10px 0; } }
    @media only screen and (max-width: 767px) {
      .registration-v3__figure-note {
        line-height: 1.04;
        font-size: 25px; } }
  .registration-v3__form {
    width: 330px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v3__form {
        width: 300px; } }
    @media only screen and (max-width: 767px) {
      .registration-v3__form {
        width: 260px;
        margin: 0 auto; } }
  .registration-v3__input {
    display: block;
    margin-bottom: 16px; }
  .registration-v3__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px; }
  .registration-v3__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117; }
    .registration-v3__button:hover {
      box-shadow: 0 0 10px 0 rgba(253, 99, 36, 0.7); }
    @media only screen and (max-width: 767px) {
      .registration-v3__button {
        width: 260px;
        margin: 18px auto 0; } }
  .registration-v3__socials {
    margin: 24px auto 0; }
  .registration-v3__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px; }

.registration-v4 {
  display: block;
  position: relative; }
  .registration-v4__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase; }
    .registration-v4__title_large {
      font-size: 15px;
      font-weight: 100;
      text-align: center;
      text-transform: none;
      margin-bottom: 11px;
      color: #fff; }
      @media only screen and (max-width: 767px) {
        .registration-v4__title_large {
          text-align: center; } }
  .registration-v4__inner {
    display: flex;
    height: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .registration-v4__inner {
        flex-direction: column;
        margin-top: 16px; } }
  .registration-v4__inner-left {
    flex: 1 1 50%;
    text-align: right; }
    @media only screen and (max-width: 767px) {
      .registration-v4__inner-left {
        border-right: none;
        padding-right: 0; } }
  .registration-v4__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v4__inner-right {
        padding-left: 23px; } }
    @media only screen and (max-width: 767px) {
      .registration-v4__inner-right {
        border-right: none;
        padding-left: 0; } }
  .registration-v4__figure {
    width: 342px;
    height: 354px;
    border-radius: 10px;
    background-color: #435182;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.63);
    margin: 0 auto;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v4__figure {
        width: 275px;
        height: 349px; } }
    @media only screen and (max-width: 767px) {
      .registration-v4__figure {
        width: 280px;
        height: 165px;
        display: block;
        margin: 0 auto 25px auto;
        font-size: 0; } }
  .registration-v4__figure-img {
    display: block;
    object-fit: contain;
    max-width: 100%;
    margin: 0 auto; }
  .registration-v4__figure-info {
    position: absolute;
    bottom: 121px;
    text-align: center;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .registration-v4__figure-info {
        display: inline-block;
        width: 156px;
        vertical-align: top;
        bottom: 96px;
        right: 11px;
        text-align: left; } }
  .registration-v4__figure-caption {
    font-size: 22px;
    text-align: center;
    color: #ffb824;
    position: relative;
    margin-bottom: 15px; }
    @media only screen and (max-width: 767px) {
      .registration-v4__figure-caption {
        font-size: 18px; } }
  .registration-v4__figure-note {
    font-family: 'ClearSans';
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v4__figure-note {
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .registration-v4__figure-note {
        font-size: 23px; } }
  .registration-v4__counter {
    display: block;
    width: 342px;
    height: 112px;
    border-radius: 0 0 10px 10px;
    background-color: #364371;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.63);
    position: absolute;
    bottom: 0; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v4__counter {
        width: 275px;
        height: 112px; } }
    @media only screen and (max-width: 767px) {
      .registration-v4__counter {
        width: 280px;
        height: 82px; } }
  .registration-v4__counter-title {
    display: block;
    margin: 5px auto;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffb824;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v4__counter-title {
        margin: 10px auto 0 auto; } }
    @media only screen and (max-width: 767px) {
      .registration-v4__counter-title {
        display: block;
        margin: 8px auto 0 auto;
        font-size: 14px; } }
  .registration-v4__counter-inner {
    width: 131px;
    height: 58px;
    border-radius: 6px;
    background-color: #2f5c8e;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    margin: 10px auto;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .registration-v4__counter-inner {
        margin: 7px auto;
        width: 101px;
        height: 41px; } }
  .registration-v4__counter-inner_inner {
    width: 112px;
    height: 42px;
    opacity: 0.86;
    border-radius: 2px;
    box-shadow: inset 0 8px 7px 0 rgba(0, 0, 0, 0.86), inset 0 -8px 7px 0 rgba(0, 0, 0, 0.89);
    position: absolute;
    top: 8px;
    left: 9px;
    background-color: #ffffff; }
    @media only screen and (max-width: 767px) {
      .registration-v4__counter-inner_inner {
        top: 6px;
        left: 8px;
        width: 86px;
        height: 29px; } }
  .registration-v4__form {
    width: 330px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v4__form {
        width: 300px; } }
    @media only screen and (max-width: 767px) {
      .registration-v4__form {
        width: 260px;
        margin: 0 auto; } }
  .registration-v4__input {
    display: block;
    margin-bottom: 16px; }
  .registration-v4__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px; }
  .registration-v4__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117; }
    .registration-v4__button:hover {
      box-shadow: 0 0 10px 0 rgba(253, 99, 36, 0.7); }
    @media only screen and (max-width: 767px) {
      .registration-v4__button {
        width: 260px;
        margin: 18px auto 0; } }
  .registration-v4__socials {
    margin: 24px auto 0; }
  .registration-v4__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px; }
  .registration-v4__timer-title {
    font-size: 22px;
    text-align: center;
    color: #ffb824;
    margin-top: -20px; }

.js-timeout {
  font-family: 'ClearSans';
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.74);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.61);
  letter-spacing: 9px;
  line-height: 38px; }
  @media only screen and (max-width: 767px) {
    .js-timeout {
      font-size: 21px;
      letter-spacing: 6px;
      line-height: 28px; } }

.registration-v5 {
  display: block;
  position: relative; }
  .registration-v5__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase; }
    .registration-v5__title_large {
      font-size: 22px; }
  .registration-v5__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .registration-v5__inner {
        flex-direction: column;
        margin-top: 16px; } }
  .registration-v5__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    text-align: right; }
    @media only screen and (max-width: 767px) {
      .registration-v5__inner-left {
        border-right: none;
        padding-right: 0; } }
  .registration-v5__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__inner-right {
        padding-left: 23px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__inner-right {
        border-right: none;
        padding-left: 0; } }
  .registration-v5__figure {
    width: 360px;
    position: absolute;
    left: 95px; }
    .registration-v5__figure table {
      border-spacing: 0 8px; }
      @media only screen and (max-width: 767px) {
        .registration-v5__figure table {
          padding-left: 10px; } }
      .registration-v5__figure table td:nth-child(2) {
        padding-left: 10px;
        text-align: left;
        font-family: 'ClearSans';
        font-size: 16px;
        font-weight: 500;
        color: #ffffff; }
        @media only screen and (max-width: 767px) {
          .registration-v5__figure table td:nth-child(2) {
            font-size: 14px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__figure {
        width: 275px;
        height: 349px;
        left: 60px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__figure {
        left: auto;
        width: 300px;
        position: relative;
        display: block;
        margin: 0 auto 25px auto;
        font-size: 0; } }
  .registration-v5__figure-img {
    width: 45px;
    margin-top: 8px;
    margin-left: 25px;
    margin-right: 10px;
    display: block;
    object-fit: contain;
    float: left; }
    @media only screen and (max-width: 767px) {
      .registration-v5__figure-img {
        width: 40px;
        height: auto;
        margin-left: 10px; } }
  .registration-v5__figure-info {
    width: 243px;
    height: 62px;
    border-radius: 4px;
    background-color: #333157;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.65);
    margin: 0 auto; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__figure-info {
        width: 220px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__figure-info {
        width: 140px;
        height: 57px; } }
  .registration-v5__figure-container {
    margin-top: 20px;
    margin-right: 37px;
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__figure-container {
        margin-right: 0; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__figure-container {
        flex-direction: row;
        margin-right: 0;
        margin-top: 10px; } }
  .registration-v5__figure-caption {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.76);
    font-size: 22px;
    font-weight: bold;
    color: #ffb824;
    margin-top: 8px;
    text-align: left;
    font-size: 22px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__figure-caption {
        font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__figure-caption {
        font-size: 18px;
        margin-top: 1px; } }
  .registration-v5__figure-note {
    font-family: 'ClearSans';
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__figure-note {
        font-size: 12px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__figure-note {
        font-size: 12px;
        font-weight: 500; } }
  .registration-v5__counter {
    width: 243px;
    height: 62px;
    border-radius: 4px;
    background-color: #333157;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.65);
    margin: 3px auto; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__counter {
        width: 220px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__counter {
        width: 140px;
        height: 57px;
        margin-left: 3px;
        margin-top: 0; } }
  .registration-v5__cross {
    width: 31px;
    height: 30px;
    background-color: #58567c;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.45);
    border: solid 3px #3a3859;
    border-radius: 50%;
    position: absolute;
    top: 252px;
    left: 25px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__cross {
        top: 265px;
        left: 12px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__cross {
        top: 195px;
        left: 133px; } }
    .registration-v5__cross:after {
      content: '+';
      font-size: 25px;
      font-weight: bold;
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.34);
      font-family: 'ClearSans';
      color: #ffffff;
      position: absolute;
      top: -4px;
      left: 6px; }
  .registration-v5__form {
    width: 330px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v5__form {
        width: 300px; } }
    @media only screen and (max-width: 767px) {
      .registration-v5__form {
        width: 260px;
        margin: 0 auto; } }
  .registration-v5__input {
    display: block;
    margin-bottom: 16px; }
  .registration-v5__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px; }
  .registration-v5__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117; }
    .registration-v5__button:hover {
      box-shadow: 0 0 10px 0 rgba(253, 99, 36, 0.7); }
    @media only screen and (max-width: 767px) {
      .registration-v5__button {
        width: 260px;
        margin: 18px auto 0; } }
  .registration-v5__socials {
    margin: 24px auto 0; }
  .registration-v5__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px; }

.registration-v6 {
  display: block;
  position: relative; }
  .registration-v6__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase; }
    .registration-v6__title_large {
      font-size: 22px; }
  .registration-v6__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .registration-v6__inner {
        flex-direction: column;
        margin-top: 16px; } }
  .registration-v6__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    text-align: right; }
    @media only screen and (max-width: 767px) {
      .registration-v6__inner-left {
        border-right: none;
        padding-right: 0; } }
  .registration-v6__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__inner-right {
        padding-left: 23px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__inner-right {
        border-right: none;
        padding-left: 0; } }
  .registration-v6__figure {
    width: 342px;
    margin: 0 auto;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__figure {
        width: 275px;
        height: 349px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__figure {
        width: 280px;
        display: block;
        margin: 0 auto 25px auto; } }
  .registration-v6__figure-img-two, .registration-v6__figure-img-one {
    float: left;
    margin-right: 25px;
    margin-left: 11px; }
  .registration-v6__figure-img-one {
    width: 76px;
    margin-top: 18px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__figure-img-one {
        width: 68px;
        margin-top: 14px;
        margin-left: 13px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__figure-img-one {
        width: 50px;
        margin-top: 8px;
        margin-left: 7px; } }
  .registration-v6__figure-img-two {
    width: 78px;
    margin-right: 11px;
    margin-top: 10px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__figure-img-two {
        margin-top: 9px;
        margin-left: 12px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__figure-img-two {
        width: 65px;
        margin-top: 10px;
        margin-left: 15px; } }
  .registration-v6__title-img-one {
    padding-top: 29px;
    text-align: left;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.48);
    text-transform: uppercase; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__title-img-one {
        font-size: 14px;
        padding-top: 20px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__title-img-one {
        padding-top: 8px; } }
  .registration-v6__title-img-two {
    padding-top: 27px;
    text-align: left;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.48); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__title-img-two {
        font-size: 14px;
        padding-top: 20px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__title-img-two {
        padding-top: 13px;
        font-size: 14px; } }
  .registration-v6__title-img-num {
    font-weight: bold;
    font-size: 22px;
    color: #fdab1d; }
  .registration-v6__figure-info-title {
    margin-bottom: 15px;
    text-align: center;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82);
    text-transform: uppercase; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__figure-info-title {
        font-size: 14px; } }
  .registration-v6__figure-info {
    width: 342px;
    height: 108px;
    border-radius: 4px;
    background-color: #2c2b3e; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__figure-info {
        width: 275px;
        height: 96px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__figure-info {
        width: 280px;
        height: 70px;
        border-bottom: 2px solid  #435182; } }
  .registration-v6__figure-caption {
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #ffb824; }
    @media only screen and (max-width: 767px) {
      .registration-v6__figure-caption {
        text-align: left;
        font-size: 18px; } }
  .registration-v6__figure-note {
    font-family: 'ClearSans';
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__figure-note {
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__figure-note {
        font-size: 23px; } }
  .registration-v6__counter {
    margin-top: 1px;
    width: 342px;
    height: 174px;
    border-radius: 4px;
    background-color: #435182; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__counter {
        width: 275px;
        height: 140px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__counter {
        width: 280px;
        height: 123px; } }
  .registration-v6__counter-title {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffb824;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__counter-title {
        margin: 2px auto 0 auto; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__counter-title {
        display: block;
        margin: 2px auto 0 auto;
        font-size: 14px; } }
  .registration-v6__counter-inner {
    width: 131px;
    height: 58px;
    border-radius: 6px;
    background-color: #7f8ab0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    margin: 5px auto;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__counter-inner {
        width: 97.1px;
        height: 43px;
        margin: 0 auto; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__counter-inner {
        margin: 0 auto;
        width: 101px;
        height: 41px; } }
  .registration-v6__counter-inner_inner {
    width: 112px;
    height: 42px;
    opacity: 0.86;
    border-radius: 2px;
    box-shadow: inset 0 8px 7px 0 rgba(0, 0, 0, 0.86), inset 0 -8px 7px 0 rgba(0, 0, 0, 0.89);
    position: absolute;
    top: 8px;
    left: 9px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__counter-inner_inner {
        width: 83px;
        height: 31.1px;
        top: 6px;
        left: 7px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__counter-inner_inner {
        top: 6px;
        left: 8px;
        width: 86px;
        height: 29px; } }
  .registration-v6__form {
    width: 330px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v6__form {
        width: 300px; } }
    @media only screen and (max-width: 767px) {
      .registration-v6__form {
        width: 260px;
        margin: 0 auto; } }
  .registration-v6__input {
    display: block;
    margin-bottom: 16px; }
  .registration-v6__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px; }
  .registration-v6__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117; }
    .registration-v6__button:hover {
      box-shadow: 0 0 10px 0 rgba(253, 99, 36, 0.7); }
    @media only screen and (max-width: 767px) {
      .registration-v6__button {
        width: 260px;
        margin: 18px auto 0; } }
  .registration-v6__socials {
    margin: 24px auto 0; }
  .registration-v6__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px; }

.registration-v7 {
  display: block;
  position: relative; }
  .registration-v7__title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #ffb824;
    text-transform: uppercase; }
    .registration-v7__title_large {
      font-size: 18px; }
      @media only screen and (max-width: 767px) {
        .registration-v7__title_large {
          width: 295px; } }
    .registration-v7__title_small {
      font-size: 18px;
      color: #ffffff; }
      @media only screen and (max-width: 767px) {
        .registration-v7__title_small {
          font-size: 11px; } }
  .registration-v7__inner {
    display: flex;
    margin-top: 26px;
    height: 100%;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .registration-v7__inner {
        flex-direction: column;
        margin-top: 16px; } }
  .registration-v7__inner-left {
    flex: 1 1 50%;
    border-right: solid 2px #121129;
    text-align: right; }
    @media only screen and (max-width: 767px) {
      .registration-v7__inner-left {
        border-right: none;
        padding-right: 0; } }
  .registration-v7__inner-right {
    flex: 1 1 50%;
    border-left: solid 2px #302f3e;
    padding-left: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__inner-right {
        padding-left: 23px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__inner-right {
        border-right: none;
        padding-left: 0; } }
  .registration-v7__figure {
    width: 342px;
    margin: 0 auto;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__figure {
        width: 275px;
        height: 349px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__figure {
        width: 280px;
        display: block;
        margin: 0 auto 25px auto; } }
  .registration-v7__figure-img-two, .registration-v7__figure-img-one {
    float: left;
    margin-right: 25px;
    margin-left: 11px; }
  .registration-v7__figure-img-one {
    width: 76px;
    margin-top: 15px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__figure-img-one {
        width: 68px;
        margin-top: 14px;
        margin-left: 13px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__figure-img-one {
        width: 50px;
        margin-top: 8px;
        margin-left: 7px; } }
  .registration-v7__figure-img-two {
    width: 107px;
    float: none;
    margin-right: 11px;
    margin-top: 10px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__figure-img-two {
        margin-top: 9px;
        margin-left: 12px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__figure-img-two {
        float: left;
        width: 65px;
        margin-top: 10px;
        margin-left: 15px; } }
  .registration-v7__title-img-one {
    padding-top: 29px;
    text-align: left;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.48);
    text-transform: uppercase; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__title-img-one {
        font-size: 16px;
        padding-top: 13px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__title-img-one {
        font-size: 14px;
        padding-top: 8px; } }
  .registration-v7__title-img-two {
    padding-top: 4px;
    text-align: center;
    font-family: 'ClearSans';
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.48); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__title-img-two {
        font-size: 14px;
        padding-bottom: 6px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__title-img-two {
        text-align: left;
        padding-top: 13px;
        font-size: 14px; } }
  .registration-v7__title-img-num {
    font-weight: bold;
    font-size: 21px;
    color: #fdab1d; }
    .registration-v7__title-img-num-big {
      font-size: 22px; }
  .registration-v7__figure-info {
    width: 342px;
    height: 100px;
    border-radius: 4px;
    background-color: #2c2b3e; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__figure-info {
        width: 275px;
        height: 96px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__figure-info {
        width: 280px;
        height: 70px;
        border-bottom: 2px solid  #435182; } }
  .registration-v7__figure-caption {
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: #ffb824; }
    @media only screen and (max-width: 767px) {
      .registration-v7__figure-caption {
        text-align: left;
        font-size: 18px; } }
  .registration-v7__figure-note {
    font-family: 'ClearSans';
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 9px 2px rgba(0, 0, 0, 0.5); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__figure-note {
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__figure-note {
        font-size: 23px; } }
  .registration-v7__counter {
    margin-top: 1px;
    width: 342px;
    height: 257px;
    border-radius: 4px;
    background-color: #435182; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__counter {
        width: 275px;
        height: 248px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__counter {
        width: 280px;
        height: 123px; } }
  .registration-v7__counter-title {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffb824;
    text-shadow: 0 3px 0 rgba(0, 0, 0, 0.82); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__counter-title {
        margin: 2px auto 0 auto; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__counter-title {
        display: block;
        margin: 2px auto 0 auto;
        font-size: 14px; } }
  .registration-v7__counter-inner {
    width: 131px;
    height: 58px;
    border-radius: 6px;
    background-color: #7f8ab0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
    margin: 5px auto;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__counter-inner {
        width: 97.1px;
        height: 43px;
        margin: 0 auto; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__counter-inner {
        margin: 0 auto;
        width: 101px;
        height: 41px; } }
  .registration-v7__counter-inner_inner {
    width: 112px;
    height: 42px;
    opacity: 0.86;
    border-radius: 2px;
    box-shadow: inset 0 8px 7px 0 rgba(0, 0, 0, 0.86), inset 0 -8px 7px 0 rgba(0, 0, 0, 0.89);
    position: absolute;
    top: 8px;
    left: 9px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__counter-inner_inner {
        width: 83px;
        height: 31.1px;
        top: 6px;
        left: 7px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__counter-inner_inner {
        top: 6px;
        left: 8px;
        width: 86px;
        height: 29px; } }
  .registration-v7__form {
    width: 330px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .registration-v7__form {
        width: 300px; } }
    @media only screen and (max-width: 767px) {
      .registration-v7__form {
        width: 260px;
        margin: 0 auto; } }
  .registration-v7__input {
    display: block;
    margin-bottom: 16px; }
  .registration-v7__input-inner {
    width: 100%;
    height: 56px;
    border-radius: 3px;
    border: none;
    background-color: #acabbd;
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.48);
    font-weight: 700;
    padding: 0 17px; }
  .registration-v7__button {
    display: block;
    margin: 24px auto 0;
    width: 220px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(120deg, #fda929, #fd1741);
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);
    font-size: 22px;
    text-shadow: 0 2px 0 #822117; }
    .registration-v7__button:hover {
      box-shadow: 0 0 10px 0 rgba(253, 99, 36, 0.7); }
    @media only screen and (max-width: 767px) {
      .registration-v7__button {
        width: 260px;
        margin: 18px auto 0; } }
  .registration-v7__socials {
    margin: 24px auto 0; }
  .registration-v7__socials-title {
    font-size: 14px;
    font-weight: 500;
    color: #7f7ba0;
    text-align: center;
    margin: 0 auto 16px; }

#sunday-popup {
  background: url(../img/sunday-popup.png) center top no-repeat #010417;
  width: 782px;
  height: 559px;
  color: #fff;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    #sunday-popup {
      height: auto;
      padding-bottom: 21px;
      left: calc(50% - 335px);
      transform: none;
      position: absolute;
      display: block;
      overflow-y: auto !important; } }
  @media only screen and (max-width: 782px) {
    #sunday-popup {
      left: 0; } }
  @media only screen and (max-width: 670px) {
    #sunday-popup {
      background-position: -50px 0; } }
  @media only screen and (max-width: 500px) {
    #sunday-popup {
      background-image: none; } }
  #sunday-popup .popup__close {
    background: transparent;
    box-shadow: none; }
  #sunday-popup h1 {
    font-size: 43px;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media only screen and (max-width: 400px) {
      #sunday-popup h1 {
        font-size: 35px; } }
  #sunday-popup h2 {
    font-size: 30px;
    margin-top: 0; }
    @media only screen and (max-width: 400px) {
      #sunday-popup h2 {
        font-size: 25px; } }
    #sunday-popup h2 span {
      color: #ffbf3e; }
  #sunday-popup .popup-container {
    padding-left: 220px; }
    @media only screen and (max-width: 710px) {
      #sunday-popup .popup-container {
        padding-left: 120px; } }
    @media only screen and (max-width: 500px) {
      #sunday-popup .popup-container {
        padding: 0 10px; } }
    #sunday-popup .popup-container span {
      color: #9e9cbf;
      font-family: 'ClearSans',sans-serif;
      font-size: 15px;
      line-height: 14px;
      text-align: center;
      width: 50%;
      display: block;
      margin: 20px auto 0; }
      @media only screen and (max-width: 600px) {
        #sunday-popup .popup-container span {
          width: 100%; } }
  #sunday-popup .popup__button {
    display: block;
    margin: 0 auto;
    background: linear-gradient(150deg, #ffb11a, #ff0036); }
  #sunday-popup img {
    max-width: 100%; }

#rwp-popup {
  background: url(../img/rwp-popup.png) center top no-repeat #010417;
  max-width: 663px;
  height: 560px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  left: calc(50% - 284px);
  transform: translate(0, -49%);
  display: block;
  overflow-y: auto !important;
  min-height: 0; }
  @media only screen and (max-width: 767px) {
    #rwp-popup {
      height: auto;
      padding-bottom: 21px;
      left: calc(50% - 331px);
      transform: none;
      position: absolute;
      display: block;
      overflow-y: auto !important; } }
  @media only screen and (max-width: 663px) {
    #rwp-popup {
      left: 0; } }
  #rwp-popup .popup__close {
    background: transparent;
    box-shadow: none; }
  #rwp-popup h1 {
    font-size: 43px;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media only screen and (max-width: 400px) {
      #rwp-popup h1 {
        font-size: 35px; } }
  #rwp-popup h2 {
    font-size: 30px;
    margin-top: 0; }
    @media only screen and (max-width: 400px) {
      #rwp-popup h2 {
        font-size: 25px; } }
    #rwp-popup h2 span {
      color: #ffbf3e; }
  #rwp-popup .popup-container span {
    color: #9e9cbf;
    font-family: 'ClearSans',sans-serif;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
    width: 50%;
    display: block;
    margin: 20px auto 0; }
    @media only screen and (max-width: 600px) {
      #rwp-popup .popup-container span {
        width: 100%; } }
  #rwp-popup .popup__button {
    display: block;
    margin: -5px auto 0;
    background: linear-gradient(150deg, #ffb11a, #ff0036);
    color: white;
    text-transform: uppercase;
    text-shadow: 0 2px 1.96px #1d1b2e;
    font-size: 22px;
    font-weight: 100;
    width: 261px;
    height: 77px; }
  #rwp-popup img {
    max-width: 100%;
    margin-top: 20px; }

#monday-rush-popup {
  background: url(../img/monday-rush-popup.png) top no-repeat #000213;
  max-width: 717px;
  height: 660px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  left: calc(50% - 284px);
  -webkit-transform: translateY(-49%);
  -ms-transform: translateY(-49%);
  transform: translateY(-49%); }
  @media only screen and (max-width: 767px) {
    #monday-rush-popup {
      height: auto;
      padding-bottom: 21px;
      left: calc(50% - 358px);
      transform: none;
      position: absolute;
      display: block;
      overflow-y: auto !important; } }
  @media only screen and (max-width: 717px) {
    #monday-rush-popup {
      left: 0; } }
  #monday-rush-popup .popup__close {
    background: transparent;
    box-shadow: none; }
  #monday-rush-popup h1 {
    font-size: 33px;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media only screen and (max-width: 400px) {
      #monday-rush-popup h1 {
        font-size: 28px; } }
  #monday-rush-popup h2 {
    font-size: 30px;
    margin: 0; }
    @media only screen and (max-width: 400px) {
      #monday-rush-popup h2 {
        font-size: 25px; } }
    #monday-rush-popup h2 span {
      color: #ffbf3e; }
  #monday-rush-popup .popup-container {
    margin-bottom: 20px; }
    #monday-rush-popup .popup-container span {
      color: #9e9cbf;
      font-family: 'ClearSans',sans-serif;
      font-size: 15px;
      line-height: 14px;
      text-align: center;
      width: 50%;
      display: block;
      margin: 20px auto 0; }
      @media only screen and (max-width: 600px) {
        #monday-rush-popup .popup-container span {
          width: 100%; } }
  #monday-rush-popup .popup__button {
    display: block;
    margin: -5px auto 0;
    background: linear-gradient(150deg, #ffb11a, #ff0036);
    color: white;
    text-transform: uppercase;
    text-shadow: 0 2px 1.96px #1d1b2e;
    font-size: 22px;
    font-weight: 100;
    width: 261px;
    height: 77px; }
  #monday-rush-popup img {
    max-width: 100%;
    margin-top: 20px; }

.clock-timer {
  width: 99px;
  height: 49px;
  position: relative;
  background-color: #09203a;
  border-radius: 2px;
  box-shadow: inset 0 1px 0 rgba(4, 4, 4, 0.24), 0 1px 0 #302f43; }
  .clock-timer__icon {
    position: absolute;
    top: 13px;
    left: 17px; }
  .clock-timer__counter {
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 15px;
    text-align: right; }

.payment {
  display: block;
  position: relative;
  max-height: 440px; }
  @media only screen and (max-width: 767px) {
    .payment {
      max-height: none; } }
  .payment__gallery {
    font-size: 0; }
  .payment__row {
    display: block;
    position: relative;
    text-align: center;
    padding: 0 20px;
    margin: 0 auto; }
    .payment__row-inner {
      text-align: left; }
    @media only screen and (max-width: 767px) {
      .payment__row {
        padding: 0 8px; } }
  .payment__item {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    margin: 8px 15px 8px 0;
    cursor: pointer; }
    .payment__item:nth-child(3n) {
      margin-right: 0; }
  .payment__tooltip {
    display: none;
    position: relative;
    margin: 4px auto 30px;
    width: 600px;
    max-width: calc(100% - 45px);
    left: -4px; }
    .payment__tooltip_inner {
      text-align: left; }
      @media only screen and (max-width: 767px) {
        .payment__tooltip_inner {
          width: calc(100% - 8px); } }
    @media only screen and (max-width: 767px) {
      .payment__tooltip {
        width: 100%;
        padding: 0 8px; } }
    .payment__tooltip_open {
      display: block; }

.tab {
  display: block;
  position: relative; }
  .tab__close {
    width: 17px;
    height: 17px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 31px;
    right: 26px;
    text-align: center;
    padding: 0;
    cursor: pointer; }
  .tab__action {
    display: block;
    position: relative;
    height: 70px;
    font-size: 0;
    background-color: #09203a;
    box-shadow: inset 0 1px 0 rgba(61, 68, 75, 0.35); }
    @media only screen and (max-width: 767px) {
      .tab__action {
        height: 43px; } }
  .tab__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 24px 47px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.42);
    font-family: 'ClearSans',sans-serif;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #727f8c; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tab__item {
        padding: 24px 34px; } }
    @media only screen and (max-width: 767px) {
      .tab__item {
        font-size: 14px;
        padding: 12px; } }
    .tab__item:before {
      content: "-";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%); }
    .tab__item:first-child:before {
      content: none; }
    .tab__item_active + .tab__item:before {
      content: none; }
    .tab__item:hover {
      color: #fff; }
      .tab__item:hover:after {
        color: #727f8c; }
    .tab__item_active {
      color: #fefefe;
      background-color: #091420;
      box-shadow: inset 0 3px 0 #ff9600;
      border-radius: 3px 3px 0 0; }
      .tab__item_active:before {
        content: none; }
  .tab__content {
    display: block;
    position: relative; }
    .tab__content .popup .tab__content {
      background-color: #222131; }

.tab_style_button {
  display: block;
  position: relative; }
  .tab_style_button .tab__action {
    height: 58px;
    box-shadow: none;
    background: none; }
  .tab_style_button .tab__content {
    margin-top: 25px;
    background-color: transparent; }
  .tab_style_button .tab__item {
    width: 200px;
    padding: 18px 10px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.09);
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    background-color: #09203a;
    border-radius: 0;
    box-shadow: none;
    border-left: 1px solid #1b1a27; }
    @media only screen and (max-width: 767px) {
      .tab_style_button .tab__item {
        width: 32%;
        font-size: 14px; } }
    .tab_style_button .tab__item:before {
      content: none; }
    .tab_style_button .tab__item:first-child {
      margin-right: 0; }
    .tab_style_button .tab__item_active {
      background-color: #e2d533;
      background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
      text-shadow: 1px 1px 4px #3a0404;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
      .tab_style_button .tab__item_active ~ .tab__item:after {
        content: none; }

.tab-cashier {
  display: block;
  position: relative;
  text-align: center;
  background-color: #132e4d; }
  .tab-cashier__info-panel {
    display: block;
    margin: 0 auto;
    width: 440px; }
  .tab-cashier__header {
    min-height: 20px;
    position: relative;
    background-color: #132e4d; }
    .tab-cashier__header:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      opacity: 0.27;
      background-image: linear-gradient(90deg, #04469c, #52baff 50%, #04469c);
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.42); }
  .tab-cashier__content {
    background-color: #132e4d;
    width: 100%;
    margin: 20px auto 0;
    padding-bottom: 76px; }
    @media only screen and (max-width: 767px) {
      .tab-cashier__content {
        width: 100%; } }
    .tab-cashier__content .tab__content {
      max-height: 440px;
      overflow: hidden;
      max-width: 680px;
      margin: 25px auto 0; }
      @media only screen and (max-width: 767px) {
        .tab-cashier__content .tab__content {
          max-height: none; } }
  .tab-cashier__content_deposit {
    background-color: #1b1a27;
    width: 680px;
    margin: 0 auto;
    padding-bottom: 76px; }
    @media only screen and (max-width: 767px) {
      .tab-cashier__content_deposit {
        width: 100%; } }
    .tab-cashier__content_deposit .tab__content {
      max-height: 440px;
      overflow: hidden; }
  .tab-cashier .tab__title {
    padding: 30px 120px 21px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .tab-cashier .tab__title {
        padding: 30px 20px 21px;
        text-align: left; } }
    .tab-cashier .tab__title .fa {
      margin-right: 10px; }
      @media only screen and (max-width: 767px) {
        .tab-cashier .tab__title .fa {
          height: 100%;
          position: absolute;
          left: 20px;
          top: 31px; } }
    .tab-cashier .tab__title .tab-title_yellow {
      color: #f2e419; }
      @media only screen and (max-width: 767px) {
        .tab-cashier .tab__title .tab-title_yellow {
          padding-left: 32px; } }
      .tab-cashier .tab__title .tab-title_yellow * {
        color: #f2e419; }
    .tab-cashier .tab__title .tab-title_grey {
      color: #585861; }
    .tab-cashier .tab__title_big {
      font-size: 20px;
      margin-bottom: 13px; }
    .tab-cashier .tab__title_caption {
      font-weight: normal; }
  .tab-cashier ul.tab__title_caption {
    text-align: left;
    width: 72%;
    margin: 0 auto 10px;
    padding: 0; }
    @media only screen and (max-width: 767px) {
      .tab-cashier ul.tab__title_caption {
        width: 80%; } }
    .tab-cashier ul.tab__title_caption li {
      margin: 0 0 3px;
      list-style-type: none; }
  .tab-cashier__payment {
    padding-top: 55px; }
  .tab-cashier .tab_style_button {
    width: 640px;
    display: inline-block;
    vertical-align: top; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tab-cashier .tab_style_button {
        width: calc(100%/3*2 - 45px); }
        .tab-cashier .tab_style_button svg:not(:root),
        .tab-cashier .tab_style_button .payitem__img_inner img {
          max-width: 96%; }
        .tab-cashier .tab_style_button .payitem {
          width: 30%; }
        .tab-cashier .tab_style_button .tab__item {
          width: 30%; } }
    @media only screen and (max-width: 767px) {
      .tab-cashier .tab_style_button {
        width: 100%; } }
  .tab-cashier__bonus-sidebar {
    width: 270px;
    display: inline-block;
    background: #09203a;
    color: #fff; }
    .tab-cashier__bonus-sidebar img {
      max-width: 100%; }
    .tab-cashier__bonus-sidebar .bonus-sidebar__header {
      background: #0d2847;
      padding: 5px 15px;
      font-size: 21px;
      text-transform: uppercase;
      font-weight: 700; }
    .tab-cashier__bonus-sidebar .bonus-sidebar__content {
      padding: 0 10px; }
    .tab-cashier__bonus-sidebar .bonus-img {
      width: 100px; }
    .tab-cashier__bonus-sidebar .bonus-name {
      font-size: 18px;
      color: #fdc236;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;
      text-shadow: 0 2px 0 rgba(21, 20, 31, 0.7);
      margin: 5px 0 0; }
    .tab-cashier__bonus-sidebar .bonus-description {
      font-size: 15px;
      color: #c1c1cc;
      line-height: 1.2;
      text-align: center;
      text-shadow: 0 2px 0 rgba(44, 44, 60, 0.7); }
    .tab-cashier__bonus-sidebar .button_color_orange {
      margin-bottom: 15px;
      border-radius: 20px; }
    .tab-cashier__bonus-sidebar .bonus-timer {
      margin-bottom: 20px; }
    .tab-cashier__bonus-sidebar .bonus-sidebar__subtitle {
      background: #09203a;
      padding: 10px 15px; }
      .tab-cashier__bonus-sidebar .bonus-sidebar__subtitle .bonus-subtitle__header {
        margin: 0; }
      .tab-cashier__bonus-sidebar .bonus-sidebar__subtitle small {
        color: #cccccc;
        font-size: 12px; }
    @media only screen and (max-width: 767px) {
      .tab-cashier__bonus-sidebar {
        display: block;
        margin: 20px auto 0;
        width: 320px;
        max-width: 100%; } }
  .tab-cashier .bonus-panel__title_alert {
    white-space: normal;
    margin-top: -6px; }

.tab-cashier__header_auto {
  height: auto; }

.tab-bonuses {
  display: block;
  position: relative; }
  .tab-bonuses__gallery {
    padding: 40px 32px 0;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .tab-bonuses__gallery {
        padding: 20px; } }
  .tab-bonuses__item {
    margin-bottom: 26px; }

.vip {
  display: block;
  position: relative;
  font-family: 'ClearSans', sans-serif; }
  .vip__header {
    text-align: center;
    padding: 34px; }
  .vip__action {
    background-color: #132e4d;
    text-align: center;
    position: relative;
    display: block;
    padding: 50px 0 64px; }
  .vip__rating {
    height: 100px; }
  .vip__title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    position: relative; }
    .vip__title.title_font_hugest {
      font-size: 30px;
      line-height: 40px;
      margin: 30px 20px 10px; }
  .vip__subtitle {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 12px;
    display: block; }
    @media only screen and (max-width: 767px) {
      .vip__subtitle {
        display: none; } }
  .vip__icon {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 12px;
    text-align: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: #4ea1e1;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .vip__icon .rating__info {
      bottom: 0; }
    .vip__icon .rating__tooltip {
      text-transform: none;
      font-weight: 400; }
    .vip__icon .icon {
      margin: 0; }
  .vip__table {
    display: table;
    border-collapse: collapse;
    position: relative;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .vip__table {
        display: block; } }
  .vip__cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .vip__cell {
        display: block; } }
  .vip__input .input__inner {
    width: 205px;
    height: 47px;
    text-align: center;
    border-radius: 1px;
    background-color: #091420;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.13), 0 1px 0 #2c2b3d; }
    @media only screen and (max-width: 767px) {
      .vip__input .input__inner {
        width: 166px; } }
  .vip__input_color_white .input__inner {
    color: #fff; }
  .vip__input_color_yellow .input__inner {
    color: #fff000; }
  .vip__viewrate {
    width: 166px;
    height: 63px;
    background-color: #3788d6;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    color: #fff;
    font-size: 29px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 10px; }
    @media only screen and (max-width: 767px) {
      .vip__viewrate {
        margin: 0 auto; } }
  .vip__button {
    width: auto;
    height: 63px;
    white-space: nowrap;
    background-color: #e2d533;
    background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    text-shadow: 1px 1px 4px #3a0404;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    padding: 0 20px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    display: block;
    position: absolute;
    bottom: -32px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1; }
  .vip__levels-table {
    padding: 0 50px 34px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .vip__levels-table {
        padding: 0 30px 34px; } }
    @media only screen and (max-width: 767px) {
      .vip__levels-table {
        padding: 0 28px 34px;
        position: relative;
        z-index: 0; } }

.profile {
  display: block;
  width: 100%;
  height: 100%;
  position: relative; }
  .profile__table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
    position: relative; }
  .profile__header {
    height: 133px; }
  .profile__aside {
    display: table-cell;
    vertical-align: top;
    position: relative;
    width: 298px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .profile__aside {
        width: 219px; } }
    @media only screen and (max-width: 767px) {
      .profile__aside {
        display: block;
        width: 100%; } }
    .profile__aside:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-image: linear-gradient(180deg, #132e4d, #52baff 50%, #132e4d);
      box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
      @media only screen and (max-width: 767px) {
        .profile__aside:after {
          display: none; } }
    .profile__aside .cabinet-promo {
      max-width: 200px;
      margin: 0 auto;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .profile__aside .cabinet-promo {
          margin-top: 20px; } }
      .profile__aside .cabinet-promo .profile-contacts__button {
        margin-top: -19%; }
      .profile__aside .cabinet-promo .title__inner {
        position: relative;
        width: 100%; }
      .profile__aside .cabinet-promo .rating__info {
        top: 1px; }
      .profile__aside .cabinet-promo .error {
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #ff002c;
        display: none; }
      .profile__aside .cabinet-promo .cabinet-promo__note {
        color: #9e9cbf;
        font-family: 'ClearSans',sans-serif;
        font-size: 15px;
        line-height: 14px;
        text-align: center;
        margin: 10px; }
      .profile__aside .cabinet-promo .form-email {
        position: relative; }
      .profile__aside .cabinet-promo__input {
        width: 100%;
        height: 42px;
        padding: 10px 109px 14px 17px;
        background-color: #141320;
        color: #fff;
        font-size: 14px;
        text-transform: none;
        border-radius: 1px;
        box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.13), 0 1px 0 #222130; }
      .profile__aside .cabinet-promo .tooltip__content {
        text-transform: none;
        font-weight: normal; }
      .profile__aside .cabinet-promo__button {
        width: 80px;
        height: 29px;
        padding: 6px 0;
        font-size: 13px;
        line-height: 17px;
        display: inline-block; }
      .profile__aside .cabinet-promo .promo--success {
        font-size: 13px;
        color: white;
        border: solid 1px rgba(255, 255, 255, 0.16);
        padding: 15px;
        margin: 20px 0; }
      .profile__aside .cabinet-promo .promo--success p {
        margin-top: 2px; }
      .profile__aside .cabinet-promo .promo--success a {
        color: #3c90f0;
        text-decoration: underline; }
  .profile__title {
    font-size: 30px;
    line-height: 40px;
    display: block;
    text-align: center;
    padding: 46px 20px 47px; }
  .profile__main {
    display: table-cell;
    vertical-align: top; }
    @media only screen and (max-width: 767px) {
      .profile__main {
        display: block;
        width: 100%; } }
  .profile__form_v1 {
    background-color: #1b1a27;
    box-shadow: inset 0 -1px 0 #2b2a3c, 0 3px 8px rgba(0, 0, 0, 0.72); }
    .profile__form_v1 .profile-details {
      padding: 35px 42px 42px 47px; }
      .profile__form_v1 .profile-details:after {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
      .profile__form_v1 .profile-details .profile-details__input {
        padding: 0;
        width: auto;
        margin-right: 11px; }
        .profile__form_v1 .profile-details .profile-details__input:last-child {
          margin-right: 0; }
      .profile__form_v1 .profile-details .profile-details__title {
        margin-bottom: 38px; }
      .profile__form_v1 .profile-details .input__inner {
        width: 229px;
        height: 53px;
        padding: 10px 26px; }
    .profile__form_v1 .profile-contacts {
      padding: 39px 39px 35px; }
      .profile__form_v1 .profile-contacts:after {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
      .profile__form_v1 .profile-contacts .input__inner {
        height: 53px; }
      .profile__form_v1 .profile-contacts .profile-contacts__button {
        width: 121px;
        height: 37px;
        bottom: 8px;
        padding: 0 10px; }
        .profile__form_v1 .profile-contacts .profile-contacts__button_green {
          background-image: linear-gradient(180deg, #4edb80 0%, #307c0b 100%); }
    .profile__form_v1 .profile-socials {
      padding: 35px 42px 32px; }
      .profile__form_v1 .profile-socials:after {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
      .profile__form_v1 .profile-socials .profile-socials__title {
        margin-bottom: 8px; }
      .profile__form_v1 .profile-socials .profile-socials__note {
        text-align: left;
        color: #fff;
        font-size: 16px;
        line-height: 24px; }
      .profile__form_v1 .profile-socials .profile-socials__action {
        margin-top: 33px;
        display: inline-block;
        vertical-align: top; }
    .profile__form_v1 .profile-notify {
      padding: 31px 43px 35px; }
      .profile__form_v1 .profile-notify:after {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
      .profile__form_v1 .profile-notify .profile-notify__title {
        margin-bottom: 23px; }
      .profile__form_v1 .profile-notify .profile-notify__checkbox {
        display: block;
        margin-bottom: 13px; }
        .profile__form_v1 .profile-notify .profile-notify__checkbox:last-child {
          margin-bottom: 0; }
    .profile__form_v1 .profile__action {
      padding: 35px 29px 32px 38px;
      text-align: center; }
    .profile__form_v1 .profile__button {
      padding: 14px 30px;
      float: none;
      font-size: 20px; }
  .profile__password {
    display: block;
    position: relative; }
  .profile__info {
    display: block;
    position: relative; }
  .profile__details {
    display: block;
    position: relative;
    padding: 0;
    border: none; }
  .profile__action {
    padding: 41px 31px 18px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .profile__action {
        padding: 41px 20px 18px; } }
    @media only screen and (max-width: 767px) {
      .profile__action {
        padding: 26px 20px 40px;
        text-align: center; } }
    .profile__action:after {
      content: '';
      display: table;
      clear: both; }
  .profile__button {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    padding: 10px 12px;
    font-family: 'ClearSans',sans-serif;
    font-size: 16px;
    line-height: 18px;
    float: left; }
    @media only screen and (max-width: 767px) {
      .profile__button {
        float: none;
        margin: 0 auto;
        display: block; } }
    .profile__button_submit {
      float: right;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
      @media only screen and (max-width: 767px) {
        .profile__button_submit {
          float: none;
          margin: 15px auto 0;
          display: block;
          width: 100%; } }
  .profile__panel {
    display: block;
    position: relative; }
    .profile__panel:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }

.profile-info {
  display: block;
  position: relative;
  text-align: center; }
  .profile-info__title {
    display: block;
    position: relative;
    padding: 43px 10px 21px 19px; }
    @media only screen and (max-width: 767px) {
      .profile-info__title {
        padding: 27px 74px 21px 84px;
        text-align: left; } }
  .profile-info__caption {
    display: block;
    position: relative;
    font-size: 16px;
    text-align: center;
    padding: 0 10px 0 21px; }
    @media only screen and (max-width: 767px) {
      .profile-info__caption {
        display: none; } }
  .profile-info__status {
    display: inline-block;
    padding-left: 10px;
    padding-top: 30px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .profile-info__status {
        display: block;
        padding-left: 20px;
        padding-top: 24px;
        position: absolute;
        top: 0; } }
    .profile-info__status .status img {
      width: 90px;
      margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .profile-info__status .status .status__note {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .profile-info__status .status {
        width: 50px;
        height: 54px; }
        .profile-info__status .status img {
          display: block;
          width: 30px;
          height: 30px;
          margin: 0 auto; }
      .profile-info__status .status__icon {
        min-width: 30px;
        top: 2px; }
      .profile-info__status .status__note {
        font-size: 11px; } }
  .profile-info__rating {
    display: block;
    position: relative;
    padding-bottom: 31px; }
    .profile-info__rating .rating__stars {
      width: auto; }
    .profile-info__rating:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg, #132e4d, #52baff 50%, #132e4d);
      box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-info__bonus {
    display: block;
    position: relative;
    padding-bottom: 31px;
    padding-top: 32px; }
    .profile-info__bonus:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-info__action {
    display: block;
    position: relative;
    padding: 41px 20px 41px 28px; }
    @media only screen and (max-width: 767px) {
      .profile-info__action {
        position: absolute;
        top: 24px;
        right: 20px;
        padding: 0; } }
  .profile-info__button {
    font-family: "ClearSans", sans-serif;
    background-image: linear-gradient(140deg, #ff8d00 0%, #e2d533 100%);
    text-shadow: 1px 1px 4px #3a0404;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    font-size: 20px;
    padding: 10px 15px; }
    @media only screen and (max-width: 767px) {
      .profile-info__button {
        width: 45px;
        height: 45px;
        font-size: 0; } }
  .profile-info__icon {
    display: none; }
    @media only screen and (max-width: 767px) {
      .profile-info__icon {
        display: block;
        position: absolute;
        top: 14px;
        right: 9px;
        pointer-events: none; } }

.profile-details {
  padding: 41px 31px 18px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .profile-details {
      padding: 41px 20px 18px; } }
  @media only screen and (max-width: 767px) {
    .profile-details {
      padding: 20px 19px 18px; } }
  .profile-details:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-details__title {
    display: block;
    margin-bottom: 34px; }
    .profile-details__title_left {
      text-align: left; }
  .profile-details__action {
    margin: -8px;
    font-size: 0; }
  .profile-details__input {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 8px; }
    @media only screen and (max-width: 767px) {
      .profile-details__input {
        width: 100%; } }
    .profile-details__input .input__inner {
      width: 100%;
      height: 42px;
      padding: 10px 17px 14px;
      background-color: #091420;
      color: #fff;
      font-size: 16px;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.13), 0 1px 0 #091420; }

.profile-contacts {
  display: block;
  position: relative;
  padding: 41px 31px 18px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .profile-contacts {
      padding: 41px 20px 18px; } }
  .profile-contacts:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(90deg, #132e4d, #52baff 50%, #132e4d);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-contacts__title {
    display: block;
    margin-bottom: 29px; }
  .profile-contacts__action {
    font-size: 0;
    margin: 0 -8px; }
  .profile-contacts__item {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 0 8px; }
    @media only screen and (max-width: 767px) {
      .profile-contacts__item {
        width: 100%; }
        .profile-contacts__item + .profile-contacts__item {
          margin-top: 22px; } }
  .profile-contacts__label {
    display: block;
    position: relative;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    text-transform: uppercase;
    margin-bottom: 14px; }
  .profile-contacts__label-inner {
    font-size: 16px;
    display: inline-block;
    width: 70px;
    line-height: 23px; }
  .profile-contacts__status {
    background-color: #fd2230;
    border-radius: 1px;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 13px 4px;
    display: inline-block;
    vertical-align: top;
    margin-left: 18px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .profile-contacts__status {
        margin-left: 5px; } }
    .profile-contacts__status_confirmed {
      background-color: #6a9500;
      padding: 3px 11px 4px; }
  .profile-contacts__input {
    display: block;
    position: relative; }
    .profile-contacts__input .input__inner {
      width: 100%;
      height: 42px;
      background-color: #091420;
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.13), 0 1px 0 #091420;
      padding: 10px 15px 10px 15px; }
    .profile-contacts__input input[placeholder] {
      text-overflow: ellipsis; }
    .profile-contacts__input input::-moz-placeholder {
      text-overflow: ellipsis; }
    .profile-contacts__input input:-moz-placeholder {
      text-overflow: ellipsis; }
    .profile-contacts__input input:-ms-input-placeholder {
      text-overflow: ellipsis; }
  .profile-contacts .profile-contacts__button + .field-userprofile-email .input__inner, .profile-contacts .profile-contacts__button + .input__inner, .profile-contacts .profile-contacts__button + .field-userprofile-phone .input__inner {
    padding: 10px 105px 10px 15px;
    text-transform: none; }
  .profile-contacts__button {
    width: 93px;
    height: 29px;
    padding: 6px 0;
    background-image: linear-gradient(170deg, #ff8d00 0%, #e2d533 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
    text-shadow: 1px 1px 4px #3a0404;
    font-size: 13px;
    line-height: 17px;
    font-family: "ClearSans", sans-serif;
    position: absolute;
    right: 6px;
    top: 44px;
    z-index: 1; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .profile-contacts__button {
        font-size: 11px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .profile-contacts__button {
        font-size: 11px; } }
    @media only screen and (max-width: 767px) {
      .profile-contacts__button {
        font-size: 10px; } }
  .profile-contacts .field-userprofile-email .input__inner_readonly {
    pointer-events: none; }
  .profile-contacts .profile-contacts__button + .field-userprofile-phone {
    position: relative; }
    .profile-contacts .profile-contacts__button + .field-userprofile-phone .input__inner {
      padding-left: 20px; }
  .profile-contacts .profile-contacts__button + .field-userprofile-phone:before {
    content: '+';
    display: block;
    position: absolute;
    top: 0;
    left: 12px;
    font-size: 14px;
    color: #fff;
    height: 42px;
    line-height: 42px; }
    @media only screen and (max-width: 767px) {
      .profile-contacts .profile-contacts__button + .field-userprofile-phone:before {
        left: 12px; } }
  .profile-contacts__subtitle {
    color: #9e9cbf;
    font-family: 'ClearSans',sans-serif;
    font-size: 13px;
    line-height: 14px; }

.profile-socials {
  display: block;
  position: relative;
  padding: 27px 42px 26px 42px; }
  @media only screen and (max-width: 767px) {
    .profile-socials {
      padding: 27px 18px 26px; } }
  .profile-socials:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(90deg, #132e4d, #52baff 50%, #132e4d);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-socials__title {
    display: block;
    margin-bottom: 15px; }
  .profile-socials__note {
    color: #cccccc;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
    margin: 0; }
  .profile-socials__action {
    margin-top: 27px; }

.profile-notify {
  display: block;
  position: relative;
  padding: 41px 31px 18px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .profile-notify {
      padding: 41px 20px 18px; } }
  @media only screen and (max-width: 767px) {
    .profile-notify {
      padding: 19px; } }
  .profile-notify:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(90deg, #132e4d, #52baff 50%, #132e4d);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-notify__row {
    display: block;
    width: 100%; }
    .profile-notify__row + .profile-notify__row {
      margin-top: 10px; }
    @media only screen and (max-width: 767px) {
      .profile-notify__row {
        display: table-row; } }
  .profile-notify__title {
    display: block;
    margin-bottom: 4px; }
  .profile-notify__action {
    display: block;
    position: relative;
    font-size: 0; }
    @media only screen and (max-width: 767px) {
      .profile-notify__action {
        display: table;
        width: 100%; } }
  .profile-notify__block {
    display: inline-block;
    vertical-align: top;
    margin-right: 56px; }
  .profile-notify__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 13px;
    display: block; }
    .profile-notify__row .profile-notify__label {
      padding-top: 16px; }
    @media only screen and (max-width: 767px) {
      .profile-notify__label {
        display: table-cell;
        vertical-align: top; } }
  .profile-notify__choice {
    display: block; }
    @media only screen and (max-width: 767px) {
      .profile-notify__choice {
        display: table-cell;
        vertical-align: top; }
        .profile-notify__row .profile-notify__choice {
          padding-top: 16px; } }
  .profile-notify__checkbox {
    margin-right: 80px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .profile-notify__checkbox {
        margin-right: 40px; } }
    @media only screen and (max-width: 767px) {
      .profile-notify__checkbox {
        margin-right: 0;
        display: block;
        margin-bottom: 11px; } }
    .profile-notify__checkbox:last-child {
      margin-right: 0; }
    .profile-notify__checkbox .checkbox__label {
      font-size: 15px;
      font-family: "ClearSans", sans-serif;
      font-weight: 500; }

.profile-panel {
  display: block;
  position: relative; }
  .profile-panel__table {
    display: table;
    width: 100%;
    position: relative; }
  .profile-panel__cell {
    display: table-cell;
    position: relative;
    vertical-align: bottom;
    padding-right: 27px;
    width: 1px; }
    .profile-panel__cell_fluid {
      width: 100%;
      padding-right: 0; }
  .profile-panel__head {
    display: block;
    position: relative;
    padding: 30px 42px 24px; }
    .profile-panel__head:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-panel__content {
    display: block;
    vertical-align: middle;
    padding: 21px 38px 32px 42px; }
  .profile-panel__user-panel {
    display: inline-block; }
    .profile-panel__user-panel .user-panel__countpad {
      margin-left: 50px; }
    .profile-panel__user-panel .user-panel__button {
      margin-left: 40px; }
  .profile-panel__caption {
    font-size: 19px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "ClearSans", sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    margin: 0; }
    .profile-panel__caption:nth-child(2) {
      margin-right: 20px; }
    .profile-panel__caption_accent {
      color: #fff000; }
  .profile-panel__title {
    display: block;
    line-height: 24px; }
    .profile-panel__title:nth-child(2) {
      font-size: 24px; }

.profile-password {
  padding: 35px 40px 35px; }
  .profile-password:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.1); }
  .profile-password__title {
    display: block;
    margin-bottom: 29px; }
    .profile-password__title_left {
      text-align: left; }
  .profile-password__action {
    margin: -8px;
    font-size: 0; }
  .profile-password__input {
    display: inline-block;
    vertical-align: top;
    width: 33.3%;
    padding: 8px; }
    .profile-password__input .input__inner {
      width: 100%;
      height: 53px;
      padding: 14px 17px 14px;
      background-color: #141320;
      color: #fff;
      font-size: 16px;
      border-radius: 1px;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.13), 0 1px 0 #222130; }

.popup-panel {
  display: block;
  position: relative;
  box-shadow: inset 0 -3px 0 #21528a, 0 3px 8px rgba(0, 0, 0, 0.19);
  background-color: #21528a;
  margin: 32px 32px 0;
  overflow: hidden;
  min-height: 55px;
  transition: height ease-in-out 0.2s; }
  @media only screen and (max-width: 767px) {
    .popup-panel {
      margin: 24px 20px 0; } }
  .popup-panel__toggle {
    width: 44px;
    height: 55px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    text-align: center;
    padding: 17px 0;
    cursor: pointer; }
    .popup-panel__toggle .icon_popup-panel_toggle {
      transition: transform ease-in-out 0.2s;
      transform: rotate(90deg); }
    .popup-panel__toggle.close .icon_popup-panel_toggle {
      transform: rotate(270deg); }
  .popup-panel__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .popup-panel__head {
    text-align: center; }
  .popup-panel__title {
    text-align: center;
    padding: 18px 40px 7px;
    line-height: 16px;
    text-shadow: 0 2px 1px rgba(9, 32, 58, 0.83);
    color: #fff000;
    font-size: 18px;
    font-weight: 700; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .popup-panel__title {
        font-size: 16px; } }
    @media only screen and (max-width: 767px) {
      .popup-panel__title {
        font-size: 16px;
        line-height: 16px;
        padding: 10px 40px 10px; } }
    .popup-panel__title_inline {
      display: inline-block;
      vertical-align: top;
      padding: 13px 20px; }
  .popup-panel__subtitle {
    display: block;
    color: #fff;
    text-transform: none;
    text-align: center;
    padding: 0 10px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 auto 12px;
    max-width: 632px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .popup-panel__subtitle {
        font-size: 14px; } }
    @media only screen and (max-width: 767px) {
      .popup-panel__subtitle {
        font-size: 12px;
        line-height: 16px;
        padding: 0 20px; } }
  .popup-panel__content {
    text-align: center;
    margin-top: 10px; }
  .popup-panel__row {
    text-align: center; }
  .popup-panel__input {
    height: 42px;
    margin-right: 8px; }
    .popup-panel__input .input__inner {
      width: 192px;
      color: #e5e2f3;
      height: 42px;
      border-radius: 2px;
      box-shadow: 0 1px 1px rgba(43, 42, 70, 0.46), inset 0 1px 1px rgba(0, 0, 0, 0.79);
      background-color: #09203a; }
      @media only screen and (max-width: 767px) {
        .popup-panel__input .input__inner {
          width: 170px; } }
  .popup-panel__button {
    min-width: 70px;
    height: 42px;
    margin-top: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    text-shadow: 0 1px 0 #be2f21; }
    .popup-panel__button_wide {
      min-width: 202px;
      padding: 10px 14px; }
  .popup-panel__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 288px; }
    @media only screen and (max-width: 767px) {
      .popup-panel__bonus-info {
        max-width: 216px; } }
  .popup-panel__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top; }
  .popup-panel__bonus-note {
    display: block;
    vertical-align: top;
    text-align: left;
    color: #cccccc;
    font-size: 11px;
    font-weight: 400;
    padding-left: 30px; }
  .popup-panel__link {
    display: block;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    text-decoration: underline;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
    cursor: pointer; }
    .popup-panel__link_pale {
      color: #375475; }
  .popup-panel__footer > .popup-panel__subtitle {
    font-size: 11px; }

.popup_re-call {
  background-image: linear-gradient(180deg, #152740, #070829);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  z-index: 202; }
  .popup_re-call .popup__close {
    background: transparent;
    box-shadow: none;
    margin-top: 7px; }
  .popup_re-call .popup__head {
    background: #00142c; }
  .popup_re-call .popup__title {
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 15px 15px; }
  .popup_re-call .popup__content {
    font-size: 18px;
    padding: 0 12% 20px; }
    .popup_re-call .popup__content a {
      color: #ffcc00; }
    .popup_re-call .popup__content input {
      background-color: #070929;
      margin: 15px auto;
      padding: 12px;
      color: #a0a5be;
      border: 1px solid #083665; }
      .popup_re-call .popup__content input:focus {
        outline: 0; }
    .popup_re-call .popup__content button {
      background-image: linear-gradient(180deg, #e2d533, #ff8d00);
      box-shadow: 0px 0px 16px 9px #e4cf1757, inset 0 2px 0 0 rgba(247, 204, 69, 0.004);
      width: 232px;
      height: 51px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      display: block;
      margin: 10px auto 10px;
      color: #8e231f;
      font-size: 18px;
      text-transform: uppercase; }
      .popup_re-call .popup__content button:focus {
        outline: 0; }
    .popup_re-call .popup__content > span {
      cursor: pointer;
      text-decoration: underline;
      color: #424952; }
  .popup_re-call #re-call-not-phone-error {
    color: red;
    text-decoration: none; }

.overflow.re-call_overflow {
  z-index: 201; }

.popup_lottery-bonus-popup {
  background-image: linear-gradient(180deg, #152740, #070829);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  width: 660px; }
  @media only screen and (max-width: 767px) {
    .popup_lottery-bonus-popup {
      overflow: auto !important; } }
  .popup_lottery-bonus-popup .popup__close {
    background: transparent;
    box-shadow: none;
    margin-top: 7px; }
  .popup_lottery-bonus-popup .popup__head {
    background: #00142c; }
  .popup_lottery-bonus-popup .popup__title {
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 15px 15px; }
  .popup_lottery-bonus-popup .popup__content {
    font-size: 17px;
    padding: 0 10px;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .popup_lottery-bonus-popup .popup__content {
        display: block; } }
    .popup_lottery-bonus-popup .popup__content img {
      max-width: 100%;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
      @media only screen and (max-width: 767px) {
        .popup_lottery-bonus-popup .popup__content img {
          transform: none;
          position: unset; } }
    .popup_lottery-bonus-popup .popup__content button {
      background-image: linear-gradient(180deg, #e2d533, #ff8d00);
      box-shadow: 0px 0px 16px 9px #e4cf1757, inset 0 2px 0 0 rgba(247, 204, 69, 0.004);
      width: 100%;
      height: 51px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      display: block;
      margin: 10px auto 15px;
      color: #8e231f;
      font-size: 18px;
      text-transform: uppercase; }
      .popup_lottery-bonus-popup .popup__content button:last-child {
        box-shadow: 0 0 13px 6px rgba(66, 118, 195, 0.16), inset 0 0 35px 12px rgba(46, 152, 230, 0.18);
        background: none;
        width: 75%;
        color: #fff;
        border: 1px solid #0c4e64;
        text-shadow: 0 1px 0 #000;
        font-size: 18px; }
      .popup_lottery-bonus-popup .popup__content button:focus {
        outline: 0; }
    .popup_lottery-bonus-popup .popup__content .first__p {
      font-weight: 500;
      color: #61698d;
      font-size: 18px; }
  .popup_lottery-bonus-popup .timer__note {
    color: #7297d3;
    padding-bottom: 0;
    font-size: 11px; }
  .popup_lottery-bonus-popup .timer__row_digit .timer__cell {
    color: #7297d3;
    font-size: 36px; }
  .popup_lottery-bonus-popup .timer__row_caption .timer__cell {
    color: #7297d3;
    font-size: 12px; }

.lottery-bonus-popup__column {
  flex-basis: 50%;
  padding: 20px 15px 20px; }
  @media only screen and (max-width: 767px) {
    .lottery-bonus-popup__column {
      width: 100%; } }
  .lottery-bonus-popup__column > *:first-child {
    margin-top: 0; }
  .lottery-bonus-popup__column h2 {
    font-size: 36px;
    color: #ffcc00;
    font-weight: bold;
    line-height: 1;
    margin: 0; }
  .lottery-bonus-popup__column p {
    text-align: justify;
    margin: 25px auto; }
    .lottery-bonus-popup__column p span {
      color: #ffcc00; }
  .lottery-bonus-popup__column:first-child p {
    color: #61698d;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center; }
  .lottery-bonus-popup__column:first-child h3 {
    color: #ffcc00;
    font-size: 22px;
    line-height: 1; }
    .lottery-bonus-popup__column:first-child h3 span {
      font-size: 34px;
      display: block; }

.payitem {
  width: 190px;
  background-color: #fff;
  background-image: linear-gradient(180deg, #f8f8f8 0%, #d3d3d3 100%);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 8px rgba(0, 0, 0, 0.17), 0 8px 10px rgba(0, 0, 0, 0.16); }
  @media only screen and (max-width: 767px) {
    .payitem {
      width: calc(100%/3 - 8px);
      height: auto;
      margin: 8px 8px 8px 0; } }
  .payitem__img {
    height: 92px;
    text-align: center;
    position: relative;
    display: table;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .payitem__img {
        height: 83px; } }
    .payitem__img_inner {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      text-align: center; }
    @media only screen and (max-width: 767px) {
      .payitem__img img {
        max-width: 100%; } }
    @media only screen and (max-width: 767px) {
      .payitem__img svg {
        width: calc(100% - 20px); } }
    @media only screen and (max-width: 767px) {
      .payitem__img .svg-walletone {
        width: calc(100% - 36px); } }
  .payitem__footer {
    background-color: #d5d5d5;
    box-shadow: inset 0 1px 0 #cbcbcb, 0 -1px 0 #ebebeb;
    padding: 11px 13px 10px 13px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .payitem__footer {
        min-height: 75px;
        padding: 11px 2px 10px 2px; } }
    @media only screen and (max-width: 767px) {
      .payitem__footer {
        padding: 11px 2px 10px 2px;
        min-height: 75px; } }
  .payitem__note {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.68);
    color: #222131;
    font-family: 'ClearSans', sans-serif;
    font-weight: 700;
    line-height: 17px;
    font-size: 15px;
    margin: 0;
    text-align: center; }
    .payitem__note a {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
      color: black; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .payitem__note {
        font-size: 12px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .payitem__note {
        font-size: 12px; } }
    @media only screen and (max-width: 767px) {
      .payitem__note {
        font-size: 12px; } }
    .payitem__note_small {
      font-size: 12px;
      font-weight: 500; }

.pay-tooltip {
  display: inline-block;
  position: relative;
  background-color: #fff;
  border-radius: 3px;
  padding: 18px 15px;
  width: 100%;
  text-align: left;
  color: #4e4e4e;
  font-size: 16px; }
  @media only screen and (max-width: 767px) {
    .pay-tooltip {
      padding: 18px 12px;
      min-height: 100px; } }
  @media only screen and (max-width: 767px) {
    .pay-tooltip_withphone {
      min-height: 155px; } }
  .pay-tooltip:after {
    content: " ";
    position: absolute;
    bottom: 99%;
    /* At the top of the tooltip */
    left: 50%;
    margin-left: -24px;
    border-width: 24px;
    border-style: solid;
    border-color: transparent transparent #fff transparent; }
  .pay-tooltip.left:after {
    left: 16%; }
  .pay-tooltip.right:after {
    left: 84%; }
  .pay-tooltip__input {
    display: inline-block;
    vertical-align: middle;
    position: relative; }
    .pay-tooltip__input__second .pay-tooltip__caption {
      min-width: 132px;
      display: inline-block !important; }
    .pay-tooltip__input__second .input__inner {
      width: 102px;
      height: 36px;
      background-color: #ffffff;
      border-radius: 2px;
      border: 1px solid #bababa;
      color: #4a4a4a;
      font-size: 16px;
      font-weight: bold;
      box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
      padding: 9px 18px 8px 12px;
      margin-left: 14px; }
      #withdraw-tab .pay-tooltip__input__second .input__inner {
        width: 130px; }
        @media only screen and (max-width: 767px) {
          #withdraw-tab .pay-tooltip__input__second .input__inner {
            width: 120px; } }
    .pay-tooltip__input.pay-tooltip__with7:before {
      content: '7';
      position: absolute;
      left: 34px;
      top: 8px; }
      @media only screen and (max-width: 767px) {
        .pay-tooltip__input.pay-tooltip__with7:before {
          left: 24px; } }
      #withdraw-tab .pay-tooltip__input.pay-tooltip__with7:before {
        left: 25px;
        top: 7px;
        font-size: 16px; }
    .pay-tooltip__input.pay-tooltip__with7 .input__inner {
      padding-left: 21px; }
    .pay-tooltip__input.pay-tooltip__with-plus:before {
      content: '+';
      position: absolute;
      left: 34px;
      top: 8px; }
      @media only screen and (max-width: 767px) {
        .pay-tooltip__input.pay-tooltip__with-plus:before {
          left: 24px;
          top: 7px; } }
      #withdraw-tab .pay-tooltip__input.pay-tooltip__with-plus:before {
        left: 25px;
        top: 7px;
        font-size: 16px; }
    .pay-tooltip__input.pay-tooltip__with-plus .input__inner {
      padding-left: 21px; }
    .pay-tooltip__input .input__inner {
      width: 102px;
      height: 36px;
      background-color: #ffffff;
      border-radius: 2px;
      border: 1px solid #bababa;
      color: #4a4a4a;
      font-size: 16px;
      font-weight: bold;
      box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
      padding: 9px 18px 8px 12px;
      margin-left: 14px; }
      #withdraw-tab .pay-tooltip__input .input__inner {
        width: 130px; }
        @media only screen and (max-width: 767px) {
          #withdraw-tab .pay-tooltip__input .input__inner {
            width: 120px; } }
  .pay-tooltip__summ {
    color: #4a4a4a;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle; }
    @media only screen and (max-width: 767px) {
      .pay-tooltip__summ {
        display: block; } }
    .pay-tooltip__summ__second {
      margin-top: 10px;
      display: inline; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .pay-tooltip__summ__second {
          display: block; } }
      @media only screen and (max-width: 767px) {
        .pay-tooltip__summ__second {
          display: block; } }
    @media only screen and (max-width: 767px) {
      .pay-tooltip__summ .pay-tooltip__input {
        display: block;
        margin: -24px 0 0 6px; } }
    .pay-tooltip__summ .pay-tooltip__input:before {
      color: transparent; }
    .pay-tooltip__summ input[type="radio"] {
      vertical-align: middle;
      margin: 0; }
    @media only screen and (max-width: 767px) {
      .pay-tooltip__summ input.l_num {
        display: block;
        margin-top: 20px; } }
    .pay-tooltip__summ label {
      display: inline-block;
      padding-right: 13px;
      font-weight: bold;
      cursor: pointer; }
      .pay-tooltip__summ label:first-child {
        padding-left: 12px; }
      @media only screen and (max-width: 767px) {
        .pay-tooltip__summ label {
          padding-right: 9px; } }
  .pay-tooltip__phone {
    padding: 0 0 20px; }
  .pay-tooltip__phone_inner {
    width: 170px;
    height: 36px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 2px;
    border: 1px solid #bababa;
    color: #4a4a4a;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    padding: 9px 18px 8px 30px;
    margin: 0 0 0 12px;
    vertical-align: middle;
    display: inline-block; }
    @media only screen and (max-width: 767px) {
      .pay-tooltip__phone_inner {
        width: 130px;
        margin-left: 4px; } }
  .pay-tooltip__number {
    padding: 0 0 10px;
    position: relative;
    display: block; }
  .pay-tooltip__number_inner {
    width: 170px;
    height: 36px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 2px;
    border: 1px solid #bababa;
    color: #4a4a4a;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    padding: 9px 18px 8px 12px;
    margin: 0 0 0 12px;
    vertical-align: middle;
    display: inline-block; }
    #withdraw-tab .pay-tooltip__input .pay-tooltip__number_inner-noprefix {
      margin-left: 12px; }
    @media only screen and (max-width: 767px) {
      .pay-tooltip__number_inner {
        width: 130px;
        margin-left: 4px;
        padding: 9px 10px 8px 10px; } }
  .pay-tooltip__button {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 9px 15px 8px;
    border-radius: 2px;
    background-image: linear-gradient(to bottom, #ff8d00, #e2d533);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16), 0 8px 6px 0 rgba(0, 0, 0, 0.16);
    margin-left: 14px;
    margin-top: -2px; }
    @media only screen and (max-width: 767px) {
      .pay-tooltip__button {
        display: block;
        position: absolute;
        right: 16px;
        bottom: 17px;
        padding: 10px 5px 10px;
        font-size: 14px; } }
    @media only screen and (max-width: 767px) {
      .pay-tooltip__button_withdraw {
        display: block;
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: 10px; } }
  .pay-tooltip__note {
    color: #f00;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    border-radius: 3px;
    background-color: #ffecec;
    border: solid 1px #ff9797;
    text-align: left;
    padding: 8px 14px 8px 14px;
    margin-bottom: 14px; }
    .pay-tooltip__note* {
      color: #f00; }
  .pay-tooltip__caption {
    min-width: 132px;
    display: inline-block; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .pay-tooltip__card-date {
      margin-left: 2px !important; } }

.pay-tooltip_error .pay-tooltip__input {
  display: block;
  margin: 0; }
  .pay-tooltip_error .pay-tooltip__input .input__inner {
    width: 100%; }

.pay-tooltip_error .pay-tooltip__button {
  display: block;
  margin: 11px auto 0;
  padding: 13px 23px 12px; }

.history {
  display: block;
  position: relative;
  max-height: 440px; }
  .history__filter {
    margin-top: 9px; }
  .history__table {
    display: table;
    width: 600px;
    max-height: 440px;
    margin: 0 auto;
    border-collapse: collapse; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .history__table {
        width: 400px; } }
    @media only screen and (max-width: 767px) {
      .history__table {
        width: calc(100% - 40px); } }
  .history__body {
    max-height: 300px; }
  .history__cell {
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 13px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 20px 0;
    border-bottom: 1px solid #232232; }
    @media only screen and (max-width: 767px) {
      .history__cell {
        padding: 20px 4px; } }
    .history__head .history__cell {
      font-size: 19px;
      padding-bottom: 32px; }
    .history__cell:first-child {
      text-align: left; }
    .history__cell:last-child {
      text-align: right; }

.filter {
  display: block;
  position: relative;
  text-align: left;
  margin: 0 auto;
  height: 58px;
  padding: 11px 11px 12px 11px;
  background-color: #4d4b63;
  border-radius: 1px;
  font-size: 0; }
  .history__filter .filter {
    width: 600px; }
  .filter__item {
    display: inline-block;
    vertical-align: top;
    background: #6d6c84; }
    .filter__item:first-child {
      width: 80px;
      margin-right: 9px; }
    .filter__title + .filter__item:first-child {
      margin-right: 0; }
    .filter__item + .filter__item:nth-child(2n + 1) {
      margin-right: 9px; }
    .filter__item + .filter__item:nth-child(2) {
      width: 91px;
      margin-right: 30px; }
  .filter__button {
    display: block;
    position: absolute;
    top: 11px;
    right: 11px;
    padding: 7px 14px;
    border-radius: 1px;
    font-family: 'ClearSans', sans-serif;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.18), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .filter__button_fortune {
      padding: 9px 14px;
      font-size: 15px;
      font-family: 'ClearSans', sans-serif; }
  .filter__select {
    border-radius: 0;
    background: #6d6c84;
    height: 34px;
    padding: 8px 30px 8px 15px;
    border: none;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%; }
    .filter__select option[disabled] {
      display: none; }
  .filter__date {
    display: block;
    position: relative; }
    .filter__date input[placeholder] {
      color: #fff; }
    .filter__date input::-moz-placeholder {
      color: #fff; }
    .filter__date input:-moz-placeholder {
      color: #fff; }
    .filter__date input:-ms-input-placeholder {
      color: #fff; }
  .filter__date-inner {
    display: block;
    position: relative;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    width: 91px;
    background-color: #6d6c84;
    border-radius: 1px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
    border: none;
    padding: 9px 0;
    text-align: center; }
  .filter__title {
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    padding: 9px 12px 6px; }

.bonus-panel {
  position: relative;
  display: table;
  font-size: 0;
  width: 100%;
  min-height: 240px;
  background-color: #09203a;
  border-radius: 1px;
  box-shadow: inset 0 -3px 0 #09203a, 0 3px 8px rgba(0, 0, 0, 0.19); }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .bonus-panel {
      min-height: 255px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .bonus-panel {
      min-height: 255px; } }
  @media only screen and (max-width: 767px) {
    .bonus-panel {
      padding: 20px 14px; } }
  .bonus-panel__view {
    display: table-cell;
    vertical-align: middle;
    padding-left: 27px;
    position: relative;
    width: 24%; }
    @media only screen and (min-width: 1440px) {
      .bonus-panel__view {
        width: 20%;
        text-align: center; } }
    @media only screen and (max-width: 767px) {
      .bonus-panel__view {
        display: block;
        padding-left: 0;
        text-align: center;
        width: auto; } }
  .bonus-panel__img {
    width: 180px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .bonus-panel__img {
        width: 140px; } }
  .bonus-panel__info {
    display: table-cell;
    vertical-align: top;
    padding-left: 20px;
    padding-bottom: 46px;
    padding-top: 20px; }
    @media only screen and (max-width: 767px) {
      .bonus-panel__info {
        display: block;
        padding-left: 0;
        padding-bottom: 0; } }
  .bonus-panel__action {
    display: table-cell;
    text-align: center;
    position: relative;
    width: 30%;
    min-width: 300px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .bonus-panel__action {
        width: 214px;
        min-width: inherit; } }
    @media only screen and (max-width: 767px) {
      .bonus-panel__action {
        display: block;
        width: 100%;
        min-width: inherit; } }
    .bonus-panel__action:before {
      content: '';
      display: block;
      position: absolute;
      top: 110px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 314px;
      height: 153px;
      opacity: 0.4;
      background-image: radial-gradient(164px 86px at 50% 50%, #e31933 0%, transparent 80%, transparent 100%); }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .bonus-panel__action:before {
          display: none; } }
      @media only screen and (max-width: 767px) {
        .bonus-panel__action:before {
          top: 73px; } }
  .bonus-panel__timer {
    margin-top: 15px; }
  .bonus-panel__button {
    display: inline-block;
    vertical-align: top;
    margin-top: 33px;
    margin-bottom: 15px; }
    .bonus-panel__button_large {
      padding: 15px 21px;
      line-height: 20px;
      font-size: 18px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38); }
      @media only screen and (max-width: 767px) {
        .bonus-panel__button_large {
          margin-bottom: 80px; } }
  .bonus-panel__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.32);
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    text-align: left;
    margin: 11px 0 0; }
    @media only screen and (max-width: 767px) {
      .bonus-panel__title {
        padding: 20px 0;
        text-align: center; } }
    .bonus-panel__action .bonus-panel__title {
      text-align: center; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .bonus-panel__action .bonus-panel__title {
          white-space: normal;
          margin: 30px 0;
          padding: 0 10px; } }
      @media only screen and (max-width: 767px) {
        .bonus-panel__action .bonus-panel__title {
          margin: 20px 0;
          padding: 0 5px;
          white-space: normal; } }
    .bonus-panel__title_alert {
      font-size: 14px;
      color: #ff1632;
      white-space: nowrap;
      margin: 38px 30px 0;
      text-align: center; }
    .bonus-panel__title_important {
      font-size: 14px;
      color: #ffea00;
      white-space: nowrap;
      margin: 38px 30px 0;
      text-align: center; }
    .bonus-panel__title_important {
      font-size: 14px;
      color: #ffea00;
      white-space: nowrap;
      margin: 38px 30px 0;
      text-align: center; }
  .bonus-panel__note {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.24);
    color: #cacad4;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 22px;
    margin: 14px 0 0 0; }
    @media only screen and (max-width: 767px) {
      .bonus-panel__note {
        margin: 0; } }
  .bonus-panel__tooltip {
    display: block;
    position: absolute;
    width: 180px;
    height: 56px;
    border-radius: 3px;
    background-image: linear-gradient(to bottom, #f36467, #e22624);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8), inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
    font-size: 13px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    padding: 10px;
    text-transform: none;
    bottom: -3px;
    right: 100%;
    margin-right: 20px;
    line-height: 18px; }
    @media only screen and (max-width: 767px) {
      .bonus-panel__tooltip {
        bottom: -78px;
        right: 50%;
        transform: translateX(50%);
        margin-right: 0; } }
  .bonus-panel__arrow {
    display: block;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 100%;
    transform: rotate(90deg);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    @media only screen and (max-width: 767px) {
      .bonus-panel__arrow {
        top: -6px;
        left: calc(50% - 7px); } }
    .bonus-panel__arrow:after {
      content: " ";
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      left: 50%;
      z-index: 3;
      margin-left: -7px;
      border-style: solid;
      border-width: 7px;
      border-color: transparent transparent #EC4849 transparent; }
      @media only screen and (max-width: 767px) {
        .bonus-panel__arrow:after {
          border-color: transparent #f36467 transparent transparent; } }
    .bonus-panel__arrow_right {
      right: 5px;
      left: auto; }
      .bonus-panel__arrow_right:after {
        right: 5px;
        left: auto; }
  .bonus-panel__informer {
    width: 224px;
    height: 56px;
    margin: 20px auto 0;
    border-radius: 3px;
    background-image: linear-gradient(to top, #f2d42a, #f3db53);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 rgba(255, 255, 255, 0.3);
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    color: #140602;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
    padding: 12px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .bonus-panel__informer {
        width: 182px;
        padding: 5px; } }
    .bonus-panel__informer_green {
      width: 147px;
      height: 46px;
      border-radius: 3px;
      background-image: linear-gradient(to top, #89b035, #91b83c);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25), inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 0 1px 1px rgba(8, 23, 4, 0.5);
      padding: 15px; }

.levels-table {
  display: block;
  position: relative;
  margin: 0 auto;
  font-family: 'ClearSans', sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase; }
  .levels-table__table {
    display: table;
    width: 826px;
    margin: 0 auto;
    z-index: 1; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__table {
        width: 594px; } }
    @media only screen and (max-width: 767px) {
      .levels-table__table {
        display: block;
        width: 100%; } }
  .levels-table__slider {
    position: absolute;
    top: 260px;
    left: 50%;
    transform: translateX(-50%);
    width: 860px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__slider {
        top: 208px;
        width: 610px; } }
    @media only screen and (max-width: 767px) {
      .levels-table__slider {
        display: none; } }
  .levels-table__slider-bar {
    width: 100%;
    height: 17px;
    border: 1px solid #457ea5;
    border-radius: 3px; }
  .levels-table__slider-inner {
    width: 30%;
    position: absolute;
    top: -1px;
    left: 0;
    height: 17px;
    background-color: #388ad6;
    background-image: linear-gradient(90deg, rgba(87, 185, 220, 0.29), #35d0ff 50%, rgba(87, 185, 220, 0.29));
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
  .levels-table__info {
    display: none;
    position: relative;
    height: 252px;
    border-radius: 3px;
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.4), 0 3px 8px rgba(0, 0, 0, 0.19);
    background-color: #09203a; }
    .levels-table__info.active {
      min-height: 258px;
      display: table !important;
      width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__info {
        height: 280px; } }
    @media only screen and (max-width: 767px) {
      .levels-table__info {
        display: block;
        height: auto; }
        .levels-table__info:after {
          content: " ";
          position: absolute;
          bottom: 100%;
          /* At the top of the tooltip */
          left: 50%;
          margin-left: -7px;
          border-width: 7px;
          border-style: solid;
          border-color: transparent transparent #09203a transparent; } }
  .levels-table__status {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 234px;
    text-align: center; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__status {
        width: 180px; } }
    @media only screen and (max-width: 767px) {
      .levels-table__status {
        display: block;
        position: absolute;
        top: 24px;
        left: 20px;
        text-align: left; } }
  .levels-table__status-inner {
    display: inline-block;
    vertical-align: top; }
    @media only screen and (max-width: 767px) {
      .levels-table__status-inner {
        width: 48px;
        height: 45px; }
        .levels-table__status-inner .status__icon {
          width: 30px;
          top: 1px; }
        .levels-table__status-inner .status__note {
          font-size: 11px;
          bottom: 3px; } }
  .levels-table__item {
    display: table-cell;
    position: relative;
    width: calc(100% / 6);
    vertical-align: bottom;
    text-align: center;
    padding: 62px 10px 0;
    cursor: pointer; }
    .levels-table__item:focus {
      outline: none; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__item {
        padding: 62px 9px 0; } }
    @media only screen and (max-width: 767px) {
      .levels-table__item {
        display: block;
        width: 100%;
        padding: 87px 30px 30px;
        min-width: 250px; }
        .levels-table__item:before {
          content: '';
          display: block;
          position: absolute;
          top: 60px;
          left: 0;
          width: 1px;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.14); } }
    .levels-table__item:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 92%;
      background-image: linear-gradient(180deg, #132e4d, #52baff 50%, #132e4d); }
      @media only screen and (max-width: 767px) {
        .levels-table__item:after {
          height: 100%;
          top: 60px;
          background-color: rgba(0, 0, 0, 0.14);
          background-image: none; } }
    .levels-table__item:first-child:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 92%;
      background-image: linear-gradient(180deg, #132e4d, #52baff 50%, #132e4d); }
      @media only screen and (max-width: 767px) {
        .levels-table__item:first-child:before {
          height: 100%;
          top: 54px;
          background-color: rgba(0, 0, 0, 0.14);
          background-image: none; } }
  .levels-table__icon {
    display: block;
    position: relative;
    margin: 31px auto 0;
    width: 92px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__icon {
        width: 30px; } }
    @media only screen and (max-width: 767px) {
      .levels-table__icon {
        float: right;
        margin: 0;
        width: 30px; } }
  .levels-table__arrow {
    display: block;
    position: relative;
    margin-top: 30px;
    opacity: 0; }
    @media only screen and (max-width: 767px) {
      .levels-table__arrow {
        display: none; } }
    .levels-table__arrow_active {
      opacity: 1; }
    .levels-table__arrow:after {
      content: " ";
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      left: 50%;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent #09203a transparent; }
  .levels-table__title {
    font-size: 20px;
    line-height: 22px;
    margin: 0; }
    @media only screen and (max-width: 767px) {
      .levels-table__title {
        padding-left: 65px; } }
    .levels-table__title_small {
      font-size: 18px;
      line-height: 20px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .levels-table__title_small {
          font-size: 15px; } }
      @media only screen and (max-width: 767px) {
        .levels-table__title_small {
          padding-top: 2px;
          float: left;
          padding-left: 0; } }
    .levels-table__title_accent {
      color: #ffde00; }
      @media only screen and (max-width: 767px) {
        .levels-table__title_accent {
          min-height: 50px; } }
  .levels-table__caption {
    font-size: 14px;
    line-height: 18px;
    color: #9c9c9c;
    white-space: nowrap;
    margin-top: 104px;
    display: block; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__caption {
        white-space: normal; } }
    @media only screen and (max-width: 767px) {
      .levels-table__caption {
        margin-top: 30px;
        display: block;
        float: left;
        clear: left; } }
  .levels-table__note {
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    margin: 16px 0 0 0; }
  .levels-table__summary {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    padding-right: 37px;
    padding-top: 25px;
    padding-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .levels-table__summary {
        padding: 20px;
        display: block; } }
  .levels-table__ratenote {
    font-size: 15px;
    padding: 18px 0 6px;
    line-height: 20px;
    color: #fff;
    white-space: nowrap;
    display: block;
    position: absolute;
    top: 205px;
    right: 0;
    z-index: 2; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .levels-table__ratenote {
        top: 158px; } }
    @media only screen and (max-width: 767px) {
      .levels-table__ratenote {
        display: none; } }
    .levels-table__ratenote_zero {
      right: auto;
      left: 8px; }
  .levels-table__link {
    font-weight: 400;
    text-decoration: none;
    color: #858585;
    display: block;
    margin-top: 14px; }
    @media only screen and (max-width: 767px) {
      .levels-table__link {
        display: none; } }
  .levels-table__viewrate {
    display: block;
    margin: 16px auto 0;
    width: 70px;
    height: 31px;
    padding: 6px 0;
    text-align: center;
    background-color: #09203a;
    background-image: linear-gradient(180deg, #09233f, #0d2f57);
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    @media only screen and (max-width: 767px) {
      .levels-table__viewrate {
        margin: 22px auto 0;
        float: right;
        clear: right; } }
  .levels-table__ratestat {
    display: none;
    width: auto;
    text-align: left;
    clear: both; }
    @media only screen and (max-width: 767px) {
      .levels-table__ratestat {
        display: block; } }

.levels-table__item_active .levels-table__caption, .slick-current .levels-table__caption {
  color: #fff; }

.levels-table__item_active .levels-table__viewrate, .slick-current .levels-table__viewrate {
  background-color: #3687d6;
  background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%); }

.levels-table__item_active .levels-table__link, .slick-current .levels-table__link {
  color: #fff; }

.promo-panel {
  display: table;
  position: relative;
  width: 100%; }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .promo-panel {
      height: 306px;
      box-shadow: 0 11px 11px rgba(0, 0, 0, 0.11);
      border: 1px solid #506885;
      border-radius: 2px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .promo-panel {
      box-shadow: 0 11px 11px rgba(0, 0, 0, 0.11);
      border: 1px solid #506885;
      border-radius: 2px; } }
  @media only screen and (max-width: 767px) {
    .promo-panel {
      max-width: 268px;
      margin: 0 auto; } }
  .promo-panel__cell {
    display: table-cell;
    position: relative;
    vertical-align: top;
    font-family: "ClearSans", sans-serif; }
    @media only screen and (max-width: 767px) {
      .promo-panel__cell {
        display: block;
        width: 100%; } }
    .promo-panel__cell:first-child {
      padding-right: 11px;
      padding-left: 11px;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 0 8px rgba(0, 0, 0, 0.52);
      text-align: center; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .promo-panel__cell:first-child {
          background-color: #29476a;
          padding-right: 0;
          box-shadow: none; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .promo-panel__cell:first-child {
          background-color: #29476a;
          padding-right: 0;
          box-shadow: none; } }
      @media only screen and (max-width: 767px) {
        .promo-panel__cell:first-child {
          background-color: rgba(18, 45, 76, 0.82); } }
    .promo-panel__cell_info {
      padding: 30px 22px 0 25px;
      background-color: #29476a;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12); }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .promo-panel__cell_info {
          box-shadow: none; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .promo-panel__cell_info {
          box-shadow: none; } }
      @media only screen and (max-width: 767px) {
        .promo-panel__cell_info {
          padding: 30px 22px 20px 25px; } }
  .promo-panel__img {
    display: block;
    height: auto;
    max-height: 300px;
    max-width: 181px;
    margin: 17px auto; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .promo-panel__img {
        max-height: 178px;
        max-width: 155px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .promo-panel__img {
        max-height: 178px;
        max-width: 155px; } }
    @media only screen and (max-width: 767px) {
      .promo-panel__img {
        max-height: 268px;
        max-width: 100%;
        margin: 0 auto; } }
  .promo-panel__title {
    margin: 0; }
  .promo-panel__caption {
    margin: 10px 0 0;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 16px;
    line-height: 22px; }
  .promo-panel__button {
    display: block;
    float: left;
    position: relative;
    margin-top: 30px;
    padding: 11px 25px;
    white-space: nowrap;
    border-radius: 23px;
    text-shadow: 0 1px 0 rgba(4, 4, 4, 0.26);
    font-size: 20px;
    font-weight: 700; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .promo-panel__button {
        float: none; } }
    @media only screen and (max-width: 767px) {
      .promo-panel__button {
        float: none;
        margin: 30px auto 0; } }
  .promo-panel__link {
    display: block;
    float: right;
    text-align: right;
    text-decoration: none;
    margin: 48px 0 0 0;
    color: #00a6ff;
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase; }
    .promo-panel__link:hover {
      text-decoration: underline; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .promo-panel__link {
        margin: 26px 0 0; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .promo-panel__link {
        float: none;
        margin: 19px auto 20px;
        text-align: left; } }
    @media only screen and (max-width: 767px) {
      .promo-panel__link {
        float: none;
        margin: 20px auto 20px;
        text-align: center; } }
    .promo-panel__link .icon {
      margin-left: 8px;
      margin-right: 6px;
      margin-top: 2px; }

.promo-bonus {
  display: block;
  position: relative;
  text-align: center;
  background-color: #2f527b;
  border-radius: 1px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.13), inset 0 0 0 #1b3a5e, 0 -1px 3px rgba(0, 0, 0, 0.19); }
  .promo-bonus__title {
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    padding: 25px 23px 0;
    margin: 0;
    display: block; }
  .promo-bonus__img {
    display: block;
    position: relative;
    margin-top: 8px;
    padding: 10px 0; }
    .promo-bonus__img img {
      height: 200px; }

.promo-details {
  display: block;
  position: relative;
  color: #ffffff; }
  .promo-details__gift-panel {
    margin-bottom: 36px; }
  .promo-details__dropdown {
    display: block; }
    @media only screen and (max-width: 767px) {
      .promo-details__dropdown {
        display: none; } }
  .promo-details__divider {
    width: 100%;
    height: 1px;
    background-color: #0e2035;
    box-shadow: 0 1px 0 #1d3b5e;
    margin: 21px 0 22px; }
  .promo-details__title {
    padding-left: 3px;
    position: relative;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
    font-family: 'ClearSans',sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0 21px 0; }
  .promo-details h1, .promo-details h2, .promo-details h3, .promo-details h4, .promo-details h5 {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
    font-family: 'ClearSans',sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0 21px 0; }
    .promo-details h1:first-child, .promo-details h2:first-child, .promo-details h3:first-child, .promo-details h4:first-child, .promo-details h5:first-child {
      border-bottom: #0e2035;
      box-shadow: 0 0 1px 0 #1d3b5e; }
  .promo-details p {
    margin: 0 0 14px 0;
    font-size: 16px;
    font-family: 'ClearSans',sans-serif;
    line-height: 18px; }
  .promo-details__bullet-list.bullet-list, .promo-details ul {
    margin: 21px 0 38px 0; }
    .promo-details__bullet-list.bullet-list li, .promo-details ul li {
      position: relative;
      color: #fff;
      font-weight: 400;
      padding-left: 28px;
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.29);
      font-family: 'ClearSans', sans-serif;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 18px;
      list-style-type: none; }
      .promo-details__bullet-list.bullet-list li:before, .promo-details ul li:before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        left: 0;
        top: 4px;
        width: 12px;
        height: 12px;
        background-color: #fda31b;
        background-image: linear-gradient(170deg, #fda31b, #fd0834);
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 1.4px 2.7px 1px rgba(0, 0, 0, 0.18); }
  .promo-details__content {
    font-size: 16px;
    color: #fff; }
  .promo-details__button {
    display: none;
    position: absolute;
    right: 0;
    top: 1px;
    width: auto;
    background-color: #185e9c;
    font-size: 12px;
    line-height: 20px;
    text-shadow: none;
    text-align: center;
    padding: 0 15px;
    font-family: 'ClearSans',sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    height: 21px;
    border-radius: 1px;
    background-image: linear-gradient(to bottom, #2b85c9, #193a62);
    box-shadow: 0 -1px 0 0 #79c6ff, 0 2px 0 0 #152f50, inset 0 1px 7px 0 rgba(255, 255, 255, 0.3); }
    @media only screen and (max-width: 767px) {
      .promo-details__button {
        display: block; } }

.gift-panel {
  display: block;
  position: relative;
  padding-bottom: 26px; }
  .gift-panel__content {
    display: table;
    width: 100%;
    height: 206px;
    background-color: #29476a;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12); }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gift-panel__content {
        height: 234px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gift-panel__content {
        height: 270px; } }
  .gift-panel__cell {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    padding: 23px 20px 16px 10px; }
    @media only screen and (max-width: 767px) {
      .gift-panel__cell {
        display: block;
        padding: 20px; } }
    .gift-panel__cell_img {
      width: 1px;
      padding: 23px 16px 16px 23px; }
      @media only screen and (max-width: 767px) {
        .gift-panel__cell_img {
          width: auto;
          text-align: center;
          padding: 20px; } }
  .gift-panel__img {
    width: 175px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gift-panel__img {
        width: 116px; } }
  .gift-panel__caption {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    line-height: 22px; }
    @media only screen and (min-width: 1440px) {
      .gift-panel__caption {
        padding-right: 135px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gift-panel__caption {
        padding-right: 35px; } }
  .gift-panel__footer {
    height: 47px;
    margin-top: -1px;
    background-color: #243f5e;
    border: 1px solid #2e4d72; }
  .gift-panel__button {
    position: absolute;
    bottom: 6px;
    padding: 11px 25px;
    left: 50%;
    white-space: nowrap;
    border-radius: 23px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-shadow: 0 1px 0 rgba(4, 4, 4, 0.26);
    font-size: 20px;
    font-weight: 700; }

.bullet-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .bullet-list__item {
    position: relative;
    color: #fff;
    font-weight: 400;
    padding-left: 28px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.29);
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 18px; }
    .bullet-list__item:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 4px;
      width: 12px;
      height: 12px;
      background-color: #fda31b;
      background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 1.4px 2.7px 1px rgba(0, 0, 0, 0.18); }

.panel {
  display: table;
  position: relative;
  width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .panel {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .panel {
      display: block; } }
  .panel__cell {
    display: table-cell;
    position: relative;
    vertical-align: middle; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__cell {
        display: inline-block;
        position: static; } }
    @media only screen and (max-width: 767px) {
      .panel__cell {
        display: inline-block;
        width: 100%;
        position: static; } }
    .panel__cell_img {
      text-align: center;
      width: 1px;
      padding-right: 1px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .panel__cell_img {
          width: 260px; } }
      @media only screen and (max-width: 767px) {
        .panel__cell_img {
          width: 100%; } }
      .panel__cell_img .timer__note {
        color: #8aadee;
        font-weight: 100; }
    .panel__cell_content {
      vertical-align: top; }
    .panel__cell_column {
      width: 40%;
      padding: 0 15px;
      vertical-align: top;
      color: #fff; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .panel__cell_column {
          padding: 0 20px; }
          .panel__cell_column:nth-child(2) {
            width: 390px; }
          .panel__cell_column:nth-child(3) {
            width: 100%; } }
      @media only screen and (max-width: 767px) {
        .panel__cell_column {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery__content .panel__cell_column {
          padding: 0; } }
      @media only screen and (max-width: 767px) {
        .lottery__content .panel__cell_column {
          padding: 0; } }
    .panel__cell .button_light {
      margin-top: 20px; }
  .panel__overflow {
    display: block;
    position: absolute;
    height: auto;
    top: 0;
    left: 0;
    right: 1px;
    z-index: 2; }
    @media only screen and (min-width: 1440px) {
      .panel__overflow {
        height: auto; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__overflow {
        height: auto; } }
    @media only screen and (max-width: 767px) {
      .panel__overflow {
        width: auto;
        height: auto; } }
  .panel__img {
    display: block;
    width: 100%;
    max-width: 279px; }
    @media only screen and (min-width: 1440px) {
      .panel__img {
        max-width: 369px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__img {
        max-width: 301px; } }
    @media only screen and (max-width: 767px) {
      .panel__img {
        max-width: 115px; } }
  .panel .img_overflow {
    height: 245px;
    width: 250px;
    overflow: hidden;
    margin: 20px 0 0 20px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel .img_overflow {
        height: initial;
        width: initial; } }
    @media only screen and (max-width: 767px) {
      .panel .img_overflow {
        width: 115px;
        min-height: 100px;
        height: initial; } }
  .panel__summary {
    position: relative;
    font-size: 0;
    height: 289px; }
    @media only screen and (min-width: 1440px) {
      .panel__summary {
        height: 363px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__summary {
        height: 301px; } }
    @media only screen and (max-width: 767px) {
      .panel__summary {
        height: 100%; } }
  .panel__slider {
    position: relative;
    height: 144px;
    text-align: center;
    margin-top: 1px;
    background-color: #29476a;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07), 0 5px 5px rgba(0, 0, 0, 0.12); }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__slider {
        margin-left: 280px;
        height: 133px; } }
    @media only screen and (max-width: 767px) {
      .panel__slider {
        margin-top: 134px;
        z-index: 2; } }
  .panel__slider-inner {
    width: 560px;
    display: inline-block;
    vertical-align: top;
    margin-top: 28px; }
    @media only screen and (min-width: 1440px) {
      .panel__slider-inner {
        width: 954px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__slider-inner {
        width: 770px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__slider-inner {
        width: 324px; } }
    @media only screen and (max-width: 767px) {
      .panel__slider-inner {
        width: 277px; } }
    .panel__slider-inner .slider__item {
      padding: 0 17px; }
  .panel__info {
    width: 300px;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 25px 0 22px; }
    @media only screen and (min-width: 1440px) {
      .panel__info {
        width: 598px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__info {
        width: 470px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__info {
        width: 389px;
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .panel__info {
        width: 100%;
        padding: 0; } }
  .panel__prize {
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: center;
    width: 314px;
    height: 100%;
    padding: 29px 19px 20px 19px; }
    @media only screen and (min-width: 1440px) {
      .panel__prize {
        width: 410px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__prize {
        width: 365px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__prize {
        width: 268px; } }
    @media only screen and (max-width: 767px) {
      .panel__prize {
        width: 100%;
        height: 253px; } }
  .panel__icons {
    display: table;
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 40px; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__icons {
        margin-top: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__icons {
        display: block;
        margin-top: 0px; } }
    @media only screen and (max-width: 767px) {
      .panel__icons {
        display: block;
        margin-top: 20px; } }
    .panel__icons.bonus-name {
      margin-top: 20px; }
      .panel__icons.bonus-name .panel__icon-title {
        font-size: 14px;
        line-height: 18px; }
      @media only screen and (max-width: 767px) {
        .panel__icons.bonus-name img {
          height: 50px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .panel__icons.bonus-name img {
          height: 50px; } }
  .panel__icon-cell {
    vertical-align: top;
    text-align: center;
    display: inline-block;
    width: 32%; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__icon-cell {
        display: inline-block;
        width: 32%; } }
    @media only screen and (max-width: 767px) {
      .panel__icon-cell {
        display: inline-block;
        width: 31%; } }
    .panel__icon-cell img {
      width: 80px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .panel__icon-cell img {
          width: 60px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .panel__icon-cell img {
          width: 50px; } }
      @media only screen and (max-width: 767px) {
        .panel__icon-cell img {
          width: 50px; } }
  .panel__icon {
    display: block;
    margin: 0 auto;
    padding: 26px 0 10px;
    text-shadow: 0 1px 0 #fff;
    color: #1f2934;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    background-image: url(../img/sprite.png?v=2);
    background-position: -899px -258px;
    width: 51px;
    height: 66px; }
    .panel__icon-cell:last-child .panel__icon {
      background-image: url(../img/sprite.png?v=2);
      background-position: -899px -66px;
      width: 51px;
      height: 66px; }
    .panel__icon-cell:nth-child(2) .panel__icon {
      background-image: url(../img/sprite.png?v=2);
      background-position: -899px -162px;
      width: 51px;
      height: 66px; }
  .panel__icon-title {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.18);
    color: #fefefe;
    font-family: 'ClearSans', sans-serif;
    font-weight: 500;
    font-size: 14px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__icon-title {
        display: block;
        vertical-align: top;
        line-height: 30px; } }
  .panel__header {
    display: block;
    position: relative;
    min-height: 54px;
    padding: 0 25px 16px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__header {
        min-height: 88px;
        padding-left: 108px;
        padding-top: 25px; } }
    @media only screen and (max-width: 767px) {
      .panel__header {
        min-height: 64px;
        padding: 0 15px 0 76px;
        display: table;
        vertical-align: middle;
        height: 64px;
        width: 100%; } }
    .panel__header_noshadow {
      padding-bottom: 0; }
      .panel__header_noshadow:after {
        content: none; }
  .panel__timer {
    height: 114px;
    padding: 15px 0;
    margin-top: 5px;
    margin-left: 20px; }
    @media only screen and (min-width: 1440px) {
      .panel__timer {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .panel__timer {
        margin-bottom: 10px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .panel__timer {
        margin-bottom: 10px; } }
    @media only screen and (max-width: 767px) {
      .panel__timer {
        margin-left: 0; } }
    .panel__timer .timer__note {
      margin: 0;
      padding: 0; }
    .panel__timer .timer__table {
      margin-top: 4px; }
  .panel__countnote {
    float: left;
    font-family: 'ClearSans', sans-serif;
    padding-left: 5px; }
  .panel__countbutton {
    float: right;
    background-color: #388ad6;
    background-image: linear-gradient(180deg, #2b85c9, #193a62);
    box-shadow: 0 -1px 0 0 #79c6ff, 0 2px 0 0 #152f50, inset 0 1px 7px 0 rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    font-size: 12px;
    line-height: 20px;
    text-shadow: none;
    padding: 0px 8px;
    font-family: 'ClearSans',sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__countbutton {
        position: absolute;
        right: 17px;
        top: 30px;
        padding: 0 4px; } }
    @media only screen and (max-width: 767px) {
      .panel__countbutton {
        position: absolute;
        right: 17px;
        top: 30px;
        padding: 0 4px; } }
  .panel__countdown {
    position: relative;
    clear: both;
    display: inline-block;
    vertical-align: top;
    padding-left: 3px;
    margin-top: 26px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__countdown {
        padding-left: 0; } }
    @media only screen and (max-width: 767px) {
      .panel__countdown {
        padding-left: 0;
        display: block;
        margin-top: 36px; } }
    .panel__countdown .countdown__divider {
      padding: 0 4px 0 2px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .panel__countdown .countdown__divider {
          padding: 0 1px 0 0; } }
      @media only screen and (max-width: 767px) {
        .panel__countdown .countdown__divider {
          padding: 0 1px 0 0; } }
    @media only screen and (min-width: 1440px) {
      .panel__countdown .countdown__item {
        width: 36px;
        background-size: 42px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .panel__countdown .countdown__item {
        width: 24px;
        background-size: 30px;
        font-size: 20px;
        line-height: 34px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .panel__countdown .countdown__item {
        width: 24px;
        background-size: 30px;
        font-size: 20px;
        line-height: 34px;
        margin-right: 3px; } }
    @media only screen and (max-width: 767px) {
      .panel__countdown .countdown__item {
        width: 24px;
        background-size: 30px;
        font-size: 20px;
        line-height: 34px;
        margin-right: 3px; } }
  .panel__title {
    margin: 0;
    font-family: 'ClearSans', sans-serif;
    display: inline-block;
    vertical-align: middle; }
    .panel__title_tournament {
      padding: 0 0 0 14px;
      max-width: 401px;
      max-height: 38px;
      overflow: hidden; }
      @media only screen and (min-width: 1440px) {
        .panel__title_tournament {
          max-width: 302px; } }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .panel__title_tournament {
          max-width: 302px; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .panel__title_tournament {
          max-width: 138px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .panel__title_tournament {
          max-width: 138px; } }
      @media only screen and (max-width: 767px) {
        .panel__title_tournament {
          max-width: none;
          padding: 0 0 0 10px;
          display: table-cell;
          vertical-align: middle; } }
    .panel__title.title {
      font-size: 16px;
      line-height: 19px; }
    .panel__title_lottery {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.27);
      color: #ffd801;
      font-weight: 500;
      line-height: 28px;
      text-overflow: initial;
      white-space: normal;
      overflow: initial; }
  .panel__caption {
    margin: 20px 0 0;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 17px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.38);
    max-height: 102px;
    overflow: hidden;
    padding: 0 25px; }
  .panel__status {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.11);
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 9px 13px;
    color: #fff;
    background-color: #14c471;
    background-image: linear-gradient(180deg, #18e581 0%, #0e9b5d 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 4px 8px rgba(0, 0, 0, 0.13); }
    @media only screen and (max-width: 767px) {
      .panel__status {
        display: table-cell;
        vertical-align: middle;
        text-indent: -9999px;
        font-size: 0;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;
        height: 11px; }
        .panel__status:before {
          display: block;
          position: relative;
          content: '';
          width: 11px;
          height: 11px;
          background-image: linear-gradient(to bottom, #30e08a, #10a663);
          box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.31);
          border: solid 1px #29af73;
          text-indent: -9999px;
          border-radius: 50%;
          padding: 0; } }
    .panel__status_finished {
      background-color: #ff6526;
      background-image: linear-gradient(180deg, #ffb11a 0%, #ff0036 100%);
      box-shadow: none; }
      @media only screen and (max-width: 767px) {
        .panel__status_finished {
          display: table-cell;
          vertical-align: middle;
          text-indent: -9999px;
          font-size: 0;
          background: none;
          border: none;
          box-shadow: none;
          padding: 0;
          height: 11px; }
          .panel__status_finished:before {
            display: block;
            position: relative;
            content: '';
            width: 11px;
            height: 11px;
            background-color: #fd7423;
            background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
            border: solid 1px #fd0834;
            text-indent: -9999px;
            border-radius: 50%;
            padding: 0; } }
    .panel__status_future {
      background-color: #ffea00;
      background-image: linear-gradient(180deg, #ffea00 0%, #d69200 100%);
      box-shadow: none; }
      @media only screen and (max-width: 767px) {
        .panel__status_future {
          display: table-cell;
          vertical-align: middle;
          text-indent: -9999px;
          font-size: 0;
          background: none;
          border: none;
          box-shadow: none;
          padding: 0;
          height: 11px; }
          .panel__status_future:before {
            display: block;
            position: relative;
            content: '';
            width: 11px;
            height: 11px;
            background-color: #ffea00;
            background-image: linear-gradient(180deg, #ffea00 0%, #d69200 100%);
            border: solid 1px #d69200;
            text-indent: -9999px;
            border-radius: 50%;
            padding: 0; } }
  .panel__info-button {
    display: block;
    position: relative;
    margin-top: 23px;
    padding: 0 25px;
    z-index: 3; }
    @media only screen and (max-width: 767px) {
      .panel__info-button {
        text-align: center; } }
    .panel__info-button .button {
      padding: 10px 11px 9px 13px; }
      @media only screen and (max-width: 767px) {
        .panel__info-button .button {
          white-space: nowrap; } }
    .panel__info-button_lottery {
      margin-top: 32px; }
  .panel__arrow {
    display: inline-block;
    line-height: 12px;
    padding: 4px 0 0 6px;
    vertical-align: top; }
  .panel__tickets {
    display: block;
    position: relative;
    margin-top: 1px; }
  .panel__finished {
    display: block;
    position: relative;
    margin-top: 1px;
    padding: 16px;
    width: 100%;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.11);
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #fd7423;
    background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }

@media only screen and (max-width: 767px) {
  .panel_lottery .panel__title_lottery {
    display: table-cell;
    padding-left: 13px; } }

.panel_lottery .panel__summary {
  height: 312px; }
  @media only screen and (max-width: 767px) {
    .panel_lottery .panel__summary {
      height: auto; } }

.panel_lottery .panel__overflow {
  height: 312px;
  width: 251px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .panel_lottery .panel__overflow {
      height: 88px;
      width: 88px; } }
  @media only screen and (max-width: 767px) {
    .panel_lottery .panel__overflow {
      width: 64px;
      height: 64px; } }

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .panel_lottery .panel__finished {
    position: absolute;
    width: 660px;
    bottom: -4px;
    left: 0;
    height: 40px;
    font-size: 18px;
    right: 0;
    display: block;
    box-shadow: none;
    padding: 10px; } }

@media only screen and (max-width: 767px) {
  .panel_lottery .panel__finished {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 72px;
    top: 27px;
    padding: 0;
    text-indent: -9999px; } }

.panel_lottery .panel__img {
  height: 251px;
  width: 251px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .panel_lottery .panel__img {
      max-width: 88px;
      height: 88px; } }
  @media only screen and (max-width: 767px) {
    .panel_lottery .panel__img {
      width: 64px;
      height: 64px; } }

@media only screen and (min-width: 1440px) {
  .panel_lottery .panel__info {
    width: 716px; } }

@media only screen and (min-width: 1240px) and (max-width: 1439px) {
  .panel_lottery .panel__info {
    width: 522px; } }

.tournament-details {
  display: block;
  position: relative; }
  .tournament-details__header {
    display: block;
    position: relative;
    height: 238px; }
    @media only screen and (max-width: 767px) {
      .tournament-details__header {
        height: auto;
        min-height: 182px;
        margin-bottom: 15px; } }
    .tournament-details__header:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 797px;
      width: 100%;
      height: 165px;
      opacity: 0.29;
      background-image: radial-gradient(380px 210px at 50% 163.64%, white 0%, transparent 100%);
      border-bottom: 1px solid #224061; }
  .tournament-details__summary {
    position: relative;
    max-width: 1140px;
    margin: 0 auto 20px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tournament-details__summary {
        max-width: 750px; } }
    @media only screen and (max-width: 767px) {
      .tournament-details__summary {
        max-width: 600px; } }
  .tournament-details__crosstitle {
    display: block;
    position: relative;
    padding: 55px 100px 51px; }
    @media only screen and (max-width: 767px) {
      .tournament-details__crosstitle {
        padding: 55px 20px 51px; } }
    .tournament-details__crosstitle_small {
      padding: 33px 100px 30px; }
      @media only screen and (max-width: 767px) {
        .tournament-details__crosstitle_small {
          padding: 33px 20px 30px; } }
    .leaderboard .tournament-details__crosstitle {
      border-bottom: 1px solid #036;
      padding: 17px 0;
      margin-bottom: 4px;
      font-size: 21px;
      font-weight: 100; }
      .leaderboard .tournament-details__crosstitle span {
        color: #8aadee; }
  .tournament-details__leaderboard {
    position: relative;
    max-width: 1140px;
    margin: 0 auto; }
  .tournament-details__slider {
    width: 960px;
    margin: 0 auto; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .tournament-details__slider {
        width: 800px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tournament-details__slider {
        width: 630px; } }
    @media only screen and (max-width: 767px) {
      .tournament-details__slider {
        width: calc(100% - 40px);
        max-width: 480px; } }
    .tournament-details__slider .slider__item {
      height: 104px;
      overflow: hidden;
      margin: 0 10px; }
      @media only screen and (max-width: 767px) {
        .tournament-details__slider .slider__item {
          margin: 0 5px; } }
      .tournament-details__slider .slider__item .slider__img {
        height: 55px;
        width: 81px; }
        @media only screen and (max-width: 767px) {
          .tournament-details__slider .slider__item .slider__img {
            width: 81px;
            height: 55px;
            max-width: 100%;
            margin: 0 auto; } }
  .tournament-details__button {
    padding: 9px 27px;
    margin-top: 22px; }
  @media only screen and (max-width: 767px) {
    .tournament-details__header_top {
      height: 80px;
      padding-top: 20px; }
      .tournament-details__header_top:before {
        content: '';
        display: inline-block;
        width: 1px;
        margin-left: -1px;
        font-size: 0;
        height: 80px;
        vertical-align: middle; } }
  .tournament-details__title {
    display: block;
    position: relative;
    line-height: 1.2; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .tournament-details__title {
        line-height: 1.2;
        font-size: 22px; } }
    @media only screen and (max-width: 767px) {
      .tournament-details__title {
        font-size: 16px;
        padding-top: 0;
        display: inline-block;
        vertical-align: middle;
        width: calc(99% - 1px);
        line-height: 25px; } }
  .tournament-details__subtitle {
    padding-top: 22px;
    display: block;
    position: relative;
    font-size: 20px;
    font-weight: 100;
    color: #8aadee; }
  .tournament-details__countdown {
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .tournament-details__countdown {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 0;
        white-space: nowrap; } }
  .tournament-details__currency {
    display: block;
    position: absolute;
    bottom: 0;
    right: -48px;
    font-size: 56px;
    line-height: 44px;
    font-weight: 500;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .tournament-details__currency {
        display: none; } }
    .tournament-details__currency_ruble {
      background-image: url(../img/sprite.png?v=2);
      background-position: -792px -248px;
      width: 36px;
      height: 38px;
      bottom: 10px; }
    .tournament-details__currency_dollar {
      right: -40px;
      font-size: 47px; }
  .tournament-details__prizes-sum {
    margin: 0;
    font-size: 42px; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .tournament-details__prizes-sum {
        font-size: 33px; } }
    @media only screen and (max-width: 767px) {
      .tournament-details__prizes-sum {
        font-size: 24px; } }
    .tournament-details__prizes-sum span {
      color: #fff; }

.summary {
  display: block;
  position: relative;
  font-size: 0;
  height: 400px;
  box-shadow: 0 10px 14px 3px rgba(11, 29, 53, 0.19), 0 10px 20px 1px rgba(11, 29, 53, 0.48);
  border-radius: 7px;
  margin-top: 30px;
  overflow: hidden;
  border-bottom: 1px solid #003366; }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .summary {
      height: 400px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .summary {
      height: auto; } }
  @media only screen and (max-width: 767px) {
    .summary {
      height: auto; } }
  .summary__block {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: calc(50% - 1px);
    height: 400px; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .summary__block {
        height: 400px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .summary__block {
        width: 100%; }
        .summary__block:first-child {
          position: relative;
          top: 425px; }
        .summary__block:last-child {
          position: relative;
          top: -360px; } }
    @media only screen and (max-width: 767px) {
      .summary__block {
        width: 100%;
        display: block; }
        .summary__block:first-child {
          position: relative;
          top: 390px;
          height: 370px; }
        .summary__block:last-child {
          position: relative;
          top: -360px; } }
    .summary__block .timer__table {
      display: inline-block;
      vertical-align: middle; }
  .summary__description {
    display: block;
    height: 100%;
    position: relative;
    background-color: #102448;
    padding-top: 20px;
    color: #7c9ddb; }
  .summary__content {
    display: block;
    position: relative;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    color: #7c9ddb;
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    text-align: left;
    padding: 0 90px 0 0;
    height: 330px;
    margin: 0 20px 20px;
    overflow: auto; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .summary__content {
        height: 360px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .summary__content {
        padding: 10px; } }
    @media only screen and (max-width: 767px) {
      .summary__content {
        padding: 0; } }
    .summary__content p:first-child {
      margin-top: 0; }
  .summary__info {
    display: table;
    width: 100%;
    height: 100%;
    text-align: left;
    background: #20355c; }
  .summary__item {
    display: table;
    width: 100%;
    height: 40px; }
    .summary__item:nth-child(2) {
      background: #213a69; }
    .summary__item:nth-child(3) {
      background: #25437a; }
    .summary__item:last-child {
      background: #274b8e; }
  .summary__cell {
    display: table-cell;
    vertical-align: middle;
    padding: 0 22px; }
    .summary__cell:last-child {
      text-align: right; }
    .summary__description .summary__cell {
      text-align: left; }
  .summary__divider {
    display: table-row;
    height: 1px; }
  .summary__lottery-info {
    height: 354px; }
    @media only screen and (max-width: 767px) {
      .summary__lottery-info {
        height: auto; } }
  .summary__title {
    text-transform: inherit; }
    .summary__title:last-child {
      text-transform: uppercase; }
    .summary__cell:first-child .summary__title {
      color: #8aadee;
      font-weight: 100; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .summary__title {
        font-size: 16px; } }
  .summary__details {
    height: 280px;
    overflow: hidden; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .summary__details {
        height: 278px; } }
    @media only screen and (max-width: 767px) {
      .summary__details {
        height: 270px; } }
  .summary table {
    font-size: 15px;
    width: 100%;
    color: #8aadee; }
    .summary table tr:last-child td {
      padding-top: 10px; }
    .summary table td {
      width: 50%;
      padding: 0 20px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .summary table td {
          padding: 0 10px; } }
      @media only screen and (max-width: 767px) {
        .summary table td {
          padding: 0 10px; } }
      .summary table td:last-child {
        text-align: right; }
      .summary table td.countdown__timer {
        background: linear-gradient(to right, #20345b 0%, #0b1f41 100%);
        margin: 0; }
        .summary table td.countdown__timer > span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px; }
          @media only screen and (min-width: 1000px) and (max-width: 1239px) {
            .summary table td.countdown__timer > span {
              font-size: 13px; } }
        .summary table td.countdown__timer .timer__row_digit .timer__cell {
          font-size: 25px; }
          @media only screen and (min-width: 1000px) and (max-width: 1239px) {
            .summary table td.countdown__timer .timer__row_digit .timer__cell {
              font-size: 20px; } }
          @media only screen and (max-width: 767px) {
            .summary table td.countdown__timer .timer__row_digit .timer__cell {
              font-size: 18px; } }
    .summary table small {
      font-size: 15px;
      color: #fff;
      text-transform: lowercase; }
      .summary table small.tournament-details__subtitle {
        padding-top: 10px; }
  .summary .active-tournament__status,
  .summary .future-tournament__status,
  .summary .finished-tournament__status {
    font-size: 16px;
    padding-right: 0px;
    padding-top: 5px;
    padding-bottom: 15px; }
    .summary .active-tournament__status .tourn__status,
    .summary .future-tournament__status .tourn__status,
    .summary .finished-tournament__status .tourn__status {
      display: inline-block; }
      .summary .active-tournament__status .tourn__status .stat,
      .summary .future-tournament__status .tourn__status .stat,
      .summary .finished-tournament__status .tourn__status .stat {
        color: #8aadee;
        font-size: 0.95em; }
    .summary .active-tournament__status img,
    .summary .future-tournament__status img,
    .summary .finished-tournament__status img {
      position: relative;
      top: 5px;
      right: 0px;
      width: 45px; }
  .summary .active-tournament__status {
    color: #00ffbb; }
  .summary .future-tournament__status {
    color: #ff9500; }
  .summary .finished-tournament__status {
    color: #ff364b; }
  .summary__start-time, .summary__end-time {
    font-size: 22px;
    color: #fff;
    margin: 5px 0 0;
    font-weight: 100; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .summary__start-time, .summary__end-time {
        font-size: 21px; } }

.leaderboard {
  display: block;
  position: relative;
  margin: 0 auto;
  width: auto;
  max-width: 1140px;
  padding-bottom: 20px;
  font-size: 0;
  min-height: 220px;
  background-color: #20355c;
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.52); }
  @media only screen and (max-width: 767px) {
    .leaderboard {
      padding-bottom: 60px;
      position: relative; } }
  .leaderboard__block {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 26px 18px 0 18px; }
    .leaderboard__block:focus {
      outline: none; }
    .tournament-details__leaderboard .leaderboard__block {
      padding: 0; }
  .leaderboard__importance {
    display: table;
    margin: 0 auto 20px;
    height: 29px;
    background-color: #30508c;
    width: 800px;
    border-left: 1px solid #3376b5;
    border-right: 1px solid #3376b5;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .leaderboard__importance {
        max-width: 750px; } }
    @media only screen and (max-width: 767px) {
      .leaderboard__importance {
        max-width: 600px; } }
    .leaderboard__importance:before {
      content: ' ';
      display: block;
      position: absolute;
      background-color: #2a467b;
      opacity: 0.6;
      height: 30px;
      bottom: -7px;
      width: calc(100% - 14px);
      left: 1%; }
    .leaderboard__importance:after {
      content: ' ';
      display: block;
      position: absolute;
      background-color: #2d4a82;
      opacity: 0.4;
      height: 30px;
      bottom: -13px;
      width: calc(100% - 26px);
      left: 2%; }
    @media only screen and (max-width: 767px) {
      .leaderboard__importance {
        margin: 0 18px;
        width: calc(100% - 36px); } }
  .leaderboard__cell {
    display: table-cell;
    vertical-align: middle;
    width: 1px;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    padding: 10px 15px;
    border-top: 1px solid #3376b5;
    border-bottom: 1px solid #3376b5;
    position: relative;
    z-index: 1;
    background: #30508c; }
    .leaderboard__cell:nth-last-child(1) {
      color: #fdc236;
      width: 13%;
      padding: 10px 15px 10px 0px;
      text-align: right; }
    .leaderboard__cell:nth-last-child(2) {
      color: #17b271; }
    .leaderboard__cell_fluid {
      width: auto; }
  .tournament-details__leaderboard .leaderboard {
    max-width: 810px;
    padding-bottom: 30px;
    width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .tournament-details__leaderboard .leaderboard {
        max-width: 750px; } }
    @media only screen and (max-width: 767px) {
      .tournament-details__leaderboard .leaderboard {
        max-width: 600px; } }

.slider {
  display: block;
  margin: 0 auto !important;
  position: relative; }
  .slider__item {
    display: block;
    padding: 0 4px; }
    .slider__item:focus {
      outline: none; }
    .slider__item__inner {
      position: relative;
      width: 940px;
      margin: 0 auto;
      z-index: 1; }
      @media only screen and (min-width: 1440px) {
        .slider__item__inner {
          margin: 0 -76px;
          width: 1420px; } }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .slider__item__inner {
          width: 1194px; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .slider__item__inner {
          width: 940px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .slider__item__inner {
          width: 700px; } }
  .slider__img_text {
    position: absolute;
    left: 0;
    top: 0; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .slider__img_text {
        width: 32%; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .slider__img_text {
        width: 32%; } }
    .slider__img_text.puaro_text {
      top: 23px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .slider__img_text.puaro_text {
          top: 25px;
          width: 32%;
          left: -7px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .slider__img_text.puaro_text {
          top: 32px;
          width: 32%;
          left: -4px; } }
  .slider__img {
    display: block;
    position: relative; }
    .slider__img_desktop {
      display: block !important; }
      @media only screen and (max-width: 767px) {
        .slider__img_desktop {
          display: none !important; } }
    .slider__img_mobile {
      display: none !important; }
      @media only screen and (max-width: 767px) {
        .slider__img_mobile {
          display: block !important; } }
  .slider__title {
    display: block;
    text-align: center;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .slider .slick-prev {
    left: -14px;
    top: 24px;
    text-align: left;
    height: 20px; }
    .slider .slick-prev:before {
      background-image: url("../img/svg/arrow-left.svg");
      content: '';
      display: inline-block;
      width: 12px;
      height: 20px; }
  .slider .slick-next {
    top: 24px;
    right: -14px;
    text-align: right;
    height: 20px; }
    .slider .slick-next:before {
      background-image: url("../img/svg/arrow-right.svg");
      content: '';
      display: inline-block;
      width: 12px;
      height: 20px; }
  .slider .slick-dots {
    display: block;
    width: 100%;
    bottom: -24px; }
  .slider .slick-dots li, .slider .slick-dots li button {
    width: 33px;
    height: 4px;
    margin: 0 4px; }
  .slider .slick-dots li button:before {
    width: 33px;
    height: 4px;
    background-color: #1f3957;
    content: ''; }
  .slider .slick-dots li.slick-active button:before {
    width: 33px;
    height: 4px;
    background-color: #fda91b;
    background-image: linear-gradient(180deg, #fda31b 0%, #fd5908 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }

.slider_info .slider__item {
  display: block;
  position: relative;
  padding: 17px 10px;
  width: 170px;
  height: 78px;
  margin: 0 4px;
  background-color: #415f83;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 4px 0 rgba(4, 4, 4, 0.12); }

.slider_info .slick-prev {
  left: -23px;
  top: 38px; }

.slider_info .slick-next {
  top: 38px;
  right: -23px; }

.slider_hero {
  display: none; }
  @media only screen and (max-width: 767px) {
    .slider_hero {
      height: auto; } }
  .slider_hero .slider__item {
    width: 100%;
    padding: 0; }
  .slider_hero .slider__img_withcursor {
    cursor: pointer; }
  .slider_hero .slider__img {
    width: 100%;
    height: 390px; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .slider_hero .slider__img {
        height: 324px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .slider_hero .slider__img {
        height: 258px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .slider_hero .slider__img {
        height: 192px; } }
    @media only screen and (max-width: 767px) {
      .slider_hero .slider__img {
        height: 250px; } }
  @media only screen and (max-width: 767px) {
    .slider_hero .tournament__img {
      height: 150px; } }
  .slider_hero .slider__link {
    display: inline-block;
    position: absolute;
    bottom: 100px;
    text-decoration: none;
    font-family: "ClearSans", sans-serif;
    left: 127px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase; }
    .slider_hero .slider__link:before {
      content: "";
      position: absolute;
      display: block;
      background-image: url(../img/sprite.png?v=2);
      background-position: -852px -671px;
      width: 14px;
      height: 12px;
      left: 100%;
      top: 9px;
      margin-left: 10px;
      transition: margin-left ease 0.3s; }
    .slider_hero .slider__link:hover {
      text-decoration: underline; }
      .slider_hero .slider__link:hover:before {
        margin-left: 30px; }
  .slider_hero .slick-dots {
    bottom: 10px;
    right: 0;
    display: inline-block;
    width: auto;
    z-index: 100; }
    @media only screen and (min-width: 1440px) {
      .slider_hero .slick-dots {
        right: 0; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .slider_hero .slick-dots {
        right: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .slider_hero .slick-dots {
        right: 0;
        bottom: 11px; } }
    @media only screen and (max-width: 767px) {
      .slider_hero .slick-dots {
        top: 17px;
        right: 17px;
        bottom: auto; } }
  .slider_hero .slick-dots li, .slider_hero .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0 4px; }
  .slider_hero .slick-dots li button:before {
    width: 15px;
    height: 15px;
    background-color: #27425f;
    border-radius: 50%;
    content: '';
    opacity: 1; }
  .slider_hero .slick-dots li.slick-active button:before {
    width: 15px;
    height: 15px;
    background-color: #00455e;
    background-image: linear-gradient(180deg, #1b87d5 0%, #3a6dbf 100%);
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 rgba(122, 199, 255, 0.38), 0 2px 4px rgba(0, 0, 0, 0.24); }

.slider_leaderboard.slick-slider {
  margin-bottom: 10px;
  position: static; }
  .slider_leaderboard.slick-slider .slick-list {
    position: static; }

.slider_leaderboard .slick-dots {
  bottom: 13px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 9; }

.slider_leaderboard .slick-next.slick-disabled:before, .slider_leaderboard .slick-prev.slick-disabled:before {
  opacity: 1;
  color: rgba(255, 255, 255, 0.25); }

.slider_leaderboard .slick-dots li, .slider_leaderboard .slick-dots li button {
  width: 15px;
  height: 15px;
  margin: 0 4px;
  font-size: 15px;
  color: #fff; }

.slider_leaderboard .slick-dots li.slick-active button {
  font-size: 20px;
  color: #8aadee;
  position: relative;
  top: 1px; }

.slider_leaderboard .slick-dots li button:before {
  display: none; }

.slider_leaderboard .slick-next {
  bottom: -20px;
  position: absolute;
  top: auto;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 101; }
  .slider_leaderboard .slick-next:before {
    content: "\f054";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    padding: 10px 12px;
    border-left: 1px solid #036; }

.slider_leaderboard .slick-prev {
  bottom: -20px;
  position: absolute;
  top: auto;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 101; }
  .slider_leaderboard .slick-prev:before {
    content: "\f053";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    padding: 10px 12px;
    border-right: 1px solid #036; }

.lottery-details__tickets.slick-slider {
  margin-bottom: 60px; }

.lottery-details__tickets .slick-dots {
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: auto;
  z-index: 100; }

.lottery-details__tickets .slick-dots li, .lottery-details__tickets .slick-dots li button {
  width: 15px;
  height: 15px;
  margin: 0 4px; }

.lottery-details__tickets .slick-dots li button:before {
  width: 15px;
  height: 15px;
  background-color: #27425f;
  border-radius: 50%;
  content: '';
  opacity: 1; }

.lottery-details__tickets .slick-dots li.slick-active button:before {
  width: 15px;
  height: 15px;
  background-color: #00455e;
  background-image: linear-gradient(180deg, #1b87d5 0%, #3a6dbf 100%);
  box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 rgba(122, 199, 255, 0.38), 0 2px 4px rgba(0, 0, 0, 0.24); }

.slider_gameplay .slider__item {
  margin: 0 11px;
  padding: 0; }

.slider_gameplay .slick-prev {
  left: -14px;
  top: 25px; }

.slider_gameplay .slick-next {
  top: 25px;
  right: -14px; }

.slider_small .slider__img {
  width: 100%; }

.slider_small .slick-prev, .slider_small .slick-next {
  top: 35px;
  /*@include xSmall {
      width: 63px;
      height: 100%;
      background-image: linear-gradient(to right,rgba(0,0,0,.1) 0,rgba(0,0,0,.5) 100%);
      opacity: 0.3;
      z-index: 2;
      top: 34px;
      right: 17px;

      &:before {
        display: none;
      }
    }*/ }

.slider_small .slick-prev {
  /*@include xSmall {
      transform: rotate(180deg);
      top: 0;
      left: 17px;
    }*/ }

.slider_small .slick-dots {
  bottom: -14px; }

.slider_tournament .slick-prev, .slider_tournament .slick-next {
  top: 23px; }

.levels-table__table.slick-slider {
  margin-bottom: 0; }
  .levels-table__table.slick-slider .slick-dots {
    top: 44px;
    bottom: auto; }
  .levels-table__table.slick-slider .slick-dots li, .levels-table__table.slick-slider .slick-dots li button {
    width: 15px;
    height: 15px;
    margin: 0 4px; }
  .levels-table__table.slick-slider .slick-dots li button:before {
    width: 15px;
    height: 15px;
    background-color: #27425f;
    border-radius: 50%;
    content: '';
    opacity: 1; }
  .levels-table__table.slick-slider .slick-dots li.slick-active button:before {
    width: 15px;
    height: 15px;
    background-color: #00455e;
    background-image: linear-gradient(180deg, #1b87d5 0%, #3a6dbf 100%);
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 rgba(122, 199, 255, 0.38), 0 2px 4px rgba(0, 0, 0, 0.24); }

.chosen-container-single .chosen-single {
  background: #6d6c84;
  border: none;
  border-radius: 0;
  height: 35px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  color: #fff;
  font-family: 'ClearSans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
  padding: 0 0 0 14px; }

.chosen-container-single .chosen-single div b {
  background-image: url(../img/sprite.png?v=2);
  background-position: -434px -438px;
  width: 12px;
  height: 8px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px; }

.chosen-container.chosen-with-drop .chosen-single div b {
  background-image: url(../img/sprite.png?v=2);
  background-position: -392px -438px;
  width: 12px;
  height: 8px; }

.chosen-container-single .chosen-drop {
  background: #6d6c84;
  border-radius: 0;
  border: none;
  margin-top: 0; }

.chosen-container-active.chosen-with-drop .chosen-single {
  background: #6d6c84;
  border: none;
  box-shadow: none; }

.chosen-container .chosen-results {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
  font-family: 'ClearSans', sans-serif;
  padding: 0;
  margin: 0; }
  .chosen-container .chosen-results .active-result {
    padding: 10px 14px; }

.chosen-container .chosen-results li.highlighted {
  background-color: #398cd7;
  background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.18), 0 8px 10px rgba(0, 0, 0, 0.16); }

.chosen-container .chosen-results li.disabled-result {
  display: none; }

.Zebra_DatePicker {
  background-color: #132e4d;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2), 0 -1px 0px 2px #132e4d; }

.Zebra_DatePicker.dp_visible {
  border-radius: 2px;
  border: none; }

.Zebra_DatePicker * {
  color: #ffffff; }

.Zebra_DatePicker td.dp_hover, .Zebra_DatePicker .dp_header td.dp_hover {
  background-color: #353535;
  border-radius: 1px;
  color: #ffffff !important; }

.Zebra_DatePicker td.dp_selected {
  background-color: #6a9500;
  border-radius: 1px;
  color: #FFF !important; }

.Zebra_DatePicker td.dp_current {
  color: #ffffff; }

.lottery {
  display: block;
  position: relative; }
  .lottery__title {
    padding: 22px;
    text-align: center; }
  @media only screen and (max-width: 767px) {
    .lottery__caption {
      display: none; } }
  .lottery__caption_xs {
    display: none; }
    @media only screen and (max-width: 767px) {
      .lottery__caption_xs {
        display: block;
        font-size: 14px; } }
  .lottery__tabs {
    height: 60px;
    border-radius: 12px;
    font-size: 0;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 5px 8px rgba(0, 0, 0, 0.17);
    width: 575px;
    margin: 0 auto;
    max-width: 100%; }
    @media only screen and (max-width: 767px) {
      .lottery__tabs {
        height: 35px; } }
  .lottery__tabitem {
    width: 50%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: center;
    padding: 19px;
    cursor: pointer;
    border: 1px solid #1776bb;
    background-color: #00284b; }
    @media only screen and (max-width: 767px) {
      .lottery__tabitem {
        padding: 12px 19px 32px 19px; } }
    .lottery__tabitem:before {
      content: none; }
    .lottery__tabitem:hover .title {
      color: #ffea00; }
    .lottery__tabitem:first-child {
      border-radius: 10px 0 0 10px; }
    .lottery__tabitem:last-child {
      border-radius: 0 10px 10px 0; }
    .lottery__tabitem_active {
      background-color: #135a82;
      border-radius: 2px; }
      .lottery__tabitem_active .title {
        color: #ffea00; }
  .lottery__content {
    margin-top: 32px; }
  .lottery__panel {
    display: block;
    position: relative;
    margin-bottom: 35px; }

.ticket {
  display: block;
  position: relative;
  width: 100%;
  font-size: 0;
  height: 144px;
  background-color: #29476a;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07), 0 5px 5px rgba(0, 0, 0, 0.12); }
  .ticket__action {
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 100%;
    padding: 26px 25px 30px 26px; }
    .ticket__action:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #223c59;
      box-shadow: 1px 0 0 #355478; }
  .ticket__summary {
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07), 0 5px 5px rgba(0, 0, 0, 0.12);
    width: 260px;
    height: 100%;
    background-color: #29476a;
    background-image: url("../img/timer-bg.png");
    background-position: center;
    background-repeat: no-repeat; }
  .ticket__head {
    display: block;
    position: relative; }
  .ticket__title {
    display: inline-block;
    vertical-align: top;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1px 10px 2px 3px; }
  .ticket__info {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    position: relative;
    cursor: pointer;
    padding: 4px 0 0 0;
    text-align: center;
    width: 21px;
    height: 21px;
    font-size: 0;
    border-radius: 50%;
    background-color: #4ea1e1;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
  .ticket__tooltip {
    min-width: 191px;
    right: 0;
    margin-right: 11px;
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    top: calc(100% + 23px);
    opacity: 0;
    visibility: hidden; }
    .ticket__info:hover .ticket__tooltip {
      opacity: 1;
      visibility: visible; }
  .ticket__exchange {
    display: block;
    position: relative;
    white-space: nowrap;
    margin-top: 22px; }
  .ticket__input {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 148px; }
    .ticket__input_withbutton {
      width: 190px; }
      .ticket__input_withbutton .input__inner {
        padding: 17px 63px 17px 14px; }
    .ticket__input .input__inner {
      width: 100%;
      height: 44px;
      background-color: #1a3350;
      border-radius: 2px;
      box-shadow: inset 0 1px 0 #152a42, 0 1px 0 #33567f;
      font-size: 14px;
      font-weight: 500; }
  .ticket__button {
    width: 49px;
    height: 44px;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px; }
  .ticket__icon {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 11px 18px 11px 21px; }
  .ticket__caption {
    display: block;
    position: relative;
    text-align: center;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.18);
    text-transform: uppercase;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 44px auto 0; }
    .ticket__caption_accent {
      color: #ffde00;
      font-size: 35px;
      font-weight: 700;
      margin: 14px auto 0; }

.lottery-details {
  display: block;
  position: relative; }
  .lottery-details__header {
    display: block;
    position: relative;
    height: 238px; }
    @media only screen and (max-width: 767px) {
      .lottery-details__header {
        height: auto;
        min-height: 182px;
        margin-bottom: 15px; } }
    .lottery-details__header:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 797px;
      width: 100%;
      height: 165px;
      opacity: 0.29;
      background-image: radial-gradient(380px 210px at 50% 163.64%, white 0%, transparent 100%);
      border-bottom: 1px solid #224061; }
  .lottery-details__summary {
    position: relative; }
  .lottery-details__tickets {
    font-size: 0;
    margin-top: 32px; }
  .lottery-details__ticket-table {
    width: 50%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    max-height: 450px;
    background-color: #2f4b6c;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 4px 0 rgba(4, 4, 4, 0.12); }
    @media only screen and (max-width: 767px) {
      .lottery-details__ticket-table {
        max-height: none; } }
  .lottery-details__winnings {
    display: block;
    position: relative;
    margin-top: 34px;
    padding: 0 0 46px;
    overflow: hidden; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-details__winnings {
        margin: 34px auto 10px;
        max-width: 648px; } }
  @media only screen and (max-width: 767px) {
    .lottery-details__header_top {
      height: 80px;
      padding-top: 20px; }
      .lottery-details__header_top:before {
        content: '';
        display: inline-block;
        width: 1px;
        margin-left: -1px;
        font-size: 0;
        height: 80px;
        vertical-align: middle; } }
  .lottery-details__title {
    padding-top: 55px;
    display: block;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .lottery-details__title {
        padding-top: 0;
        display: inline-block;
        vertical-align: middle;
        width: calc(99% - 1px);
        line-height: 33px; } }
  .lottery-details__subtitle {
    padding-top: 22px;
    display: block;
    position: relative; }
  .lottery-details__countdown {
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .lottery-details__countdown {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 0;
        white-space: nowrap; } }
  .lottery-details__currency {
    display: block;
    position: absolute;
    bottom: 0;
    right: -48px;
    font-size: 56px;
    line-height: 44px;
    font-weight: 500;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .lottery-details__currency {
        display: none; } }
    .lottery-details__currency_ruble {
      background-image: url(../img/sprite.png?v=2);
      background-position: -792px -248px;
      width: 36px;
      height: 38px;
      bottom: 3px; }
    .lottery-details__currency_dollar {
      right: -40px;
      font-size: 47px; }

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none; }

@media only screen and (max-width: 767px) {
  .wrap {
    width: 97%; } }

.section__promo {
  background: transparent; }

.lottery-details-promo {
  display: block;
  position: relative; }
  .lottery-details-promo__header {
    display: block;
    position: relative;
    text-align: center;
    margin: 0 auto 44px;
    padding-top: 17px; }
    @media only screen and (max-width: 767px) {
      .lottery-details-promo__header {
        width: 90%;
        margin-bottom: 20px; } }
  .lottery-details-promo__autorisation {
    margin: 19px auto; }
    .lottery-details-promo__autorisation .button__outer {
      position: relative;
      display: inline-block;
      vertical-align: middle; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__autorisation .button__outer {
          display: block;
          margin-bottom: 35px; } }
    .lottery-details-promo__autorisation .registration__text {
      display: block;
      color: #b2b2b2;
      font-family: 'ClearSans', sans-serif;
      font-size: 18px;
      font-weight: 400;
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 100%;
      text-align: center; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__autorisation .registration__text {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__autorisation .registration__text {
          font-size: 14px; } }
  .lottery-details-promo .registration__button, .lottery-details-promo .deposit__button, .lottery-details-promo .layout_lottery-promo__popup .reg__button {
    display: inline-block;
    border: none;
    vertical-align: middle;
    padding: 0 10px;
    width: 297px;
    height: 50px;
    margin: 0;
    background-image: linear-gradient(349deg, #fd0834 0%, #fd2a2f 21%, #fd6724 45%, #fd971c 67%, #fda01b 71%, #fda31b 100%);
    border-radius: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    cursor: pointer;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 50px;
    transition: opacity 0.3s ease; }
    .lottery-details-promo .registration__button:hover, .lottery-details-promo .deposit__button:hover, .lottery-details-promo .layout_lottery-promo__popup .reg__button:hover {
      background-image: linear-gradient(165deg, #fd0834 0%, #fd2a2f 21%, #fd6724 45%, #fd971c 67%, #fda01b 71%, #fda31b 100%);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4); }
    .lottery-details-promo .registration__button:active, .lottery-details-promo .registration__button:focus, .lottery-details-promo .deposit__button:active, .lottery-details-promo .deposit__button:focus, .lottery-details-promo .layout_lottery-promo__popup .reg__button:active, .lottery-details-promo .layout_lottery-promo__popup .reg__button:focus {
      outline: none;
      background-image: linear-gradient(178deg, #d86512 0%, #8b0c0d 100%);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4); }
    @media only screen and (max-width: 767px) {
      .lottery-details-promo .registration__button, .lottery-details-promo .deposit__button, .lottery-details-promo .layout_lottery-promo__popup .reg__button {
        width: 212px;
        height: 40px;
        line-height: 40px;
        font-size: 14px; } }
  .lottery-details-promo .deposit__button {
    margin-top: 20px; }
  .lottery-details-promo .login__button {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 100px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #22384b;
    border-radius: 3px;
    box-shadow: none;
    font-family: "ClearSans", sans-serif;
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 700;
    line-height: 50px;
    padding: 0; }
    .lottery-details-promo .login__button:hover, .lottery-details-promo .login__button:active, .lottery-details-promo .login__button:focus {
      background: #0b2238;
      color: #6bb5ff; }
  .lottery-details-promo__slider {
    background-color: #15355a;
    border: 1px solid #38608e;
    overflow: hidden;
    box-shadow: 0 7px 2px rgba(0, 0, 0, 0.13), inset 1px 0 0 #214268, inset 0 1px 0 #214268;
    margin-bottom: 20px; }
    .lottery-details-promo__slider .slider__title {
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 25px;
      font-weight: 700;
      line-height: 26px;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      overflow: visible; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__slider .slider__title {
          font-size: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slider__title {
          font-size: 18px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slider__title {
          font-size: 12px;
          padding: 0 0 0 80px; } }
    .lottery-details-promo__slider .slider__date {
      width: 110px;
      height: 45px;
      background-color: rgba(255, 255, 255, 0.08);
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 45px;
      position: absolute;
      text-align: center;
      top: -5px;
      left: 0; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slider__date {
          width: 75px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          font-weight: 700; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slider__date {
          width: 65px;
          height: 28px;
          line-height: 28px;
          font-size: 12px;
          font-weight: 700; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-details-promo__slider .slick-slider__item.top-slider .slick-slider__description {
        padding: 0 40px 0 0; } }
    @media only screen and (max-width: 767px) {
      .lottery-details-promo__slider .slick-slider__item.top-slider .slick-slider__counter.timer-box {
        margin: 5px auto; } }
    .lottery-details-promo__slider .slick-slider__item.top-slider .slick__img {
      width: 500px;
      max-width: 500px;
      max-height: inherit; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__item.top-slider .slick__img {
          width: 350px;
          max-width: 350px;
          padding: 0; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__item.top-slider .slick__img {
          width: 300px;
          max-width: 300px; } }
    .lottery-details-promo__slider .slick-slider__item.finished .slider__date {
      color: #fff001; }
      .lottery-details-promo__slider .slick-slider__item.finished .slider__date:before {
        content: '';
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 19px;
        height: 14px;
        background: url("../img/promo/checked_item.png") no-repeat;
        background-size: contain;
        margin-left: -3px; }
    .lottery-details-promo__slider .slick-slider__item.active .slider__date, .lottery-details-promo__slider .slick-current.slick-active.active .slider__date {
      background-color: #3668a2;
      box-shadow: inset 0 1px 0 #5a88bd; }
    .lottery-details-promo__slider .slick-slider__item.top-slider.future .slick-slider__model {
      margin-bottom: 30px; }
    .lottery-details-promo__slider .slick-slider {
      margin: 10px; }
      .lottery-details-promo__slider .slick-slider.top-slider {
        padding-bottom: 70px; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider {
          margin: 10px 10px; } }
    .lottery-details-promo__slider .slick-slider__item {
      outline: none;
      display: flex;
      justify-content: center;
      padding: 75px 88px 0;
      position: relative; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__item {
          padding-top: 45px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__item {
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
          align-items: center;
          padding-top: 45px;
          padding-bottom: 10px; } }
    .lottery-details-promo__slider .slick-slider__img {
      flex: 0 0 50%;
      padding: 10px 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      /*.slick__img {
        display: inline-block;
        max-width: 408px;

        @include Medium {
          max-width: 287px;

        }
        @include Small {
          max-width: 220px;
        }
        @include xSmall {
          max-height: 176px;
          overflow: hidden;
          }
      }*/ }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__img {
          flex: 0 0 40%; } }
      .lottery-details-promo__slider .slick-slider__img .slick__img {
        max-width: 408px;
        max-height: 273px; }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .lottery-details-promo__slider .slick-slider__img .slick__img {
            max-width: 350px;
            max-height: 190px; } }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .lottery-details-promo__slider .slick-slider__img .slick__img {
            max-width: 255px;
            max-height: 160px; } }
        @media only screen and (max-width: 767px) {
          .lottery-details-promo__slider .slick-slider__img .slick__img {
            max-width: 253px;
            max-height: 136px; } }
    .lottery-details-promo__slider .slick-slider__description {
      flex: 0 0 50%;
      padding: 0 20px; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__description {
          flex: 0 0 auto;
          align-self: center;
          padding-bottom: 10px;
          text-align: center; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-details-promo__slider {
        flex: 0 0 60%;
        padding: 0 10px 0 0; } }
    .lottery-details-promo__slider .slick-slider__subtitle {
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 35px;
      font-weight: 500;
      line-height: 35px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__slider .slick-slider__subtitle {
          font-size: 28px;
          line-height: 28px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__subtitle {
          font-size: 14px;
          line-height: 20px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__subtitle {
          font-size: 12px;
          line-height: 15px;
          text-align: center; } }
    .lottery-details-promo__slider .slick-slider__title {
      color: #fff001;
      font-size: 35px;
      font-weight: 700;
      font-family: 'ClearSans', sans-serif;
      line-height: 35px;
      padding: 12px 0; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__slider .slick-slider__title {
          font-size: 28px;
          line-height: 28px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__title {
          font-size: 20px;
          line-height: 24px;
          padding: 5px 0; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__title {
          font-size: 16px;
          line-height: 18px;
          text-align: center; } }
    .lottery-details-promo__slider .slick-slider__model {
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 18px;
      font-weight: 500; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__model {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__model {
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          text-align: center; } }
    .lottery-details-promo__slider .slick-slider__counter {
      width: 292px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.08);
      padding: 20px 20px 8px 20px;
      margin: 20px 0 15px; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__counter.timer-box {
          height: auto;
          min-height: auto;
          padding: 15px 20px 0 20px; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__slider .slick-slider__counter.winners {
          width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__counter.winners {
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__counter.winners {
          width: 100%;
          padding: 6px 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__counter.winners .slick-slider__text, .lottery-details-promo__slider .slick-slider__counter.winners .slick-slider__text.txt-winner {
          font-size: 13px;
          line-height: 20px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__counter.winners .slick-slider__text, .lottery-details-promo__slider .slick-slider__counter.winners .slick-slider__text.txt-winner {
          font-size: 13px;
          line-height: 20px; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__slider .slick-slider__counter {
          width: 242px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__counter {
          padding: 20px 20px 8px 20px;
          width: 242px;
          margin: 10px 0; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__counter {
          padding: 10px;
          width: 200px;
          height: 78px;
          margin: 5px 0;
          text-align: center; } }
      .lottery-details-promo__slider .slick-slider__counter .slick-slider__text {
        margin-top: 0; }
      .lottery-details-promo__slider .slick-slider__counter .slick-slider__text.txt-winner {
        color: #fff;
        font-size: 16px;
        font-weight: normal; }
    .lottery-details-promo__slider .slick-slider__note, .lottery-details-promo__slider .slick-slider__text {
      color: #a2bedc;
      font-family: 'ClearSans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
      text-align: left; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__note.slick-slider__note_date, .lottery-details-promo__slider .slick-slider__text.slick-slider__note_date {
          display: none; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__note.slick-slider__note_date, .lottery-details-promo__slider .slick-slider__text.slick-slider__note_date {
          display: none; } }
    .lottery-details-promo__slider .slick-slider__text {
      margin-top: 30px; }
    .lottery-details-promo__slider .slick-slider__winners {
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      text-align: left; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__winners {
          font-size: 14px; } }
      .lottery-details-promo__slider .slick-slider__winners a {
        color: #fff;
        text-decoration: none; }
    .lottery-details-promo__slider .counter__title {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
      color: #a2bedc;
      font-family: 'ClearSans', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 13px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__slider .counter__title {
          font-size: 14px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .counter__title {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .counter__title {
          font-size: 11px; } }
    .lottery-details-promo__slider .slick-slider__timer {
      padding: 10px 0 9px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__slider .slick-slider__timer {
          padding: 5px 0; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-slider__timer {
          padding: 5px 0; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__timer {
          padding: 5px 0;
          display: inline-block; } }
      .lottery-details-promo__slider .slick-slider__timer .timer__cell {
        text-shadow: 0 3px 0 rgba(0, 0, 0, 0.25);
        color: #f0efef;
        font-family: 'ClearSans', sans-serif;
        font-size: 40px;
        font-weight: 700; }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .lottery-details-promo__slider .slick-slider__timer .timer__cell {
            font-size: 30px; } }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .lottery-details-promo__slider .slick-slider__timer .timer__cell {
            font-size: 30px; } }
        @media only screen and (max-width: 767px) {
          .lottery-details-promo__slider .slick-slider__timer .timer__cell {
            font-size: 18px;
            text-align: center; } }
      .lottery-details-promo__slider .slick-slider__timer .timer__row_caption .timer__cell, .lottery-details-promo__slider .slick-slider__timer .timer__row_caption span {
        text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
        color: #a2bedc;
        font-family: 'ClearSans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 13px;
        text-transform: none; }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .lottery-details-promo__slider .slick-slider__timer .timer__row_caption .timer__cell, .lottery-details-promo__slider .slick-slider__timer .timer__row_caption span {
            font-size: 12px; } }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .lottery-details-promo__slider .slick-slider__timer .timer__row_caption .timer__cell, .lottery-details-promo__slider .slick-slider__timer .timer__row_caption span {
            font-size: 12px; } }
        @media only screen and (max-width: 767px) {
          .lottery-details-promo__slider .slick-slider__timer .timer__row_caption .timer__cell, .lottery-details-promo__slider .slick-slider__timer .timer__row_caption span {
            font-size: 11px;
            width: 10px;
            overflow: hidden;
            font-weight: normal; } }
      .lottery-details-promo__slider .slick-slider__timer .timer__row_caption span {
        width: 33.3%;
        display: inline-block;
        text-align: center; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-slider__timer .timer__cell_empty {
          padding-right: 0; } }
    .lottery-details-promo__slider .slick-slider__link {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
      color: rgba(255, 255, 255, 0.6);
      font-family: 'ClearSans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 13px;
      text-decoration: underline; }
    .lottery-details-promo__slider .slick-dots {
      bottom: 0;
      margin-left: -64px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-dots {
          bottom: 0px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-dots {
          margin-left: 0;
          bottom: 13px; } }
      .lottery-details-promo__slider .slick-dots li {
        width: auto;
        margin: 0 5px;
        height: 25px;
        text-align: center;
        position: relative; }
        @media only screen and (max-width: 767px) {
          .lottery-details-promo__slider .slick-dots li {
            margin: 0 4px; } }
        .lottery-details-promo__slider .slick-dots li:last-child .dot {
          width: 18px;
          height: 18px;
          background-color: #fd951e; }
          .lottery-details-promo__slider .slick-dots li:last-child .dot:before {
            content: '';
            position: absolute;
            background: url("../img/promo/super.png") no-repeat;
            width: 135px;
            height: 31px;
            background-size: contain;
            top: -13px;
            left: 80%; }
            @media only screen and (max-width: 767px) {
              .lottery-details-promo__slider .slick-dots li:last-child .dot:before {
                background: url("../img/promo/super_mobile.png") no-repeat;
                width: 119px;
                height: 25px;
                right: 9px;
                left: auto;
                top: 100%; } }
        .lottery-details-promo__slider .slick-dots li:nth-last-child(2) .dot {
          width: 16px;
          height: 16px; }
        .lottery-details-promo__slider .slick-dots li:nth-last-child(3) .dot {
          width: 14px;
          height: 14px; }
      .lottery-details-promo__slider .slick-dots .dot {
        display: block;
        width: 10px;
        height: 10px;
        box-sizing: content-box;
        background-color: #031e3c;
        border-radius: 50%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        .lottery-details-promo__slider .slick-dots .dot.finished {
          background: #fff001 !important; }
          .lottery-details-promo__slider .slick-dots .dot.finished:after {
            position: absolute;
            content: '';
            width: 19px;
            height: 17px;
            top: 50%;
            left: 50%;
            background: url("../img/promo/checked.png") no-repeat 0 0;
            background-size: contain;
            transform: translate(-30%, -60%); }
      .lottery-details-promo__slider .slick-dots .slick-active .dot {
        box-shadow: 0 0 0 2px #fff; }
        .lottery-details-promo__slider .slick-dots .slick-active .dot.end {
          box-shadow: 0 0 0 2px #fff, 0 0 5px 0 #fff,0 0 10px 0 #fff001, 0 0 30px 0 #fff001, 0 0 20px 0 #000; }
          .lottery-details-promo__slider .slick-dots .slick-active .dot.end:after {
            opacity: 0.7; }
    .lottery-details-promo__slider .slick-arrow {
      display: flex;
      position: absolute;
      right: 20px;
      width: 30px;
      height: 80px;
      text-align: center;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      background: transparent;
      z-index: 2;
      border: none; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__slider .slick-arrow {
          top: 43%;
          right: 0; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__slider .slick-arrow {
          right: -5px;
          top: 30%; } }
      .lottery-details-promo__slider .slick-arrow.disabled {
        cursor: default; }
      .lottery-details-promo__slider .slick-arrow:before {
        color: #fff;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.46);
        font-size: 80px;
        font-weight: normal;
        line-height: 80px;
        display: block;
        content: '\203A';
        font-family: 'ClearSans', sans-serif;
        transition: color ease 0.3s; }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .lottery-details-promo__slider .slick-arrow:before {
            font-size: 72px; } }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .lottery-details-promo__slider .slick-arrow:before {
            font-size: 72px; } }
        @media only screen and (max-width: 767px) {
          .lottery-details-promo__slider .slick-arrow:before {
            font-size: 42px; } }
      .lottery-details-promo__slider .slick-arrow.slick-prev {
        left: 20px;
        right: auto; }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .lottery-details-promo__slider .slick-arrow.slick-prev {
            left: 0; } }
        @media only screen and (max-width: 767px) {
          .lottery-details-promo__slider .slick-arrow.slick-prev {
            left: -5px; } }
        .lottery-details-promo__slider .slick-arrow.slick-prev:before {
          content: '\2039';
          font-family: 'ClearSans', sans-serif; }
      .lottery-details-promo__slider .slick-arrow.slick-disabled:before {
        color: #4b617b;
        text-shadow: none; }
  .lottery-details-promo__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    @media only screen and (min-width: 1440px) {
      .lottery-details-promo__list {
        padding: 0 120px; } }
    .lottery-details-promo__list .lottery-details-promo__slider__list {
      margin-left: auto; }
      @media only screen and (min-width: 1440px) {
        .lottery-details-promo__list .lottery-details-promo__slider__list {
          flex: 0 0 1055px; } }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .lottery-details-promo__list .lottery-details-promo__slider__list {
          flex: 0 0 1055px; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__list .lottery-details-promo__slider__list {
          flex: 0 0 815px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__list .lottery-details-promo__slider__list {
          flex: 0 0 602px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .lottery-details-promo__slider__list {
          flex: 0 0 100%; } }
    .lottery-details-promo__list .slick-slider {
      margin-bottom: 10px; }
    .lottery-details-promo__list .lottery-details-promo__slider {
      box-shadow: 0 1px 0 #153151, inset 0 1px 0 #38608e, inset -1px 0 0 #38608e, inset 1px 0 0 #355d8c, inset 0 -1px 0 #1d426e; }
    .lottery-details-promo__list .list__date {
      display: block;
      flex: 0 0 105px;
      width: 105px;
      margin-right: 20px;
      background-color: #15355a;
      box-shadow: 0 7px 2px rgba(0, 0, 0, 0.13), inset 1px 0 0 #214268, inset 0 1px 0 #214268;
      top: 0;
      transition: top ease-in-out 0.3s; }
      .lottery-details-promo__list .list__date.weee {
        position: fixed;
        z-index: 10;
        top: 10px; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__list .list__date {
          flex: 0 0 83px;
          width: 83px;
          margin-right: 15px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .list__date {
          display: none; } }
    .lottery-details-promo__list .list__date__block .slick-arrow {
      position: relative;
      transition: none;
      left: 0;
      right: 0;
      width: 100%;
      top: 11px; }
      .lottery-details-promo__list .list__date__block .slick-arrow.slick-next {
        top: auto;
        bottom: -9px; }
        .lottery-details-promo__list .list__date__block .slick-arrow.slick-next:after {
          content: '';
          height: 0;
          opacity: 0;
          transition: opacity ease-in-out 0.4s;
          right: 3px;
          top: -33px;
          position: absolute;
          width: 115px;
          background: url("../img/promo/super_date.png") no-repeat; }
        .lottery-details-promo__list .list__date__block .slick-arrow.slick-next.slick-disabled {
          height: 0; }
          .lottery-details-promo__list .list__date__block .slick-arrow.slick-next.slick-disabled:before {
            display: none; }
          .lottery-details-promo__list .list__date__block .slick-arrow.slick-next.slick-disabled:after {
            height: 55px;
            opacity: 1; }
      .lottery-details-promo__list .list__date__block .slick-arrow:before {
        color: #fff;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.46);
        font-size: 24px;
        font-weight: normal;
        line-height: 20px;
        display: block;
        content: '\203A';
        font-family: 'ClearSans', sans-serif;
        transition: color ease 0.3s;
        transform: rotate(90deg); }
      .lottery-details-promo__list .list__date__block .slick-arrow.slick-prev:before {
        content: '\2039';
        font-family: 'ClearSans', sans-serif; }
    .lottery-details-promo__list .list__date__title {
      display: block;
      height: 37px;
      background-color: rgba(255, 255, 255, 0.12);
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 13px;
      font-weight: 900;
      line-height: 37px;
      text-align: center; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__list .list__date__title {
          font-size: 10px; } }
    .lottery-details-promo__list .list__date__item {
      display: block;
      cursor: pointer;
      height: 29px;
      line-height: 29px;
      width: 83px;
      text-align: center;
      margin: 0 auto;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
      color: white;
      font-family: 'ClearSans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      position: relative;
      text-decoration: none; }
      .lottery-details-promo__list .list__date__item.finished {
        color: #fff001; }
        .lottery-details-promo__list .list__date__item.finished:before {
          content: '';
          position: absolute;
          width: 14px;
          height: 10px;
          background: url("../img/promo/checked_item.png") no-repeat;
          background-size: contain;
          left: 7px;
          top: 50%;
          transform: translateY(-50%); }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .lottery-details-promo__list .list__date__item.finished {
            padding: 0 11px 0 21px; } }
      .lottery-details-promo__list .list__date__item:last-child {
        color: #fd951e; }
        .lottery-details-promo__list .list__date__item:last-child:after {
          content: '';
          height: 55px;
          transition: opacity ease-in-out 0.4s;
          right: -14px;
          top: 8px;
          position: absolute;
          width: 115px;
          background: url("../img/promo/super_date.png") no-repeat; }
      .lottery-details-promo__list .list__date__item.active_day {
        background-color: #3668a2;
        box-shadow: inset 0 1px 0 #5a88bd;
        margin-top: 1px; }
      .lottery-details-promo__list .list__date__item.active {
        color: #03b1ff; }
      .lottery-details-promo__list .list__date__item:hover {
        color: #007dbf; }
    .lottery-details-promo__list .lottery-details-promo__slider__list .js-fixed.slick-slider {
      margin-bottom: 0;
      transition: top ease-in-out 0.2s; }
    .lottery-details-promo__list .slick-slider__item {
      padding: 35px 0 10px; }
    .lottery-details-promo__list .slick-slider__img {
      height: auto; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .slick-slider__img {
          margin-bottom: 20px; } }
      .lottery-details-promo__list .slick-slider__img .slick__img {
        max-width: 408px;
        max-height: 273px; }
        @media only screen and (min-width: 1000px) and (max-width: 1239px) {
          .lottery-details-promo__list .slick-slider__img .slick__img {
            max-width: 350px;
            max-height: 190px; } }
        @media only screen and (min-width: 768px) and (max-width: 999px) {
          .lottery-details-promo__list .slick-slider__img .slick__img {
            max-width: 255px;
            max-height: 160px; } }
        @media only screen and (max-width: 767px) {
          .lottery-details-promo__list .slick-slider__img .slick__img {
            max-width: 253px;
            max-height: 136px; } }
    .lottery-details-promo__list .slick-slider__description {
      padding: 0 6px; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .slick-slider__description {
          max-width: 280px; } }
    .lottery-details-promo__list .slick-slider__subtitle {
      font-size: 20px;
      font-weight: 500; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__list .slick-slider__subtitle {
          font-size: 18px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__list .slick-slider__subtitle {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .slick-slider__subtitle {
          font-size: 14px; } }
    .lottery-details-promo__list .slick-slider__title {
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
      padding: 8px 0; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__list .slick-slider__title {
          font-size: 28px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__list .slick-slider__title {
          font-size: 20px;
          line-height: 22px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .slick-slider__title {
          font-size: 18px;
          line-height: 20px; } }
    .lottery-details-promo__list .slick-slider__model {
      font-size: 20px;
      font-weight: 500; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__list .slick-slider__model {
          font-size: 18px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details-promo__list .slick-slider__model {
          font-size: 14px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .slick-slider__model {
          font-size: 14px; } }
    .lottery-details-promo__list .slick-slider__counter {
      width: auto;
      max-width: 320px;
      display: table;
      min-width: 240px;
      height: auto;
      margin-bottom: 8px; }
      @media only screen and (max-width: 767px) {
        .lottery-details-promo__list .slick-slider__counter {
          min-width: 0;
          max-width: none;
          width: 260px;
          margin: 10px 0 6px 0; } }
    .lottery-details-promo__list .counter__title {
      font-size: 14px; }
    .lottery-details-promo__list .slick-slider__timer .timer__cell {
      font-size: 28px; }
    .lottery-details-promo__list .slick-slider__link {
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
      color: white; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details-promo__list .slick-slider__link {
          font-size: 14px; } }
  .lottery-details-promo__title {
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    display: block;
    position: relative;
    margin-bottom: 8px; }
    .lottery-details-promo__title_list {
      text-align: center;
      font-size: 25px; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .lottery-details-promo__title {
        font-size: 20px;
        font-weight: 900;
        line-height: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-details-promo__title {
        font-size: 18px;
        font-weight: 900;
        line-height: 18px; } }
    @media only screen and (max-width: 767px) {
      .lottery-details-promo__title {
        font-size: 11px;
        font-weight: 900;
        line-height: 15px; } }
  .lottery-details-promo__subtitle {
    color: #fff001;
    font-family: 'ClearSans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 20px;
    text-transform: uppercase; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .lottery-details-promo__subtitle {
        font-size: 20px;
        font-weight: 900;
        line-height: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-details-promo__subtitle {
        font-size: 18px;
        font-weight: 900;
        line-height: 18px; } }
    @media only screen and (max-width: 767px) {
      .lottery-details-promo__subtitle {
        font-size: 11px;
        font-weight: 900;
        line-height: 12px;
        margin-bottom: 10px; } }
  .lottery-details-promo__countdown {
    display: inline-block;
    vertical-align: top;
    margin-top: 24px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .lottery-details-promo__countdown {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 0;
        white-space: nowrap; } }

.lottery-details-promo__title_list {
  margin-bottom: 20px; }
  @media only screen and (max-width: 767px) {
    .lottery-details-promo__title_list {
      font-size: 16px; } }

.popup-js {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -49%);
  transform: translate(-50%, -49%); }
  @media only screen and (max-width: 767px) {
    .popup-js {
      position: absolute;
      left: 0;
      top: 0;
      transform: none;
      width: 100% !important;
      max-height: 100%;
      overflow-y: scroll; } }

.popup__promo__info {
  width: 800px;
  background-color: #10253d;
  border: 1px solid #00426d;
  border-radius: 5px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.36), inset 0 0 0px rgba(255, 255, 255, 0.21); }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .popup__promo__info {
      width: 568px; } }
  .popup__promo__info .popup__head {
    height: 64px;
    border-bottom: 1px solid #354c68; }
  .popup__promo__info .popup__content {
    background-image: radial-gradient(296px 277px at 51.85% 100.98%, rgba(68, 102, 141, 0.83) 0%, rgba(19, 46, 77, 0.73) 100%);
    min-height: 270px;
    color: #fff;
    padding: 50px 60px;
    font-family: 'ClearSans', sans-serif; }
    @media only screen and (max-width: 767px) {
      .popup__promo__info .popup__content {
        padding: 20px; } }
  @media only screen and (max-width: 767px) {
    .popup__promo__info .popup__content li {
      font-size: 14px;
      line-height: 20px; } }
  .popup__promo__info .popup__footer {
    background: transparent;
    border-top: 1px solid #354c68; }
  .popup__promo__info .popup__close {
    height: 100%;
    background: transparent; }
  .popup__promo__info .icon-close {
    background: url("../img/promo/close.png") no-repeat;
    width: 33px;
    height: 33px;
    cursor: pointer;
    opacity: 0.8; }
  .popup__promo__info .popup__close:hover .icon-close {
    opacity: 1; }
  .popup__promo__info .popup__title {
    text-align: center;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px; }
  .popup__promo__info .popup__promo__info__list {
    margin: 0;
    padding: 0; }
  .popup__promo__info .popup__promo__info__list__item {
    margin: 0;
    padding: 0 0 10px 25px;
    font-family: 'ClearSans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    list-style-type: none;
    position: relative; }
    .popup__promo__info .popup__promo__info__list__item:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #fe0;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      left: 0;
      top: 0;
      margin-top: 8px; }
  .popup__promo__info .button__popup__close {
    display: inline-block;
    width: 100px;
    height: 34px;
    margin: 15px auto;
    background-color: transparent;
    border: 1px solid #3b5469;
    border-radius: 3px;
    color: white;
    font-family: 'ClearSans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase; }
    .popup__promo__info .button__popup__close:hover, .popup__promo__info .button__popup__close:active, .popup__promo__info .button__popup__close:focus {
      background: #0b2238;
      color: #6bb5ff; }

.overlay {
  display: block;
  position: fixed;
  overflow: auto;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5); }

.layout_lottery-promo__popup {
  width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -49%);
  transform: translate(-50%, -49%);
  background: #15355a;
  border: solid 1px #38608e;
  font-family: 'ClearSans',sans-serif;
  padding: 30px 0;
  border-radius: 2px; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .layout_lottery-promo__popup {
      width: 90%; } }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup {
      width: 90%; } }

.icon-close {
  background: url("../img/promo/close.png") no-repeat;
  width: 33px;
  height: 33px;
  cursor: pointer;
  opacity: 0.8;
  position: absolute;
  right: 10px;
  top: 10px; }

.layout_lottery-promo__popup-close:hover .icon-close {
  opacity: 1; }

.layout_lottery-promo__popup-title {
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: 500; }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup-title {
      font-size: 14px; } }

.layout_lottery-promo__popup-content {
  width: 80%;
  margin: auto;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .layout_lottery-promo__popup-content {
      width: 90%; } }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup-content {
      width: 90%;
      text-align: center; } }

.layout_lottery-promo__popup-img {
  z-index: 10;
  position: relative; }

.layout_lottery-promo__popup-info {
  background: #38608e;
  position: absolute;
  top: 40px;
  border-radius: 2px;
  border: solid 1px rgba(255, 255, 255, 0.17);
  margin-left: 150px;
  width: calc(100% - 150px);
  padding: 15px 0px 15px 70px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 28px; }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup-info {
      font-size: 14px;
      line-height: 20px;
      position: relative;
      width: 100%;
      margin: 10px 0 20px 0;
      padding: 10px;
      top: 0; } }

span.layout_lottery-promo__popup-content, span.layout_lottery-promo__popup-subtitle {
  display: inline-block; }

.layout_lottery-promo__popup-subtitle {
  color: #fff001; }

.layout_lottery-promo__popup-action {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px; }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup-action {
      margin-bottom: 0; } }
  .layout_lottery-promo__popup-action a {
    color: #b2b2b2;
    font-size: 14px;
    font-weight: normal;
    margin-left: -100px; }

.layout_lottery-promo__popup .button__outer {
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup .button__outer {
      display: block;
      margin-bottom: 35px; } }

.layout_lottery-promo__popup .registration__button {
  display: inline-block;
  border: none;
  vertical-align: middle;
  padding: 0 10px;
  width: 297px;
  height: 50px;
  margin: 0;
  background-image: linear-gradient(349deg, #fd0834 0%, #fd2a2f 21%, #fd6724 45%, #fd971c 67%, #fda01b 71%, #fda31b 100%);
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
  color: white;
  font-family: 'ClearSans', sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 50px;
  transition: opacity 0.3s ease; }
  .layout_lottery-promo__popup .registration__button:hover {
    background-image: linear-gradient(165deg, #fd0834 0%, #fd2a2f 21%, #fd6724 45%, #fd971c 67%, #fda01b 71%, #fda31b 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4); }
  .layout_lottery-promo__popup .registration__button:active, .layout_lottery-promo__popup .registration__button:focus {
    outline: none;
    background-image: linear-gradient(178deg, #d86512 0%, #8b0c0d 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4); }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup .registration__button {
      width: 212px;
      height: 40px;
      line-height: 40px;
      font-size: 14px; } }

.layout_lottery-promo__popup .registration__text {
  display: block;
  color: #b2b2b2;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  bottom: -24px;
  left: 0;
  width: 100%;
  text-align: center; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .layout_lottery-promo__popup .registration__text {
      font-size: 14px; } }
  @media only screen and (max-width: 767px) {
    .layout_lottery-promo__popup .registration__text {
      font-size: 14px; } }

.layout_lottery-promo__popup .login__button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  box-shadow: none;
  font-family: "ClearSans", sans-serif;
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
  padding: 0;
  margin-left: 10px; }
  .layout_lottery-promo__popup .login__button:hover, .layout_lottery-promo__popup .login__button:focus {
    color: #6bb5ff;
    border-color: #4b6b86;
    outline: none; }
  .layout_lottery-promo__popup .login__button:active {
    border-color: #123c61; }

.lottery-btm-box {
  background-color: #194574;
  border: 1px solid #15355a;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 50; }

.lottery-btm-content {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center; }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .lottery-btm-content {
      width: 80%; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .lottery-btm-content {
      width: 90%; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .lottery-btm-content {
      flex-direction: column;
      width: 90%;
      text-align: center; } }
  @media only screen and (max-width: 767px) {
    .lottery-btm-content {
      flex-direction: column;
      width: 90%;
      text-align: center; } }

.lottery-btm_item {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'ClearSans', sans-serif;
  text-transform: uppercase;
  color: #fff; }
  .lottery-btm_item:first-child {
    margin-right: auto; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-btm_item:first-child {
        margin: auto; } }
    @media only screen and (max-width: 767px) {
      .lottery-btm_item:first-child {
        margin: auto; } }

.lottery-btm_highlight {
  color: #fff001; }

.lottery-btm_item__text {
  display: inline-block; }

.lottery-btm-box .casher__button {
  display: inline-block;
  border: none;
  vertical-align: middle;
  padding: 0 10px;
  width: 204px;
  height: 40px;
  background-image: linear-gradient(349deg, #fd0834 0%, #fd2a2f 21%, #fd6724 45%, #fd971c 67%, #fda01b 71%, #fda31b 100%);
  border-radius: 3px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.21);
  color: white;
  font-family: 'ClearSans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  transition: opacity 0.3s ease;
  margin: 0 0 0 20px; }
  .lottery-btm-box .casher__button:hover {
    background-image: linear-gradient(165deg, #fd0834 0%, #fd2a2f 21%, #fd6724 45%, #fd971c 67%, #fda01b 71%, #fda31b 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4); }
  .lottery-btm-box .casher__button:active, .lottery-btm-box .casher__button:focus {
    outline: none;
    background-image: linear-gradient(178deg, #d86512 0%, #8b0c0d 100%);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16), 0 8px 6px rgba(0, 0, 0, 0.16), inset 1px 1px 0 rgba(255, 255, 255, 0.4); }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .lottery-btm-box .casher__button {
      margin: 10px 0 0 0; } }
  @media only screen and (max-width: 767px) {
    .lottery-btm-box .casher__button {
      margin: 10px 0 0 0; } }

.lottery-info {
  display: block;
  position: relative;
  height: 354px;
  background-color: #3c5675;
  background-image: url("../img/timer-bg.png");
  background-repeat: no-repeat;
  background-position: center top;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 5px 0 rgba(0, 0, 0, 0.12); }
  @media only screen and (max-width: 767px) {
    .lottery-info {
      height: auto; } }
  .lottery-info__title {
    font-size: 22px;
    padding-top: 45px; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-info__title {
        padding: 27px 30px 27px; } }
    .lottery-info__title_important {
      padding-top: 66px;
      font-family: 'ClearSans', sans-serif;
      font-weight: 700;
      text-shadow: 0 3px 0 rgba(0, 0, 0, 0.23); }
    .lottery-info__title_important + .lottery-info__title {
      font-size: 36px;
      padding-top: 17px;
      padding-bottom: 58px;
      text-shadow: 0 3px 0 rgba(0, 0, 0, 0.23); }
  .lottery-info__timer {
    padding-top: 9px;
    padding-bottom: 40px; }
  .lottery-info__info-title {
    padding: 35px 30px 27px;
    display: block;
    margin: 0;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .lottery-info__info-title {
        font-size: 14px;
        padding: 25px 30px 27px; } }
    @media only screen and (max-width: 767px) {
      .lottery-info__info-title {
        padding: 35px 20px 27px; } }
    .lottery-info__info-title:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 1px;
      background-color: #2d425b;
      box-shadow: 0 1px 0 #446184; }

.main_lottery-details img {
  max-width: 100%; }

.section.section_full .section__main-lottery {
  max-width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .section.section_full .section__main-lottery .panel.panel_tournament {
      padding-top: 20px;
      padding-bottom: 0; } }
  .section.section_full .section__main-lottery .main__item {
    width: 870px;
    max-width: calc(100% - 20px);
    background: #00284b;
    border: 1px solid #2a588c;
    border-radius: 3px;
    filter: none; }
    @media only screen and (min-width: 1440px) {
      .section.section_full .section__main-lottery .main__item {
        width: 1380px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .section.section_full .section__main-lottery .main__item {
        width: 1140px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .section.section_full .section__main-lottery .main__item {
        width: 660px; } }
    @media only screen and (max-width: 767px) {
      .section.section_full .section__main-lottery .main__item {
        width: 90%;
        display: block;
        position: relative; } }
    .section.section_full .section__main-lottery .main__item:hover {
      box-shadow: inset 0px 0px 30px 0px #4c9cb9;
      filter: drop-shadow(0 0 10px #002c5b); }
    .section.section_full .section__main-lottery .main__item .tournament_name {
      letter-spacing: 1px;
      font-size: 28px;
      margin-top: 0; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .section.section_full .section__main-lottery .main__item .tournament_name {
          margin-bottom: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .section.section_full .section__main-lottery .main__item .tournament_name {
          margin: 0 0 10px;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; } }
      @media only screen and (max-width: 767px) {
        .section.section_full .section__main-lottery .main__item .tournament_name {
          position: static;
          max-width: 100%;
          margin: 10px 0 -16px;
          font-size: 22px;
          text-align: center; } }
    .section.section_full .section__main-lottery .main__item .tournament_summa {
      color: #366297; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .section.section_full .section__main-lottery .main__item .tournament_summa {
          margin-bottom: 10px; } }
    .section.section_full .section__main-lottery .main__item .countdown span {
      color: #fcc807;
      font-size: 47px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .section.section_full .section__main-lottery .main__item .countdown span {
          font-size: 44px; } }
      @media only screen and (max-width: 767px) {
        .section.section_full .section__main-lottery .main__item .countdown span {
          font-size: 38px; } }
      .section.section_full .section__main-lottery .main__item .countdown span:last-child {
        color: #366297;
        font-size: 50px; }
        @media only screen and (max-width: 767px) {
          .section.section_full .section__main-lottery .main__item .countdown span:last-child {
            font-size: 40px; } }
    .section.section_full .section__main-lottery .main__item .tournament_status .active-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .finished-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .future-tournament__status {
      padding-top: 0;
      padding-bottom: 22px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .section.section_full .section__main-lottery .main__item .tournament_status .active-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .finished-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .future-tournament__status {
          padding-right: 53px;
          padding-bottom: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .section.section_full .section__main-lottery .main__item .tournament_status .active-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .finished-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .future-tournament__status {
          top: 14px; } }
      @media only screen and (max-width: 767px) {
        .section.section_full .section__main-lottery .main__item .tournament_status .active-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .finished-tournament__status, .section.section_full .section__main-lottery .main__item .tournament_status .future-tournament__status {
          top: 20px;
          right: 5px; } }
    .section.section_full .section__main-lottery .main__item .active-tournament__status {
      color: #00e14f; }
    .section.section_full .section__main-lottery .main__item .tournament_status .finished-tournament__status {
      color: #ff3e00; }
    .section.section_full .section__main-lottery .main__item .tournament_description {
      color: #fff;
      text-align: justify; }
    .section.section_full .section__main-lottery .main__item .icon.icon_arrow-right-white {
      background-image: none; }
      .section.section_full .section__main-lottery .main__item .icon.icon_arrow-right-white:after {
        content: '»';
        font-size: 30px;
        font-style: normal;
        position: relative;
        top: -2px; }
    .section.section_full .section__main-lottery .main__item .button {
      background: #135a82;
      border: 0;
      box-shadow: none;
      float: right;
      padding: 10px 20px;
      text-transform: capitalize;
      font-weight: 100;
      text-shadow: none; }
      @media only screen and (max-width: 767px) {
        .section.section_full .section__main-lottery .main__item .button {
          float: none;
          margin-bottom: 0;
          position: absolute;
          right: 30px; } }
  @media only screen and (max-width: 767px) {
    .section.section_full .section__main-lottery .panel__img {
      width: 100%;
      max-width: 100%; } }
  .section.section_full .section__main-lottery .panel {
    display: block; }
    @media only screen and (max-width: 767px) {
      .section.section_full .section__main-lottery .panel {
        padding-bottom: 80px; } }
  .section.section_full .section__main-lottery .lottery__caption_xs {
    font-size: 14px; }
  .section.section_full .section__main-lottery .panel__cell {
    display: inline-block;
    max-width: 100%; }
    @media only screen and (max-width: 767px) {
      .section.section_full .section__main-lottery .panel__cell {
        display: block;
        width: 100%; } }
    .section.section_full .section__main-lottery .panel__cell .panel__overflow {
      display: none; }
    @media only screen and (max-width: 767px) {
      .section.section_full .section__main-lottery .panel__cell .img_overflow {
        width: 100%; } }
    .section.section_full .section__main-lottery .panel__cell:first-child {
      width: 250px; }
      @media only screen and (max-width: 767px) {
        .section.section_full .section__main-lottery .panel__cell:first-child {
          width: 75%; } }
    .section.section_full .section__main-lottery .panel__cell:nth-child(2) {
      width: 330px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .section.section_full .section__main-lottery .panel__cell:nth-child(2) {
          width: 290px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .section.section_full .section__main-lottery .panel__cell:nth-child(2) {
          padding-left: 15px; } }
      @media only screen and (max-width: 767px) {
        .section.section_full .section__main-lottery .panel__cell:nth-child(2) {
          width: 100%; } }
    .section.section_full .section__main-lottery .panel__cell:last-child {
      width: 729px; }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .section.section_full .section__main-lottery .panel__cell:last-child {
          width: 489px; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .section.section_full .section__main-lottery .panel__cell:last-child {
          position: static;
          width: 279px; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .section.section_full .section__main-lottery .panel__cell:last-child {
          padding: 0;
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .section.section_full .section__main-lottery .panel__cell:last-child {
          position: static;
          width: 100%; } }
  .section.section_full .section__main-lottery .tournament_status .active-tournament__status img, .section.section_full .section__main-lottery .tournament_status .finished-tournament__status img, .section.section_full .section__main-lottery .tournament_status .future-tournament__status img {
    top: -3px;
    right: 14px;
    width: 51px; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .section.section_full .section__main-lottery .tournament_status .active-tournament__status img, .section.section_full .section__main-lottery .tournament_status .finished-tournament__status img, .section.section_full .section__main-lottery .tournament_status .future-tournament__status img {
        top: 17px;
        right: 24px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .section.section_full .section__main-lottery .tournament_prises {
      text-align: center; } }
  @media only screen and (max-width: 767px) {
    .section.section_full .section__main-lottery .tournament_prises {
      text-align: center; } }
  .section.section_full .section__main-lottery .panel__icon-cell {
    text-align: left; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .section.section_full .section__main-lottery .panel__icon-cell {
        text-align: center; } }
    @media only screen and (max-width: 767px) {
      .section.section_full .section__main-lottery .panel__icon-cell {
        text-align: center; } }
  .section.section_full .section__main-lottery #prev_lotteries .panel__icon-cell .panel__icon {
    text-align: center;
    margin: 0; }

@media only screen and (max-width: 767px) {
  img.layout_tournament-item__img {
    position: relative;
    height: 130px;
    width: 100%;
    max-width: none; } }

.layout_tournament-item_details {
  color: #fff;
  height: 117px;
  background-image: linear-gradient(90deg, #09203a, #183a63 50%, #09203a);
  width: 1420px;
  margin: -4px auto 0;
  position: relative;
  padding-top: 33px;
  font-size: 18px;
  line-height: 1.5; }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .layout_tournament-item_details {
      width: 1220px; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .layout_tournament-item_details {
      width: 980px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .layout_tournament-item_details {
      width: 750px; } }
  @media only screen and (max-width: 767px) {
    .layout_tournament-item_details {
      max-width: 100%;
      padding: 15px 10px;
      height: initial; } }
  .layout_tournament-item_details:after {
    content: '';
    width: 100%;
    height: 1px;
    background-image: linear-gradient(90deg, #001a3b 0%, #1f7ebe 50%, #001a3b 100%);
    display: block;
    position: absolute;
    bottom: 0; }

.tournament-item_gamer-status {
  color: #fff000; }
  .tournament-item_gamer-status span {
    color: #fff; }

.lottery-details_yellow-big-txt {
  color: #fff000;
  font-size: 1.3em;
  font-weight: 900; }

.lottery-details_block {
  background-color: #00284b;
  border: 1px solid #2d517d;
  border-radius: 3px;
  width: 1420px;
  margin: 0 auto 20px;
  padding: 20px 50px 35px;
  color: #fff; }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .lottery-details_block {
      width: 1220px; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .lottery-details_block {
      width: 980px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .lottery-details_block {
      width: 750px; } }
  @media only screen and (max-width: 767px) {
    .lottery-details_block {
      width: 90%;
      min-width: 310px;
      padding: 10px 15px; } }
  @media only screen and (min-width: 1240px) and (max-width: 1439px) {
    .lottery-details_block-slider {
      width: 1100px;
      margin: 0 auto; } }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .lottery-details_block-slider {
      width: 880px;
      margin: 0 auto; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .lottery-details_block-slider {
      width: 350px;
      margin: 0 auto; } }
  @media only screen and (max-width: 767px) {
    .lottery-details_block-slider {
      max-width: 350px;
      width: 90%;
      min-width: 200px;
      margin: 0 auto; } }
  .lottery-details_block-slider_slide {
    text-align: right;
    padding: 0 0 0 15px;
    position: relative; }
    .lottery-details_block-slider_slide p {
      text-align: left;
      width: 200px;
      padding-left: 10px;
      color: #fff000;
      border-left: 1px solid;
      height: 46px;
      overflow: hidden; }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .lottery-details_block-slider_slide p {
          border: 0;
          width: initial; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details_block-slider_slide p {
          border: 0;
          width: initial; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details_block-slider_slide p {
          border: 0;
          width: initial; } }
      @media only screen and (max-width: 767px) {
        .lottery-details_block-slider_slide p {
          width: 100%;
          max-width: 100%;
          padding: 0;
          border: 0; } }
    .lottery-details_block-slider_slide span {
      position: absolute;
      top: calc(50% - 40px);
      font-size: 80px;
      color: #fff000;
      font-weight: 900;
      text-shadow: 0 0 7px #000;
      right: 55%; }
      @media only screen and (min-width: 1240px) and (max-width: 1439px) {
        .lottery-details_block-slider_slide span {
          font-size: 65px;
          right: 50%; } }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .lottery-details_block-slider_slide span {
          font-size: 50px;
          right: 51%; } }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .lottery-details_block-slider_slide span {
          font-size: 65px; } }
      @media only screen and (max-width: 767px) {
        .lottery-details_block-slider_slide span {
          font-size: 50px; } }
    .lottery-details_block-slider_slide img {
      display: inline;
      max-width: 65%; }
  .lottery-details_block .slick-next {
    right: -40px;
    height: 71px;
    width: 37px; }
    .lottery-details_block .slick-next:before {
      content: url(../img/grand-lottery-arrow-r.png);
      height: 71px;
      width: 37px;
      display: block; }
  .lottery-details_block .slick-prev {
    left: -40px;
    height: 71px;
    width: 37px; }
    .lottery-details_block .slick-prev:before {
      content: url(../img/grand-lottery-arrow-l.png);
      height: 71px;
      width: 37px;
      display: block; }
  .lottery-details_block-header {
    font-size: 24px;
    color: #fff000;
    font-weight: 700;
    line-height: 1.993;
    position: relative;
    z-index: 9;
    background: #00284b;
    width: 300px;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .lottery-details_block-header {
        width: 100%;
        line-height: 1.2; } }
  .lottery-details_block-subheader {
    color: #1f7ebe;
    font-size: 18px;
    border-bottom: 1px solid #1f7ebe;
    line-height: 4;
    display: inline-block;
    padding: 0 20px;
    margin-top: -25px;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .lottery-details_block-subheader {
        border-top: 0;
        margin-top: 0;
        line-height: 1.2;
        padding: 10px 0; } }

.lottery-details_more {
  background: #091b2f;
  margin-top: 25px;
  padding: 15px 10px 15px;
  border: 1px solid #506885;
  border-radius: 3px; }

.lottery-details_more-header {
  border: 1px solid #1f7ebe;
  background-color: #122d4c;
  width: 184px;
  margin: -25px auto 0;
  border-radius: 3px;
  font-weight: 100;
  color: #1f7ebe;
  padding: 12px 0;
  position: relative;
  top: 25px; }

.lottery-details_popup-button {
  margin-top: 35px;
  display: inline-block;
  text-decoration: underline; }

.popup.lottery-res-popup {
  background: url(../img/grand-lottery-popup-bg.png) 50% -1px no-repeat;
  height: auto;
  width: 686px;
  border: 1px solid #2d517d;
  border-radius: 3px;
  padding-bottom: 50px;
  color: #fff;
  font-weight: 100; }
  .popup.lottery-res-popup .popup__close {
    background: transparent;
    box-shadow: none; }
  .popup.lottery-res-popup h2 {
    color: #fff000;
    text-align: center; }
  .popup.lottery-res-popup table {
    width: 90%;
    margin: 0 auto; }
  .popup.lottery-res-popup thead {
    background: #1a406d; }
    .popup.lottery-res-popup thead td {
      padding: 15px 10px; }
  .popup.lottery-res-popup tbody tr {
    background: #1a406d; }
    .popup.lottery-res-popup tbody tr:nth-child(odd) {
      background: #1e4c81; }
  .popup.lottery-res-popup td {
    border: 0;
    padding: 10px; }

.table-panel {
  display: block;
  position: relative;
  background-color: #2f4b6c;
  border-radius: 2px;
  margin: 19px 1px;
  padding: 0 19px;
  max-height: 396px; }
  @media only screen and (max-width: 767px) {
    .table-panel {
      max-height: none; } }
  .table-panel__title {
    display: block;
    position: relative;
    text-align: left;
    font-size: 16px;
    padding: 12px 10px 30px 6px; }
    .table-panel__title_float_left {
      float: left;
      padding: 12px 0 30px 0; }
    .table-panel__title_float_right {
      float: right;
      padding: 12px 0 30px 0; }
    .table-panel__title_accent {
      padding: 0 10px 6px 6px; }
  .table-panel__table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    position: relative;
    text-align: left; }
  .table-panel__cell {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 14px; }
    .table-panel__cell:last-child {
      text-align: right; }
    thead .table-panel__row .table-panel__cell {
      padding: 10px 14px; }
    tbody .table-panel__row:nth-child(odd) .table-panel__cell {
      background-color: #406289;
      padding: 6px 14px; }

.winnings {
  display: block;
  position: relative;
  text-align: center;
  height: 179px;
  background-color: #2f4b6c;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18), 0 4px 0 rgba(4, 4, 4, 0.12); }
  .winnings__title {
    text-align: left;
    display: block;
    position: relative;
    padding: 19px;
    font-size: 17px; }
    .winnings__title:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #213752;
      box-shadow: 0 1px 0 #37567a; }
  .winnings__slider {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 890px;
    margin-top: 20px;
    text-align: left; }
    .winnings__slider .title {
      font-size: 15px; }

html, body {
  height: 100%; }

@media only screen and (min-width: 768px) and (max-width: 999px) {
  body {
    min-width: 320px !important; } }

@media only screen and (max-width: 767px) {
  body {
    min-width: 320px !important; } }

.game_bg {
  background-image: url("../img/gameplay-bg.jpg");
  background-repeat: no-repeat;
  background-position: center 0; }
  @media only screen and (max-width: 767px) {
    .game_bg {
      background-image: none; } }

.gameplay_over {
  display: table;
  height: 100%;
  width: 100%; }

.gameplay {
  display: table-cell;
  vertical-align: middle;
  padding: 68px 15px 68px 15px;
  min-width: 300px; }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .gameplay {
      padding: 48px 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .gameplay {
      padding: 48px 15px; } }
  @media only screen and (max-width: 767px) {
    .gameplay {
      padding: 48px 15px 0; } }
  .gameplay__shadow {
    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.5);
    max-width: 1506px;
    margin: 0 auto; }
  .gameplay__wrap {
    display: block;
    position: relative;
    max-width: 1506px;
    height: calc(100vh - 156px);
    min-height: 600px;
    max-height: 720px;
    margin: 0 auto;
    padding: 0 280px; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .gameplay__wrap {
        padding: 0 280px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay__wrap {
        padding: 0 220px;
        height: calc(100vh - 156px); } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gameplay__wrap {
        padding: 0;
        display: table;
        width: 100%;
        height: auto;
        min-height: 0; } }
    @media only screen and (max-width: 767px) {
      .gameplay__wrap {
        padding: 0;
        display: table;
        width: 100%;
        height: auto;
        min-height: 0; } }
  .gameplay__logo {
    display: block;
    height: 60px;
    background-image: radial-gradient(circle at 53% 105%, #0c213b, #0d223b 14%, #11263e 35%, #192d44 76%, #26384e);
    box-shadow: 4px 0 5px 0 rgba(0, 0, 0, 0.11);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(151deg, rgba(255, 255, 255, 0.25), #344559);
    border-image-slice: 1;
    position: relative;
    z-index: 1;
    margin: -1px -1px 24px -1px; }
  .gameplay__logo-inner {
    display: block;
    position: absolute;
    bottom: -19px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay__logo-inner {
        max-width: 200px;
        bottom: -18px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gameplay__logo-inner {
        max-width: 180px;
        bottom: -12px; } }
    @media only screen and (max-width: 767px) {
      .gameplay__logo-inner {
        max-width: 160px;
        bottom: -9px; } }
  .gameplay__left {
    display: block;
    float: left;
    position: relative;
    vertical-align: top;
    height: calc(100vh - 156px);
    max-height: 720px;
    min-height: 600px;
    padding-top: 60px;
    background-color: #00284b;
    width: 280px;
    margin-left: -280px; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .gameplay__left {
        width: 280px;
        margin-left: -280px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay__left {
        width: 220px;
        margin-left: -220px;
        height: calc(100vh - 156px); } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gameplay__left {
        width: 340px;
        margin: 25px auto;
        float: none;
        padding-bottom: 15px;
        padding-top: 0;
        min-height: 0;
        height: auto; } }
    @media only screen and (max-width: 767px) {
      .gameplay__left {
        width: 284px;
        margin: 15px auto;
        float: none;
        padding-bottom: 15px;
        overflow: hidden;
        padding-top: 0;
        min-height: 0;
        height: auto; }
        .gameplay__left .rating__tooltip {
          right: 74px; }
        .gameplay__left .tooltip__arrow {
          left: 90%; } }
  .gameplay__user {
    display: block;
    position: relative;
    height: 100%; }
  .gameplay__main {
    display: block;
    float: left;
    position: relative;
    width: 100%;
    height: 100%;
    vertical-align: top;
    background-color: #050d17; }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gameplay__main {
        float: none;
        display: table-header-group; } }
    @media only screen and (max-width: 767px) {
      .gameplay__main {
        float: none;
        display: table-header-group; } }
  .gameplay__aside {
    display: block;
    float: left;
    position: relative;
    vertical-align: top;
    height: calc(100vh - 156px);
    max-height: 720px;
    background-color: #00284b;
    width: 280px;
    margin-right: -280px;
    min-height: 600px; }
    .gameplay__aside_overflow {
      overflow: hidden; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .gameplay__aside_overflow {
          overflow: visible; } }
      @media only screen and (max-width: 767px) {
        .gameplay__aside_overflow {
          overflow: visible;
          max-height: none; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .gameplay__aside {
        width: 280px;
        margin-right: -280px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay__aside {
        width: 220px;
        margin-right: -220px;
        height: calc(100vh - 156px); } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gameplay__aside {
        width: 340px;
        float: none;
        margin: 25px auto;
        height: auto;
        min-height: 0; } }
    @media only screen and (max-width: 767px) {
      .gameplay__aside {
        width: 284px;
        float: none;
        margin: 15px auto;
        height: auto;
        min-height: 0; } }
    .gameplay__aside .aside__fade {
      position: absolute;
      bottom: 0;
      height: 20px;
      background-image: linear-gradient(to bottom, rgba(20, 53, 90, 0), #15355a);
      width: 100%; }
    .gameplay__aside_small {
      width: 300px; }
    .gameplay__aside .game-tabs_tab {
      display: none; }
      .gameplay__aside .game-tabs_tab.active {
        display: block; }
    .gameplay__aside .gamepage-tabs {
      width: 50%;
      display: inline-block;
      background-color: #07182b; }
      .gameplay__aside .gamepage-tabs.active {
        background-color: #00284b; }
      .gameplay__aside .gamepage-tabs object {
        width: 41px;
        margin-top: 5px; }
    .gameplay__aside .lottery-name {
      color: #ffd801;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 10px; }
    .gameplay__aside #tab-lottery .tournament__img-overlay {
      height: auto; }
  .gameplay__wrap_head {
    display: block;
    position: relative;
    max-width: 1506px;
    margin: 0 auto;
    padding: 0 280px;
    height: 60px; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .gameplay__wrap_head {
        padding: 0 280px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay__wrap_head {
        padding: 0 220px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gameplay__wrap_head {
        padding: 0 0 0 200px;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .gameplay__wrap_head {
        padding: 0 0 0 180px;
        margin-bottom: 20px; } }
    .gameplay__wrap_head .gameplay__left {
      height: 100%;
      padding-top: 0;
      min-height: 0; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .gameplay__wrap_head .gameplay__left {
          width: 200px;
          margin: 0 0 0 -200px;
          float: left; } }
      @media only screen and (max-width: 767px) {
        .gameplay__wrap_head .gameplay__left {
          width: 180px;
          margin: 0 0 0 -180px;
          float: left;
          overflow: visible; } }
    .gameplay__wrap_head .gameplay__main {
      height: 100%;
      min-height: 0; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .gameplay__wrap_head .gameplay__main {
          float: left; } }
      @media only screen and (max-width: 767px) {
        .gameplay__wrap_head .gameplay__main {
          float: left; } }
    .gameplay__wrap_head .gameplay__aside {
      height: 100%;
      min-height: 0;
      background-color: #07182b; }
  .gameplay__nav {
    padding: 11px 14px 0 16px;
    height: 60px;
    background-color: #00284b;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11);
    font-family: 'ClearSans',sans-serif; }
    @media only screen and (max-width: 767px) {
      .gameplay__nav {
        text-align: right; } }
    .gameplay__nav i.fa {
      color: #ffffff;
      display: none;
      margin-left: 5px; }
      @media only screen and (max-width: 767px) {
        .gameplay__nav i.fa {
          display: inline-block; } }
  .gameplay__canvas {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
    background-color: #00284b;
    max-width: 980px;
    overflow: hidden; }
    @media screen and (max-height: 708px) {
      .gameplay__canvas .gameplay__box {
        max-width: 664px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .gameplay__canvas {
        height: auto; } }
    @media only screen and (max-width: 767px) {
      .gameplay__canvas {
        height: auto; } }
    .gameplay__canvas .game_param {
      width: 100%;
      position: absolute;
      max-width: 980px;
      opacity: 0;
      height: auto;
      max-height: calc(100% - 56px);
      z-index: -1; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .gameplay__canvas .game_param {
          position: relative; } }
      @media only screen and (max-width: 767px) {
        .gameplay__canvas .game_param {
          position: relative; } }
    .gameplay__canvas .gameplay__canvas_inner {
      background-color: #000;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      position: absolute; }
      .gameplay__canvas .gameplay__canvas_inner object {
        margin: 0 auto; }
    .gameplay__canvas .gameplay__box {
      margin: 0 auto;
      display: block;
      position: relative;
      width: 100%; }
    .gameplay__canvas .gameplay__box:before {
      content: "";
      display: block;
      padding-top: 75%; }
    .gameplay__canvas .gameplay__box_content {
      position: absolute;
      top: 5px;
      left: 0;
      bottom: 5px;
      right: 0;
      background: black; }
  @media screen and (max-height: 890px) and (min-width: 1400px) {
    .gameplay .gameplay__box {
      max-width: 670px; } }
  .gameplay__slider {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    padding: 9px 30px 0;
    height: 116px;
    opacity: 0;
    background-color: #00284b;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15), 0 3px 3px rgba(0, 0, 0, 0.9); }
    @media only screen and (max-width: 767px) {
      .gameplay__slider {
        display: none; } }
    .gameplay__slider_open {
      opacity: 1;
      z-index: 1; }
    .gameplay__slider .slider__img {
      width: 100%; }
  .gameplay__gallery {
    display: block;
    position: relative;
    height: calc(100% - 88px);
    background-color: #15355a;
    border: 1px solid #254b78; }
  .gameplay .rating__summary {
    white-space: normal; }
    .gameplay .rating__summary .rating__icon.fa {
      font-size: 13px;
      width: 13px;
      height: 15px; }
    .gameplay .rating__summary .rating__icon {
      font-size: 13px;
      width: 13px;
      height: 15px; }

.hide_small {
  display: block; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .hide_small {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .hide_small {
      display: none; } }

.hide_big {
  display: none; }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .hide_big {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .hide_big {
      display: block; } }

.gameplay_small {
  min-width: 1506px;
  background-image: url("../img/gameplaysmall-bg.jpg");
  background-position: center top; }
  .gameplay_small .gameplay__aside {
    width: 300px; }
  .gameplay_small .gameplay__wrap {
    width: 1506px;
    padding-right: 47px; }
  .gameplay_small .gameplay-toppanel {
    padding: 21px 32px; }
  .gameplay_small .gameplay-toppanel__title {
    font-size: 20px;
    color: #fff; }

.gameplay-toppanel {
  display: table;
  width: 100%;
  position: relative;
  background-image: url("../img/gameplayaside-bg.jpg");
  height: 60px;
  margin: 0 0 0 -1px;
  font-size: 0;
  display: flex; }
  .gameplay-toppanel_small {
    background-image: url("../img/gameplayasidesmall-bg.jpg"); }
  .gameplay-toppanel__icon {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    width: 1px;
    padding-left: 10px; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay-toppanel__icon {
        padding-left: 0px; } }
    .gameplay-toppanel__icon .lottery__icon {
      width: 45px;
      height: 54px;
      padding-top: 2px; }
      @media only screen and (min-width: 1000px) and (max-width: 1239px) {
        .gameplay-toppanel__icon .lottery__icon {
          margin: 0px 3px; } }
  .gameplay-toppanel__summary {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    padding-left: 9px; }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay-toppanel__summary {
        padding-left: 1px; } }
  .gameplay-toppanel__caption {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .gameplay-toppanel__caption {
        font-size: 12px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay-toppanel__caption {
        font-size: 11px; } }
  .gameplay-toppanel__title {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.23);
    color: #fff001;
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    text-transform: uppercase;
    margin: 0;
    text-overflow: ellipsis; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .gameplay-toppanel__title {
        font-size: 12px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .gameplay-toppanel__title {
        font-size: 12px; } }
  .gameplay-toppanel__tooltip.tooltip {
    display: none;
    right: -2px;
    top: 60px; }
  .gameplay-toppanel__info {
    display: table-cell;
    position: relative;
    width: 1px;
    vertical-align: middle;
    cursor: pointer;
    padding: 0 10px; }
    .gameplay-toppanel__info:hover .tooltip {
      display: block; }

.gameplay-user {
  display: block;
  position: relative; }
  .gameplay-user__title {
    position: relative;
    display: block;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.26);
    color: #d7e6f8;
    font-family: 'ClearSans', sans-serif;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    margin: 0;
    padding: 26px 10px 31px 10px; }
    .gameplay-user__title:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #112c4c;
      box-shadow: 0 1px 0 #193b63; }
  .gameplay-user__vip {
    display: block;
    position: relative;
    padding: 14px 24px 38px 26px; }
    .gameplay-user__vip:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #112c4c;
      box-shadow: 0 1px 0 #193b63; }
  .gameplay-user__bonus {
    display: block;
    position: relative;
    padding: 25px 24px 43px 26px; }
    .gameplay-user__bonus:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #112c4c;
      box-shadow: 0 1px 0 #193b63; }
  .gameplay-user__action {
    text-align: center;
    padding: 38px 0 0 0; }
    .gameplay-user__vip + .gameplay-user__action {
      padding: 19px 0 0; }
  .gameplay-user__button {
    width: 218px;
    height: 44px;
    background-image: linear-gradient(180deg, #ff8d00, #e2d533);
    text-shadow: 1px 1px 4px #3a0404;
    color: #fefefe;
    font-size: 16px;
    font-weight: 700;
    font-family: 'ClearSans', sans-serif;
    padding: 11px 0 12px;
    margin-bottom: 18px;
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.13), inset 0 1px 0 #d7e610, 0 2px 0 rgba(0, 0, 0, 0.11); }
    .gameplay-user__button:last-child {
      margin-bottom: 0; }
    .gameplay-user__button .fa {
      color: #ffffff;
      margin: 0; }
    .gameplay-user__button_orange {
      background-color: #fd7f21;
      background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .gameplay-user__button_blue {
      background-color: #3c8fd7;
      background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .gameplay-user__button_darkblue {
      background-color: #2c5380;
      background-image: linear-gradient(180deg, #3b5777 0%, #215187 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .gameplay-user__button_active {
      background-color: #7abbee;
      background-image: linear-gradient(180deg, #4ea8db 0%, #a2cdff 100%); }
  .gameplay-user__button-text {
    display: inline-block;
    vertical-align: top;
    line-height: 22px;
    margin-left: 13px; }
    .gameplay-user__button-text * {
      color: #ffffff; }

.hide_big_nav {
  display: none;
  color: #ffffff;
  font-weight: 700;
  position: relative;
  min-width: 86px;
  padding-top: 8px; }
  @media only screen and (max-width: 767px) {
    .hide_big_nav {
      display: inline-block; } }

.gameplay-nav-small {
  position: absolute;
  right: -10px;
  z-index: 1;
  display: block;
  width: 120px;
  margin: 0;
  padding: 0;
  background-color: #153358;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5); }
  .gameplay-nav-small.hide {
    display: none; }
  .gameplay-nav-small__item {
    display: block;
    vertical-align: top;
    position: relative;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 16px;
    cursor: pointer; }

.gameplay-nav {
  display: table;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .gameplay-nav {
      display: none; } }
  .gameplay-nav__item {
    display: table-cell;
    vertical-align: top;
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 16px;
    cursor: pointer; }
    .gameplay-nav__item a {
      color: #fff;
      text-decoration: none; }
    .gameplay-nav__item_active {
      color: #fff000; }
      .gameplay-nav__item_active:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -2px;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        background-image: url(../img/sprite.png?v=2);
        background-position: -852px -611px;
        width: 16px;
        height: 11px; }
    .gameplay-nav__item:before {
      content: '-';
      color: #fff;
      display: block;
      position: absolute;
      top: 50%;
      left: 100%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .gameplay-nav__item:last-child:before {
      content: none; }

.gameplay-signup {
  display: block;
  position: relative;
  text-align: center;
  padding: 0; }
  .gameplay-signup__title {
    font-size: 20px;
    font-weight: 500;
    padding-top: 9px;
    line-height: 23px; }
    .gameplay-signup__title .gold {
      color: #f2e419; }
    .gameplay-signup__title + .gameplay-signup__title {
      padding-top: 0; }
    .gameplay-signup__title_huge {
      font-size: 25px;
      font-weight: 700;
      padding-top: 35px; }
    .gameplay-signup__title_signin {
      margin-top: 20px; }
  .gameplay-signup__jackpot {
    margin-top: 25px; }
  .gameplay-signup__input {
    margin-top: 37px; }
    .gameplay-signup__input .input__inner {
      width: 100%;
      height: 54px;
      background-color: #0e2744;
      border-radius: 2px;
      box-shadow: inset 0 1px 0 #0d2035, 0 1px 0 #1c4371;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-family: "ClearSans", sans-serif; }
    .gameplay-signup__input + .gameplay-signup__input {
      margin-top: 12px; }
  .gameplay-signup__button {
    margin-top: 33px;
    padding: 10px 20px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
    color: #fefefe;
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
    font-family: "ClearSans", sans-serif;
    background-color: #fd7123;
    background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .gameplay-signup__button_signin {
      margin-top: 46px;
      width: 219px;
      padding: 10px 24px; }
      .gameplay-signup__button_signin + .gameplay-signup__button_signin {
        margin-top: 19px;
        background-color: #3687d6;
        background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
  .gameplay-signup .gameplay-user__button {
    width: 194px; }
  .gameplay-signup__caption {
    font-size: 16px;
    margin-top: 25px; }
    .gameplay-signup__button_signin + .gameplay-signup__caption {
      margin-top: 55px; }
  .gameplay-signup__socials {
    margin-top: 20px; }

.gameplay-gallery {
  list-style: none;
  margin: 0;
  padding: 27px 27px 10px; }
  .gameplay-gallery__item {
    margin-bottom: 25px; }
    .gameplay-gallery__item:last-child {
      margin-bottom: 0; }
  .gameplay-gallery .preview {
    background: none;
    box-shadow: none; }
  .gameplay-gallery .preview__info {
    padding: 0; }
  .gameplay-gallery .preview__title {
    text-shadow: 0 1px 0 #111c28;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    padding-top: 13px; }
  .gameplay-gallery .preview__button.button_color_orange {
    margin-top: 35px; }

.gameplay-panel {
  display: block;
  position: relative;
  width: auto;
  border-radius: 6px;
  background-color: #132e4d;
  box-shadow: 0 1px 0 0 #131221, inset 0 -1px 0 0 #132e4d; }
  .gameplay-panel__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .gameplay-panel__head {
    margin-bottom: 15px; }
  .gameplay-panel__head:after {
    display: none; }
  .gameplay-panel__title {
    text-align: center;
    padding: 37px 40px 7px;
    text-shadow: 0 2px 1px rgba(9, 32, 58, 0.83);
    text-transform: uppercase;
    color: #fff000;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px; }
    @media only screen and (max-width: 767px) {
      .gameplay-panel__title {
        font-size: 13px;
        line-height: 16px;
        padding: 20px 16px 8px; } }
  .gameplay-panel__subtitle {
    display: block;
    color: #fff;
    text-transform: none;
    text-align: center;
    padding: 0 10px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    max-width: 590px;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .gameplay-panel__subtitle {
        font-size: 12px;
        line-height: 16px;
        padding: 0 30px; } }
  .gameplay-panel__note {
    display: inline-block;
    vertical-align: top;
    margin: 0 6px;
    color: #fff001; }
  .gameplay-panel__content {
    text-align: center;
    margin-top: 10px; }
  .gameplay-panel__input {
    height: 37px; }
    @media only screen and (max-width: 767px) {
      .gameplay-panel__input {
        margin-bottom: 10px; } }
    .gameplay-panel__input .input__inner {
      width: 192px;
      color: #7a7a7a;
      height: 37px;
      box-shadow: 0 1px 1px rgba(43, 42, 70, 0.46), inset 0 1px 1px rgba(0, 0, 0, 0.79);
      border-radius: 2px;
      background-color: #09203a; }
      @media only screen and (max-width: 767px) {
        .gameplay-panel__input .input__inner {
          width: 200px; } }
  .gameplay-panel__button {
    min-width: 70px;
    height: 37px;
    margin-top: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    text-shadow: 0 1px 0 #be2f21; }
    .gameplay-panel__button_wide {
      min-width: 202px;
      padding: 10px 14px; }
    .gameplay-panel__button_footer {
      display: block;
      margin: 0 12px;
      background: transparent;
      border: solid 1px rgba(109, 108, 146, 0.65);
      box-shadow: none;
      text-transform: none;
      font-size: 16px;
      font-weight: 400;
      color: #cdccde;
      text-shadow: none;
      border-radius: 6px;
      height: 36px;
      line-height: 34px;
      padding: 0 20px; }
      @media only screen and (max-width: 767px) {
        .gameplay-panel__button_footer {
          font-size: 13px;
          padding: 0 10px;
          margin: 0 6px; } }
  .gameplay-panel__bonus-info {
    display: block;
    position: relative;
    margin: 14px auto 18px;
    max-width: 380px; }
    @media only screen and (max-width: 767px) {
      .gameplay-panel__bonus-info {
        max-width: 216px; } }
  .gameplay-panel__bonus-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top; }
  .gameplay-panel__bonus-note {
    display: block;
    vertical-align: top;
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 400;
    padding-left: 30px;
    line-height: 13px; }
    @media only screen and (max-width: 767px) {
      .gameplay-panel__bonus-note {
        font-size: 12px; } }
  .gameplay-panel__link {
    display: block;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.38);
    text-decoration: underline;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    color: #7a7a7a;
    margin: 0 12px; }
    @media only screen and (max-width: 767px) {
      .gameplay-panel__link {
        font-size: 13px;
        margin: 0 6px; } }
  .gameplay-panel__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 56px;
    background-color: #09203a;
    box-shadow: 0 1px 0 0 #131221, inset 0 -1px 0 0 #132e4d; }

.jackpot {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  position: relative;
  padding-top: 19px; }
  .jackpot__base {
    display: block;
    position: relative;
    width: 204px;
    height: 63px;
    padding: 7px 10px;
    background-color: #102843;
    border: 6px solid #7ac4ff;
    border-radius: 31px;
    box-shadow: 0 5px 8px rgba(14, 34, 57, 0.93);
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    color: #d7e6f8;
    z-index: 3;
    letter-spacing: -3px; }
  .jackpot__back {
    width: 75px;
    height: 75px;
    background-color: #102843;
    border: 6px solid #fd8a1f;
    border-radius: 50%;
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2; }
  .jackpot__overlay {
    display: block;
    position: absolute;
    width: 100%;
    top: 11px;
    z-index: 1;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
    .jackpot__overlay:before {
      content: '';
      width: 72px;
      height: 75px;
      background-color: #102843;
      border: 6px solid #fd8a1f;
      border-radius: 50%;
      position: absolute;
      display: block;
      top: -4px;
      left: 34px;
      z-index: 1; }
    .jackpot__overlay:after {
      content: '';
      width: 72px;
      height: 75px;
      background-color: #102843;
      border: 6px solid #fd8a1f;
      border-radius: 50%;
      position: absolute;
      display: block;
      top: -4px;
      right: 34px;
      z-index: 1; }

.vipclub__header {
  display: block;
  position: relative;
  height: 133px;
  background-image: linear-gradient(230deg, rgba(255, 255, 255, 0.2) 0%, transparent 15%), linear-gradient(-230deg, #324c69 0%, #132e4d 15%);
  border-radius: 6px 6px 0 0;
  box-shadow: inset -1px 0 0 0 #38608e,inset 1px 0 0 0 #355d8c; }

.vipclub__content {
  display: block;
  position: relative;
  background-color: #15355a;
  border-radius: 3px;
  padding: 0 0 137px 0; }
  .vipclub__content:before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background-color: #0d2239;
    box-shadow: 0 1px 0 #1a3a5e;
    width: 100%;
    top: 0;
    left: 0;
    right: 0; }

.vipclub__row {
  display: block;
  position: relative;
  font-size: 0;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .vipclub__row {
      padding-bottom: 0 !important; } }

.vipclub__item {
  display: inline-block;
  vertical-align: top;
  margin: 20px 58px;
  cursor: pointer; }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .vipclub__item {
      margin: 20px 47px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .vipclub__item {
      margin: 20px 10px; } }
  @media only screen and (max-width: 767px) {
    .vipclub__item {
      margin: 20px auto;
      display: block;
      width: 209px; } }

.vipclub__info {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: left;
  width: 100%;
  max-width: 862px;
  margin: 0 auto;
  background-color: #285282;
  border: 1px solid #31639d;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.22);
  padding: 29px;
  display: none; }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .vipclub__info {
      max-width: 815px; } }
  @media only screen and (min-width: 768px) and (max-width: 999px) {
    .vipclub__info {
      max-width: 815px; } }
  @media only screen and (max-width: 767px) {
    .vipclub__info {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      width: auto;
      margin: 0 20px; } }
  .vipclub__info_open {
    display: block; }

.vipclub__subtitle {
  font-size: 16px;
  line-height: 18px; }

.vipclub__caption {
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-family: "ClearSans", sans-serif; }

.vipclub__bullet-list {
  margin: 36px 0; }

.vipclub__title {
  font-size: 40px;
  line-height: 22px;
  text-align: center;
  padding: 55px;
  font-family: 'ClearSans', sans-serif; }
  @media only screen and (min-width: 1000px) and (max-width: 1239px) {
    .vipclub__title {
      font-size: 40px; } }

.vipclub__note {
  margin: 0;
  text-align: center;
  padding: 62px 135px 10px;
  color: #fff;
  font-family: 'ClearSans', sans-serif;
  font-size: 16px;
  line-height: 20px; }
  @media only screen and (max-width: 767px) {
    .vipclub__note {
      padding: 20px; } }

.vipclub__arrow {
  display: block;
  position: absolute;
  z-index: 3;
  top: 1px;
  left: 50%;
  bottom: 100%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
  .vipclub__arrow:after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 50%;
    z-index: 3;
    margin-left: -14px;
    border-style: solid;
    border-width: 14px;
    border-color: transparent transparent #285282 transparent; }
  .vipclub__arrow_right {
    right: 45px;
    left: auto; }
    .vipclub__arrow_right:after {
      right: 45px;
      left: auto; }
    @media only screen and (max-width: 767px) {
      .vipclub__arrow_right {
        left: 50%;
        right: auto; }
        .vipclub__arrow_right:after {
          right: auto;
          left: 50%; } }
  .vipclub__arrow_left {
    left: 98px;
    right: auto; }
    .vipclub__arrow_left:after {
      right: auto;
      left: auto; }
    @media only screen and (max-width: 767px) {
      .vipclub__arrow_left {
        left: 50%;
        right: auto; }
        .vipclub__arrow_left:after {
          right: auto;
          left: 50%; } }

.vip-panel {
  display: block;
  position: relative;
  width: 209px;
  height: 244px;
  border-radius: 3px;
  background-color: #214875;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #31557f; }
  .vip-panel:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 44px;
    background-color: #1f426b;
    box-shadow: 0 -2px 0 0 #284f7c, 0 -1px 0 0 #193b61; }
  .vip-panel:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #193b61;
    box-shadow: 0 -1px 0 #284f7c; }
  .vip-panel__badge {
    display: block;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    width: 38px;
    height: 38px;
    top: -12px;
    right: -14px;
    background-color: #3b8ed7;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 2px 7px 3px rgba(0, 0, 0, 0.26);
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 23px;
    line-height: 38px;
    font-weight: 700;
    text-transform: uppercase; }
  .vip-panel__button {
    display: block;
    position: absolute;
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 42px;
    border-radius: 22px;
    bottom: 26px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    font-family: "ClearSans", sans-serif;
    padding: 10px 10px;
    line-height: 20px;
    outline: none;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 1px 0 #2765a8; }

.tab-vip .tab__content > div.active {
  width: 100%;
  height: 252px;
  display: table !important; }

.jackpot-details {
  display: block;
  position: relative; }
  .jackpot-details__header {
    background-color: #15355a;
    padding: 0 44px 60px;
    background-image: url("../img/vip-details-bg.jpg");
    background-repeat: no-repeat;
    background-position: center top; }
  .jackpot-details__countdown {
    display: block;
    position: relative;
    text-align: center;
    padding-top: 86px; }
  .jackpot-details__title {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    font-family: 'ClearSans', sans-serif;
    margin: 75px auto 60px; }
  .jackpot-details__subtitle {
    text-shadow: 0 2px 0 #000;
    font-size: 22px;
    text-align: center;
    margin-top: 56px; }
  .jackpot-details__caption {
    font-family: 'ClearSans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    margin: 0 0 36px 0; }
    .jackpot-details__caption:last-child {
      margin: 0; }
    .jackpot-details__caption_accent {
      color: #fff000; }
  .jackpot-details__gallery {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 42px; }

.fortune-levels {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  width: 100%;
  background-color: #4d4b63; }
  .fortune-levels__head {
    background-color: #58566d; }
  .fortune-levels__headCell {
    position: relative;
    text-align: center;
    min-width: 116px;
    height: 60px; }
    .fortune-levels__headCell:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #4e4c61;
      box-shadow: 1px 0 0 #656378; }
    .fortune-levels__headCell_fluid {
      text-align: left;
      padding: 0 27px; }
      .fortune-levels__headCell_fluid:after {
        content: none; }
  .fortune-levels__cell {
    display: table-cell;
    position: relative;
    vertical-align: top;
    min-width: 116px;
    text-align: center;
    padding: 0; }
    .fortune-levels__cell:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #413f55;
      box-shadow: 1px 0 0 #56546c; }
    .fortune-levels__cell_fluid {
      width: 100%;
      text-align: left;
      padding: 20px 0; }
      .fortune-levels__cell_fluid:after {
        content: none; }
  .fortune-levels__title {
    font-size: 18px;
    font-family: 'ClearSans', sans-serif;
    line-height: 20px; }
  .fortune-levels__list {
    margin: 0;
    padding: 0 0 14px;
    list-style: none; }
  .fortune-levels__listItem {
    display: block;
    position: relative; }
  .fortune-levels__listLink {
    display: block;
    position: relative;
    height: 60px;
    padding: 21px;
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.17);
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1; }
    .fortune-levels__listLink:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #403e54;
      box-shadow: 0 1px 0 #55536d; }
    .fortune-levels__listLink_active {
      background-color: #877de9;
      background-image: linear-gradient(140deg, #9d97de 0%, #8076ec 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26); }
      .fortune-levels__listLink_active:before {
        content: none; }
  .fortune-levels__item {
    display: block;
    position: relative;
    padding: 20px 32px 20px 128px;
    margin-bottom: 10px; }
    .fortune-levels__item:last-child {
      margin-bottom: 0; }
  .fortune-levels__icon {
    display: block;
    position: absolute;
    left: 20px;
    top: 0; }
  .fortune-levels__caption {
    text-shadow: 0 1px 0 rgba(4, 4, 4, 0.32);
    color: #cbcad7;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    display: block; }

.fortune-history {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  width: 100%;
  background-color: #4d4b63; }
  .fortune-history__head {
    background-color: #58566d; }
  .fortune-history__headCell {
    position: relative;
    text-align: left;
    min-width: 116px;
    height: 60px;
    padding: 0 20px; }
    .fortune-history__headCell:last-child:after {
      content: none; }
    .fortune-history__headCell:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #4e4c61;
      box-shadow: 1px 0 0 #656378; }
    .fortune-history__headCell_fluid {
      text-align: left;
      padding: 0 20px; }
  .fortune-history__cell {
    display: table-cell;
    position: relative;
    min-width: 116px;
    height: 61px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase; }
    .fortune-history__cell:first-child {
      min-width: 215px;
      text-align: left;
      padding-left: 20px; }
    .fortune-history__cell:last-child {
      min-width: 257px;
      text-align: left;
      padding: 0 24px; }
    .fortune-history__cell:last-child:after {
      content: none; }
    .fortune-history__cell:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #413f55;
      box-shadow: 1px 0 0 #56546c; }
    .fortune-history__cell:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 1px;
      height: 1px;
      background-color: #403e54;
      box-shadow: 0 1px 0 #55536d; }
    .fortune-history__cell_fluid {
      width: 100%;
      text-align: left;
      padding: 19px 21px; }
  .fortune-history__title {
    font-size: 18px;
    font-family: 'ClearSans', sans-serif;
    line-height: 20px; }
  .fortune-history__list {
    margin: 0;
    padding: 0 0 14px;
    list-style: none; }
  .fortune-history__listItem {
    display: block;
    position: relative; }
  .fortune-history__listLink {
    display: block;
    position: relative;
    height: 60px;
    padding: 21px;
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.17);
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1; }
    .fortune-history__listLink:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #403e54;
      box-shadow: 0 1px 0 #55536d; }
    .fortune-history__listLink_active {
      background-color: #54526c;
      background-image: linear-gradient(140deg, #9d97de 0%, #8076ec 100%);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26); }
      .fortune-history__listLink_active:before {
        content: none; }
  .fortune-history__item {
    display: block;
    position: relative;
    padding: 20px 32px 20px 128px;
    margin-bottom: 10px; }
    .fortune-history__item:last-child {
      margin-bottom: 0; }
  .fortune-history__icon {
    display: block;
    position: absolute;
    left: 20px;
    top: 0; }
  .fortune-history__caption {
    text-shadow: 0 1px 0 rgba(4, 4, 4, 0.32);
    color: #cbcad7;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    display: block; }
  .fortune-history__button {
    background-color: #ff7c22;
    background-image: linear-gradient(165deg, #ffb11a 0%, #ff0036 100%);
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26);
    text-shadow: 0 2px 0 rgba(4, 4, 4, 0.11);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    padding: 5px 12px; }
    .fortune-history__button_disabled {
      background: none;
      box-shadow: none;
      pointer-events: none;
      text-shadow: none;
      padding: 3px 4px; }

.fortune-wheel {
  display: block;
  width: 1024px;
  height: 978px;
  position: relative;
  background-image: url("../img/lottery-bg.png");
  background-repeat: no-repeat;
  background-position: center; }
  .fortune-wheel__head {
    display: table;
    position: relative;
    white-space: nowrap;
    width: 100%;
    height: 86px;
    border-collapse: collapse;
    padding: 0; }
    .fortune-wheel__head:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      opacity: 0.27;
      background-color: #3f3d60;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.42); }
  .fortune-wheel__cell {
    display: table-cell;
    vertical-align: middle;
    width: 100%; }
    .fortune-wheel__cell_fixed {
      width: 1px; }
  .fortune-wheel__title {
    display: inline-block;
    font-size: 20px;
    font-family: 'ClearSans', sans-serif;
    padding: 18px 0px 10px; }
  .fortune-wheel__info {
    display: block;
    position: relative;
    padding: 0 34px; }
  .fortune-wheel__dashboard {
    display: block;
    position: relative;
    padding: 29px 63px 26px; }
  .fortune-wheel__icon {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 19px;
    padding-top: 7px; }
  .fortune-wheel__tooltip {
    left: 50%;
    top: 38px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s, visibility 0.1s; }
    .fortune-wheel__icon:hover .fortune-wheel__tooltip {
      opacity: 1;
      visibility: visible; }
  .fortune-wheel__button {
    height: 31px;
    font-size: 15px;
    margin-right: 5px;
    padding: 6px 15px;
    background-color: #3e92d8;
    background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    .fortune-wheel__button_aux {
      background-color: #fd7423;
      background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
      padding: 6px 16px; }
  .fortune-wheel__lottery {
    display: block;
    position: absolute;
    width: 590px;
    height: 590px;
    top: 200px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .fortune-wheel__wheel {
    display: block;
    position: absolute;
    width: 590px;
    height: 590px;
    top: 0;
    left: 0;
    background-image: url("../img/lottery-wheel.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2; }
  .fortune-wheel__logo {
    display: block;
    position: absolute;
    width: 464px;
    height: 118px;
    top: 141px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-image: url("../img/lottery-title.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1; }
  .fortune-wheel__spinner {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 144px;
    height: 144px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url("../img/lottery-spinner.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 3; }
  .fortune-wheel__arrow {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 65px;
    height: 91px;
    right: 6px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url("../img/lottery-arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 3; }
  .fortune-wheel__range {
    display: block;
    width: 588px;
    position: absolute;
    bottom: 38px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }

.range {
  display: block;
  position: relative; }
  .range__back {
    display: block;
    position: absolute;
    width: 100%;
    height: 17px;
    background: #15141d;
    border-radius: 8px;
    border: none;
    left: 0;
    right: 0;
    bottom: 25px; }
  .range__back-table {
    display: table;
    width: 100%;
    height: 17px;
    table-layout: fixed;
    border-collapse: collapse; }
  .range__back-cell {
    display: table-cell;
    position: relative;
    width: calc(100%/7); }
    .range__back-cell:before {
      content: '';
      display: block;
      position: absolute;
      left: -10px;
      top: -3px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: #15141d; }
    .range__back-cell:first-child:before {
      left: 0; }
    .range__back-cell:nth-child(2):before {
      left: -5px; }
    .range__back-cell:nth-child(4):before {
      left: -13px; }
    .range__back-cell:nth-child(5):before {
      left: -17px; }
    .range__back-cell:nth-child(6):before {
      left: -21px; }
    .range__back-cell:last-child:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: -3px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background: #15141d; }
  .range__input {
    opacity: 0; }
  .range .irs-bar {
    height: 17px;
    background-color: #8c83e6;
    background-image: linear-gradient(180deg, #9d97de 0%, #8076ec 100%);
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26);
    border: none; }
  .range .irs-line {
    background: #15141d;
    border-radius: 8px;
    height: 17px;
    border: none;
    overflow: inherit; }
  .range .irs-grid {
    bottom: 57px;
    width: 565px !important; }
  .range .irs-grid-text {
    display: block;
    position: absolute;
    bottom: -6px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: inherit; }
    .range .irs-grid-text.js-grid-text-0 {
      font-size: 20px;
      line-height: 20px;
      color: #fff;
      bottom: -3px;
      left: 4px !important; }
      .range .irs-grid-text.js-grid-text-0:before {
        left: -3px;
        bottom: -4px; }
    .range .irs-grid-text.js-grid-text-1 {
      font-size: 23px;
      line-height: 23px;
      bottom: -4px;
      margin-left: -7px !important; }
      .range .irs-grid-text.js-grid-text-1:before {
        left: -6px;
        bottom: -3px; }
    .range .irs-grid-text.js-grid-text-2 {
      font-size: 28px;
      line-height: 28px; }
      .range .irs-grid-text.js-grid-text-2:before {
        left: -4px;
        bottom: -5px; }
    .range .irs-grid-text.js-grid-text-3 {
      font-size: 30px;
      line-height: 30px; }
      .range .irs-grid-text.js-grid-text-3:before {
        font-size: 14px;
        left: -9px;
        bottom: -5px; }
    .range .irs-grid-text.js-grid-text-4 {
      font-size: 33px;
      line-height: 33px; }
      .range .irs-grid-text.js-grid-text-4:before {
        font-size: 15px;
        left: -9px;
        bottom: -5px; }
    .range .irs-grid-text.js-grid-text-5 {
      font-size: 36px;
      line-height: 36px; }
      .range .irs-grid-text.js-grid-text-5:before {
        font-size: 15px;
        left: -9px;
        bottom: -6px; }
    .range .irs-grid-text.js-grid-text-6 {
      font-size: 40px;
      line-height: 40px; }
      .range .irs-grid-text.js-grid-text-6:before {
        font-size: 15px;
        left: -6px;
        bottom: -8px; }
    .range .irs-grid-text:before {
      content: 'x';
      display: block;
      font-size: 10px;
      font-weight: 700;
      position: absolute;
      left: -9px;
      bottom: 0; }
    .range .irs-grid-text_active {
      color: #fff; }
  .range .irs-grid-pol {
    display: none; }
  .range .irs-slider {
    top: 29px;
    width: 24px;
    height: 24px;
    border: none;
    background-color: #ff6426;
    background-image: linear-gradient(140deg, #ffb11a 0%, #ff0036 100%);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 5px 8px rgba(0, 0, 0, 0.17); }

.irs-grid-text_active ~ .irs-grid-text {
  color: #15141d; }

.tab__content > div {
  display: none;
  height: 0; }

.tab__content .active {
  height: auto;
  display: block; }

.registration__image img {
  max-width: 100%;
  max-height: 135px; }

.popup_section__aside .aside_promo {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }

.popup_section__aside .aside_promo .promo-bonus, .popup_section__aside .aside_promo .aside__promo-table {
  background: #09203a;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.13) inset, 0 3px 8px rgba(0, 0, 0, 0.19); }

.popup_section__aside .aside_promo .table_promo .table__row:nth-child(2n+1) {
  background: #1c4069; }

.main_gallery .main__item {
  vertical-align: top;
  margin: 9px;
  -webkit-box-shadow: 0px 0px 15px 1px #0095ff;
  -moz-box-shadow: 0px 0px 15px 1px #0095ff;
  box-shadow: 0px 0px 15px 1px #0095ff; }
  .main_gallery .main__item:before {
    content: "";
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg, #fefeff00 0%, #52baff 50%, #fefeff00 100%);
    display: block;
    position: absolute;
    top: -3px; }
  .main_gallery .main__item:after {
    content: "";
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg, #fefeff00 0%, #52baff 50%, #fefeff00 100%);
    display: block;
    position: absolute; }
  @media only screen and (min-width: 1440px) {
    .main_gallery .main__item .preview__info {
      padding: 20px 22px; } }
  .main_gallery .main__item.preview {
    width: 182px; }
    @media only screen and (min-width: 1440px) {
      .main_gallery .main__item.preview {
        width: 210px; } }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .main_gallery .main__item.preview {
        width: 240px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .main_gallery .main__item.preview {
        width: 205px; } }
    @media only screen and (max-width: 767px) {
      .main_gallery .main__item.preview {
        width: 43%;
        max-width: 300px;
        margin: 3.33%; } }
  @media only screen and (min-width: 1440px) {
    .main_gallery .main__item:first-child {
      width: 286px; } }
  @media only screen and (min-width: 1440px) {
    .main_gallery .main__item:nth-child(2) {
      width: 286px; } }
  @media only screen and (min-width: 1440px) {
    .main_gallery .main__item:nth-child(3) {
      width: 286px; } }

.popup_chooseBonus .bonus__item {
  cursor: pointer; }

#login-modal .modal__input.input .modal__caption {
  cursor: pointer; }

@media only screen and (max-width: 767px) {
  .lottery-btm-box .lottery-btm_item {
    font-size: 10px;
    line-height: 15px; } }

@media only screen and (max-width: 767px) {
  .lottery-btm-box .casher__button {
    width: 180px;
    height: 28px;
    font-size: 12px; } }

.popup_bonusNotification .popup__content {
  padding-bottom: 20px; }
  .popup_bonusNotification .popup__content .popup__title {
    padding: 25px 0; }

.popup.successOutpay .popup__content {
  padding-bottom: 20px;
  min-height: 90px;
  padding-top: 25px; }

.popup_afterRegistration .popup__content {
  padding-bottom: 20px;
  min-height: 150px; }

.popup_afterActivation .popup__content {
  padding-bottom: 20px;
  min-height: 150px; }

.popup_phoneVerified .popup__content {
  padding-bottom: 20px; }
  .popup_phoneVerified .popup__content .popup__title {
    padding: 25px 0; }

.popup_newViplevel .popup__content {
  padding-top: 20px;
  text-align: center; }
  .popup_newViplevel .popup__content .popup__title {
    font-size: 31px;
    line-height: 40px; }
  .popup_newViplevel .popup__content .popup__subtitle {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 19px;
    padding: 0;
    margin-top: 9px; }
  .popup_newViplevel .popup__content .bonus__item {
    min-width: 227px;
    min-height: 246px; }
    @media only screen and (max-width: 767px) {
      .popup_newViplevel .popup__content .bonus__item {
        width: 100%; } }

.popup_registration .modal__error {
  max-width: 400px; }

.popup_confirmEmail .popup__content {
  padding: 27px 53px 55px 53px; }
  @media only screen and (max-width: 767px) {
    .popup_confirmEmail .popup__content {
      padding: 40px 24px; } }

.popup_confirmEmail .popup__title {
  padding-bottom: 20px; }

.popup_confirmEmail .popup__caption {
  font-size: 15px;
  line-height: 18px; }

.text-center {
  text-align: center; }

.txt-yellow {
  color: #fdc236; }

@media only screen and (max-width: 767px) {
  .mb_-22-mob {
    margin-bottom: -22px; } }

.maintenance {
  display: table;
  width: 100%;
  height: 100vh;
  min-height: 770px;
  background-image: url("../img/underconstruction-bg.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  text-align: center; }
  .maintenance__row {
    display: table-row;
    position: relative; }
  .maintenance__logo {
    display: table-cell;
    position: relative;
    padding-top: 4%; }
    .maintenance__logo img {
      padding-left: 60px; }
    @media only screen and (max-width: 767px) {
      .maintenance__logo {
        padding-top: 20px; }
        .maintenance__logo img {
          padding-left: 40px;
          width: 300px; } }
  .maintenance__title {
    display: table-cell;
    position: relative;
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.26), 0 3px 2px rgba(0, 0, 0, 0.31);
    color: #ff001e;
    font-family: 'ClearSans', sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 2%; }
    @media only screen and (max-width: 767px) {
      .maintenance__title {
        font-size: 32px;
        padding: 16px 10px;
        line-height: 48px; } }
  .maintenance__info {
    display: table-cell;
    position: relative;
    max-width: 936px;
    margin: 0 auto;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.44);
    font-family: 'ClearSans', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 41px;
    color: #fff;
    padding: 0 40px; }
    @media only screen and (max-width: 767px) {
      .maintenance__info {
        font-size: 21px;
        line-height: 30px;
        padding: 0 20px; } }
    .maintenance__info_accent {
      color: #ffea00; }
  .maintenance__divider {
    display: table-cell;
    position: relative;
    background-image: url("../img/divider.png");
    background-repeat: no-repeat;
    background-position: center center;
    height: 1px;
    width: 100%;
    padding: 2% 0; }
  .maintenance__caption {
    display: table-cell;
    position: relative;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 15px; }
  .maintenance__subscribe {
    display: inline-block;
    position: relative;
    margin-top: 27px;
    margin-bottom: 30px; }
  .maintenance__input {
    display: block;
    position: relative;
    width: 479px;
    height: 73px;
    background-color: rgba(255, 255, 255, 0.12);
    border: 3px solid #fff;
    border-radius: 36px;
    color: #fff;
    font-family: 'ClearSans', sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 134px 0 20px; }
    @media only screen and (max-width: 767px) {
      .maintenance__input {
        width: 300px; } }
  .maintenance__button {
    display: block;
    position: absolute;
    top: 7px;
    right: 7px;
    width: 109px;
    height: 59px;
    background-color: #fff;
    border-radius: 29px;
    border: none;
    color: #06f;
    font-family: 'ClearSans', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer; }

.restriction {
  display: block;
  height: 100vh;
  background-image: url("../img/restriction-bg.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  text-align: center; }
  .restriction__logo {
    padding-top: 242px; }
    .restriction__logo img {
      padding-left: 60px; }
    @media only screen and (max-width: 767px) {
      .restriction__logo {
        padding-top: 20px; }
        .restriction__logo img {
          padding-left: 40px;
          width: 300px; } }
  .restriction__title {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.26), 0 3px 2px rgba(0, 0, 0, 0.31);
    color: #ff001e;
    font-family: 'ClearSans', sans-serif;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 44px;
    margin: 0; }
    @media only screen and (max-width: 767px) {
      .restriction__title {
        font-size: 32px;
        padding-top: 8px; } }
  .restriction__info {
    max-width: 1040px;
    margin: 0 auto;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.44);
    font-family: 'ClearSans', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    color: #fff;
    padding: 44px 40px 20px; }
    @media only screen and (max-width: 767px) {
      .restriction__info {
        padding: 44px 20px 20px;
        line-height: 32px;
        font-size: 22px; } }
    .restriction__info_accent {
      color: #ffea00; }

.pagenotfound {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center; }
  .pagenotfound__hero {
    display: block;
    position: relative;
    width: auto;
    max-width: 1175px;
    height: 100%;
    padding: 75px 20px;
    margin: 0 auto; }
    @media only screen and (min-width: 1240px) and (max-width: 1439px) {
      .pagenotfound__hero {
        max-width: 983px; } }
    @media only screen and (min-width: 1000px) and (max-width: 1239px) {
      .pagenotfound__hero {
        max-width: 940px; } }
    @media only screen and (min-width: 768px) and (max-width: 999px) {
      .pagenotfound__hero {
        max-width: 702px; } }
    @media only screen and (max-width: 767px) {
      .pagenotfound__hero {
        padding: 50px 6px !important; } }
    .pagenotfound__hero:before {
      content: '';
      display: block;
      position: absolute;
      top: 75px;
      left: 20px;
      width: 406px;
      height: 230px;
      z-index: 1;
      background-color: transparent;
      background-image: url("../img/404-bg-header-left.png");
      background-repeat: no-repeat; }
      @media only screen and (max-width: 767px) {
        .pagenotfound__hero:before {
          display: none; } }
    .pagenotfound__hero:after {
      content: '';
      display: block;
      position: absolute;
      top: 75px;
      right: 20px;
      width: 406px;
      height: 230px;
      z-index: 1;
      background-color: transparent;
      background-image: url("../img/404-bg-header-right.png");
      background-repeat: no-repeat; }
      @media only screen and (max-width: 767px) {
        .pagenotfound__hero:after {
          display: none; } }
    @media (max-height: 800px) {
      .pagenotfound__hero {
        padding: 75px 20px 0; }
        .pagenotfound__hero:before, .pagenotfound__hero:after {
          top: 75px; } }
    @media only screen and (max-height: 800px) and (max-width: 767px) {
      .pagenotfound__hero:before, .pagenotfound__hero:after {
        left: 0; } }
  .pagenotfound__shadow {
    display: block;
    position: relative;
    width: 100%;
    box-shadow: -10px 0 15px -5px rgba(0, 0, 0, 0.2), 10px 0 15px -5px rgba(0, 0, 0, 0.2); }
  .pagenotfound__header {
    display: block;
    position: relative;
    height: 90px;
    background-color: #091b2f;
    box-shadow: inset 0 -1px 0 #091b2f, 0 1px 0 #1c3d62; }
  .pagenotfound__content {
    display: block;
    position: relative;
    background-color: #132e4d;
    border: 1px solid #254b78;
    border-bottom: none;
    border-top: none;
    margin-top: 1px; }
  .pagenotfound__footer {
    display: block;
    position: relative;
    background-color: #14355d;
    height: 58px;
    border: 1px solid #254b78;
    border-top: none;
    box-shadow: 0 20px 30px -5px rgba(0, 0, 0, 0.3); }
  .pagenotfound__table {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    height: 100%; }
  .pagenotfound__row {
    display: table-row; }
  .pagenotfound__cell {
    display: table-cell;
    text-align: center;
    padding: 2% 0; }
    .pagenotfound__row:first-of-type .pagenotfound__cell {
      padding: 6.6% 0 0 0; }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .pagenotfound__row:first-of-type .pagenotfound__cell {
          padding: 54px 0 0 0; } }
      @media only screen and (max-width: 767px) {
        .pagenotfound__row:first-of-type .pagenotfound__cell {
          padding: 54px 0 0 0; } }
    .pagenotfound__row:nth-of-type(2) .pagenotfound__cell {
      padding: 2.7% 0 0 0; }
    .pagenotfound__row:nth-of-type(3) .pagenotfound__cell {
      padding: 1.3% 0 0 0; }
    .pagenotfound__row:nth-of-type(4) .pagenotfound__cell {
      padding: 2% 0 5% 0; }
      @media only screen and (max-width: 767px) {
        .pagenotfound__row:nth-of-type(4) .pagenotfound__cell {
          padding: 2% 0 10% 0; } }
  .pagenotfound__logo {
    display: block;
    position: absolute;
    width: 275px;
    height: 160px;
    bottom: -37px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
    @media only screen and (max-width: 767px) {
      .pagenotfound__logo {
        width: 300px;
        height: auto; } }
  .pagenotfound__title {
    display: block;
    position: relative;
    color: #92b2d8;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 179px;
    margin: 0 auto;
    line-height: 150px; }
    @media only screen and (max-width: 767px) {
      .pagenotfound__title {
        font-size: 90px;
        line-height: 100px; } }
  .pagenotfound__subtitle {
    display: block;
    position: relative;
    color: #92b2d8;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 50px;
    margin: 0 auto;
    letter-spacing: 3px; }
    @media only screen and (max-width: 767px) {
      .pagenotfound__subtitle {
        font-size: 22px; } }
  .pagenotfound__caption {
    display: block;
    position: relative;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.29);
    color: #fff59d;
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .pagenotfound__caption {
        padding: 0 15px;
        font-size: 17px; } }
  .pagenotfound__note {
    display: block;
    position: relative;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.17);
    color: #80a3cb;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 8px 20px; }
  .pagenotfound__gallery {
    display: block;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0; }
    .pagenotfound__gallery .preview {
      margin: 9px;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.65), inset 0 -1px 0 rgba(255, 255, 255, 0.04); }
      @media only screen and (min-width: 768px) and (max-width: 999px) {
        .pagenotfound__gallery .preview {
          width: 180px; } }
      @media only screen and (max-width: 767px) {
        .pagenotfound__gallery .preview {
          width: 43%; }
          .pagenotfound__gallery .preview:last-child {
            display: none; } }
  .pagenotfound__button {
    display: block;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 11px 25px;
    margin: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.28);
    color: #fefefe;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16); }
    @media only screen and (max-width: 767px) {
      .pagenotfound__button {
        white-space: nowrap; } }

.rules {
  padding: 33px 35px 33px; }
  .rules__title {
    color: #fefefe;
    margin: 0; }
  .rules__caption {
    font-weight: 400;
    color: #fff001;
    font-size: 15px;
    margin: 18px 0 0 0; }
    .rules__note + .rules__caption {
      margin-top: 33px; }
  .rules__note {
    font-weight: 400;
    color: #7c9bbe;
    font-size: 14px;
    line-height: 17px;
    margin: 16px 0 0; }

.rules .page_content, .rules.page_content {
  color: #ccc;
  font-size: 14px; }
  .rules .page_content h1, .rules.page_content h1 {
    color: #ffea00;
    margin: 0; }
  .rules .page_content h2, .rules .page_content h3, .rules.page_content h2, .rules.page_content h3 {
    font-weight: 400;
    color: #fff59d;
    font-size: 15px;
    margin: 18px 0 0 0; }
  .rules .page_content h4, .rules .page_content h5, .rules.page_content h4, .rules.page_content h5 {
    font-weight: 400;
    color: #ccc;
    font-size: 13px;
    margin: 18px 0 0 0; }
  .rules .page_content p, .rules.page_content p {
    font-weight: 400;
    color: #ccc;
    font-size: 14px;
    line-height: 17px;
    margin: 16px 0 0; }
  .rules .page_content ul, .rules .page_content ol, .rules.page_content ul, .rules.page_content ol {
    font-weight: 400;
    color: #ccc;
    font-size: 14px;
    line-height: 17px; }

.partners {
  padding: 33px 35px 33px; }
  .partners__title {
    color: #fefefe;
    margin: 0;
    font-size: 1.6em; }
    .partners__title_href {
      font-weight: 400;
      color: #7c9bbe;
      font-size: 16px;
      line-height: 15px;
      display: inline-block;
      margin-left: 15px;
      vertical-align: bottom; }
  .partners .partners__link_container .partners__title {
    display: inline-block;
    vertical-align: bottom; }
  .partners__caption {
    font-weight: 400;
    color: #fff001;
    font-size: 18px;
    margin: 18px 0 16px 0; }
    .partners__caption:first-letter {
      text-transform: uppercase; }
  .partners__note {
    font-weight: 400;
    color: #7c9bbe;
    font-size: 15px;
    line-height: 17px;
    margin: 16px 0 0; }

.scroller {
  display: none;
  z-index: 200;
  cursor: pointer;
  position: fixed;
  bottom: 20%;
  left: 2%;
  width: 43px;
  height: 50px;
  border-radius: 2px;
  box-shadow: inset 0 1px 6px 0 rgba(255, 255, 255, 0.32);
  border: solid 1px rgba(92, 92, 92, 0.4);
  text-align: center; }
  .scroller:hover {
    border: solid 1px #93d1ff; }
  .scroller__icon {
    font-size: 21px;
    padding-top: 4px;
    color: #a8d1ff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5); }
  .scroller__note {
    display: block;
    position: relative;
    font-family: 'ClearSans', sans-serif;
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    text-align: center;
    color: #a8d1ff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.sh_btn {
  border-radius: 3px; }
  @media only screen and (max-width: 767px) {
    .sh_btn {
      width: 36px !important;
      min-height: 36px !important;
      height: 36px !important;
      border-radius: 3px 0 0 3px !important;
      overflow: hidden !important; } }

html {
  height: 100%; }
  html.hidden {
    overflow: hidden; }

body {
  background-color: #09203a;
  font-family: 'ClearSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative;
  overflow-x: hidden; }
  @media only screen and (max-width: 767px) {
    body {
      overflow-x: hidden; } }
  body.hidden {
    overflow: hidden; }

.txt-orange {
  color: #ff8920; }

a, a:hover, a:active {
  color: #fff;
  text-decoration: none; }

main a, main a:active {
  color: #3c90f0; }

@media only screen and (max-width: 767px) {
  html.modal_open {
    overflow: hidden; }
    html.modal_open.Sa {
      overflow: visible;
      height: auto; }
      html.modal_open.Sa body {
        overflow: visible; }
    html.modal_open body {
      overflow: hidden; } }

html.modal_open .overflow {
  display: block; }

.loading {
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99; }

.txt-orange {
  color: #ff8920; }

.loader {
  color: #ffffff;
  font-size: 20px;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load 1.3s infinite linear;
  animation: load 1.3s infinite linear;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

@-webkit-keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@keyframes load {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@font-face {
  font-family: "ClearSans";
  src: url("ClearSansRegular/ClearSansRegular.eot");
  src: url("ClearSansRegular/ClearSansRegular.eot?#iefix") format("embedded-opentype"), url("ClearSansRegular/ClearSansRegular.woff") format("woff"), url("ClearSansRegular/ClearSansRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }
