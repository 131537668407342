.header {
  display: table;
  position: relative;
  background-color: $bghead;
  height: 182px;
  width: 100%;
  overflow: hidden;

  @include Small {
    height: 230px;
   
  }

  @include xSmall {
    min-height: 156px;
    height: 156px;
    z-index: 100;
    overflow: auto;
  }

  &__inner {
    display: block;
    position: relative;
  }

  &__left {
  //   display: table-cell;
  //   background-image: url('../img/header-bg-left.svg');
  //   background-position: center bottom;
  //   background-repeat: repeat-x;

    @include xSmall {
      background-image: linear-gradient(to bottom,#091420 0%,#091420 32%,#132e4d 32%, #324c69 100%);
    }
  }

  &__right {
    // display: table-cell;
    // background-image: url('../img/header-bg-right.svg');
    // background-position: center bottom;
    // background-repeat: repeat-x;

    @include xSmall {
      background-image: linear-gradient(to bottom,#091420 0%,#091420 32%,#132e4d 32%, #324c69 100%);
    }
  }

  &__wrap {
    display: table-cell;
    //background-image: url('../img/header-bg-center.svg');
    //background-position: bottom 0 left -136px;
    //background-color: #091420;
    z-index: 1;
    position: relative;
    height: 100%;

    @include xSmall {
      width: 480px;
    }
    // &_bg {
    //   height: 255px;
    //   width: 100%;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: 0;
    //   // background-image: url('../img/header-bg-center.svg');
    //   background-position: bottom 0 left -168px;
    //   background-color: #091420;
    //   @include xLarge {
    //     background-position: bottom 0 left -129px;
    //   }

    //   @include Large {
    //     background-position: bottom 0 left -162px;
    //   }

    //   @include Medium {
    //     background-position: bottom 0 left -160px;
    //   }

    //   @include xSmall {
    //     background-position: bottom 0 left -168px;
    //     background: none;
    //     width: 100%;
    //   }
    // } 


    &_scroll {
      overflow-y: auto;
      touch-action: initial;
    }

  }

  &__toppanel {
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: $bghead-top-panel;

    // @include xLarge {
    //   padding-left: 320px;
    // }

    @include xSmall {
      position: relative;
      padding-left: 0;
      width: 100%;
      margin-left: 0px;
    }

    &.open {
      position: fixed;
      z-index: 100;
      background-color: #091420;
    }


    @include xSmall {

      .signup {
        height: 36px;
      }

      .signup__button {
        padding: 10px 9px;
      }

      .head-panel {
        width: auto;
        display: block;
        z-index: 2;
        right: 19px;
        position: absolute;
        top: 64px;
      }

      .head-panel__cell_fluid {
        display: none;
      }

      .head-panel__button {
        margin-left: 0;
        margin-top: 10px;
        font-size: 14px;
        line-height: 16px;
        padding: 10px 21px 10px;
        width: 130px;
      }
    }

  }

  &__logo {
    display: block;
    top: 56px;
    left: 4px;
    position: absolute;
    z-index: 2;
    width: 206px;
    height: 124px;
    background-image: url('../img/logo/logo.png');
    
    @include Small {
      top: 74px;
    }
    // @include background-2x( '../img/logo/logo', 'png', 300px, 224px, center center, no-repeat );

    @include xSmall {
      @include background-2x( '../img/logo/logo', 'png', 125px, 80px, center center, no-repeat );
      width: 125px;
      height: 80px;
      top: 65px;
      left: 15px;
    }
  }

  &__panel {
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    top: 44px;
    padding: 3px 0 40px 198px;

    &.open {
      display: block;
      position: absolute;
      bottom: 0;
      background-color: #091420;
      right: 0;
      left: 0;
      top: 50px;
      width: 100%!important;
      z-index: 2;
    }

    @include xLarge{
      padding: 3px 0 40px 330px;
    }

    @include Large{
      padding: 3px 0 40px 295px;
    }

    @include Small {
      padding: 27px 0 40px 165px;
    }

    @include xSmall {
      display: block;
      width: 170px!important;
      top: 62px;
      z-index: 100;
      left: auto;
      padding: 2px 14px 0px 22px;

      .head-panel {
        display: none;
      }
    }

    &_logged {

      @include xLarge{
        padding: 15px 0 40px 330px;
      }
  
      @include Large{
        padding: 15px 0 40px 295px;
      }

      @include Medium{
        padding: 15px 0 40px 213px;
      }
  
      @include Small {
        padding: 15px 0 40px 228px;
      }

      @include xSmall {
        display: none;
      }

    }

    @include xSmall {

      .signup {
        height: 36px;
      }

      .signup__button {
        padding: 10px 9px;
      }

    }
  }

  &__head-nav {
    display: block;
    // padding-left: 260px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $bgcolor;

    @include Small {
      padding-left: 0;
      z-index: 1;
    }

    @include xSmall {
      background-image: linear-gradient(360deg, #324c69, #132e4d);
      height: 106px;
      padding-left: 184px;
      bottom: auto;
      top: 50px;

      .nav {
        display: none;
      }
    }

    &_top{
      
      position: relative;

      &:after {
        content:'';
        width:80%;
        position: absolute;
        right: 35px;
        bottom:0;
        height: 2px;
        background-image: linear-gradient(90deg,rgba(87, 185, 220, 0.29),#35d0ff 50%,rgba(87, 185, 220, 0.29));
        -webkit-box-shadow: 0px 0px 80px 10px rgba(63,200,250,1);
        -moz-box-shadow: 0px 0px 80px 10px rgba(63,200,250,1);
        box-shadow: 0px 0px 80px 10px rgba(63,200,250,1);
        @include Large{
          right: 25;
          width: 75%;
        }
    
        @include Medium {
          right: 20px;
          width: 70%;
        }
    
        @include Small {
          height: 0;
          box-shadow: none;
        }
      }
    }
  }

  &_panel-open {
    height: 100%;
    overflow: auto;
    display: block;

    .header__wrap {
      display: block;
      height: 100%;
      min-height: 660px;
      width: 100%;
      max-width: 100%;
    }
  }

  &__confirm-email{
    height: 30px;
    background-image: linear-gradient(100deg,#fda31b,#fd0834);
    width: 100%;
    padding-top: 6px;
    position: fixed;
    top:0;
    z-index: 11;

    @include xSmall {
      display: none!important;
    }

    .confirm-email__txt{
      width: 940px;
      margin: 0 auto;
      font-weight: 700;
      color: #fff;
      font-size: 15px;
      text-shadow: 0 2px 0 rgba(0,0,0,.22);
      text-align: center;
      text-transform: uppercase;
      position: relative;

      @include xLarge {
        width: 1280px;
      }

      @include Large {
        width: 1180px;
      }

      @include Small {
        width: 700px;
      }

      @include xSmall {
        width: 100%;
        max-width: 480px;
      }

    }

    .confirm-email__close{
      position: absolute;
      right: 5px;
      top: 3px;
    }

  }
}