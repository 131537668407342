$wrapWidth: 1506px;
$wrapWidthSmall: 1459px;
$gameplay_height: 760px;
$gameplay_nav: 60px;
html, body {
	height: 100%;
}
body {
	@include Small{
		min-width: 320px!important;
	}
	@include xSmall{
		min-width: 320px!important;
	}
}
.game_bg {
	background-image: url('../img/gameplay-bg.jpg');
	background-repeat: no-repeat;
	background-position: center 0;
	@include xSmall{
		background-image: none;
	}
}
.gameplay_over {
	display: table;
	height: 100%;
	width: 100%;
}
.gameplay {
	display: table-cell;
	vertical-align: middle;
	padding: 68px 15px 68px 15px;
	min-width: 300px;
	@include Medium{
		padding: 48px 15px;
	}
	@include Small{
		padding: 48px 15px;
	}
	@include xSmall{
		padding: 48px 15px 0;
	}
	&__shadow {
		box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.5);
		max-width: 1506px;
		margin: 0 auto;
	}
	&__wrap {
		display: block;
		position: relative;
		max-width: $wrapWidth;
		height: calc(100vh - 156px);
		min-height: 600px;
		max-height: 720px;
		margin: 0 auto;
		padding: 0 280px;
		@include Large{
			padding: 0 280px;
		}
		@include Medium{
			padding: 0 220px;
			height: calc(100vh - 156px);
		}
		@include Small{
			padding: 0;
			display: table;
			width: 100%;
			height: auto;
			min-height: 0;
		}
		@include xSmall{
			padding: 0;
			display: table;
			width: 100%;
			height: auto;
			min-height: 0;
		}
	}

	&__logo {
		display: block;
		height: $gameplay_nav;
		background-image: radial-gradient(circle at 53% 105%, #0c213b, #0d223b 14%, #11263e 35%, #192d44 76%, #26384e);
		box-shadow: 4px 0 5px 0 rgba(0, 0, 0, 0.11);
		border-style: solid;
		border-width: 1px;
		border-image-source: linear-gradient(151deg, rgba(255, 255, 255, 0.25), #344559);
		border-image-slice: 1;
		position: relative;
		z-index: 1;
		margin: -1px -1px 24px -1px;
	}

	&__logo-inner {
		display: block;
		position: absolute;
		bottom: -19px;
		left: 50%;
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);

		@include Medium{
			max-width: 200px;
			bottom: -18px;
		}
		@include Small{
			max-width: 180px;
			bottom: -12px;
		}
		@include xSmall{
			max-width: 160px;
			bottom: -9px;
		}
	}

	&__left {
		display: block;
		float: left;
		position: relative;
		vertical-align: top;
		height: calc(100vh - 156px);
		max-height: 720px;
		min-height: 600px;
		padding-top: 60px;
		background-color: #00284b;
		width: 280px;
		margin-left: -280px;
		@include Large{
			width: 280px;
			margin-left: -280px;
		}
		@include Medium{
			width: 220px;
			margin-left: -220px;
			height: calc(100vh - 156px);
		}
		@include Small{
			width: 340px;
			margin: 25px auto;
			float: none;
			padding-bottom: 15px;
			padding-top: 0;
			min-height: 0;
			height: auto;
		}
		@include xSmall{
			width: 284px;
			margin: 15px auto;
			float: none;
			padding-bottom: 15px;
			overflow: hidden;
			padding-top: 0;
			min-height: 0;
			height: auto;
			.rating__tooltip {
				right: 74px;
			}
			.tooltip__arrow {
				left: 90%;
			}
		}
	}


	&__user {
		display: block;
		position: relative;
		height: 100%;
	}

	&__main {
		display: block;
		float: left;
		position: relative;
		width: 100%;
		height: 100%;
		vertical-align: top;
		background-color: #050d17;
		@include Small{
			float: none;
			display: table-header-group;
		}
		@include xSmall{
			float: none;
			display: table-header-group;
		}

	}
	&__aside {
		display: block;
		float: left;
		position: relative;
		vertical-align: top;
		height: calc(100vh - 156px);
		max-height: 720px;
		background-color: #00284b;
		width: 280px;
		margin-right: -280px;
		min-height: 600px;
		&_overflow {
			overflow: hidden;
			@include Small{
				overflow: visible;
			}
			@include xSmall{
				overflow: visible;
				max-height: none;
			}
		}

		@include Large{
			width: 280px;
			margin-right: -280px;
		}
		@include Medium{
			width: 220px;
			margin-right: -220px;
			height: calc(100vh - 156px);
		}
		@include Small{
			width: 340px;
			float: none;
			margin: 25px auto;
			height: auto;
			min-height: 0;
		}
		@include xSmall{
			width: 284px;
			float: none;
			margin: 15px auto;
			height: auto;
			min-height: 0;
		}
		.aside__fade {
			position: absolute;
			bottom: 0;
			height: 20px;
			background-image: linear-gradient(to bottom, rgba(20, 53, 90, 0.0), #15355a);
			width: 100%;
		}
		&_small {
			width: 300px;
		}

		.game-tabs_tab{
			display: none;
			&.active{
				display: block;
			}
		}
		.gamepage-tabs{
			width: 50%;
			display: inline-block;
			background-color: #07182b;

			&.active{
				background-color: #00284b;
			}
			object{
				width: 41px;
				margin-top: 5px;
			}
		}
		.lottery-name{
			color: #ffd801;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 10px;
		}
		#tab-lottery{
			.tournament__img-overlay{
				height: auto;
			}
		}
	}
	&__wrap_head {
		display: block;
		position: relative;
		max-width: $wrapWidth;
		margin: 0 auto;
		padding: 0 280px;
		height: $gameplay_nav;
		@include Large{
			padding: 0 280px;
		}
		@include Medium{
			padding: 0 220px;
		}
		@include Small{
			padding: 0 0 0 200px;
			margin-bottom: 20px;

		}
		@include xSmall{
			padding: 0 0 0 180px;
			margin-bottom: 20px;
		}
		.gameplay__left {
			height: 100%;
			padding-top: 0;
			min-height: 0;
			@include Small {
				width: 200px;
				margin: 0 0 0 -200px;
				float: left;

			}
			@include xSmall {
				width: 180px;
				margin: 0 0 0 -180px;
				float: left;
				overflow: visible;
			}
		}
		.gameplay__main {
			height: 100%;
			min-height: 0;
			@include Small {
				float: left;
			}
			@include xSmall {
				float: left;
			}
		}
		.gameplay__aside {
			height: 100%;
			min-height: 0;
			background-color: #07182b;
		}

	}

	&__nav {
		padding: 11px 14px 0 16px;
		height: $gameplay_nav;
		background-color: #00284b;
		box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11);
		font-family: 'ClearSans',sans-serif;
		@include xSmall {
			text-align: right;
		}
		i.fa {
			color: #ffffff;
			display: none;
			margin-left: 5px;
			@include xSmall {
				display: inline-block;

			}

		}
	}
	&__canvas {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 0;
		background-color: #00284b;
		// border-bottom: 1px solid #254b78;
		max-width: 980px;
		overflow: hidden;
		@media screen and (max-height: 708px) {
			.gameplay__box {
				max-width: 664px;
			}
		}
		@include Small {
			height: auto;
		}
		@include xSmall {
			height: auto;

		}
		.game_param {
			width: 100%;
			position: absolute;
			max-width: 980px;
			opacity: 0;
			height: auto;
			max-height: calc(100% - 56px);
			z-index: -1;
			@include Small {
				position: relative;

			}
			@include xSmall {
				position: relative;

			}
		}
		.gameplay__canvas_inner {
			background-color: #000;
			//transform: translate(-50%, -50%);
			top: 0;
			left: 0;
			width: 100%;
			text-align: center;
			position: absolute;
			object{
				margin: 0 auto;
			}

		}
		.gameplay__box {
			margin: 0 auto;
			display: block;
			position: relative;
			width: 100%;

		}

		.gameplay__box:before {
			content: "";
			display: block;
			padding-top: 75%;
		}

		.gameplay__box_content {
			position: absolute;
			top: 5px;
			left: 0;
			bottom: 5px;
			right: 0;
			background: black;
		}
	}
	@media screen and (max-height: 890px) and (min-width: 1400px){
		.gameplay__box {
			max-width: 670px;
		}
	}

	&__slider {
		display: block;
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
		z-index: -1;
		padding: 9px 30px 0;
		height: 116px;
		opacity: 0;
		background-color: #00284b;
		box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15), 0 3px 3px rgba(0, 0, 0, 0.90);

		@include xSmall {
			display: none;
		}
		&_open {
			opacity: 1;
			z-index: 1;
		}
		.slider__img {
			width: 100%;
		}
	}
	&__gallery {
		display: block;
		position: relative;
		height: calc(100% - 88px);
		background-color: #15355a;
		border: 1px solid #254b78;
	}
	.rating__summary {
		white-space: normal;
		.rating__icon.fa{
			font-size: 13px;
			width: 13px;
			height: 15px;
		}
		.rating__icon {
			font-size: 13px;
			width: 13px;
			height: 15px;
		}
	}
}
.hide_small{
	display: block;
	@include Small {
		display: none;

	}
	@include xSmall {
		display: none;

	}
}
.hide_big{
	display: none;
	@include Small {
		display: block;

	}
	@include xSmall {
		display: block;

	}
}

.gameplay_small {
	min-width: $wrapWidth;
	background-image: url('../img/gameplaysmall-bg.jpg');
	background-position: center top;

	.gameplay__aside {
		width: 300px;
	}

	.gameplay__wrap {
		width: $wrapWidth;
		padding-right: 47px;
	}

	.gameplay-toppanel {
		padding: 21px 32px;
	}

	.gameplay-toppanel__title {
		font-size: 20px;
		color: #fff;
	}

}