.winsline {
  display: block;
  position: relative;
  font-size: 0;
  width: 959px;
  overflow: hidden;
  margin-right: -19px;

  @include xLarge {
    width: 1440px;
    margin-right: -20px;
  }

  @include Large {
    width: 1203px;
    margin-right: -23px;
  }
  @include Small {
    width: 719px;
  }

  @include xSmall {
    width: calc(100% + 20px);
    margin-right: -20px;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 76px;
    padding-right: 19px;

    @include xLarge {
      padding-right: 20px;
    }

    @include xSmall {
      height: 64px;
    }
  }

  &__block {
    background-color: #15355a;
    border-radius: 2px;
    height: 100%;
    transition: background-color ease 0.2s;
    text-decoration: none;

    .winsline__item:hover & {
      background-color: #1f4c7e;
    }
  }

  &__img {
    width: 88px;
    height: 76px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    border: 3px solid #2c82ff;
   @include xSmall {
      width: 64px;
      height: 63px;
    }
  }

  &__overlay {
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.47), 0 2px 3px rgba(6, 23, 42, 0.26);
    transition: background-color ease 0.1s;
    background-color: rgba(0,0,0, 0);
    border-radius: 2px;
    width: 82px;
    height: 71px;
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;

    @include xSmall {
      width: 64px;
      height: 63px;
    }

    .winsline__item:hover & {
      background-color: rgba(0,0,0, 0.63);
      opacity: 1;
      visibility: visible;

      @include xSmall {
        opacity: 0;
        visibility: hidden;
      }

    }
  }

  &__button {
    display: block;
    position: absolute;
    bottom: 3px;
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
  }

  &__content {
    display: block;
    position: relative;
    line-height: 20px;
    height: 76px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 13px 10px 97px;

    @include xLarge {
      padding: 9px 13px 10px 105px;
    }

    @include xSmall {
      padding: 8px 13px 8px 80px;
      height: 64px;
    }
  }

  &__title {
    color: #fff;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include xSmall {
      font-size: 10px;
      line-height: 12px;
      //float: left;
      padding-top: 1px;
      margin-right: 11px;
      //max-width: 56px;
    }

    &_color_yellow {
      color: #ffe400;
    }
  }

  &__note {
    color: #01ff25;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    text-overflow: ellipsis;
    display: block;
    float: left;
    overflow: hidden;
    max-width: 74px;
    white-space: nowrap;

    @include xSmall {
      line-height: 14px;
      font-size: 10px;
    }

    &_small {
      color: #3c90f0;
      text-transform: lowercase;
      margin-left: 4px;
    }
  }

}
