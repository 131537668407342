.gameplay-user {
	display: block;
	position: relative;

	&__title {
		position: relative;
		display: block;
		text-shadow: 0 2px 0 rgba(0, 0, 0, 0.26);
		color: #d7e6f8;
		font-family: 'ClearSans', sans-serif;
		font-size: 20px;
		line-height: 20px;
		text-align: center;
		margin: 0;
		padding: 26px 10px 31px 10px;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #112c4c;
			box-shadow: 0 1px 0 #193b63;
		}
	}

	&__vip {
		display: block;
		position: relative;
		padding: 14px 24px 38px 26px;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #112c4c;
			box-shadow: 0 1px 0 #193b63;
		}
	}

	&__bonus {
		display: block;
		position: relative;
		padding: 25px 24px 43px 26px;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #112c4c;
			box-shadow: 0 1px 0 #193b63;
		}
	}

	&__action {
		text-align: center;
		padding: 38px 0 0 0;

		.gameplay-user__vip + & {
			padding: 19px 0 0;
		}
	}

	&__button {
		width: 218px;
		height: 44px;
		background-image: linear-gradient(180deg,#ff8d00,#e2d533);
  		text-shadow: 1px 1px 4px rgb(58, 4, 4);
		color: #fefefe;
		font-size: 16px;
		font-weight: 700;
		font-family: 'ClearSans', sans-serif;
		padding: 11px 0 12px;
		margin-bottom: 18px;
		box-shadow: inset 0 0 7px hsla(0,0%,100%,.13), inset 0 1px 0 #d7e610, 0 2px 0 rgba(0,0,0,.11);
		&:last-child {
			margin-bottom: 0;
		}
		.fa {
			color: #ffffff;
          	margin: 0;
		}

		&_orange {
			background-color: #fd7f21;
			background-image: linear-gradient(170deg, #fda31b 0%, #fd0834 100%);
			box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
		}

		&_blue {
      background-color: #3c8fd7;
			background-image: linear-gradient(180deg, #4ea8db 0%, #236dd2 100%);
			box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
		}

		&_darkblue {
      background-color: #2c5380;
			background-image: linear-gradient(180deg, #3b5777 0%, #215187 100%);
			box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.11), 0 0 16px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.16);
		}

		&_active {
      background-color: #7abbee;
			background-image: linear-gradient(180deg, #4ea8db 0%, #a2cdff 100%);
		}
	}

	&__button-text {
		display: inline-block;
		vertical-align: top;
		line-height: 22px;
		margin-left: 13px;
		* {
			color: #ffffff;
		}
	}

}