.profile-panel {
  display: block;
  position: relative;

  &__table {
    display: table;
    width: 100%;
    position: relative;
  }

  &__cell {
    display: table-cell;
    position: relative;
    vertical-align: bottom;
    padding-right: 27px;
    width: 1px;
    &_fluid {
      width: 100%;
      padding-right: 0;
    }
  }

  &__head {
    display: block;
    position: relative;
    padding: 30px 42px 24px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: rgba(0,0,0,0.2);
      box-shadow: 0 1px 1px rgba(255,255,255,0.1);
    }
  }

  &__content {
    display: block;
    vertical-align: middle;
    padding: 21px 38px 32px 42px;
  }

  &__user-panel {
    display: inline-block;

    .user-panel__countpad {
      margin-left: 50px;
    }

    .user-panel__button {
      margin-left: 40px;
    }

  }

  &__caption {
    font-size: 19px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "ClearSans", sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    margin: 0;

    &:nth-child(2) {
      margin-right: 20px;
    }

    &_accent {
      color: $textaccent;
    }
  }

  &__title {
    display: block;
    line-height: 24px;
    &:nth-child(2) {
      font-size: 24px;
    }
  }

}